import { useState } from "react";
import type { IconName } from "@blueprintjs/core";
import { Button, Colors, Menu } from "@blueprintjs/core";
import { PremiumStore } from "../../stores/PremiumStore";
import { GiRoundStar } from "react-icons/gi";
import { ZilchSvg } from "../common/ZilchSvg";
import { useGameSelector } from "../common/useGameSelector";
import { routes } from "../../router";
import { transitionScreen } from "../../transitionScreen";
import type { UserResponse } from "../../../backend/procedures/user";
import { api } from "../../api";
import type { UseQueryResult } from "@tanstack/react-query";
import { UserStore, type AuthResponse } from "../../stores/UserStore";
import { PromptStore } from "../../stores/PromptStore";
import { transitionInFromCss } from "@zilch/css-utils";
import { useWindowSizeDerivedValue } from "@zilch/window-size";
import { Popover } from "../common/Popover";
import { MenuItem2 } from "@blueprintjs/popover2";

export function HomeScreenTitle() {
  const selectGameAndNavigate = useSelectGameAndNavigateIfPremium();
  const userStore = UserStore.use();
  const premiumStore = PremiumStore.use();

  const [gettingPremiumPromptType, setGettingPremiumPromptType] =
    useState(false);

  const user =
    userStore.query.data?.type === "authenticated"
      ? userStore.query.data
      : undefined;

  const prompt = PromptStore.usePrompt();

  const reallySmall = useWindowSizeDerivedValue((width) => width < 530);
  const small = useWindowSizeDerivedValue((width) => width < 900);

  const onClickTournament = () => {
    selectGameAndNavigate({
      title: "Tournament",
      destination: "tournament",
    });
  };

  const onClickMultiplayer = () => {
    selectGameAndNavigate({
      title: "Multiplayer",
      showMultiplayerGuide: true,
      destination: "standard",
    });
  };

  const onClickGameMaker = () => {
    selectGameAndNavigate({
      title: "Custom games",
      hideZilchGames: true,
      destination: "standard",
    });
  };

  const onClickPremium = async () => {
    if (premiumStore.hasPremium) {
      setGettingPremiumPromptType(true);
      const promptType = await getSharePremiumPromptType({
        user,
        fetchUserWithFullPremiumSummary() {
          return userStore.fetchUserWithFullPremiumSummary();
        },
      }).catch((error): SharePremiumPromptType => {
        console.error(error);
        return { type: "unknown" };
      });

      if (promptType.type === "subscriptionOwnerWithoutTeams") {
        transitionScreen(routes.account());
      } else {
        setGettingPremiumPromptType(false);
        await prompt(
          (props) => {
            return (
              <SharePrompt
                promptType={promptType}
                onDone={() => props.resolve(null)}
              />
            );
          },
          {
            width: 460,
          }
        );
      }
    } else {
      premiumStore.setSection("feature-overview");
    }
  };

  const premiumIcon = premiumStore.hasPremium ? (
    "upload"
  ) : (
    <GiRoundStar color={Colors.GRAY4} size={15} />
  );

  const premiumText = premiumStore.hasPremium ? "Share Premium" : "Premium";

  return (
    <>
      {!reallySmall && (
        <ZilchSvg
          size={24}
          backgroundColor={Colors.DARK_GRAY2}
          foregroundColor={Colors.GRAY4}
          style={{ marginRight: "5px" }}
        />
      )}
      {!reallySmall && (
        <HomeScreenButton
          index={0}
          primary={premiumStore.hasPremium}
          text={premiumText}
          icon={premiumIcon}
          loading={gettingPremiumPromptType}
          onClick={onClickPremium}
        />
      )}
      {small && (
        <Popover
          content={
            <Menu>
              {reallySmall && (
                <MenuItem2
                  icon={premiumIcon}
                  text={premiumText}
                  onClick={onClickPremium}
                />
              )}
              <MenuItem2
                onClick={onClickTournament}
                text="Tournament"
                icon="many-to-one"
              />
              <MenuItem2
                onClick={onClickMultiplayer}
                text="Multiplayer"
                icon="people"
              />
              <MenuItem2
                onClick={onClickGameMaker}
                text="Game Maker"
                icon="application"
              />
            </Menu>
          }
        >
          <Button
            minimal
            icon="more"
            className={transitionInFromCss.top}
            style={{
              animationDelay: "400ms",
            }}
          />
        </Popover>
      )}
      {!small && (
        <>
          <HomeScreenButton
            index={1}
            icon="many-to-one"
            onClick={onClickTournament}
            text="Tournament"
          />
          <HomeScreenButton
            index={2}
            icon="people"
            onClick={onClickMultiplayer}
            text="Multiplayer"
          />
          <HomeScreenButton
            index={3}
            icon="application"
            onClick={onClickGameMaker}
            text="Game Maker"
          />
        </>
      )}
    </>
  );
}

function HomeScreenButton(props: {
  icon: IconName | JSX.Element;
  text: string;
  onClick(): void;
  primary?: boolean;
  loading?: boolean;
  index: number;
}) {
  return (
    <Button
      className={transitionInFromCss.top}
      intent={props.primary ? "primary" : "none"}
      style={{
        borderRadius: props.primary ? "15px" : undefined,
        animationDelay: props.index * 50 + 500 + "ms",
      }}
      icon={props.icon}
      minimal={!props.primary}
      outlined={props.primary}
      onClick={props.onClick}
      loading={props.loading}
    >
      {props.text}
    </Button>
  );
}

function useSelectGameAndNavigateIfPremium() {
  const premiumStore = PremiumStore.use();
  const selectGame = useGameSelector();

  return async (params: {
    title: string;
    destination: "tournament" | "standard";
    hideZilchGames?: boolean;
    showMultiplayerGuide?: boolean;
  }) => {
    if (!premiumStore.hasPremium) {
      premiumStore.setSection("feature-overview");
      return;
    }

    const result = await selectGame(params.title, params.hideZilchGames);
    let route;
    if (typeof result?.gameId === "string") {
      if (params.destination === "tournament") {
        route = routes.tournamentInternalGame({ gameId: result.gameId });
      } else {
        route = routes.internalGame({
          gameId: result.gameId,
          showMultiplayerGuide: params.showMultiplayerGuide,
          dev: result.dev ? true : undefined,
        });
      }
    } else if (result) {
      if (params.destination === "tournament") {
        route = routes.tournamentExternalGame({
          owner: result.gameId.owner,
          repo: result.gameId.repo,
        });
      } else {
        route = routes.externalGame({
          owner: result.gameId.owner,
          repo: result.gameId.repo,
          showMultiplayerGuide: params.showMultiplayerGuide,
          dev: result.dev ? true : undefined,
        });
      }
    }

    if (route) {
      transitionScreen(route);
    }
  };
}
type SharePremiumPromptType =
  | {
      type: "subscriptionOwnerWithoutTeams";
    }
  | {
      type: "subscriptionOwner";
    }
  | {
      type: "teamAdmin";
    }
  | {
      type: "verifiedOrganizationEmail";
      email: string;
      organizationName: string | null;
    }
  | {
      type: "teamMember";
      addedByLogin: string | null;
    }
  | {
      type: "unknown";
    };

async function getSharePremiumPromptType(params: {
  user: UserResponse | undefined;
  fetchUserWithFullPremiumSummary(): Promise<UseQueryResult<AuthResponse>>;
}): Promise<SharePremiumPromptType> {
  let detailedUser = params.user;

  if (detailedUser?.subscriptions === undefined) {
    const response = await params.fetchUserWithFullPremiumSummary();

    if (response.isSuccess && response.data.type === "authenticated") {
      detailedUser = response.data;
    }
  }

  const isSubscriptionOwnerWithoutTeams =
    getIsSubscriptionOwnerWithoutTeams(detailedUser);

  if (isSubscriptionOwnerWithoutTeams) {
    return { type: "subscriptionOwnerWithoutTeams" };
  }

  const isSubscriptionOwner = (detailedUser?.subscriptions ?? []).some(
    (subscription) => {
      return subscription.ownerId === detailedUser?.userId;
    }
  );

  const isTeamAdmin = (detailedUser?.subscriptions ?? []).some(
    (subscription) => {
      return subscription.teams.some((team) => {
        return team.role === "admin";
      });
    }
  );

  if (isSubscriptionOwner) {
    return { type: "subscriptionOwner" };
  }

  if (isTeamAdmin) {
    return { type: "teamAdmin" };
  }

  if (detailedUser?.organizationEmail?.status === "verified") {
    return {
      type: "verifiedOrganizationEmail",
      email: detailedUser.organizationEmail.email,
      organizationName: detailedUser.organizationEmail.organizationName,
    };
  }

  const addedBy = detailedUser?.subscriptions?.find((subscription) => {
    return subscription.teams.length > 0;
  })?.teams[0]?.addedBy;

  if (addedBy) {
    return {
      type: "teamMember",
      addedByLogin: await api.manage.getUserLogin.query({ userId: addedBy }),
    };
  }

  return { type: "unknown" };
}

function getIsSubscriptionOwnerWithoutTeams(user: UserResponse | undefined) {
  const subscriptions = user?.subscriptions ?? [];
  const ownedSubscriptions = subscriptions.filter(
    (subscription) => subscription.ownerId === user?.userId
  );

  return (
    ownedSubscriptions.length > 0 &&
    ownedSubscriptions.every((subscription) => {
      return subscription.teams.length === 0;
    })
  );
}

function SharePrompt(props: {
  promptType: SharePremiumPromptType;
  onDone(): void;
}) {
  const render = ({
    message,
    actionText,
    onClick,
  }: {
    message: React.ReactNode;
    actionText: React.ReactNode;
    onClick(): void;
  }) => {
    return (
      <div style={{ padding: "30px" }}>
        <div style={{ fontSize: "20px", marginBottom: "30px" }}>{message}</div>
        <div style={{ textAlign: "right" }}>
          <Button large intent="primary" onClick={onClick}>
            <span style={{ fontWeight: 600 }}>{actionText}</span>
          </Button>
        </div>
      </div>
    );
  };

  if (props.promptType.type === "subscriptionOwner") {
    return render({
      message: (
        <>
          As a <b>subscription owner</b> you can share Premium with others by
          adding them to teams managed by your subscription.
        </>
      ),
      actionText: "Manage Teams",
      onClick() {
        transitionScreen(routes.account());
        props.onDone();
      },
    });
  } else if (props.promptType.type === "teamAdmin") {
    return render({
      message: (
        <>
          As a <b>team admin</b> you can share Premium with others by adding
          them to one of the teams you manage.
        </>
      ),
      actionText: "Manage Teams",
      onClick() {
        transitionScreen(routes.account());
        props.onDone();
      },
    });
  } else if (props.promptType.type === "verifiedOrganizationEmail") {
    const emailDomain = props.promptType.email.split("@")[1] ?? "[domain]";
    return render({
      message: (
        <>
          {props.promptType.organizationName ? (
            <>
              Thanks to <b>{props.promptType.organizationName}</b> everyone
            </>
          ) : (
            "Everyone"
          )}{" "}
          with a <b>@{emailDomain}</b> email address has access to free Premium.
          Spread the word!
        </>
      ),
      actionText: "Sounds good!",
      onClick() {
        props.onDone();
      },
    });
  } else if (props.promptType.type === "teamMember") {
    return render({
      message: (
        <>
          You have Premium thanks to <b>@{props.promptType.addedByLogin}</b>. He
          or she may be able to extend access to others as well. If you know of
          someone that would enjoy Premium let{" "}
          <b>@{props.promptType.addedByLogin}</b> know (contact info might be
          available on GitHub).
        </>
      ),
      actionText: "View GitHub Profile",
      onClick() {
        if (props.promptType.type === "teamMember") {
          window.open(
            `https://github.com/${props.promptType.addedByLogin}`,
            "_blank",
            "noreferrer"
          );
        }
        props.onDone();
      },
    });
  } else {
    return render({
      message: (
        <>
          If you own a Premium subscription or admin a team you can give others
          Premium by adding them to a team (manage this on the account page). Or
          if you have organization provided Premium everyone with an
          organization provided email should have access.
        </>
      ),
      actionText: "Sounds good!",
      onClick() {
        props.onDone();
      },
    });
  }
}
