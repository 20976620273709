.container {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 16px;
  background: var(--dark-gray2);
  border-radius: 20px;
  height: 400px;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}