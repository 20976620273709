.container {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to right, rgba(0,0,0,1) calc(100% - 20px), transparent), linear-gradient(black, black);
  -webkit-mask-size: 100% 100%, 100% 15px;
  -webkit-mask-position: 0 0, 0 100%;
  -webkit-mask-repeat: no-repeat, no-repeat;
  transition: all ease .2s;
}

.slot {
  padding: 20px;
  padding-right: 0px;
  padding-bottom: 6px;
  display: flex;
  width: 100%;
  align-items: end;
  overflow-x: scroll;
  transition: all ease .2s;
}

.botPositioner {
  position: absolute;
  top: 0;
  left: 0;
}

.slotBotLoadError {
  width: 230px;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.spinnerContainer {
  width: 230px;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
}