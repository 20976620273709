.container {
  height: 50px;
  position: absolute;
  padding: 10px 0px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 8;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.containerWithBackButton {
  left: 53px;
}

.backButtonContainer {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 8;
  transition: all cubic-bezier(0.5, 1.5, 0.5, 1) 0.3s;
}

.backButtonContainerHidden {
  opacity: 0;
  transform: translateX(-100px) scale(0.5);
  pointer-events: none;
}

.gameMakerUpload {
  font-size: 11px;
  padding: 3px 5px;
  border: 1px dashed var(--gray1);
  border-radius: 4px;
  margin-left: 5px;
  background: rgba(47, 52, 60, 0.7);
  white-space: nowrap;
  cursor: pointer;
  position: relative;
}

.gameMakerUpload:disabled {
  opacity: 0.5;
  cursor: wait;
}

.gameMakerUpload:not(:disabled):hover {
  background: rgba(67, 72, 80, 0.7);
}

.gameMakerUpload:not(:disabled):active {
  background: rgba(57, 62, 70, 0.7);
}

.backButton {
  width: 50px;
  height: 50px;
  border-radius: 100% !important;
}

.versionSelector {
  width: 250px !important;
}

.versionWarning {
  padding: 3px 5px;
  border-radius: 2px;
  background: var(--orange3) 33;
  color: var(--orange5);
  margin-bottom: 15px;
}

.rightSection {
  display: flex;
  height: 30px;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
}

.leftSection {
  height: 30px;
  flex-basis: 100%;
  padding-left: 20px;
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) calc(100% - 20px),
    transparent
  );
  -webkit-mask-size: 100% 100%;
  -webkit-mask-position: 0 0;
  -webkit-mask-repeat: no-repeat;
  display: flex;
  align-items: center;
  font-weight: 700;
  white-space: nowrap;
}

.courseHighlightContainer {
  background: rgba(37, 42, 49, 0.8);
  border-radius: 2px;
}

.courseHighlightContentContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: -5px;
  white-space: nowrap;
}

.courseName {
  font-weight: 700;
  background: linear-gradient(to right, var(--blue3), var(--blue2));
  border-radius: 3px;
  padding: 2px 5px;
  color: var(--white);
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}

.tryCourse {
  color: var(--light-gray1);
  font-weight: 600;
}

.titleLink {
  color: var(--white) !important;
  text-decoration: none !important;
  border-top: 1px solid transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  transition: all ease 0.2s;
}

.titleLink:hover,
.titleLink:active {
  border-bottom-color: rgba(255, 255, 255, 0.4);
}
