import { useState } from "react";
import { toaster } from "../../toaster";

export function useOperation<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...args: any[]) => Promise<unknown>,
>(args: { run: T; onError?: (error: unknown) => void | "handled" }) {
  const [inProgress, setInProgress] = useState(false);

  return {
    inProgress,
    run: (...params: Parameters<T>) => {
      setInProgress(true);
      args
        .run(...params)
        .catch((error) => {
          if (args.onError?.(error) === "handled") {
            return;
          }

          // eslint-disable-next-line no-console
          console.error(error);
          toaster.show({
            message: "Unexpected problem encountered.",
            intent: "danger",
          });
        })
        .finally(() => {
          setInProgress(false);
        });
    },
  };
}
