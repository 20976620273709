import type { GameId } from "@zilch/game-config";
import { stringifyGameId } from "@zilch/game-config";
import { GiRoundStar } from "react-icons/gi";
import { panic } from "@zilch/panic";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { api } from "../api";
import { toaster } from "../toaster";
import { UserStore } from "./UserStore";
import { useDelay } from "@zilch/delay";
import css from "./StarStore.module.css";
import { AnchorButton, Colors } from "@blueprintjs/core";
import { routes } from "../router";
import { classes, delayCss, transitionInFromCss } from "@zilch/css-utils";
import { usePulse } from "@zilch/use-pulse";
import { Popover } from "../components/common/Popover";
import { GuideContent } from "../components/game/GuideContent";
import { transitionScreen } from "../transitionScreen";
import { preventDefaultLinkClickBehavior } from "type-route";

type StarStore = ReturnType<typeof useStarStore>;

const context = createContext<StarStore | null>(null);

export const StarStore = {
  Provide: ProvideStarStore,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  use: () => useContext(context) ?? panic("StarStore not provided"),
};

const starAnimationDuration = 1500;

interface StarAnimation {
  originX: number;
  targetX: number;
  key: string;
}

const emptyArray: { starCount: number; gameId: GameId }[] = [];

function useStarStore() {
  const userStore = UserStore.use();
  const [starCount, setStarCount] = useState(new Map<string, number>());
  const starButtonRef = useRef<HTMLAnchorElement | null>(null);
  const [starAnimations, setStarAnimations] = useState(
    new Map<string, StarAnimation>()
  );

  const responseStarCount =
    userStore.query.data?.type === "authenticated"
      ? userStore.query.data.starCount
      : emptyArray;

  const [visibleStarCount, setVisibleStarCount] = useState(0);

  useEffect(() => {
    if (responseStarCount === undefined) {
      return;
    }

    const starCountByGameId = new Map<string, number>();

    responseStarCount.forEach((value) => {
      starCountByGameId.set(stringifyGameId(value.gameId), value.starCount);
    });

    setStarCount(starCountByGameId);
    setVisibleStarCount(getTotalStarCount(starCountByGameId));
  }, [responseStarCount]);

  const getStarCount = useCallback(
    (gameId: GameId) => {
      return starCount.get(stringifyGameId(gameId)) ?? 0;
    },
    [starCount]
  );

  const totalStarCount = useMemo(() => {
    return getTotalStarCount(starCount);
  }, [starCount]);

  const [renderPulse, triggerPulse] = usePulse("gold");

  const [showStarPopover, setShowStarPopover] = useState(false);
  const slotsScrollXOffset = useRef(0);

  return {
    totalStarCount,
    starAnimations,
    getStarCount,
    slotsScrollXOffset,
    renderStarButton() {
      if (userStore.query.isLoading) {
        return null;
      }

      return renderPulse(
        <Popover
          isOpen={showStarPopover}
          onClose={() => setShowStarPopover(false)}
          background="blue"
          placement="bottom"
          content={
            <GuideContent
              title="Nice win!"
              message="Boss bot defeated. You've earned some Zilch coin."
            />
          }
        >
          <StarButton
            starButtonRef={starButtonRef}
            starCount={visibleStarCount}
          />
        </Popover>
      );
    },
    reset: () => {
      setVisibleStarCount(0);
      setStarCount(() => new Map());
    },
    setStarCount: useCallback(
      (gameId: GameId, value: number, updateVisibleImmediately = false) => {
        const stringifiedGameId = stringifyGameId(gameId);

        setStarCount((starCount) => {
          const newStarCount = new Map(starCount);
          const previousTotalCount = getTotalStarCount(starCount);
          const previousStarCount = newStarCount.get(stringifiedGameId) ?? 0;

          api.star.setStarCount
            .mutate({ gameId, starCount: value })
            .catch((err) => {
              console.error(err);
              setStarCount((map) => {
                const newMap = new Map(map);
                newMap.set(stringifiedGameId, previousStarCount);
                if (updateVisibleImmediately) {
                  setVisibleStarCount(previousTotalCount);
                }
                return newMap;
              });
              toaster.show({
                intent: "danger",
                message: "Unable to save star update. Try refreshing the page.",
              });
            });

          newStarCount.set(stringifiedGameId, value);
          if (updateVisibleImmediately) {
            setVisibleStarCount(getTotalStarCount(newStarCount));
          }

          return newStarCount;
        });
      },
      []
    ),

    triggerStarAnimation(originSlotIndex: number, final: boolean) {
      const originX = originSlotIndex * 270 + 135 - slotsScrollXOffset.current;
      let targetX = window.innerWidth - 222;
      if (starButtonRef.current) {
        const boundingRect = starButtonRef.current.getBoundingClientRect();
        targetX = boundingRect.x + boundingRect.width / 2;
      }

      const starAnimation: StarAnimation = {
        key: crypto.randomUUID(),
        originX,
        targetX,
      };

      setStarAnimations((value) => {
        const newValue = new Map(value);
        newValue.set(starAnimation.key, starAnimation);
        return newValue;
      });

      setTimeout(() => {
        triggerPulse();
        setVisibleStarCount((value) => value + 1);
      }, starAnimationDuration - 50);

      setTimeout(() => {
        setStarAnimations((value) => {
          const newValue = new Map(value);
          newValue.delete(starAnimation.key);
          return newValue;
        });
      }, starAnimationDuration);

      if (final) {
        setTimeout(() => {
          setShowStarPopover(true);
        }, starAnimationDuration + 400);
      }
    },
  };
}

function ProvideStarStore({ children }: { children: React.ReactNode }) {
  const starStore = useStarStore();
  return (
    <>
      <context.Provider value={starStore}>{children}</context.Provider>
      {Array.from(starStore.starAnimations.values()).map((starAnimation) => {
        return (
          <StarAnimation
            key={starAnimation.key}
            starAnimation={starAnimation}
          />
        );
      })}
    </>
  );
}

function StarAnimation(props: { starAnimation: StarAnimation }) {
  const mounted = useDelay(16);
  const entered = useDelay(800);

  let x: number;
  let y: number;
  let transition: string;
  let scale: number;
  let opacity: number;

  if (mounted) {
    opacity = 1;
  } else {
    opacity = 0;
  }

  if (entered) {
    x = props.starAnimation.targetX;
    transition = `all ease-in ${0.6}s`;
  } else {
    x = props.starAnimation.originX;
    transition = `all cubic-bezier(.5,2,.5,1) ${0.7}s`;
  }

  if (entered) {
    y = 25;
    scale = 0.1;
  } else if (mounted) {
    y = window.innerHeight - 280;
    scale = 1;
  } else {
    y = window.innerHeight - 60;
    scale = 0;
  }

  return (
    <div
      className={css.animationContainer}
      style={{
        transform: `translate(${x}px, ${y}px)`,
        transition,
        opacity,
      }}
    >
      <div
        className={css.starContainer}
        style={{
          transition,
          transform: `scale(${scale}) rotate(${
            entered ? 360 : mounted ? 0 : 10
          }deg)`,
        }}
      >
        <GiRoundStar size={130} className={css.star} />
      </div>
      <GiRoundStar
        className={css.emphasis}
        style={{
          opacity: mounted ? 0 : 1,
          transform: `scale(${mounted ? 1 : 0})`,
          transition: `transform ease .35s .15s, opacity ease .4s .2s`,
        }}
      />
    </div>
  );
}

function getTotalStarCount(starCount: Map<string, number>) {
  return Array.from(starCount.values()).reduce(
    (total, current) => total + current,
    0
  );
}

function StarButton({
  starButtonRef,
  starCount,
}: {
  starButtonRef: React.MutableRefObject<HTMLAnchorElement | null>;
  starCount: number;
}) {
  const [starCountList, setStarCountList] = useState<
    { key: string; value: number }[]
  >([]);
  const [width, setWidth] = useState(10);

  useEffect(() => {
    setStarCountList((list) => {
      return [{ key: crypto.randomUUID(), value: starCount }, ...list];
    });
    setTimeout(() => {
      setStarCountList((list) => {
        if (list.length <= 1) {
          return list;
        }
        const newList = [...list];
        newList.pop();
        return newList;
      });
    }, 300);
  }, [starCount]);

  return (
    <AnchorButton
      href={routes.store().href}
      onClick={(e) => {
        if (preventDefaultLinkClickBehavior(e)) {
          transitionScreen(routes.store());
        }
      }}
      elementRef={starButtonRef}
      className={classes(transitionInFromCss.top, delayCss[200])}
      style={{ paddingTop: "0px", paddingBottom: "0px" }}
      icon={
        <GiRoundStar
          style={{ marginLeft: "5px" }}
          color={starCount === 0 ? Colors.GRAY4 : Colors.GOLD4}
        />
      }
    >
      <div
        className={css.starCountContainer}
        style={{
          width: 16 + width,
        }}
      >
        {starCountList.map((starCount, index) => {
          return (
            <AnimatedStarCount
              key={starCount.key}
              value={starCount.value}
              transitionOut={index > 0}
              onSetWidth={index === 0 ? setWidth : undefined}
            />
          );
        })}
      </div>
    </AnchorButton>
  );
}

function AnimatedStarCount({
  value,
  transitionOut,
  onSetWidth,
}: {
  value: number;
  transitionOut: boolean;
  onSetWidth?(value: number): void;
}) {
  const mounted = useDelay(16);
  return (
    <div
      ref={(element) => {
        if (element) {
          onSetWidth?.(element.clientWidth);
        }
      }}
      style={{
        fontWeight: 700,
        color: value === 0 ? Colors.GRAY4 : Colors.WHITE,
        position: "absolute",
        transition: transitionOut
          ? "all ease .2s"
          : "all cubic-bezier(.5,1.8,.5,1) .3s",
        transform: `translateY(${transitionOut ? -20 : mounted ? 0 : 20}px)`,
        opacity: transitionOut || !mounted ? 0 : 1,
      }}
    >
      {value}
    </div>
  );
}
