.container {
  display: flex;
  gap: 30px;
  align-items: center;
  margin: 30px;
  margin-top: 0px;
  margin-right: 16px;
  flex-wrap: wrap;
  width: calc(100% - 46px);
}

.leftSection {
  border-radius: 20px;
  background: var(--dark-gray2);
  padding: 20px 34px;
  flex-grow: 8;
  height: 100px;
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 30px;
  max-width: 100%;
}

.star {
  transform: translateY(-2px);
  filter: drop-shadow(0 -2px 0px var(--gold5))
    drop-shadow(0 2px 0px var(--gold2)) drop-shadow(0 3px 0px var(--gold2))
    drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.4));
  min-width: 38px;
}

.sectionText {
  font-size: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rightSection {
  border-radius: 20px;
  background: var(--dark-gray2);
  padding: 10px 50px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 300px;
  flex-grow: 1;
  gap: 20px;
  max-width: 100%;
  overflow: hidden;
}

.coinIcon {
  font-size: 14px;
  font-weight: 700;
  color: rgb(246, 196, 70);
  text-shadow: 0 1px 0 var(--gold2);
  filter: drop-shadow(0 -1px 0px var(--gold5))
    drop-shadow(0 3px 0px var(--gold2))
    drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.4));
  border: 2px solid var(--gold4);
  width: 26px;
  height: 26px;
  background: linear-gradient(rgb(149, 108, 5), rgb(202, 147, 10));
  min-width: 26px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coinIcon:before {
  content: "";
  width: 2px;
  height: 16px;
  background: rgb(246, 196, 70);
  border-bottom: 1px solid var(--gold2);
  position: absolute;
}

.coinAmount {
  transform: translateY(-2px);
  font-size: 44px;
  font-weight: 900;
  color: var(--gold4);
  filter: drop-shadow(0 -2px 0px var(--gold5))
    drop-shadow(0 2px 0px var(--gold2)) drop-shadow(0 3px 0px var(--gold2))
    drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.4));
}
