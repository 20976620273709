import { HTMLSelect, Tag } from "@blueprintjs/core";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import { useMemo } from "react";
import { api } from "../../api";
import { useOperation } from "../common/useOperation";
import { RemoveTeamMemberButton } from "./RemoveTeamMemberButton";
import css from "./MemberList.module.css";

export interface User {
  login: string | null;
  userId: string;
  addedBy: string;
  role: "member" | "admin" | "owner";
}

interface Props {
  teamId: string;
  users: User[];
  viewerUserId: string;
  onUpdate(close?: boolean): Promise<void>;
}

export function MemberList(props: Props) {
  const viewer = useMemo(() => {
    return (
      props.users.find((user) => user.userId === props.viewerUserId) ?? null
    );
  }, [props.users, props.viewerUserId]);

  return (
    <div className={css.listContainer}>
      {props.users.map((user) => {
        return (
          <Member
            key={user.userId}
            user={user}
            viewer={viewer}
            teamId={props.teamId}
            onUpdate={props.onUpdate}
          />
        );
      })}
    </div>
  );
}

function Member({
  user,
  viewer,
  teamId,
  onUpdate,
}: {
  user: User;
  viewer: User | null;
  teamId: string;
  onUpdate(close?: boolean): Promise<void>;
}) {
  const roleChangeOperation = useOperation({
    async run(role: "admin" | "member") {
      await api.manage.addOrUpdateTeamMembers.mutate({
        teamId,
        users: [
          {
            identifier: {
              type: "userId",
              value: user.userId,
            },
            role,
          },
        ],
      });
      await onUpdate();
    },
  });

  return (
    <div
      key={user.userId}
      className={classes(transitionInFromCss.bottom, css.row)}
    >
      <div>
        <a
          href={`https://www.github.com/${user.login}`}
          target="_blank"
          rel="noreferrer"
        >
          {user.login ?? user.userId}
        </a>
        {user.userId === viewer?.userId && (
          <Tag minimal style={{ marginLeft: "6px" }}>
            You
          </Tag>
        )}
        {user.userId !== viewer?.userId && user.userId === viewer?.addedBy && (
          <Tag minimal style={{ marginLeft: "6px" }}>
            Added you
          </Tag>
        )}
      </div>
      <div>
        {user.role === "owner" ? (
          <HTMLSelect
            options={["Owner"]}
            disabled
            className={css.select}
            minimal
          />
        ) : (
          <HTMLSelect
            options={["Admin", "Member"]}
            minimal
            className={css.select}
            value={user.role === "admin" ? "Admin" : "Member"}
            disabled={
              roleChangeOperation.inProgress || viewer?.role === "member"
            }
            onChange={() => {
              const newRole = user.role === "admin" ? "member" : "admin";
              if (newRole === "member" && viewer?.userId === user.userId) {
                if (
                  confirm(
                    "You won't be able to add yourself back as a team admin."
                  )
                ) {
                  roleChangeOperation.run(newRole);
                }
              } else {
                roleChangeOperation.run(newRole);
              }
            }}
          />
        )}
        <RemoveTeamMemberButton
          teamId={teamId}
          onRemoved={() => onUpdate(user.userId === viewer?.userId)}
          userId={user.userId}
          userRole={user.role}
          viewerRole={viewer?.role ?? null}
          viewerUserId={viewer?.userId ?? null}
          buttonProps={{
            icon: "remove",
            minimal: true,
          }}
        />
      </div>
    </div>
  );
}
