.outerContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  -webkit-mask:
    linear-gradient(
        transparent,
        rgba(0, 0, 0, 0.1) 37px,
        rgba(0, 0, 0, 1) 80px,
        rgba(0, 0, 0, 1) calc(100% - 60px),
        transparent
      )
      0 0,
    linear-gradient(
        to right,
        transparent calc(100% - 14px),
        black calc(100% - 14px)
      )
      0 0;
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
}

.innerContainer {
  display: flex;
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  background: rgba(0, 0, 0, 0.1);
  width: 381px;
  flex-direction: column;
  position: relative;
  gap: 10px;
  padding-left: 10px;
  padding-bottom: 60px;
  padding-top: 70px;
  min-height: 100%;
  transition: all ease 0.3s;
}

.smallInnerContainer {
  width: 100%;
  border-right: 0px;
}

.innerContainer.hidden {
  transform: translateX(-100%);
}

.topSection {
  width: 300px;
  margin-left: 29px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
}

.topSectionHidden {
  transform: translateY(30px);
  opacity: 0;
  pointer-events: none;
}
