import { getForbiddenWordsInText } from "./getForbiddenWordsInText";

const invalidNames = new Set(["boss bot", "practice bot", "manual bot"]);

export function validateBotName(botName: string): { problems: string[] } {
  const problems: string[] = [];

  if (botName.trim().length !== botName.length) {
    problems.push("Bot name must have have leading or trailing whitespace.");
  }

  if (botName.length < 3 || botName.length > 40) {
    problems.push("Bot name should be 3-40 characters long.");
  }

  if (invalidNames.has(botName.toLowerCase())) {
    problems.push(`Bot name cannot be "${botName}"`);
  }

  const forbiddenWords = getForbiddenWordsInText(botName).slice(0, 3);

  if (forbiddenWords.length > 0) {
    problems.push(
      `The word${forbiddenWords.length === 1 ? "" : "s"} ${forbiddenWords
        .map((word) => `"${word}"`)
        .join(", ")} ${forbiddenWords.length === 1 ? "is" : "are"} not allowed.`
    );
  }

  return { problems };
}
