import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "../queryClient";
import { groups, routes, useRoute } from "../router";
import cookie from "cookie";
import { z } from "zod";
import { api } from "../api";
import { TRPCClientError } from "@trpc/client";
import type { API } from "../../backend/routes/trpc";
import { panic } from "@zilch/panic";
import { requiredScopes } from "@zilch/required-scopes";
import { toaster } from "../toaster";
import type { UserResponse } from "../../backend/procedures/user";
import { usePrevious } from "@zilch/use-previous";
import { PromptStore } from "./PromptStore";
import { AnchorButton, Button, Colors, Icon, Tag } from "@blueprintjs/core";
import { ZilchSvg } from "../components/common/ZilchSvg";
import { SiGithub } from "react-icons/si";
import css from "./UserStore.module.css";
import { classes } from "@zilch/css-utils";
import { posthog } from "posthog-js";
import * as csx from "csx";
import { Reveal } from "../components/common/Reveal";
import { Popover } from "../components/common/Popover";

type UserStore = ReturnType<typeof useUserStore>;

export type AuthResponse =
  | { type: "sessionExpired" }
  | { type: "unauthenticated" }
  | ({
      type: "authenticated";
    } & UserResponse);

const context = createContext<UserStore | null>(null);

type GithubAuthCallbackData = z.infer<typeof GithubAuthCallbackData>;
const GithubAuthCallbackData = z.union([
  z.object({
    type: z.literal("error"),
    error: z.string(),
    state: z.string(),
  }),
  z.object({
    type: z.literal("success"),
    code: z.string(),
    state: z.string(),
  }),
]);

export const UserStore = {
  Provide: ProvideUserStore,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  use: () => useContext(context) ?? panic("GithubStore not provided"),
};

type SignInSource =
  | "titleBar"
  | "authenticatedView"
  | "botCreator"
  | "botSelector"
  | "gameLock"
  | "multiplayer"
  | "gamesCreatedBy"
  | "gameCreator";

function clearSessionCookie() {
  document.cookie = "HasSessionId=; Max-Age=0; Path=/; Secure; SameSite=Strict";
}

async function makeUserRequest(
  refreshLogin: boolean,
  ensureUpToDatePremiumSummary: boolean,
  ensureUpToDateStarCount: boolean,
  signal: AbortSignal
): Promise<AuthResponse> {
  if (cookie.parse(document.cookie)["HasSessionId"] !== "true") {
    return { type: "unauthenticated" };
  }

  const userResponse = await api.user.get
    .query(
      { refreshLogin, ensureUpToDatePremiumSummary, ensureUpToDateStarCount },
      { signal }
    )
    .catch((error: TRPCClientError<API>) => {
      if (error instanceof TRPCClientError) {
        return error;
      }

      throw error;
    });

  signal.throwIfAborted();

  if (userResponse instanceof TRPCClientError) {
    if (userResponse.data?.code === "UNAUTHORIZED") {
      if (userResponse.message === "session-expired") {
        clearSessionCookie();
        return { type: "sessionExpired" };
      } else if (userResponse.message === "session-cookie-absent") {
        return { type: "unauthenticated" };
      }
    }

    throw userResponse;
  }

  return {
    type: "authenticated",
    ...userResponse,
  };
}

async function makeSignInRequest(
  signal: AbortSignal,
  code: string | null
): Promise<AuthResponse> {
  if (!code) {
    const state = crypto.randomUUID();

    localStorage.setItem("githubAuthState", state);

    code = await new Promise<string | null>((resolve) => {
      const messageHandler = (message: MessageEvent<unknown>) => {
        const validation = GithubAuthCallbackData.safeParse(message.data);

        if (
          validation.success &&
          validation.data.state === state &&
          message.origin === window.origin
        ) {
          window.removeEventListener("message", messageHandler);
          if (validation.data.type === "success") {
            resolve(validation.data.code);
          } else {
            resolve(null);
          }
        }
      };

      window.addEventListener("message", messageHandler);

      signal.addEventListener("abort", () => {
        window.removeEventListener("message", messageHandler);
        resolve(null);
      });

      const width = Math.min(500, screen.availWidth - 80);
      const height = Math.min(658, screen.availHeight - 80);

      const top =
        (window.outerHeight - height) / 2 +
        (window.screenY || window.screenTop || 0);
      const left =
        (window.outerWidth - width) / 2 +
        (window.screenX || window.screenLeft || 0);

      window.open(
        `https://github.com/login/oauth/authorize?login=&client_id=${
          ClientEnv.ZILCH_GITHUB_CLIENT_ID
        }&scope=${requiredScopes.join(" ")}&state=${state}`,
        undefined,
        `top=${top},left=${left},width=${width},height=${height},popup=yes,scrollbars=yes,resizable=yes`
      );
    });

    signal.throwIfAborted();
  }

  localStorage.removeItem("githubAuthState");

  if (code === null) {
    return { type: "unauthenticated" };
  }

  const user: UserResponse = await api.user.signIn.mutate({ code }, { signal });

  posthog.identify(user.userId, {
    githubUsername: user.likelyLogin,
  });

  if (user.firstLogin) {
    posthog.capture("user_signed_up");
  }

  signal.throwIfAborted();

  return {
    type: "authenticated",
    ...user,
  };
}

async function makeSignOutRequest(signal: AbortSignal): Promise<AuthResponse> {
  clearSessionCookie();
  api.user.signOut.mutate(void 0, { signal });
  return { type: "unauthenticated" };
}

function cancel() {
  return queryClient.cancelQueries({ queryKey: ["UserStore.query"] });
}

export type PremiumSources =
  | "unknown"
  | "active-subscription"
  | "organization-plan"
  | "team-membership";

function useUserStore() {
  const route = useRoute();
  const [signingIn, setSigningIn] = useState<SignInSource | false>(false);

  const authOperationRef = useRef<
    | { type: "signIn"; source: SignInSource; code: string | null }
    | { type: "signOut" }
    | { type: "impersonate"; login: string }
    | { type: "stopImpersonation" }
    | null
  >(null);

  const routeRef = useRef(route);
  routeRef.current = route;

  const refreshLoginRef = useRef(true);
  const ensureUpToDateStarCountRef = useRef(true);
  const ensureUpToDatePremiumSummaryRef = useRef(false);

  const query = useQuery(
    ["UserStore.query"],
    async ({ signal }): Promise<AuthResponse> => {
      if (!signal) panic("AbortController required");

      const refreshLogin = refreshLoginRef.current;
      refreshLoginRef.current = false;

      const ensureUpToDataStarCount = ensureUpToDateStarCountRef.current;
      ensureUpToDateStarCountRef.current = false;

      if (authOperationRef.current?.type === "signIn") {
        const code = authOperationRef.current.code;
        setSigningIn(authOperationRef.current.source);
        authOperationRef.current = null;
        return await makeSignInRequest(signal, code).finally(() => {
          setSigningIn(false);
        });
      } else if (authOperationRef.current?.type === "signOut") {
        authOperationRef.current = null;
        return await makeSignOutRequest(signal);
      } else if (authOperationRef.current?.type === "impersonate") {
        const login = authOperationRef.current.login;
        authOperationRef.current = null;
        return {
          type: "authenticated",
          ...(await api.user.impersonate.mutate({ login }, { signal })),
        };
      } else if (authOperationRef.current?.type === "stopImpersonation") {
        authOperationRef.current = null;
        return {
          type: "authenticated",
          ...(await api.user.stopImpersonation.mutate(void 0, { signal })),
        };
      } else {
        const ensureUpToDatePremiumSummary =
          routeRef.current.name === routes.account.name ||
          ensureUpToDatePremiumSummaryRef.current;
        ensureUpToDatePremiumSummaryRef.current = false;
        return await makeUserRequest(
          refreshLogin,
          ensureUpToDatePremiumSummary,
          ensureUpToDataStarCount,
          signal
        );
      }
    },
    {
      enabled:
        !groups.githubAuthCallback.has(route) &&
        !groups.stripeCallback.has(route),
      refetchOnWindowFocus: false,
    }
  );

  const isAuthenticated =
    query.isSuccess && query.data.type === "authenticated";
  const previousIsAuthenticated = usePrevious(isAuthenticated);

  const isAuthenticatedRef = useRef(isAuthenticated);
  isAuthenticatedRef.current = isAuthenticated;

  const previousIsAuthenticatedRef = useRef(previousIsAuthenticated);
  previousIsAuthenticatedRef.current = previousIsAuthenticated;

  useEffect(() => {
    if (previousIsAuthenticatedRef.current && !isAuthenticatedRef.current) {
      // Reset posthog if we transition from an authenticated to an unauthenticated state.
      posthog.reset();
    } else if (query.isSuccess && query.data.type === "authenticated") {
      // update identity information if it changes
      posthog.identify(query.data.userId, {
        githubUsername: query.data.likelyLogin,
      });
    }
  }, [query.data, query.isSuccess]);

  const previousRouteName = usePrevious(route.name);
  const transitionedToAccountRoute =
    previousRouteName !== routes.account.name &&
    route.name === routes.account.name;
  const refetchQueryRef = useRef(query.refetch);
  refetchQueryRef.current = query.refetch;

  const prompt = PromptStore.usePrompt();
  const promptRef = useRef(prompt);
  promptRef.current = prompt;

  useEffect(() => {
    if (transitionedToAccountRoute) {
      refetchQueryRef.current();
    }
  }, [transitionedToAccountRoute]);

  const signIn = useCallback(
    async (source: SignInSource, code: string | null = null) => {
      const result = await promptRef.current<"connect" | null>(
        (props) => {
          return (
            <GithubPermissionNotification
              customMessage={
                source === "botCreator"
                  ? "Zilch needs permission to create a GitHub repository for your new bot."
                  : undefined
              }
              onConnect={() => props.resolve("connect")}
            />
          );
        },
        {
          width: 400,
        }
      );

      if (!result) {
        return;
      }

      authOperationRef.current = { type: "signIn", source, code };
      return await query.refetch();
    },
    [query]
  );

  const signInRef = useRef(signIn);
  signInRef.current = signIn;

  useEffect(() => {
    if (!groups.githubBotAuthCallback.has(route)) {
      return;
    }

    fetch(
      `/api/tunnel/bot?authCallbackData=${window.btoa(
        JSON.stringify(
          route.name === routes.githubBotAuthSuccessCallback.name
            ? {
                type: "success",
                state: route.params.state,
                code: route.params.code,
              }
            : {
                type: "error",
                state: route.params.state,
                error: route.params.error,
              }
        )
      )}`
    )
      .then((response) => {
        if (!response.ok) {
          console.error(response.text());
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        window.close();
      });
  }, [route]);

  useEffect(() => {
    if (!groups.githubAuthCallback.has(route)) {
      return;
    }

    const message: GithubAuthCallbackData =
      route.name === routes.githubAuthSuccessCallback.name
        ? {
            type: "success",
            state: route.params.state,
            code: route.params.code,
          }
        : {
            type: "error",
            state: route.params.state,
            error: route.params.error,
          };

    const opener = window.opener as Window | null;

    if (opener?.location.origin === ClientEnv.ORIGIN) {
      opener.postMessage(message, window.origin);
      opener.focus();
      window.close();
    } else {
      routes.home().replace();

      if (
        localStorage.getItem("githubAuthState") !== message.state ||
        message.type !== "success"
      ) {
        toaster.show({
          message: "Unable to complete sign in.",
          intent: "danger",
        });
        return;
      }

      signInRef.current("titleBar", message.code);
    }
  }, [route]);

  const signOut = useCallback(async () => {
    authOperationRef.current = { type: "signOut" };
    return await query.refetch();
  }, [query]);

  const signOutRef = useRef(signOut);
  signOutRef.current = signOut;

  const fetchUserWithFullPremiumSummary = useCallback(async () => {
    ensureUpToDatePremiumSummaryRef.current = true;
    return await query.refetch();
  }, [query]);

  const impersonate = useCallback(
    async (login: string) => {
      authOperationRef.current = { type: "impersonate", login };
      return await query.refetch();
    },
    [query]
  );

  const stopImpersonation = useCallback(async () => {
    authOperationRef.current = { type: "stopImpersonation" };
    return await query.refetch();
  }, [query]);

  useEffect(() => {
    if (query.isError) {
      signOutRef.current();
      toaster.show({
        message:
          "Unexpected authentication error encountered. You've been signed out.",
        intent: "danger",
      });
    }
  }, [query.isError]);

  useEffect(() => {
    if (!signingIn) {
      return;
    }

    let timeout: NodeJS.Timeout | undefined;
    let toasterKey: string | undefined;
    let alreadyFocused = false;

    const handleFocus = () => {
      if (alreadyFocused) {
        return;
      }
      alreadyFocused = true;

      timeout = setTimeout(() => {
        toasterKey = toaster.show({
          icon: "warning-sign",
          message:
            "It's taking longer than expected to sign you in. Feel free to cancel this sign in attempt and try again.",
          timeout: 0,
          intent: "warning",
          isCloseButtonShown: false,
          action: {
            text: <u>Cancel</u>,
            onClick: cancel,
          },
        });
      }, 2500);
    };

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
      clearTimeout(timeout);
      if (toasterKey) {
        toaster.dismiss(toasterKey);
      }
    };
  }, [signingIn]);

  const premiumSources = useMemo(() => {
    const premiumSources = new Set<PremiumSources>();

    if (!query.isSuccess || query.data.type !== "authenticated") {
      return premiumSources;
    }

    if (query.data.likelyHasPremium && query.data.hasPremium === undefined) {
      return new Set<PremiumSources>(["unknown"]);
    }

    if (!query.data.hasPremium) {
      return premiumSources;
    }

    const userId = query.data.userId;

    const activeSubscriptions =
      query.data.subscriptions?.filter(
        (subscription) =>
          subscription.usedSeatCount <= subscription.seatCount &&
          (subscription.status === "active" ||
            subscription.status === "incomplete")
      ) ?? [];

    const ownedActiveSubscriptions = activeSubscriptions.filter(
      (subscription) => subscription.ownerId === userId
    );

    if (ownedActiveSubscriptions.length > 0) {
      if (activeSubscriptions.length > ownedActiveSubscriptions.length) {
        premiumSources.add("team-membership");
      }

      premiumSources.add("active-subscription");
    } else if (activeSubscriptions.length > 0) {
      premiumSources.add("team-membership");
    }

    if (
      query.data.organizationEmail?.status === "verified" ||
      query.data.organizationEmail?.status === "expiring-soon"
    ) {
      premiumSources.add("organization-plan");
    }

    return premiumSources;
  }, [query]);

  return {
    query,
    premiumSources,
    impersonate,
    stopImpersonation,
    fetchUserWithFullPremiumSummary,
    signIn,
    signOut,
    cancel,
    signingIn,
  };
}

function ProvideUserStore(props: { children: React.ReactNode }) {
  const store = useUserStore();
  return <context.Provider value={store}>{props.children}</context.Provider>;
}

function GithubPermissionNotification(props: {
  onConnect(): void;
  customMessage?: string;
}) {
  const noAccountEmphasisColor = Colors.BLUE5;
  return (
    <>
      <div className={css.permissionTopSection}>
        <SiGithub
          size={76}
          color={Colors.LIGHT_GRAY2}
          style={{
            padding: "12px",
            borderRadius: "100%",
          }}
        />
        <Icon icon="shield" color={Colors.BLUE5} size={26} />
        <ZilchSvg
          size={58}
          style={{
            marginLeft: "8px",
          }}
          foregroundColor={Colors.LIGHT_GRAY2}
          backgroundColor={"transparent"}
        />
      </div>
      <div className={css.permissionMiddleSection}>
        <Reveal>
          {(text) => {
            return (
              <>
                <div
                  style={{
                    fontSize: "30px",
                    fontWeight: 900,
                    textAlign: "center",
                    lineHeight: "32px",
                  }}
                >
                  {text("■■Authorize Zilch■■■■■■■")}
                </div>
                <div
                  style={{
                    fontSize: "18px",
                    lineHeight: "22px",
                    textAlign: "center",
                    fontWeight: 500,
                    maxWidth: "310px",
                  }}
                >
                  {text(
                    props.customMessage ??
                      "Zilch's powerful GitHub integration makes it easy to start building bots."
                  )}
                </div>{" "}
              </>
            );
          }}
        </Reveal>
        <div
          style={{
            width: "calc(100% + 8px)",
            background: Colors.DARK_GRAY2,
            borderRadius: "4px",
            padding: "4px",
            marginLeft: "-4px",
          }}
        >
          <Button
            fill
            intent="primary"
            large
            style={{
              padding: "20px 30px",
              fontWeight: 600,
              fontSize: "18px",
              marginBottom: "4px",
            }}
            rightIcon="arrow-right"
            onClick={props.onConnect}
          >
            Connect GitHub Account
          </Button>
          <Popover
            position="bottom"
            fill
            matchTargetWidth
            content={
              <div style={{ padding: "4px" }}>
                <div
                  style={{
                    margin: "10px 14px",
                    fontSize: "15px",
                    fontWeight: 500,
                  }}
                >
                  Over 100 million developers use GitHub to create, store, and
                  manage their code.
                </div>
                <AnchorButton
                  fill
                  large
                  minimal
                  rightIcon="arrow-right"
                  alignText="left"
                  className="bp4-popover-dismiss"
                  target="_blank"
                  style={{ position: "relative" }}
                  rel="noreferrer"
                  href="https://github.com/signup"
                  icon={<SiGithub color={Colors.GRAY4} />}
                >
                  Create GitHub account
                  <Tag
                    style={{
                      fontSize: "12px",
                      padding: "2px 6px",
                      lineHeight: "16px",
                      minHeight: "20px",
                      position: "absolute",
                      right: "44px",
                    }}
                    minimal
                  >
                    Free
                  </Tag>
                </AnchorButton>
                <AnchorButton
                  fill
                  className="bp4-popover-dismiss"
                  large
                  minimal
                  rightIcon="arrow-right"
                  alignText="left"
                  target="_blank"
                  href="/what-is-github"
                  icon="learning"
                >
                  Learn more about GitHub
                </AnchorButton>
              </div>
            }
          >
            <Button
              minimal
              fill
              alignText="left"
              rightIcon={
                <Icon
                  icon="chevron-down"
                  color={csx.color(noAccountEmphasisColor).fade(0.8).toString()}
                />
              }
              icon={<Icon icon="user" color={noAccountEmphasisColor} />}
            >
              <span
                style={{
                  color: noAccountEmphasisColor,
                  fontWeight: 600,
                }}
              >
                Don't have a GitHub account?
              </span>
            </Button>
          </Popover>
        </div>
      </div>
      <div className={classes(css.permissionBottomSection, "bp4-text-muted")}>
        Connecting your GitHub account with Zilch indicates acceptance of the{" "}
        <a target="_blank" href="/terms">
          Terms of Service
        </a>{" "}
        +{" "}
        <a target="_blank" href="/privacy">
          Privacy Policy
        </a>
        <div
          style={{
            marginTop: "11px",
            width: "120px",
            height: "1px",
            background: csx
              .color(Colors.DARK_GRAY5)
              .mix(Colors.GRAY1)
              .toString(),
          }}
        />
        <div style={{ marginTop: "10px" }}>
          <a target="_blank" href="/what-is-github">
            Learn more about GitHub ›
          </a>
        </div>
      </div>
    </>
  );
}
