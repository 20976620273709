.background {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100px;
  pointer-events: none;
  transition: all ease .3s;
  background: linear-gradient(rgba(28, 33, 39, 0), var(--dark-gray1));
}

.container {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all cubic-bezier(.5,1.4,.5,1) .5s;
  display: grid;
  grid-template-columns: 180px minmax(100px, 1fr) 160px;
  transform: translateY(0px);
}

.hidden {
  transform: translateY(140px);
  pointer-events: none;
  transition: all ease .2s;
  opacity: 0;
}

.leftSide {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.rightSide {
  padding: 20px 25px;
  padding-left: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressBar {
  width: 100%;
  height: 100%;
  transform-origin: center left;
  background: var(--gray4);
}

.progressBarSection {
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  cursor: pointer;
}

.progressBarContainer {
  width: 100%;
  height: 6px;
  background: var(--dark-gray5);
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.hoverSection {
  position: absolute;
  background: var(--dark-gray5);
  height: 6px;
  border-radius: 3px;
  opacity: 0;
  left: 0;
  z-index: 1;
  transform-origin: center left;
  pointer-events: none;
  overflow: hidden;
  transition: opacity ease .3s, height ease .3s;
}

.hoverSectionFill {
  background: var(--gray4);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  transform: scaleX(.5);
  transform-origin: center left;
}

.hoverSectionActive {
  opacity: 1;
  height: 10px;
}

.hoverSectionHidden {
  opacity: 0 !important;
}

.timelineHeadContainer {
  position: absolute;
  z-index: 2;
  left: 0px;
}

.timelineHead {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: var(--gray4);
  left: -4px;
  top: -3px;
  position: absolute;
  transition: all ease .2s;
  cursor: grab;
}

.timelineHeadHovered {
  transform: scale(2);
}

.timelineHeadActive {
  transform: scale(3) !important;
}