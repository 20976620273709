.pricingOuterContainer {
  margin-bottom: -16px;
  overflow: hidden;
  position: relative;
}

.pricingContainer {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: start;
  justify-content: space-between;
  padding-top: 20px;
  border-top: 1px solid var(--dark-gray4);
}