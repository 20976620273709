import { Button, Colors, NumericInput } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { useState } from "react";
import { PremiumStore } from "../../stores/PremiumStore";
import css from "./SeatsInput.module.css";

export function SeatsInput() {
  const [hasFocus, setHasFocus] = useState(false);
  const premiumStore = PremiumStore.use();

  return (
    <div className={css.container}>
      <div>
        <div className={css.prompt}>
          How many Premium seats would you like to purchase?
        </div>
        <div className={css.promptSubLabel}>
          <Tooltip2
            content={
              <>
                No single seat subscriptions are offered
                <br />
                because Zilch is better together 🎉
              </>
            }
            position="bottom"
            hoverOpenDelay={300}
            isOpen={premiumStore.section === "checkout" ? false : undefined}
          >
            <a
              style={{
                transition: "all ease .3s",
                cursor:
                  premiumStore.section === "calculate-price"
                    ? "pointer"
                    : "default",
                color:
                  premiumStore.section === "calculate-price"
                    ? Colors.BLUE5
                    : "#abb3bf",
                textDecoration:
                  premiumStore.section === "calculate-price"
                    ? undefined
                    : "none",
                fontWeight:
                  premiumStore.section === "calculate-price" ? 600 : 400,
              }}
            >
              Minimum 2
            </a>
          </Tooltip2>{" "}
          (you + friend)
        </div>
        <a
          style={{
            opacity: premiumStore.section === "calculate-price" ? 0 : 1,
          }}
          className={css.seeRecommendations}
          tabIndex={premiumStore.section === "calculate-price" ? -1 : 0}
          onClick={() => premiumStore.setSection("calculate-price")}
        >
          See recommendations
        </a>
      </div>
      <div className={css.inputContainer}>
        <Button
          icon="minus"
          large
          disabled={premiumStore.seats <= 2}
          className={css.button}
          style={{ left: "20px" }}
          onClick={() => premiumStore.setSeats(premiumStore.seats - 1)}
        />
        <Tooltip2
          content="Enter value greater than or equal to 2"
          isOpen={!hasFocus && premiumStore.seats < 2}
          placement="bottom"
        >
          <NumericInput
            large
            min={2}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            value={premiumStore.seats}
            onValueChange={(value) => {
              if (!isNaN(value) && value >= 0) {
                premiumStore.setSeats(value);
              }
            }}
            buttonPosition="none"
            className={css.input}
            intent={premiumStore.seats < 2 ? "danger" : "none"}
          />
        </Tooltip2>
        <Button
          icon="plus"
          large
          intent={
            premiumStore.section === "calculate-price" ? "primary" : "none"
          }
          className={css.button}
          style={{ right: "20px" }}
          onClick={() => premiumStore.setSeats(premiumStore.seats + 1)}
        />
      </div>
    </div>
  );
}
