import { Button, Dialog } from "@blueprintjs/core";
import { useDelay, useDelayedValue } from "@zilch/delay";
import { useRef } from "react";
import type { VerifyEmailResult } from "../../../backend/procedures/organization";
import { PremiumStore } from "../../stores/PremiumStore";
import { UserStore } from "../../stores/UserStore";
import { AuthenticatedView } from "../common/AuthenticatedView";
import { BuySubscriptionSection } from "./BuySubscriptionSection";
import { EmailCheckSection } from "./EmailCheckSection";
import { FeatureOverviewSection } from "./FeatureOverviewSection";
import css from "./GetPremium.module.css";
import { useTransitionStyle } from "./useTransitionStyle";
import { Img } from "../common/Img";
import premiumPng from "../../resources/icons/premium.png";

export function GetPremium() {
  const premiumStore = PremiumStore.use();

  return (
    <Dialog
      isOpen={premiumStore.section !== null}
      className={css.premiumDialog}
      shouldReturnFocusOnClose={false}
      portalClassName={css.premiumDialogContainer}
    >
      <Button
        icon="cross"
        className={css.premiumDialogExit}
        onClick={() => {
          premiumStore.setSection(null);
        }}
      />
      <AuthenticatedView
        actionText="Sign in / create account"
        icon={
          <div className={css.unauthenticatedIconContainer}>
            <Img src={premiumPng} className={css.unauthenticatedIcon} />
          </div>
        }
        title={
          <>
            <div className={css.unauthenticatedTitle}>Premium has it all.</div>
            <div style={{ fontWeight: 500 }}>
              All boss bots unlocked, tournament mode, multiplayer, bot avatar
              designer, leaderboard, game maker, merch discounts.
            </div>
          </>
        }
      >
        {(user) => (
          <GetPremiumAuthenticated
            alreadyHasPremium={user.likelyHasPremium || user.hasPremium}
          />
        )}
      </AuthenticatedView>
    </Dialog>
  );
}

export interface VerifyEmailState {
  result:
    | VerifyEmailResult
    | { status: "error"; email: string; message: string }
    | null;
  checkNum: number;
}

function GetPremiumAuthenticated(props: { alreadyHasPremium: boolean }) {
  const premiumStore = PremiumStore.use();
  const showContent = useDelay(100);
  const transitionContentIn = useDelay(300);

  const sectionRef = useRef(premiumStore.section);
  if (premiumStore.section !== null) {
    sectionRef.current = premiumStore.section;
  }
  const section = sectionRef.current;

  const showFeatureOverview =
    useDelayedValue(section === "feature-overview", {
      delay: 500,
    }) || section === "feature-overview";

  const isLowerSection =
    section === "email-check" ||
    section === "calculate-price" ||
    section === "checkout";

  const showEmailCheckOrBuySubscriptionOrActivateDiscount =
    useDelayedValue(isLowerSection, {
      delay: 500,
    }) || isLowerSection;

  if (!showContent) {
    return null;
  }

  return (
    <>
      {showFeatureOverview && (
        <FeatureOverviewSection
          alreadyHasPremium={props.alreadyHasPremium}
          show={section === "feature-overview" && transitionContentIn}
        />
      )}
      {showEmailCheckOrBuySubscriptionOrActivateDiscount && (
        <EmailCheckOrBuySubscriptionSection
          show={isLowerSection && transitionContentIn}
        />
      )}
    </>
  );
}

function EmailCheckOrBuySubscriptionSection(props: { show: boolean }) {
  const premiumStore = PremiumStore.use();
  const mounted = useDelay(0);

  const sectionRef = useRef(premiumStore.section);
  if (premiumStore.section !== null) {
    sectionRef.current = premiumStore.section;
  }
  const section = sectionRef.current;

  const isBuySubscriptionSection =
    section === "calculate-price" || section === "checkout";

  const showBuySubscription =
    useDelayedValue(isBuySubscriptionSection, {
      delay: 400,
    }) || isBuySubscriptionSection;

  const showEmailCheck =
    useDelayedValue(section === "email-check", {
      delay: 400,
    }) || section === "email-check";

  const userStore = UserStore.use();

  const showAboutPremiumButton =
    mounted && props.show && userStore.premiumSources.size === 0;

  return (
    <div
      className={css.emailCheckOrSubscriptionContainer}
      style={useTransitionStyle(props.show, "bottom")}
    >
      <Button
        minimal
        icon="arrow-up"
        onClick={() => premiumStore.setSection("feature-overview")}
        className={css.emailCheckOrSubscriptionUpButton}
        tabIndex={showAboutPremiumButton ? 0 : -1}
        style={{
          opacity: showAboutPremiumButton ? 1 : 0,
          transitionDelay: showAboutPremiumButton ? ".3s" : undefined,
          pointerEvents: !showAboutPremiumButton ? "none" : undefined,
        }}
      >
        <span className="bp4-text-muted">About Premium</span>
      </Button>
      {showEmailCheck && <EmailCheckSection show={section === "email-check"} />}
      {showBuySubscription && (
        <BuySubscriptionSection show={isBuySubscriptionSection} />
      )}
    </div>
  );
}
