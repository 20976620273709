import type { Intent } from "@blueprintjs/core";
import { Button, Classes } from "@blueprintjs/core";
import { plural } from "@zilch/plural";
import { DAY } from "@zilch/time";
import React from "react";
import type { OrganizationEmailWithStatus } from "../../../backend/db/organization";
import type { UserResponse } from "../../../backend/procedures/user";
import { api } from "../../api";
import { PremiumStore } from "../../stores/PremiumStore";
import { UserStore } from "../../stores/UserStore";
import { Popover } from "../common/Popover";
import { useOperation } from "../common/useOperation";
import { DetailedButton } from "./DetailedButton";
import { SubSection } from "./SubSection";

interface Props {
  user: UserResponse;
}

export function Organization(props: Props) {
  const organizationEmail = props.user.organizationEmail ?? null;
  const premiumStore = PremiumStore.use();

  return (
    <SubSection title="Organization">
      <p className="bp4-text-muted">
        Free Premium is available to students and employees of select
        organizations.{" "}
        <a href="/premium/organization" rel="noreferrer" target="_blank">
          Learn about organization plans.
        </a>
      </p>
      {!organizationEmail && (
        <Button
          onClick={() => {
            premiumStore.setSection("email-check");
          }}
        >
          Check for free access
        </Button>
      )}
      {organizationEmail && (
        <OrganizationButton organizationEmail={organizationEmail} />
      )}
    </SubSection>
  );
}

function OrganizationButton({
  organizationEmail,
}: {
  organizationEmail: OrganizationEmailWithStatus;
}) {
  const premiumStore = PremiumStore.use();
  const userStore = UserStore.use();

  let detailsIntent: Intent = "none";
  let detailsContent: React.ReactNode = null;

  if (
    organizationEmail.organizationPlanExpiration !== null &&
    organizationEmail.organizationPlanExpiration < Date.now()
  ) {
    detailsIntent = "danger";
    detailsContent = "Organization plan expired";
  } else if (organizationEmail.status === "expired") {
    detailsIntent = "danger";
    detailsContent = "Reverification due";
  } else {
    const days = Math.max(
      0,
      Math.round((Date.now() - organizationEmail.lastVerified) / DAY)
    );

    detailsIntent =
      organizationEmail.status === "expiring-soon" ? "warning" : "none";
    detailsContent = `Verified ${plural(days, "day")} ago`;
  }

  const unenrollOperation = useOperation({
    async run() {
      await api.organization.unenrollEmail.mutate();
      await userStore.query.refetch();
    },
  });

  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        gap: "10px",
        flexDirection: "column",
      }}
    >
      <DetailedButton
        title={organizationEmail.organizationName}
        titleLabel="Organization"
        icon="office"
        detailsIntent={detailsIntent}
        details={
          <>
            <div style={{ fontWeight: 600 }}>{organizationEmail.email}</div>
            <div>{detailsContent}</div>
          </>
        }
        onOpen={() => {
          premiumStore.setSection("email-check");
        }}
      />
      <Popover
        content={
          <div style={{ padding: "15px", width: "300px" }}>
            <p>Are you sure you want to unenroll {organizationEmail.email}?</p>
            <Button
              intent="danger"
              small
              onClick={unenrollOperation.run}
              className={Classes.POPOVER_DISMISS}
            >
              Unenroll
            </Button>
          </div>
        }
      >
        <Button loading={unenrollOperation.inProgress}>
          Unenroll {organizationEmail.email}
        </Button>
      </Popover>
    </div>
  );
}
