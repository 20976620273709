import {
  AnchorButton,
  Button,
  Code,
  Colors,
  FormGroup,
  InputGroup,
  Menu,
  MenuDivider,
} from "@blueprintjs/core";
import { MenuItem2 } from "@blueprintjs/popover2";
import { type BotAvatarAndColor, BotType } from "@zilch/bot-models";
import { useDelay } from "@zilch/delay";
import { useEffect, useMemo, useState } from "react";
import { api } from "../../api";
import { PromptStore } from "../../stores/PromptStore";
import { UserStore } from "../../stores/UserStore";
import { toaster } from "../../toaster";
import { validateBotName } from "../../validateBotName";
import { Popover } from "../common/Popover";
import { BotAvatarCreator } from "./BotAvatarCreator";
import {
  type BotCardProps,
  getAvatar,
  getBotName,
  getColor,
  type MaybeTransitionBotConfig,
} from "./BotCard";
import { GuideContent } from "./GuideContent";
import { SiGithub } from "react-icons/si";
import { MenuTag } from "../common/MenuTag";
import type { Tip } from "../playbook/Playbook";
import { usePlaybook } from "../playbook/Playbook";
import { useStorage } from "../../useStorage";

export function UserBotMenu(props: BotCardProps) {
  const prompt = PromptStore.usePrompt();
  const avatar = getAvatar(props.botConfig);
  const preferredColor = getColor(props.botConfig);
  const name = getBotName(props.botConfig);

  const owner =
    props.botConfig.slotSelection?.transitionData.owner ??
    (!props.botConfig.transition && props.botConfig.value.type === BotType.User
      ? props.botConfig.value.owner
      : "");
  const repo = getRepo(props.botConfig);

  const userStore = UserStore.use();
  const isOwner = userStore.query.isSuccess
    ? userStore.query.data.type === "authenticated" &&
      owner === userStore.query.data.likelyLogin
    : false;

  const setupGuideOpenDelay = useDelay(300);
  const [isSetupGuideOpen, setIsSetupGuideOpen] = useState(false);

  const isUnimplemented = props.setupGuide?.type == "new";
  const isOffline = props.setupGuide?.type == "offline";

  useEffect(() => {
    if (isUnimplemented || isOffline) {
      setIsSetupGuideOpen(true);
    }
  }, [isUnimplemented, isOffline]);

  const playbook = usePlaybook();
  const storage = useStorage();

  let newBotTip: Omit<Tip, "target"> | null = null;
  if (isUnimplemented && avatar && name && owner && repo) {
    newBotTip = {
      avatar: avatar,
      botName: name,
      owner: owner,
      preferredColor: preferredColor,
      repo: repo,
      type: "building-your-first-bot",
    };
  }

  return (
    <Menu large style={{ width: "230px" }}>
      <MenuItem2
        icon={<SiGithub />}
        text="GitHub Repository"
        href={`https://github.com/${owner}/${repo}`}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => {
          if (!newBotTip || storage.dontShowNewBotTutorial.get()) {
            return;
          }

          e.preventDefault();

          playbook.setSection({
            name: "building-your-first-bot",
            tip: { ...newBotTip, target: "github" },
          });
        }}
      />
      {!isOwner && (
        <Popover
          background="blue"
          onClose={() => setIsSetupGuideOpen(false)}
          isOpen={isSetupGuideOpen && setupGuideOpenDelay}
          fill
          position="left"
          ref={(popover) => {
            props.onSetRepositionPopover?.(popover?.reposition ?? null);
          }}
          content={
            <GuideContent
              title={
                <>
                  Ask {owner || "the author"} to run{" "}
                  <Code style={{ color: Colors.WHITE }}>./connect</Code> for
                  this bot.
                </>
              }
              message="For more options on how to start the game read the multiplayer setup guide."
            />
          }
        >
          <MenuItem2
            icon="people"
            text="Multiplayer"
            href="/docs/multiplayer"
            target="_blank"
            style={{
              outline: isSetupGuideOpen
                ? `2px solid ${Colors.BLUE4}`
                : undefined,
            }}
            labelElement={<MenuTag>Setup Guide</MenuTag>}
          />
        </Popover>
      )}
      {isOwner && (
        <>
          <Popover
            background="blue"
            onClose={() => setIsSetupGuideOpen(false)}
            isOpen={isSetupGuideOpen && setupGuideOpenDelay}
            fill
            position="left"
            ref={(popover) => {
              props.onSetRepositionPopover?.(popover?.reposition ?? null);
            }}
            content={
              <GuideContent
                title={
                  isOffline ? "Offline" : isUnimplemented ? "New bot!" : ""
                }
                message={`Follow ${
                  props.botConfig.transition
                    ? "this bot"
                    : props.botConfig.value.name
                }'s setup instructions to get up and running.`}
              />
            }
          >
            <MenuItem2
              icon="application"
              text="Codespace"
              labelElement={<MenuTag>Quick Start</MenuTag>}
              style={{
                outline: isSetupGuideOpen
                  ? `2px solid ${Colors.BLUE4}`
                  : undefined,
              }}
              href={`https://codespaces.new/${owner}/${repo}?quickstart=1`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => {
                if (!newBotTip || storage.dontShowNewBotTutorial.get()) {
                  return;
                }

                e.preventDefault();

                playbook.setSection({
                  name: "building-your-first-bot",
                  tip: { ...newBotTip, target: "codespace" },
                });
              }}
            />
          </Popover>
          <MenuDivider />
          <MenuItem2
            icon="grid-view"
            text="Edit Avatar"
            onClick={async () => {
              const value = await prompt<BotAvatarAndColor>(
                (props) => {
                  return (
                    <EditAvatar
                      initialValue={{ avatar, preferredColor }}
                      onDone={props.resolve}
                    />
                  );
                },
                {
                  lowerZIndex: true,
                }
              );

              if (
                value === null ||
                props.botConfig.transition ||
                props.botConfig.value.type !== BotType.User
              ) {
                return;
              }

              const updateAvatarOperation = api.bot.updateAvatar
                .mutate({
                  owner: props.botConfig.value.owner,
                  repo: props.botConfig.value.repo,
                  avatar: value.avatar,
                  preferredColor: value.preferredColor,
                })
                .catch((error) => {
                  toaster.show({
                    intent: "danger",
                    message: `Error encountered updating bot avatar: ${
                      error instanceof Error
                        ? error.message
                        : "unexpected problem"
                    }`,
                  });
                });

              props.onInvalidateConfig?.("avatar", updateAvatarOperation, {
                ...props.botConfig.value,
                avatar: value.avatar,
                preferredColor: value.preferredColor,
              });
            }}
          />
          <MenuItem2
            icon="text-highlight"
            text="Rename"
            onClick={async () => {
              const newName = await prompt<string | null>(({ resolve }) => {
                return <RenameBot initialValue={name} onRename={resolve} />;
              });

              if (
                newName === null ||
                newName === name ||
                props.botConfig.transition ||
                props.botConfig.value.type !== BotType.User
              ) {
                return;
              }

              const renameOp = api.bot.renameBot
                .mutate({
                  owner: props.botConfig.value.owner,
                  repo: props.botConfig.value.repo,
                  name: newName,
                })
                .catch((error) => {
                  toaster.show({
                    intent: "danger",
                    message: `Error encountered updating bot name: ${
                      error instanceof Error
                        ? error.message
                        : "unexpected problem"
                    }`,
                  });
                });

              props.onInvalidateConfig?.("rename", renameOp, {
                ...props.botConfig.value,
                name: newName,
              });
            }}
          />
          <MenuItem2
            icon="trash"
            text="Delete"
            onClick={async () => {
              let owner: string;
              let repo: string;

              if (
                props.botConfig.transition &&
                props.botConfig.slotSelection.type === BotType.User
              ) {
                owner = props.botConfig.slotSelection.owner;
                repo = props.botConfig.slotSelection.repo;
              } else if (
                !props.botConfig.transition &&
                props.botConfig.value.type === BotType.User
              ) {
                owner = props.botConfig.value.owner;
                repo = props.botConfig.value.repo;
              } else {
                return;
              }

              const result = await prompt<void>((props) => {
                return (
                  <DeleteBot
                    owner={owner}
                    repo={repo}
                    botName={name}
                    onDone={props.resolve}
                  />
                );
              });

              if (result === null) {
                return;
              }

              props.onInvalidateConfig?.("removal");
            }}
          />
        </>
      )}
    </Menu>
  );
}

function RenameBot({
  initialValue,
  onRename,
}: {
  initialValue: string;
  onRename(value: string): void;
}) {
  const [value, setValue] = useState(initialValue);
  const error = useMemo<string | null>(() => {
    return validateBotName(value.trim()).problems[0] ?? null;
  }, [value]);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (error) return;
        onRename(value);
      }}
    >
      <FormGroup
        label={
          <>
            Rename <b>{initialValue}</b>
          </>
        }
        style={{ marginBottom: "3px", padding: "20px" }}
        intent={error ? "danger" : "none"}
        helperText={error ? error : "Press the escape key to cancel"}
      >
        <InputGroup
          large
          intent={error ? "danger" : "none"}
          value={value}
          autoFocus
          onChange={(e) => {
            setValue(e.target.value);
          }}
          rightElement={
            <Button
              intent={error ? "none" : "primary"}
              disabled={!!error}
              type="submit"
            >
              Rename
            </Button>
          }
        />
      </FormGroup>
    </form>
  );
}

function EditAvatar({
  initialValue,
  onDone,
}: {
  initialValue: BotAvatarAndColor;
  onDone(value: BotAvatarAndColor | null): void;
}) {
  const [value, setValue] = useState(initialValue);
  return (
    <div style={{ paddingTop: "20px" }}>
      <BotAvatarCreator
        avatar={value.avatar}
        preferredColor={value.preferredColor}
        onDone={() => {
          onDone(value);
        }}
        onUpdateAvatar={(avatar, preferredColor) => {
          setValue({ avatar, preferredColor });
        }}
      />
    </div>
  );
}

function DeleteBot({
  botName,
  owner,
  repo,
  onDone,
}: {
  botName: string;
  owner: string;
  repo: string;
  onDone(): void;
}) {
  const [navigatedToGitHub, setNavigatedToGitHub] = useState(false);

  return (
    <div style={{ padding: "20px" }}>
      <p
        className="bp4-text-large"
        style={{ marginBottom: "10px", fontWeight: 700 }}
      >
        Delete {botName} on GitHub
      </p>
      <p className="bp4-text-large" style={{ marginBottom: "20px" }}>
        After navigating to GitHub scroll to the bottom of the repository
        settings page and click "Delete this repository". When finished return
        here.
      </p>
      {navigatedToGitHub ? (
        <Button large fill onClick={onDone} intent="primary">
          Done
        </Button>
      ) : (
        <AnchorButton
          large
          fill
          intent="danger"
          target="_blank"
          rel="noreferrer"
          href={`https://github.com/${owner}/${repo}/settings`}
          onClick={() => setNavigatedToGitHub(true)}
          rightIcon="arrow-right"
        >
          Delete on GitHub
        </AnchorButton>
      )}
    </div>
  );
}

function getRepo(botConfig: MaybeTransitionBotConfig) {
  if (botConfig.slotSelection?.type === BotType.User) {
    return botConfig.slotSelection.repo;
  }

  if (!botConfig.transition && botConfig.value.type === BotType.User) {
    return botConfig.value.repo;
  }

  return "";
}
