import css from "./SecondaryScreen.module.css";

interface Props {
  title: string;
  children: React.ReactNode;
}

export function SecondaryScreen(props: Props) {
  return (
    <div className={css.container}>
      <div className={css.content}>
        <div className={css.header}>{props.title}</div>
        {props.children}
      </div>
    </div>
  );
}
