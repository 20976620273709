import { createSvgPaths } from "@zilch/bot-avatar";
import React, { useEffect, useState } from "react";
import css from "./index.module.css";

interface BotAvatarSvgProps {
  size: number;
  avatar: string;
  color: string;
  className?: string;
  backgroundOpacity?: number;
  disableCentering?: boolean;
  foregroundColor?: string;
  onClick?: () => void;
}

export const BotAvatarSvg = React.memo(
  ({
    avatar,
    color,
    size,
    className,
    backgroundOpacity,
    disableCentering,
    foregroundColor,
    onClick,
  }: BotAvatarSvgProps) => {
    const [shapes, setShapes] = useState<{
      foreground: string[];
      background: string[];
    } | null>(null);

    useEffect(() => {
      const animationFrame = requestAnimationFrame(() => {
        setShapes(createSvgPaths({ avatar, center: !disableCentering }));
      });
      return () => cancelAnimationFrame(animationFrame);
    }, [avatar, disableCentering]);

    return (
      <svg
        viewBox="0 0 12 12"
        style={{ width: size, height: size }}
        className={className}
        onClick={onClick}
      >
        {shapes?.background.map((shape, index) => (
          <Path
            key={index}
            path={shape}
            color={color}
            opacity={backgroundOpacity}
          />
        )) ?? null}
        {shapes?.foreground.map((shape, index) => (
          <Path key={index} path={shape} color={foregroundColor ?? "#ffffff"} />
        )) ?? null}
      </svg>
    );
  }
);

function Path({
  path,
  color,
  opacity,
}: {
  path: string;
  color: string;
  opacity?: number;
}) {
  return (
    <path
      fillRule="evenodd"
      d={path}
      fill={color}
      className={css.path}
      opacity={opacity}
    />
  );
}
