import {
  Dialog,
  FormGroup,
  InputGroup,
  TagInput,
  Button,
  Classes,
} from "@blueprintjs/core";
import { useQuery } from "@tanstack/react-query";
import { classes } from "@zilch/css-utils";
import { panic } from "@zilch/panic";
import { format } from "date-fns";
import { useState, useRef, useEffect, useMemo } from "react";
import { api } from "../../api";
import type { OrgId } from "./OrganizationList";
import css from "./OrganizationDialog.module.css";
import { Popover } from "../common/Popover";

export function OrganizationDialog(props: { orgId: OrgId; onClose(): void }) {
  const existingOrganizationQuery = useQuery(
    [
      "admin.getOrganization",
      props.orgId?.type === "existing" ? props.orgId.id : null,
    ],
    () => {
      return api.admin.getOrganization.query({
        organizationId:
          props.orgId?.type === "existing"
            ? props.orgId.id
            : panic("Unexpected"),
      });
    },
    {
      enabled: props.orgId?.type === "existing",
    }
  );

  const [creatingOrUpdating, setCreatingOrUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const disabled =
    (props.orgId?.type === "existing" &&
      !existingOrganizationQuery.isSuccess) ||
    creatingOrUpdating;

  const [organizationName, setOrganizationName] = useState("");
  const [planExpiration, setPlanExpiration] = useState<number | null>(null);
  const [domains, setDomains] = useState<string[]>([]);

  const existingOrganizationQueryRef = useRef(existingOrganizationQuery);
  existingOrganizationQueryRef.current = existingOrganizationQuery;

  const existingOrganizationDataRetrieved =
    props.orgId?.type === "existing" &&
    existingOrganizationQuery.isSuccess &&
    existingOrganizationQuery.data.organizationId === props.orgId.id;

  useEffect(() => {
    const data = existingOrganizationQueryRef.current.data;
    if (!existingOrganizationDataRetrieved || !data) {
      return;
    }

    setOrganizationName(data.name);
    setPlanExpiration(data.planExpiration);
    setDomains(data.domains);
  }, [existingOrganizationDataRetrieved]);

  const isOpen = props.orgId !== null;

  useEffect(() => {
    if (!isOpen) {
      setOrganizationName("");
      setPlanExpiration(null);
      setDomains([]);
    }
  }, [isOpen]);

  const formattedPlanExpiration = useMemo(() => {
    if (planExpiration === null) {
      return "";
    }

    return format(planExpiration, "yyyy-MM-dd'T'HH:mm");
  }, [planExpiration]);

  async function createOrUpdate() {
    if (!props.orgId) {
      return;
    }

    if (organizationName.trim().length === 0) {
      alert("Organization name field missing.");
      return;
    }

    try {
      setCreatingOrUpdating(true);
      if (props.orgId.type === "new") {
        await api.admin.createOrganization.mutate({
          name: organizationName,
          planExpiration,
          domains,
        });
      } else {
        await api.admin.updateOrganization.mutate({
          organizationId: props.orgId.id,
          name: organizationName,
          planExpiration,
          domains,
        });
        existingOrganizationQuery.remove();
      }
    } catch (error) {
      console.error(error);
      alert("Unexpected problem encountered. Check console for details.");
      setCreatingOrUpdating(false);
      return;
    }

    setCreatingOrUpdating(false);
    props.onClose();
  }

  const onDelete = () => {
    if (props.orgId?.type !== "existing") {
      return;
    }
    setDeleting(true);
    api.admin.deleteOrganization
      .mutate({ organizationId: props.orgId.id })
      .then(() => {
        props.onClose();
      })
      .catch((error) => {
        console.error(error);
        alert(
          "Unexpected problem deleting encountered. Check console for details."
        );
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const onPlanExpirationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let expirationAsNumber = null;
    try {
      if (e.target.value.length > 0) {
        expirationAsNumber = new Date(e.target.value).valueOf();
        if (isNaN(expirationAsNumber)) {
          expirationAsNumber = null;
        }
      }
    } catch {}
    setPlanExpiration(expirationAsNumber);
  };

  return (
    <Dialog
      isOpen={props.orgId !== null}
      title={
        props.orgId?.type === "new"
          ? `Create Organization`
          : `Update Organization`
      }
      icon="office"
      onClose={props.onClose}
    >
      <div className={css.orgDialog}>
        <FormGroup label="Organization Name">
          <InputGroup
            disabled={disabled}
            placeholder="Zilch"
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
          />
        </FormGroup>
        <FormGroup label="Plan Expiration">
          <InputGroup
            type="datetime-local"
            disabled={disabled}
            value={formattedPlanExpiration}
            onChange={onPlanExpirationChange}
          />
        </FormGroup>
        <FormGroup label="Domains">
          <TagInput
            values={domains}
            placeholder="e.g. zilch.dev"
            onChange={(values) =>
              setDomains(
                values.map(
                  (v) => v?.toString() ?? panic("unexpected nullish value")
                )
              )
            }
            disabled={disabled}
            tagProps={{ minimal: true }}
          />
        </FormGroup>
      </div>
      <div className={classes("bp4-dialog-footer", css.orgDialogFooter)}>
        <div>
          {props.orgId?.type === "existing" && (
            <Popover
              placement="bottom"
              content={
                <div className={css.deleteConfirmContainer}>
                  <p>Are you sure?</p>
                  <div className={css.deleteConfirmFooter}>
                    <Button small className={Classes.POPOVER_DISMISS}>
                      Cancel
                    </Button>
                    <Button
                      small
                      intent="danger"
                      className={Classes.POPOVER_DISMISS}
                      onClick={onDelete}
                    >
                      Yes, delete organization
                    </Button>
                  </div>
                </div>
              }
            >
              <Button minimal intent="danger" loading={deleting}>
                Delete organization
              </Button>
            </Popover>
          )}
        </div>

        <div className={css.orgDialogFooterRight}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button
            intent="primary"
            disabled={disabled}
            type="submit"
            loading={creatingOrUpdating}
            onClick={createOrUpdate}
          >
            {props.orgId?.type === "new" ? "Create" : "Update"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
