import { Colors, Menu } from "@blueprintjs/core";
import { BotAvatarSvg } from "@zilch/bot-avatar-svg";
import {
  BotColor,
  type BotConfig,
  BotType,
  type TransitionSlotSelection,
  zilchBotName,
  type UserBotConfig,
  BotOutcome,
} from "@zilch/bot-models";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "../common/Card";
import css from "./BotCard.module.css";
import { SiGithub } from "react-icons/si";
import { useLanguages } from "./useLanguages";
import type { GameId } from "@zilch/game-config";
import { Popover } from "../common/Popover";
import { UserBotMenu } from "./UserBotMenu";
import { useDelayedValue } from "@zilch/delay";
import { UserStore } from "../../stores/UserStore";
import { StarStore } from "../../stores/StarStore";
import { BossBotMenu } from "./BossBotMenu";
import { GuideContent } from "./GuideContent";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import { Reveal } from "../common/Reveal";
import { usePlaybook } from "../playbook/Playbook";
import { ClickMeFinger } from "../common/ClickMeFinger";

export type MaybeTransitionBotConfig =
  | {
      transition: false;
      value: BotConfig;
      slotSelection: TransitionSlotSelection;
    }
  | {
      transition: true;
      slotSelection: NonNullable<TransitionSlotSelection>;
    };

export interface BotCardProps {
  botConfig: MaybeTransitionBotConfig;
  botOutcome?: BotOutcome;
  color?: BotColor;
  onSelect?(
    transitionOrigin: { x: number; y: number } | null,
    bossDifficulty: "easy" | "medium" | "hard" | null
  ): void;
  isSelected?: boolean;
  onSetDifficulty?(difficulty: "easy" | "medium" | "hard"): void;
  onInvalidateConfig?(
    reason: "rename" | "removal" | "avatar",
    operation?: Promise<void>,
    newConfig?: UserBotConfig
  ): void;
  setupGuide?: {
    type: "new" | "offline";
    openImmediately: boolean;
  };
  showBossBotPrompt?: boolean | "immediately";
  gameId: GameId;
  onSetRepositionPopover?(reposition: (() => void) | null): void;
  onSwitchForBossBot?(): void;
  isGameDone?: boolean;
}

export function BotCard(props: BotCardProps) {
  const avatar = getAvatar(props.botConfig);
  const color = getColor(props.botConfig, props.color);
  const name = getBotName(props.botConfig);

  const type = props.botConfig.transition
    ? props.botConfig.slotSelection.type
    : props.botConfig.value.type;

  const [id] = useState(() => "id-" + crypto.randomUUID());

  const userStore = UserStore.use();
  const login =
    userStore.query.isSuccess && userStore.query.data.type === "authenticated"
      ? userStore.query.data.likelyLogin
      : null;

  const repositionChildPopoverRef = useRef<(() => void) | null>(null);

  const onSetRepositionPopoverRef = useRef(props.onSetRepositionPopover);
  onSetRepositionPopoverRef.current = props.onSetRepositionPopover;

  useEffect(() => {
    return () => {
      onSetRepositionPopoverRef.current?.(null);
    };
  }, []);

  let content;

  if (type === BotType.Boss) {
    content = (
      <BossBotMenu
        botConfig={props.botConfig}
        onSetDifficulty={(difficulty) => {
          if (props.onSelect) {
            const rect = document.getElementById(id)?.getBoundingClientRect();
            props.onSelect(rect ? { x: rect.x, y: rect.y } : null, difficulty);
          } else {
            props.onSetDifficulty?.(difficulty);
          }
        }}
      />
    );
  } else if (type === BotType.Practice) {
    if (props.showBossBotPrompt) {
      content = (
        <Reveal>
          {(text) => (
            <GuideContent
              title={text("Practice bot says...")}
              action={{
                text: "Play Boss Bot",
                onClick: () => props.onSwitchForBossBot?.(),
              }}
              message={
                <>
                  {text(
                    "Nice win" +
                      (userStore.query.data?.type === "authenticated"
                        ? ""
                        : "!")
                  )}
                  {userStore.query.data?.type === "authenticated" ? (
                    <>
                      {" "}
                      <span style={{ fontWeight: 600 }}>
                        {text(userStore.query.data.likelyLogin + "!")}
                      </span>
                    </>
                  ) : null}{" "}
                  {text("I think you're ready to take on the Boss bot.")}
                </>
              }
            />
          )}
        </Reveal>
      );
    } else {
      content = (
        <Menu large style={{ width: "230px" }}>
          <BotInfo
            title="Practice bot says..."
            content={
              <>
                Hello
                {login ? (
                  <>
                    {" "}
                    <b>{login}</b>
                  </>
                ) : null}
                ! I'm a great training partner when you're building new bots.
              </>
            }
          />
        </Menu>
      );
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (type === BotType.User) {
    content = (
      <UserBotMenu
        {...props}
        onSetRepositionPopover={(reposition) => {
          repositionChildPopoverRef.current = reposition;
        }}
      />
    );
  }

  const [isMenuOpen, setIsMenuOpen] = useState<
    "user-interaction" | "auto-open" | false
  >(false);

  useEffect(() => {
    if (
      props.setupGuide?.openImmediately ||
      props.showBossBotPrompt === "immediately"
    ) {
      setIsMenuOpen("auto-open");
    }
  }, [props.setupGuide?.openImmediately, props.showBossBotPrompt]);

  const isMenuOpenDelayed =
    (useDelayedValue(isMenuOpen !== false, {
      delay: 700,
    }) &&
      isMenuOpen !== false) ||
    isMenuOpen === "user-interaction";

  const shouldShowUnimplementedIndicator =
    props.botOutcome === BotOutcome.Unimplemented &&
    !(type !== "boss" && props.onSelect ? false : isMenuOpen);

  const showUnimplementedIndicator: boolean =
    useDelayedValue(shouldShowUnimplementedIndicator, { delay: 200 }) &&
    shouldShowUnimplementedIndicator;

  const playbook = usePlaybook();

  return (
    <Popover
      placement="top"
      onInteraction={(value) => {
        if (!props.isSelected) {
          setIsMenuOpen(value ? "user-interaction" : false);
        }
      }}
      ref={(popover) => {
        if (props.onSelect) {
          return;
        }
        props.onSetRepositionPopover?.(() => {
          if (isMenuOpenDelayed) {
            popover?.reposition();
            repositionChildPopoverRef.current?.();
          }
        });
      }}
      background={props.showBossBotPrompt ? "blue" : undefined}
      content={content}
      isOpen={type !== "boss" && props.onSelect ? false : isMenuOpenDelayed}
    >
      <Card
        id={id}
        className={css.container}
        interactive
        tabIndex={0}
        onClick={() => {
          if ((type !== "boss" || props.isSelected) && props.onSelect) {
            const rect = document.getElementById(id)?.getBoundingClientRect();
            props.onSelect(rect ? { x: rect.x, y: rect.y } : null, null);
          }
        }}
      >
        <ClickMeFinger
          left={-19}
          bottom={-38}
          show={
            (showUnimplementedIndicator ||
              (!!props.showBossBotPrompt &&
                !isMenuOpen &&
                !!props.isGameDone)) &&
            playbook.section.name === "none" &&
            !playbook.emphasizeStartGame
          }
        />
        <BotAvatarSvg
          size={36}
          avatar={avatar}
          color={BotColor[color]}
          className={css.avatar}
        />
        <div className={css.name} title={name}>
          {name}
        </div>
        <div className={css.footer}>
          <span className={classes(css.footerElement, "bp4-text-muted")}>
            <BottomLeftContent
              botConfig={props.botConfig}
              gameId={props.gameId}
            />
          </span>
          <span className={classes(css.footerElement, "bp4-text-muted")}>
            <BottomRightContent botConfig={props.botConfig} />
          </span>
        </div>
      </Card>
    </Popover>
  );
}

function BottomLeftContent({
  botConfig,
  gameId,
}: {
  botConfig: MaybeTransitionBotConfig;
  gameId: GameId;
}) {
  const starStore = StarStore.use();
  const starsWon = starStore.getStarCount(gameId);

  const type = botConfig.transition
    ? botConfig.slotSelection.type
    : botConfig.value.type;

  if (type === BotType.User) {
    let owner = "";
    if (botConfig.transition) {
      owner = botConfig.slotSelection.transitionData.owner ?? "";
    } else {
      owner =
        botConfig.value.type === BotType.User ? botConfig.value.owner : "";
    }

    if (!owner) {
      return null;
    }

    return (
      <span
        key={owner}
        className={css.fadeIn}
        style={{ display: "flex", alignItems: "center", gap: "5px" }}
      >
        <SiGithub />
        {owner}
      </span>
    );
  } else if (type === BotType.Practice) {
    return <>Moves randomly</>;
  } else {
    return (
      <>
        <b
          style={{ color: starsWon === 0 ? Colors.GRAY4 : Colors.GRAY5 }}
          key={starsWon}
          className={css.fadeIn}
        >
          {starsWon} / 3
        </b>{" "}
        stars won
      </>
    );
  }
}

function useLanguageName(languageId: string | null) {
  const languages = useLanguages();

  if (languageId !== null && languages.isSuccess) {
    const parts = languageId.split(".");
    const maybeLanguageName = languages.data[parts[0]!]?.name ?? parts[0] ?? "";
    if (maybeLanguageName) {
      return maybeLanguageName;
    }
  }

  return null;
}

function BottomRightContent({
  botConfig,
}: {
  botConfig: MaybeTransitionBotConfig;
}) {
  const type = botConfig.transition
    ? botConfig.slotSelection.type
    : botConfig.value.type;

  const languageName = useLanguageName(
    botConfig.transition
      ? botConfig.slotSelection.transitionData.language ?? null
      : botConfig.value.language
  );

  if (type === BotType.User) {
    if (languageName) {
      return <>{languageName}</>;
    }

    return null;
  } else if (type === BotType.Boss) {
    const difficulty =
      botConfig.slotSelection?.type === BotType.Boss
        ? botConfig.slotSelection.difficulty
        : null;

    if (difficulty === null) {
      return null;
    }

    return (
      <span className={transitionInFromCss.bottom} key={difficulty}>
        {{ easy: "Easy", medium: "Medium", hard: "Hard" }[difficulty]}
      </span>
    );
  } else {
    return null;
  }
}

function BotInfo({
  title,
  content,
}: {
  title: string;
  content?: React.ReactNode;
}) {
  return (
    <div style={{ padding: "5px" }}>
      <div style={{ fontSize: "14px", fontWeight: 600 }}>{title}</div>
      {content && (
        <div
          className="bp4-text-muted"
          style={{
            fontWeight: 400,
            whiteSpace: "normal",
            marginTop: "3px",
            fontSize: "14px",
          }}
        >
          {content}
        </div>
      )}
    </div>
  );
}

export function getColor(
  botConfig: MaybeTransitionBotConfig,
  color?: BotColor
) {
  if (color) {
    return color;
  }

  if (botConfig.transition) {
    return botConfig.slotSelection.color;
  }

  return botConfig.slotSelection?.color ?? botConfig.value.preferredColor;
}

export function getAvatar(botConfig: MaybeTransitionBotConfig) {
  if (botConfig.transition) {
    return botConfig.slotSelection.transitionData.avatar ?? "";
  }

  return (
    botConfig.slotSelection?.transitionData.avatar ?? botConfig.value.avatar
  );
}

export function getBotName(botConfig: MaybeTransitionBotConfig) {
  let botName: string | undefined;

  if (botConfig.slotSelection) {
    if (botConfig.slotSelection.type !== BotType.User) {
      botName = zilchBotName[botConfig.slotSelection.type];
    } else {
      botName = botConfig.slotSelection.transitionData.name;
    }
  }

  if (botName) {
    return botName;
  }

  if (!botConfig.transition) {
    return botConfig.value.name;
  }

  if (botConfig.slotSelection.type !== BotType.User) {
    return zilchBotName[botConfig.slotSelection.type];
  }

  return botConfig.slotSelection.owner + "/" + botConfig.slotSelection.repo;
}
