import type { IconName, Intent, MaybeElement } from "@blueprintjs/core";
import newBotSrc from "../../resources/newBot.gif";
import connectSrc from "../../resources/connect.gif";
import onlineSrc from "../../resources/online.png";
import {
  Colors,
  Button,
  Checkbox,
  AnchorButton,
  Code,
  Classes,
  Icon,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { Reveal } from "../common/Reveal";
import type { TransitionState } from "../tournament/DataTransition";
import { DataTransition } from "../tournament/DataTransition";
import type { Tip } from "./Playbook";
import { usePlaybook } from "./Playbook";
import { SiGithub } from "react-icons/si";
import { Popover } from "../common/Popover";
import { ClickMeFinger } from "../common/ClickMeFinger";
import { useStorage } from "../../useStorage";
import { VideoLink } from "../common/VideoLink";
import { buildingFirstBotVideoRelativeLink } from "@zilch/video-links";

const articleLink = "/building-your-first-zilch-bot";

function PrimaryAction(props: {
  icon: IconName | MaybeElement;
  intent?: Intent;
  actionText: string;
  helperText: React.ReactNode;
  href?: string;
  target?: string;
  rel?: string;
  onClick?(): void;
  renderAction?: (action: React.ReactNode) => React.ReactNode;
}) {
  const action = (
    <AnchorButton
      fill
      large
      intent={props.intent ?? "primary"}
      alignText="left"
      style={{ fontWeight: 600, height: "60px", fontSize: "18px" }}
      icon={props.icon}
      onClick={props.onClick}
      href={props.href}
      target={props.target}
      rel={props.rel}
    >
      {props.actionText}
    </AnchorButton>
  );

  return (
    <div
      style={{
        padding: "10px",
        borderRadius: "5px",
        margin: "10px",
        background: Colors.DARK_GRAY2,
      }}
    >
      {props.renderAction?.(action) ?? action}
      <div
        style={{
          padding: "10px 5px 0px 5px",
          fontWeight: 500,
        }}
        className="bp4-text-muted"
      >
        {props.helperText}
      </div>
    </div>
  );
}

function IntroSection({
  transitionState,
  tip,
  onNextSection,
  dontAskAgain,
  onSetDontAskAgain,
}: {
  transitionState: TransitionState;
  tip: Tip;
  onNextSection(): void;
  dontAskAgain: boolean;
  onSetDontAskAgain(value: boolean): void;
}) {
  return (
    <Section transitionState={transitionState} offscreenPosition="above">
      <div>
        <Reveal>
          {(text) => (
            <div
              style={{
                fontSize: "26px",
                fontWeight: 700,
                padding: "20px",
              }}
            >
              {text(
                "■■■Interested in a helpful tutorial on programming your new bot?"
              )}
            </div>
          )}
        </Reveal>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            paddingTop: "5px",
            flexDirection: "column",
          }}
        >
          <VideoLink
            href={buildingFirstBotVideoRelativeLink}
            target="_blank"
            rel="noreferrer"
            onClick={onNextSection}
            thumbnailSrc={newBotSrc}
            aspectRatio={210 / 350}
            maxWidth="350px"
            videoLength="23:12"
          />
          <div style={{ width: "330px", fontWeight: 500 }}>
            Don't want to watch?{" "}
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={articleLink} target="_blank" onClick={onNextSection}>
              Read it instead.
            </a>
          </div>
        </div>
      </div>
      <div>
        <PrimaryAction
          actionText="Launch Tutorial"
          helperText={
            <>
              Once you've finished the tutorial return here to start programming{" "}
              {tip.botName}.
            </>
          }
          icon={<Icon icon="rocket" size={20} />}
          renderAction={(action) => {
            return (
              <Popover
                fill
                matchTargetWidth
                content={
                  <div
                    style={{
                      padding: "5px",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "5px",
                    }}
                  >
                    <AnchorButton
                      large
                      minimal
                      icon="video"
                      className={Classes.POPOVER_DISMISS}
                      target="_blank"
                      href={buildingFirstBotVideoRelativeLink}
                      rel="noreferrer"
                      onClick={onNextSection}
                    >
                      <span style={{ fontWeight: 600 }}>Watch</span>
                    </AnchorButton>
                    <AnchorButton
                      large
                      minimal
                      className={Classes.POPOVER_DISMISS}
                      icon="manual"
                      target="_blank"
                      href={articleLink}
                      onClick={onNextSection}
                    >
                      <span style={{ fontWeight: 600 }}>Read</span>
                    </AnchorButton>
                  </div>
                }
                position="top"
              >
                {action}
              </Popover>
            );
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "20px 10px 10px 20px",
          }}
        >
          <Checkbox
            label="Don't ask again"
            checked={dontAskAgain}
            onChange={() => onSetDontAskAgain(!dontAskAgain)}
            large
            style={{ marginBottom: "0px" }}
          />
          <Button outlined large onClick={onNextSection}>
            Maybe later
          </Button>
        </div>
      </div>
    </Section>
  );
}

function Section({
  transitionState,
  offscreenPosition,
  children,
}: {
  transitionState: TransitionState;
  offscreenPosition: "above" | "below";
  children: React.ReactNode;
}) {
  const playbook = usePlaybook();

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        flexDirection: "column",
        justifyContent: "space-between",
        display: "flex",
        left: 0,
        right: 0,
        bottom: 0,
        gap: "30px",
        padding: "14px",
        paddingRight: "0px",
        paddingTop: "39px",
        overflowY: "scroll",
        transition: "all cubic-bezier(0.5, 1.3, 0.5, 1) 0.4s",
        opacity: transitionState === "entered" ? 1 : 0,
        transform: `translateY(${
          transitionState === "entered"
            ? "0%"
            : offscreenPosition === "above"
            ? "-100%"
            : "100%"
        }) scale(${transitionState === "entered" ? 1 : 0.98})`,
      }}
    >
      <Button
        minimal
        icon="chevron-left"
        style={{ position: "absolute", left: "14px", top: "14px" }}
        onClick={() =>
          playbook.setSection((section) => {
            return { ...section, name: "overview" };
          })
        }
      >
        All resources
      </Button>

      {children}
    </div>
  );
}

function OpenBotAction({
  tip,
  navigatedToBotCode,
  onNavigatedToBotCode,
  intent,
}: {
  tip: Tip;
  intent?: Intent;
  onNavigatedToBotCode(): void;
  navigatedToBotCode: boolean;
}) {
  return (
    <PrimaryAction
      intent={intent}
      actionText={
        tip.target === "codespace" ? "Open Codespace" : "Open GitHub Repository"
      }
      icon={tip.target === "codespace" ? "application" : <SiGithub />}
      renderAction={(action) => {
        return (
          <div style={{ position: "relative" }}>
            {action}
            <ClickMeFinger
              left={-42}
              bottom={-47}
              enterDelay={2600}
              show={!navigatedToBotCode}
            />
          </div>
        );
      }}
      helperText={
        tip.target === "codespace" ? (
          <>
            If you reach the 60 hour free-tier Codespaces usage limit (or just
            prefer local development){" "}
            <a href="https://git-scm.com/" target="_blank" rel="noreferrer">
              git clone
            </a>{" "}
            the{" "}
            <a
              href={`https://github.com/${tip.owner}/${tip.repo}`}
              target="_blank"
              rel="noreferrer"
              onClick={onNavigatedToBotCode}
            >
              repository
            </a>{" "}
            onto your machine.
          </>
        ) : (
          <>
            <a
              href="https://docs.github.com/en/repositories/creating-and-managing-repositories/cloning-a-repository"
              target="_blank"
              rel="noreferrer"
            >
              Git clone
            </a>{" "}
            {tip.botName}'s repository then open <Code>README.md</Code> to get
            started.
          </>
        )
      }
      href={
        tip.target === "github"
          ? `https://github.com/${tip.owner}/${tip.repo}`
          : `https://codespaces.new/${tip.owner}/${tip.repo}?quickstart=1`
      }
      target="_blank"
      rel="noreferrer"
      onClick={onNavigatedToBotCode}
    />
  );
}

function OpenBotSection({
  transitionState,
  tip,
}: {
  transitionState: TransitionState;
  tip: Tip;
}) {
  const [navigatedToBotCode, setNavigatedToBotCode] = useState(false);
  const [focusKey, setFocusKey] = useState(0);
  const playbook = usePlaybook();

  useEffect(() => {
    const onFocus = () => {
      setFocusKey((value) => value + 1);
    };

    window.addEventListener("focus", onFocus);

    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, []);

  return (
    <Section transitionState={transitionState} offscreenPosition="below">
      <div>
        <div
          style={{
            position: "absolute",
            top: "14px",
            right: "0px",
          }}
        >
          <Popover
            placement="bottom"
            content={
              <div
                style={{
                  padding: "5px",
                  gap: "5px",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  width: "190px",
                }}
              >
                <AnchorButton
                  minimal
                  icon="video"
                  className={Classes.POPOVER_DISMISS}
                  href={buildingFirstBotVideoRelativeLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Watch
                </AnchorButton>
                <AnchorButton
                  minimal
                  icon="manual"
                  className={Classes.POPOVER_DISMISS}
                  href={articleLink}
                  target="_blank"
                >
                  Read
                </AnchorButton>
              </div>
            }
          >
            <Button
              style={{
                fontWeight: 600,
              }}
              minimal
              intent="primary"
            >
              Launch Tutorial
            </Button>
          </Popover>
        </div>
        <Reveal key={focusKey}>
          {(text) => (
            <div
              style={{
                fontSize: "26px",
                fontWeight: 700,
                padding: "20px",
              }}
            >
              {navigatedToBotCode ? (
                <>
                  {text(
                    '■■■Has your bot come online? If so, close the Playbook and click "Start Game."'
                  )}
                </>
              ) : (
                <>
                  {text(`■■■Next up: open ${tip.botName} in a code editor and`)}{" "}
                  <Code>
                    <a href="/docs/zilch-connect" target="_blank">
                      {text("./c■o■n■n■e■c■t■")}
                    </a>
                  </Code>{" "}
                  {text("it to Zilch.")}
                </>
              )}
            </div>
          )}
        </Reveal>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              width: "350px",
              height: "210px",
              overflow: "hidden",
              boxShadow: `0 7px 15px -2px var(--black)`,
              borderRadius: "8px",
            }}
            src={navigatedToBotCode ? onlineSrc : connectSrc}
          />
        </div>
      </div>
      <OpenBotAction
        intent={navigatedToBotCode ? "none" : "primary"}
        navigatedToBotCode={navigatedToBotCode}
        onNavigatedToBotCode={() => {
          setNavigatedToBotCode(true);
          playbook.setEmphasizeStartGame(true);
          playbook.setEmphasizeClosePlaybook(true);
        }}
        tip={tip}
      />
    </Section>
  );
}

export function BuildingYourFirstZilchBot({
  transitionState,
  tip,
}: {
  transitionState: TransitionState;
  tip: Tip;
}) {
  const [section, setSection] = useState<"intro" | "open-bot">("intro");
  const [dontAskAgain, setDontAskAgain] = useState(false);
  const storage = useStorage();

  return (
    <div
      style={{
        overflow: "hidden",
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        transition: "all ease .3s",
        opacity: transitionState === "entered" ? 1 : 0,
        transform:
          transitionState === "entered" ? "translateX(0%)" : "translateX(100%)",
      }}
    >
      <DataTransition
        exitDuration={400}
        items={[section]}
        skipInitialEnterTransition
        getItemKey={(key) => key}
        render={(section, transitionState) => {
          if (section === "intro") {
            return (
              <IntroSection
                dontAskAgain={dontAskAgain}
                onSetDontAskAgain={setDontAskAgain}
                transitionState={transitionState}
                onNextSection={() => {
                  if (dontAskAgain) {
                    storage.dontShowNewBotTutorial.set(true);
                  }
                  setSection("open-bot");
                }}
                tip={tip}
              />
            );
          } else {
            return (
              <OpenBotSection transitionState={transitionState} tip={tip} />
            );
          }
        }}
      />
    </div>
  );
}
