.outerContainer {
  position: absolute;
  top: 120px;
}

.innerContainer {
  display: flex;
  align-items: center;
}

.slotActions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2px 0px;
  margin-right: 5px;
  opacity: 0.2;
  transition:
    opacity ease 0.4s,
    transform cubic-bezier(0.5, 1.5, 0.5, 1) 0.4s;
  position: relative;
  z-index: 1;
}

.botCardContainer {
  position: relative;
  z-index: 2;
  margin-right: 10px;
}

.slotActions.hidden {
  opacity: 0 !important;
  pointer-events: none;
  transition:
    opacity ease 0.4s,
    transform cubic-bezier(0.5, 0, 0.5, -0.5) 0.3s;
  transform: translateX(30px) scale(0.8);
}

.viewStats {
  padding: 5px 8px;
  line-height: 15px;
  text-align: center;
  font-weight: 600;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.8;
}

.viewStats:hover {
  opacity: 1;
}

.viewStats:active {
  opacity: 0.6;
}

.innerContainer:hover .slotActions {
  opacity: 1;
}

.colorPickerButton {
  height: 60px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: all ease 0.2s;
}

.colorPickerButton:hover {
  transform: scale(1.1);
}

.colorPickerButton:active {
  opacity: 0.8;
  transform: scale(1.05);
}

.colorPickerContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  padding: 10px;
}

.shadow {
  position: absolute;
  top: -1px;
  left: 29px;
  right: -1px;
  bottom: -1px;
  background: var(--black);
  border-radius: 3px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.7);
}

@keyframes ActiveIndicatorAnimation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.activeIndicator {
  position: absolute;
  left: calc(100% + 18px);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  transition: all ease 0.3s;
  animation: ActiveIndicatorAnimation 2s ease 0s infinite both;
}

.rankContainer {
  width: 60px;
  height: 77px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.winPercentageContainer {
  position: absolute;
  overflow: hidden;
  border-radius: 2px;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
}

.winPercentage {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  transform-origin: 0px 100%;
  transition: all ease 0.3s;
}

.trophy {
  position: absolute;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  padding: 4px;
  border-radius: 50%;
}

.rank {
  position: absolute;
  right: -4px;
  top: -4px;
  height: 18px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
  font-size: 10px;
  pointer-events: none;
  transition: all 0.3s ease;
}
