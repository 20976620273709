.container {
  border-radius: 3px;
  width: 100%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}

.containerDisabled {
  opacity: 0.6;
}

.contentContainer {
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  padding-right: 3px;
}

.titleContainer {
  transform: translateY(-1px);
}

.created {
  font-size: 12px;
  color: var(--gray4);
}

.title {
  color: var(--white);
}

.icon {
  margin-left: 3px;
}

.rightSection {
  display: flex;
  align-items: end;
  flex-direction: column;
  font-size: 12px;
  color: var(--gray4);
}

.footer {
  padding: 4px 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
