@keyframes LanguageOptionIconAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.languageOptionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100px;
  cursor: pointer;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 10px;
  transition: all ease 0.2s;
}

.languageOptionContainer:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.languageOptionContainer:active {
  transition-duration: 0s;
  background-color: rgba(255, 255, 255, 0.15);
}

.languageList {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px;
  padding: 0px 6px 70px 20px;
  min-height: 142px;
}

.languageListFooter {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px;
  padding: 15px;
  margin: 20px;
  margin-top: 9px;
  background: var(--dark-gray2);
  border: 1px solid var(--dark-gray5);
  border-radius: 10px;
}

.messageOuterContainer {
  position: absolute;
  left: 1px;
  right: 14px;
  bottom: 0px;
  border-bottom-right-radius: 2px;
  pointer-events: none;
  border-bottom-left-radius: 2px;
  padding: 20px 6px 20px 19px;
  background: linear-gradient(to bottom, transparent, var(--dark-gray3));
}

.languageListContainer {
  overflow-y: scroll;
  transition: all ease 0.3s;
  border-top: 1px solid transparent;
}

.languageListContainerTopBorder {
  border-top-color: var(--dark-gray4);
}

.languageName {
  position: absolute;
  bottom: 10px;
  color: var(--light-gray1);
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  width: 100%;
  padding: 1px 2px;
  text-overflow: ellipsis;
  transition: all ease 0.2s;
}

.languageOptionContainer:hover .languageName {
  color: var(--white);
}

.languageOptionContainerVariantMenuOpenCss {
  background-color: rgba(255, 255, 255, 0.1);
}

.container {
  display: grid;
  grid-template-rows: 50px 1fr;
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  bottom: 1px;
  overflow: hidden;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 35px;
}

.languageOptionIcon {
  animation: LanguageOptionIconAnimation 0.3s ease both;
}
