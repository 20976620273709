.filter {
  text-transform: uppercase;
  color: var(--blue5);
  font-weight: 600;
  padding: 0px;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0px;
  white-space: nowrap;
}

.filterContainer {
  background: linear-gradient(to right, transparent, var(--dark-gray3) 30px);
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 30px;
}

.filter:hover {
  color: #c5ddff;
}
.filter:active {
  color: #b1d2ff;
}

.slotHeader {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.gameListHeader {
  display: grid;
  grid-template-columns: 80px 1fr;
  padding-bottom: 5px;
  gap: 10px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  color: var(--gray4);
}

.drawer {
  margin-left: -1px !important;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  background: var(--dark-gray3) !important;
  display: grid !important;
  grid-template-rows: auto 1fr !important;
}

.topSection {
  padding: 20px;
  padding-top: 16px;
  padding-bottom: 0px;
  margin-right: 1px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--dark-gray5);
}

.quickFilterSection {
  display: grid;
  grid-template-columns: 50px 1fr 1fr;
  align-items: start;
  gap: 10px;
  height: 55px;
  overflow: hidden;
  transition: all ease 0.2s;
  margin-top: 15px;
}

.quickFilterSection.hidden {
  height: 0px;
  opacity: 0;
}

.gameListItemContainer {
  margin-left: 12px;
  height: 70px;
  border-bottom: 1px solid var(--dark-gray4);
  display: flex;
  align-items: center;
}

.gameListItemContainer.lastItem {
  border-bottom: none;
}

.gameListItem {
  display: grid;
  grid-template-columns: 80px 80px 1fr;
  gap: 10px;
  flex-grow: 1;
  padding: 6px;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  background: transparent;
}

.gameListItem:hover {
  background: rgba(255, 255, 255, 0.06);
}

.gameListItem:active {
  background: rgba(255, 255, 255, 0.04);
}

.gameListItem:hover .replayIcon {
  color: rgba(255, 255, 255, 0.3);
}

.replayIcon {
  color: rgba(255, 255, 255, 0.1);
  place-self: center;
}

.decidedByContainer {
  font-weight: 400;
  font-size: 13px;
  color: var(--gray3);
  width: 100px;
  text-align: left;
}

.decidedByLabel {
  font-weight: 600;
  color: var(--gray3);
  opacity: 0.7;
  font-size: 9px;
  text-transform: uppercase;
  margin-top: 1px;
  margin-bottom: 1px;
}

.watchReplay {
  position: absolute;
  opacity: 0;
  right: 31px;
  text-align: right;
  pointer-events: none;
  top: 7px;
  font-size: 12px;
  color: var(--gray3);
}

.gameListItem:hover .watchReplay,
.gameListItem:active .watchReplay {
  transition: all ease 0.2s;
  transform: translateX(9px);
  opacity: 1;
}

.watchReplayIcon {
  position: absolute;
  right: 10px;
  top: 13px;
  color: var(--gray2);
  transition: all ease 0.2s;
  opacity: 1;
}

.gameListItem:hover .watchReplayIcon,
.gameListItem:active .watchReplayIcon {
  transform: translateX(9px);
}
