@keyframes InAnimation {
  from {
    opacity: 0;
    transform: scale(0) translateY(300px);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.premiumActionsContainer {
  position: sticky;
  height: 100px;
  bottom: 255px;
  display: flex;
  align-items: start;
  justify-content: center;
  transition: all ease 0.5s;
}

.premiumActions {
  background: rgba(28, 33, 39, 0.2);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  height: 60px;
  margin-top: 275px;
  border-radius: 30px;
  display: flex;
  gap: 20px;
  padding: 10px;
  backdrop-filter: blur(5px);
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100;
  transition: all ease 0.3s;
}

@media (max-width: 700px) {
  .premiumActions:not(.scrolledPastPremium) {
    opacity: 0;
  }
}

@media (max-width: 400px) {
  .premiumActions {
    gap: 10px;
  }
}

.progressBarBase {
  position: absolute;
  width: 4px;
  left: calc(50% - 2px);
  bottom: -100px;
  z-index: -1;
  height: 3300px;
  background: repeating-linear-gradient(
    var(--black),
    var(--dark-gray2) 300px,
    var(--black) 600px
  );
  -webkit-mask-image: linear-gradient(
    rgba(0, 0, 0, 1) calc(100% - 100px),
    transparent
  );
  -webkit-mask-size: 100% 100%;
  -webkit-mask-position: 0 0;
  -webkit-mask-repeat: no-repeat;
}

.progressBar {
  border: 3px solid var(--black);
  z-index: -1;
  left: 0px;
  right: 0px;
  bottom: -200px;
  border-radius: 10px;
  transition: all ease 0.5s;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background: linear-gradient(
    rgba(98, 217, 107, 0.4),
    rgba(98, 217, 107, 0.2) 200px,
    transparent 100%
  );
  border-bottom: none;
  position: absolute;
  -webkit-mask-image: linear-gradient(
    rgba(0, 0, 0, 1) calc(100% - 200px),
    transparent
  );
  -webkit-mask-size: 100% 100%;
  -webkit-mask-position: 0 0;
  -webkit-mask-repeat: no-repeat;
  animation-name: ProgressBarAnimation;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.progressBar::after {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  border-radius: 10px;
  height: 100%;
  border: 4px solid var(--black);
  background: linear-gradient(
    rgba(98, 217, 107, 1),
    rgba(98, 217, 107, 0.6) 80px,
    rgba(98, 217, 107, 0.1) 550px,
    transparent 100%
  );
}

.goodFirstGame {
  position: absolute;
  top: -60px;
  width: 200px;
  pointer-events: none;
  animation: InAnimation 0.77s cubic-bezier(0.5, 1.5, 0.5, 1) both;
  animation-delay: 0.5s;
}

.itemContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 260px;
  height: 260px;
  flex-direction: column;
  cursor: pointer;
  animation: InAnimation 0.8s cubic-bezier(0.5, 1.8, 0.5, 1) both;
}

.animateImgContainer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ImgAnimation ease both 2.2s;
}

.itemImg {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all ease 0.3s;
  top: 0;
  left: 0;
  filter: drop-shadow(0px 7px 8px rgba(0, 0, 0, 0.3));
}

.itemImgContainer {
  position: absolute;
  z-index: 1;
  width: 220px;
  height: 220px;
  transition: all cubic-bezier(0.5, 1.8, 0.5, 1) 0.5s;
  pointer-events: none;
}

.itemBackgroundCircle {
  position: absolute;
  z-index: 0;
  width: 200px;
  pointer-events: none;
  height: 200px;
  border-radius: 100%;
  box-shadow: 0px 5px 20px -2px rgba(0, 0, 0, 0.2);
  background: radial-gradient(
    at 50px 50px,
    var(--dark-gray4) 40%,
    var(--black)
  );
  transition: all cubic-bezier(0.5, 1.8, 0.5, 1) 0.5s;
  border: 2px solid var(--dark-gray2);
}

.itemBackgroundCircleOutlined {
  box-shadow: 0 0 0 4px var(--black);
}

.itemLabel {
  position: absolute;
  background: var(--black);
  box-shadow:
    0 0 0 1px var(--dark-gray4),
    0 3px 10px -1px var(--black);
  padding: 0px 14px;
  height: 30px;
  border-radius: 15px;
  transform: translateY(60px);
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all cubic-bezier(0.5, 1.8, 0.5, 1) 0.5s;
  z-index: 1;
  color: var(--light-gray3);
}

.premiumLabel {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: -10px;
}

.premiumCheck {
  margin-right: 8px;
  margin-left: -2px;
}

.premiumUnlock {
  padding-left: 10px;
  border-radius: 12px !important;
  font-weight: 800;
}

.itemStars {
  display: flex;
  align-items: center;
  gap: 3px;
}

.itemContainer:hover .itemBackgroundCircle {
  transform: scale(0.85);
}

.itemContainer:hover .itemImgContainer {
  transform: scale(1.5) translateY(-10px) rotate(5deg);
}

.itemContainer:hover .itemLabel {
  transform: translateY(80px);
}

.itemContainer:active .itemLabel {
  transform: translateY(70px);
}

.itemContainer:active .itemBackgroundCircle {
  transform: scale(0.8);
}

.itemContainer:active .itemImgContainer {
  transform: scale(1.05) translateY(-10px) rotate(5deg);
}
