import { useDelay } from "@zilch/delay";
import { times } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { BotAvatarSvg } from "@zilch/bot-avatar-svg";
import css from "./HomeScreenContainer.module.css";
import { generateBotAvatar } from "@zilch/bot-avatar";
import { wrapIndex } from "@zilch/wrap-index";
import { BotColor } from "@zilch/bot-models";
import { Colors } from "@blueprintjs/core";
import { useWindowSizeDerivedValue } from "@zilch/window-size";

const TILE_SIZE = 100;

interface Size {
  columns: number;
  rows: number;
  offset: number;
}

type GetBotConfig = (
  row: number,
  column: number
) => {
  color: string;
  foregroundColor: string;
  avatar: string;
};

function getSize(element: HTMLDivElement): Size {
  const columns = Math.ceil(element.clientWidth / TILE_SIZE);
  return {
    columns,
    rows: Math.ceil(element.clientHeight / TILE_SIZE),
    offset: (columns * TILE_SIZE - element.clientWidth) / 2,
  };
}

export function HomeScreenContainer({
  children,
}: {
  children?: React.ReactNode;
}) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [size, setSize] = useState<Size | null>(null);
  const [showTitleBackground, setShowTitleBackground] = useState(false);

  useEffect(() => {
    const element = containerRef.current;

    if (!element) {
      return;
    }

    setSize(getSize(element));

    const observer = new ResizeObserver(() => {
      setSize(getSize(element));
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
      observer.disconnect();
    };
  }, []);

  const updateTitleBackground = () => {
    if ((contentRef.current?.scrollTop ?? 0) > 120) {
      setShowTitleBackground(true);
    } else {
      setShowTitleBackground(false);
    }
  };

  useEffect(() => {
    updateTitleBackground();
  }, []);

  return (
    <div ref={containerRef} className={css.container}>
      <div className={css.background}>
        <div
          style={{
            display: "grid",
            width: size ? TILE_SIZE * size.columns + "px" : undefined,
            height: size ? TILE_SIZE * size.rows + "px" : undefined,
            gridTemplateColumns: `repeat(auto-fit, ${TILE_SIZE}px)`,
            gridTemplateRows: `repeat(auto-fit, ${TILE_SIZE}px)`,
            transform: `translateX(${-(size?.offset ?? 0)}px)`,
          }}
        >
          {size !== null &&
            times(size.rows).flatMap((row) => {
              return times(size.columns).map((column) => {
                return (
                  <BotTile
                    offset={size.offset}
                    getBotConfig={(row, column) => {
                      const avatar =
                        (row + Math.round(row / 2) + column) % 2 === 0 &&
                        row % 2 === 0
                          ? "pWHgMyAHB9NK_ceC4"
                          : generateBotAvatar();
                      const colors = Object.keys(
                        BotColor
                      ) as (keyof typeof BotColor)[];
                      const color =
                        BotColor[colors[wrapIndex(colors, column + row + 5)]!];

                      return {
                        avatar,
                        color,
                        foregroundColor: Colors.WHITE,
                      };
                    }}
                    key={`${column}-${row}`}
                    column={column}
                    row={row}
                  />
                );
              });
            })}
        </div>
      </div>
      <div
        style={{
          height: "50px",
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          transform: `translateY(${showTitleBackground ? 0 : -50}px)`,
          transition: "ease all .3s",
          zIndex: 3,
          boxShadow: "0px 0px 10px rgba(0,0,0,.3)",
          background:
            "linear-gradient(to right, var(--dark-gray3), var(--dark-gray1), var(--dark-gray1), var(--dark-gray3))",
        }}
      />
      <div
        className={css.content}
        ref={contentRef}
        onScroll={() => {
          updateTitleBackground();
        }}
      >
        <div className={css.contentInner}>{children}</div>
      </div>
    </div>
  );
}

function BotTile({
  column,
  row,
  offset,
  getBotConfig,
}: {
  column: number;
  row: number;
  offset: number;
  getBotConfig: GetBotConfig;
}) {
  const [botConfig] = useState(() => getBotConfig(row, column));
  const { width, height } = useWindowSizeDerivedValue();

  const positionX = (column + 0.5) * TILE_SIZE - offset;
  const positionY = (row + 0.5) * TILE_SIZE;
  const percentageY = positionY / height;

  const halfway = width / 2;
  const halfOffset = width / 6;
  let diff = 0;
  if (positionX < halfway) {
    diff = Math.min(0, positionX - (halfway - halfOffset));
  } else {
    diff = Math.max(0, positionX - (halfway + halfOffset));
  }
  let scale = (2 * diff) / width;
  let scaleDiffFactor: number;
  if (width < 400) {
    scaleDiffFactor = 1.2;
  } else if (width < 500) {
    scaleDiffFactor = 2.2;
  } else if (width < 600) {
    scaleDiffFactor = 3;
  } else {
    scaleDiffFactor = 4;
  }
  scale += ((scale < 0 ? -1 : 1) * 0.6 - scale) / scaleDiffFactor;
  if (Math.abs(scale) < 0.2) {
    scale = 0;
  }
  const translateX =
    Math.abs(percentageY - 0.5) *
    0.7 *
    (height + width) *
    (positionX > halfway ? 1 : -1);

  const delay = useDelay((column + row) * 30);

  if (!delay) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        paddingTop: "100%",
        gridColumn: column + 1,
        gridRow: row + 1,
        position: "relative",
      }}
    >
      <div
        style={{
          transition: "ease all 1s",
          transform: `scale(${Math.abs(scale)}) translateX(${translateX}px)`,
        }}
      >
        <BotAvatarSvg
          className={css.botSvg}
          avatar={botConfig.avatar}
          color={botConfig.color}
          foregroundColor={botConfig.foregroundColor}
          size={70}
        />
      </div>
    </div>
  );
}
