import React, { useCallback, useEffect, useRef, useState } from "react";
import css from "./index.module.css";
import { classes } from "@zilch/css-utils";

export function usePulse(
  color: "gold" | "danger",
  {
    borderRadius = 3,
    inline = false,
  }: { borderRadius?: number; inline?: boolean } = {}
) {
  const [pulseKey, setPulseKey] = useState(0);
  const timeoutId = useRef<number>();

  useEffect(() => {
    return () => clearTimeout(timeoutId.current);
  }, []);

  const trigger = useCallback((delay: number = 0) => {
    timeoutId.current = setTimeout(() => {
      setPulseKey((value) => value + 1);
    }, delay) as unknown as number;
  }, []);

  const render = useCallback(
    (children?: React.ReactNode) => {
      const pulse = (
        <Pulse pulseKey={pulseKey} borderRadius={borderRadius} color={color} />
      );

      if (children === undefined) {
        return pulse;
      }

      return (
        <div
          className={css.container}
          style={inline ? { display: "inline-block" } : undefined}
        >
          {pulse}
          {children}
        </div>
      );
    },
    [pulseKey, color, borderRadius, inline]
  );

  return [render, trigger] as [
    renderPulse: typeof render,
    triggerPulse: typeof trigger,
  ];
}

function Pulse({
  pulseKey,
  borderRadius,
  color,
}: {
  pulseKey: number;
  borderRadius: number;
  color: "danger" | "gold";
}) {
  const initialPulseKey = useRef(pulseKey);
  return (
    <div
      className={classes(
        css.pulse,
        pulseKey > initialPulseKey.current && css[color]
      )}
      style={{ borderRadius }}
      key={pulseKey}
    />
  );
}
