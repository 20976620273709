.permissionTopSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin: 30px;
}

.permissionMiddleSection {
  margin: 0px 30px;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.permissionBottomSection {
  padding: 12px 28px;
  margin-bottom: 14px;
}
