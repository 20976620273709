@keyframes FadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container {
  transition: all ease 0.2s;
  position: relative;
  z-index: 2;
  width: 230px;
  height: 77px;
  padding: 8px !important;
  padding-bottom: 0px !important;
  display: inline-grid;
  grid-template-columns: 36px 1fr;
  grid-template-rows: 36px 1fr;
  align-items: center;
}

.avatar {
  justify-self: end;
}

.name {
  margin-bottom: 2px;
  font-weight: 600;
  font-size: 16px;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: transform ease 0.2s;
}

.lockContainer {
  position: absolute;
  pointer-events: none;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  background: rgba(56, 62, 71, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  z-index: 1;
}

.lock {
  padding: 10px;
  border-radius: 100%;
  background: var(--black);
  box-shadow:
    0 0 0 1px var(--gray1),
    0 3px 10px -1px var(--black);
}

.fadeIn {
  animation: FadeInAnimation 0.3s ease both;
}

.footer {
  grid-column: 1 / span 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2px;
  gap: 5px;
}

.footerElement {
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 3px;
}

.bottomLeftText {
  margin-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
