import { Button, Colors, FormGroup, Icon } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import { sleep } from "@zilch/sleep";
import { usePrevious } from "@zilch/use-previous";
import { type Code, VerificationCodeInput } from "@zilch/verification-code";
import { useEffect, useRef, useState } from "react";
import { api } from "../../api";
import { PremiumStore } from "../../stores/PremiumStore";
import { UserStore } from "../../stores/UserStore";
import { toaster } from "../../toaster";
import css from "./VerificationForm.module.css";
import { posthog } from "posthog-js";

export function VerificationForm(props: { email: string; onBack(): void }) {
  const [verificationCode, setVerificationCode] = useState<Code>([
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  const userStore = UserStore.use();

  const [error, setError] = useState<string | null>(null);
  const [activating, setActivating] = useState<boolean>(false);
  const [sendingNewCode, setSendingNewCode] = useState<boolean>(false);
  const focusVerificationCodeRef = useRef<() => void>();
  const premiumStore = PremiumStore.use();

  useEffect(() => {
    setError(null);
  }, [verificationCode]);

  const unlockPremium = async () => {
    if (verificationCode.some((value) => value === null)) {
      setError("Fill in verification code.");
      return;
    }

    setActivating(true);

    const response = await api.organization.activateEmail
      .mutate({
        email: props.email,
        code: verificationCode.join(""),
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return "error" as const;
      })
      .finally(() => setActivating(false));

    if (response === "error") {
      setError("Unexpected problem encountered.");
      return;
    }

    if (response.status === "invalid-code") {
      setError("Code incorrect.");
      return;
    }

    const previousAccessibleOnAccountScreenPremiumSources = new Set(
      userStore.premiumSources
    );

    const user = await userStore.query.refetch();
    const hasPremium =
      user.data?.type === "authenticated" && user.data.likelyHasPremium;

    if (!hasPremium) {
      setError("Unexpected problem encountered.");
      return;
    }

    if (previousAccessibleOnAccountScreenPremiumSources.size === 0) {
      posthog.capture("premium_activated", {
        source: "organization",
        email: props.email,
        domain: props.email.split("@")[1],
      });
      premiumStore.setSection("feature-overview");
    } else {
      premiumStore.setSection(null);
      toaster.show({ intent: "success", message: "Email verified" });
    }
  };

  const onNewCode = async () => {
    setSendingNewCode(true);
    setVerificationCode([null, null, null, null, null, null]);

    await sleep(1000);

    const result = await api.organization.verifyEmail
      .mutate({ email: props.email })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return "error" as const;
      });

    setSendingNewCode(false);

    if (result === "error" || result.status !== "verification-code-sent") {
      props.onBack();
      toaster.show({
        intent: "danger",
        message:
          "Encountered unexpected problem sending new verification code.",
      });
      return;
    }

    toaster.show({
      intent: "success",
      message: `New code sent to ${props.email}`,
    });

    setTimeout(() => {
      focusVerificationCodeRef.current?.();
    });
  };

  const filledCount = verificationCode.filter((entry) => entry !== null).length;
  const prevFilledCount = usePrevious(filledCount);
  const justTransitionedToFilled =
    (prevFilledCount === null || prevFilledCount < 6) && filledCount === 6;

  const unlockPremiumRef = useRef(unlockPremium);
  unlockPremiumRef.current = unlockPremium;
  useEffect(() => {
    if (justTransitionedToFilled) {
      unlockPremiumRef.current();
    }
  }, [justTransitionedToFilled]);

  return (
    <form
      className={classes(css.container, transitionInFromCss.bottom)}
      onSubmit={(e) => {
        e.preventDefault();
        unlockPremium();
      }}
    >
      <div className={css.leftSection}>
        <Button
          className={css.backButton}
          icon="arrow-left"
          onClick={props.onBack}
        />

        <FormGroup
          className={css.verificationInputContainer}
          intent={error ? "danger" : "none"}
          helperText={
            error ? (
              error
            ) : (
              <>
                Enter code sent to{" "}
                <span className={css.email} title={props.email}>
                  {props.email}
                </span>
              </>
            )
          }
        >
          <VerificationCodeInput
            autoFocus
            intent={error ? "danger" : "none"}
            value={verificationCode}
            disabled={activating || sendingNewCode}
            onChange={setVerificationCode}
            focusRef={focusVerificationCodeRef}
          />
        </FormGroup>

        <Tooltip2
          content="Get new code"
          placement="top"
          isOpen={sendingNewCode || activating ? false : undefined}
        >
          <Button
            icon={<Icon icon="reset" color={Colors.GRAY3} />}
            minimal
            disabled={activating}
            onClick={onNewCode}
            loading={sendingNewCode}
            style={{ borderRadius: "100%" }}
          />
        </Tooltip2>
      </div>

      <Button
        intent="primary"
        large
        style={{ width: "190px" }}
        type="submit"
        disabled={sendingNewCode}
        loading={activating}
      >
        {userStore.premiumSources.has("organization-plan")
          ? "Reverify eligibility"
          : "Unlock free Premium"}
      </Button>
    </form>
  );
}
