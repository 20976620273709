import { useQuery } from "@tanstack/react-query";
import type { GameId } from "@zilch/game-config";
import type { TransitionSlotSelection } from "@zilch/bot-models";
import {
  BotType,
  DiskBotConfig,
  type SlotSelection,
  type UserBotConfig,
} from "@zilch/bot-models";
import { api } from "../../api";
import { UserStore } from "../../stores/UserStore";
import { parse } from "jsonc-parser";

export function useUserBotConfigListQuery(
  owner: string | null,
  gameId: GameId | null
) {
  const userStore = UserStore.use();
  const authenticated =
    userStore.query.isSuccess && userStore.query.data.type === "authenticated";

  return useQuery(
    ["getUserBotConfigList", owner, gameId, authenticated],
    async ({ signal }) => {
      const userBots =
        owner && gameId && authenticated
          ? await getUserBotConfigList(owner, gameId, signal!)
          : [];

      if (userBots === "nonexistent-user") {
        return userBots;
      }

      const byOwnerAndRepo = new Map<string, Map<string, UserBotConfig>>();

      for (const bot of userBots) {
        const repoMap =
          byOwnerAndRepo.get(bot.owner) ?? new Map<string, UserBotConfig>();
        repoMap.set(bot.repo, bot);
        byOwnerAndRepo.set(bot.owner, repoMap);
      }

      return {
        list: userBots,
        bySlot(slot: NonNullable<SlotSelection | TransitionSlotSelection>) {
          if (slot.type === BotType.User) {
            return byOwnerAndRepo.get(slot.owner)?.get(slot.repo) ?? null;
          } else {
            return null;
          }
        },
      };
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
    }
  );
}

async function getUserBotConfigList(
  owner: string,
  gameId: GameId,
  signal: AbortSignal
) {
  const index = await api.bot.getBotIndex.query(
    {
      owner,
      gameId,
    },
    { signal }
  );

  if (index === "nonexistent-user") {
    return index;
  }

  const botConfigs = await Promise.all(
    index.map(async (bot): Promise<UserBotConfig | null> => {
      const diskBotConfig = await fetch(
        `https://cdn.jsdelivr.net/gh/${owner}/${bot.repo}@${bot.latestCommit}/zilch.json`,
        {
          signal,
        }
      )
        .then(async (response): Promise<DiskBotConfig> => {
          return DiskBotConfig.parse(parse(await response.text()));
        })
        .catch((error) => {
          console.error("Unable to parse config for", owner, bot.repo, error);
          return null;
        });

      if (
        diskBotConfig === null ||
        !doGameIdsMatch(gameId, diskBotConfig.game)
      ) {
        return null;
      }

      return {
        ...diskBotConfig,
        latestCommit: bot.latestCommit,
        owner,
        repo: bot.repo,
        type: BotType.User,
      };
    })
  );

  return botConfigs.filter(
    (botConfig) => botConfig !== null
  ) as UserBotConfig[];
}

function doGameIdsMatch(a: GameId, b: GameId) {
  const normalizeGameId = (value: GameId) => {
    return typeof value === "string" ? { owner: "zilch", repo: value } : value;
  };

  const normalizedA = normalizeGameId(a);
  const normalizedB = normalizeGameId(b);

  return (
    normalizedA.owner === normalizedB.owner &&
    normalizedA.repo === normalizedB.repo
  );
}
