import css from "./Store.module.css";
import { StoreTitle } from "./StoreTitle";
import { StoreGames } from "./StoreGames";
import { StoreCoins } from "./StoreCoins";
import { StoreProductList } from "./StoreProductList";

export function Store() {
  return (
    <>
      <div className={css.topFade} />
      <div className={css.container}>
        <StoreTitle />
        <StoreGames />
        <StoreCoins />
        <StoreProductList />
      </div>
    </>
  );
}
