.panelContainer {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 50px auto 50px;
  height: 100%;
}

.panelHeader {
  padding-left: 10px;
  display: flex;
  align-items: center;
}