import { AnchorButton } from "@blueprintjs/core";
import { routes } from "../../router";
import { transitionScreen } from "../../transitionScreen";
import { NonIdealState } from "../common/NonIdealState";
import { preventDefaultLinkClickBehavior } from "type-route";

export function NotFound() {
  return (
    <NonIdealState
      icon="search"
      title="Not Found"
      action={
        <AnchorButton
          href={routes.home().href}
          onClick={(e) => {
            if (preventDefaultLinkClickBehavior(e)) {
              transitionScreen(routes.home());
            }
          }}
        >
          Go to Game List
        </AnchorButton>
      }
    />
  );
}
