import { classes } from "@zilch/css-utils";
import css from "./GuideContent.module.css";
import { AnchorButton, Button, Classes } from "@blueprintjs/core";
import React from "react";

interface Props {
  title: React.ReactNode;
  message: React.ReactNode;
  action?: {
    text: string;
    href?: string;
    onClick?(event: React.MouseEvent): void;
  };
}

export function GuideContent(props: Props) {
  const buttonProps = {
    className: Classes.POPOVER_DISMISS,
    outlined: true,
    onClick: props.action ? props.action.onClick : undefined,
  };
  return (
    <div className={classes(css.container, "bp4-text-large")}>
      <div className={css.title}>{props.title}</div>
      <div>{props.message}</div>
      {props.action && (
        <div
          style={{ marginTop: "10px", display: "flex", justifyContent: "end" }}
        >
          <div
            style={{
              borderRadius: "2px",
              background: "rgba(255, 255, 255, 0.08)",
            }}
          >
            {props.action.href ? (
              <AnchorButton {...buttonProps} href={props.action.href}>
                {props.action.text}
              </AnchorButton>
            ) : (
              <Button {...buttonProps}>{props.action.text}</Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
