import { Colors, Tag } from "@blueprintjs/core";
import React from "react";
import { GiRoundStar } from "react-icons/gi";
import { routes } from "../../router";
import { StarStore } from "../../stores/StarStore";
import css from "./GameList.module.css";
import chessPng from "../../resources/icons/chess.png";
import tableTennisPng from "../../resources/icons/table-tennis.png";
import ticTacToePng from "../../resources/icons/tic-tac-toe.png";
import flagPng from "../../resources/icons/flag.png";
import { transitionScreen } from "../../transitionScreen";
import { PromptStore } from "../../stores/PromptStore";
import goodFirstGameSrc from "../../resources/icons/good-first-game.png";
import { useWindowSizeDerivedValue } from "@zilch/window-size";

export function GameList() {
  const starStore = StarStore.use();

  const prompt = PromptStore.usePrompt();

  const small = useWindowSizeDerivedValue((width) => width < 1140);
  const reallySmall = useWindowSizeDerivedValue((width) => width < 600);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: reallySmall
          ? "1fr"
          : small
          ? "repeat(2, 1fr)"
          : "repeat(4, 1fr)",
        gap: "20px",
      }}
    >
      <div style={{ position: "relative" }}>
        <img src={goodFirstGameSrc} className={css.goodFirstGame} />
        <GameListItem
          index={0}
          stars={starStore.getStarCount("table-tennis")}
          onClick={() => {
            transitionScreen(routes.internalGame({ gameId: "table-tennis" }));
          }}
          label="Table Tennis"
          src={tableTennisPng}
        />
      </div>

      <GameListItem
        index={1}
        stars={starStore.getStarCount("tic-tac-toe")}
        onClick={() => {
          transitionScreen(routes.internalGame({ gameId: "tic-tac-toe" }));
        }}
        label="Tic-Tac-Toe"
        src={ticTacToePng}
      />
      <GameListItem
        index={2}
        stars={starStore.getStarCount("chess")}
        label="Chess"
        src={chessPng}
        onClick={() => {
          transitionScreen(routes.internalGame({ gameId: "chess" }));
        }}
      />
      <GameListItem
        index={3}
        stars={false}
        label={
          <>
            Capture the Flag
            <Tag
              round
              minimal
              style={{
                transform: "translate(9px, -1px)",
              }}
            >
              Coming Soon
            </Tag>
          </>
        }
        src={flagPng}
        onClick={() => {
          prompt(() => (
            <div style={{ padding: "20px", fontSize: "18px" }}>
              Capture the Flag hasn't been built yet but is on its way. Do you
              think you'd enjoy playing Capture the Flag? Have another game
              idea? Please share!
              <br />
              <br />
              <b>
                <a href="mailto:hello@zilch.dev">hello@zilch.dev</a>
              </b>
            </div>
          ));
        }}
      />
    </div>
  );
}

function GameListItem(props: {
  src: string;
  label: React.ReactNode;
  stars: number | false;
  onClick: (event: React.MouseEvent) => void;
  index: number;
}) {
  // TODO look into making this a link?
  return (
    <div
      className={css.itemContainer}
      tabIndex={0}
      onClick={props.onClick}
      style={{
        animationDelay: props.index * 40 + 400 + "ms",
      }}
    >
      <div className={css.itemImgContainer}>
        <img
          src={props.src}
          className={css.itemImg}
          style={{
            zIndex: 1,
            filter:
              props.stars === false
                ? "grayscale(100%) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.5))"
                : undefined,
          }}
        />
      </div>
      <div className={css.itemBackgroundCircle} />
      <div className={css.itemLabel}>
        <div>{props.label}</div>
        {typeof props.stars === "number" && (
          <div className={css.itemStars}>
            <GiRoundStar
              size={15}
              color={props.stars > 0 ? Colors.GOLD4 : Colors.GRAY3}
            />
            <GiRoundStar
              size={15}
              color={props.stars > 1 ? Colors.GOLD4 : Colors.GRAY3}
            />
            <GiRoundStar
              size={15}
              color={props.stars > 2 ? Colors.GOLD4 : Colors.GRAY3}
            />
          </div>
        )}
      </div>
    </div>
  );
}
