import { classes } from "@zilch/css-utils";
import { panic } from "@zilch/panic";
import gameMakerPng from "../../resources/icons/game-maker.png";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { z } from "zod";
import chessPng from "../../resources/icons/chess.png";
import tableTennisPng from "../../resources/icons/table-tennis.png";
import ticTacToePng from "../../resources/icons/tic-tac-toe.png";
import { loadGameConfig, type Source, useSource } from "./loadGameConfig";
import css from "./GameEngine.module.css";
import stringify from "fast-json-stable-stringify";
import type { parseConfigDefinition } from "../../../game/parseConfigDefinition";
import type { getConfigSchemaAndPresetsDefinition } from "../../../game/getConfigSchemaAndPresetsDefinition";
import type { setConfigAndTimeLimitDefinition } from "../../../game/setConfigAndTimeLimitDefinition";
import { createProcedureClient, defineProcedure } from "@zilch/frame-rpc";
import type { playGameDefinition } from "../../../game/playGameDefinition";
import {
  BotColor,
  BotOutcome,
  BotType,
  type SlotSelection,
} from "@zilch/bot-models";
import type { createRendererDefinition } from "../../../game/createRendererDefinition";
import type { setGameSpeedDefinition } from "../../../game/setGameSpeedDefinition";
import type { setActiveGameIdDefinition } from "../../../game/setActiveGameIdDefinition";
import { cloneDeep } from "lodash";
import { sleep } from "@zilch/sleep";
import { chalk } from "@zilch/chalk";
import * as csx from "csx";
import type { resetGameStateDefinition } from "../../../game/resetGameStateDefinition";
import { UserStore } from "../../stores/UserStore";
import type { getGameLengthDefinition } from "../../../game/getGameLengthDefinition";
import type { startGameBotDefinition } from "../../../game/startGameBotDefinition";
import type { moveGameBotDefinition } from "../../../game/moveGameBotDefinition";
import type { disposeGameBotDefinition } from "../../../game/disposeGameBotDefinition";
import { stringifyTimeLimit } from "./GameConfigForm";
import { stringifyBotTime } from "./BotTerminals";
import type { loadGameDefinition } from "../../../game/loadGameDefinition";
import { type GameConfig } from "@zilch/game-config";
import type { setBotColorsDefinition } from "../../../game/setBotColorsDefinition";
import type { setStatusDefinition } from "../../../game/setStatusDefinition";
import type { setReplayProgressDefinition } from "../../../game/setReplayProgress";
import type { endGameBotDefinition } from "../../../game/endGameBotDefinition";
import { NewBotStore } from "../../stores/NewBotStore";
import { devFileManager } from "./devFileManager";
import { groups, useRoute } from "../../router";

export interface BotOutcomeListItem {
  index: number;
  slot: SlotSelection;
  outcome: BotOutcome;
  time: number;
}

export type PrimaryOutcomeType =
  | "victory"
  | "victory-over-boss-but-incompatible-config"
  | "victory-over-boss"
  | "victory-over-practice"
  | "time-limit-exceeded"
  | "unimplemented"
  | "defeat-by-boss"
  | "defeat-by-practice"
  | "defeat"
  | "draw"
  | "bug-detected"
  | "stopped"
  | "game-locked"
  | "connection-problem"
  | "game-error";

interface PrimaryOutcome {
  type: PrimaryOutcomeType;
  subjectIndices: number[];
}

type BotOutcomeList = BotOutcomeListItem[];

interface FinalGameOutcome {
  status: "done";
  replayProgress: number | null;
  botOutcomes: BotOutcomeList;
  primary: PrimaryOutcome | null;
  gameLength: number;
}

export type GameOutcome =
  | FinalGameOutcome
  | {
      status: "in-progress";
    }
  | {
      status: "not-started";
    };

export const GameSpeed = {
  Fast: "fast",
  Normal: "normal",
  Paused: "paused",
  Step: "step",
} as const;
export type GameSpeed = (typeof GameSpeed)[keyof typeof GameSpeed];

const context = createContext<GameEngine | null>(null);

export const GameEngine = {
  Provide: ProvideGameEngine,
  use: useGameEngineFromContext,
};

function useGameEngineFromContext() {
  return useContext(context) ?? panic("Context should be provided");
}

function ProvideGameEngine(props: { children: React.ReactNode }) {
  const store = useGameEngine();
  return <context.Provider value={store}>{props.children}</context.Provider>;
}

interface LoadingScreenData {
  name: string;
  iconSrc: string;
}

type GameEngineInstanceCreator =
  | {
      status: "creating";
      loadingScreenData: LoadingScreenData | null;
      instance: null;
      error: null;
    }
  | {
      status: "error";
      instance: null;
      loadingScreenData: null;
      error: string;
      showRawErrorMessage: boolean;
      retry(): void;
    }
  | {
      status: "created";
      instance: GameEngineInstance;
      loadingScreenData: LoadingScreenData;
      error: null;
    };

export type GameEngine = ReturnType<typeof useGameEngine>;
function useGameEngine() {
  const [instanceCreator, setInstanceCreator] =
    useState<GameEngineInstanceCreator>({
      status: "creating",
      instance: null,
      error: null,
      loadingScreenData: null,
    });

  return {
    ...instanceCreator,
    renderIFrame(props: {
      gameSpeed: GameSpeed;
      sandbox: boolean;
      mode: "tournament" | "standard";
      onSetGameSpeed?: (value: GameSpeed) => void;
      onSetOutcome?: (outcome: GameOutcome) => void;
      onSetTimes?: (times: number[]) => void;
      onSetActiveBots?: (activeBots: Set<number>) => void;
      onSetNotStartedBots?(
        fn: (indicesOfNotStartedBots: Set<number>) => Set<number>
      ): void;
    }) {
      return (
        <GameFrame
          mode={props.mode}
          instanceCreator={instanceCreator}
          onSetInstanceCreator={setInstanceCreator}
          gameSpeed={props.gameSpeed}
          onSetGameSpeed={props.onSetGameSpeed}
          onSetOutcome={props.onSetOutcome}
          onSetTimes={props.onSetTimes}
          onSetActiveBots={props.onSetActiveBots}
          onSetNotStartedBots={props.onSetNotStartedBots}
          sandbox={props.sandbox}
        />
      );
    },
  };
}

function GameFrame(props: {
  instanceCreator: GameEngineInstanceCreator;
  onSetInstanceCreator(instanceCreator: GameEngineInstanceCreator): void;
  gameSpeed: GameSpeed;
  onSetGameSpeed?(value: GameSpeed): void;
  onSetOutcome?(outcome: GameOutcome): void;
  onSetTimes?(times: number[]): void;
  onSetActiveBots?(activeBots: Set<number>): void;
  onSetNotStartedBots?(
    fn: (indicesOfNotStartedBots: Set<number>) => Set<number>
  ): void;
  sandbox: boolean;
  mode: "tournament" | "standard";
}) {
  const [retryKey, setRetryKey] = useState(0);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const route = useRoute();
  const routeRefreshKey = groups.game.has(route)
    ? route.params.refreshKey ?? null
    : null;
  const previousRouteRefreshKeyRef = useRef(routeRefreshKey);

  useEffect(() => {
    if (routeRefreshKey !== previousRouteRefreshKeyRef.current) {
      setRetryKey((value) => value + 1);
    }
    previousRouteRefreshKeyRef.current = routeRefreshKey;
  }, [routeRefreshKey]);

  const source = useSource();

  const setInstanceCreatorRef = useRef(props.onSetInstanceCreator);
  setInstanceCreatorRef.current = props.onSetInstanceCreator;

  const gameSpeedRef = useRef(props.gameSpeed);
  gameSpeedRef.current = props.gameSpeed;

  const onSetGameSpeedRef = useRef(props.onSetGameSpeed);
  onSetGameSpeedRef.current = props.onSetGameSpeed;

  const onSetOutcomeRef = useRef(props.onSetOutcome);
  onSetOutcomeRef.current = props.onSetOutcome;

  const onSetTimesRef = useRef(props.onSetTimes);
  onSetTimesRef.current = props.onSetTimes;

  const onSetActiveBotsRef = useRef(props.onSetActiveBots);
  onSetActiveBotsRef.current = props.onSetActiveBots;

  const onSetNotStartedBotsRef = useRef(props.onSetNotStartedBots);
  onSetNotStartedBotsRef.current = props.onSetNotStartedBots;

  const userStore = UserStore.use();
  const userLogin =
    userStore.query.isSuccess && userStore.query.data.type === "authenticated"
      ? userStore.query.data.likelyLogin
      : null;
  const userLoginRef = useRef(userLogin);
  userLoginRef.current = userLogin;

  const newBotStore = NewBotStore.use();
  const isBotNewRef = useRef(newBotStore.isBotNew);
  isBotNewRef.current = newBotStore.isBotNew;
  const setBotNewValueRef = useRef(newBotStore.setBotNewValue);
  setBotNewValueRef.current = newBotStore.setBotNewValue;

  useEffect(() => {
    if (!containerRef.current) {
      panic("expected container ref to be defined");
    }

    let cancel = false;
    let gameEngineInstance: GameEngineInstance | null = null;

    onSetOutcomeRef.current?.({ status: "not-started" });
    onSetNotStartedBotsRef.current?.(() => new Set());

    setInstanceCreatorRef.current({
      status: "creating",
      instance: null,
      loadingScreenData: null,
      error: null,
    });

    const abortController = new AbortController();

    createGameEngine({
      container: containerRef.current,
      source,
      mode: props.mode,
      signal: abortController.signal,
      onSetNotStartedBots: (fn) => {
        onSetNotStartedBotsRef.current?.(fn);
      },
      setLoadingScreenData(data) {
        if (cancel) {
          return;
        }

        setInstanceCreatorRef.current({
          status: "creating",
          instance: null,
          loadingScreenData: data,
          error: null,
        });
      },
      gameSpeed: {
        get() {
          return gameSpeedRef.current;
        },
        set(value) {
          onSetGameSpeedRef.current?.(value);
        },
      },
      getUserLogin() {
        return userLoginRef.current;
      },
      isBotNew(owner, repo) {
        return isBotNewRef.current(owner, repo);
      },
      setBotNewValue(owner, repo, isNew) {
        setBotNewValueRef.current(owner, repo, isNew);
      },
      onSetTimes(times) {
        onSetTimesRef.current?.(times);
      },
      onSetActiveBots(activeBots) {
        onSetActiveBotsRef.current?.(activeBots);
      },
      onSetOutcome(outcome) {
        onSetOutcomeRef.current?.(outcome);
        onSetNotStartedBotsRef.current?.(() => new Set());
      },
    })
      .then((gameEngine) => {
        gameEngineInstance = gameEngine;

        if (abortController.signal.aborted || cancel) {
          gameEngine.dispose();
          return;
        }

        setInstanceCreatorRef.current({
          status: "created",
          instance: gameEngine,
          loadingScreenData: {
            name: gameEngine.gameConfig.name,
            iconSrc: getGameIconSrc(gameEngine.gameConfig),
          },
          error: null,
        });
      })
      .catch(async (error) => {
        if (abortController.signal.aborted) {
          return;
        }

        const retry = () => {
          setRetryKey((value) => value + 1);
        };

        if (error instanceof Response) {
          setInstanceCreatorRef.current({
            status: "error",
            instance: null,
            loadingScreenData: null,
            showRawErrorMessage: true,
            error: await error.text(),
            retry,
          });
        } else if (error instanceof Error) {
          setInstanceCreatorRef.current({
            status: "error",
            instance: null,
            loadingScreenData: null,
            showRawErrorMessage: false,
            error: error.message,
            retry,
          });
        } else if (typeof error === "string") {
          setInstanceCreatorRef.current({
            status: "error",
            showRawErrorMessage: false,
            loadingScreenData: null,
            instance: null,
            error,
            retry,
          });
        } else if (
          error !== null &&
          typeof error === "object" &&
          error.type === "devGameConfigResponseError" &&
          source.dev
        ) {
          setInstanceCreatorRef.current({
            status: "error",
            showRawErrorMessage: false,
            loadingScreenData: null,
            instance: null,
            error: JSON.stringify(error, null, 2),
            retry,
          });
        } else {
          setInstanceCreatorRef.current({
            status: "error",
            showRawErrorMessage: false,
            loadingScreenData: null,
            instance: null,
            error: "Unexpected problem",
            retry,
          });
        }

        throw error;
      });

    return () => {
      cancel = true;
      gameEngineInstance?.dispose();
      abortController.abort();
    };
  }, [source, retryKey, props.mode]);

  const key = useMemo(() => stringify(source) + retryKey, [source, retryKey]);

  return (
    <div
      ref={containerRef}
      className={classes(
        css.container,
        (props.instanceCreator.status !== "created" ||
          props.mode === "tournament") &&
          css.containerHidden
      )}
      key={key}
    />
  );
}

function botColorBg(slot: NonNullable<SlotSelection>, message: string) {
  const botColor = csx.color(BotColor[slot.color]);

  return chalk
    .bgRgb(botColor.red(), botColor.green(), botColor.blue())
    .whiteBright.bold(` ${message} `);
}

type MoveResponse = z.infer<typeof MoveResponse>;
const MoveResponse = z.object({
  payload: z.string(),
  duration: z.number().positive(),
});

type StartResponse = z.infer<typeof StartResponse>;
const StartResponse = z.object({
  duration: z.number().positive(),
});

export type GameEngineInstance = Unpromise<ReturnType<typeof createGameEngine>>;
async function createGameEngine({
  container,
  source,
  signal,
  gameSpeed,
  onSetOutcome: onSetOutcomeProp,
  getUserLogin,
  onSetTimes,
  onSetActiveBots,
  onSetNotStartedBots,
  setLoadingScreenData,
  isBotNew,
  setBotNewValue,
  mode,
}: {
  container: HTMLDivElement;
  source: Source;
  signal: AbortSignal;
  gameSpeed: {
    get(): GameSpeed;
    set(value: GameSpeed): void;
  };
  getUserLogin(): string | null;
  onSetOutcome?(outcome: GameOutcome): void;
  onSetTimes?(times: number[]): void;
  setLoadingScreenData(data: LoadingScreenData): void;
  onSetActiveBots?(activeBots: Set<number>): void;
  isBotNew(owner: string, repo: string): boolean;
  setBotNewValue(owner: string, repo: string, isNew: boolean): void;
  onSetNotStartedBots(
    fn: (indicesOfNotStartedBots: Set<number>) => Set<number>
  ): void;
  mode: "tournament" | "standard";
}) {
  const finalOutcomeListeners = new Map<
    number,
    (outcome: FinalGameOutcome) => void
  >();

  const onSetOutcome = (gameId: number, outcome: GameOutcome) => {
    onSetOutcomeProp?.(outcome);

    if (outcome.status !== "done") {
      return;
    }

    finalOutcomeListeners.get(gameId)?.(outcome);
    finalOutcomeListeners.delete(gameId);
  };

  const gameConfig = await loadGameConfig(source, signal);

  setLoadingScreenData({
    iconSrc: getGameIconSrc(gameConfig),
    name: gameConfig.name,
  });

  const url = new URL(gameConfig.url);
  const iframe = document.createElement("iframe");
  let normalizedUrl = url.host + url.pathname + url.search;
  if (normalizedUrl.endsWith("/")) {
    normalizedUrl = normalizedUrl.slice(0, -1);
  }
  iframe.src =
    ClientEnv.ALTERNATE_ORIGIN +
    "/dist/game-frame/" +
    document.body.dataset["gameFrameHash"] +
    "/" +
    normalizedUrl;
  // TODO is there any way to remove allow-same-origin here and still have boss bots work?
  // also will probably be needed to game maker support and another vulnerability here would be
  // the ability of games to register a service worker and intercept boss bot requests and
  // substitute their own boss?! I'm sure there are other more malicious things as well.
  // Regardless a wildcard dns would solve some of that.
  iframe.setAttribute("sandbox", "allow-scripts allow-same-origin");
  iframe.className = css.iframe;
  container.appendChild(iframe);

  const iframeWindow =
    iframe.contentWindow ??
    panic("expected iframe.contentWindow to be defined");

  await iframeReady(iframeWindow);

  const loadGame = createProcedureClient<typeof loadGameDefinition>({
    procedureId: "loadGame",
    getTransferableList(_mode, _gameId, assetsPathOrFile) {
      return typeof assetsPathOrFile === "string" ? [] : [assetsPathOrFile];
    },
    returnType: "skipCheck",
    targetOrigin: "*",
    targetWindow: iframeWindow,
  });

  if (source.dev) {
    const file = await devFileManager.getFile(gameConfig.gameId);
    if (file === null) {
      throw "not-found";
    }
    await loadGame(mode, gameConfig.gameId, file);
  } else {
    await loadGame(mode, gameConfig.gameId, gameConfig.url);
  }

  handleIframeMouseDownDefinition.register({
    targetOrigin: "*",
    targetWindow: iframeWindow,
    context: () => {
      container.dispatchEvent(new MouseEvent("mousedown", { bubbles: true }));
    },
  });

  handleDragEnterDefinition.register({
    targetOrigin: "*",
    targetWindow: iframeWindow,
    context: () => {
      container.dispatchEvent(new MouseEvent("dragenter", { bubbles: true }));
    },
  });

  const getConfigSchemaAndPresets = createProcedureClient<
    typeof getConfigSchemaAndPresetsDefinition
  >({
    procedureId: "getConfigSchemaAndPresets",
    targetOrigin: "*",
    targetWindow: iframeWindow,
    // Need skip check here b/c for some reason can't get the types to work
    returnType: "skipCheck",
  });

  const parseConfig = createProcedureClient<typeof parseConfigDefinition>({
    procedureId: "parseConfig",
    targetOrigin: "*",
    targetWindow: iframeWindow,
    returnType: z.object({ error: z.string() }).or(
      z.object({
        config: z.unknown(),
        summary: z.string(),
        serializedConfig: z.string(),
      })
    ),
  });

  const resetGameState = createProcedureClient<typeof resetGameStateDefinition>(
    {
      procedureId: "resetGameState",
      targetOrigin: "*",
      targetWindow: iframeWindow,
      returnType: z.void(),
    }
  );

  const setConfigAndTimeLimit = createProcedureClient<
    typeof setConfigAndTimeLimitDefinition
  >({
    procedureId: "setConfigAndTimeLimit",
    targetOrigin: "*",
    targetWindow: iframeWindow,
    returnType: z.void(),
  });

  const setReplayProgress = createProcedureClient<
    typeof setReplayProgressDefinition
  >({
    procedureId: "setReplayProgress",
    targetOrigin: "*",
    targetWindow: iframeWindow,
    returnType: z.void(),
  });

  const setGameSpeed = createProcedureClient<typeof setGameSpeedDefinition>({
    procedureId: "setGameSpeed",
    targetOrigin: "*",
    targetWindow: iframeWindow,
    returnType: z.void(),
  });

  const setStatus = createProcedureClient<typeof setStatusDefinition>({
    procedureId: "setStatus",
    targetOrigin: "*",
    targetWindow: iframeWindow,
    returnType: z.void(),
  });

  const setActiveGameId = createProcedureClient<
    typeof setActiveGameIdDefinition
  >({
    procedureId: "setActiveGameId",
    targetOrigin: "*",
    targetWindow: iframeWindow,
    returnType: z.void(),
  });

  const botRunners = new Map<string, BotRunner>();

  const playGame = createProcedureClient<typeof playGameDefinition>({
    procedureId: "playGame",
    targetOrigin: "*",
    targetWindow: iframeWindow,
    returnType: z.object({
      gameLength: z.number(),
      botOutcomes: z.array(
        z.enum([
          BotOutcome.Error,
          BotOutcome.Defeat,
          BotOutcome.Draw,
          BotOutcome.Victory,
          BotOutcome.None,
        ])
      ),
    }),
  });

  const getGameLength = createProcedureClient<typeof getGameLengthDefinition>({
    procedureId: "getGameLength",
    targetOrigin: "*",
    targetWindow: iframeWindow,
    returnType: z.number(),
  });

  moveDefinition.register({
    context: botRunners,
    targetOrigin: "*",
    targetWindow: iframeWindow,
  });

  endDefinition.register({
    context: botRunners,
    targetOrigin: "*",
    targetWindow: iframeWindow,
  });

  writeDefinition.register({
    context: botRunners,
    targetOrigin: "*",
    targetWindow: iframeWindow,
  });

  startBotDefinition.register({
    context: botRunners,
    targetOrigin: "*",
    targetWindow: iframeWindow,
  });

  handleGameBotExitDefinition.register({
    context: botRunners,
    targetOrigin: "*",
    targetWindow: iframeWindow,
  });

  const startGameBot = createProcedureClient<typeof startGameBotDefinition>({
    procedureId: "startGameBot",
    targetOrigin: "*",
    targetWindow: iframeWindow,
    returnType: StartResponse,
  });

  const moveGameBot = createProcedureClient<typeof moveGameBotDefinition>({
    procedureId: "moveGameBot",
    targetOrigin: "*",
    targetWindow: iframeWindow,
    returnType: MoveResponse,
  });

  const endGameBot = createProcedureClient<typeof endGameBotDefinition>({
    procedureId: "endGameBot",
    targetOrigin: "*",
    targetWindow: iframeWindow,
    returnType: z.void(),
  });

  const disposeGameBot = createProcedureClient<typeof disposeGameBotDefinition>(
    {
      procedureId: "disposeGameBot",
      targetOrigin: "*",
      targetWindow: iframeWindow,
      returnType: z.void(),
    }
  );

  const setBotColors = createProcedureClient<typeof setBotColorsDefinition>({
    procedureId: "setBotColors",
    targetOrigin: "*",
    targetWindow: iframeWindow,
    returnType: z.void(),
  });

  pauseDefinition.register({
    context: {
      async onPause() {
        gameSpeed.set("paused");
        await setGameSpeed("paused");
      },
    },
    targetOrigin: "*",
    targetWindow: iframeWindow,
  });

  const { configPresets, configSchema, serializedDefaultPreset } = z
    .object({
      configPresets: z.array(z.object({ name: z.string(), value: z.string() })),
      configSchema: z.unknown(),
      serializedDefaultPreset: z.string(),
    })
    .parse(await getConfigSchemaAndPresets());

  await setGameSpeed(gameSpeed.get());

  if (mode === "standard") {
    const createRenderer = createProcedureClient<
      typeof createRendererDefinition
    >({
      procedureId: "createRenderer",
      returnType: z.void(),
      targetOrigin: "*",
      targetWindow: iframeWindow,
    });

    await createRenderer();
    // Aesthetic delay so losing frames on game load is less notable
    await sleep(1000);
  }

  let currentSlotSelections: NonNullable<SlotSelection>[] = [];
  let activeGameId = 0;

  const stdoutListeners = new Map<number, (output: string | Buffer) => void>();
  const clearListeners = new Map<number, () => void>();

  let currentTimeLimit: { game: number | null; move: number | null } = {
    game: null,
    move: null,
  };

  let currentSerializedConfig: string | null = null;

  const setFinalOutcome = async (
    gameId: number,
    botOutcomes: BotOutcome[],
    times: number[][],
    gameLength: number,
    getTimeLimitExceededMessage?: (index: number) => string | null
  ) => {
    if (gameId !== activeGameId) {
      return;
    }

    activeGameId++;
    setActiveGameId(activeGameId);
    const messageGameId = activeGameId;

    await disposeBotRunners(botRunners);

    if (messageGameId !== activeGameId) {
      return;
    }

    const printMessage = async (index: number, message: string) => {
      if (mode === "tournament") {
        return;
      }

      for (const part of message.split("")) {
        await sleep(/\s+/.test(part) ? 0 : 25);

        if (activeGameId !== messageGameId) {
          return;
        }

        if (part === "■") {
          await sleep(80);
        } else {
          stdoutListeners.get(index)?.(part);
        }
      }
    };

    const hasDefaultConfig =
      currentSerializedConfig === serializedDefaultPreset &&
      currentTimeLimit.game === gameConfig.defaultTimeLimitMilliseconds.game &&
      currentTimeLimit.move === gameConfig.defaultTimeLimitMilliseconds.move;

    const primaryOutcome = getPrimaryOutcome(
      getUserLogin(),
      botOutcomes.map((outcome, index) => {
        return {
          outcome,
          index,
          slot: currentSlotSelections[index] ?? null,
          time: getTotalTime(times, index),
        };
      }),
      !hasDefaultConfig
    );

    const finalOutcome: GameOutcome = {
      replayProgress: null,
      botOutcomes: currentSlotSelections.map(
        (slot, index): BotOutcomeListItem => {
          const outcome = botOutcomes[index] ?? BotOutcome.None;

          const message = {
            [BotOutcome.ConnectionProblem]: "Offline",
            [BotOutcome.Defeat]: "Defeat",
            [BotOutcome.Draw]: "Draw",
            [BotOutcome.Error]: "Error",
            [BotOutcome.GameError]: "System Error",
            [BotOutcome.GameLocked]: "Game Locked",
            [BotOutcome.None]: "Stopped",
            [BotOutcome.TimeLimitExceeded]: "Time Limit Exceeded",
            [BotOutcome.Unimplemented]: "New Bot",
            [BotOutcome.Victory]: "Victory",
          }[outcome];

          if (messageGameId !== activeGameId) {
            throw new Error("Not active " + messageGameId);
          }

          stdoutListeners.get(index)?.("\n" + botColorBg(slot, message) + "\n");

          if (outcome === BotOutcome.Error) {
            printMessage(
              index,
              `■■■\n${chalk.whiteBright.bold(
                "Well done!■■■ You found a bug."
              )}■■■■\n${chalk.white(
                `So,■■■ what's next?\n\n■■${chalk.dim(
                  "(1)"
                )} See if there's a useful\n    error message above.\n■■■■${chalk.dim(
                  "(2)"
                )} If not,■ check out Zilch's\n    debugging guide.`
              )}\n`
            );
          }

          if (
            outcome === BotOutcome.TimeLimitExceeded &&
            getTimeLimitExceededMessage
          ) {
            const message = getTimeLimitExceededMessage(index);
            if (message) {
              printMessage(index, message);
            }
          }

          if (
            outcome === BotOutcome.Victory &&
            primaryOutcome?.type === "victory-over-boss-but-incompatible-config"
          ) {
            printMessage(
              index,
              `\n■■■■${chalk.whiteBright.bold(
                "*Non-standard game config\n detected.■■■■■"
              )}\n\n Unfortunately,■■■ that victory\n doesn't win you any boss\n bot stars.■■■■■■■\n\n Reset the game config to\n ensure your wins count.\n\n\n`
            );
          }

          if (outcome === BotOutcome.Unimplemented) {
            const botColor = csx.color(
              BotColor[currentSlotSelections[index]?.color ?? "blue"]
            );

            printMessage(
              index,
              `\n${chalk.bold.rgb(
                botColor.red(),
                botColor.green(),
                botColor.blue()
              )(
                "DEBUGGING TIP:"
              )}■■■■■ Everything your bot prints out will show up here in the terminal.\n`
            );
          }

          return {
            index,
            outcome,
            slot: currentSlotSelections[index],
            time: getTotalTime(times, index),
          };
        }
      ),
      primary: primaryOutcome,
      status: "done",
      gameLength,
    };

    onSetOutcome(gameId, finalOutcome);

    return finalOutcome;
  };
  let times: number[][] = [];

  return {
    gameConfig,
    configPresets,
    configSchema,
    parseConfig,
    setReplayProgress,
    async setConfigAndTimeLimit(
      serializedConfig: string,
      config: unknown,
      timeLimit: { game: number | null; move: number | null }
    ) {
      currentTimeLimit = {
        game: timeLimit.game,
        move: timeLimit.move,
      };
      currentSerializedConfig = serializedConfig;
      await setConfigAndTimeLimit(config, timeLimit);
    },
    setGameSpeed,
    setStatus,
    setSlotSelections(slots: SlotSelection[]) {
      setBotColors(
        slots.map((value) => (value ? BotColor[value.color] : null))
      );
    },
    resetGameState,
    stdoutListeners,
    clearListeners,
    async playGame(slotSelections: NonNullable<SlotSelection>[]) {
      const run = async (gameId: number) => {
        times = slotSelections.map(() => []);
        resetGameState();

        const gameTimeLimit = currentTimeLimit.game;
        const moveTimeLimit = currentTimeLimit.move;

        currentSlotSelections = cloneDeep(slotSelections);

        if (gameSpeed.get() === GameSpeed.Step) {
          gameSpeed.set(GameSpeed.Normal);
          setGameSpeed(GameSpeed.Normal);
        }

        clearListeners.forEach((clear) => clear());
        stdoutListeners.forEach((listener) => {
          listener(chalk.bold("\x1bc⚙️  Initializing") + "\n");
        });

        onSetOutcome(gameId, { status: "in-progress" });
        onSetNotStartedBots(
          () => new Set(slotSelections.map((_, index) => index))
        );

        await setActiveGameId(gameId);
        if (gameId !== activeGameId) {
          return;
        }

        const activeBots = new Set<number>();
        const triggerTimesUpdate = async () => {
          if (gameId !== activeGameId) {
            return;
          }

          const totals: number[] = [];
          const gameTimeLimitExceededData = new Map<number, number>();
          const moveTimeLimitExceededData = new Map<number, number>();
          for (let i = 0; i < times.length; i++) {
            const botTimes = times[i] ?? [];
            const mostRecentBotTime = botTimes[botTimes.length - 1] ?? 0;
            const totalBotTime = getTotalTime(times, i);
            totals.push(totalBotTime);

            if (moveTimeLimit !== null && mostRecentBotTime > moveTimeLimit) {
              moveTimeLimitExceededData.set(i, mostRecentBotTime);
            }

            if (gameTimeLimit !== null && totalBotTime > gameTimeLimit) {
              gameTimeLimitExceededData.set(i, totalBotTime);
            }
          }

          onSetTimes?.(totals);

          if (
            gameTimeLimitExceededData.size > 0 ||
            moveTimeLimitExceededData.size > 0
          ) {
            // TODO don't do time limit exceeded if they didn't go over the actual allotted time.
            setFinalOutcome(
              gameId,
              currentSlotSelections.map((_, index) =>
                gameTimeLimitExceededData.has(index) ||
                moveTimeLimitExceededData.has(index)
                  ? BotOutcome.TimeLimitExceeded
                  : BotOutcome.None
              ),
              times,
              await getGameLength(),
              (index) => {
                const violatingGameTime =
                  gameTimeLimitExceededData.get(index) ?? null;
                const violatingMoveTime =
                  moveTimeLimitExceededData.get(index) ?? null;

                if (violatingGameTime === null && violatingMoveTime === null) {
                  return null;
                }

                let message = `■■■\n${chalk.whiteBright.bold(
                  "Too slow.■■■ Time to optimize!"
                )}■■■■\n\n`;

                for (const type of ["game", "move"] as const) {
                  const violatingTime =
                    type === "game" ? violatingGameTime : violatingMoveTime;
                  const timeLimit =
                    type === "game" ? gameTimeLimit : moveTimeLimit;

                  if (violatingTime === null) {
                    continue;
                  }

                  message +=
                    chalk.whiteBright.bold(
                      "Limit  :■■ " + stringifyTimeLimit(timeLimit, type)
                    ) + "\n■■■";
                  message += chalk.redBright.bold(
                    `Actual : ${stringifyBotTime(violatingTime)}`
                  );
                  message += "\n\n";
                }

                message += chalk.whiteBright(
                  `${chalk.bold(
                    "Need a hand?"
                  )}■■■■ Optimize your\noptimizations w/ performance\ntips from Zilch.\n\n`
                );

                return message;
              }
            );
          }
        };
        onSetActiveBots?.(new Set());

        await triggerTimesUpdate();
        await disposeBotRunners(botRunners);

        if (mode === "standard") {
          await sleep(700);
        }

        if (gameId !== activeGameId) {
          return;
        }

        const botRunnersForGame = await Promise.all(
          currentSlotSelections.map((slotSelection, botIndex) => {
            if (slotSelection.type === BotType.User) {
              return createUserBotRunner({
                botIndex,
                slotSelection,
                isBotNew() {
                  return isBotNew(slotSelection.owner, slotSelection.repo);
                },
                onStdout(data) {
                  if (gameId === activeGameId) {
                    stdoutListeners.get(botIndex)?.(data);
                  }
                },
                onStderr(data) {
                  if (gameId === activeGameId) {
                    stdoutListeners.get(botIndex)?.(chalk.red(data));
                  }
                },
                async onAddMoveDuration(duration) {
                  times[botIndex]?.push(duration);
                  await triggerTimesUpdate();
                },
                async onEarlyExit(outcome) {
                  if (gameId === activeGameId) {
                    setFinalOutcome(
                      gameId,
                      currentSlotSelections.map((_, index) => {
                        return index === botIndex ? outcome : BotOutcome.None;
                      }),
                      times,
                      await getGameLength()
                    );
                  }
                },
                onStarted() {
                  setBotNewValue(
                    slotSelection.owner,
                    slotSelection.repo,
                    false
                  );
                  onSetNotStartedBots((notStartedBots) => {
                    const newNotStartedBots = new Set(notStartedBots);
                    newNotStartedBots.delete(botIndex);
                    return newNotStartedBots;
                  });
                },
                onSetActive(active) {
                  if (active) {
                    activeBots.add(botIndex);
                  } else {
                    activeBots.delete(botIndex);
                  }
                  onSetActiveBots?.(cloneDeep(activeBots));
                },
                async createBotInstance(params) {
                  return await createBotInstance(
                    slotSelection.owner,
                    slotSelection.repo,
                    params
                  ).catch((error) => {
                    params.onError(error);
                    throw error;
                  });
                },
              });
            } else {
              return createGameBotRunner({
                onStarted() {
                  onSetNotStartedBots((notStartedBots) => {
                    const newNotStartedBots = new Set(notStartedBots);
                    newNotStartedBots.delete(botIndex);
                    return newNotStartedBots;
                  });
                },
                gameConfig,
                type:
                  slotSelection.type === BotType.Boss
                    ? (
                        {
                          easy: "boss-easy",
                          medium: "boss-medium",
                          hard: "boss-hard",
                        } as const
                      )[slotSelection.difficulty]
                    : "practice",
                botIndex,
                onStdout: (data) => {
                  stdoutListeners.get(botIndex)?.(data);
                },
                async onAddMoveDuration(duration) {
                  times[botIndex]?.push(duration);
                  await triggerTimesUpdate();
                },
                async onDisposed() {
                  if (gameId === activeGameId) {
                    setFinalOutcome(
                      gameId,
                      currentSlotSelections.map((_, index) => {
                        return index === botIndex
                          ? BotOutcome.Error
                          : BotOutcome.None;
                      }),
                      times,
                      await getGameLength()
                    );
                  }
                },
                onSetActive: (active) => {
                  if (active) {
                    activeBots.add(botIndex);
                  } else {
                    activeBots.delete(botIndex);
                  }
                  onSetActiveBots?.(cloneDeep(activeBots));
                },
              });
            }
          })
        );

        if (gameId !== activeGameId) {
          return;
        }

        const newBotIndices = new Set<number>();
        const noConnectionIndices = new Set<number>();

        for (let i = 0; i < botRunnersForGame.length; i++) {
          const botRunner =
            botRunnersForGame[i] ?? panic("expected bot runner to be defined");
          if (typeof botRunner !== "string") {
            botRunners.set(botRunner.botInstanceId, botRunner);
          } else if (botRunner === "no-connection") {
            noConnectionIndices.add(i);
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          } else if (botRunner === "new-bot") {
            newBotIndices.add(i);
          }
        }

        if (newBotIndices.size > 0) {
          await setFinalOutcome(
            gameId,
            slotSelections.map((_, index) => {
              return newBotIndices.has(index)
                ? BotOutcome.Unimplemented
                : BotOutcome.None;
            }),
            times,
            0
          );
          return;
        }

        if (noConnectionIndices.size > 0) {
          await setFinalOutcome(
            gameId,
            slotSelections.map((_, index) => {
              return noConnectionIndices.has(index)
                ? BotOutcome.ConnectionProblem
                : BotOutcome.None;
            }),
            times,
            0
          );
          return;
        }

        botRunnersForGame.forEach((botRunner, index) => {
          const slot = currentSlotSelections[index];
          if (typeof botRunner === "string" || !slot) {
            return;
          }

          botRunner.write("\n" + botColorBg(slot, "Ready") + "\n\n");
        });

        const { gameLength, botOutcomes } = await playGame(
          gameId,
          botRunnersForGame.map((botRunner) => {
            if (typeof botRunner === "string") {
              panic("unexpected string value for bot runner");
            }

            return botRunner.botInstanceId;
          }),
          gameConfig.moveDelay
        ).catch(async (error) => {
          if (gameId === activeGameId) {
            await setFinalOutcome(
              gameId,
              slotSelections.map(() => BotOutcome.GameError),
              times,
              0
            );
          }

          throw error;
        });

        if (gameId !== activeGameId) {
          return;
        }

        await setFinalOutcome(gameId, botOutcomes, times, gameLength);
      };

      const gameId = ++activeGameId;
      const finalOutcomeOperation = new Promise<FinalGameOutcome>((resolve) => {
        finalOutcomeListeners.set(gameId, resolve);
      });

      return Promise.race([
        finalOutcomeOperation,
        run(gameId).then(() => null),
      ]);
    },
    async stop() {
      await setFinalOutcome(
        activeGameId,
        new Array(currentSlotSelections.length).fill(
          BotOutcome.None
        ) as BotOutcome[],
        times,
        await getGameLength()
      );
    },
    async dispose() {
      await disposeBotRunners(botRunners);
    },
  };

  function createGameBotRunner({
    botIndex,
    type,
    onAddMoveDuration,
    onStdout,
    onSetActive,
    onDisposed,
    gameConfig,
    onStarted,
  }: {
    type: "practice" | "boss-easy" | "boss-medium" | "boss-hard";
    botIndex: number;
    onStdout: (message: string) => void;
    onAddMoveDuration: (duration: number) => Promise<void>;
    onSetActive: (active: boolean) => void;
    onStarted(): void;
    onDisposed: () => void;
    gameConfig: GameConfig;
  }) {
    const botInstanceId = crypto.randomUUID();

    const botRunner: BotRunner = {
      botInstanceId,
      async start(gameTimeLimit, moveTimeLimit, serializedConfig) {
        onSetActive(true);

        const { duration } = await startGameBot(
          gameConfig.url + "/",
          botInstanceId,
          {
            botIndex,
            serializedConfig,
            timeLimit: {
              game: gameTimeLimit ?? Number.MAX_SAFE_INTEGER,
              move: moveTimeLimit ?? Number.MAX_SAFE_INTEGER,
            },
            type,
          }
        );
        onStarted();
        onSetActive(false);
        await onAddMoveDuration(duration);
        return { duration };
      },
      dispose() {
        disposeGameBot(botInstanceId);
        onDisposed();
      },
      async move(payload) {
        onSetActive(true);
        const response = await moveGameBot(botInstanceId, payload);
        onSetActive(false);
        await onAddMoveDuration(response.duration);
        return response;
      },
      async end(payload) {
        await endGameBot(botInstanceId, payload);
      },
      write(message) {
        onStdout(message);
      },
    };

    return botRunner;
  }
}

function getTotalTime(times: number[][], botIndex: number) {
  return (times[botIndex] ?? []).reduce((current, total) => current + total, 0);
}

async function disposeBotRunners(botRunners: Map<string, BotRunner>) {
  await Promise.all(
    Array.from(botRunners.values()).map((botRunner) => {
      botRunners.delete(botRunner.botInstanceId);
      return botRunner.dispose();
    })
  );
}

interface BotRunner {
  botInstanceId: string;
  start(
    gameTimeLimit: number | null,
    moveTimeLimit: number | null,
    serializedConfig: string
  ): Promise<{ duration: number }>;
  write(message: string): void;
  move(payload: string): Promise<{ payload: string; duration: number }>;
  end(payload: string): Promise<void>;
  dispose(): void;
}

interface BotInstance {
  start(payload: string): Promise<StartResponse>;
  move(payload: string): Promise<MoveResponse>;
  end(payload: string): void;
  dispose(): void;
}

interface CreateBotInstanceParams {
  botInstanceId: string;
  onStdout(stdout: string): void;
  onStderr(stderr: string): void;
  onError(error: unknown): void;
  onExit(exit: number | null): void;
  isBotNew(): boolean;
}

async function createBotInstance(
  owner: string,
  repo: string,
  params: CreateBotInstanceParams
): Promise<BotInstance | "no-connection" | "new-bot"> {
  let disposing = false;

  const socket = new WebSocket(
    `${ClientEnv.ORIGIN.replace(
      "https",
      "wss"
    )}/api/tunnel/bot?bot=${owner}.${repo}&clientId=${params.botInstanceId}`
  );

  const connected = await new Promise<boolean>((resolve) => {
    const cleanUp = () => {
      socket.removeEventListener("close", onClose);
      socket.removeEventListener("error", onError);
      socket.removeEventListener("open", onOpen);
    };

    const onClose = () => {
      cleanUp();
      resolve(false);
    };

    const onError = () => {
      cleanUp();
      resolve(false);
    };

    const onOpen = () => {
      cleanUp();
      resolve(true);
    };

    socket.addEventListener("close", onClose);
    socket.addEventListener("error", onError);
    socket.addEventListener("open", onOpen);
  });

  if (!connected) {
    return params.isBotNew() ? "new-bot" : "no-connection";
  }

  let onStarted: ((payload: string) => void) | null = null;
  let onMoved: ((payload: string) => void) | null = null;

  socket.addEventListener("message", (event) => {
    const { command, payload } = parseBotMessage(event.data);

    if (command === "stdout") {
      params.onStdout(payload);
    } else if (command === "stderr") {
      params.onStderr(payload);
    } else if (command === "exit" && !disposing) {
      let exitCode: number | null = null;
      try {
        if (payload !== "null") {
          exitCode = parseInt(payload);
        }
      } catch (error) {
        console.error("problem parsing exit payload", error);
      }
      params.onStderr("Exit " + exitCode);
      params.onExit(exitCode);
    } else if (command === "start") {
      onStarted?.(payload);
      onStarted = null;
    } else if (command === "move") {
      onMoved?.(payload);
      onMoved = null;
    }
  });

  socket.addEventListener("close", () => {
    if (!disposing) {
      params.onError("unexpected close");
    }
  });

  socket.addEventListener("error", () => {
    params.onError("socket error");
  });

  return {
    move(payload) {
      return new Promise<MoveResponse>((resolve, reject) => {
        onMoved = (response: string) => {
          let move: MoveResponse;
          try {
            move = MoveResponse.parse(JSON.parse(response));
          } catch (error) {
            reject(error);
            return;
          }
          resolve(move);
        };
        socket.send(`move,${payload}`);
      });
    },
    async start(payload) {
      return new Promise<StartResponse>((resolve, reject) => {
        onStarted = (response: string) => {
          let startResult: StartResponse;
          try {
            startResult = StartResponse.parse(JSON.parse(response));
          } catch (error) {
            reject(error);
            return;
          }
          resolve(startResult);
        };
        socket.send(`start,${payload}`);
      });
    },
    end(payload) {
      disposing = true;
      socket.send(`end,${payload}`);
    },
    dispose() {
      disposing = true;
      if (
        socket.readyState === socket.OPEN ||
        socket.readyState === socket.CONNECTING
      ) {
        socket.close();
      }
    },
  };
}

function parseBotMessage(message: unknown) {
  const data = (message as string).toString();
  const splitIndex = data.indexOf(",");
  const command = data.slice(0, splitIndex);
  const payload = data.slice(splitIndex + 1);

  return {
    command,
    payload,
  };
}

async function createUserBotRunner(options: {
  botIndex: number;
  slotSelection: NonNullable<SlotSelection>;
  onStdout(data: Buffer | string): void;
  onStderr(data: Buffer | string): void;
  onEarlyExit(outcome: BotOutcome): void;
  onStarted(): void;
  onAddMoveDuration(duration: number): Promise<void>;
  onSetActive(active: boolean): void;
  createBotInstance(
    params: CreateBotInstanceParams
  ): Promise<BotInstance | "new-bot" | "no-connection">;
  isBotNew(): boolean;
}) {
  let disposing = false;

  if (options.slotSelection.type !== "user") {
    panic("Expected bot to be user bot");
  }

  const botInstanceId = crypto.randomUUID();

  const botInstance = await options.createBotInstance({
    botInstanceId,
    onStderr: options.onStderr,
    onStdout: options.onStdout,
    onError: (error) => {
      console.error("BOT INSTANCE ERROR", error);
      options.onEarlyExit(
        options.isBotNew()
          ? BotOutcome.Unimplemented
          : BotOutcome.ConnectionProblem
      );
    },
    onExit(code) {
      if (disposing) {
        return;
      }
      options.onStderr(`\nExit code ${code}\n`);
      options.onEarlyExit(BotOutcome.Error);
    },
    isBotNew: options.isBotNew,
  });

  if (typeof botInstance === "string") {
    return botInstance;
  }

  const botRunner: BotRunner = {
    botInstanceId: botInstanceId,
    async start(
      gameTimeLimit: number | null,
      moveTimeLimit: number | null,
      serializedConfig: string
    ) {
      options.onSetActive(true);

      const { duration } = await botInstance.start(
        `${gameTimeLimit ?? Number.MAX_SAFE_INTEGER},${
          moveTimeLimit ?? Number.MAX_SAFE_INTEGER
        },${options.botIndex}.${serializedConfig}`
      );

      options.onSetActive(false);
      options.onStarted();
      options.onAddMoveDuration(duration);

      return { duration };
    },
    write(message: string) {
      options.onStdout(message);
    },
    async end(payload: string) {
      disposing = true;
      await botInstance.end(payload);
      await sleep(200);
    },
    async move(payload: string) {
      options.onSetActive(false);
      const response = await botInstance.move(payload);
      options.onSetActive(true);
      options.onAddMoveDuration(response.duration);

      return response;
    },
    dispose() {
      disposing = true;
      botInstance.dispose();
    },
  };

  return botRunner;
}

export const handleIframeMouseDownDefinition = defineProcedure({
  procedureId: "handleIframeMouseDown",
  parametersType: "skipCheck",
  async procedure(handleIframeMouseDown: () => void) {
    handleIframeMouseDown();
  },
});

export const handleDragEnterDefinition = defineProcedure({
  procedureId: "handleDragEnter",
  parametersType: "skipCheck",
  async procedure(handleDragEnter: () => void) {
    handleDragEnter();
  },
});

export const handleGameBotExitDefinition = defineProcedure({
  procedureId: "handleGameBotExit",
  parametersType: z.tuple([z.string()]),
  async procedure(botRunners: Map<string, BotRunner>, botInstanceId: string) {
    botRunners.get(botInstanceId)?.dispose();
  },
});

export const moveDefinition = defineProcedure({
  procedureId: "move",
  parametersType: z.tuple([z.string(), z.string()]),
  async procedure(
    botRunners: Map<string, BotRunner>,
    botInstanceId: string,
    payload: string
  ) {
    const botRunner =
      botRunners.get(botInstanceId) ??
      panic("no bot runner with id " + botInstanceId);
    return await botRunner.move(payload);
  },
});

export const endDefinition = defineProcedure({
  procedureId: "end",
  parametersType: z.tuple([z.string(), z.string()]),
  async procedure(
    botRunners: Map<string, BotRunner>,
    botInstanceId: string,
    payload: string
  ) {
    const botRunner =
      botRunners.get(botInstanceId) ??
      panic("no bot runner with id " + botInstanceId);
    return await botRunner.end(payload);
  },
});

export const writeDefinition = defineProcedure({
  procedureId: "write",
  parametersType: z.tuple([z.string(), z.string()]),
  async procedure(
    botRunners: Map<string, BotRunner>,
    botInstanceId: string,
    message: string
  ) {
    const botRunner =
      botRunners.get(botInstanceId) ??
      panic("no bot runner with id " + botInstanceId);
    botRunner.write(message);
  },
});

export const startBotDefinition = defineProcedure({
  procedureId: "startBot",
  parametersType: z.tuple([
    z.string(),
    z.number().int().nullable(),
    z.number().int().nullable(),
    z.string(),
  ]),
  async procedure(
    botRunners: Map<string, BotRunner>,
    botInstanceId: string,
    gameTimeLimit: number | null,
    moveTimeLimit: number | null,
    serializedConfig: string
  ) {
    const botRunner =
      botRunners.get(botInstanceId) ??
      panic("no bot runner with id " + botInstanceId);
    return await botRunner.start(
      gameTimeLimit,
      moveTimeLimit,
      serializedConfig
    );
  },
});

export const readyDefinition = defineProcedure({
  procedureId: "ready",
  parametersType: "skipCheck",
  async procedure(context: { onReady(): void }) {
    context.onReady();
  },
});

export const pauseDefinition = defineProcedure({
  procedureId: "pause",
  parametersType: "skipCheck",
  async procedure(context: { onPause(): void }) {
    context.onPause();
  },
});

async function iframeReady(iframeWindow: Window, signal?: AbortSignal) {
  await new Promise<void>((resolve) => {
    const disposeReady = readyDefinition.register({
      targetWindow: iframeWindow,
      targetOrigin: "*",
      context: {
        onReady() {
          disposeReady();
          resolve();
        },
      },
    });

    const onAbort = () => {
      disposeReady();
      signal?.removeEventListener("abort", onAbort);
    };

    signal?.addEventListener("abort", onAbort);
  });
}

function getPrimaryOutcome(
  userLogin: string | null,
  botOutcomeList: BotOutcomeList | null,
  configIncompatibleWithBossVictory: boolean
): PrimaryOutcome | null {
  if (botOutcomeList === null) {
    return null;
  }

  const errorOutcomes: BotOutcomeList = [];
  const unimplementedOutcomes: BotOutcomeList = [];
  const connectionProblemOutcomes: BotOutcomeList = [];
  const victoryOutcomes: BotOutcomeList = [];
  const bossVictoryOutcomes: BotOutcomeList = [];
  const practiceVictoryOutcomes: BotOutcomeList = [];
  const ownerBotVictoryOutcomes: BotOutcomeList = [];
  const nonOwnerUserBotVictoryOutcomes: BotOutcomeList = [];
  const defeatOutcomes: BotOutcomeList = [];
  const drawOutcomes: BotOutcomeList = [];
  const noneOutcomes: BotOutcomeList = [];
  const timeLimitExceededOutcomes: BotOutcomeList = [];
  const gameLockedOutcomes: BotOutcomeList = [];
  const gameErrorOutcomes: BotOutcomeList = [];

  let nonVictoriousBossBotCount = 0;
  let nonVictoriousPracticeBotCount = 0;
  let nonOwnerUserBotCount = 0;
  let ownerBotCount = 0;

  for (let i = 0; i < botOutcomeList.length; i++) {
    const botOutcome = botOutcomeList[i]!;

    if (botOutcome.slot?.type === BotType.User) {
      if (botOutcome.slot.owner === userLogin) {
        ownerBotCount++;
      } else {
        nonOwnerUserBotCount++;
      }
    }

    if (
      botOutcome.slot?.type === BotType.Boss &&
      botOutcome.outcome !== BotOutcome.Victory
    ) {
      nonVictoriousBossBotCount++;
    }

    if (
      botOutcome.slot?.type === BotType.Practice &&
      botOutcome.outcome !== BotOutcome.Victory
    ) {
      nonVictoriousPracticeBotCount++;
    }

    if (botOutcome.outcome === BotOutcome.GameError) {
      gameErrorOutcomes.push(botOutcome);
    } else if (botOutcome.outcome === BotOutcome.Error) {
      errorOutcomes.push(botOutcome);
    } else if (botOutcome.outcome === BotOutcome.Unimplemented) {
      unimplementedOutcomes.push(botOutcome);
    } else if (botOutcome.outcome === BotOutcome.ConnectionProblem) {
      connectionProblemOutcomes.push(botOutcome);
    } else if (botOutcome.outcome === BotOutcome.Victory) {
      victoryOutcomes.push(botOutcome);

      if (botOutcome.slot?.type === BotType.Boss) {
        bossVictoryOutcomes.push(botOutcome);
      }

      if (botOutcome.slot?.type === BotType.Practice) {
        practiceVictoryOutcomes.push(botOutcome);
      }

      if (botOutcome.slot?.type === BotType.User) {
        if (botOutcome.slot.owner === userLogin) {
          ownerBotVictoryOutcomes.push(botOutcome);
        } else {
          nonOwnerUserBotVictoryOutcomes.push(botOutcome);
        }
      }
    } else if (botOutcome.outcome === BotOutcome.Defeat) {
      defeatOutcomes.push(botOutcome);
    } else if (botOutcome.outcome === BotOutcome.Draw) {
      drawOutcomes.push(botOutcome);
    } else if (botOutcome.outcome === BotOutcome.None) {
      noneOutcomes.push(botOutcome);
    } else if (botOutcome.outcome === BotOutcome.TimeLimitExceeded) {
      timeLimitExceededOutcomes.push(botOutcome);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (botOutcome.outcome === BotOutcome.GameLocked) {
      gameLockedOutcomes.push(botOutcome);
    } else {
      panic("Encountered unexpected outcome", botOutcome.outcome);
    }
  }

  if (gameLockedOutcomes.length > 0) {
    return {
      subjectIndices: gameLockedOutcomes.map(({ index }) => index),
      type: "game-locked",
    };
  }

  if (noneOutcomes.length === botOutcomeList.length) {
    return {
      subjectIndices: noneOutcomes.map(({ index }) => index),
      type: "stopped",
    };
  }

  if (errorOutcomes.length > 0) {
    return {
      type: "bug-detected",
      subjectIndices: errorOutcomes.map(({ index }) => index),
    };
  }

  if (unimplementedOutcomes.length > 0) {
    return {
      type: "unimplemented",
      subjectIndices: unimplementedOutcomes.map(({ index }) => index),
    };
  }

  if (connectionProblemOutcomes.length > 0) {
    return {
      type: "connection-problem",
      subjectIndices: connectionProblemOutcomes.map(({ index }) => index),
    };
  }

  if (
    bossVictoryOutcomes.length > 0 &&
    ownerBotCount + nonOwnerUserBotCount > 0
  ) {
    const bossIndices = new Set(bossVictoryOutcomes.map(({ index }) => index));
    return {
      type: "defeat-by-boss",
      subjectIndices: botOutcomeList
        .map((_, index) => index)
        .filter((index) => !bossIndices.has(index)),
    };
  }

  if (
    practiceVictoryOutcomes.length > 0 &&
    ownerBotCount + nonOwnerUserBotCount > 0
  ) {
    const practiceIndices = new Set(
      practiceVictoryOutcomes.map(({ index }) => index)
    );
    return {
      type: "defeat-by-practice",
      subjectIndices: botOutcomeList
        .map((_, index) => index)
        .filter((index) => !practiceIndices.has(index)),
    };
  }

  if (
    ownerBotVictoryOutcomes.length > 0 &&
    nonVictoriousBossBotCount > 0 &&
    ownerBotCount > 0
  ) {
    return {
      type: configIncompatibleWithBossVictory
        ? "victory-over-boss-but-incompatible-config"
        : "victory-over-boss",
      subjectIndices: victoryOutcomes.map(({ index }) => index),
    };
  }

  if (
    ownerBotVictoryOutcomes.length > 0 &&
    nonVictoriousPracticeBotCount > 0 &&
    ownerBotCount > 0
  ) {
    return {
      type: "victory-over-practice",
      subjectIndices: victoryOutcomes.map(({ index }) => index),
    };
  }

  if (victoryOutcomes.length > 0) {
    return {
      type: "victory",
      subjectIndices: victoryOutcomes.map(({ index }) => index),
    };
  }

  if (defeatOutcomes.length > 0) {
    return {
      type: "defeat",
      subjectIndices: defeatOutcomes.map(({ index }) => index),
    };
  }

  if (drawOutcomes.length > 0) {
    return {
      type: "draw",
      subjectIndices: drawOutcomes.map(({ index }) => index),
    };
  }

  if (gameErrorOutcomes.length > 0) {
    return {
      type: "game-error",
      subjectIndices: gameErrorOutcomes.map(({ index }) => index),
    };
  }

  if (timeLimitExceededOutcomes.length > 0) {
    return {
      type: "time-limit-exceeded",
      subjectIndices: timeLimitExceededOutcomes.map(({ index }) => index),
    };
  }

  return null;
}

export function getGameIconSrc(gameConfig: GameConfig) {
  if (gameConfig.dev) {
    // TODO figure out way to load actual icon if it has an
    // /assets/icon.png file
    return gameMakerPng;
  }

  let iconSrc = gameConfig.url + "/assets/icon.png";

  if (gameConfig.gameId === "tic-tac-toe") {
    iconSrc = ticTacToePng;
  } else if (gameConfig.gameId === "chess") {
    iconSrc = chessPng;
  } else if (gameConfig.gameId === "table-tennis") {
    iconSrc = tableTennisPng;
  }

  return iconSrc;
}
