import { panic } from "@zilch/panic";

const avatarSize = 10;
const charset = createCharset(
  // 64 characters
  "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_"
);
const numAvatarBits = avatarSize ** 2;
const encodingLength = Math.ceil(numAvatarBits / charset.charBits);

function createCharset(charset: string) {
  const map = new Map(charset.split("").map((char, index) => [char, index]));

  return {
    charBits: Math.floor(Math.log2(charset.length)),
    getInteger: (char: string): number | null => map.get(char) ?? null,
    getChar: (integer: number): string | null => charset[integer] ?? null,
  };
}

export const avatarSerializer = {
  toString(avatar: boolean[][]): string {
    let bitHolder = "";
    let serializeValue = "";
    for (let y = 0; y < avatarSize; y++) {
      for (let x = 0; x < avatarSize; x++) {
        const filled = avatar[y]?.[x] ?? false;
        bitHolder += filled ? "1" : "0";

        if (bitHolder.length === charset.charBits) {
          addBits();
        }
      }
    }

    if (bitHolder.length > 0) {
      bitHolder = bitHolder.padEnd(charset.charBits, "0");
      addBits();
    }

    return serializeValue;

    function addBits() {
      const char = charset.getChar(parseInt(bitHolder, 2));
      if (char === null) {
        panic("attempted to get character outside range 0-64");
      }
      serializeValue += char;
      bitHolder = "";
    }
  },

  fromString(serialized: string): boolean[][] | null {
    if (
      typeof serialized !== "string" ||
      serialized.length !== encodingLength
    ) {
      return null;
    }

    const avatar = new Array<boolean[]>(avatarSize)
      .fill([])
      .map(() => new Array(avatarSize).fill(false));

    let position = 0;

    for (const char of serialized) {
      const integer = charset.getInteger(char);

      if (integer === null) {
        return null;
      }

      integer
        .toString(2)
        .padStart(charset.charBits, "0")
        .split("")
        .forEach((value) => {
          if (value === "1" && position < numAvatarBits) {
            const x = position % avatarSize;
            const y = Math.floor(position / avatarSize);
            avatar[y]![x] = true;
          }
          position++;
        });
    }

    return avatar;
  },
};
