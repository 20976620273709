.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 60px;
  right: 60px;
  height: 200px;
  background: var(--green3);
  border: 1px solid var(--green4);
  border-radius: 8px;
  transition:
    transform cubic-bezier(0.5, 1.5, 0.5, 1) 0.5s 0.3s,
    opacity ease 0.5s 0.3s,
    background ease 0.2s,
    border ease 0.2s;
}

.priceLabel {
  position: absolute;
  left: -8px;
  top: -8px;
  padding: 10px 15px;
  background: var(--green4);
  border-radius: 8px;
  color: var(--white);
  transition: all ease 0.3s;
  font-size: 18px;
  font-weight: 800;
  line-height: 18px;
}

.containerHidden {
  transform: scale(0.7) translateY(20px);
  transition: all ease 0.2s;
  pointer-events: none;
  opacity: 0;
}

.amount {
  position: absolute;
  transition: all cubic-bezier(0.5, 1.5, 0.5, 1) 0.4s;
}

.priceContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: all ease 0.3s;
  padding-left: 40px;
  transform: translateY(-2px);
  pointer-events: none;
}

.priceCents {
  display: inline-block;
  width: 37px;
}

.priceTopLine {
  font-size: 30px;
}

.priceBottomLine {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.65);
}

.perSeatPerMonth {
  font-size: 20px;
}

.dollarAmountContainer {
  overflow: hidden;
  position: relative;
  font-size: 96px;
  font-weight: 900;
  text-align: right;
  margin-right: 10px;
  margin-left: 8px;
  transition: all ease 0.3s;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
