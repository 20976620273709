.animationContainer {
  position: absolute;
  top: 0;
  left: 0;
}

.starContainer {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -75px;
  margin-top: -75px;
  position: absolute;
}

.starCountContainer {
  transition: all ease 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px 10px 0px 6px;
  height: 30px;
  overflow: hidden;
}

.emphasis {
  position: absolute;
  height: 200px;
  width: 200px;
  margin-left: -100px;
  margin-top: -100px;
}

.star path {
  stroke: var(--gold5);
  fill: var(--gold4);
  stroke-width: 4px;
}

.emphasis path {
  stroke: var(--gold5);
  fill: var(--orange4);
  stroke-width: 15px;
}
