import type {
  TournamentGameOutcome,
  TournamentMatchup,
  TournamentProgress,
} from "./TournamentScreen";
import {
  Button,
  Colors,
  Drawer,
  Icon,
  Menu,
  MenuDivider,
} from "@blueprintjs/core";
import type { GameConfig, GameId } from "@zilch/game-config";
import { useEffect, useMemo, useRef, useState } from "react";
import { RiSwordFill } from "react-icons/ri";
import css from "./TournamentSummary.module.css";
import { GiLaurelsTrophy } from "react-icons/gi";
import { VirtualList } from "./VirtualList";
import { TournamentSummaryBotMenu } from "./TournamentSummaryBotMenu";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import { Popover } from "../common/Popover";
import { MenuItem2 } from "@blueprintjs/popover2";
import type { BotConfig, TransitionSlotSelection } from "@zilch/bot-models";
import { BotColor } from "@zilch/bot-models";
import { useBotConfig } from "./TournamentSlot";
import { BotAvatarSvg } from "@zilch/bot-avatar-svg";
import { SECOND } from "@zilch/time";
import { AiFillBug } from "react-icons/ai";

interface Props {
  selectedBot: number | "all" | null;
  onSetSelectedBot(selectedBot: number | "all" | null): void;
  progress: TournamentProgress;
  gameConfig: GameConfig;
  onWatchReplay(matchupIndex: number, gameIndex: number): void;
}

type QuickFilter = "all" | "wins" | "losses";
type OutcomeFilter =
  | "outright"
  | "compute-time"
  | "coin-flip"
  | "forfeit"
  | null;

export function TournamentSummary(props: Props) {
  const [quickFilter, setQuickFilter] = useState<QuickFilter>("all");
  const [outcomeFilter, setOutcomeFilter] = useState<OutcomeFilter>(null);

  useEffect(() => {
    if (props.selectedBot === "all") {
      setQuickFilter("all");
    }
  }, [props.selectedBot]);

  const selectedBotRef = useRef(
    props.selectedBot === null ? 0 : props.selectedBot
  );
  if (props.selectedBot !== null) {
    selectedBotRef.current = props.selectedBot;
  }

  return (
    <Drawer
      isOpen={props.selectedBot !== null}
      onClose={() => props.onSetSelectedBot(null)}
      position="left"
      size="381px"
      className={css.drawer}
    >
      <div className={css.topSection}>
        <TournamentSummaryBotMenu
          progress={props.progress}
          selectedIndex={selectedBotRef.current}
          gameId={props.gameConfig.gameId}
          onSetSelectedIndex={props.onSetSelectedBot}
        />
        <QuickFilterSection
          progress={props.progress}
          quickFilter={quickFilter}
          selectedSlot={
            typeof props.selectedBot === "number" ? props.selectedBot : null
          }
          onSetQuickFilter={setQuickFilter}
        />
        <GameListHeader
          quickFilter={quickFilter}
          gameConfig={props.gameConfig}
          outcomeFilter={outcomeFilter}
          progress={props.progress}
          onSetOutcomeFilter={setOutcomeFilter}
          selectedBot={
            typeof props.selectedBot === "number" ? props.selectedBot : null
          }
        />
      </div>
      <GameList
        progress={props.progress}
        quickFilter={quickFilter}
        outcomeFilter={outcomeFilter}
        selectedBot={
          typeof props.selectedBot === "number" ? props.selectedBot : null
        }
        onWatchReplay={props.onWatchReplay}
        gameId={props.gameConfig.gameId}
      />
    </Drawer>
  );
}

function QuickFilterSection(props: {
  progress: TournamentProgress;
  selectedSlot: number | null;
  quickFilter: QuickFilter;
  onSetQuickFilter(quickFilter: QuickFilter): void;
}) {
  const slotIndexRef = useRef(props.selectedSlot);
  if (props.selectedSlot !== null) {
    slotIndexRef.current = props.selectedSlot;
  }

  const index = slotIndexRef.current;
  const stats = index === null ? null : props.progress.statsBySlot[index];
  const wins = stats?.gamesWon ?? 0;
  const losses = stats ? stats.gamesPlayed - stats.gamesWon : 0;

  const renderQuickFilter = (
    value: QuickFilter,
    label: string,
    icon?: JSX.Element
  ) => {
    const color = props.quickFilter === value ? Colors.WHITE : Colors.GRAY4;
    return (
      <Button
        active={props.quickFilter === value}
        large
        minimal
        icon={icon}
        style={{ color }}
        onClick={() => props.onSetQuickFilter(value)}
      >
        <span style={{ fontWeight: 500 }}>{label}</span>
      </Button>
    );
  };

  return (
    <div
      className={classes(
        css.quickFilterSection,
        props.selectedSlot === null && css.hidden
      )}
    >
      {renderQuickFilter("all", "All")}
      {renderQuickFilter(
        "wins",
        wins === 1 ? "1 Win" : `${wins} Wins`,
        <GiLaurelsTrophy size={18} />
      )}
      {renderQuickFilter(
        "losses",
        losses === 1 ? "1 Loss" : `${losses} Losses`,
        <RiSwordFill size={17} />
      )}
    </div>
  );
}

function GameListHeader(props: {
  gameConfig: GameConfig;
  outcomeFilter: OutcomeFilter;
  quickFilter: QuickFilter;
  onSetOutcomeFilter(value: OutcomeFilter): void;
  progress: TournamentProgress;
  selectedBot: number | null;
}) {
  const decidedByCounts = useMemo(() => {
    let forfeit = 0;
    let standardWinCondition = 0;
    let computeTime = 0;
    let coinFlip = 0;

    for (const matchup of props.progress.matchups) {
      let bot: "bot1" | "bot2" | null = null;

      if (props.selectedBot !== null) {
        if (matchup.bot1SlotIndex === props.selectedBot) {
          bot = "bot1";
        } else if (matchup.bot2SlotIndex === props.selectedBot) {
          bot = "bot2";
        } else {
          continue;
        }
      }

      for (const game of matchup.games) {
        if (typeof game === "string") {
          continue;
        }

        if (bot && props.quickFilter !== "all") {
          if (props.quickFilter === "wins" && game.winner !== bot) {
            continue;
          } else if (props.quickFilter === "losses" && game.loser !== bot) {
            continue;
          }
        }

        if (game.winReason === "coin-flip") {
          coinFlip++;
        } else if (game.winReason === "time") {
          computeTime++;
        } else if (game.winReason === "victory") {
          standardWinCondition++;
        } else {
          forfeit++;
        }
      }
    }
    return { computeTime, standardWinCondition, forfeit, coinFlip };
  }, [props.progress, props.quickFilter, props.selectedBot]);

  return (
    <div className={css.gameListHeader}>
      <div className={css.slotHeader}>{props.gameConfig.slots[0]}</div>
      <div className={css.slotHeader}>
        {props.gameConfig.slots[1]}
        <div className={css.filterContainer}>
          <Popover
            position="bottom"
            content={
              <Menu>
                {props.outcomeFilter !== "outright" && (
                  <MenuItem2
                    onClick={() => props.onSetOutcomeFilter("outright")}
                    text="Standard Win Condition"
                    label={
                      "(" +
                      decidedByCounts.standardWinCondition.toString() +
                      ")"
                    }
                  />
                )}
                {props.outcomeFilter !== "compute-time" && (
                  <MenuItem2
                    onClick={() => props.onSetOutcomeFilter("compute-time")}
                    text="Tie Breaker, Compute Time"
                    label={"(" + decidedByCounts.computeTime.toString() + ")"}
                  />
                )}
                {props.outcomeFilter !== "coin-flip" && (
                  <MenuItem2
                    onClick={() => props.onSetOutcomeFilter("coin-flip")}
                    text="Tie Breaker, Coin Flip"
                    label={"(" + decidedByCounts.coinFlip.toString() + ")"}
                  />
                )}
                {props.outcomeFilter !== "forfeit" && (
                  <MenuItem2
                    onClick={() => props.onSetOutcomeFilter("forfeit")}
                    text="Forfeit"
                    label={"(" + decidedByCounts.forfeit.toString() + ")"}
                  />
                )}
                {props.outcomeFilter !== null && (
                  <>
                    <MenuDivider />
                    <MenuItem2
                      text={
                        <span className="bp4-text-muted">Clear Filter</span>
                      }
                      onClick={() => props.onSetOutcomeFilter(null)}
                    />
                  </>
                )}
              </Menu>
            }
          >
            <button
              className={classes(css.filter, transitionInFromCss.bottom)}
              key={props.outcomeFilter}
            >
              {
                {
                  "compute-time": `Filter: Tie Breaker, Compute Time (${decidedByCounts.computeTime})`,
                  forfeit: `Filter: Forfeit  (${decidedByCounts.forfeit})`,
                  outright: `Filter: Standard Win Condition  (${decidedByCounts.standardWinCondition})`,
                  "coin-flip": `Filter: Tie Breaker, Coin flip  (${decidedByCounts.coinFlip})`,
                  none: "Filter",
                }[props.outcomeFilter ?? "none"]
              }
            </button>
          </Popover>
        </div>
      </div>
    </div>
  );
}

function GameList({
  selectedBot,
  progress,
  quickFilter,
  outcomeFilter,
  gameId,
  onWatchReplay,
}: {
  selectedBot: number | null;
  progress: TournamentProgress;
  quickFilter: QuickFilter;
  outcomeFilter: OutcomeFilter;
  gameId: GameId;
  onWatchReplay(matchupIndex: number, gameIndex: number): void;
}) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const observer = new ResizeObserver(([entry]) => {
      setHeight(entry?.contentRect.height ?? 0);
    });

    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const onWatchReplayRef = useRef(onWatchReplay);
  onWatchReplayRef.current = onWatchReplay;

  const items = useMemo(() => {
    const itemData = progress.matchups
      .map((matchup, matchupIndex) => {
        return { matchup, matchupIndex };
      })
      .filter(({ matchup }) => {
        return (
          selectedBot === null ||
          matchup.bot1SlotIndex === selectedBot ||
          matchup.bot2SlotIndex === selectedBot
        );
      })
      .flatMap(({ matchup, matchupIndex }) => {
        return matchup.games.map((game, gameIndex) => {
          return { game, matchup, gameIndex, matchupIndex };
        });
      })
      .filter(({ game, matchup }) => {
        let primaryBot: 1 | 2 | null = null;

        if (selectedBot === matchup.bot1SlotIndex) {
          primaryBot = 1;
        } else if (selectedBot === matchup.bot2SlotIndex) {
          primaryBot = 2;
        }

        if (primaryBot !== null) {
          if (typeof game === "string") {
            return false;
          }

          if (quickFilter === "wins" && game.winner !== `bot${primaryBot}`) {
            return false;
          }

          if (quickFilter === "losses" && game.loser !== `bot${primaryBot}`) {
            return false;
          }
        }

        if (typeof game !== "string") {
          if (outcomeFilter === "outright" && game.winReason !== "victory") {
            return false;
          }

          if (outcomeFilter === "compute-time" && game.winReason !== "time") {
            return false;
          }

          if (outcomeFilter === "coin-flip" && game.winReason !== "coin-flip") {
            return false;
          }

          if (
            outcomeFilter === "forfeit" &&
            !game.winReason.startsWith("forfeit")
          ) {
            return false;
          }
        }

        return true;
      });

    return {
      count: itemData.length,
      render: (index: number) => {
        const item = itemData[index];

        if (!item || typeof item.game === "string") {
          return null;
        }

        return (
          <GameListItem
            game={item.game}
            matchup={item.matchup}
            progress={progress}
            lastItem={itemData.length === index + 1}
            gameId={gameId}
            onWatchReplay={() => {
              onWatchReplayRef.current(item.matchupIndex, item.gameIndex);
            }}
          />
        );
      },
    };
  }, [progress, selectedBot, quickFilter, outcomeFilter, gameId]);

  return (
    <div
      ref={containerRef}
      style={{
        height: "calc(100% - 1px)",
        position: "relative",
        background: Colors.DARK_GRAY2,
        marginRight: "1px",
        borderBottomRightRadius: "10px",
      }}
    >
      <div
        style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
      >
        {items.count === 0 && (
          <div style={{ padding: "20px", color: Colors.GRAY3 }}>No games</div>
        )}
        <VirtualList
          height={height}
          itemHeight={70}
          itemCount={items.count}
          renderItem={items.render}
        />
      </div>
    </div>
  );
}

function GameListItem({
  game,
  matchup,
  progress,
  gameId,
  onWatchReplay,
  lastItem,
}: {
  game: TournamentGameOutcome;
  matchup: TournamentMatchup;
  progress: TournamentProgress;
  gameId: GameId;
  lastItem: boolean;
  onWatchReplay(): void;
}) {
  const p1Slot = progress.slots[matchup[`${game.p1}SlotIndex`]] ?? null;
  const p2Slot = progress.slots[matchup[`${game.p2}SlotIndex`]] ?? null;
  const p1BotConfig = useBotConfig(p1Slot, gameId);
  const p2BotConfig = useBotConfig(p2Slot, gameId);
  const className = classes(
    css.gameListItemContainer,
    lastItem && css.lastItem
  );

  if (typeof game === "string") {
    return <div className={className}>{game}</div>;
  }

  return (
    <div className={className}>
      <button className={css.gameListItem} onClick={onWatchReplay}>
        <SlotDisplay
          slot={p1Slot}
          bot={game.p1}
          botConfig={p1BotConfig}
          game={game}
        />
        <SlotDisplay
          slot={p2Slot}
          bot={game.p2}
          botConfig={p2BotConfig}
          game={game}
        />
        <OutcomeDisplay
          slot1={p1Slot}
          slot2={p2Slot}
          botConfig1={p1BotConfig}
          botConfig2={p2BotConfig}
          game={game}
        />
      </button>
    </div>
  );
}

function OutcomeDisplay({
  botConfig1,
  botConfig2,
  slot1,
  slot2,
  game,
}: {
  game: TournamentGameOutcome | "not-started" | "in-progress";
  slot1: TransitionSlotSelection;
  slot2: TransitionSlotSelection;
  botConfig1: BotConfig | null;
  botConfig2: BotConfig | null;
}) {
  if (
    !botConfig1 ||
    !botConfig2 ||
    !slot1 ||
    !slot2 ||
    typeof game === "string"
  ) {
    return <div />;
  }

  let winReason;

  if (game.winReason === "coin-flip") {
    winReason = "Tie Breaker, Coin Flip";
  } else if (game.winReason === "forfeit-bug") {
    winReason = "Forfeit (Bug)";
  } else if (game.winReason === "forfeit-connection") {
    winReason = "Forfeit (Unable to Connect)";
  } else if (game.winReason === "forfeit-time") {
    winReason = "Forfeit (Time Limit Exceeded)";
  } else if (game.winReason === "forfeit-other") {
    winReason = "Forfeit";
  } else if (game.winReason === "time") {
    winReason = "Tie Breaker, Compute Time";
  } else {
    winReason = "Standard Win Condition";
  }

  return (
    <div style={{ position: "relative" }}>
      <div className={css.decidedByContainer}>
        <div className={css.decidedByLabel}>Decided by</div>
        {winReason}
      </div>
      <div className={css.watchReplay}>
        Watch
        <br />
        Replay
      </div>
      <Icon icon="chevron-right" className={css.watchReplayIcon} />
    </div>
  );
}

function SlotDisplay({
  slot,
  botConfig,
  game,
  bot,
}: {
  bot: "bot1" | "bot2";
  botConfig: BotConfig | null;
  slot: TransitionSlotSelection;
  game: TournamentGameOutcome;
}) {
  if (!slot || !botConfig) {
    return <div />;
  }

  const computeTime = typeof game === "string" ? 0 : game[`${bot}Time`];

  let outcomeIcon;

  if (typeof game === "string") {
    outcomeIcon = null;
  } else {
    if (game.winner === bot) {
      outcomeIcon = <GiLaurelsTrophy size={20} color={Colors.GOLD5} />;
    } else if (game.winReason === "forfeit-bug") {
      outcomeIcon = <AiFillBug size={18} color={Colors.GRAY3} />;
    } else if (game.winReason === "forfeit-connection") {
      outcomeIcon = <Icon icon="offline" size={16} color={Colors.GRAY3} />;
    } else if (game.winReason === "forfeit-time") {
      outcomeIcon = <Icon icon="stopwatch" size={18} color={Colors.GRAY3} />;
    } else if (game.winReason === "forfeit-other") {
      outcomeIcon = <Icon icon="flag" size={16} color={Colors.GRAY3} />;
    } else {
      outcomeIcon = <RiSwordFill size={18} color={Colors.GRAY3} />;
    }
  }

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "30px 30px",
          alignItems: "center",
          justifyItems: "center",
          marginBottom: "4px",
        }}
      >
        <BotAvatarSvg
          avatar={botConfig.avatar}
          color={BotColor[slot.color]}
          size={24}
        />
        {outcomeIcon}
      </div>
      <div
        style={{
          whiteSpace: "nowrap",
          fontSize: "11px",
          color: Colors.GRAY3,
          display: "flex",
          alignItems: "center",
          gap: "5px",
          padding: "0px 5px",
        }}
      >
        <Icon icon="time" size={10} />
        <div>{humanizeTime(computeTime)}</div>
      </div>
    </div>
  );
}

function humanizeTime(ms: number) {
  if (ms < 10) {
    return ms.toFixed(2) + " ms";
  } else if (ms < 100) {
    return ms.toFixed(1) + " ms";
  } else if (ms < SECOND) {
    return Math.round(ms) + " ms";
  } else {
    return (ms / SECOND).toFixed(2) + " s";
  }
}
