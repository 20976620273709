import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Dialog, type Panel, PanelStack2 } from "@blueprintjs/core";
import css from "./StepsDialog.module.css";

interface Props {
  render?: (children?: React.ReactNode) => React.ReactNode;
  initialPanel: Panel<object>;
  open: boolean;
  onClose(): void;
  closeDisabled?: boolean;
  portalClassName?: string;
}

function BlankPanel() {
  return <div />;
}

export function StepsDialog(props: Props) {
  const initialPanelRef = useRef(props.initialPanel);
  initialPanelRef.current = props.initialPanel;

  const [stack, setStack] = useState([initialPanelRef.current]);

  useEffect(() => {
    if (props.open) {
      setStack([initialPanelRef.current]);
    } else {
      setStack([]);
    }
  }, [props.open]);

  const onOpenPanel = useCallback((panel: Panel<object>) => {
    // TODO look into why this hack is necessary/file a bug upstream?
    // background: when changing panels (going back or forward) if previous
    // change was in opposite direction the animation will be off. Looks
    // like a timing of when some css classes are added problem. This fixes
    // the issue by pushing an invisible panel which will transition incorrectly
    // and then the visible which will transition as expected.
    setStack((stack) => [...stack, { renderPanel: BlankPanel }]);
    requestAnimationFrame(() => {
      setStack((stack) => [...stack, panel]);
    });
  }, []);

  const onClosePanel = useCallback(() => {
    setStack((stack) => stack.slice(0, -1));
    requestAnimationFrame(() => {
      setStack((stack) => stack.slice(0, -1));
    });
  }, []);

  const render = props.render ?? ((children) => children);

  return (
    <Dialog
      isOpen={props.open}
      className={css.dialog}
      canEscapeKeyClose={false}
      onClose={props.onClose}
      enforceFocus
      portalClassName={props.portalClassName}
      canOutsideClickClose={!props.closeDisabled}
    >
      <div className={css.dialogContent}>
        <Button
          icon="cross"
          disabled={props.closeDisabled}
          className={css.exitButton}
          onClick={props.onClose}
        />
        {render(
          <PanelStack2
            className={css.stackContainer}
            onOpen={onOpenPanel}
            onClose={onClosePanel}
            stack={stack}
            showPanelHeader={false}
          />
        )}
      </div>
    </Dialog>
  );
}
