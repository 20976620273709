import { EditableText, H3 } from "@blueprintjs/core";
import type { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import type {
  SubscriptionWithTeams,
  Team,
} from "../../../backend/db/subscription";
import { api } from "../../api";
import { toaster } from "../../toaster";
import { useOperation } from "../common/useOperation";
import { AddUsersButton } from "./AddUsersButton";
import { DeleteTeamButton } from "./DeleteTeamButton";
import type { User } from "./MemberList";
import { RemoveTeamMemberButton } from "./RemoveTeamMemberButton";
import { SubscriptionSeatInfoButton } from "./SubscriptionSeatInfoButton";
import css from "./UpdateTeamHeader.module.css";

interface Props {
  userId: string;
  team: Team;
  onUpdate(close?: boolean): void;
  teamQuery: UseQueryResult<User[]>;
  subscription: SubscriptionWithTeams;
}

export function UpdateTeamHeader({
  team,
  onUpdate,
  teamQuery,
  userId,
  subscription,
}: Props) {
  const [teamName, setTeamName] = useState(team.name);

  const updateTeamNameOperation = useOperation({
    run: (value: string) => {
      return api.manage.updateTeamName
        .mutate({ teamId: team.id, teamName: value.trim() })
        .then(() => {
          onUpdate();
          toaster.show({
            message: "Updated team name",
            intent: "success",
          });
        });
    },
    onError: () => {
      setTeamName(team.name);
    },
  });

  useEffect(() => {
    setTeamName(team.name);
  }, [team]);

  return (
    <div className={css.container}>
      <H3 className={css.teamName}>
        {team.role === "owner" || team.role === "admin" ? (
          <EditableText
            value={teamName}
            disabled={updateTeamNameOperation.inProgress}
            onCancel={(value) => setTeamName(value)}
            onChange={(value) => setTeamName(value)}
            onConfirm={(value) => {
              if (value.trim() === team.name) {
                setTeamName(team.name);
                return;
              }

              if (value.trim().length === 0) {
                toaster.show({
                  message: "Enter a value",
                  intent: "danger",
                });
                return;
              }

              updateTeamNameOperation.run(value);
            }}
          />
        ) : (
          teamName
        )}
      </H3>
      <div className={css.actions}>
        <div className={css.actionsLeft}>
          <SubscriptionSeatInfoButton team={team} subscription={subscription} />
          {team.role === "member" && (
            <RemoveTeamMemberButton
              userId={userId}
              teamId={team.id}
              userRole={team.role}
              viewerRole={team.role}
              viewerUserId={userId}
              buttonProps={{
                icon: "log-out",
                minimal: true,
                children: <span className="bp4-text-muted">Leave</span>,
              }}
              onRemoved={async () => {
                onUpdate(true);
              }}
            />
          )}
          {team.role !== "member" && (
            <DeleteTeamButton
              teamId={team.id}
              onDeleted={() => {
                onUpdate(true);
              }}
            />
          )}
        </div>
        {team.role !== "member" && (
          <AddUsersButton
            label="Add Members"
            onAdd={async (logins) => {
              if (
                subscription.usedSeatCount <= subscription.seatCount &&
                logins.length + subscription.usedSeatCount >
                  subscription.seatCount &&
                !confirm(
                  "Adding these user(s) will result in this team's subscription having more seats seats filled than are available. Premium access for team members will be restricted until more seats are available or team members are removed."
                )
              ) {
                return { success: false };
              }

              const result = await api.manage.addOrUpdateTeamMembers.mutate({
                teamId: team.id,
                users: logins.map((login) => {
                  return {
                    identifier: { type: "login", value: login },
                    role: "member",
                  };
                }),
              });

              await teamQuery.refetch();
              onUpdate();

              if (result.invalidLogins.length > 0) {
                return {
                  success: false,
                  error: `Unable to add the following invalid username(s): ${result.invalidLogins.join(
                    ", "
                  )}`,
                };
              }

              return { success: true };
            }}
          />
        )}
      </div>
    </div>
  );
}
