import { Button } from "@blueprintjs/core";
import { classes } from "@zilch/css-utils";
import { useDelay } from "@zilch/delay";
import type { GameOutcome } from "./GameEngine";
import css from "./ReplayControls.module.css";
import { useEffect, useRef, useState } from "react";

interface Props {
  moveDelay: number;
  outcome: GameOutcome;
  onSetReplayProgress: (replayProgress: number | null) => void;
}

interface ReplayState {
  targetReplayProgress: number;
  paused: boolean;
}

export function ReplayControls(props: Props) {
  const visible =
    useDelay(200) &&
    props.outcome.status === "done" &&
    props.outcome.replayProgress !== null;

  const gameLength =
    props.outcome.status === "done" ? props.outcome.gameLength : 0;

  const hoverSectionRef = useRef<HTMLDivElement | null>(null);
  const hoverSectionFillRef = useRef<HTMLDivElement | null>(null);
  const progressBarSectionRef = useRef<HTMLDivElement | null>(null);
  const timelineHeadContainerRef = useRef<HTMLDivElement | null>(null);
  const timelineHeadRef = useRef<HTMLDivElement | null>(null);

  const draggingRef = useRef(false);
  const dragStartRef = useRef<number | null>(null);

  const gameLengthRef = useRef(gameLength);
  gameLengthRef.current = gameLength;

  const [replayState, setReplayState] = useState<ReplayState>({
    targetReplayProgress: 0,
    paused: false,
  });

  const exitingRef = useRef(false);

  useEffect(() => {
    if (visible) {
      exitingRef.current = false;
    }
  }, [visible]);

  const progressBarRef = useRef<HTMLDivElement | null>(null);

  const fillRef = useRef(0);
  const replayProgressRef = useRef(0);

  const onSetReplayProgressRef = useRef(props.onSetReplayProgress);
  onSetReplayProgressRef.current = props.onSetReplayProgress;

  const [done, setDone] = useState(false);

  const updateFill = (newFill: number) => {
    if (!progressBarRef.current || !timelineHeadContainerRef.current) {
      return;
    }

    progressBarRef.current.style.transform = `scaleX(${newFill})`;
    timelineHeadContainerRef.current.style.transform = `translateX(${
      newFill * (progressBarRectRef.current?.width ?? 0)
    }px)`;
    setDone(newFill === 1);

    fillRef.current = newFill;
    updateHoverSectionFill();

    const newReplayProgress = Math.round(newFill * gameLengthRef.current);
    if (newReplayProgress !== replayProgressRef.current) {
      replayProgressRef.current = newReplayProgress;
      onSetReplayProgressRef.current(newReplayProgress);
    }
  };

  const updateFillRef = useRef(updateFill);
  updateFillRef.current = updateFill;

  const pausedRef = useRef(replayState.paused);
  pausedRef.current = replayState.paused;

  const mountDelay = useDelay(500);
  useEffect(() => {
    if (mountDelay) {
      setReplayState((state) => {
        return { ...state, targetReplayProgress: gameLengthRef.current };
      });
    }
  }, [mountDelay]);

  const moveDelayRef = useRef(props.moveDelay);
  moveDelayRef.current = props.moveDelay;

  const replayStateRef = useRef(replayState);
  replayStateRef.current = replayState;

  useEffect(() => {
    let lastTime = performance.now();
    let animationFrame: number = requestAnimationFrame(loop);

    return () => {
      cancelAnimationFrame(animationFrame);
    };

    function loop() {
      if (exitingRef.current || draggingRef.current) {
        return;
      }

      const targetFill =
        replayStateRef.current.targetReplayProgress / gameLengthRef.current;
      const currentFill = fillRef.current;
      const currentTime = performance.now();
      const timeElapsed = currentTime - lastTime;
      lastTime = currentTime;
      const diff = pausedRef.current
        ? Math.abs(targetFill - currentFill) / 10
        : (0.9 * timeElapsed) / moveDelayRef.current / gameLengthRef.current;
      const threshold = 0.0001;
      if (
        (currentFill < targetFill &&
          currentFill + diff >= targetFill - threshold) ||
        (currentFill > targetFill &&
          currentFill - diff <= targetFill + threshold)
      ) {
        updateFillRef.current(targetFill);
        return;
      }
      animationFrame = requestAnimationFrame(loop);
      updateFillRef.current(
        currentFill + diff * (currentFill < targetFill ? 1 : -1)
      );
    }
  }, [replayState]);

  const updateHoverSectionFill = () => {
    if (activeSectionRef.current === null || !hoverSectionFillRef.current) {
      return;
    }

    const percentagePerSection = 1 / gameLengthRef.current;

    const minPercentage = activeSectionRef.current * percentagePerSection;
    const hoverSectionFill = Math.max(
      0,
      Math.min(1, (fillRef.current - minPercentage) / percentagePerSection)
    );

    hoverSectionFillRef.current.style.transform = `scaleX(${hoverSectionFill})`;
  };

  const stepBackward = () => {
    setReplayState((state) => {
      return {
        paused: true,
        targetReplayProgress: Math.max(
          0,
          (state.paused
            ? state.targetReplayProgress
            : Math.round(fillRef.current * gameLengthRef.current)) - 1
        ),
      };
    });
  };

  const stepForward = () => {
    setReplayState((state) => {
      return {
        paused: true,
        targetReplayProgress: Math.min(
          gameLengthRef.current,
          (state.paused
            ? state.targetReplayProgress
            : Math.round(fillRef.current * gameLengthRef.current)) + 1
        ),
      };
    });
  };

  const togglePaused = () => {
    if (fillRef.current === 1) {
      return;
    }

    setReplayState((state) => {
      if (state.paused) {
        return {
          paused: false,
          targetReplayProgress: gameLengthRef.current,
        };
      } else {
        return {
          paused: true,

          targetReplayProgress: Math.round(
            fillRef.current * gameLengthRef.current
          ),
        };
      }
    });
  };

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        stepBackward();
      } else if (event.key === "ArrowRight") {
        stepForward();
      } else if (event.key === " ") {
        togglePaused();
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  const activeSectionRef = useRef<number | null>(null);

  const progressBarRectRef = useRef<DOMRect | null>(null);

  useEffect(() => {
    if (!progressBarSectionRef.current) {
      return;
    }

    const observer = new ResizeObserver(([entry]) => {
      if (!entry) {
        return;
      }

      progressBarRectRef.current = entry.contentRect;
      updateFillRef.current(fillRef.current);
    });

    observer.observe(progressBarSectionRef.current);

    observer.observe(progressBarSectionRef.current);

    progressBarRectRef.current =
      progressBarSectionRef.current.getBoundingClientRect();

    const getFillPercent = (event: MouseEvent) => {
      const rect = progressBarRectRef.current ?? { x: 0, width: 1 };
      const x = event.clientX - rect.x;
      return Math.max(0, Math.min(1, x / rect.width));
    };

    const updateActiveSection = (event: MouseEvent) => {
      const rect = progressBarRectRef.current;

      if (!rect) {
        return;
      }

      const percent = getFillPercent(event);
      const sectionWidth = rect.width / gameLengthRef.current;

      const newActiveSection = Math.min(
        gameLengthRef.current - 1,
        Math.floor(percent * gameLengthRef.current)
      );

      if (activeSectionRef.current !== newActiveSection) {
        activeSectionRef.current = newActiveSection;

        if (hoverSectionRef.current) {
          hoverSectionRef.current.style.transform = `translateX(${
            activeSectionRef.current * sectionWidth
          }px)`;
          hoverSectionRef.current.style.width = sectionWidth + "px";
        }

        updateHoverSectionFill();
      }
    };

    const handleMouseEnter = (event: MouseEvent) => {
      if (!progressBarSectionRef.current) {
        return;
      }

      hoverSectionRef.current?.classList.add(css.hoverSectionActive);
      timelineHeadRef.current?.classList.add(css.timelineHeadHovered);
      progressBarRectRef.current =
        progressBarSectionRef.current.getBoundingClientRect();
      updateActiveSection(event);
    };

    const handleMouseLeave = () => {
      activeSectionRef.current = null;
      timelineHeadRef.current?.classList.remove(css.timelineHeadHovered);
      hoverSectionRef.current?.classList.remove(css.hoverSectionActive);
    };

    const handleDocumentMouseMove = (event: MouseEvent) => {
      if (!progressBarSectionRef.current) {
        return;
      }

      if (
        dragStartRef.current !== null &&
        !draggingRef.current &&
        Math.abs(event.clientX - dragStartRef.current) > 3
      ) {
        timelineHeadRef.current?.classList.add(css.timelineHeadActive);
        draggingRef.current = true;
        hoverSectionRef.current?.classList.add(css.hoverSectionHidden);
        document.body.classList.add("resizing");
        document.body.classList.add("grabbing");
      }

      if (draggingRef.current) {
        updateFillRef.current(getFillPercent(event));
      }

      if (activeSectionRef.current !== null) {
        updateActiveSection(event);
      }
    };

    const handleDocumentMouseUp = (event: MouseEvent) => {
      if (draggingRef.current) {
        const percent = getFillPercent(event);
        document.body.classList.remove("resizing");
        document.body.classList.remove("grabbing");
        setReplayState({
          paused: true,
          targetReplayProgress: Math.round(percent * gameLengthRef.current),
        });
      }
      draggingRef.current = false;
      dragStartRef.current = null;
      hoverSectionRef.current?.classList.remove(css.hoverSectionHidden);
      timelineHeadRef.current?.classList.remove(css.timelineHeadActive);
    };

    const handleMouseUp = () => {
      const activeSection = activeSectionRef.current ?? 0;

      setReplayState({
        paused: true,
        targetReplayProgress:
          activeSection + 0.5 < fillRef.current * gameLengthRef.current
            ? activeSection
            : activeSection + 1,
      });
    };

    const handleMouseDown = (event: MouseEvent) => {
      dragStartRef.current = event.clientX;
    };

    const progressSectionElement = progressBarSectionRef.current;

    document.addEventListener("mouseup", handleDocumentMouseUp);
    document.addEventListener("mousemove", handleDocumentMouseMove);

    progressSectionElement.addEventListener("mouseup", handleMouseUp);
    progressSectionElement.addEventListener("mousedown", handleMouseDown);
    progressSectionElement.addEventListener("mouseleave", handleMouseLeave);
    progressSectionElement.addEventListener("mouseenter", handleMouseEnter);

    return () => {
      observer.disconnect();
      document.removeEventListener("mouseup", handleDocumentMouseUp);
      document.removeEventListener("mousemove", handleDocumentMouseMove);
      progressSectionElement.removeEventListener("mousedown", handleMouseDown);
      progressSectionElement.removeEventListener(
        "mouseenter",
        handleMouseEnter
      );
      progressSectionElement.removeEventListener(
        "mouseleave",
        handleMouseLeave
      );
      progressSectionElement.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return (
    <>
      <div style={{ opacity: visible ? 1 : 0 }} className={css.background} />
      <div className={classes(css.container, !visible && css.hidden)}>
        <div className={css.leftSide}>
          <Button
            icon="step-backward"
            style={{ borderRadius: "100%" }}
            onClick={stepBackward}
          />
          <Button
            icon={done ? "reset" : replayState.paused ? "play" : "pause"}
            style={{ borderRadius: "100%" }}
            large
            onClick={() => {
              if (fillRef.current === 1) {
                updateFill(0);
                setReplayState({
                  paused: false,
                  targetReplayProgress: 0,
                });
                setTimeout(() => {
                  setReplayState({
                    paused: false,
                    targetReplayProgress: gameLengthRef.current,
                  });
                }, props.moveDelay);
              } else {
                togglePaused();
              }
            }}
          />
          <Button
            style={{ borderRadius: "100%" }}
            icon="step-forward"
            onClick={stepForward}
          />
        </div>
        <div className={css.progressBarSection} ref={progressBarSectionRef}>
          <div
            ref={timelineHeadContainerRef}
            className={css.timelineHeadContainer}
          >
            <div ref={timelineHeadRef} className={css.timelineHead} />
          </div>
          <div ref={hoverSectionRef} className={css.hoverSection}>
            <div ref={hoverSectionFillRef} className={css.hoverSectionFill} />
          </div>
          <div className={css.progressBarContainer}>
            <div ref={progressBarRef} className={css.progressBar} />
          </div>
        </div>
        <div className={css.rightSide}>
          <Button
            fill
            onClick={() => {
              exitingRef.current = true;
              props.onSetReplayProgress(null);
            }}
            style={{
              borderRadius: "15px",
            }}
          >
            <span className="bp4-text-muted">Exit Replay</span>
          </Button>
        </div>
      </div>
    </>
  );
}
