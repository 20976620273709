.content {
  position: relative;
  height: 400px;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 1px;
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  background: var(--dark-gray1);
}