.message {
  padding: 20px;
  width: 300px;
}

.plan {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.accountMenu {
  width: 195px !important;
}

.premium {
  padding: 5px;
  margin: 0px !important;
}

.attentionDot {
  position: absolute;
  width: 10px;
  height: 10px;
  margin-left: -3px;
  margin-top: -3px;
  border-radius: 100%;
  transition: all cubic-bezier(0.5, 1.8, 0.5, 1) 0.3s 0.3s;
  box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2);
  z-index: 2;
  opacity: 0;
  transform: scale(0);
}

.attentionDotVisible {
  opacity: 1;
  transform: scale(1);
}

.signIn {
  white-space: nowrap;
}
