.container {
  height: 100%;
  background: radial-gradient(var(--black), transparent),
    linear-gradient(
      to right,
      rgba(17, 20, 24, 0) min(0%, calc(50% - 600px)),
      var(--black) calc(50% - 30px),
      var(--black) calc(50% + 30px),
      rgba(17, 20, 24, 0) max(100%, calc(50% + 600px))
    ),
    linear-gradient(transparent, var(--black));
}
