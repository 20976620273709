import type { GameId } from "@zilch/game-config";
import { stringifyGameId } from "@zilch/game-config";
import { UserStore } from "./stores/UserStore";
import { useMemo } from "react";
import { z } from "zod";

export type GameSelectorItem = z.infer<typeof GameSelectorItem>;
export const GameSelectorItem = z.object({
  owner: z.string(),
  repo: z.string(),
  gameName: z.string(),
  gameImage: z.string(),
});

export function useStorage() {
  const userStore = UserStore.use();
  const userId =
    userStore.query.data?.type === "authenticated"
      ? userStore.query.data.userId
      : "<unauthenticated>";

  return useMemo(() => {
    const key = {
      dontShowBrowserPrompt: "dontShowBrowserPrompt",
      dontShowScreenSizePrompt: `dontShowScreenSizePrompt`,
      lastVisit: `lastVisit`,
      newBots: `${userId}.newBots`,
      dividerWidth: `${userId}.divider.width`,
      dontShowNewBotTutorial: `${userId}.dontShowNewBotTutorial`,
      dontShowCodespacesMessage: `${userId}.dontShowCodespacesMessage`,
      sharedBotLoaderRecentlyUsedUsernames: `${userId}.sharedBotLoader.recentlyUsedUsernames`,
      gameSelectorRecentlyLoadedGames: `${userId}.gameSelector.recentlyLoadedGames`,
      mostRecentQueryStringForGame: (gameId: GameId) =>
        `${userId}.mostRecentQueryStringForGame.${stringifyGameId(gameId)}`,
    };

    const getAllRecentlyLoadedGames = () => {
      try {
        return z
          .array(GameSelectorItem)
          .parse(
            JSON.parse(
              localStorage.getItem(key.gameSelectorRecentlyLoadedGames) ?? "[]"
            )
          );
      } catch {
        return [];
      }
    };

    return {
      newBots: {
        set(bots: Set<string>) {
          if (bots.size > 0) {
            localStorage.setItem(key.newBots, Array.from(bots).join("\n"));
          } else {
            localStorage.removeItem(key.newBots);
          }
        },
        get() {
          const value = localStorage.getItem(key.newBots);
          if (!value) {
            return new Set<string>();
          }
          return new Set(value.split("\n"));
        },
      },

      dontShowNewBotTutorial: {
        set(value: boolean) {
          if (value === true) {
            localStorage.setItem(key.dontShowNewBotTutorial, "true");
          } else {
            localStorage.removeItem(key.dontShowNewBotTutorial);
          }
        },
        get() {
          return localStorage.getItem(key.dontShowNewBotTutorial) === "true";
        },
      },

      dontShowBrowserPrompt: {
        set(value: boolean) {
          if (value === true) {
            localStorage.setItem(key.dontShowBrowserPrompt, "true");
          } else {
            localStorage.removeItem(key.dontShowBrowserPrompt);
          }
        },
        get() {
          return localStorage.getItem(key.dontShowBrowserPrompt) === "true";
        },
      },

      dontShowScreenSizePrompt: {
        set(value: boolean) {
          if (value === true) {
            localStorage.setItem(key.dontShowScreenSizePrompt, "true");
          } else {
            localStorage.removeItem(key.dontShowScreenSizePrompt);
          }
        },
        get() {
          return localStorage.getItem(key.dontShowScreenSizePrompt) === "true";
        },
      },

      divider: {
        width: {
          set: (width: number) =>
            localStorage.setItem(key.dividerWidth, width.toString()),
          get: () => {
            const width = localStorage.getItem(key.dividerWidth);
            if (width === null) {
              return null;
            }
            return parseInt(width);
          },
        },
      },

      sharedBotLoader: {
        recentlyUsedUsernames: {
          set: (usernames: string[]) => {
            if (usernames.length === 0) {
              localStorage.removeItem(key.sharedBotLoaderRecentlyUsedUsernames);
            } else {
              localStorage.setItem(
                key.sharedBotLoaderRecentlyUsedUsernames,
                usernames.join(",")
              );
            }
          },
          get: () => {
            return (
              localStorage
                .getItem(key.sharedBotLoaderRecentlyUsedUsernames)
                ?.split(",") ?? []
            );
          },
        },
      },

      gameSelector: {
        recentlyLoadedGames: {
          update: (recentlyLoadedGame: GameSelectorItem) => {
            const games = getAllRecentlyLoadedGames();
            const storedGameIndex = games.findIndex(
              (game) =>
                game.owner === recentlyLoadedGame.owner &&
                game.repo === recentlyLoadedGame.repo
            );

            if (storedGameIndex !== -1) {
              games.splice(storedGameIndex, 1);
            }

            games.push(recentlyLoadedGame);

            localStorage.setItem(
              key.gameSelectorRecentlyLoadedGames,
              JSON.stringify(games)
            );
          },
          remove: (owner: string, repo: string) => {
            const games = getAllRecentlyLoadedGames();
            const storedGameIndex = games.findIndex(
              (game) => game.owner === owner && game.repo === repo
            );

            if (storedGameIndex !== -1) {
              games.splice(storedGameIndex, 1);
              localStorage.setItem(
                key.gameSelectorRecentlyLoadedGames,
                JSON.stringify(games)
              );
            }
          },
          getAll: () => {
            return getAllRecentlyLoadedGames();
          },
        },
      },
    };
  }, [userId]);
}
