import { serializePoint } from "./serializePoint";
import { getShapes } from "./getShapes";
import type { SvgShape } from "./SvgShape";
import type { Point } from "./Point";
import type { SvgPath } from "./SvgPath";
import { wrapIndex } from "@zilch/wrap-index";

type Shape = Point[];
type EdgeKind = "top" | "bottom" | "left" | "right";

interface FillStatus {
  topLeft: boolean;
  topRight: boolean;
  bottomLeft: boolean;
  bottomRight: boolean;
}

export function createSvgShapes(grid: boolean[][]): SvgShape[] {
  return getShapes(grid).map(traceShape).map(simplifyShape);
}

function traceShape(shape: Shape): SvgShape {
  const shapePoints = new Set(shape.map(serializePoint));
  const svgPoints = new Map(
    shape
      .flatMap((point) => [
        point,
        { x: point.x + 1, y: point.y },
        { x: point.x, y: point.y + 1 },
        { x: point.x + 1, y: point.y + 1 },
      ])
      .map((point) => [serializePoint(point), point])
  );

  const svgShape: SvgShape = [];
  const edges = createEdgeSet();

  for (const svgPoint of svgPoints.values()) {
    if (edges.hasPoint(svgPoint)) {
      continue;
    }

    let current: Point | null = svgPoint;
    let last: Point | null = null;
    const path: SvgPath = [];

    while (current) {
      path.push(current);
      if (last) {
        edges.add(last, current);
      }

      const next: Point | null =
        [
          { x: current.x + 1, y: current.y },
          { x: current.x, y: current.y + 1 },
          { x: current.x - 1, y: current.y },
          { x: current.x, y: current.y - 1 },
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ].find((next) => isValidTrace(next, current!, last)) ?? null;

      last = current;
      current = next;
    }

    if (path.length > 4) {
      svgShape.push(path.slice(0, -1));
    }
  }

  return svgShape;

  function isValidTrace(
    next: Point,
    current: Point,
    last: Point | null
  ): boolean {
    const nextSerialized = serializePoint(next);

    // Ensure point is on grid
    if (!svgPoints.has(nextSerialized)) {
      return false;
    }

    // Ensure this is an edge
    const edge = getEdgeKind(current, next);
    if (edge === null) {
      return false;
    }

    // Ensure we're not crossing a diagonal
    if (last && hasDiagonal(current)) {
      const lastEdge = getEdgeKind(last, current);
      const fill = getAdjacentSquaresFillStatus(current);

      const valid = new Set(
        fill.topLeft
          ? ["top,left", "left,top", "right,bottom", "bottom,right"]
          : ["top,right", "right,top", "left,bottom", "bottom,left"]
      ).has(`${edge},${lastEdge}`);

      if (!valid) {
        return false;
      }
    }

    // Ensure this edge hasn't been used before
    return !edges.has(current, next);
  }

  function hasDiagonal(point: Point) {
    const fill = getAdjacentSquaresFillStatus(point);
    return (
      fill.topLeft === fill.bottomRight &&
      fill.bottomLeft === fill.topRight &&
      fill.topLeft !== fill.bottomLeft
    );
  }

  function getEdgeKind(p1: Point, p2: Point): EdgeKind | null {
    const fill = getAdjacentSquaresFillStatus(p1);
    if (p2.x > p1.x && fill.topRight !== fill.bottomRight) {
      return fill.topRight ? "top" : "bottom";
    } else if (p2.x < p1.x && fill.topLeft !== fill.bottomLeft) {
      return fill.topLeft ? "top" : "bottom";
    } else if (p2.y > p1.y && fill.bottomLeft !== fill.bottomRight) {
      return fill.bottomLeft ? "left" : "right";
    } else if (p2.y < p1.y && fill.topLeft !== fill.topRight) {
      return fill.topLeft ? "left" : "right";
    } else {
      return null;
    }
  }

  function getAdjacentSquaresFillStatus(point: Point): FillStatus {
    return {
      topLeft: shapePoints.has(
        serializePoint({
          x: point.x - 1,
          y: point.y - 1,
        })
      ),
      bottomLeft: shapePoints.has(
        serializePoint({ x: point.x - 1, y: point.y })
      ),
      bottomRight: shapePoints.has(serializePoint({ x: point.x, y: point.y })),
      topRight: shapePoints.has(serializePoint({ x: point.x, y: point.y - 1 })),
    };
  }
}

function simplifyShape(shape: SvgShape): SvgShape {
  return shape.map((path) =>
    path.filter((current, index) => {
      const previous = path[wrapIndex(path, index - 1)];
      const next = path[wrapIndex(path, index + 1)];

      const redundant =
        (previous!.x === current.x && current.x === next!.x) ||
        (previous!.y === current.y && current.y === next!.y);

      return !redundant;
    })
  );
}

function createEdgeSet() {
  const points = new Set<string>();
  const edges = new Set<string>();

  return {
    add(p1: Point, p2: Point) {
      points.add(serializePoint(p1));
      points.add(serializePoint(p2));
      serializeEdge(p1, p2).forEach((edge) => edges.add(edge));
    },
    has(p1: Point, p2: Point) {
      return serializeEdge(p1, p2).some((edge) => edges.has(edge));
    },
    hasPoint(point: Point) {
      return points.has(serializePoint(point));
    },
  };

  function serializeEdge(p1: Point, p2: Point) {
    return [
      `${serializePoint(p1)};${serializePoint(p2)}`,
      `${serializePoint(p2)};${serializePoint(p1)}`,
    ];
  }
}
