import type { Route } from "type-route";
import type { groups } from "./router";
import { routes } from "./router";
import type { GameId } from "@zilch/game-config";

export function getGameIdForAnalytics(
  value: Route<typeof groups.game> | GameId
) {
  if (typeof value === "string") {
    return `zilch.${value}`;
  } else if ("owner" in value) {
    return `${value.owner}.${value.repo}`;
  } else if (value.name === routes.internalGame.name) {
    return `zilch.${value.params.gameId}`;
  } else {
    return `${value.params.owner}.${value.params.repo}`;
  }
}
