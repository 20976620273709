import { Button, H3 } from "@blueprintjs/core";
import type { PanelActions } from "@blueprintjs/core/lib/esm/components/panel-stack2/panelTypes";
import { type BotAvatarAndColor, BotColor } from "@zilch/bot-models";
import React, { useState, useContext } from "react";
import { BotAvatarSvg } from "@zilch/bot-avatar-svg";
import { generateBotAvatarAndColor } from "../../generateBotAvatarAndColor";
import { BotCreatorPanel } from "./BotCreatorPanel";
import { BotCreationStore } from "./BotCreator";
import { BotCreatorCustomAvatarPanel } from "./BotCreatorCustomAvatarPanel";
import { BotCreatorNamePanel } from "./BotCreatorNamePanel";
import css from "./BotCreatorAvatarPanel.module.css";
import { classes, delayCss, transitionInFromCss } from "@zilch/css-utils";
import { useLanguages } from "./useLanguages";
import { useWindowSizeDerivedValue } from "@zilch/window-size";
import { PremiumStore } from "../../stores/PremiumStore";
import { Popover } from "../common/Popover";
import { GuideContent } from "./GuideContent";

export function BotCreatorAvatarPanel(props: PanelActions) {
  const store = useContext(BotCreationStore);
  const [initialAvatarOptions, setInitialAvatarOptions] = useState(true);
  const languagesQuery = useLanguages();
  const premiumStore = PremiumStore.use();

  if (!store) {
    return null;
  }

  const languageId = (store.botConfig.language ?? "").split(".")[0];

  const backText: React.ReactNode = (
    <>
      Language:&nbsp;&nbsp;
      <b>
        {languagesQuery.isSuccess && languageId
          ? languagesQuery.data[languageId]?.name ?? "Unknown"
          : "Other"}
      </b>
    </>
  );

  return (
    <BotCreatorPanel {...props} className={css.container} backText={backText}>
      <div>
        <H3
          style={{ margin: 0 }}
          className={classes(transitionInFromCss.bottom, delayCss["500"])}
        >
          Pick an Avatar
        </H3>
        <div className={css.selectAvatarButtonsContainer}>
          {store.avatarOptions.map((option, key) => {
            let delay =
              {
                0: 75,
                1: 25,
                2: 125,
              }[key] ?? 0;

            if (initialAvatarOptions) {
              delay += 350;
            }

            return (
              <SelectAvatarButton
                key={key}
                delay={delay}
                avatarAndColor={option}
                onSelect={onSelect}
              />
            );
          })}
        </div>
        <div
          className={classes(
            css.additionalOptions,
            transitionInFromCss.bottom,
            delayCss["200"]
          )}
        >
          <Popover
            content={
              <GuideContent
                title="Unlock more avatars..."
                message={
                  <>
                    Free Premium is available to students and employees of
                    select organizations.
                  </>
                }
                action={{
                  text: "Learn more",
                  onClick: () => {
                    premiumStore.setSection("feature-overview");
                  },
                }}
              />
            }
            background="blue"
            position="bottom"
            isOpen={premiumStore.hasPremium ? false : undefined}
          >
            <Button
              minimal
              onClick={() => {
                if (premiumStore.hasPremium) {
                  setInitialAvatarOptions(false);
                  store.onNewAvatarOptions();
                }
              }}
            >
              More options
            </Button>
          </Popover>
          <span className={classes(css.or, "bp4-text-muted")}>or</span>
          <Button
            minimal
            onClick={() => {
              if (store.botConfig.preferredColor && store.botConfig.avatar) {
                onCustomize({
                  preferredColor: store.botConfig.preferredColor,
                  avatar: store.botConfig.avatar,
                });
              } else {
                onCustomize(generateBotAvatarAndColor());
              }
            }}
          >
            Custom avatar
          </Button>
        </div>
      </div>
    </BotCreatorPanel>
  );

  function onSelect(avatarAndColor: BotAvatarAndColor) {
    store?.onSetBotConfig((config) => {
      return {
        ...config,
        preferredColor: avatarAndColor.preferredColor,
        avatar: avatarAndColor.avatar,
      };
    });
    props.openPanel({
      renderPanel: (props) => <BotCreatorNamePanel {...props} />,
    });
  }

  function onCustomize(avatarAndColor: BotAvatarAndColor) {
    store?.onSetBotConfig((config) => {
      return {
        ...config,
        preferredColor: avatarAndColor.preferredColor,
        avatar: avatarAndColor.avatar,
      };
    });
    props.openPanel({
      renderPanel: (props) => <BotCreatorCustomAvatarPanel {...props} />,
    });
  }
}

function SelectAvatarButton({
  avatarAndColor,
  onSelect,
  delay,
}: {
  avatarAndColor: BotAvatarAndColor;
  onSelect: (avatarAndColor: BotAvatarAndColor) => void;
  delay: number;
}) {
  const small = useWindowSizeDerivedValue((width) => width < 400);
  const size = small ? 70 : 100;
  return (
    <Button
      key={avatarAndColor.avatar}
      onClick={() => onSelect(avatarAndColor)}
      style={{
        width: size + "px",
        height: size + "px",
        animationDelay: delay + "ms",
      }}
      className={transitionInFromCss.bottom}
    >
      <BotAvatarSvg
        avatar={avatarAndColor.avatar}
        color={BotColor[avatarAndColor.preferredColor]}
        size={small ? 50 : 60}
      />
    </Button>
  );
}
