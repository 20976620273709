@keyframes StarAnimation {
  from {
    opacity: 0;
    transform: scale(0.5) translateY(20px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0px);
  }
}

.star {
  position: absolute;
  bottom: 100%;
  display: flex;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  padding: 20px;
  animation: StarAnimation both cubic-bezier(0.5, 1.8, 0.5, 1) 0.3s;
}

.locked {
  width: 230px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.star path {
  stroke: var(--gray3);
  fill: var(--dark-gray3);
  stroke-width: 15px;
  opacity: 0.9;
}

.starFilled path {
  stroke: var(--gold5);
  fill: var(--gold4);
  opacity: 1;
}
