import { H5 } from "@blueprintjs/core";
import React from "react";

interface Props {
  title: string;
  children: React.ReactNode;
}

export function SubSection(props: Props) {
  return (
    <div>
      <H5
        style={{
          cursor: "pointer",
          margin: "0px",
          marginBottom: "10px",
        }}
      >
        {props.title}
      </H5>
      {props.children}
    </div>
  );
}
