.container {
  height: 100%;
  position: relative;
}

.section {
  position: absolute;
}

.leftSection {
  left: 0;
  top: 0;
  bottom: 0;
}

.rightSection {
  background: var(--dark-gray1);
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  max-width: calc(100vw - 70px);
}

.handle {
  position: absolute;
  left: -9px;
  width: 19px;
  top: 0;
  bottom: 0;
}

.handle::after {
  content: "";
  transition: all ease 0.3s;
  position: absolute;
  top: 0;
  left: 9px;
  right: 9px;
  bottom: 0;
  background: var(--dark-gray5);
  z-index: 5;
}

:global(body.resizing:not(.grabbing)) .handle::after {
  left: 8px;
  right: 8px;
  transition: none;
  background: var(--blue3) !important;
}

.handle:hover::after {
  left: 8px;
  right: 8px;
  background: var(--gray1);
}

:global(body.resizing.col-resize) .handle {
  cursor: col-resize !important;
}

:global(body.resizing.w-resize) .handle {
  cursor: w-resize !important;
}

.toggle {
  position: absolute;
  top: calc(50% - 12px);
  left: -33px;
  border-radius: 100% !important;
  z-index: 2;
  transition: all ease 0.3s;
}

.toggleHidden {
  opacity: 0;
  left: -11px;
  pointer-events: none;
  transform: scale(0.5);
}

:global(body.col-resize *) {
  cursor: col-resize !important;
}

:global(body.w-resize *) {
  cursor: w-resize !important;
}

:global(body.grabbing *) {
  cursor: grabbing !important;
}
