import { createAvatarSvgShapes } from "./createAvatarSvgShapes";
import type { SvgShape } from "./SvgShape";

export function createBotAvatarSvgFileContents(
  avatar: string,
  color: string,
  size: number
) {
  const shapes = createAvatarSvgShapes(avatar);

  if (shapes === null) {
    return null;
  }

  const background = shapes.background.map((shape) => path(shape, color));
  const foreground = shapes.foreground.map((shape) => path(shape, "#ffffff"));

  return `<svg viewBox="0 0 12 12" width="${size}px" height="${size}px" xmlns="http://www.w3.org/2000/svg">${[
    ...background,
    ...foreground,
  ].join("")}</svg>`;

  function path(shape: SvgShape, fill: string) {
    const d = shape
      .map((p) => "M " + p.map(({ x, y }) => `${x},${y}`).join(" ") + "z")
      .join(" ");

    return `<path fill-rule="evenodd" d="${d}" fill="${fill}"/>`;
  }
}
