import { classes } from "@zilch/css-utils";
import { FullLogo } from "../common/FullLogo";
import css from "./Footer.module.css";
import { discordJoinLink } from "@zilch/discord";
import React from "react";

export function Footer() {
  return (
    <>
      <div className={css.container}>
        <div>
          <FullLogo />
        </div>
        <Column
          title="Premium"
          links={[
            {
              type: "link",
              label: "Organization",
              href: "/premium/organization",
            },
            {
              type: "link",
              label: "Team and Classroom",
              href: "/premium/team-and-classroom",
            },
            { type: "link", label: "Individual", href: "/premium/individual" },
          ]}
        />
        <Column
          title="Resources"
          links={[
            { type: "link", label: "Blog", href: "/blog" },
            { type: "link", label: "Documentation", href: "/docs" },
            { type: "link", label: "Support", href: "/support" },
          ]}
        />
        <Column
          title="Social"
          links={[
            {
              type: "link",
              label: "YouTube",
              href: "https://youtube.com/@zilchdev",
            },
            {
              type: "link",
              label: "GitHub",
              href: "https://github.com/zilch/hello",
            },
            { type: "link", label: "Discord", href: discordJoinLink },
          ]}
        />
        <Column
          title="Company"
          links={[
            {
              type: "element",
              element: (
                <div>
                  <a className={css.columnLink} href="/terms">
                    Terms
                  </a>{" "}
                  <span className="bp4-text-muted">/</span>{" "}
                  <a className={css.columnLink} href="/privacy">
                    Privacy
                  </a>
                </div>
              ),
            },
            { type: "link", label: "Careers", href: "/careers" },
            { type: "link", label: "Attribution", href: "/attribution" },
          ]}
        />
      </div>
      <div className={css.bottomSection}>
        <span>Build {ClientEnv.BUILD}</span>
        <span>© Zilch LLC</span>
      </div>
    </>
  );
}

function Column(props: {
  title: string;
  links: (
    | { type: "link"; label: string; href: string }
    | { type: "element"; element: React.ReactNode }
  )[];
}) {
  return (
    <div className={css.column}>
      <div className={classes(css.columnTitle, "bp4-text-muted")}>
        {props.title}
      </div>
      {props.links.map((link, index) => {
        if (link.type === "element") {
          return <React.Fragment key={index}>{link.element}</React.Fragment>;
        }

        return (
          <a key={index} href={link.href} className={css.columnLink}>
            {link.label}
          </a>
        );
      })}
    </div>
  );
}
