.blue {
  box-shadow:
    0 0 0 1px var(--blue4),
    inset 0 0 0 1px rgb(255 255 255 / 20%),
    0 2px 4px rgb(17 20 24 / 40%),
    0 8px 24px rgb(17 20 24 / 40%) !important;
}

.blue > :global(.bp4-popover2-content) {
  background: var(--blue3) !important;
}

.blue
  > :global(.bp4-popover2-arrow)
  > svg
  > path:global(.bp4-popover2-arrow-fill) {
  fill: var(--blue3) !important;
}

.blue
  > :global(.bp4-popover2-arrow)
  > svg
  > path:global(.bp4-popover2-arrow-border) {
  fill: var(--blue4);
  fill-opacity: 1;
}

.green {
  box-shadow:
    0 0 0 1px var(--green4),
    inset 0 0 0 1px rgb(255 255 255 / 20%),
    0 2px 4px rgb(17 20 24 / 40%),
    0 8px 24px rgb(17 20 24 / 40%) !important;
}

.green > :global(.bp4-popover2-content) {
  background: var(--green3) !important;
}

.green
  > :global(.bp4-popover2-arrow)
  > svg
  > path:global(.bp4-popover2-arrow-fill) {
  fill: var(--green3) !important;
}

.green
  > :global(.bp4-popover2-arrow)
  > svg
  > path:global(.bp4-popover2-arrow-border) {
  fill: var(--green4);
  fill-opacity: 1;
}

.red {
  box-shadow:
    0 0 0 1px var(--red4),
    inset 0 0 0 1px rgb(255 255 255 / 20%),
    0 2px 4px rgb(17 20 24 / 40%),
    0 8px 24px rgb(17 20 24 / 40%) !important;
}

.red > :global(.bp4-popover2-content) {
  background: var(--red3) !important;
}

.red
  > :global(.bp4-popover2-arrow)
  > svg
  > path:global(.bp4-popover2-arrow-fill) {
  fill: var(--red3) !important;
}

.red
  > :global(.bp4-popover2-arrow)
  > svg
  > path:global(.bp4-popover2-arrow-border) {
  fill: var(--red4);
  fill-opacity: 1;
}
