import posthog from "posthog-js";

export function initPosthog() {
  // Could take this posthog api key and put it in an env file but
  // not actually sure how much value that'll add. It's public no matter
  // what and posthog has server-side protections against events being
  // sent from unauthorized domains - so it's hard to see the benefit
  // from a security standpoint. This is simpler.
  posthog.init("phc_3q1cLRiMJnVJzKQ9jjIElL7oSTsvxMrCJfJqv49sEj6", {
    api_host: "https://app.posthog.com",
    session_recording: {
      recordCrossOriginIframes: true,
    },
  });
}
