.container {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  transition: transform cubic-bezier(0.5, 1.4, 0.5, 1) 0.5s;
  transform: translateY(0px);
}

.hidden {
  transform: translateY(180px);
  pointer-events: none;
  transition: transform ease 0.2s;
}

.startGameButtonContainer {
  height: 60px;
  z-index: 1;
}

.startGame {
  white-space: nowrap;
  transition: all ease 0.3s;
  overflow: hidden;
  position: relative;
}

.buttonText {
  display: flex;
  transition: all cubic-bezier(0.5, 1.5, 0.5, 1) 0.4s;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.speedButton {
  transition: all ease 0.3s;
}

.configFormContainer {
  margin-top: 10px;
  background: rgba(47, 52, 60, 0.8);
  border: 1px solid rgba(95, 107, 124, 0.4);
  border-radius: 2px;
  animation-delay: 350ms;
}

.watchReplayContainer {
  transition: all ease 0.3s;
  overflow: hidden;
}

.watchReplayButtonContainer {
  background: rgba(47, 52, 60, 0.8);
  border: 1px solid rgba(95, 107, 124, 0.4);
  border-radius: 2px;
  margin-top: 10px;
}
