import { Button, ButtonGroup, Menu, MenuDivider } from "@blueprintjs/core";
import { BotOutcome, BotType, type SlotSelection } from "@zilch/bot-models";
import React, { useEffect, useRef, useState } from "react";
import { useDelay, useDelayedValue } from "@zilch/delay";
import css from "./GameSetup.module.css";
import { GameConfigForm } from "./GameConfigForm";
import {
  type GameEngineInstance,
  type GameOutcome,
  GameSpeed,
} from "./GameEngine";
import { Popover } from "../common/Popover";
import { MenuItem2 } from "@blueprintjs/popover2";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import { PremiumStore } from "../../stores/PremiumStore";
import { UserStore } from "../../stores/UserStore";
import { kebabCase } from "lodash";
import { ClickMeFinger } from "../common/ClickMeFinger";
import { usePlaybook } from "../playbook/Playbook";
import type { GameId } from "@zilch/game-config";

interface Props {
  gameEngineInstance: GameEngineInstance;
  slotSelections: SlotSelection[];
  onStartGame(): void;
  onShowNotEnoughBotsMessage(): void;
  onShowSignInMessage(index: number): void;
  onShowNotLoadedMessage(index: number): void;
  onShowBossLockedMessage(gameId: GameId): void;
  onShowCustomGameLockedMessage(): void;
  onShowMultiplayerLockedMessage(index: number): void;
  speed: GameSpeed;
  outcome: GameOutcome;
  onSetOutcome(outcome: GameOutcome): void;
  onSetSpeed(speed: GameSpeed): void;
  sandbox: boolean;
}

export function GameSetup(props: Props) {
  const visible =
    useDelay(200) &&
    props.outcome.status !== "in-progress" &&
    (props.outcome.status !== "done" || props.outcome.replayProgress === null);

  const hasReplay =
    props.outcome.status === "done" && props.outcome.gameLength > 0;
  const showWatchReplay =
    useDelayedValue(hasReplay, { delay: 300 }) || hasReplay;

  const [showResetGameConfigMessage, setShowResetGameConfigMessage] =
    useState(false);
  const shouldShowResetGameConfigMessage =
    props.outcome.status === "done" &&
    props.outcome.primary?.type === "victory-over-boss-but-incompatible-config";

  useEffect(() => {
    setShowResetGameConfigMessage(shouldShowResetGameConfigMessage);
  }, [shouldShowResetGameConfigMessage]);

  const delayedShowResetGameConfigMessage =
    useDelayedValue(showResetGameConfigMessage, { delay: 6500 }) &&
    showResetGameConfigMessage;

  return (
    <div className={classes(css.container, !visible && css.hidden)}>
      <StartGameButton {...props} />
      {showWatchReplay && (
        <div
          style={{
            height: (hasReplay ? 42 : 0) + "px",
            opacity: hasReplay ? 1 : 0,
          }}
          className={css.watchReplayContainer}
        >
          <div className={css.watchReplayButtonContainer}>
            <Button
              rightIcon="reset"
              minimal
              fill
              alignText="left"
              onClick={() => {
                if (props.outcome.status === "done") {
                  props.onSetOutcome({
                    ...props.outcome,
                    replayProgress: 0,
                  });
                }
              }}
            >
              <span className="bp4-text-muted">Watch Replay</span>
            </Button>
          </div>
        </div>
      )}
      <div
        className={classes(transitionInFromCss.top, css.configFormContainer)}
      >
        <Popover
          background="blue"
          placement="right"
          isOpen={delayedShowResetGameConfigMessage}
          onClose={() => setShowResetGameConfigMessage(false)}
          content={<div style={{ padding: "10px" }}>Reset game config.</div>}
        >
          <GameConfigForm
            gameEngineInstance={props.gameEngineInstance}
            gameOutcome={props.outcome}
            onSetOutcome={props.onSetOutcome}
            sandbox={props.sandbox}
          />
        </Popover>
      </div>
    </div>
  );
}

function StartGameButton(props: Props) {
  const [showLeftToPick, setShowLeftToPick] = useState(false);

  const numFilledSlots = props.slotSelections.filter(
    (selection) => !!selection
  ).length;
  const minSlots = props.gameEngineInstance.gameConfig.minSlots;
  const leftToFill = Math.max(0, minSlots - numFilledSlots);
  const readyToStart = numFilledSlots >= minSlots;
  const premiumStore = PremiumStore.use();

  const isBossLocked =
    !premiumStore.hasPremium &&
    ((props.gameEngineInstance.gameConfig.gameId !== "table-tennis" &&
      props.slotSelections.some((slot) => slot?.type === BotType.Boss)) ||
      props.slotSelections.some(
        (slot) => slot?.type === BotType.Boss && slot.difficulty === "hard"
      ));

  const isLockedCustomGame =
    !premiumStore.hasPremium &&
    typeof props.gameEngineInstance.gameConfig.gameId !== "string";

  const userStore = UserStore.use();
  const currentUser =
    userStore.query.isSuccess &&
    userStore.query.data.type === "authenticated" &&
    userStore.query.data.likelyLogin;
  const isMultiplayerLockedIndex =
    !premiumStore.hasPremium &&
    props.slotSelections.findIndex(
      (slot) => slot?.type === "user" && slot.owner !== currentUser
    );
  const signInBlockedBotIndex =
    userStore.query.data?.type !== "authenticated" &&
    props.slotSelections.findIndex((slot) => slot?.type === "user");

  const [startGameText, setStartGameText] = useState<
    { node: React.ReactNode; key: string } | string
  >("Start Game");

  useEffect(() => {
    if (readyToStart) {
      setShowLeftToPick(false);
    }
  }, [readyToStart]);

  let buttonContents: {
    key: string;
    text: React.ReactNode;
  } = {
    key:
      "start-game-" +
      kebabCase(
        typeof startGameText === "string" ? startGameText : startGameText.key
      ),
    text: (
      <span style={{ fontWeight: 600 }}>
        {typeof startGameText === "string" ? startGameText : startGameText.node}
      </span>
    ),
  };

  if (showLeftToPick) {
    buttonContents = {
      key: "left-to-pick-" + leftToFill,
      text: (
        <span className="bp4-text-muted">
          Pick <b>{leftToFill}</b> more {leftToFill === 1 ? "bot" : "bots"}
        </span>
      ),
    };
  } else if (
    props.outcome.status === "done" &&
    !!props.outcome.botOutcomes.find(({ outcome }) => {
      return (
        outcome === BotOutcome.ConnectionProblem ||
        outcome === BotOutcome.Error ||
        outcome === BotOutcome.GameError ||
        outcome === BotOutcome.GameLocked
      );
    })
  ) {
    buttonContents = {
      key: "try-again",
      text: <span style={{ fontWeight: 600 }}>Try Again</span>,
    };
  } else if (
    props.outcome.status === "done" &&
    !props.outcome.botOutcomes.find(
      ({ outcome }) => outcome === BotOutcome.Unimplemented
    )
  ) {
    buttonContents = {
      key: "play-again",
      text: <span style={{ fontWeight: 600 }}>Play Again</span>,
    };
  }

  const notStarted = props.outcome.status === "not-started";
  const notStartedRef = useRef(notStarted);
  notStartedRef.current = notStarted;

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (readyToStart && notStartedRef.current) {
      timeout = setTimeout(() => {
        setStartGameText({
          key: "thumbs-up",
          node: <span style={{ fontSize: "24px" }}>👍</span>,
        });
      }, 200);
    } else {
      setStartGameText("Start Game");
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [readyToStart]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const setStartGameTextWithDelay = (text: string) => {
      timeout = setTimeout(() => {
        setStartGameText(text);
      }, 500);
    };

    if (buttonContents.key === "start-game-thumbs-up") {
      setStartGameTextWithDelay("Ready");
    } else if (buttonContents.key === "start-game-ready") {
      setStartGameTextWithDelay("to");
    } else if (buttonContents.key === "start-game-to") {
      setStartGameTextWithDelay("Start Game");
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [buttonContents.key]);

  const buttonContentsRef = useRef(buttonContents);
  if (props.outcome.status !== "in-progress") {
    buttonContentsRef.current = buttonContents;
  }

  const [buttonContentsList, setButtonContentsList] = useState<
    { text: React.ReactNode; key: number }[]
  >([]);

  useEffect(() => {
    setButtonContentsList((list) => {
      return [
        { text: buttonContentsRef.current.text, key: (list[0]?.key ?? 0) + 1 },
        ...list,
      ];
    });
    setTimeout(() => {
      setButtonContentsList((list) =>
        list.length > 1 ? list.slice(0, -1) : list
      );
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonContentsRef.current.key]);

  const playbook = usePlaybook();

  return (
    <div style={{ position: "relative" }}>
      <ButtonGroup large fill className={css.startGameButtonContainer}>
        <Button
          large
          fill
          className={css.startGame}
          intent={showLeftToPick ? "none" : "primary"}
          onClick={() => {
            if (isLockedCustomGame) {
              props.onShowCustomGameLockedMessage();
            } else if (
              signInBlockedBotIndex !== false &&
              signInBlockedBotIndex > -1
            ) {
              props.onShowSignInMessage(signInBlockedBotIndex);
            } else if (
              isMultiplayerLockedIndex !== false &&
              isMultiplayerLockedIndex > -1
            ) {
              props.onShowMultiplayerLockedMessage(isMultiplayerLockedIndex);
            } else if (isBossLocked) {
              props.onShowBossLockedMessage(
                props.gameEngineInstance.gameConfig.gameId
              );
            } else if (readyToStart) {
              props.onStartGame();
            } else {
              setShowLeftToPick(true);
              props.onShowNotEnoughBotsMessage();
            }
          }}
        >
          {buttonContentsList.map((buttonContent, index) => {
            return (
              <ButtonText
                key={buttonContent.key}
                text={buttonContent.text}
                transitionOut={index > 0}
              />
            );
          })}
        </Button>
        <Popover
          placement="top"
          content={
            <Menu>
              <MenuDivider title="Initial Game Speed" />
              <MenuItem2
                icon="pause"
                text="Paused"
                onClick={() => props.onSetSpeed(GameSpeed.Paused)}
              />
              <MenuItem2
                icon="play"
                text="Normal"
                onClick={() => props.onSetSpeed(GameSpeed.Normal)}
              />
              <MenuItem2
                icon="fast-forward"
                text="Fast"
                onClick={() => props.onSetSpeed(GameSpeed.Fast)}
              />
            </Menu>
          }
        >
          <Button
            large
            className={css.speedButton}
            icon={
              {
                paused: "pause" as const,
                normal: "play" as const,
                step: "play" as const,
                fast: "fast-forward" as const,
              }[props.speed]
            }
            intent={showLeftToPick ? "none" : "primary"}
          />
        </Popover>
      </ButtonGroup>
      <ClickMeFinger
        enterDelay={2500}
        show={playbook.section.name === "none" && playbook.emphasizeStartGame}
      />
    </div>
  );
}

function ButtonText({
  text,
  transitionOut,
}: {
  text: React.ReactNode;
  transitionOut: boolean;
}) {
  const mounted = useDelay(16);
  return (
    <div
      className={css.buttonText}
      style={{
        opacity: mounted && !transitionOut ? 1 : 0,
        transform: `translateY(${transitionOut ? -60 : mounted ? 0 : 60}px)`,
      }}
    >
      {text}
    </div>
  );
}
