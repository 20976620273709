import { avatarSerializer } from "./avatarSerializer";
import { createSvgShapes } from "./createSvgShapes";
import { expandGrid } from "./expandGrid";
import { padGrid } from "./padGrid";

export function createAvatarSvgShapes(avatar: string) {
  const deserializedAvatar = avatarSerializer.fromString(avatar);

  if (deserializedAvatar === null) {
    return null;
  }

  const paddedAvatar = padGrid(deserializedAvatar);

  const foreground = createSvgShapes(paddedAvatar);
  const background = createSvgShapes(expandGrid(paddedAvatar));

  return {
    foreground,
    background,
  };
}
