import { AnchorButton, Colors, Icon } from "@blueprintjs/core";
import {
  BotColor,
  BotOutcome,
  type TransitionSlotSelection,
} from "@zilch/bot-models";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import type { GameEngineInstance, GameOutcome } from "./GameEngine";
import { useUserBotConfigListQuery } from "./useUserBotConfigListQuery";
import css from "./BotTerminals.module.css";
import * as csx from "csx";
import { type ITheme, Terminal as XTerm } from "xterm";
import { WebglAddon } from "xterm-addon-webgl";
import "xterm/css/xterm.css";
import { useEffect, useRef, useState } from "react";
import { panic } from "@zilch/panic";
import { ResizeSensor2 } from "@blueprintjs/popover2";
import { debounce } from "lodash";
import { usePulse } from "@zilch/use-pulse";
import { useDelay } from "@zilch/delay";
import { useAnimatedNumber } from "@zilch/animated-number";

interface Props {
  gameEngineInstance: GameEngineInstance;
  slotSelections: TransitionSlotSelection[];
  gameOutcome: GameOutcome;
  times: number[];
}

export function BotTerminals(props: Props) {
  const [collapsedTerminals, setCollapsedTerminals] = useState(
    new Set<number>()
  );

  const slotSelections = [...props.slotSelections];
  while (slotSelections.length < props.gameEngineInstance.gameConfig.minSlots) {
    slotSelections.push(null);
  }

  useEffect(() => {
    if (
      props.gameOutcome.status !== "done" ||
      (props.gameOutcome.primary?.type !== "bug-detected" &&
        props.gameOutcome.primary?.type !== "time-limit-exceeded" &&
        props.gameOutcome.primary?.type !==
          "victory-over-boss-but-incompatible-config")
    ) {
      return;
    }

    setCollapsedTerminals(
      new Set(
        props.gameOutcome.botOutcomes
          .filter((outcome) => {
            if (
              props.gameOutcome.status === "done" &&
              props.gameOutcome.primary?.type ===
                "victory-over-boss-but-incompatible-config"
            ) {
              return outcome.outcome !== BotOutcome.Victory;
            }

            return (
              outcome.outcome !== BotOutcome.Error &&
              outcome.outcome !== BotOutcome.TimeLimitExceeded
            );
          })
          .map((outcome) => outcome.index)
      )
    );
  }, [props.gameOutcome]);

  const [outcomeKey, setOutcomeKey] = useState(0);
  useEffect(() => {
    setOutcomeKey((value) => value + 1);
  }, [props.gameOutcome]);

  return (
    <div
      className={css.container}
      style={{
        gridTemplateRows: slotSelections
          .map((_, index) => (collapsedTerminals.has(index) ? "23px" : "1fr"))
          .join(" "),
      }}
    >
      {slotSelections.map((slotSelection, index) => {
        return (
          <BotTerminalContainer
            gameEngineInstance={props.gameEngineInstance}
            key={index}
            index={index}
            time={props.times[index] ?? 0}
            slotSelection={slotSelection}
            collapsed={collapsedTerminals.has(index)}
            terminalAction={
              props.gameOutcome.status === "done"
                ? props.gameOutcome.botOutcomes[index]?.outcome ===
                  BotOutcome.TimeLimitExceeded
                  ? "time-limit"
                  : props.gameOutcome.botOutcomes[index]?.outcome ===
                    BotOutcome.Error
                  ? "bug"
                  : null
                : null
            }
            pulseKey={
              props.gameOutcome.status === "done" &&
              ((props.gameOutcome.primary?.type ===
                "victory-over-boss-but-incompatible-config" &&
                props.gameOutcome.botOutcomes[index]?.outcome ===
                  BotOutcome.Victory) ||
                props.gameOutcome.botOutcomes[index]?.outcome ===
                  BotOutcome.Error ||
                props.gameOutcome.botOutcomes[index]?.outcome ===
                  BotOutcome.TimeLimitExceeded)
                ? outcomeKey
                : false
            }
            onToggleCollapsed={() => {
              setCollapsedTerminals((collapsedTerminals) => {
                const newCollapsedTerminals = new Set(collapsedTerminals);
                if (newCollapsedTerminals.has(index)) {
                  newCollapsedTerminals.delete(index);
                } else {
                  newCollapsedTerminals.add(index);
                }
                return newCollapsedTerminals;
              });
            }}
          />
        );
      })}
    </div>
  );
}

const theme: ITheme = {
  background: Colors.DARK_GRAY1,
  black: Colors.GRAY2,
  blue: Colors.BLUE4,
  brightBlue: Colors.BLUE5,
  brightBlack: Colors.GRAY1,
  cyan: Colors.TURQUOISE4,
  brightCyan: Colors.TURQUOISE5,
  green: Colors.GREEN4,
  brightGreen: Colors.GREEN5,
  magenta: Colors.VIOLET4,
  brightMagenta: Colors.VIOLET5,
  red: Colors.RED4,
  brightRed: Colors.RED5,
  white: Colors.LIGHT_GRAY5,
  brightWhite: Colors.WHITE,
  yellow: Colors.GOLD4,
  brightYellow: Colors.GOLD5,
  foreground: Colors.LIGHT_GRAY1,
  selectionBackground: Colors.BLUE1,
  cursor: Colors.LIGHT_GRAY2,
  cursorAccent: Colors.WHITE,
};

function BotTerminalContainer(props: {
  index: number;
  slotSelection: TransitionSlotSelection;
  gameEngineInstance: GameEngineInstance;
  collapsed: boolean;
  onToggleCollapsed(): void;
  pulseKey: number | false;
  terminalAction: "time-limit" | "bug" | null;
  time: number;
}) {
  const userBotConfigListQuery = useUserBotConfigListQuery(
    props.slotSelection?.type === "user" ? props.slotSelection.owner : null,
    props.gameEngineInstance.gameConfig.gameId
  );

  const [focused, setFocused] = useState(false);

  const color = props.slotSelection?.color
    ? csx
        .color(BotColor[props.slotSelection.color])
        .fade(focused ? 1 : 0.7)
        .toString()
    : Colors.DARK_GRAY5;

  let botName: string;

  if (props.slotSelection?.type === "boss") {
    botName = `Boss Bot (${
      { easy: "Easy", medium: "Medium", hard: "Hard" }[
        props.slotSelection.difficulty
      ]
    })`;
  } else if (props.slotSelection?.type === "practice") {
    botName = "Practice Bot";
  } else if (props.slotSelection?.type === "user") {
    if (props.slotSelection.transitionData.name) {
      botName = props.slotSelection.transitionData.name;
    } else if (userBotConfigListQuery.isSuccess) {
      if (userBotConfigListQuery.data === "nonexistent-user") {
        botName = "Unable to load bot";
      } else {
        botName =
          userBotConfigListQuery.data.bySlot(props.slotSelection)?.name ?? "";
      }
    } else if (userBotConfigListQuery.isLoading) {
      botName = "";
    } else {
      botName =
        props.slotSelection.transitionData.name ??
        `${props.slotSelection.owner}/${props.slotSelection.repo}`;
    }
  } else {
    botName = "No bot selected";
  }

  const [renderPulse, triggerPulse] = usePulse("danger");

  const triggerPulseRef = useRef(triggerPulse);
  triggerPulseRef.current = triggerPulse;

  useEffect(() => {
    if (props.pulseKey !== false) {
      triggerPulseRef.current(100);
    }
  }, [props.pulseKey]);

  return (
    <div
      className={css.outerTerminalContainer}
      style={{
        borderColor: color,
        boxShadow: `inset 0 0 0 1px ${focused ? color : "transparent"}`,
      }}
    >
      <div className={css.terminalHeader}>
        <div
          tabIndex={0}
          onClick={props.onToggleCollapsed}
          role="button"
          className={classes(
            css.terminalToggle,
            !props.slotSelection && "bp4-text-muted"
          )}
        >
          <Icon
            icon={props.collapsed ? "chevron-right" : "chevron-down"}
            size={12}
          />
          <span key={botName} className={transitionInFromCss.left}>
            {botName}{" "}
            <span className="bp4-text-muted">
              / {props.gameEngineInstance.gameConfig.slots[props.index]}
            </span>
          </span>
        </div>
        <BotTime
          time={props.time}
          timeLimitExceeded={props.terminalAction == "time-limit"}
        />
      </div>
      <BotTerminal
        onFocus={() => {
          setFocused(true);
        }}
        addPaddingBelowTerminal={!!props.terminalAction}
        onBlur={() => {
          setFocused(false);
        }}
        onSetStdoutListener={(listener) => {
          props.gameEngineInstance.stdoutListeners.set(props.index, listener);
        }}
        onSetClearListener={(listener) => {
          props.gameEngineInstance.clearListeners.set(props.index, listener);
        }}
      />
      {!props.collapsed && props.pulseKey !== false && props.terminalAction && (
        <TerminalActionButton
          type={
            props.terminalAction === "time-limit"
              ? "performance-tips"
              : "debugging-guide"
          }
          key={props.pulseKey}
        />
      )}
      {renderPulse()}
    </div>
  );
}

export function stringifyBotTime(value: number) {
  if (value < 10) {
    return value.toFixed(3) + "ms";
  } else if (value < 100) {
    return value.toFixed(2) + "ms";
  } else if (value < 1000) {
    return value.toFixed(1) + "ms";
  } else if (value < 10000) {
    return (value / 1000).toFixed(3) + "s";
  } else if (value < 100000) {
    return (value / 1000).toFixed(2) + "s";
  } else if (value < 1000000) {
    return (value / 1000).toFixed(1) + "s";
  } else {
    return Math.round(value / 1000) + "s";
  }
}

function BotTime({
  time,
  timeLimitExceeded,
}: {
  time: number;
  timeLimitExceeded: boolean;
}) {
  const AnimatedTime = useAnimatedNumber(time, "up");
  return (
    <div
      className={classes(
        "bp4-text-muted",
        css.botTime,
        timeLimitExceeded && css.timeLimitExceeded
      )}
    >
      <AnimatedTime
        render={(value) => {
          return stringifyBotTime(value);
        }}
      />
    </div>
  );
}

function TerminalActionButton({
  type,
}: {
  type: "debugging-guide" | "performance-tips";
}) {
  const delay = useDelay(7400);
  if (!delay) {
    return null;
  }
  return (
    <AnchorButton
      minimal
      style={{ position: "absolute", bottom: 6, right: 6 }}
      small
      icon={<Icon color={Colors.GOLD5} icon="lightbulb" />}
      className={transitionInFromCss.bottom}
      href={
        type === "debugging-guide"
          ? "/docs/debugging-guide"
          : "/docs/performance-tips"
      }
      target="_blank"
    >
      {type === "debugging-guide" ? "Debugging Guide" : "Performance Tips"}
    </AnchorButton>
  );
}

function BotTerminal({
  onBlur,
  onFocus,
  onSetClearListener,
  onSetStdoutListener,
  addPaddingBelowTerminal,
}: {
  onFocus(): void;
  addPaddingBelowTerminal: boolean;
  onBlur(): void;
  onSetClearListener(listener: () => void): void;
  onSetStdoutListener(listener: (stdout: string | Buffer) => void): void;
}) {
  const termDomElementRef = useRef<HTMLDivElement | null>(null);

  const resizeRef = useRef<(() => void) | null>(null);

  const onFocusRef = useRef(onFocus);
  onFocusRef.current = onFocus;

  const onBlurRef = useRef(onBlur);
  onBlurRef.current = onBlur;

  const onSetStdoutListenerRef = useRef(onSetStdoutListener);
  onSetStdoutListenerRef.current = onSetStdoutListener;

  const onSetClearListenerRef = useRef(onSetClearListener);
  onSetClearListenerRef.current = onSetClearListener;

  const addPaddingBelowTerminalRef = useRef(addPaddingBelowTerminal);
  addPaddingBelowTerminalRef.current = addPaddingBelowTerminal;

  useEffect(() => {
    resizeRef.current?.();
  }, [addPaddingBelowTerminal]);

  useEffect(() => {
    if (!termDomElementRef.current) {
      panic("expected element ref to be defined here");
    }

    const term = new XTerm({
      theme,
      convertEol: true,
      disableStdin: true,
      cursorBlink: false,
      cursorWidth: 1,
    });

    term.open(termDomElementRef.current);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const webglTerm = (window as any).webglTerm || false;
    // TODO maybe don't do webgl addon so terminal stuff shows up in posthog?
    if (webglTerm) {
      term.loadAddon(new WebglAddon());
    }
    term.textarea?.addEventListener("focus", handleFocus);
    term.textarea?.addEventListener("blur", handleBlur);

    term.attachCustomKeyEventHandler((event) => {
      const ctrlKey = navigator.platform.includes("Mac")
        ? event.metaKey
        : event.ctrlKey;
      if (ctrlKey && event.code === "KeyC" && event.type === "keydown") {
        const selection = term.getSelection();
        if (selection) {
          navigator.clipboard.writeText(selection);
          return false;
        }
      }
      return true;
    });

    resizeRef.current = debounce(
      () => {
        if (termDomElementRef.current) {
          resizeTerminal(
            term,
            termDomElementRef.current,
            addPaddingBelowTerminalRef.current
          );
        }
      },
      50,
      { leading: true, trailing: true }
    );
    resizeRef.current();

    onSetStdoutListenerRef.current((stdout) => {
      term.write(stdout);
    });

    onSetClearListenerRef.current(() => {
      term.clear();
    });

    return () => {
      term.textarea?.removeEventListener("focus", handleFocus);
      term.textarea?.removeEventListener("blur", handleBlur);
      term.dispose();
    };

    function handleFocus() {
      onFocusRef.current();
    }

    function handleBlur() {
      onBlurRef.current();
    }
  }, []);

  return (
    <ResizeSensor2
      onResize={() => {
        resizeRef.current?.();
      }}
    >
      <div className={css.innerTerminalContainer}>
        <div className={css.terminal} ref={termDomElementRef} />
      </div>
    </ResizeSensor2>
  );
}

function resizeTerminal(
  terminal: XTerm,
  container: HTMLDivElement,
  addPaddingBelowTerminal: boolean
) {
  const availableHeight =
    container.clientHeight - (addPaddingBelowTerminal ? 30 : 0);
  // Make room for the scrollbar by decreasing this
  const availableWidth = container.clientWidth - 20;

  if (availableHeight === 0 || availableWidth === 0) {
    return;
  }

  const cellDimensions = getCellDimensions(terminal);
  if (
    !cellDimensions ||
    cellDimensions.width === 0 ||
    cellDimensions.height === 0
  ) {
    return;
  }

  const columns = Math.max(
    2,
    Math.floor(availableWidth / cellDimensions.width)
  );
  const rows = Math.max(1, Math.floor(availableHeight / cellDimensions.height));

  if (
    (terminal.rows === rows && terminal.cols === columns) ||
    isNaN(columns) ||
    isNaN(rows)
  ) {
    return;
  }

  // This forces a full re-render
  getRenderService(terminal)?.clear();
  terminal.resize(columns, rows);
}

function getCellDimensions(terminal: XTerm) {
  const renderService = getRenderService(terminal);

  if (!renderService) {
    return;
  }

  return {
    width: renderService.dimensions.css.cell.width,
    height: renderService.dimensions.css.cell.height,
  };
}

function getRenderService(terminal: XTerm): {
  dimensions: { css: { cell: { width: number; height: number } } };
  clear: () => void;
} | null {
  // @ts-expect-error xterm doesn't have a public API for this unfortunately
  return terminal._core._renderService;
}
