.container {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.img {
  transition: all cubic-bezier(0.5, 1.7, 0.5, 1) 0.6s;
  position: absolute;
  height: min(400px, calc(80vh - 140px));
  width: min(400px, calc(80vw - 520px));
  min-width: 150px;
  min-height: 150px;
  filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.4));
  object-fit: contain;
}

.loadStatus {
  color: var(--gray2);
  font-weight: 600;
  font-size: 22px;
  position: absolute;
}

.svgContainer {
  position: absolute;
  height: min(1000px, min(80vh, calc(80vw - 380px)));
  aspect-ratio: 1/1;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  transition: all ease 0.3s;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg {
  width: 160%;
  height: 160%;
  pointer-events: none;
  position: absolute;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2));
}

.svgOverlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  background: radial-gradient(
    at 18% 18%,
    rgba(255, 255, 255, 0.15),
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0.6) 90%
  );
}
