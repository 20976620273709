import "./colors.css";

if (window.location.origin !== ClientEnv.ORIGIN) {
  window.location.href = ClientEnv.ORIGIN;
  panic("Page must only be loaded from proper origin");
}

import ReactDOM from "react-dom/client";
import { FocusStyleManager, Spinner } from "@blueprintjs/core";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./queryClient";
import css from "./index.module.css";
import { UserStore } from "./stores/UserStore";
import { groups, RouteProvider, routes, useRoute } from "./router";
import { panic } from "@zilch/panic";
import { PremiumStore } from "./stores/PremiumStore";
import { memo, Suspense, useEffect, useRef, useState } from "react";
import { TitleBar } from "./components/common/TitleBar";
import { classes, delayCss, transitionInFromCss } from "@zilch/css-utils";
import { GameScreen } from "./components/game/GameScreen";
import { GetPremium } from "./components/get-premium/GetPremium";
import { NotFound } from "./components/not-found/NotFound";
import { AccountScreen } from "./components/account/AccountScreen";
import { PromptStore } from "./stores/PromptStore";
import { StarStore } from "./stores/StarStore";
import { Store } from "./components/store/Store";
import type { GameConfig } from "@zilch/game-config";
import { HomeScreen } from "./components/home/HomeScreen";
import { NewBotStore } from "./stores/NewBotStore";
import { TournamentScreen } from "./components/tournament/TournamentScreen";
import { SmallScreenMessage } from "./components/common/SmallScreenMessage";
import { processZilchGameFile } from "./components/common/useGameSelector";
import { BrowserMessage } from "./components/common/BrowserMessage";
import { ProvidePlaybook } from "./components/playbook/Playbook";
import { initPosthog } from "@zilch/posthog";

// NOTE: if editing any of the posthog config also do so for the docs
//   at src/site/.vitepress/config.ts

// By default only send events from prod. Remove this statement
// if you're wanting to test sending events from local. Events
// can be send from the https://zilch.local domain.
if (window.location.origin === "https://www.zilch.dev") {
  initPosthog();
}

FocusStyleManager.onlyShowFocusOnTabs();

window.addEventListener("keydown", (e) => {
  if (
    document.activeElement &&
    (document.activeElement instanceof HTMLDivElement ||
      document.activeElement instanceof HTMLAnchorElement) &&
    document.activeElement.tabIndex === 0 &&
    (e.key === "Enter" || e.key === "Spacebar")
  ) {
    document.activeElement.click();
  }
});

const App = memo(() => {
  const route = useRoute();
  const routeRef = useRef(route);
  routeRef.current = route;
  const titlebarPaddingRightChangeListenerRef = useRef<
    null | ((paddingRight: number) => void)
  >(null);
  const [gameConfig, setGameConfig] = useState<GameConfig | null>(null);

  let content;
  let displayContentOnly = false;

  if (
    groups.githubAuthCallback.has(route) ||
    groups.githubBotAuthCallback.has(route) ||
    groups.stripeCallback.has(route)
  ) {
    displayContentOnly = true;
    content = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
        className={classes(transitionInFromCss.bottom, delayCss["300"])}
      >
        <div style={{ maxWidth: "300px", fontSize: "22px" }}>
          Close this page and return to the tab (or terminal) where you started
          the auth flow.
        </div>
      </div>
    );
  } else if (route.name === routes.home.name) {
    content = <HomeScreen />;
  } else if (groups.game.has(route)) {
    const key = JSON.stringify([
      route.params.dev ?? false,
      route.params.release,
      route.name === routes.internalGame.name
        ? route.params.gameId
        : route.params.owner + "/" + route.params.repo,
    ]);
    content = (
      <GameScreen
        onSetGameConfig={setGameConfig}
        route={route}
        key={key}
        onChangeBotTerminalsWidth={(width) => {
          titlebarPaddingRightChangeListenerRef.current?.(width);
        }}
      />
    );
  } else if (route.name === routes.account.name) {
    content = <AccountScreen route={route} />;
  } else if (route.name === routes.store.name) {
    content = <Store />;
  } else if (groups.tournament.has(route)) {
    content = <TournamentScreen onSetGameConfig={setGameConfig} />;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (route.name === false) {
    content = <NotFound />;
  } else {
    panic("Unhandled route", route);
  }

  const isGameScreenRoute = groups.game.has(route);
  useEffect(() => {
    if (!isGameScreenRoute) {
      titlebarPaddingRightChangeListenerRef.current?.(0);
    }
  }, [isGameScreenRoute]);

  useEffect(() => {
    const root = document.querySelector<HTMLDivElement>("#root");

    if (!root) {
      return;
    }

    const getZilchGameFile = (e: DragEvent) => {
      const files = e.dataTransfer?.files ?? [];
      const file = files[0];
      if (files.length !== 1 || !file?.name.endsWith(".zilchgame")) {
        return null;
      }
      return file;
    };

    let dragging = false;

    root.addEventListener("dragenter", (e) => {
      e.preventDefault();

      if (dragging) {
        return;
      }

      dragging = true;

      root.classList.add("dragging");
    });

    root.addEventListener("dragover", (e) => {
      e.preventDefault();
    });

    root.addEventListener("dragleave", (e) => {
      e.preventDefault();

      if (!dragging || e.relatedTarget !== null) {
        return;
      }

      dragging = false;
      root.classList.remove("dragging");
    });

    root.addEventListener("drop", async (e) => {
      e.preventDefault();

      dragging = false;
      root.classList.remove("dragging");

      const file = getZilchGameFile(e);

      if (!file) {
        return;
      }

      const result = await processZilchGameFile(file);

      if (!result) {
        return;
      }

      if (
        routes.externalGame.name === routeRef.current.name &&
        routeRef.current.params.dev &&
        routeRef.current.params.owner === result.owner &&
        routeRef.current.params.repo === result.repo
      ) {
        routes
          .externalGame({
            repo: result.repo,
            refreshKey: (routeRef.current.params.refreshKey ?? 0) + 1,
            owner: result.owner,
            dev: true,
          })
          .replace();
      } else {
        routes
          .externalGame({
            repo: result.repo,
            owner: result.owner,
            dev: true,
          })
          .push();
      }
    });
  }, []);

  if (displayContentOnly) {
    return content;
  }

  return (
    <Suspense
      fallback={
        <Spinner
          size={50}
          className={classes(
            transitionInFromCss.bottom,
            delayCss[180],
            css.spinner
          )}
        />
      }
    >
      <TitleBar
        gameConfig={gameConfig}
        onSetTitlebarPaddingRightChangeListener={(listener) => {
          titlebarPaddingRightChangeListenerRef.current = listener;
        }}
      />
      {content}
      <GetPremium />
      <div className={css.dropIndicator}>
        {groups.game.has(route) && route.params.dev ? (
          <>Drop&nbsp;.zilchgame&nbsp;file&nbsp;here</>
        ) : (
          <>
            Launch&nbsp;game&nbsp;maker,
            drop&nbsp;.zilchgame&nbsp;file&nbsp;here.
          </>
        )}
      </div>
    </Suspense>
  );
});

const root = ReactDOM.createRoot(
  document.getElementById("root") ??
    panic(`Unable to find element with id "root"`)
);

// TODO add React.StrictMode when Blueprint 6.0 comes out
root.render(
  <QueryClientProvider client={queryClient}>
    <RouteProvider>
      <PromptStore.Provide>
        {(prompts) => {
          return (
            <UserStore.Provide>
              <NewBotStore.Provide>
                <StarStore.Provide>
                  <PremiumStore.Provide>
                    <BrowserMessage>
                      <SmallScreenMessage>
                        <ProvidePlaybook>
                          <App />
                          {prompts}
                        </ProvidePlaybook>
                      </SmallScreenMessage>
                    </BrowserMessage>
                  </PremiumStore.Provide>
                </StarStore.Provide>
              </NewBotStore.Provide>
            </UserStore.Provide>
          );
        }}
      </PromptStore.Provide>
    </RouteProvider>
  </QueryClientProvider>
);
