import { DataTransition } from "../tournament/DataTransition";
import css from "./ClickMeFinger.module.css";

interface Props {
  show: boolean;
  enterDelay?: number;
  left?: number;
  bottom?: number;
}

export function ClickMeFinger(props: Props) {
  return (
    <DataTransition
      enterDelay={props.enterDelay ?? 100}
      items={props.show ? ["show"] : []}
      exitDuration={300}
      getItemKey={(key) => key}
      render={(key, transitionState) => (
        <div
          key={key}
          style={{
            opacity: transitionState === "entered" ? 1 : 0,
            left: props.left ?? -22,
            bottom: props.bottom ?? -48,
          }}
          className={css.clickMe}
        >
          👉
        </div>
      )}
    />
  );
}
