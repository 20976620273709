.container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.iconContainer {
  background: var(--dark-gray2);
  border-radius: 100%;
  padding: 60px;
}

.icon {
  transition: all ease .3s;
}