/**
 * Panic is reserved for errors we don't have a reasonable
 * way to recover from.
 */
export function panic(
  message: string,
  _valueWithTypeNarrowedToNever?: never
): never {
  throw new Error(message);
}
