@keyframes TransitionUpAnimation {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.container {
  position: relative;
  height: 100%;
  overflow-x: auto;
  overflow-y: scroll;
}

.container:before {
  content: "";
  position: fixed;
  left: 0px;
  right: 14px;
  top: 0px;
  height: 100px;
  background: linear-gradient(var(--dark-gray3), transparent);
  z-index: 1;
  pointer-events: none;
}

.content {
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  margin-top: 80px;
  margin-bottom: 50px;
  width: 100%;
  max-width: 490px;
  min-width: 400px;
}

.header {
  padding: 20px 30px;
  padding-top: 80px;
  border-radius: 5px;
  background: linear-gradient(var(--blue4), var(--blue3));
  color: var(--white);
  font-size: 28px;
  font-weight: 700;
  margin-left: -10px;
  margin-right: -10px;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.content > *:nth-child(0) {
  animation: TransitionUpAnimation ease 200ms both;
  animation-delay: 0ms;
}

.content > *:nth-child(1) {
  animation: TransitionUpAnimation ease 200ms both;
  animation-delay: 30ms;
}

.content > *:nth-child(2) {
  animation: TransitionUpAnimation ease 200ms both;
  animation-delay: 60ms;
}

.content > *:nth-child(3) {
  animation: TransitionUpAnimation ease 200ms both;
  animation-delay: 90ms;
}

.content > *:nth-child(4) {
  animation: TransitionUpAnimation ease 200ms both;
  animation-delay: 120ms;
}

.content > *:nth-child(5) {
  animation: TransitionUpAnimation ease 200ms both;
  animation-delay: 150ms;
}

.content > *:nth-child(6) {
  animation: TransitionUpAnimation ease 200ms both;
  animation-delay: 180ms;
}

.content > *:nth-child(7) {
  animation: TransitionUpAnimation ease 200ms both;
  animation-delay: 210ms;
}

.content > *:nth-child(8) {
  animation: TransitionUpAnimation ease 200ms both;
  animation-delay: 240ms;
}

.content > *:nth-child(9) {
  animation: TransitionUpAnimation ease 200ms both;
  animation-delay: 270ms;
}
