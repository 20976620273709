import { times } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import css from "./VirtualList.module.css";

interface Props {
  itemCount: number;
  itemHeight: number;
  renderItem(itemIndex: number): React.ReactNode;
  getItemKey?(itemIndex: number): string;
  height?: number;
}

export function VirtualList(props: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const itemRange = useMemo(() => {
    const buffer = 5;
    return {
      start: Math.floor(scrollTop / props.itemHeight) - buffer,
      end: Math.ceil((scrollTop + containerHeight) / props.itemHeight) + buffer,
    };
  }, [containerHeight, scrollTop, props.itemHeight]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    setContainerHeight(containerRef.current.clientHeight);

    const observer = new ResizeObserver(([entry]) => {
      if (!entry) {
        return;
      }

      setContainerHeight(entry.contentRect.height);
    });

    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={css.container}
      style={{ height: props.height ?? "100%" }}
      onScroll={(e) => setScrollTop((e.target as HTMLDivElement).scrollTop)}
    >
      <div style={{ height: props.itemCount * props.itemHeight + "px" }} />
      {times(itemRange.end - itemRange.start).map((value) => {
        const itemIndex = value + itemRange.start;

        if (itemIndex >= props.itemCount) {
          return null;
        }

        return (
          <div
            key={props.getItemKey?.(itemIndex) ?? itemIndex}
            className={css.virtualListItem}
            style={{
              top: itemIndex * props.itemHeight + "px",
              height: props.itemHeight + "px",
            }}
          >
            {props.renderItem(itemIndex)}
          </div>
        );
      })}
    </div>
  );
}
