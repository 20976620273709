import { Colors, Icon } from "@blueprintjs/core";
import css from "./EmailCheckVisual.module.css";

export function EmailCheckVisual() {
  return (
    <div className={css.container}>
      <div className={css.iconContainer}>
        <Icon
          icon="office"
          size={80}
          color={Colors.LIGHT_GRAY1}
          className={css.icon}
        />
      </div>
    </div>
  );
}
