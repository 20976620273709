.content {
  padding: 15px;
  width: 260px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}