import {
  Button,
  Classes,
  Colors,
  FormGroup,
  H4,
  Menu,
  MenuDivider,
  Tag,
} from "@blueprintjs/core";
import { MenuItem2 } from "@blueprintjs/popover2";
import React, { useEffect, useState } from "react";
import { SiGithub } from "react-icons/si";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import { UserStore } from "../../stores/UserStore";
import css from "./AccountButton.module.css";
import { Popover } from "./Popover";
import { routes } from "../../router";
import { PremiumStore } from "../../stores/PremiumStore";
import { useDelayedValue } from "@zilch/delay";
import * as csx from "csx";
import { transitionScreen } from "../../transitionScreen";
import { preventDefaultLinkClickBehavior } from "type-route";

export function AccountButton() {
  const userStore = UserStore.use();
  const [showSessionExpiredMessage, setShowSessionExpiredMessage] =
    useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);

  const sessionExpired = userStore.query.data?.type === "sessionExpired";
  const premiumStatus = usePremiumStatus();

  useEffect(() => {
    if (sessionExpired) {
      setShowSessionExpiredMessage(true);
    }
  }, [sessionExpired]);

  useEffect(() => {
    if (!userStore.query.isLoading) {
      setInitialLoadComplete(true);
    }
  }, [userStore.query.isLoading]);

  const allowAttentionDotVisibility = useDelayedValue(
    userStore.query.data?.type === "authenticated",
    { delay: 300 }
  );

  if (!initialLoadComplete) {
    return null;
  }

  const open =
    showSessionExpiredMessage ||
    (accountMenuOpen && userStore.query.data?.type === "authenticated");

  const signInDisabled =
    userStore.signingIn && userStore.signingIn !== "titleBar";

  return (
    <Popover
      isOpen={showSessionExpiredMessage}
      onClose={() => void setShowSessionExpiredMessage(false)}
      background="blue"
      content={<SessionExpiredMessage />}
    >
      <Popover
        placement="bottom"
        onInteraction={(open) =>
          setAccountMenuOpen(
            userStore.query.data?.type === "authenticated" && open
          )
        }
        isOpen={
          userStore.query.data?.type === "authenticated"
            ? accountMenuOpen
            : false
        }
        content={<AccountMenu premiumStatus={premiumStatus} />}
      >
        <>
          <div
            className={classes(
              css.attentionDot,
              allowAttentionDotVisibility &&
                (premiumStatus === "inactive-premium" ||
                  premiumStatus === "expiring-email") &&
                css.attentionDotVisible
            )}
            style={{
              background:
                premiumStatus === "inactive-premium"
                  ? Colors.RED4
                  : premiumStatus === "expiring-email"
                  ? Colors.ORANGE4
                  : Colors.DARK_GRAY3,
            }}
          />
          {userStore.query.data?.type === "authenticated" && (
            <div
              className={transitionInFromCss.top}
              style={{
                borderRadius: "2px",
                background: Colors.DARK_GRAY2,
              }}
            >
              <Button
                active={open}
                key="authenticated"
                outlined={
                  premiumStatus === "inactive-premium" ||
                  premiumStatus === "expiring-email"
                }
                intent={
                  premiumStatus === "inactive-premium"
                    ? "danger"
                    : premiumStatus === "expiring-email"
                    ? "warning"
                    : "none"
                }
                icon={
                  userStore.query.data.impersonatorUserId ? (
                    "hat"
                  ) : (
                    <SiGithub size={16} color={Colors.GRAY4} />
                  )
                }
                rightIcon="caret-down"
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <span>{userStore.query.data.likelyLogin}</span>
                  {(premiumStatus === "inactive-premium" ||
                    premiumStatus === "expiring-email") && (
                    <Tag
                      minimal
                      intent={
                        premiumStatus === "inactive-premium"
                          ? "danger"
                          : "warning"
                      }
                    >
                      {premiumStatus === "inactive-premium"
                        ? "Premium inactive"
                        : "Expiring email"}
                    </Tag>
                  )}
                </span>
              </Button>
            </div>
          )}
          {userStore.query.data?.type !== "authenticated" && (
            <Button
              key="unauthenticated"
              className={classes(css.signIn, transitionInFromCss.top)}
              icon={
                <SiGithub
                  size={16}
                  color={csx
                    .color(Colors.GRAY4)
                    .fade(signInDisabled ? 0.3 : 1)
                    .toString()}
                />
              }
              disabled={signInDisabled}
              loading={userStore.signingIn === "titleBar"}
              onClick={async () => {
                await userStore.signIn("titleBar");
              }}
            >
              Sign in
            </Button>
          )}
        </>
      </Popover>
    </Popover>
  );
}

function SessionExpiredMessage() {
  return (
    <div className={css.message}>
      <H4>Sign in again to get back to where you left off.</H4>
      <div>
        You were gone for a bit so we signed you out to keep your account
        secure.
      </div>
    </div>
  );
}

type PremiumStatus = ReturnType<typeof usePremiumStatus>;
function usePremiumStatus() {
  const userStore = UserStore.use();

  if (userStore.query.data?.type !== "authenticated") {
    return null;
  }

  let premiumStatus:
    | "active-premium"
    | "expiring-email"
    | "inactive-premium"
    | "no-premium" = "no-premium";

  const user = userStore.query.data;

  if (
    (user.likelyHasPremium || user.hasPremium) &&
    userStore.premiumSources.size === 1 &&
    userStore.premiumSources.has("organization-plan") &&
    user.organizationEmail?.status === "expiring-soon"
  ) {
    premiumStatus = "expiring-email";
  } else if (user.likelyHasPremium) {
    premiumStatus = "active-premium";
  } else if (user.hasPremium) {
    premiumStatus = "active-premium";
  } else if (user.organizationEmail?.status === "expired") {
    premiumStatus = "inactive-premium";
  } else if (
    user.organizationEmail &&
    user.organizationEmail.organizationPlanExpiration !== null &&
    user.organizationEmail.organizationPlanExpiration < Date.now()
  ) {
    premiumStatus = "inactive-premium";
  } else if (
    user.subscriptions.length > 0 &&
    user.subscriptions.every(
      (subscription) =>
        (subscription.status !== "active" &&
          subscription.status !== "incomplete") ||
        subscription.usedSeatCount > subscription.seatCount
    )
  ) {
    premiumStatus = "inactive-premium";
  }

  return premiumStatus;
}

function AccountMenu({ premiumStatus }: { premiumStatus: PremiumStatus }) {
  const userStore = UserStore.use();
  const premiumStore = PremiumStore.use();

  if (userStore.query.data?.type !== "authenticated") {
    return null;
  }

  const accountRoute = routes.account();
  const accountLink = {
    href: accountRoute.href,
    onClick: (event: React.MouseEvent) => {
      if (preventDefaultLinkClickBehavior(event)) {
        transitionScreen(accountRoute);
      }
    },
  };

  return (
    <Menu className={css.accountMenu}>
      <MenuItem2
        {...accountLink}
        text={
          <div className={css.plan}>
            <span>Plan</span>
            {premiumStatus === "active-premium" && (
              <Tag minimal intent="success">
                Premium
              </Tag>
            )}
            {premiumStatus === "inactive-premium" && (
              <Tag minimal intent="danger">
                Premium (inactive)
              </Tag>
            )}
            {premiumStatus === "expiring-email" && (
              <Tag minimal intent="warning">
                Premium (reverify)
              </Tag>
            )}
            {premiumStatus === "no-premium" && <Tag minimal>Standard</Tag>}
          </div>
        }
      />
      {premiumStatus === "no-premium" && (
        <>
          <FormGroup
            className={css.premium}
            helperText={
              <>
                <a
                  style={{ fontWeight: 600 }}
                  className={Classes.POPOVER_DISMISS}
                  onClick={() => {
                    premiumStore.setSection("feature-overview");
                  }}
                >
                  Free Premium
                </a>{" "}
                is available to students and employees of select organizations.
              </>
            }
          >
            <Button
              fill
              intent="primary"
              className={Classes.POPOVER_DISMISS}
              onClick={() => {
                premiumStore.setSection("feature-overview");
              }}
            >
              Learn about Premium
            </Button>
          </FormGroup>
          <MenuDivider />
        </>
      )}
      <MenuItem2 text="Account" icon="person" {...accountLink} />
      {userStore.query.data.impersonatorUserId !== null && (
        <MenuItem2
          text="Stop Impersonation"
          icon="hat"
          onClick={userStore.stopImpersonation}
        />
      )}
      <MenuItem2
        text="Sign Out"
        icon="log-out"
        onClick={() => void userStore.signOut()}
      />
    </Menu>
  );
}
