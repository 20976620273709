import { useAnimatedNumber } from "@zilch/animated-number";
import { classes } from "@zilch/css-utils";
import { useDelay, useDelayedValue } from "@zilch/delay";
import { useEffect, useState } from "react";
import { PremiumStore } from "../../stores/PremiumStore";
import css from "./PricePerUnit.module.css";

export function PricePerUnit() {
  const premiumStore = PremiumStore.use();

  const displayPrice =
    useDelayedValue(premiumStore.section, { delay: 400 }) === "checkout"
      ? ClientEnv.YEARLY_PREMIUM_PRICE_PER_SEAT
      : 0;
  const Price = useAnimatedNumber(displayPrice);

  return (
    <div
      className={classes(
        css.container,
        premiumStore.section === "calculate-price" && css.containerHidden
      )}
    >
      <div className={css.priceLabel}>
        All of Zilch
        <br />
        for just{" "}
        <span
          style={{
            transform: "translate(5px, 1px) scale(1.3) rotate(20deg)",
            display: "inline-block",
          }}
        >
          👉
        </span>
      </div>
      <div className={css.priceContainer}>
        <div>
          <div className={css.priceTopLine}>$</div>
          <div className={css.priceBottomLine}>&nbsp;</div>
        </div>
        <Price render={(value) => <DollarAmount value={Math.floor(value)} />} />
        <div>
          <div className={css.priceTopLine}>
            .
            <div className={css.priceCents}>
              <Price render={(value) => value.toFixed(2).split(".")[1]} />
            </div>
            &nbsp;<span className={css.perSeatPerMonth}>/ seat / year</span>
          </div>
          <div className={css.priceBottomLine}>billed yearly</div>
        </div>
      </div>
    </div>
  );
}

function DollarAmount(props: { value: number }) {
  const [amounts, setAmounts] = useState(() => [
    {
      key: crypto.randomUUID(),
      value: props.value,
      transition: "from-bottom" as "from-top" | "from-bottom",
    },
  ]);

  useEffect(() => {
    setAmounts((amounts) => {
      const previousValue = amounts[0]?.value ?? Infinity;
      return [
        {
          key: crypto.randomUUID(),
          value: props.value,
          transition: props.value > previousValue ? "from-top" : "from-bottom",
        },
        ...amounts,
      ];
    });
    setTimeout(() => {
      setAmounts((values) => values.slice(0, -1));
    }, 400);
  }, [props.value]);

  const [width, setWidth] = useState(50);

  return (
    <div style={{ width: width + "px" }} className={css.dollarAmountContainer}>
      {amounts.map((amount, index) => {
        return (
          <Amount
            value={amount.value}
            key={amount.key}
            transition={
              index === 0
                ? amount.transition
                : amount.value > (amounts[index - 1]?.value ?? 0)
                ? "to-bottom"
                : "to-top"
            }
            onSetWidth={setWidth}
          />
        );
      })}
    </div>
  );
}

function Amount(props: {
  value: number;
  transition: "from-top" | "from-bottom" | "to-top" | "to-bottom";
  onSetWidth(width: number): void;
}) {
  const mounted = useDelay(0);
  let translateY = 0;
  let opacity = 1;

  if (mounted) {
    if (props.transition === "to-bottom") {
      translateY = -100;
      opacity = 0;
    } else if (props.transition === "to-top") {
      translateY = 100;
      opacity = 0;
    }
  } else {
    if (props.transition === "from-bottom") {
      translateY = 100;
      opacity = 0;
    } else if (props.transition === "from-top") {
      translateY = -100;
      opacity = 0;
    }
  }

  return (
    <div
      ref={(element) => {
        if (
          props.transition !== "to-bottom" &&
          props.transition !== "to-top" &&
          element
        ) {
          props.onSetWidth(element.clientWidth);
        }
      }}
      className={css.amount}
      style={{
        transform: `translateY(${translateY}%)`,
        opacity,
      }}
    >
      {props.value}
    </div>
  );
}
