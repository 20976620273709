.botCanvasContainer {
  border-radius: 4px;
  background: var(--dark-gray2);
  position: relative;
}

.buttonContainer {
  position: absolute;
  z-index: 2;
  bottom: 15.234375px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  transition: all ease 0.5s;
}

.buttonContainerFaded {
  opacity: 0.4;
}

.colorsContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 14px;
  padding-bottom: 14px;
}

.container {
  display: grid;
  grid-template-columns: auto 148px;
  grid-template-rows: 325px;
  padding: 20px;
  gap: 20px;
  padding-top: 0px;
}

.smallContainer {
  grid-template-columns: auto;
}

.tooltip {
  display: block !important;
}

.colorButton {
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 3px;
  color: var(--white);
  background: var(--dark-gray2);
  transition: all ease 0.2s;
}

.colorButton:hover {
  transform: scale(1.1);
}

.colorButton:active {
  transform: scale(0.9);
}

.editGrid {
  display: grid;
  z-index: 2;
  position: absolute;
  height: 203.125px;
  width: 203.125px;
  top: calc(50% - 20.3125px);
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity ease 0.3s;
  grid-template-columns: repeat(10, 20.3125px);
  grid-template-rows: repeat(10, 20.3125px);
}

.editGridVisible {
  opacity: 1;
}

.noPointsMessage {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 54%;
  color: var(--gray3);
  text-align: center;
  opacity: 1;
  transition: opacity ease 0.3s;
  transition-delay: 0.3s;
  pointer-events: none;
}

.noPointMessageFaded {
  transition-delay: 0s;
  opacity: 0;
}

.editGridSquare {
  border: none;
  opacity: 0;
  cursor: cell;
}

.colorLabel {
  text-transform: capitalize;
}

.botAvatar {
  position: absolute;
  top: calc(50% - 20.3125px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.editingLockedOverlay {
  position: absolute;
  left: 1px;
  right: 1px;
  top: 50px;
  bottom: 1px;
  border-radius: 3px;
  padding-bottom: 50px;
  background: rgba(47, 52, 60, 0.06);
  z-index: 3;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
}

.colorIcon {
  position: absolute;
  border-radius: 100%;
  border: 2px solid var(--dark-gray1);
  width: 13px;
  margin-top: 2px;
  margin-left: 1px;
  height: 13px;
  transition: all ease 0.2s;
}
