import ReactDOM from "react-dom/client";
import { Toaster } from "@blueprintjs/core";
import { panic } from "@zilch/panic";
import { session } from "./router";

// TODO change back to old toaster api once https://github.com/palantir/blueprint/issues/5212 is resolved
export let toaster: Toaster;

const container = document.createElement("div");
document.body.appendChild(container);

ReactDOM.createRoot(container).render(
  <Toaster
    position="bottom-right"
    ref={(instance) => {
      toaster = instance ?? panic("toaster instance should not be null");
    }}
  />
);

let previousRoute = session.getInitialRoute();
session.listen((route) => {
  if (route.name !== previousRoute.name) {
    toaster.clear();
  }
  previousRoute = route;
});
