import { classes, delayCss, transitionInFromCss } from "@zilch/css-utils";
import type { VerifyEmailState } from "./GetPremium";
import css from "./EmailCheckSectionTitle.module.css";
import { type PremiumSources, UserStore } from "../../stores/UserStore";
import { Reveal } from "../common/Reveal";

interface Props {
  verifyEmailState: VerifyEmailState;
}

export function EmailCheckSectionTitle({ verifyEmailState }: Props) {
  const userStore = UserStore.use();
  const { title, subTitle } = getTitles(
    verifyEmailState,
    userStore.premiumSources
  );

  return (
    <Reveal key={`${title}-${subTitle}`} delay={300}>
      {(text) => {
        return (
          <>
            <div
              key={`title-${verifyEmailState.checkNum}`}
              title={typeof title === "string" ? title : undefined}
              className={classes(transitionInFromCss.bottom, css.title)}
            >
              {text(title)}
            </div>

            <div
              key={`subTitle-${verifyEmailState.checkNum}`}
              title={typeof subTitle === "string" ? subTitle : undefined}
              className={classes(
                "bp4-text-muted",
                transitionInFromCss.bottom,
                delayCss[30],
                css.subTitle
              )}
            >
              {text(subTitle)}
            </div>
          </>
        );
      }}
    </Reveal>
  );
}

function getTitles(
  verifyEmailState: VerifyEmailState,
  currentPremiumSources: Set<PremiumSources>
): {
  title: string;
  subTitle: string;
} {
  const result = verifyEmailState.result;

  if (result === null) {
    return {
      title: "Enter your work / school email.",
      subTitle: currentPremiumSources.has("organization-plan")
        ? "We'll use this email address to reverify your eligibility for free Premium through your organization."
        : "We'll use this email address to determine if you're eligible for free Premium through your organization.",
    };
  }

  if (result.status === "error") {
    return {
      title: "Oops!",
      subTitle: result.message,
    };
  }

  if (result.status === "verification-code-sent") {
    if (currentPremiumSources.has("organization-plan")) {
      return {
        title: "Enter verification code.",
        subTitle: `You should receive an email verification code shortly. Enter it here to reverify your eligibility for free Premium.`,
      };
    } else {
      return {
        title: "You qualify for free Premium!",
        subTitle: `You should receive an email verification code shortly. Enter it here to unlock Premium for your account.`,
      };
    }
  }

  let organization = result.email.split("@")[1] ?? "your organization";
  if (result.status !== "no-org-for-domain") {
    organization = result.organization;
  }

  const partnershipLine =
    result.status === "org-plan-expired"
      ? `Zilch's partnership with ${organization} is expired`
      : `Zilch hasn't partnered with ${organization} yet`;

  if (currentPremiumSources.size > 0) {
    return {
      title: "That email doesn't work.",
      subTitle: `${partnershipLine}. Try another email.`,
    };
  }

  return {
    title: "You need a Premium subscription.",
    subTitle: `${partnershipLine} but we'd love to change that. Could you put in a good word for us?`,
  };
}
