.container {
  padding: 40px 60px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 0px;
  margin-top: 40px;
  border-top: 1px solid var(--dark-gray5);
}

.buySubscription {
  position: relative;
  transition: all ease .3s;
  width: 190px;
  overflow: hidden;
}

.buySubscriptionPrimary {
  transition-delay: .5s;
  transition: all cubic-bezier(.5,1.5,.5,1) .5s;
  transform: scale(1.2);
}

.buySubscriptionContent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all cubic-bezier(.5,1.5,.5,1) .5s;
}

.buySubscriptionArrow {
  position: absolute;
  right: 18px;
  top: 50%;
  margin-top: -8px;
  transition-delay: .4s;
  transition: all cubic-bezier(.5,1.5,.5,1) .4s;
  display: flex;
  align-items: center;
}