import type { GameConfig } from "@zilch/game-config";
import type { BotOutcomeListItem, GameOutcome } from "../game/GameEngine";
import { GameEngine, GameSpeed } from "../game/GameEngine";
import { useEffect, useRef, useState } from "react";
import { TournamentSlotList } from "./TournamentSlotList";
import css from "./TournamentScreen.module.css";
import type { BotColor } from "@zilch/bot-models";
import { BotOutcome } from "@zilch/bot-models";
import { type TransitionSlotSelection } from "@zilch/bot-models";
import { TournamentBotSelector } from "./TournamentBotSelector";
import { shuffle, times } from "lodash";
import { TournamentSetup } from "./TournamentSetup";
import { MINUTE } from "@zilch/time";
import { useDelayedValue } from "@zilch/delay";
import { TournamentControls } from "./TournamentControls";
import { GameOutcomeDisplay } from "../game/GameOutcomeDisplay";
import { TournamentSummary } from "./TournamentSummary";
import { TournamentVisualization } from "./TournamentVisualization";
import { sleep } from "@zilch/sleep";
import { PremiumStore } from "../../stores/PremiumStore";
import { PromptStore } from "../../stores/PromptStore";
import { useWindowSizeDerivedValue } from "@zilch/window-size";

interface Props {
  onSetGameConfig(gameConfig: GameConfig | null): void;
}

export function TournamentScreen(props: Props) {
  return (
    <GameEngine.Provide>
      <TournamentScreenWithGameEngine {...props} />
    </GameEngine.Provide>
  );
}

function TournamentScreenWithGameEngine(props: Props) {
  const tournamentManager = useTournamentManager();
  const gameConfig = tournamentManager.engine.instance?.gameConfig ?? null;
  const { progress, setProgress } = tournamentManager;

  const [selectedBotStats, setSelectedBotStats] = useState<
    number | "all" | null
  >(null);
  const onSetGameConfigRef = useRef(props.onSetGameConfig);
  onSetGameConfigRef.current = props.onSetGameConfig;
  useEffect(() => {
    onSetGameConfigRef.current(gameConfig);
  }, [gameConfig]);

  const [botSelectorOpen, setBotSelectorOpen] = useState<BotColor | boolean>(
    false
  );

  const triggerNotEnoughBotsIndicatorRef = useRef<(() => void) | null>(null);

  const shouldShowTournamentSetup = progress.state !== "in-progress";
  const shouldShowTournamentControls = progress.state === "in-progress";

  const showTournamentSetup =
    useDelayedValue(shouldShowTournamentSetup, { delay: 500 }) ||
    shouldShowTournamentSetup;
  const showTournamentControls =
    useDelayedValue(shouldShowTournamentControls, { delay: 500 }) ||
    shouldShowTournamentControls;

  const prompt = PromptStore.usePrompt();

  const small = useWindowSizeDerivedValue((width) => width < 650);

  // TODO make sure this doesn't work if no premium
  return (
    <>
      {tournamentManager.engine.renderIFrame({
        mode: "tournament",
        sandbox: true,
        gameSpeed: GameSpeed.Fast,
      })}
      <div className={css.container}>
        <TournamentSlotList
          viewStatsOpen={selectedBotStats !== null}
          gameId={gameConfig?.gameId ?? null}
          small={small}
          tournamentManager={tournamentManager}
          triggerNotEnoughBotsIndicatorRef={triggerNotEnoughBotsIndicatorRef}
          botSelectorOpen={!!botSelectorOpen}
          onOpenBotSelector={(color) => {
            setBotSelectorOpen(color ?? true);
          }}
          onViewStats={(slotIndex) => setSelectedBotStats(slotIndex)}
          onSetDifficulty={(slotIndex, difficulty) => {
            setProgress((progress) => {
              const slot = progress.slots[slotIndex];
              if (!slot || slot.type !== "boss") {
                return progress;
              }
              const newSlots = [...progress.slots];
              newSlots[slotIndex] = {
                ...slot,
                difficulty,
              };
              return {
                updateId: progress.updateId + 1,
                state: "not-started",
                statsBySlot: [],
                matchups: [],
                slots: newSlots,
              };
            });
          }}
          onChangeColor={(slotIndex, color) => {
            setProgress((progress) => {
              const currentSlot = progress.slots[slotIndex];

              if (!currentSlot) {
                return progress;
              }

              const newSlots = [...progress.slots];
              const existingIndex = newSlots.findIndex(
                (slot) => slot.color === color
              );

              if (existingIndex > -1) {
                newSlots[existingIndex] = {
                  ...newSlots[existingIndex]!,
                  color: currentSlot.color,
                };
              }

              newSlots[slotIndex] = {
                ...currentSlot,
                color,
              };

              return {
                updateId: progress.updateId + 1,
                state: "not-started",
                matchups: [],
                statsBySlot: [],
                slots: newSlots,
              };
            });
          }}
          onRemove={(slotIndex) => {
            setProgress((progress) => {
              const newSlots = [...progress.slots];
              newSlots.splice(slotIndex, 1);
              return {
                updateId: progress.updateId + 1,
                slots: newSlots,
                state: "not-started",
                statsBySlot: [],
                matchups: [],
              };
            });
          }}
        />
      </div>
      {!small && (
        <TournamentVisualization tournamentManager={tournamentManager} />
      )}
      {!small && (
        <GameOutcomeDisplay
          outcome={tournamentProgressToGameOutcome(progress)}
          offsetLeft={381}
          translateY="max(calc(10vh - 60px), calc(50vh - 30px - .4 * (100vw - 380px)))"
        />
      )}
      {showTournamentControls && (
        <TournamentControls tournamentManager={tournamentManager} />
      )}
      {showTournamentSetup && (
        <TournamentSetup
          tournamentManager={tournamentManager}
          botSelectorOpen={!!botSelectorOpen}
          onShowNotEnoughBotsIndicator={() => {
            triggerNotEnoughBotsIndicatorRef.current?.();
          }}
        />
      )}
      <TournamentBotSelector
        onStartTournament={() => {
          setBotSelectorOpen(false);
          tournamentManager.start();
        }}
        gameConfig={gameConfig}
        onClose={() => setBotSelectorOpen(false)}
        open={botSelectorOpen}
        slots={progress.slots}
        onSetSlot={(slot, selected) => {
          setProgress((progress) => {
            let newSlots: NonNullable<TransitionSlotSelection>[] = [];
            if (!selected) {
              newSlots = progress.slots.filter(
                ({ color }) => color !== slot.color
              );
            } else if (
              progress.slots.some(({ color }) => color === slot.color)
            ) {
              newSlots = progress.slots.map((existingSlot) => {
                if (existingSlot.color === slot.color) {
                  return slot;
                } else {
                  return existingSlot;
                }
              });
            } else {
              newSlots = [slot, ...progress.slots];
            }

            return {
              updateId: progress.updateId + 1,
              slots: newSlots,
              statsBySlot: [],
              matchups: [],
              state: "not-started",
            };
          });
        }}
      />
      {gameConfig && (
        <TournamentSummary
          selectedBot={selectedBotStats}
          onSetSelectedBot={setSelectedBotStats}
          progress={progress}
          gameConfig={gameConfig}
          onWatchReplay={(matchupIndex, gameIndex) => {
            prompt(() => (
              <div style={{ padding: "20px", fontSize: "18px" }}>
                Still working on the ability to watch replays of tournament
                games. Would this feature be helpful for you? Please share!
                <br />
                <br />
                <b>
                  <a href="mailto:hello@zilch.dev">hello@zilch.dev</a>
                </b>
              </div>
            ));
            // TODO
            console.log(
              "TODO watch replay for game " + matchupIndex + " " + gameIndex
            );
          }}
        />
      )}
    </>
  );
}

function tournamentProgressToGameOutcome(
  progress: TournamentProgress
): GameOutcome {
  if (progress.state === "in-progress") {
    return { status: "in-progress" };
  }

  if (progress.state === "not-started") {
    return { status: "not-started" };
  }

  const winningSlot =
    progress.slots[progress.statsBySlot.findIndex((stats) => stats.rank === 0)];

  if (!winningSlot) {
    return { status: "not-started" };
  }

  const outcomeListItem: BotOutcomeListItem = {
    index: 0,
    outcome: BotOutcome.Victory,
    slot: winningSlot,
    time: 0,
  };

  return {
    status: "done",
    gameLength: 0,
    replayProgress: null,
    botOutcomes: [outcomeListItem],
    primary: {
      type: "victory",
      subjectIndices: [0],
    },
  };
}

export type TournamentManager = ReturnType<typeof useTournamentManager>;
function useTournamentManager() {
  const engine = GameEngine.use();

  const [progress, setProgress] = useState<TournamentProgress>({
    updateId: 0,
    state: "not-started",
    slots: [],
    matchups: [],
    statsBySlot: [],
  });
  const [gamesPerMatchup, setGamesPerMatchup] = useState(4);

  const progressRef = useRef(progress);
  progressRef.current = progress;

  const premiumStore = PremiumStore.use();

  return {
    progress,
    gamesPerMatchup,
    setGamesPerMatchup,
    setProgress,
    engine,
    stop() {
      setProgress((progress) => {
        return {
          ...progress,
          state: "done",
          statsBySlot: [],
          updateId: progress.updateId + 1,
        };
      });
      engine.instance?.stop();
    },
    async start() {
      if (
        engine.status !== "created" ||
        progress.slots.length < 2 ||
        !premiumStore.hasPremium
      ) {
        // TODO
        return;
      }

      const matchups = createMatchups(progress.slots.length, gamesPerMatchup);
      const tournamentUpdateId = progress.updateId + 1;

      const initialProgress: TournamentProgress = {
        matchups,
        state: "in-progress",
        slots: progress.slots,
        updateId: tournamentUpdateId,
        statsBySlot: [],
      };

      progressRef.current = initialProgress;
      setProgress(initialProgress);

      const defaultPreset = await engine.instance.parseConfig(
        engine.instance.configPresets[0]?.value ?? ""
      );

      await engine.instance.setConfigAndTimeLimit(
        defaultPreset.serializedConfig ?? "",
        defaultPreset.config,
        {
          game: Math.min(
            engine.instance.gameConfig.defaultTimeLimitMilliseconds.game ??
              Infinity,
            MINUTE
          ),
          move: engine.instance.gameConfig.defaultTimeLimitMilliseconds.move,
        }
      );

      for (
        let matchupIndex = 0;
        matchupIndex < progressRef.current.matchups.length;
        matchupIndex++
      ) {
        const matchup = progressRef.current.matchups[matchupIndex];

        if (!matchup) {
          // TODO this should not happen
          break;
        }

        const bot1 = progress.slots[matchup.bot1SlotIndex];
        const bot2 = progress.slots[matchup.bot2SlotIndex];

        if (!bot1 || !bot2) {
          // TODO this should not happen
          break;
        }

        for (let gameIndex = 0; gameIndex < matchup.games.length; gameIndex++) {
          setProgress((progress) => {
            if (progress.updateId !== tournamentUpdateId) {
              return progress;
            }

            const newMatchups = [...progress.matchups];
            const games = [...(newMatchups[matchupIndex]?.games ?? [])];
            games[gameIndex] = "in-progress";
            newMatchups[matchupIndex] = {
              ...matchup,
              games,
            };

            return {
              ...progress,
              matchups: newMatchups,
            };
          });

          const start = performance.now();
          const gameOutcome = await engine.instance.playGame(
            gameIndex % 2 === 0 ? [bot1, bot2] : [bot2, bot1]
          );
          // TODO is this delay necessary?
          await sleep(Math.max(0, 500 - (performance.now() - start)));
          const totalTime = performance.now() - start;

          if (
            gameOutcome === null ||
            progressRef.current.updateId !== tournamentUpdateId
          ) {
            return;
          }

          setProgress((progress) => {
            if (progress.updateId !== tournamentUpdateId) {
              return progress;
            }

            const newMatchups = [...progress.matchups];
            const games = [...(newMatchups[matchupIndex]?.games ?? [])];
            const bot1PlayerIndex = gameIndex % 2 === 0 ? 0 : 1;
            const bot2PlayerIndex = gameIndex % 2 === 1 ? 0 : 1;
            games[gameIndex] = getTournamentGameOutcome(
              gameIndex % 2 === 0 ? "bot1" : "bot2",
              gameOutcome.botOutcomes[bot1PlayerIndex]!.outcome,
              gameOutcome.botOutcomes[bot2PlayerIndex]!.outcome,
              gameOutcome.botOutcomes[bot1PlayerIndex]!.time,
              gameOutcome.botOutcomes[bot2PlayerIndex]!.time,
              totalTime
            );
            newMatchups[matchupIndex] = {
              ...matchup,
              games,
            };

            return {
              ...progress,
              matchups: newMatchups,
            };
          });
        }

        setProgress((progress) => {
          if (progress.updateId !== tournamentUpdateId) {
            return progress;
          }

          return {
            ...progress,
            statsBySlot: getTournamentStats(progress),
          };
        });
      }

      setProgress((progress) => {
        if (progress.updateId !== tournamentUpdateId) {
          return progress;
        }

        const slotIndexMap = new Map<number, number>();

        const sortedSlots = [...progress.slots]
          .map((slot, slotIndex) => {
            return { slot, slotIndex };
          })
          .sort((a, b) => {
            return (
              (progress.statsBySlot[a.slotIndex]?.rank ??
                Number.MAX_SAFE_INTEGER) -
              (progress.statsBySlot[b.slotIndex]?.rank ??
                Number.MAX_SAFE_INTEGER)
            );
          })
          .map((item, newIndex) => {
            slotIndexMap.set(item.slotIndex, newIndex);
            return item.slot;
          });

        return {
          updateId: progress.updateId,
          matchups: progress.matchups.map((matchup): TournamentMatchup => {
            return {
              games: matchup.games,
              bot1SlotIndex: slotIndexMap.get(matchup.bot1SlotIndex) ?? -1,
              bot2SlotIndex: slotIndexMap.get(matchup.bot2SlotIndex) ?? -1,
            };
          }),
          state: "done",
          slots: sortedSlots,
          statsBySlot: [...progress.statsBySlot].sort((a, b) => {
            return a.rank - b.rank;
          }),
        };
      });
    },
  };
}

type WinReason =
  | "victory"
  | "forfeit-bug"
  | "forfeit-time"
  | "forfeit-connection"
  | "forfeit-other"
  | "time"
  | "coin-flip";

export interface TournamentGameOutcome {
  p1: "bot1" | "bot2";
  p2: "bot1" | "bot2";
  winner: "bot1" | "bot2";
  loser: "bot1" | "bot2";
  bot1Time: number;
  bot2Time: number;
  winReason: WinReason;
  bot1Outcome: BotOutcome;
  bot2Outcome: BotOutcome;
  totalTime: number;
}

export interface TournamentMatchup {
  bot1SlotIndex: number;
  bot2SlotIndex: number;
  games: (TournamentGameOutcome | "not-started" | "in-progress")[];
}

export interface TournamentProgress {
  updateId: number;
  state: "not-started" | "in-progress" | "done";
  slots: NonNullable<TransitionSlotSelection>[];
  statsBySlot: SlotStats[];
  matchups: TournamentMatchup[];
}

function createMatchups(
  slotCount: number,
  gamesPerMatchup: number
): TournamentMatchup[] {
  const matchups: TournamentMatchup[] = [];

  for (let bot1SlotIndex = 0; bot1SlotIndex < slotCount; bot1SlotIndex++) {
    for (
      let bot2SlotIndex = bot1SlotIndex + 1;
      bot2SlotIndex < slotCount;
      bot2SlotIndex++
    ) {
      matchups.push({
        bot1SlotIndex,
        bot2SlotIndex,
        games: times(gamesPerMatchup).map(() => "not-started"),
      });
    }
  }

  return shuffle(matchups);
}

function getTournamentGameOutcome(
  p1: "bot1" | "bot2",
  bot1Outcome: BotOutcome,
  bot2Outcome: BotOutcome,
  bot1Time: number,
  bot2Time: number,
  totalTime: number
): TournamentGameOutcome {
  let winner: "bot1" | "bot2" = "bot1";
  let winReason: WinReason;
  const winOrder = [
    BotOutcome.Victory,
    BotOutcome.Draw,
    BotOutcome.TimeLimitExceeded,
    BotOutcome.None,
    BotOutcome.GameError,
    BotOutcome.GameLocked,
    BotOutcome.Defeat,
    BotOutcome.ConnectionProblem,
    BotOutcome.Unimplemented,
    BotOutcome.Error,
  ];

  const forfeitOutcomes = new Set<BotOutcome>([
    BotOutcome.TimeLimitExceeded,
    BotOutcome.GameError,
    BotOutcome.GameLocked,
    BotOutcome.ConnectionProblem,
    BotOutcome.Unimplemented,
    BotOutcome.Error,
  ]);

  if (bot1Outcome === bot2Outcome) {
    if (bot1Time < bot2Time) {
      winner = "bot1";
      winReason = "time";
    } else if (bot2Time < bot1Time) {
      winner = "bot2";
      winReason = "time";
    } else {
      winner = Math.random() < 0.5 ? "bot1" : "bot2";
      winReason = "coin-flip";
    }
  } else {
    if (forfeitOutcomes.has(bot1Outcome) || forfeitOutcomes.has(bot2Outcome)) {
      if (
        bot1Outcome === BotOutcome.Error ||
        bot2Outcome === BotOutcome.Error
      ) {
        winReason = "forfeit-bug";
      } else if (
        bot1Outcome === BotOutcome.ConnectionProblem ||
        bot2Outcome === BotOutcome.ConnectionProblem
      ) {
        winReason = "forfeit-connection";
      } else if (
        bot1Outcome === BotOutcome.TimeLimitExceeded ||
        bot2Outcome === BotOutcome.TimeLimitExceeded
      ) {
        winReason = "forfeit-time";
      } else {
        winReason = "forfeit-other";
      }
    } else {
      winReason = "victory";
    }

    if (winOrder.indexOf(bot1Outcome) < winOrder.indexOf(bot2Outcome)) {
      winner = "bot1";
    } else {
      winner = "bot2";
    }
  }

  return {
    p1,
    p2: p1 === "bot1" ? "bot2" : "bot1",
    winner,
    loser: winner === "bot1" ? "bot2" : "bot1",
    winReason,
    bot1Outcome,
    bot2Outcome,
    bot1Time,
    bot2Time,
    totalTime,
  };
}

export interface SlotStats {
  rank: number;
  gamesPlayed: number;
  gamesWon: number;
  computeTimeWins: number;
  outrightWins: number;
  coinFlipWins: number;
  computeTime: number;
}

function getTournamentStats(progress: TournamentProgress): SlotStats[] {
  const gamesPlayedByBot = new Map<number, number>();
  const computeTimeWinsByBot = new Map<number, number>();
  const coinFlipWinsByBot = new Map<number, number>();
  const computeTimeByBot = new Map<number, number>();
  const outrightWinsByBot = new Map<number, number>();

  for (const matchup of progress.matchups) {
    for (const game of matchup.games) {
      if (game === "in-progress" || game === "not-started") {
        continue;
      }

      for (const bot of [1, 2] as const) {
        const slotIndex = matchup[`bot${bot}SlotIndex`];

        const gamesPlayed = gamesPlayedByBot.get(slotIndex) ?? 0;
        gamesPlayedByBot.set(slotIndex, gamesPlayed + 1);

        const computeTime = computeTimeByBot.get(slotIndex) ?? 0;
        computeTimeByBot.set(slotIndex, computeTime + game[`bot${bot}Time`]);

        if (game.winner !== `bot${bot}`) {
          continue;
        }

        if (game.winReason === "time") {
          const timeWins = computeTimeWinsByBot.get(slotIndex) ?? 0;
          computeTimeWinsByBot.set(slotIndex, timeWins + 1);
        } else if (game.winReason === "coin-flip") {
          const coinFlipWins = coinFlipWinsByBot.get(slotIndex) ?? 0;
          coinFlipWinsByBot.set(slotIndex, coinFlipWins + 1);
        } else {
          const outrightWins = outrightWinsByBot.get(slotIndex) ?? 0;
          outrightWinsByBot.set(slotIndex, outrightWins + 1);
        }
      }
    }
  }

  return progress.slots
    .map((_, slotIndex) => {
      return {
        computeTime: computeTimeByBot.get(slotIndex) ?? 0,
        gamesPlayed: gamesPlayedByBot.get(slotIndex) ?? 0,
        coinFlipWins: coinFlipWinsByBot.get(slotIndex) ?? 0,
        outrightWins: outrightWinsByBot.get(slotIndex) ?? 0,
        computeTimeWins: computeTimeWinsByBot.get(slotIndex) ?? 0,
        slotIndex,
      };
    })
    .sort((a, b) => {
      // Ranking
      // - If no games played preserve existing sort order
      // - If only one bot has played games that one is ranked first
      // - Then one with the highest win percentage (excluding coin flip wins)
      // - If win percentage is tied bot with lowest total compute time
      // - If compute time is tied then coin flip wins are taken into account
      // - If coin flips wins are tied preserve existing sort order
      if (b.gamesPlayed === 0 && a.gamesPlayed === 0) {
        return 0;
      } else if (b.gamesPlayed === 0) {
        return -1;
      } else if (a.gamesPlayed === 0) {
        return 1;
      }

      const aWinPercentage =
        (b.outrightWins + b.computeTimeWins) / (b.gamesPlayed || 1);
      const bWinPercentage =
        (a.outrightWins + a.computeTimeWins) / (a.gamesPlayed || 1);

      if (aWinPercentage === bWinPercentage) {
        if (b.computeTime === a.computeTime) {
          return b.coinFlipWins - a.coinFlipWins;
        } else {
          return b.computeTime - a.computeTime;
        }
      } else {
        return aWinPercentage - bWinPercentage;
      }
    })
    .map((value, rank) => {
      const stats: SlotStats = {
        gamesPlayed: value.gamesPlayed,
        computeTime: value.computeTime,
        coinFlipWins: value.coinFlipWins,
        computeTimeWins: value.computeTimeWins,
        outrightWins: value.outrightWins,
        gamesWon:
          value.coinFlipWins + value.computeTimeWins + value.outrightWins,
        rank,
      };

      return { stats, rank, slotIndex: value.slotIndex };
    })
    .sort((a, b) => a.slotIndex - b.slotIndex)
    .map((value) => value.stats);
}
