import { Colors } from "@blueprintjs/core";
import { BotColor } from "@zilch/bot-models";
import { classes } from "@zilch/css-utils";
import type { GameConfig } from "@zilch/game-config";
import { useEffect, useState } from "react";
import css from "./SlotLabel.module.css";

interface Props {
  label: string;
  active: boolean;
  gameOver: boolean;
  color: BotColor | null;
  gameConfig: GameConfig;
}

export function SlotLabel(props: Props) {
  const [active, setActive] = useState(props.active);

  useEffect(() => {
    if (props.gameOver) {
      setActive(false);
    } else if (props.active) {
      setActive(true);
    } else {
      const timeoutId = setTimeout(() => {
        setActive(false);
      }, props.gameConfig.moveDelay);
      return () => clearTimeout(timeoutId);
    }
    return;
  }, [props.active, props.gameOver, props.gameConfig.moveDelay]);

  return (
    <div className={classes(css.container, !active && "bp4-text-muted")}>
      <div
        className={css.indicatorContainer}
        style={{
          width: `${props.gameOver ? 0 : 12}px`,
          transform: `scale(${props.gameOver ? 0 : 1})`,
          opacity: props.gameOver ? 0 : 1,
        }}
      >
        <div
          style={{
            background: props.color ? BotColor[props.color] : Colors.DARK_GRAY5,
          }}
          className={classes(css.indicator, active && css.indicatorBlinking)}
        />
      </div>
      <div>{props.label}</div>
    </div>
  );
}
