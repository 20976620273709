import { Colors, Icon } from "@blueprintjs/core";
import React from "react";
import css from "./VideoLink.module.css";
import { classes } from "@zilch/css-utils";

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  thumbnailSrc: string;
  videoLength: string;
  maxWidth?: string;
  aspectRatio?: number;
}

export function VideoLink({
  thumbnailSrc,
  videoLength,
  maxWidth,
  aspectRatio,
  ...props
}: Props) {
  return (
    <a
      tabIndex={0}
      {...props}
      className={classes(css.video, props.className)}
      style={{
        ...props.style,
        backgroundImage: `url("${thumbnailSrc}")`,
        paddingTop: (aspectRatio ?? 9 / 16) * 100 + "%",
        maxWidth: maxWidth,
      }}
    >
      <div className={css.playButtonContainer}>
        <Icon
          icon="play"
          size={30}
          className={css.playButton}
          color={Colors.WHITE}
        />
      </div>
      <div className={css.time}>{videoLength}</div>
    </a>
  );
}
