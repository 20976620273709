import { Button, Colors, Icon } from "@blueprintjs/core";
import type { BotOutcome } from "@zilch/bot-models";
import {
  BotColor,
  BotType,
  type GameBotConfig,
  type SlotSelection,
  zilchBotAvatars,
  zilchBotName,
  zilchBotPreferredColor,
} from "@zilch/bot-models";
import { capitalize, sample } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Popover } from "../common/Popover";
import { SlotLabel } from "./SlotLabel";
import css from "./Slot.module.css";
import { classes } from "@zilch/css-utils";
import { Tooltip2 } from "@blueprintjs/popover2";
import { useUserBotConfigListQuery } from "./useUserBotConfigListQuery";
import type { GameConfig, GameId } from "@zilch/game-config";
import { useDelayedValue } from "@zilch/delay";
import { BotOutcomeDisplay } from "./BotOutcomeDisplay";

interface Props {
  label: string;
  showSwapButton: boolean;
  onOpenBotSelector(): void;
  slotSelection: SlotSelection;
  isPrimaryOutcome: boolean;
  scrollIntoView: boolean;
  botOutcome: BotOutcome | null;
  message: React.ReactNode;
  onCloseMessage(): void;
  onSwap(): void;
  onRemove(): void;
  onChangeColor(botColor: BotColor): void;
  showSlotActions: boolean;
  visible: boolean;
  gameId: GameId;
  isSpotToRightVacant: boolean;
  active: boolean;
  gameOver: boolean;
  gameConfig: GameConfig;
  color: BotColor | null;
}

export function Slot(props: Props) {
  const botConfigListQuery = useUserBotConfigListQuery(
    props.slotSelection?.type === BotType.User
      ? props.slotSelection.owner
      : null,
    props.gameId
  );
  const containerRef = useRef<HTMLDivElement | null>(null);

  let gameBot: GameBotConfig | null = null;
  if (
    props.slotSelection?.type === BotType.Boss ||
    props.slotSelection?.type === BotType.Practice
  ) {
    gameBot = {
      avatar: zilchBotAvatars[props.slotSelection.type],
      game: props.gameId,
      language: null,
      name: zilchBotName[props.slotSelection.type],
      preferredColor: zilchBotPreferredColor[props.slotSelection.type],
      type: props.slotSelection.type,
      v: 1,
      run: "",
    };
  }

  const hasBot =
    !!gameBot ||
    (props.slotSelection &&
      botConfigListQuery.isSuccess &&
      botConfigListQuery.data !== "nonexistent-user" &&
      !!botConfigListQuery.data.bySlot(props.slotSelection)) ||
    (botConfigListQuery.isLoading && !!props.slotSelection);

  const hasError =
    !gameBot &&
    (botConfigListQuery.isError ||
      botConfigListQuery.data === "nonexistent-user" ||
      (botConfigListQuery.isSuccess &&
        props.slotSelection &&
        !botConfigListQuery.data.bySlot(props.slotSelection)));

  const mounted =
    useDelayedValue(props.visible, { delay: 300 }) || props.visible;
  const delayedVisible = useDelayedValue(props.visible, { delay: 1 });

  useEffect(() => {
    if (props.scrollIntoView) {
      setTimeout(() => {
        containerRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }, 300);
    }
  }, [props.scrollIntoView]);

  const delayedMessage =
    useDelayedValue(props.message, { delay: 400 }) ?? props.message;

  if (!mounted) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className={classes(css.container, !delayedVisible && css.containerHidden)}
    >
      <div className={css.slotHeader}>
        <SlotLabel
          active={props.active}
          label={props.label}
          color={props.color}
          gameOver={props.gameOver}
          gameConfig={props.gameConfig}
        />
      </div>
      <div className={css.slotButtonContainer}>
        <Popover
          onClose={() => {
            if (props.message !== null) {
              props.onCloseMessage();
            }
          }}
          isOpen={props.message !== null}
          position="top"
          content={<>{delayedMessage}</>}
          background="blue"
        >
          <div className={css.slotButtonBackground}>
            <Button
              className={classes(
                css.slotButton,
                hasBot && css.slotButtonHasBot
              )}
              minimal
              icon={hasBot || hasError ? "blank" : "plus"}
              disabled={hasBot}
              loading={!gameBot && hasBot && botConfigListQuery.isLoading}
              onClick={props.onOpenBotSelector}
            />
          </div>
        </Popover>
        <div
          className={css.swapButtonContainer}
          style={{
            width: props.showSwapButton ? 40 : 20,
          }}
        >
          <Button
            icon="arrows-horizontal"
            className={classes(
              css.swapButton,
              !props.showSwapButton && css.swapButtonHidden
            )}
            disabled={!props.showSwapButton}
            tabIndex={props.showSwapButton ? 0 : -1}
            minimal
            onClick={props.onSwap}
          />
        </div>
      </div>
      <BotOutcomeDisplay
        isPrimaryOutcome={props.isPrimaryOutcome}
        type="text"
        botOutcome={props.botOutcome}
      />
      <div
        className={css.slotActions}
        style={{
          opacity: props.showSlotActions
            ? hasBot ||
              botConfigListQuery.isError ||
              botConfigListQuery.data === "nonexistent-user" ||
              (botConfigListQuery.isSuccess &&
                props.slotSelection &&
                !botConfigListQuery.data.bySlot(props.slotSelection))
              ? 1
              : 0.4
            : 0,
          height: props.showSlotActions && delayedVisible ? 32 : 0,
          marginTop: props.showSlotActions && delayedVisible ? 10 : 0,
          pointerEvents:
            (hasBot || hasError) && props.showSlotActions ? undefined : "none",
        }}
      >
        <ColorPicker
          color={hasError ? undefined : props.slotSelection?.color}
          disabled={!hasBot || !props.showSlotActions}
          onChangeColor={(color) => {
            props.onChangeColor(color);
          }}
        />
        <Button
          minimal
          disabled={!hasError && (!hasBot || !props.showSlotActions)}
          icon="swap-vertical"
          onClick={props.onOpenBotSelector}
        />
        <Button
          minimal
          disabled={!hasError && (!hasBot || !props.showSlotActions)}
          icon="cross"
          onClick={props.onRemove}
        />
      </div>
    </div>
  );
}

function ColorPicker({
  color,
  disabled,
  onChangeColor,
}: {
  color?: BotColor;
  disabled: boolean;
  onChangeColor(color: BotColor): void;
}) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled, open]);
  return (
    <Popover
      position="top"
      isOpen={disabled ? false : open}
      onInteraction={(open) => setOpen(open)}
      content={
        <div className={css.colorOptionsContainer}>
          {(Object.keys(BotColor) as BotColor[]).map((botColor) => {
            return (
              <Tooltip2
                key={botColor}
                content={capitalize(botColor)}
                hoverOpenDelay={500}
                isOpen={disabled || !open ? false : undefined}
                placement="top"
              >
                <button
                  className={css.colorButton}
                  style={{
                    background: BotColor[botColor],
                  }}
                  onClick={() => {
                    if (color !== botColor) {
                      onChangeColor(botColor);
                    }
                    setOpen(false);
                  }}
                >
                  {color === botColor && <Icon icon="tick" size={20} />}
                </button>
              </Tooltip2>
            );
          })}
          <Tooltip2 hoverOpenDelay={500} placement="top" content="Random">
            <button
              className={css.colorButton}
              style={{ background: Colors.DARK_GRAY2 }}
              onClick={() => {
                const colors = new Set(Object.keys(BotColor) as BotColor[]);
                if (color) {
                  colors.delete(color);
                }
                const newColor = sample(Array.from(colors));
                if (newColor) {
                  onChangeColor(newColor);
                }
                setOpen(false);
              }}
            >
              <Icon icon="random" />
            </button>
          </Tooltip2>
        </div>
      }
    >
      <Button
        minimal
        fill
        disabled={disabled}
        icon={<Icon icon="tint" color={color ? BotColor[color] : undefined} />}
      />
    </Popover>
  );
}
