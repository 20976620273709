import { Button, H3, Spinner, Tag } from "@blueprintjs/core";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { plural } from "@zilch/plural";
import { useState } from "react";
import { api } from "../../api";
import { Dialog } from "../common/Dialog";
import { NonIdealState } from "../common/NonIdealState";
import { useOperation } from "../common/useOperation";
import { AddUsersButton } from "./AddUsersButton";
import css from "./ManageAdminsButton.module.css";

export function ManageAdminsButton({ viewerUserId }: { viewerUserId: string }) {
  const [open, setOpen] = useState(false);
  const adminsQuery = useQuery(["api.admin.getAdmins"], ({ signal }) => {
    return api.admin.getAdmins.query(void 0, { signal });
  });

  return (
    <div>
      <p className="bp4-text-muted">
        Admins can add/remove other other admins, impersonate other users, and
        manage organization plans.
      </p>
      <Button onClick={() => setOpen(true)}>Manage Admin Users</Button>
      <Dialog isOpen={open} onClose={() => setOpen(false)} width={480}>
        <div className={css.header}>
          <H3 className={css.title}>Zilch Admins</H3>
          <div className={css.actions}>
            <Button
              minimal
              icon="people"
              tabIndex={-1}
              style={{ pointerEvents: "none" }}
            >
              <span className="bp4-text-muted">
                {plural((adminsQuery.data ?? []).length, "User")}
              </span>
            </Button>
            <AddUsersButton
              label="Add Admins"
              onAdd={async (logins) => {
                const result = await api.admin.addAdmins.mutate(logins);

                await adminsQuery.refetch();

                if (result.invalidLogins.length > 0) {
                  return {
                    success: false,
                    error: `Unable to add the following invalid username(s): ${result.invalidLogins.join(
                      ", "
                    )}`,
                  };
                }

                return { success: true };
              }}
            />
          </div>
        </div>
        <AdminList adminsQuery={adminsQuery} viewerUserId={viewerUserId} />
      </Dialog>
    </div>
  );
}

function AdminList({
  adminsQuery,
  viewerUserId,
}: {
  adminsQuery: UseQueryResult<{ userId: string; login: string | null }[]>;
  viewerUserId: string;
}) {
  return (
    <div className={css.content}>
      {adminsQuery.isSuccess &&
        adminsQuery.data.map((user) => {
          return (
            <AdminListRow
              key={user.userId}
              user={user}
              viewerUserId={viewerUserId}
              onRemove={async () => {
                await adminsQuery.refetch();
              }}
            />
          );
        })}
      {adminsQuery.isLoading && <NonIdealState icon={<Spinner />} />}
      {adminsQuery.isError && (
        <NonIdealState description="Unexpected problem encountered." />
      )}
    </div>
  );
}

function AdminListRow({
  user,
  onRemove,
  viewerUserId,
}: {
  user: { login: string | null; userId: string };
  onRemove(): Promise<void>;
  viewerUserId: string;
}) {
  const removeAdminOperation = useOperation({
    run: async () => {
      await api.admin.removeAdmin.mutate({
        userId: user.userId,
      });
      await onRemove();
    },
  });

  return (
    <div className={css.row}>
      <div>
        <a
          rel="noreferrer"
          target="_blank"
          href={`https://www.github.com/${user.login ?? user.userId}`}
        >
          {user.login ?? user.userId}
        </a>
        {viewerUserId === user.userId && (
          <Tag style={{ marginLeft: "5px" }} minimal>
            You
          </Tag>
        )}
      </div>
      <Button
        icon="remove"
        minimal
        disabled={viewerUserId === user.userId}
        onClick={removeAdminOperation.run}
        loading={removeAdminOperation.inProgress}
      />
    </div>
  );
}
