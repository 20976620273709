@keyframes BotAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.disabled {
  pointer-events: none;
}

.container {
  height: 100%;
  background: linear-gradient(var(--dark-gray3), var(--black));
}

.content {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 14px;
  height: 100%;
  position: relative;
  z-index: 1;
  background: radial-gradient(var(--black), transparent),
    linear-gradient(
      to right,
      rgba(17, 20, 24, 0) min(0%, calc(50% - 600px)),
      var(--black) calc(50% - 30px),
      var(--black) calc(50% + 30px),
      rgba(17, 20, 24, 0) max(100%, calc(50% + 600px))
    );
}

.contentInner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.background {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  overflow: hidden;
  opacity: 0.5;
}

.botSvg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: 0.6s ease BotAnimation both;
}
