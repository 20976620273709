import {
  type BotAvatarAndColor,
  BotColor,
  BotType,
  type TransitionUserBotSlotSelection,
  type UserBotConfig,
} from "@zilch/bot-models";
import type { GameConfig, GameId } from "@zilch/game-config";
import { times } from "lodash";
import { createContext, useEffect, useMemo, useState } from "react";
import { generateBotAvatarAndColor } from "../../generateBotAvatarAndColor";
import { StepsDialog } from "../common/StepsDialog";
import { BotCreatorLanguagePanel } from "./BotCreatorLanguagePanel";
import css from "./BotCreator.module.css";
import { UserStore } from "../../stores/UserStore";

interface Props {
  gameConfig: GameConfig;
  open: boolean;
  onClose(): void;
  onCreate(slot: TransitionUserBotSlotSelection, language: string): void;
}

export const BotCreationStore = createContext<{
  gameConfig: GameConfig;
  botConfig: Partial<UserBotConfig>;
  creating: boolean;
  onSetCreating(creating: boolean): void;
  onSetBotConfig(
    fn: (botConfig: Partial<UserBotConfig>) => Partial<UserBotConfig>
  ): void;
  onCreate(slot: TransitionUserBotSlotSelection, language: string): void;
  avatarOptions: BotAvatarAndColor[];
  onNewAvatarOptions(): void;
} | null>(null);

export function BotCreator(props: Props) {
  const [botConfig, setBotConfig] = useState(() => {
    return generateStarterBotConfig(props.gameConfig.gameId);
  });

  const userStore = UserStore.use();

  const [creating, setCreating] = useState(false);

  const [avatarOptions, setAvatarOptions] = useState(() =>
    generateAvatarOptions()
  );

  const store = useMemo(() => {
    return {
      botConfig,
      onSetBotConfig: setBotConfig,
      onCreate: props.onCreate,
      avatarOptions,
      gameConfig: props.gameConfig,
      creating,
      onSetCreating: setCreating,
      onNewAvatarOptions() {
        setAvatarOptions(generateAvatarOptions());
      },
    };
  }, [botConfig, props.onCreate, avatarOptions, props.gameConfig, creating]);

  useEffect(() => {
    if (props.open) {
      setAvatarOptions(generateAvatarOptions());
      setCreating(false);
      setBotConfig(generateStarterBotConfig(props.gameConfig.gameId));
    }
  }, [props.open, props.gameConfig.gameId]);

  return (
    <BotCreationStore.Provider value={store}>
      <StepsDialog
        closeDisabled={creating || userStore.signingIn !== false}
        portalClassName={css.portal}
        open={props.open}
        onClose={props.onClose}
        initialPanel={{
          renderPanel: (props) => <BotCreatorLanguagePanel {...props} />,
        }}
      />
    </BotCreationStore.Provider>
  );
}

function generateStarterBotConfig(gameId: GameId): Partial<UserBotConfig> {
  return {
    type: BotType.User,
    game: gameId,
  };
}

function generateAvatarOptions() {
  const availableColors = new Set(Object.keys(BotColor) as BotColor[]);
  return times(3).map(() => {
    const option = generateBotAvatarAndColor(Array.from(availableColors));
    availableColors.delete(option.preferredColor);
    return option;
  });
}
