import { Button, Callout, Colors, Drawer, H3, Icon } from "@blueprintjs/core";
import type {
  BotConfig,
  GameBotConfig,
  SlotSelection,
} from "@zilch/bot-models";
import { BotColor } from "@zilch/bot-models";
import {
  BotType,
  type TransitionSlotSelection,
  zilchBotAvatars,
  zilchBotName,
  zilchBotPreferredColor,
} from "@zilch/bot-models";
import React, { useEffect, useMemo, useState } from "react";
import { BotCreator } from "./BotCreator";
import { BotCard } from "./BotCard";
import { SharedBotSelector } from "./SharedBotSelector";
import { Tooltip2 } from "@blueprintjs/popover2";
import { createSlotSelection } from "./createSlotSelection";
import css from "./BotSelector.module.css";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import { useUserBotConfigListQuery } from "./useUserBotConfigListQuery";
import { UserStore } from "../../stores/UserStore";
import type { GameConfig } from "@zilch/game-config";
import { Popover } from "../common/Popover";
import { useDelayedValue } from "@zilch/delay";
import { GuideContent } from "./GuideContent";
import { NewBotStore } from "../../stores/NewBotStore";
import { useWindowSizeDerivedValue } from "@zilch/window-size";
import { posthog } from "posthog-js";
import { getGameIdForAnalytics } from "../../analytics";

interface Props {
  open: boolean;
  slotLabel: React.ReactNode | ((small: boolean) => React.ReactNode);
  onClose(): void;
  gameConfig: GameConfig;
  selectedSlot?: SlotSelection;
  onSetSlotSelection(
    slotSelection: TransitionSlotSelection,
    selected: boolean
  ): void;
  showMultiplayerGuide?: boolean;
  onCloseMultiplayerGuide?(): void;
  forceColor?: BotColor;
  topContent?: React.ReactNode;
  createBot:
    | false
    | {
        numSelected: number;
        getNewBotTransitionOrigin(): { x: number; y: number };
      };
}

export function BotSelector(props: Props) {
  const userStore = UserStore.use();
  const newBotStore = NewBotStore.use();
  const [mode, setMode] = useState<"select" | "create">("select");
  const botConfigListQuery = useUserBotConfigListQuery(
    userStore.query.data?.type === "authenticated"
      ? userStore.query.data.likelyLogin
      : null,
    props.gameConfig.gameId
  );

  useEffect(() => {
    if (!props.open) {
      setMode("select");
    }
  }, [props.open]);

  const gameBots = useMemo(() => {
    return [BotType.Boss, BotType.Practice].map(
      (type): GameBotConfig => ({
        avatar: zilchBotAvatars[type],
        game: props.gameConfig.gameId,
        language: null,
        name: zilchBotName[type],
        preferredColor: zilchBotPreferredColor[type],
        type,
        v: 1,
        run: "",
      })
    );
  }, [props.gameConfig.gameId]);

  const authenticated =
    userStore.query.isSuccess && userStore.query.data.type === "authenticated";

  const noUserBots =
    botConfigListQuery.data !== "nonexistent-user" &&
    (botConfigListQuery.data?.list.length ?? 0) === 0;

  const isOpen = props.open && mode === "select";

  const showGuidePopup = useDelayedValue(isOpen, { delay: 400 }) && isOpen;
  const [hasClosedGuidePopup, setHasClosedGuidePopup] = useState(false);

  useEffect(() => {
    setHasClosedGuidePopup(false);
  }, [isOpen]);

  const showSelectDrawer = useDelayedValue(isOpen, { delay: 20 }) || isOpen;

  let newBotButton = (
    <Button
      icon="plus"
      minimal
      onClick={() => setMode("create")}
      className={classes(css.newBotListButtonCss, transitionInFromCss.bottom)}
    >
      New Bot
    </Button>
  );

  if (
    noUserBots &&
    props.createBot &&
    props.createBot.numSelected === 1 &&
    !props.showMultiplayerGuide
  ) {
    newBotButton = (
      <Popover
        isOpen={showGuidePopup && !hasClosedGuidePopup}
        background="blue"
        onClose={() => setHasClosedGuidePopup(true)}
        placement="top"
        content={
          <GuideContent
            title={
              <>Time to create your first {props.gameConfig.name}&nbsp;bot!</>
            }
            message="You're just a few clicks away from writing code."
          />
        }
      >
        {newBotButton}
      </Popover>
    );
  }

  const small = useWindowSizeDerivedValue((width) => width < 560);
  const [showMultiplayer, setShowMultiplayer] = useState(false);

  const isDrawerOpen =
    useDelayedValue(showSelectDrawer, { delay: 10 }) || showSelectDrawer;

  useEffect(() => {
    setShowMultiplayer(false);
  }, [small, isDrawerOpen]);

  return (
    <>
      <Drawer
        position="bottom"
        onClose={props.onClose}
        size={380}
        className={css.drawerCss}
        isOpen={isDrawerOpen}
        portalClassName={css.drawerPortalCss}
        style={{
          gridTemplateColumns: small ? "1fr" : "1fr 271px",
        }}
      >
        {props.topContent && (
          <div
            className={css.topContent}
            style={{
              transform: `translateY(${showSelectDrawer ? 0 : 200}%)`,
              opacity: showSelectDrawer ? 1 : 0,
            }}
          >
            {props.topContent}
          </div>
        )}
        {(!showMultiplayer || !small) && (
          <div className={css.containerCss}>
            <div className={css.headerCss}>
              <div style={{ flexGrow: 1 }}>
                <H3 className={css.titleCss}>
                  {typeof props.slotLabel === "function"
                    ? props.slotLabel(small)
                    : props.slotLabel}
                </H3>
                <div
                  className="bp4-text-muted"
                  style={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                  }}
                >
                  Select bot
                </div>
              </div>
              <div style={{ whiteSpace: "nowrap" }}>
                {props.createBot && (
                  <Tooltip2
                    position="top"
                    content="New Bot"
                    isOpen={botConfigListQuery.isLoading ? false : undefined}
                  >
                    <div className={css.headerButtonContainer}>
                      <Button
                        className={css.newBotHeaderButtonCss}
                        large
                        minimal
                        icon="plus"
                        loading={botConfigListQuery.isLoading}
                        onClick={() => setMode("create")}
                      />
                    </div>
                  </Tooltip2>
                )}
                {small && (
                  <Tooltip2
                    position="top"
                    isOpen={botConfigListQuery.isLoading ? false : undefined}
                    content="Multiplayer"
                  >
                    <div className={css.headerButtonContainer}>
                      <Button
                        large
                        minimal
                        icon="people"
                        style={{ borderRadius: "100%" }}
                        onClick={() => setShowMultiplayer(true)}
                      />
                    </div>
                  </Tooltip2>
                )}
              </div>
            </div>
            <div className={css.resultAreaCss}>
              {botConfigListQuery.isError && (
                <Callout intent="warning">
                  Unexpected problem encountered loading some bots.
                </Callout>
              )}
              {botConfigListQuery.data !== "nonexistent-user" &&
                [...gameBots, ...(botConfigListQuery.data?.list ?? [])].map(
                  (botConfig, index) => {
                    const showPracticeBotGuideMessage =
                      !props.gameConfig.dev &&
                      noUserBots &&
                      props.createBot &&
                      props.createBot.numSelected === 0 &&
                      botConfig.type === BotType.Practice &&
                      !props.showMultiplayerGuide;

                    const selected = isSelected(botConfig, props.selectedSlot);

                    const botCard = (
                      <BotCard
                        gameId={props.gameConfig.gameId}
                        color={props.forceColor}
                        isSelected={selected}
                        botConfig={{
                          transition: false,
                          slotSelection: null,
                          value: botConfig,
                        }}
                        onSelect={(transitionOrigin, bossDifficulty) => {
                          props.onSetSlotSelection(
                            createSlotSelection(
                              botConfig,
                              transitionOrigin ?? undefined,
                              bossDifficulty ?? undefined
                            ),
                            !selected
                          );
                        }}
                      />
                    );

                    let content = botCard;

                    if (showPracticeBotGuideMessage) {
                      content = (
                        <Popover
                          isOpen={showGuidePopup && !hasClosedGuidePopup}
                          background="blue"
                          placement="top"
                          onClose={() => setHasClosedGuidePopup(true)}
                          content={
                            <GuideContent
                              title="Practice Bot says..."
                              message={
                                <>
                                  Hello
                                  {userStore.query.data?.type ===
                                  "authenticated" ? (
                                    <span style={{ fontWeight: 600 }}>
                                      {" "}
                                      {userStore.query.data.likelyLogin}!
                                    </span>
                                  ) : (
                                    "!"
                                  )}{" "}
                                  Pick me. I'll help you learn to play.
                                </>
                              }
                            />
                          }
                        >
                          {botCard}
                        </Popover>
                      );
                    }

                    return (
                      <div
                        key={
                          props.forceColor
                            ? `${index}-${props.forceColor}`
                            : index
                        }
                        className={transitionInFromCss.bottom}
                        style={{
                          animationDelay: 200 + index * 50 + "ms",
                          position: "relative",
                        }}
                      >
                        <SelectedBotIndicator
                          selected={selected}
                          color={props.forceColor ?? "vermilion"}
                        />
                        {content}
                      </div>
                    );
                  }
                )}
              {!botConfigListQuery.isLoading && props.createBot && newBotButton}
              {!authenticated && botConfigListQuery.isSuccess && (
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                      width: "100%",
                      padding: "6px 2px",
                      borderTop: `1px solid ${Colors.DARK_GRAY4}`,
                      maxWidth: `${230 * 3 + 30}px`,
                    }}
                    className="bp4-text-large"
                  >
                    <div>Don't see your bots?</div>
                    <Button
                      minimal
                      disabled={
                        userStore.signingIn &&
                        userStore.signingIn !== "botSelector"
                      }
                      loading={userStore.signingIn === "botSelector"}
                      onClick={() => {
                        userStore.signIn("botSelector");
                      }}
                      intent="primary"
                    >
                      <span style={{ fontSize: "16px" }}>Sign in</span>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {(showMultiplayer || !small) && (
          <SharedBotSelector
            small={small}
            onBackToAllBotsView={() => setShowMultiplayer(false)}
            forceColor={props.forceColor}
            selectedSlot={props.selectedSlot}
            showMultiplayerGuide={
              !!props.showMultiplayerGuide &&
              showGuidePopup &&
              !hasClosedGuidePopup
            }
            onCloseMultiplayerGuide={() => {
              setHasClosedGuidePopup(true);
              props.onCloseMultiplayerGuide?.();
            }}
            gameConfig={props.gameConfig}
            onSelect={(botConfig, transitionOrigin) => {
              props.onSetSlotSelection(
                createSlotSelection(botConfig, transitionOrigin),
                !isSelected(botConfig, props.selectedSlot)
              );
            }}
          />
        )}
      </Drawer>
      <BotCreator
        open={props.open && mode === "create"}
        onClose={props.onClose}
        gameConfig={props.gameConfig}
        onCreate={(slot, language) => {
          posthog.capture("bot_created", {
            gameId: getGameIdForAnalytics(props.gameConfig.gameId),
            language,
          });
          botConfigListQuery.refetch();
          newBotStore.setBotNewValue(slot.owner, slot.repo, true);
          props.onSetSlotSelection(
            {
              ...slot,
              transitionData: {
                ...slot.transitionData,
                transitionOrigin: props.createBot
                  ? props.createBot.getNewBotTransitionOrigin()
                  : undefined,
              },
            },
            false
          );
        }}
      />
    </>
  );
}

export function isSelected(
  botConfig: BotConfig,
  selectedSlot: SlotSelection | undefined
) {
  return (
    (selectedSlot?.type === "user" &&
      botConfig.type === "user" &&
      selectedSlot.owner === botConfig.owner &&
      selectedSlot.repo === botConfig.repo) ||
    (selectedSlot?.type === "boss" && botConfig.type === "boss") ||
    (selectedSlot?.type === "practice" && botConfig.type === "practice")
  );
}

export function SelectedBotIndicator(props: {
  selected: boolean;
  color: BotColor;
}) {
  return (
    <Icon
      icon="small-tick"
      className={css.selectedBotIndicator}
      style={{
        transform: `scale(${props.selected ? 1 : 0})`,
        background: BotColor[props.color],
      }}
    />
  );
}
