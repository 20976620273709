.gameListItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  place-self: start center;
  gap: 2px;
  border-radius: 10px;
  transition:
    background ease 0.3s,
    box-shadow ease 0.3s,
    transform cubic-bezier(0.5, 2, 0.5, 1) 0.4s;
  cursor: pointer;
  width: 100%;
  padding: 20px;
  position: relative;
  background: transparent;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.gameListItem:hover {
  background: var(--dark-gray4);
  transform: scale(1.1);
  box-shadow: 0 5px 14px -3px rgba(0, 0, 0, 0.4);
  z-index: 3;
}

.gameListItem:active {
  box-shadow: 0 2px 7px -3px rgba(0, 0, 0, 0.5);
  background: #343942; /* mix of dark-gray3 and dark-gray4 */
  transform: scale(1.02);
  z-index: 4;
}

.gameListImageContainer {
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all cubic-bezier(0.5, 2, 0.5, 1) 0.4s;
}

.gameListItem:hover .gameListImageContainer {
  transform: scale(1.5) rotate(4deg);
}

.gameListItem:active .gameListImageContainer {
  transform: scale(1.4) rotate(4deg);
}

.gameListImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: all ease 0.3s;
}

.gameName {
  z-index: 1;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-shadow: 0 0 5px var(--dark-gray2);
  transition: all cubic-bezier(0.5, 2, 0.5, 1) 0.4s;
  width: 100px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.gameListItem:hover .gameName {
  transform: scale(1.1) translateY(5px);
}

.gameListItem:active .gameName {
  transform: scale(1.05) translateY(3px);
}

.gameBy {
  width: 100px;
  font-weight: 600;
  text-shadow: 0 0 5px var(--dark-gray2);
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  z-index: 1;
  transition: all cubic-bezier(0.5, 2, 0.5, 1) 0.4s;
}

.gameListItem:hover .gameBy {
  transform: scale(1.1) translateY(5px);
}

.gameListItem:active .gameBy {
  transform: scale(1.05) translateY(3px);
}

.gameSelectorListContainer {
  display: grid;
  transition: transform cubic-bezier(0.5, 1.2, 0.5, 1) 0.4s;
  align-items: start;
  justify-content: space-between;
  justify-items: center;
  overflow-x: hidden;
  place-items: center center;
  position: absolute;
  padding-left: 24px;
  padding-bottom: 0px;
  margin-top: 1px;
  margin-bottom: 1px;
  height: 100%;
  width: 100%;
}

.gameSelectorListHeader {
  position: sticky;
  grid-column: 1/-1;
  place-self: start center;
  width: calc(100% + 34px);
  margin-left: -11px;
  background: linear-gradient(var(--dark-gray3) 50%, transparent);
  padding: 15px 8px 34px 23px;
  margin-bottom: -30px;
  border-top-left-radius: 2px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  top: 0;
  z-index: 2;
}

.gameByInputForm {
  grid-column: 1/-1;
  place-self: end center;
  background: linear-gradient(transparent, var(--dark-gray3) 45%);
  padding-top: 25px;
  position: sticky;
  bottom: 0px;
  width: calc(100% + 34px);
  margin-left: -11px;
  margin-top: -38px;
  border-bottom-left-radius: 2px;
  z-index: 1;
  transition: opacity 0.3s ease 0.2s;
  pointer-events: none;
}

.formGroupContainer {
  pointer-events: all;
  padding-top: 30px;
  padding-bottom: 22px;
  padding-right: 14px;
  padding-left: 25px;
}

.formGroup {
  margin-bottom: 0px !important;
  width: 100% !important;
  max-width: 470px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.createdBy {
  font-size: 16px;
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gameByContainer {
  transition:
    opacity ease 0.2s,
    transform ease 0.2s;
  position: absolute;
  height: 344px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0 -1px 0 0 #555f6f;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 5;
  background: var(--dark-gray3);
}

.gameByHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
  padding-right: 0px;
}

.gameByBackdrop {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  transition: all ease 0.3s;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
}

.gameByResults {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 12px;
}

.container {
  position: relative;
  overflow: hidden;
  margin: 1px;
  border-radius: 3px;
  transition: height ease 0.4s;
}

.gameMakerView {
  transition:
    opacity ease 0.3s,
    transform cubic-bezier(0.5, 1.2, 0.5, 1) 0.4s;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.gameMakerTitle {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 25px;
  margin-top: -15px;
}

.tooltip {
  display: block !important;
  width: 100%;
}
