@keyframes ClickMeAnimation {
  0%,
  100% {
    transform: translate(0px, 0px) rotate(-45deg) scale(1);
  }
  50% {
    transform: translate(5px, -7px) rotate(-50deg) scale(0.9);
  }
}

.clickMe {
  position: absolute;
  bottom: -48px;
  left: -22px;
  font-size: 55px;
  z-index: 12;
  transition: opacity ease 0.5s;
  pointer-events: none;
  animation: ClickMeAnimation 1.2s ease infinite both;
  filter: drop-shadow(-1px -1px 8px var(--black));
}
