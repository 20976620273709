import { Button, FormGroup, NumericInput } from "@blueprintjs/core";
import { useRef } from "react";

export function AdminSubscriptionPrompt(props: {
  hideDeleteAction?: boolean;
  onResolve(
    result: { action: "delete" } | { action: "setSeatCount"; value: number }
  ): void;
}) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <form
      style={{
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
      onSubmit={(e) => {
        e.preventDefault();
        let seats: number | null = null;

        try {
          seats = parseInt(inputRef.current?.value ?? "");
          if (Number.isNaN(seats)) {
            seats = null;
          }
        } catch {}

        if (seats === null) {
          prompt("Invalid value");
          return;
        }

        props.onResolve({
          action: "setSeatCount",
          value: seats,
        });
      }}
    >
      <FormGroup label="Seats" style={{ marginBottom: "0px" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
          <NumericInput
            fill
            defaultValue={10}
            large
            min={1}
            inputRef={inputRef}
          />
          <Button
            large
            intent="primary"
            type="submit"
            style={{ width: "300px" }}
          >
            {props.hideDeleteAction
              ? "Create Subscription"
              : "Update Subscription"}
          </Button>
        </div>
      </FormGroup>
      {!props.hideDeleteAction && (
        <div>
          <Button
            intent="danger"
            onClick={() => {
              if (confirm("Are you sure?")) {
                props.onResolve({ action: "delete" });
              }
            }}
          >
            Delete Subscription
          </Button>
        </div>
      )}
    </form>
  );
}
