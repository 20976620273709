.container {
  background: var(--dark-gray2);
  border-left: 1px solid var(--dark-gray5);
  height: 380px;
  position: relative;
  overflow: hidden;
  margin-top: 1px;
  margin-right: 1px;
  border-top-right-radius: 10px;
}

.container.small {
  border-left: none;
  margin-left: 1px;
  border-top-left-radius: 10px;
}

.usernamePickerContainer {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: all ease 0.3s;
}

.usernameForm::after {
  content: "";
  position: absolute;
  height: 15px;
  pointer-events: none;
  background: linear-gradient(var(--dark-gray2), transparent);
  z-index: 1;
  left: 0px;
  right: 14px;
}

.resultAreaCss {
  position: absolute;
  transition: all ease 0.3s;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.resultAreaHeaderCss {
  padding: 20px;
  padding-bottom: 0px;
  position: relative;
}

.resultAreaHeaderCss::after {
  content: "";
  position: absolute;
  height: 15px;
  pointer-events: none;
  background: linear-gradient(var(--dark-gray2), transparent);
  z-index: 1;
  left: 0;
  right: 14px;
}
