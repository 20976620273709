@keyframes BlinkingIndicatorAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.container {
  justify-content: space-between;
  padding: 0 3px;
  background: rgba(47, 52, 60, 0.8);
  border-radius: 3px;
  transition: all ease 0.5s;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.indicatorContainer {
  overflow: hidden;
  transition: all ease 0.3s;
  height: 10px;
  position: relative;
  display: flex;
  align-items: center;
}

.indicator {
  width: 6px;
  height: 6px;
  position: absolute;
  border-radius: 50%;
  transition: all ease 0.6s;
}

.indicatorBlinking {
  animation: BlinkingIndicatorAnimation 1s ease infinite both;
}
