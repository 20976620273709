import {
  Button,
  Colors,
  FormGroup,
  Icon,
  InputGroup,
  NonIdealState,
  Spinner,
} from "@blueprintjs/core";
import type { SlotSelection } from "@zilch/bot-models";
import { type BotColor, type UserBotConfig } from "@zilch/bot-models";
import { classes, delayCss, transitionInFromCss } from "@zilch/css-utils";
import { useDelay, useDelayedValue } from "@zilch/delay";
import type { GameConfig } from "@zilch/game-config";
import { useEffect, useRef, useState } from "react";
import { useStorage } from "../../useStorage";
import { PremiumStore } from "../../stores/PremiumStore";
import { UserStore } from "../../stores/UserStore";
import { BotCard } from "./BotCard";
import css from "./SharedBotSelector.module.css";
import { useUserBotConfigListQuery } from "./useUserBotConfigListQuery";
import { Popover } from "../common/Popover";
import { GuideContent } from "./GuideContent";
import { SiGithub } from "react-icons/si";
import { SelectedBotIndicator, isSelected } from "./BotSelector";

export function SharedBotSelector(props: {
  gameConfig: GameConfig;
  small: boolean;
  forceColor?: BotColor;
  selectedSlot?: SlotSelection;
  onSelect(
    botConfig: UserBotConfig,
    transitionOrigin?: { x: number; y: number }
  ): void;
  showMultiplayerGuide: boolean;
  onCloseMultiplayerGuide(): void;
  onBackToAllBotsView(): void;
}) {
  const [username, setUsername] = useState<string | null>(null);
  const [initialMount, setInitialMount] = useState(true);

  const nonNullUsername = useRef("");
  if (username !== null) {
    nonNullUsername.current = username;
  }

  const usernamePickerVisible =
    useDelayedValue(username === null, { delay: 300 }) || username === null;
  const resultAreaVisible =
    useDelayedValue(username !== null, { delay: 300 }) || username !== null;
  const showGuideDelay = useDelay(500);

  return (
    <div className={classes(css.container, props.small && css.small)}>
      {props.small && (
        <div
          style={{
            position: "absolute",
            right: "20px",
            top: "23px",
            width: "40px",
            height: "40px",
            background: "rgba(37, 42, 49, .8)",
            borderRadius: "100%",
            zIndex: 2,
          }}
        >
          <Button
            icon="cross"
            minimal
            large
            style={{ borderRadius: "100%" }}
            onClick={props.onBackToAllBotsView}
          />
        </div>
      )}
      {usernamePickerVisible && (
        <UsernamePicker
          small={props.small}
          onPickUsername={(value) => {
            setInitialMount(false);
            setUsername(value);
          }}
          showMultiplayerGuide={props.showMultiplayerGuide && showGuideDelay}
          transitionOut={username !== null}
          initialMount={initialMount}
          onCloseMultiplayerGuide={props.onCloseMultiplayerGuide}
        />
      )}
      {resultAreaVisible && (
        <ResultArea
          forceColor={props.forceColor}
          username={nonNullUsername.current}
          selectedSlot={props.selectedSlot}
          onBack={() => setUsername(null)}
          transitionOut={username === null}
          gameConfig={props.gameConfig}
          onSelect={(botConfig, transitionOrigin) => {
            props.onSelect(botConfig, transitionOrigin);
            if (!isSelected(botConfig, props.selectedSlot)) {
              setUsername(null);
            }
          }}
        />
      )}
    </div>
  );
}

function UsernamePicker(props: {
  onPickUsername(username: string): void;
  transitionOut: boolean;
  initialMount: boolean;
  showMultiplayerGuide: boolean;
  small: boolean;
  onCloseMultiplayerGuide(): void;
}) {
  const [username, setUsername] = useState("");
  const mounted = useDelay(0);
  const storage = useStorage();
  const [recentlyUsedUsernames, setRecentlyUsedUsernames] = useState(() => {
    return storage.sharedBotLoader.recentlyUsedUsernames.get();
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <div
      className={css.usernamePickerContainer}
      style={{
        transform: `translateX(${
          !props.initialMount && (props.transitionOut || !mounted) ? -100 : 0
        }%)`,
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (username.trim()) {
            props.onPickUsername(username.trim());
          }
        }}
        className={css.usernameForm}
      >
        <FormGroup
          label={<span style={{ fontWeight: 500 }}>Play against a friend</span>}
          style={{
            padding: "20px",
            paddingBottom: 0,
            marginBottom: 0,
            width: props.small ? "calc(100% - 50px)" : undefined,
          }}
        >
          <Popover
            background="blue"
            fill
            content={
              <GuideContent
                title="Multiplayer"
                message="Enter a friend's GitHub username here to see their bots and setup a game."
              />
            }
            enforceFocus={false}
            openOnTargetFocus={false}
            autoFocus={false}
            shouldReturnFocusOnClose={false}
            position="left"
            onClose={props.onCloseMultiplayerGuide}
            isOpen={props.showMultiplayerGuide && !props.transitionOut}
          >
            <InputGroup
              leftIcon="search"
              autoFocus={!props.initialMount}
              value={username}
              inputRef={inputRef}
              large
              fill
              onChange={(e) => setUsername(e.target.value)}
              placeholder="GitHub Username"
              rightElement={
                <Button minimal icon="chevron-right" type="submit" />
              }
            />
          </Popover>
        </FormGroup>
      </form>
      {recentlyUsedUsernames.length === 0 && (
        <div style={{ height: "250px" }} className={transitionInFromCss.bottom}>
          <NonIdealState
            icon="people"
            title="Multiplayer"
            description={
              <>
                Play against bots shared
                <br />
                by others on GitHub
              </>
            }
          />
        </div>
      )}
      {recentlyUsedUsernames.length > 0 && (
        <FormGroup
          label={<span className="bp4-text-muted">Recent</span>}
          style={{
            padding: "20px",
            paddingRight: "6px",
            overflowY: "scroll",
            height: "100%",
          }}
        >
          <div style={{ margin: "0px -4px" }}>
            {recentlyUsedUsernames.map((username) => {
              return (
                <Button
                  key={username}
                  onClick={() => {
                    props.onPickUsername(username);
                  }}
                  fill
                  large
                  alignText="left"
                  minimal
                  icon="user"
                  rightIcon="chevron-right"
                >
                  {username}
                </Button>
              );
            })}
            <Button
              fill
              large
              alignText="left"
              minimal
              icon="cross"
              onClick={() => {
                storage.sharedBotLoader.recentlyUsedUsernames.set([]);
                setRecentlyUsedUsernames([]);
              }}
            >
              <span className="bp4-text-muted">Clear Recent</span>
            </Button>
          </div>
        </FormGroup>
      )}
    </div>
  );
}

function ResultArea(props: {
  username: string;
  transitionOut: boolean;
  selectedSlot?: SlotSelection;
  forceColor?: BotColor;
  onBack(): void;
  gameConfig: GameConfig;
  onSelect(
    botConfig: UserBotConfig,
    transitionOrigin?: { x: number; y: number }
  ): void;
}) {
  const mounted = useDelay(0);

  const userStore = UserStore.use();
  const authenticated =
    userStore.query.isSuccess && userStore.query.data.type === "authenticated";
  const isOwner =
    userStore.query.isSuccess &&
    userStore.query.data.type === "authenticated" &&
    userStore.query.data.likelyLogin === props.username;

  // TODO partially valid bots (i.e. maybe they have the zilch-bot tag but their config doesn't match)
  // make it so a "no results" message is not displayed
  const sharedBotsQuery = useUserBotConfigListQuery(
    isOwner ? null : props.username,
    props.gameConfig.gameId
  );

  const premiumStore = PremiumStore.use();

  const storage = useStorage();
  const storageRef = useRef(storage);
  storageRef.current = storage;

  useEffect(() => {
    if (
      !sharedBotsQuery.isSuccess ||
      sharedBotsQuery.data === "nonexistent-user" ||
      sharedBotsQuery.data instanceof Error ||
      sharedBotsQuery.data.list.length === 0
    ) {
      return;
    }

    const recentlyUsedUsernames =
      storageRef.current.sharedBotLoader.recentlyUsedUsernames
        .get()
        .filter((username) => username !== props.username);

    recentlyUsedUsernames.unshift(props.username);

    storageRef.current.sharedBotLoader.recentlyUsedUsernames.set(
      recentlyUsedUsernames
    );
  }, [sharedBotsQuery.isSuccess, sharedBotsQuery.data, props.username]);

  let content;

  if (!premiumStore.hasPremium && authenticated) {
    content = (
      <div className={classes(transitionInFromCss.bottom, delayCss["180"])}>
        <Button
          intent="primary"
          large
          fill
          onClick={() => {
            premiumStore.setSection("feature-overview");
          }}
        >
          Learn about Premium
        </Button>
        <div className="bp4-text-muted" style={{ marginTop: "12px" }}>
          Unlock multiplayer with Premium.{" "}
          <a
            style={{ fontWeight: 600 }}
            onClick={() => {
              premiumStore.setSection("feature-overview");
            }}
          >
            Free Premium
          </a>{" "}
          is available to students and employees of select organizations.
        </div>
      </div>
    );
  } else if (sharedBotsQuery.isLoading) {
    content = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "150px",
        }}
      >
        <Spinner
          className={classes(transitionInFromCss.bottom, delayCss["300"])}
        />
      </div>
    );
  } else if (sharedBotsQuery.data === "nonexistent-user") {
    content = (
      <div
        className={classes(
          "bp4-text-large bp4-text-muted",
          transitionInFromCss.bottom
        )}
      >
        Unable to find a GitHub account with the username{" "}
        <b>{props.username}</b>.
      </div>
    );
  } else if (sharedBotsQuery.data instanceof Error) {
    content = (
      <div
        style={{ wordBreak: "break-all" }}
        className={classes(
          "bp4-text-large bp4-text-muted",
          transitionInFromCss.bottom
        )}
      >
        Unexpected error encountered: {sharedBotsQuery.data.message}
      </div>
    );
  } else if (sharedBotsQuery.data?.list.length === 0) {
    content = (
      <div
        className={classes(
          "bp4-text-large bp4-text-muted",
          transitionInFromCss.bottom
        )}
      >
        {isOwner && (
          <>
            Try looking up bots created by someone other than you. Your bots
            should show in the left section of this pane.
          </>
        )}
        {!authenticated && (
          <>
            <Button
              fill
              large
              icon={<SiGithub color={Colors.GRAY4} />}
              onClick={() => {
                userStore.signIn("multiplayer");
              }}
              loading={userStore.signingIn === "multiplayer"}
              disabled={!!userStore.signingIn}
            >
              Sign in / create account
            </Button>
            <div style={{ marginTop: "12px" }}>
              Sign in with your GitHub account to load bots created by others.
            </div>
          </>
        )}
        {!isOwner && authenticated && (
          <>
            No public {props.gameConfig.name} bots by <b>@{props.username}</b>.
          </>
        )}
      </div>
    );
  } else {
    content = (
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        {sharedBotsQuery.data?.list.map((config, index) => {
          const selected = isSelected(config, props.selectedSlot);

          return (
            <div
              className={transitionInFromCss.bottom}
              style={{
                animationDelay: index * 50 + 50 + "ms",
                position: "relative",
              }}
              key={
                props.forceColor
                  ? `${config.repo}-${props.forceColor}`
                  : config.repo
              }
            >
              <SelectedBotIndicator
                selected={selected}
                color={props.forceColor ?? "vermilion"}
              />
              <BotCard
                isSelected={selected}
                gameId={props.gameConfig.gameId}
                color={props.forceColor}
                botConfig={{
                  transition: false,
                  value: config,
                  slotSelection: null,
                }}
                onSelect={(transitionOrigin) => {
                  props.onSelect(config, transitionOrigin ?? undefined);
                }}
              />
            </div>
          );
        })}
        {sharedBotsQuery.data?.list.length === 0 && (
          <div
            className={classes(
              "bp4-text-muted bp4-text-large",
              transitionInFromCss.bottom
            )}
          >
            No results
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        transform: `translateX(${props.transitionOut || !mounted ? 100 : 0}%)`,
      }}
      className={css.resultAreaCss}
    >
      <div className={css.resultAreaHeaderCss}>
        <Button
          minimal
          icon="chevron-left"
          onClick={props.onBack}
          style={{ marginLeft: "-10px", marginTop: "-10px" }}
        >
          <span className="bp4-text-muted">Back</span>
        </Button>
        <div
          style={{
            fontSize: "16px",
            marginTop: "11px",
            position: "relative",
            zIndex: 2,
          }}
          className={classes(transitionInFromCss.bottom, delayCss["200"])}
        >
          {premiumStore.hasPremium || !authenticated ? (
            <>
              Created by <b>@{props.username}</b>
            </>
          ) : (
            <span style={{ display: "flex", gap: "6px", alignItems: "center" }}>
              <Icon icon="lock" />
              <b>Multiplayer Locked</b>
            </span>
          )}
        </div>
      </div>
      <div
        style={{
          overflowY: "scroll",
          padding: "20px",
          paddingRight: "6px",
          paddingTop: "15px",
        }}
      >
        {content}
      </div>
    </div>
  );
}
