@keyframes LeftAnimation {
  from {
    opacity: 0;
    transform: translateX(-15px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes RightAnimation {
  from {
    opacity: 0;
    transform: translateX(15px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes TopAnimation {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes BottomAnimation {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.left {
  animation: LeftAnimation ease 200ms both;
}

.right {
  animation: RightAnimation ease 200ms both;
}

.top {
  animation: TopAnimation ease 200ms both;
}

.bottom {
  animation: BottomAnimation ease 200ms both;
}

.delay30 {
  animation-delay: 30ms;
}

.delay60 {
  animation-delay: 60ms;
}

.delay90 {
  animation-delay: 90ms;
}

.delay100 {
  animation-delay: 100ms;
}

.delay120 {
  animation-delay: 120ms;
}

.delay150 {
  animation-delay: 150ms;
}

.delay180 {
  animation-delay: 180ms;
}

.delay200 {
  animation-delay: 200ms;
}

.delay240 {
  animation-delay: 240ms;
}

.delay300 {
  animation-delay: 300ms;
}

.delay350 {
  animation-delay: 350ms;
}

.delay450 {
  animation-delay: 450ms;
}

.delay500 {
  animation-delay: 500ms;
}
