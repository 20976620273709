import { Colors } from "@blueprintjs/colors";
import { Button, Icon } from "@blueprintjs/core";
import { Popover2, ResizeSensor2, Tooltip2 } from "@blueprintjs/popover2";
import { avatarSerializer, getNumSquaresFilled } from "@zilch/bot-avatar";
import { BotColor, zilchBotAvatars } from "@zilch/bot-models";
import { wrapIndex } from "@zilch/wrap-index";
import { sample, times } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { BotAvatarSvg } from "@zilch/bot-avatar-svg";
import { IoIosLock } from "react-icons/io";
import { generateBotAvatarAndColor } from "../../generateBotAvatarAndColor";
import css from "./BotAvatarCreator.module.css";
import { classes } from "@zilch/css-utils";
import { PremiumStore } from "../../stores/PremiumStore";

const EDIT_AREA_SIZE = 325;
const NUM_SQUARES = 16;

interface Props {
  avatar: string;
  preferredColor: BotColor;
  onUpdateAvatar: (avatar: string, preferredColor: BotColor) => void;
  onDone: () => Promise<void> | void;
  doneText?: string;
}

export function BotAvatarCreator(props: Props) {
  const [hoveredPoint, setHoveredPoint] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [editMode, setEditMode] = useState<"add" | "remove" | null>(null);
  const [saving, setSaving] = useState(false);
  const premiumStore = PremiumStore.use();

  const numFilledSquares = useMemo(() => {
    return getNumSquaresFilled(props.avatar);
  }, [props.avatar]);

  const notFilledEnough = numFilledSquares < 36;
  const patternNotPermitted = Object.values(zilchBotAvatars).some(
    (avatar) => avatar === props.avatar
  );

  const customAvatarIconGrid = useMemo(
    () => avatarSerializer.fromString(props.avatar),
    [props.avatar]
  );

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp);

    return () => window.removeEventListener("mouseup", handleMouseUp);

    function handleMouseUp() {
      setEditMode(null);
    }
  }, []);

  const [botCanvasWidth, setBotCanvasWidth] = useState(0);
  const [small, setSmall] = useState(false);
  const [colorPickerPopupOpen, setColorPickerPopupOpen] = useState(false);

  return (
    <ResizeSensor2
      onResize={(entries) => {
        if (entries.length > 0) {
          setSmall(entries[0]!.contentRect.width < 450);
        }
      }}
    >
      <div className={classes(css.container, small && css.smallContainer)}>
        {!premiumStore.hasPremium && (
          <div className={css.editingLockedOverlay}>
            <IoIosLock size={80} color={Colors.LIGHT_GRAY3} />
            <div
              style={{
                fontSize: "18px",
                maxWidth: "240px",
                textAlign: "center",
              }}
            >
              Bot avatar customization is a premium-exclusive feature.
            </div>
            <Button
              intent="primary"
              large
              onClick={() => {
                premiumStore.setSection("feature-overview");
              }}
            >
              Learn about Premium
            </Button>
          </div>
        )}
        <ResizeSensor2
          onResize={(entries) => {
            if (entries.length > 0) {
              setBotCanvasWidth(entries[0]!.contentRect.width);
            }
          }}
        >
          <div
            className={css.botCanvasContainer}
            style={{ opacity: premiumStore.hasPremium ? 1 : 0.1 }}
          >
            <GridLines
              hoveredPoint={hoveredPoint}
              color={props.preferredColor}
              width={botCanvasWidth}
            />
            <BotAvatarSvg
              avatar={props.avatar}
              disableCentering
              color={BotColor[props.preferredColor]}
              size={(12 * EDIT_AREA_SIZE) / NUM_SQUARES}
              className={css.botAvatar}
            />
            <div
              className={classes(
                css.noPointsMessage,
                (hoveredPoint !== null || numFilledSquares > 0) &&
                  css.noPointMessageFaded
              )}
            >
              Draw here
            </div>
            <div
              className={classes(
                css.editGrid,
                hoveredPoint !== null && css.editGridVisible
              )}
            >
              {times(10 * 10).map((position) => {
                const x = position % 10;
                const y = Math.floor(position / 10);
                return (
                  <div
                    className={css.editGridSquare}
                    key={position}
                    onMouseDown={() => {
                      const newEditMode = !!customAvatarIconGrid?.[y]?.[x]
                        ? "remove"
                        : "add";
                      setEditMode(newEditMode);

                      props.onUpdateAvatar(
                        getNewAvatar(),
                        props.preferredColor
                      );

                      function getNewAvatar() {
                        const grid = avatarSerializer.fromString(props.avatar);
                        if (grid === null) {
                          return props.avatar;
                        }
                        grid[y]![x] = newEditMode === "add";
                        return avatarSerializer.toString(grid);
                      }
                    }}
                    onMouseEnter={() => {
                      setHoveredPoint({ x, y });
                      if (editMode !== null) {
                        props.onUpdateAvatar(
                          getNewAvatar(),
                          props.preferredColor
                        );
                        function getNewAvatar() {
                          const grid = avatarSerializer.fromString(
                            props.avatar
                          );
                          if (grid === null) {
                            return props.avatar;
                          }
                          grid[y]![x] = editMode === "add";
                          return avatarSerializer.toString(grid);
                        }
                      }
                    }}
                    onMouseLeave={() => {
                      setHoveredPoint(null);
                    }}
                  />
                );
              })}
            </div>
            <div
              className={classes(
                css.buttonContainer,
                hoveredPoint !== null && css.buttonContainerFaded
              )}
            >
              <Tooltip2 isOpen={small ? undefined : false} content="Randomize">
                <Button
                  minimal
                  icon="random"
                  onClick={() => {
                    const { avatar, preferredColor } =
                      generateBotAvatarAndColor();
                    props.onUpdateAvatar(avatar, preferredColor);
                  }}
                >
                  {!small && "Randomize"}
                </Button>
              </Tooltip2>
              <div style={{ marginLeft: "4px" }}>
                <Tooltip2 isOpen={small ? undefined : false} content="Clear">
                  <Button
                    minimal
                    icon="eraser"
                    onClick={() => {
                      props.onUpdateAvatar(
                        avatarSerializer.toString(
                          times(10).map(() => times(10).map(() => false))
                        ),
                        props.preferredColor
                      );
                    }}
                  >
                    {!small && "Clear"}
                  </Button>
                </Tooltip2>
              </div>
              {small && (
                <Popover2
                  placement="top"
                  isOpen={colorPickerPopupOpen}
                  hasBackdrop={false}
                  onInteraction={(value) => setColorPickerPopupOpen(value)}
                  content={
                    <div style={{ padding: "20px", paddingBottom: "0px" }}>
                      <ColorPicker
                        color={props.preferredColor}
                        onChangeColor={(color) => {
                          setColorPickerPopupOpen(false);
                          props.onUpdateAvatar(props.avatar, color);
                        }}
                      />
                    </div>
                  }
                >
                  <Button
                    minimal
                    active={colorPickerPopupOpen}
                    icon={
                      <span>
                        <div
                          className={css.colorIcon}
                          style={{
                            background: BotColor[props.preferredColor],
                          }}
                        />
                        <Icon icon="blank" />
                      </span>
                    }
                    rightIcon="caret-down"
                  />
                </Popover2>
              )}
              {small && (
                <div style={{ marginLeft: "16px" }}>
                  <DoneButton
                    onChangeSaving={setSaving}
                    notFilledEnough={notFilledEnough}
                    onDone={props.onDone}
                    patternNotPermitted={patternNotPermitted}
                    saving={saving}
                    doneText={props.doneText}
                    small={small}
                  />
                </div>
              )}
            </div>
          </div>
        </ResizeSensor2>
        {!small && (
          <div style={{ opacity: premiumStore.hasPremium ? 1 : 0.1 }}>
            <ColorPicker
              color={props.preferredColor}
              onChangeColor={(color) => {
                props.onUpdateAvatar(props.avatar, color);
              }}
            />
            <DoneButton
              onChangeSaving={setSaving}
              notFilledEnough={notFilledEnough}
              onDone={props.onDone}
              patternNotPermitted={patternNotPermitted}
              saving={saving}
              doneText={props.doneText}
            />
          </div>
        )}
      </div>
    </ResizeSensor2>
  );
}

function ColorPicker(props: {
  color: BotColor;
  onChangeColor: (color: BotColor) => void;
}) {
  return (
    <div className={css.colorsContainer}>
      {(Object.keys(BotColor) as BotColor[]).map((color) => {
        return (
          <Tooltip2
            key={color}
            content={<span className={css.colorLabel}>{color}</span>}
            placement="top"
            hoverOpenDelay={500}
          >
            <button
              className={css.colorButton}
              style={{ background: BotColor[color] }}
              onClick={() => {
                props.onChangeColor(color);
              }}
            >
              {props.color === color && <Icon icon="tick" size={20} />}
            </button>
          </Tooltip2>
        );
      })}
      <Tooltip2
        content={<span className={css.colorLabel}>Random</span>}
        placement="top"
        hoverOpenDelay={500}
      >
        <button
          className={css.colorButton}
          style={{ color: Colors.GRAY5 }}
          onClick={() => {
            const availableColors = new Set(
              Object.keys(BotColor) as BotColor[]
            );
            availableColors.delete(props.color);
            props.onChangeColor(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              sample(Array.from(availableColors))!
            );
          }}
        >
          <Icon icon="random" />
        </button>
      </Tooltip2>
    </div>
  );
}

function DoneButton(props: {
  onDone(): void;
  doneText?: string;
  patternNotPermitted: boolean;
  notFilledEnough: boolean;
  saving: boolean;
  onChangeSaving(value: boolean): void;
  small?: boolean;
}) {
  const premiumStore = PremiumStore.use();

  return (
    <Tooltip2
      content={
        props.patternNotPermitted
          ? "Pattern not permitted"
          : props.notFilledEnough
          ? "Fill in a few more squares"
          : undefined
      }
      placement="bottom"
      isOpen={props.patternNotPermitted || props.notFilledEnough}
      className={css.tooltip}
    >
      <Button
        disabled={
          props.notFilledEnough ||
          props.patternNotPermitted ||
          !premiumStore.hasPremium
        }
        large={!props.small}
        loading={props.saving}
        fill={!props.small}
        intent="primary"
        onClick={async () => {
          props.onChangeSaving(true);
          await props.onDone();
          props.onChangeSaving(false);
        }}
      >
        {props.doneText ?? "Done"}
      </Button>
    </Tooltip2>
  );
}

function GridLines({
  hoveredPoint,
  color,
  width,
}: {
  hoveredPoint: { x: number; y: number } | null;
  color: BotColor;
  width: number;
}) {
  const keys = Object.keys(BotColor) as BotColor[];
  const oppositeColor =
    BotColor[
      keys[wrapIndex(keys, Math.round(keys.indexOf(color) + keys.length / 2))]!
    ];

  const [actualHoveredPoint, setActualHoveredPoint] = useState(hoveredPoint);
  useEffect(() => {
    if (hoveredPoint !== null) {
      setActualHoveredPoint(hoveredPoint);
    }
  }, [hoveredPoint]);
  const centerAmount = (width - EDIT_AREA_SIZE) / 2;
  return (
    <svg
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        zIndex: 1,
        opacity: hoveredPoint === null ? 0 : 1,
        transition: "opacity ease .5s",
      }}
      viewBox={`0 0 ${width} ${EDIT_AREA_SIZE}`}
    >
      {[
        ...times(11).map((row) => {
          const hovered =
            actualHoveredPoint?.y === row - 1 || actualHoveredPoint?.y === row;
          return {
            zIndex: hovered ? 1 : 0,
            element: (
              <path
                key={row + "row"}
                d={`M 0,${
                  ((row + 2) * EDIT_AREA_SIZE) / NUM_SQUARES
                } ${width},${((row + 2) * EDIT_AREA_SIZE) / NUM_SQUARES} z`}
                strokeOpacity={
                  hovered ? 0.7 : row === 0 || row === 10 ? 0.4 : 0.2
                }
                stroke={hovered ? oppositeColor : Colors.LIGHT_GRAY1}
                strokeWidth={hovered ? 3 : 1}
              />
            ),
          };
        }),
        ...times(11).map((column) => {
          const hovered =
            actualHoveredPoint?.x === column - 1 ||
            actualHoveredPoint?.x === column;
          return {
            zIndex: hovered ? 1 : 0,
            element: (
              <path
                key={column + "col"}
                d={`M ${
                  centerAmount + ((column + 3) * EDIT_AREA_SIZE) / NUM_SQUARES
                },0 ${
                  centerAmount + ((column + 3) * EDIT_AREA_SIZE) / NUM_SQUARES
                },${EDIT_AREA_SIZE} z`}
                strokeOpacity={
                  hovered ? 0.7 : column === 0 || column === 10 ? 0.4 : 0.2
                }
                stroke={hovered ? oppositeColor : Colors.LIGHT_GRAY1}
                strokeWidth={hovered ? 3 : 1}
              />
            ),
          };
        }),
      ]
        .sort((a, b) => a.zIndex - b.zIndex)
        .map(({ element }) => element)}
    </svg>
  );
}
