import {
  NonIdealState as BpNonIdealState,
  type NonIdealStateProps,
} from "@blueprintjs/core";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import { forwardRef } from "react";
import css from "./NonIdealState.module.css";

export const NonIdealState = forwardRef<BpNonIdealState, NonIdealStateProps>(
  (props, ref) => {
    return (
      <BpNonIdealState
        {...props}
        ref={ref}
        className={classes(
          transitionInFromCss.bottom,
          css.fullHeight,
          props.className
        )}
      />
    );
  }
);
