[
  "Aardvark",
  "Albatross",
  "Alligator",
  "Alpaca",
  "Android",
  "Angelfish",
  "Ant",
  "Anteater",
  "Antelope",
  "Ape",
  "Armadillo",
  "Baboon",
  "Badger",
  "Barracuda",
  "Bat",
  "Bear",
  "Beaver",
  "Bee",
  "Beetle",
  "Bigfoot",
  "Bird",
  "Bison",
  "Boar",
  "Bot",
  "Buffalo",
  "Bumblebee",
  "Butterfly",
  "Camel",
  "Caribou",
  "Cat",
  "Caterpillar",
  "Cattle",
  "Centaur",
  "Cerberus",
  "Chameleon",
  "Cheetah",
  "Chicken",
  "Chimpanzee",
  "Clam",
  "Cobra",
  "Cockroach",
  "Cod",
  "Cow",
  "Coyote",
  "Crab",
  "Crane",
  "Crocodile",
  "Crow",
  "Cyborg",
  "Cyclops",
  "Deer",
  "Dingo",
  "Dinosaur",
  "Dog",
  "Dolphin",
  "Donkey",
  "Dove",
  "Dragon",
  "Dragonfly",
  "Droid",
  "Duck",
  "Dwarf",
  "Eagle",
  "Eel",
  "Elephant",
  "Elf",
  "Elk",
  "Emu",
  "Egg",
  "Falcon",
  "Faun",
  "Ferret",
  "Finch",
  "Fish",
  "Flamingo",
  "Fly",
  "Fox",
  "Frog",
  "Gazelle",
  "Gerbil",
  "Giant",
  "Giraffe",
  "Gnat",
  "Gnome",
  "Goat",
  "Goldfish",
  "Goose",
  "Gorilla",
  "Grasshopper",
  "Griffin",
  "Hamster",
  "Hare",
  "Hawk",
  "Hedgehog",
  "Heron",
  "Herring",
  "Hippopotamus",
  "Hornet",
  "Horse",
  "Hummingbird",
  "Hyena",
  "Iguana",
  "Impala",
  "Ibis",
  "Ibex",
  "Inchworm",
  "Invention",
  "Jackal",
  "Jaguar",
  "Jellyfish",
  "Jumbotron",
  "Kangaroo",
  "Kelpie",
  "Koala",
  "Kraken",
  "Ladybug",
  "Lark",
  "Lemur",
  "Leopard",
  "Lion",
  "Lizard",
  "Llama",
  "Lobster",
  "Locust",
  "Loris",
  "Machine",
  "Manatee",
  "Meerkat",
  "Mermaid",
  "Mink",
  "Minotaur",
  "Mole",
  "Monkey",
  "Moose",
  "Mosquito",
  "Mouse",
  "Mule",
  "Narwhal",
  "Newt",
  "Nightingale",
  "Octopus",
  "Ostrich",
  "Otter",
  "Owl",
  "Ox",
  "Oyster",
  "Panther",
  "Parrot",
  "Partridge",
  "Pegasus",
  "Pelican",
  "Penguin",
  "Pheasant",
  "Phoenix",
  "Pig",
  "Pigeon",
  "Platypus",
  "Pony",
  "Porcupine",
  "Porpoise",
  "Puma",
  "Quail",
  "Quilt",
  "Quill",
  "Quartz",
  "Quiver",
  "Questionnaire",
  "Quasar",
  "Quiche",
  "Rabbit",
  "Raccoon",
  "Ram",
  "Rat",
  "Raven",
  "Reindeer",
  "Rhino",
  "Robot",
  "Salamander",
  "Salmon",
  "Sandpiper",
  "Sardine",
  "Satyr",
  "Scorpion",
  "Seagull",
  "Seahorse",
  "Seal",
  "Shark",
  "Sheep",
  "Shrimp",
  "Skunk",
  "Sloth",
  "Slug",
  "Snail",
  "Snake",
  "Sphinx",
  "Spider",
  "Squid",
  "Squirrel",
  "Starfish",
  "Stingray",
  "Stinkbug",
  "Stork",
  "Swan",
  "Tank",
  "Thunderbird",
  "Tiger",
  "Toad",
  "Toaster",
  "Troll",
  "Trout",
  "Turkey",
  "Turtle",
  "Umbrella",
  "Umpire",
  "Unicorn",
  "Unicycle",
  "Valkyrie",
  "Viper",
  "Violin",
  "Vase",
  "Velcro",
  "Vinyl",
  "Veggies",
  "Vulture",
  "Wallaby",
  "Walrus",
  "Wasp",
  "Weasel",
  "Whale",
  "Wolf",
  "Wolverine",
  "Wombat",
  "Woodpecker",
  "Worm",
  "Yak",
  "Yeti",
  "Zebra",
  "Zipper",
  "Zester",
  "Zephyr",
  "Zircon",
  "Zither",
  "Ziggurat"
]
