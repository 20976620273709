.dialog {
  background: var(--dark-gray3) !important;
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 20px);
  padding-bottom: 0px !important;
  overflow: hidden;
  position: relative;
}

.lowerZIndex {
  z-index: 19 !important;
}
