import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

export type Language = z.infer<typeof Language>;
const Language = z.object({
  name: z.string(),
  variants: z
    .record(
      z.object({
        name: z.string(),
      })
    )
    .optional(),
});

export type LanguageCollection = z.infer<typeof LanguageCollection>;
export const LanguageCollection = z.record(Language);

export function useLanguages() {
  return useQuery(
    ["languages"],
    async ({ signal }) => {
      const response = await fetch(
        `${ClientEnv.LANGUAGES_METADATA_ORIGIN}/languages.json`,
        {
          signal,
        }
      );

      if (!response.ok) {
        throw response;
      }

      const { $schema: _, ...rest } =
        (await response.json()) as LanguageCollection;

      return LanguageCollection.parse(rest);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
}
