import { capitalize, sample } from "lodash";

import adjectives from "./resources/adjectives.json";
import nouns from "./resources/nouns.json";
import { validateBotName } from "./validateBotName";

const nounsByStartingLetter = nouns.reduce((map, noun) => {
  const startingLetter = noun[0]!.toLowerCase();
  const nounList = map.get(startingLetter) ?? [];
  nounList.push(noun);
  map.set(startingLetter, nounList);
  return map;
}, new Map<string, string[]>());

const recentNouns: string[] = [];
const recentAdjectives: string[] = [];

export function generateBotNameSuggestion() {
  let suggestion: string | null = null;
  let i = 0;

  do {
    i++;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const adjective = sample(adjectives)!;

    if (recentAdjectives.includes(adjective) && i < 20) {
      continue;
    }

    const startingLetter = adjective[0]!.toLowerCase();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const noun = sample(nounsByStartingLetter.get(startingLetter) ?? nouns)!;

    if (recentNouns.includes(noun) && i < 20) {
      continue;
    }

    recentNouns.push(noun);
    recentAdjectives.push(adjective);

    if (recentNouns.length > 10) {
      recentNouns.unshift();
    }

    if (recentAdjectives.length > 10) {
      recentAdjectives.unshift();
    }

    suggestion = `${capitalize(adjective)} ${capitalize(noun)}`;
  } while (
    suggestion === null ||
    validateBotName(suggestion).problems.length > 0
  );

  return suggestion;
}
