import { useEffect, useRef, useState } from "react";
import { Colors, Icon, type IconName } from "@blueprintjs/core";
import { type GameOutcome, GameSpeed } from "./GameEngine";
import { classes } from "@zilch/css-utils";
import css from "./SpeedIndicator.module.css";
import { useDelayedValue } from "@zilch/delay";

interface Props {
  gameSpeed: GameSpeed;
  outcome: GameOutcome;
  hasNotStartedBots: boolean;
}

export function SpeedIndicator({
  gameSpeed,
  outcome,
  hasNotStartedBots,
}: Props) {
  const running = outcome.status === "in-progress" && !hasNotStartedBots;
  const runningDelayed = useDelayedValue(running, {
    delay: 500,
    initialValue: running,
  });

  const stopped =
    !running &&
    outcome.status === "done" &&
    outcome.primary?.type === "stopped";

  const changesSinceRunning = useRef(0);
  const previousGameSpeedRef = useRef<GameSpeed | null>(null);
  if (running) {
    if (previousGameSpeedRef.current !== gameSpeed) {
      changesSinceRunning.current++;
    }
  } else {
    changesSinceRunning.current = 0;
  }

  useEffect(() => {
    previousGameSpeedRef.current = gameSpeed;
  }, [gameSpeed]);

  return (
    <>
      <Speed icon="stop" show={stopped} />
      <Speed
        icon="pause"
        show={runningDelayed && gameSpeed === GameSpeed.Paused}
      />
      <Speed
        icon="play"
        show={
          runningDelayed &&
          changesSinceRunning.current > 0 &&
          gameSpeed === GameSpeed.Normal
        }
      />
      <Speed
        icon="fast-forward"
        show={
          runningDelayed &&
          changesSinceRunning.current > 0 &&
          gameSpeed === GameSpeed.Fast
        }
      />
    </>
  );
}

function Speed(props: { icon: IconName; show: boolean }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.show) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 350);
    }
  }, [props.show]);

  return (
    <Icon
      className={classes(css.speedIcon, visible && css.visibleSpeedIcon)}
      icon={props.icon}
      size={80}
      color={Colors.LIGHT_GRAY5}
    />
  );
}
