.container:not(:global(.bp4-interactive):hover):not(
    :global(.bp4-interactive):active
  ) {
  background: var(--dark-gray4) !important;
  box-shadow: inset 0 0 0 1px rgb(255 255 255 / 10%);
}

.container:global(.bp4-interactive):hover {
  background: var(--dark-gray5) !important;
}

.container:global(.bp4-interactive):active {
  transition-duration: 50ms;
  background: #3c434e !important;
}
