.listContainer {
  overflow-y: overlay;
  height: 100%;
  padding-bottom: 50px;
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid var(--dark-gray3);
}

.select {
  width: 92px;
}