export function wrapIndex(array: unknown[] | number, index: number) {
  const length = typeof array === "number" ? array : array.length;

  if (index < 0) {
    return length + (index % length);
  }

  if (index >= length) {
    return index % length;
  }

  return index;
}
