import { classes } from "@zilch/css-utils";
import css from "./StoreProductList.module.css";

export function StoreProductList() {
  return (
    <div className={classes(css.container, "bp4-text-muted")}>
      Product catalog coming soon.
    </div>
  );
}
