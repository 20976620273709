import { Colors, Button, Icon, AnchorButton } from "@blueprintjs/core";
import { useWindowSizeDerivedValue } from "@zilch/window-size";
import { useState } from "react";
import css from "./SmallScreenMessage.module.css";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import { useStorage } from "../../useStorage";
import { transitionScreen } from "../../transitionScreen";
import { isCrawler } from "@zilch/is-crawler";
import { groups, useRoute } from "../../router";

export function SmallScreenMessage(props: { children: React.ReactNode }) {
  const recommended = {
    width: Math.min(
      790,
      isMobileDevice()
        ? Infinity
        : screen.availWidth - (window.outerWidth - window.innerWidth + 10)
    ),
    height: Math.min(
      730,
      isMobileDevice()
        ? Infinity
        : screen.availHeight - (window.outerHeight - window.innerHeight + 10)
    ),
  };

  const storage = useStorage();
  const route = useRoute();

  const [show, setShow] = useState(() => {
    if (
      isCrawler() ||
      groups.githubAuthCallback.has(route) ||
      groups.githubBotAuthCallback.has(route)
    ) {
      return false;
    }

    return (
      !storage.dontShowScreenSizePrompt.get() &&
      (window.innerWidth < recommended.width ||
        window.innerHeight < recommended.height)
    );
  });

  if (show) {
    return (
      <SmallScreenMessageForm
        onContinue={() => {
          transitionScreen(null, () => {
            setShow(false);
          });
        }}
        recommended={recommended}
      />
    );
  } else {
    return <>{props.children}</>;
  }
}

function SmallScreenMessageForm(props: {
  recommended: { width: number; height: number };
  onContinue(): void;
}) {
  const { width, height } = useWindowSizeDerivedValue();
  const storage = useStorage();
  const recommendationsSatisfied =
    width >= props.recommended.width && height >= props.recommended.height;

  const [message] = useState(() => {
    if (isMobileDevice()) {
      return (
        <>
          Zilch is <span className={css.emphasizedText}>better&nbsp;big.</span>{" "}
          Are you able to use a device with a bigger screen?
        </>
      );
    } else {
      return (
        <>
          Zilch is <span className={css.emphasizedText}>better&nbsp;big.</span>{" "}
          Are you able to{" "}
          <span className={css.emphasizedText}>
            increase&nbsp;the&nbsp;size
          </span>{" "}
          of your browser window?
        </>
      );
    }
  });

  return (
    <div className={css.outerContainer}>
      <div
        className={classes(
          recommendationsSatisfied && css.cornerRecommendationsSatisfied,
          css.corner,
          css.topLeftCorner
        )}
      />
      <div
        className={classes(
          recommendationsSatisfied && css.cornerRecommendationsSatisfied,
          css.corner,
          css.topRightCorner
        )}
      />
      <div
        className={classes(
          recommendationsSatisfied && css.cornerRecommendationsSatisfied,
          css.corner,
          css.bottomLeftCorner
        )}
      />
      <div
        className={classes(
          recommendationsSatisfied && css.cornerRecommendationsSatisfied,
          css.corner,
          css.bottomRightCorner
        )}
      />
      <div className={css.innerContainer}>
        <div
          className={css.message}
          style={{
            position: "absolute",
            opacity: recommendationsSatisfied ? 1 : 0,
            transitionDelay: recommendationsSatisfied ? "150ms" : "0ms",
            transform: recommendationsSatisfied
              ? "translateY(0px)"
              : "translateY(20px)",
          }}
        >
          <span className={css.emphasizedText}>Perfect!</span> You'll be glad
          you sized up. Continue when ready.
        </div>
        <div
          className={css.message}
          style={{
            opacity: recommendationsSatisfied ? 0 : 1,
            transitionDelay: recommendationsSatisfied ? "0ms" : "150ms",
            transform: recommendationsSatisfied
              ? "translateY(-20px)"
              : "translateY(0px)",
          }}
        >
          {message}
        </div>
        {recommendationsSatisfied && (
          <Button
            fill
            large
            intent="primary"
            className={transitionInFromCss.bottom}
            onClick={() => props.onContinue()}
            style={{
              height: "72px",
              fontWeight: 600,
              fontSize: "20px",
              borderRadius: "10px",
            }}
          >
            Continue
          </Button>
        )}
        {!recommendationsSatisfied && (
          <div
            className={classes(css.sizeBarSection, transitionInFromCss.bottom)}
          >
            <SizeBar
              label="Width"
              value={width}
              recommended={props.recommended.width}
            />
            <SizeBar
              label="Height"
              value={height}
              recommended={props.recommended.height}
            />
          </div>
        )}
        <div className={css.bottomSection}>
          {recommendationsSatisfied && <div />}
          {!recommendationsSatisfied && (
            <Button
              minimal
              large
              alignText="left"
              className={transitionInFromCss.top}
              onClick={() => {
                storage.dontShowScreenSizePrompt.set(true);
                props.onContinue();
              }}
            >
              <span className="bp4-text-muted">
                Continue without sizing&nbsp;up
              </span>
            </Button>
          )}
          <AnchorButton
            alignText="left"
            intent="primary"
            large
            href="/introducing-zilch"
            minimal
            style={{ fontWeight: 600 }}
          >
            What&nbsp;is&nbsp;Zilch?
          </AnchorButton>
        </div>
      </div>
    </div>
  );
}

function SizeBar(props: { value: number; recommended: number; label: string }) {
  return (
    <div className={css.sizeBarContainer}>
      <div
        className={css.sizeBarTop}
        style={{
          color:
            props.value >= props.recommended ? Colors.FOREST5 : Colors.GRAY3,
        }}
      >
        <div style={{ fontWeight: 500, whiteSpace: "nowrap" }}>
          {props.label}{" "}
          <Icon
            icon="small-tick"
            color={Colors.FOREST5}
            style={{
              transition: "all ease .3s",
              transform: "translateY(-1px)",
              opacity: props.value >= props.recommended ? 1 : 0,
            }}
          />
        </div>
        <div style={{ fontWeight: 500 }}>{props.value}px</div>
      </div>
      <div style={{ position: "relative" }}>
        <div className={css.sizeBarBacking}>
          <div
            className={css.sizeBar}
            style={{
              transform: `scaleX(${Math.min(
                1,
                props.value / props.recommended
              )})`,
              background:
                props.value >= props.recommended
                  ? Colors.FOREST5
                  : Colors.GRAY2,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export function isMobileDevice() {
  const userAgent =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigator.userAgent || navigator.vendor || (window as any).opera;
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      userAgent.substr(0, 4)
    )
  );
}
