import { times } from "lodash";

import { avatarSerializer } from "./avatarSerializer";
import { getNumSquaresFilled } from "./getNumSquaresFilled";

export function generateBotAvatar(): string {
  let avatar: string;
  let numFilledSquares: number;

  do {
    const fullSize = Math.random() > 0.5;
    avatar = avatarSerializer.toString(
      times(fullSize ? 10 : 9).map(() => {
        const leftSide = times(fullSize ? 5 : 4).map(() => Math.random() > 0.7);
        const middle = fullSize ? [] : [Math.random() > 0.7];
        const rightSide = Array.from(leftSide).reverse();
        const row = [...leftSide, ...middle, ...rightSide];
        return row;
      })
    );
    numFilledSquares = getNumSquaresFilled(avatar);
  } while (numFilledSquares < 36);

  return avatar;
}
