import React, { useRef } from "react";
import css from "./Reveal.module.css";

interface Props {
  delay?: number;
  children: (text: (value: string) => React.ReactNode) => React.ReactNode;
}

export function Reveal(props: Props) {
  const delayRef = useRef(props.delay ?? 0);

  return (
    <>
      {props.children((text) => {
        return <RevealText text={text} delayRef={delayRef} />;
      })}
    </>
  );
}

const characterDelay: Record<string, number | undefined> = {
  " ": 0,
  "■": 100,
  ".": 500,
  "?": 500,
  "!": 500,
  ":": 400,
};

function RevealText(props: {
  text: string;
  delayRef: React.MutableRefObject<number>;
}) {
  const initialRenderRef = useRef(true);
  const delayByIndexRef = useRef<number[]>([]);
  const characters = props.text.split("");

  const content = (
    <>
      {characters.map((character, index) => {
        if (initialRenderRef.current) {
          delayByIndexRef.current[index] = props.delayRef.current;
          if (character === "." && (characters[index + 1] ?? " ") !== " ") {
            props.delayRef.current += 30;
          } else {
            props.delayRef.current += characterDelay[character] ?? 30;
          }
        }

        if (character === "■") {
          return null;
        }

        const delay = delayByIndexRef.current[index];

        return (
          <span
            key={index}
            className={css.character}
            style={{
              animationDelay: delay === undefined ? "0ms" : delay + "ms",
            }}
          >
            {character}
          </span>
        );
      })}
    </>
  );

  initialRenderRef.current = false;

  return content;
}
