.outerContainer {
  padding: 30px;
  padding-top: 10px;
  width: 100%;
  overflow: hidden;
  padding-right: 16px;
}

.container {
  display: grid;
  gap: 40px;
  padding: 50px;
  padding-bottom: 64px;
  border-radius: 20px;
  justify-content: center;
  justify-items: center;
  background: var(--dark-gray2);
}

.game {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.stars {
  display: flex;
  align-items: center;
  gap: 6px;
  top: 0;
  padding: 5px 10px;
  background: var(--dark-gray1);
  border-radius: 15px;
}

.gameIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 80px;
  height: 80px;
  cursor: pointer;
  transition: all cubic-bezier(0.5, 1.8, 0.5, 1) 0.5s;
  transform: scale(1.2);
  z-index: 1;
}

.gameIcon:hover {
  transform: scale(1.9) rotate(3deg);
}

.gameIcon:active {
  transform: scale(1) rotate(3deg);
}
