.container {
  padding: 110px 0px 20px 60px;
  width: 100%;
  overflow-x: hidden;
}

.level {
  text-transform: uppercase;
  font-weight: 900;
  color: var(--forest5);
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title {
  font-weight: 900;
  font-size: 80px;
  margin-top: -20px;
  color: var(--white);
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  filter: drop-shadow(0px 1px 0px var(--gray1))
    drop-shadow(0px 2px 0px var(--gray1))
    drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.4));
}
