import { Tag } from "@blueprintjs/core";
import React from "react";

export function MenuTag({ children }: { children: React.ReactNode }) {
  return (
    <Tag
      style={{
        fontSize: "12px",
        padding: "2px 6px",
        lineHeight: "16px",
        minHeight: "20px",
        transform: "translateY(-1px)",
      }}
      minimal
    >
      {children}
    </Tag>
  );
}
