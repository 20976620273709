import type { SlotSelection } from "@zilch/bot-models";
import {
  BotOutcome,
  BotType,
  type GameBotConfig,
  type TransitionSlotSelection,
  type UserBotConfig,
  zilchBotAvatars,
  zilchBotName,
  zilchBotPreferredColor,
  BotColor,
} from "@zilch/bot-models";
import { useDelay, useDelayedValue } from "@zilch/delay";
import React, {
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BotCard, getBotName } from "./BotCard";
import { BotSelector } from "./BotSelector";
import { Slot } from "./Slot";
import css from "./BotList.module.css";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import { useUserBotConfigListQuery } from "./useUserBotConfigListQuery";
import type { GameId } from "@zilch/game-config";
import { UserStore } from "../../stores/UserStore";
import type { GameEngineInstance, GameOutcome } from "./GameEngine";
import { BotOutcomeDisplay } from "./BotOutcomeDisplay";
import { StarStore } from "../../stores/StarStore";
import { Spinner } from "@blueprintjs/core";
import { GuideContent } from "./GuideContent";
import { sample } from "lodash";

interface Props {
  outcome: GameOutcome;
  gameEngineInstance: GameEngineInstance;
  slotSelections: TransitionSlotSelection[];
  slotMessage: {
    index: number;
    message: React.ReactNode;
  } | null;
  onCloseSlotMessage(): void;
  onSetSlotSelections(
    fn: (slotSelections: TransitionSlotSelection[]) => {
      slotSelections: TransitionSlotSelection[];
      prompt?: { slotIndex: number; message: React.ReactNode };
    }
  ): void;
  activeBots: Set<number>;
  showMultiplayerGuide: boolean;
  onCloseMultiplayerGuide(): void;
}

interface SlotSelectionForUi {
  value: NonNullable<TransitionSlotSelection>;
  index: number;
  pendingRemoval: boolean;
}

export function BotList(props: Props) {
  const [selectForSlot, setSelectForSlot] = useState<number | null>(null);

  const selectionCount = props.slotSelections.filter((slot) => !!slot).length;
  const selectionCountRef = useRef(selectionCount);
  selectionCountRef.current = selectionCount;

  const userStore = UserStore.use();
  const userBots = useUserBotConfigListQuery(
    userStore.query.data?.type === "authenticated"
      ? userStore.query.data.likelyLogin
      : null,
    props.gameEngineInstance.gameConfig.gameId
  );
  const hasBots =
    userStore.query.data?.type === "authenticated" &&
    userBots.isSuccess &&
    userBots.data !== "nonexistent-user" &&
    userBots.data.list.length > 0;
  const hasBotsRef = useRef(hasBots);
  hasBotsRef.current = hasBots;

  const showMultiplayerGuideRef = useRef(props.showMultiplayerGuide);
  showMultiplayerGuideRef.current = props.showMultiplayerGuide;

  const isDevModeRef = useRef(props.gameEngineInstance.gameConfig.dev);
  isDevModeRef.current = props.gameEngineInstance.gameConfig.dev;

  useEffect(() => {
    const openSelector = () => {
      if (isDevModeRef.current) {
        return;
      }

      if (
        !showMultiplayerGuideRef.current &&
        (hasBotsRef.current || selectionCountRef.current > 0)
      ) {
        return;
      }

      setSelectForSlot((slot) => {
        if (slot === null) {
          return 1;
        }
        return slot;
      });
    };
    const timeout = setTimeout(openSelector, 1_000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const selectForSlotRef = useRef(selectForSlot);
  if (selectForSlot !== null) {
    selectForSlotRef.current = selectForSlot;
  }

  const { onSetSlotSelections } = props;

  const onSetSlotSelection = useCallback(
    (
      slotSelection: TransitionSlotSelection,
      prompt?: { message: React.ReactNode; slotIndex: number }
    ) => {
      setSelectForSlot((selectForSlot) => {
        if (selectForSlot === null) {
          return selectForSlot;
        }

        onSetSlotSelections((currentSlotSelections) => {
          const newSlotSelections = [...currentSlotSelections];

          for (let i = newSlotSelections.length; i < selectForSlot; i++) {
            newSlotSelections[i] = null;
          }

          newSlotSelections[selectForSlot] = slotSelection;
          return { slotSelections: newSlotSelections, prompt };
        });

        return null;
      });
    },
    [onSetSlotSelections]
  );

  const botCardContainerRef = useRef<HTMLDivElement | null>(null);
  const slotsContainerRef = useRef<HTMLDivElement | null>(null);

  const slotSelectionsForUi = useSlotSelectionsForUi(props.slotSelections);
  const filledIndices = new Set(
    slotSelectionsForUi
      .filter((slot) => !slot.pendingRemoval)
      .map((slot) => slot.index)
  );

  const showSwapIndices = props.gameEngineInstance.gameConfig.slots
    .map((_, index) => index)
    .filter((index) => {
      const isNull = !filledIndices.has(index);
      const isNextNull = !filledIndices.has(index + 1);
      return (
        index < props.gameEngineInstance.gameConfig.slots.length - 1 &&
        !(isNull && isNextNull)
      );
    });

  const popoverRepositionersRef = useRef(new Map<number, () => void>());

  const starStore = StarStore.use();

  useEffect(() => {
    return () => {
      starStore.slotsScrollXOffset.current = 0;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldShow =
    useDelay(200) &&
    (props.outcome.status !== "done" || props.outcome.replayProgress === null);
  const show = useDelayedValue(shouldShow, { delay: 300 }) || shouldShow;
  const delayedShouldShow =
    useDelayedValue(shouldShow, { delay: 30 }) && shouldShow;

  return (
    <>
      {show && (
        <div
          className={css.container}
          ref={containerRef}
          style={{
            opacity: delayedShouldShow ? 1 : 0,
            transform: `translateY(${delayedShouldShow ? 0 : 200}px)`,
            transition: "all ease .2s",
            right: props.outcome.status === "in-progress" ? 137 : 225,
          }}
        >
          <div
            ref={botCardContainerRef}
            style={{
              position: "relative",
              zIndex: 1,
            }}
          >
            {slotSelectionsForUi.map((slotSelection) => {
              const numPreviousExpanded = getNumPreviousExpanded(
                showSwapIndices,
                slotSelection.index,
                props.outcome.status !== "in-progress"
              );
              return (
                <Bot
                  remove={slotSelection.pendingRemoval}
                  key={slotSelection.value.transitionData.slotId}
                  slotSelection={slotSelection.value}
                  containerRef={containerRef}
                  slotsContainerRef={slotsContainerRef}
                  slotIndex={slotSelection.index}
                  isFirstPracticeBot={
                    slotSelectionsForUi.filter(
                      (slot) => slot.value.type === BotType.Practice
                    )[0]?.index === slotSelection.index
                  }
                  onSetRepositionPopover={(reposition) => {
                    if (reposition) {
                      popoverRepositionersRef.current.set(
                        slotSelection.index,
                        reposition
                      );
                    } else {
                      popoverRepositionersRef.current.delete(
                        slotSelection.index
                      );
                    }
                  }}
                  gameOutcome={props.outcome}
                  gameId={props.gameEngineInstance.gameConfig.gameId}
                  numPreviousExpanded={numPreviousExpanded}
                  onSwitchForBossBot={() => {
                    props.onSetSlotSelections((slotSelections) => {
                      const newSlotSelections = [...slotSelections];
                      const x: number =
                        slotSelection.index * 250 +
                        numPreviousExpanded * 20 +
                        20;

                      newSlotSelections[slotSelection.index] = {
                        type: "boss",
                        color: zilchBotPreferredColor[BotType.Boss],
                        difficulty: "easy",
                        transitionData: {
                          slotId: crypto.randomUUID(),
                          transitionOrigin: {
                            x,
                            y: window.innerHeight + 120,
                          },
                        },
                      };
                      return { slotSelections: newSlotSelections };
                    });
                  }}
                  onSetDifficulty={(difficulty) => {
                    props.onSetSlotSelections((slotSelections) => {
                      const newSlotSelections = [...slotSelections];
                      let selection = newSlotSelections[slotSelection.index];
                      if (selection && selection.type === BotType.Boss) {
                        selection = {
                          ...selection,
                          difficulty,
                        };
                      }
                      newSlotSelections[slotSelection.index] = selection;
                      return { slotSelections: newSlotSelections };
                    });
                  }}
                  onSetTransitionConfig={(config, updateSlotColor) => {
                    props.onSetSlotSelections((slotSelections) => {
                      const newSlotSelections = [...slotSelections];
                      let selection = newSlotSelections[slotSelection.index];
                      if (selection) {
                        selection = {
                          ...selection,
                          transitionData: {
                            slotId: selection.transitionData.slotId,
                            avatar: config.avatar,
                            language: config.language,
                            name: config.name,
                            owner: config.owner,
                          },
                        };
                        if (updateSlotColor) {
                          selection.color = config.preferredColor;
                        }
                      }
                      newSlotSelections[slotSelection.index] = selection;
                      return { slotSelections: newSlotSelections };
                    });
                  }}
                  onDelete={() => {
                    props.onSetSlotSelections((slotSelections) => {
                      const newSlotSelections = slotSelections.map((value) => {
                        if (
                          value?.type === BotType.User &&
                          slotSelection.value.type === BotType.User &&
                          value.owner === slotSelection.value.owner &&
                          value.repo === slotSelection.value.repo
                        ) {
                          return null;
                        }

                        return value;
                      });

                      return { slotSelections: newSlotSelections };
                    });
                  }}
                  onScroll={(delta) => {
                    if (slotsContainerRef.current) {
                      slotsContainerRef.current.scrollBy({ left: delta });
                    }
                  }}
                />
              );
            })}
          </div>
          <div
            className={css.slot}
            ref={slotsContainerRef}
            onScroll={(e) => {
              starStore.slotsScrollXOffset.current = e.currentTarget.scrollLeft;
              if (botCardContainerRef.current) {
                botCardContainerRef.current.style.transform = `translateX(${-e
                  .currentTarget.scrollLeft}px)`;
                popoverRepositionersRef.current.forEach((reposition) =>
                  reposition()
                );
              }
            }}
            onWheel={(e) => {
              e.currentTarget.scrollBy({ left: e.deltaY });
            }}
          >
            {props.gameEngineInstance.gameConfig.slots.map((slot, index) => {
              const starCount = starStore.getStarCount(
                props.gameEngineInstance.gameConfig.gameId
              );

              const isUnimplementedOrOffline =
                props.outcome.status === "done" &&
                props.outcome.primary?.subjectIndices[0] === index &&
                (props.outcome.botOutcomes[index]?.outcome ===
                  BotOutcome.ConnectionProblem ||
                  props.outcome.botOutcomes[index]?.outcome ===
                    BotOutcome.Unimplemented);

              const isFirstDefeatedPracticeBotInGameWithUndefeatedBossBot =
                props.outcome.status === "done" &&
                starCount === 0 &&
                props.outcome.primary?.type === "victory-over-practice" &&
                slotSelectionsForUi.find(
                  (slot) => slot.value.type === BotType.Practice
                )?.index === index;

              let isFirstDefeatedBossBotWithStarsToGive = false;
              if (
                props.outcome.status === "done" &&
                props.outcome.primary?.type === "victory-over-boss"
              ) {
                const bossSlot = slotSelectionsForUi.find(
                  (slot) => slot.value.type === BotType.Boss
                );
                if (
                  bossSlot?.value.type === BotType.Boss &&
                  bossSlot.index === index
                ) {
                  const difficultyStars = {
                    easy: 1,
                    medium: 2,
                    hard: 3,
                  }[bossSlot.value.difficulty];
                  isFirstDefeatedBossBotWithStarsToGive =
                    difficultyStars - starCount > 0;
                }
              }

              return (
                <Slot
                  gameConfig={props.gameEngineInstance.gameConfig}
                  active={props.activeBots.has(index)}
                  key={index}
                  label={slot}
                  color={props.slotSelections[index]?.color ?? null}
                  gameOver={props.outcome.status !== "in-progress"}
                  isPrimaryOutcome={
                    props.outcome.status === "done" &&
                    (props.outcome.primary?.subjectIndices.includes(index) ??
                      false)
                  }
                  scrollIntoView={
                    isUnimplementedOrOffline ||
                    isFirstDefeatedPracticeBotInGameWithUndefeatedBossBot ||
                    isFirstDefeatedBossBotWithStarsToGive
                  }
                  isSpotToRightVacant={!!props.slotSelections[index + 1]}
                  botOutcome={
                    props.outcome.status === "done"
                      ? props.outcome.botOutcomes[index]?.outcome ?? null
                      : null
                  }
                  gameId={props.gameEngineInstance.gameConfig.gameId}
                  message={
                    props.slotMessage?.index === index
                      ? props.slotMessage.message
                      : null
                  }
                  onCloseMessage={props.onCloseSlotMessage}
                  showSwapButton={
                    showSwapIndices.includes(index) &&
                    props.outcome.status !== "in-progress"
                  }
                  onOpenBotSelector={() => setSelectForSlot(index)}
                  slotSelection={props.slotSelections[index] ?? null}
                  onRemove={() => {
                    props.onSetSlotSelections((slotSelections) => {
                      const newSlotSelections = [...slotSelections];
                      newSlotSelections[index] = null;
                      return { slotSelections: newSlotSelections };
                    });
                  }}
                  visible={
                    props.outcome.status !== "in-progress" ||
                    !!props.slotSelections[index]
                  }
                  showSlotActions={props.outcome.status !== "in-progress"}
                  onChangeColor={(color) => {
                    props.onSetSlotSelections((slotSelections) => {
                      const newSlotSelections = [...slotSelections];
                      const selection = newSlotSelections[index];
                      if (selection) {
                        newSlotSelections[index] = { ...selection, color };
                      }
                      return { slotSelections: newSlotSelections };
                    });
                  }}
                  onSwap={() => {
                    props.onSetSlotSelections((slotSelections) => {
                      const newSlotSelections = [...slotSelections];
                      newSlotSelections[index] = slotSelections[index + 1];
                      newSlotSelections[index + 1] = slotSelections[index];
                      return { slotSelections: newSlotSelections };
                    });
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
      <BotSelector
        open={selectForSlot !== null}
        showMultiplayerGuide={props.showMultiplayerGuide}
        onCloseMultiplayerGuide={props.onCloseMultiplayerGuide}
        createBot={{
          numSelected: props.slotSelections.filter((slot) => !!slot).length,
          getNewBotTransitionOrigin() {
            const numPreviousExpanded = getNumPreviousExpanded(
              showSwapIndices,
              selectForSlotRef.current ?? 0,
              props.outcome.status === "not-started"
            );

            return {
              x:
                (selectForSlotRef.current ?? 0) * 250 +
                numPreviousExpanded * 20 +
                20,
              y: window.innerHeight + 120,
            };
          },
        }}
        slotLabel={
          selectForSlotRef.current === null
            ? "Select bot"
            : props.gameEngineInstance.gameConfig.slots[
                selectForSlotRef.current
              ] ?? `Player ${selectForSlotRef.current}`
        }
        gameConfig={props.gameEngineInstance.gameConfig}
        onClose={() => setSelectForSlot(null)}
        onSetSlotSelection={(slotSelection) => {
          if (selectForSlotRef.current && slotSelection) {
            slotSelection.color = getSlotColor(
              selectForSlotRef.current,
              slotSelection.color,
              slotSelection,
              props.slotSelections
            );
          }

          if (
            selectForSlot === 1 &&
            selectionCountRef.current === 0 &&
            slotSelection?.type === BotType.Practice &&
            !hasBotsRef.current
          ) {
            onSetSlotSelection(slotSelection, {
              message: (
                <GuideContent
                  title="Next up..."
                  message="Pick a bot for this player slot to get started."
                />
              ),
              slotIndex: 0,
            });
          } else {
            onSetSlotSelection(slotSelection);
          }
        }}
      />
    </>
  );
}

function getNumPreviousExpanded(
  showSwapIndices: number[],
  slotIndex: number,
  setupMode: boolean
) {
  if (!setupMode) {
    return 0;
  }
  return showSwapIndices.filter((i) => i < slotIndex).length;
}

function convertToSlotSelectionsForUi(
  slotSelections: TransitionSlotSelection[]
): SlotSelectionForUi[] {
  return slotSelections.flatMap((slotSelection, index) => {
    if (!slotSelection) {
      return [];
    }

    const uiSlotSelection: SlotSelectionForUi = {
      value: slotSelection,
      index,
      pendingRemoval: false,
    };

    return [uiSlotSelection];
  });
}

function useSlotSelectionsForUi(
  slotSelections: TransitionSlotSelection[]
): SlotSelectionForUi[] {
  const [value, setValue] = useState(() => {
    return convertToSlotSelectionsForUi(slotSelections);
  });

  const timeoutsToClearRef = useRef(new Set<NodeJS.Timeout>());

  useEffect(() => {
    setValue((currentValue) => {
      const newValue = [...convertToSlotSelectionsForUi(slotSelections)];

      const currentIds = new Set(
        newValue.map((slot) => slot.value.transitionData.slotId)
      );

      const selectionsToRemove = currentValue.filter((slot) => {
        return (
          !slot.pendingRemoval &&
          !currentIds.has(slot.value.transitionData.slotId)
        );
      });

      const removalIds = new Set(
        selectionsToRemove.map((slot) => slot.value.transitionData.slotId)
      );

      selectionsToRemove.forEach((item) => {
        newValue.push({
          ...item,
          pendingRemoval: true,
        });
      });

      const timeout = setTimeout(() => {
        timeoutsToClearRef.current.delete(timeout);
        setValue((currentValue) => {
          return currentValue.filter((slot) => {
            return !removalIds.has(slot.value.transitionData.slotId);
          });
        });
      }, 300);

      timeoutsToClearRef.current.add(timeout);

      return newValue;
    });
  }, [slotSelections]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeoutsToClearRef.current.forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, []);

  return value;
}

function Bot({
  slotSelection,
  containerRef,
  slotsContainerRef,
  slotIndex,
  numPreviousExpanded,
  onScroll,
  remove,
  gameId,
  onDelete,
  onSetTransitionConfig,
  onSetDifficulty,
  gameOutcome,
  onSetRepositionPopover,
  isFirstPracticeBot,
  onSwitchForBossBot,
}: {
  slotSelection: NonNullable<TransitionSlotSelection>;
  containerRef: RefObject<HTMLDivElement | null>;
  slotsContainerRef: RefObject<HTMLDivElement | null>;
  slotIndex: number;
  numPreviousExpanded: number;
  onScroll(delta: number): void;
  gameId: GameId;
  remove: boolean;
  onDelete(): void;
  onSetDifficulty(difficulty: "easy" | "medium" | "hard"): void;
  onSetTransitionConfig(config: UserBotConfig, updateSlotColor: boolean): void;
  gameOutcome: GameOutcome;
  onSetRepositionPopover(reposition: null | (() => void)): void;
  isFirstPracticeBot: boolean;
  onSwitchForBossBot(): void;
}) {
  const isPrimaryOutcome =
    gameOutcome.status === "done" &&
    (gameOutcome.primary?.subjectIndices.includes(slotIndex) ?? false);

  const isFirstPrimaryOutcome =
    gameOutcome.status === "done" &&
    gameOutcome.primary?.subjectIndices[0] === slotIndex;

  const botOutcome =
    gameOutcome.status === "done"
      ? gameOutcome.botOutcomes[slotIndex]?.outcome ?? null
      : null;

  const numPreviousExpandedRef = useRef(numPreviousExpanded);
  if (!remove) {
    numPreviousExpandedRef.current = numPreviousExpanded;
  }
  const numPrevious = numPreviousExpandedRef.current;

  const botConfigListQuery = useUserBotConfigListQuery(
    slotSelection.type === "user" ? slotSelection.owner : null,
    gameId
  );

  let gameBot: GameBotConfig | null = null;
  if (
    slotSelection.type === BotType.Boss ||
    slotSelection.type === BotType.Practice
  ) {
    gameBot = {
      avatar: zilchBotAvatars[slotSelection.type],
      game: gameId,
      language: null,
      name: zilchBotName[slotSelection.type],
      preferredColor: zilchBotPreferredColor[slotSelection.type],
      type: slotSelection.type,
      v: 1,
      run: "",
    };
  }

  const botConfig =
    gameBot ??
    (botConfigListQuery.data === "nonexistent-user"
      ? null
      : botConfigListQuery.data?.bySlot(slotSelection) ?? null);

  const loadedSuccessfully = !(
    botConfig === null &&
    slotSelection.type === BotType.User &&
    !slotSelection.transitionData.avatar
  );

  const userStore = UserStore.use();

  const hasError =
    !gameBot &&
    !userStore.query.isLoading &&
    (botConfigListQuery.isError ||
      (botConfigListQuery.isSuccess &&
        !botConfigListQuery.isRefetching &&
        botConfig === null));

  const starStore = StarStore.use();

  const targetProperties = useMemo(() => {
    return {
      y: remove ? 200 : 40,
      x: slotIndex * 250 + numPrevious * 20 + 20,
      opacity: loadedSuccessfully || hasError ? 1 : 0,
    };
  }, [slotIndex, numPrevious, remove, loadedSuccessfully, hasError]);

  const [properties, setProperties] = useState(() => {
    if (
      !containerRef.current ||
      !slotSelection.transitionData.transitionOrigin ||
      !slotsContainerRef.current
    ) {
      return { ...targetProperties, opacity: 0 };
    }

    const containerRect = containerRef.current.getBoundingClientRect();
    const containerScroll = slotsContainerRef.current.scrollLeft;

    return {
      x:
        slotSelection.transitionData.transitionOrigin.x -
        (containerRect.x - containerScroll),
      y: slotSelection.transitionData.transitionOrigin.y - containerRect.y,
      opacity: 1,
    };
  });

  const initialTransitionInProgress = !useDelay(
    !containerRef.current || !slotSelection.transitionData.transitionOrigin
      ? 0
      : 500
  );

  useEffect(() => {
    const frame = requestAnimationFrame(() => {
      setProperties(targetProperties);
    });

    return () => {
      cancelAnimationFrame(frame);
    };
  }, [targetProperties]);

  const authenticated =
    userStore.query.isSuccess && userStore.query.data.type === "authenticated";

  return (
    <div
      className={css.botPositioner}
      style={{
        transform: `translateX(${properties.x}px) translateY(${properties.y}px)`,
        opacity:
          slotSelection.type === "user" && userStore.query.isLoading
            ? 0.5
            : properties.opacity,
        zIndex: initialTransitionInProgress ? 21 : 1,
        pointerEvents: remove || initialTransitionInProgress ? "none" : "all",
        transition: remove
          ? "all ease .3s"
          : "all cubic-bezier(.2,1.3,.4,1) .3s",
      }}
      onWheel={(e) => {
        onScroll(e.deltaX + e.deltaY);
      }}
    >
      <BotOutcomeDisplay
        type="icon"
        botOutcome={botOutcome}
        isPrimaryOutcome={isPrimaryOutcome}
      />
      {hasError ? (
        <div
          className={classes(css.slotBotLoadError, transitionInFromCss.bottom)}
        >
          <div className="bp4-text-muted">
            {authenticated ? "Unable to load" : "Sign in to load"}
          </div>
          <div
            style={{
              fontWeight: 600,
              whiteSpace: "nowrap",
              width: "calc(100% - 20px)",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center",
            }}
          >
            {getBotName({
              transition: true,
              slotSelection,
            })}
          </div>
        </div>
      ) : loadedSuccessfully ? (
        <BotCard
          botOutcome={botOutcome ?? undefined}
          gameId={gameId}
          setupGuide={
            botOutcome === BotOutcome.Unimplemented
              ? {
                  openImmediately: isFirstPrimaryOutcome,
                  type: "new",
                }
              : botOutcome === BotOutcome.ConnectionProblem
              ? {
                  openImmediately: isFirstPrimaryOutcome,
                  type: "offline",
                }
              : undefined
          }
          isGameDone={gameOutcome.status === "done"}
          showBossBotPrompt={
            gameOutcome.status === "done" &&
            gameOutcome.primary?.type === "victory-over-practice" &&
            starStore.getStarCount(gameId) === 0 &&
            slotSelection.type === BotType.Practice
              ? isFirstPracticeBot
                ? "immediately"
                : true
              : false
          }
          onSetRepositionPopover={onSetRepositionPopover}
          color={slotSelection.color}
          botConfig={
            (!gameBot && botConfigListQuery.isLoading) || botConfig === null
              ? { transition: true, slotSelection }
              : { transition: false, value: botConfig, slotSelection }
          }
          onSwitchForBossBot={onSwitchForBossBot}
          onSetDifficulty={onSetDifficulty}
          onInvalidateConfig={(reason, operation, newConfig) => {
            if (reason === "removal") {
              onDelete();
              if (operation) {
                operation.finally(() => {
                  botConfigListQuery.refetch();
                });
              } else {
                botConfigListQuery.refetch();
              }
            } else {
              if (newConfig) {
                onSetTransitionConfig(
                  newConfig,
                  reason === "avatar" &&
                    newConfig.preferredColor !== slotSelection.color
                );
              }

              if (operation) {
                operation.finally(() => {
                  botConfigListQuery.refetch();
                });
              } else {
                botConfigListQuery.refetch();
              }
            }
          }}
        />
      ) : (
        <div className={css.spinnerContainer}>
          <Spinner size={20} />
        </div>
      )}
    </div>
  );
}

function getSlotColor(
  index: number,
  preferredColor: BotColor,
  slotSelection: NonNullable<SlotSelection>,
  slotSelections: SlotSelection[]
): BotColor {
  const botAlreadySelected = !!slotSelections.find((selection) => {
    return (
      (selection?.type === BotType.Boss &&
        slotSelection.type === BotType.Boss) ||
      (selection?.type === BotType.Practice &&
        slotSelection.type === BotType.Practice) ||
      (selection?.type === BotType.User &&
        slotSelection.type === BotType.User &&
        selection.owner === slotSelection.owner &&
        selection.repo === slotSelection.repo)
    );
  });

  if (!botAlreadySelected) {
    return preferredColor;
  }

  const availableColors = new Set(Object.keys(BotColor) as BotColor[]);

  slotSelections.forEach((selection, slotIndex) => {
    if (selection && index !== slotIndex) {
      availableColors.delete(selection.color);
    }
  });

  if (availableColors.has(preferredColor)) {
    return preferredColor;
  }

  return sample(Array.from(availableColors)) ?? preferredColor;
}
