import {
  Button,
  Colors,
  Icon,
  type IconName,
  InputGroup,
  Classes,
  Spinner,
} from "@blueprintjs/core";
import { useQuery } from "@tanstack/react-query";
import { plural } from "@zilch/plural";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import type { UserResponse } from "../../../backend/procedures/user";
import { api } from "../../api";
import { PremiumStore } from "../../stores/PremiumStore";
import { StarStore } from "../../stores/StarStore";
import { UserStore } from "../../stores/UserStore";
import { toaster } from "../../toaster";
import { AuthenticatedView } from "../common/AuthenticatedView";
import { Popover } from "../common/Popover";
import { SecondaryScreen } from "../common/SecondaryScreen";
import { useOperation } from "../common/useOperation";
import { ManageAdminsButton } from "./ManageAdminsButton";
import { Organization } from "./Organization";
import { OrganizationList } from "./OrganizationList";
import { Section } from "./Section";
import { Subscription } from "./Subscription";
import { SubSection } from "./SubSection";
import { SubSectionItems } from "./SubSectionItems";
import { Teams } from "./Teams";
import { PromptStore } from "../../stores/PromptStore";
import { AdminSubscriptionPrompt } from "./AdminSubscriptionPrompt";
import { routes } from "../../router";
import type { Route } from "type-route";
import type { GameId } from "@zilch/game-config";
import { GiRoundStar } from "react-icons/gi";

export function AccountScreen({
  route,
}: {
  route: Route<typeof routes.account>;
}) {
  return (
    <AuthenticatedView>
      {(user) => (
        <SecondaryScreen title="Account">
          <PremiumSection user={user} route={route} />
          <DataSection />
          <AdminView user={user}>
            <AdminSection user={user} />
          </AdminView>
        </SecondaryScreen>
      )}
    </AuthenticatedView>
  );
}

function AdminView(props: { user: UserResponse; children: React.ReactNode }) {
  const isAdminQuery = useQuery(
    ["admin.isAdmin", props.user.userId],
    ({ signal }) => api.admin.isAdmin.query(void 0, { signal }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  if (!isAdminQuery.isSuccess || !isAdminQuery.data) {
    return null;
  }

  return <>{props.children}</>;
}

function AdminSection(props: { user: UserResponse }) {
  const userStore = UserStore.use();
  const [login, setLogin] = useState("");

  const impersonateOperation = useOperation({
    async run(login: string) {
      if (login.trim().length === 0) {
        return;
      }
      await userStore.impersonate(login.trim());
      setLogin("");
    },
  });

  const stopImpersonationOperation = useOperation({
    async run() {
      await userStore.stopImpersonation();
      setLogin("");
    },
  });

  const prompt = PromptStore.usePrompt();

  const createFreeSubscriptionOperation = useOperation({
    async run() {
      const seats = await prompt<number>((props) => {
        return (
          <AdminSubscriptionPrompt
            hideDeleteAction
            onResolve={(result) => {
              if (result.action === "setSeatCount") {
                props.resolve(result.value);
              } else {
                props.resolve(null);
              }
            }}
          />
        );
      });

      if (seats === null) {
        return;
      }

      await api.admin.createFreeSubscription.mutate({ seats });
      await userStore.query.refetch();
      toaster.show({
        intent: "success",
        message: "Subscription created",
      });
    },
  });

  return (
    <Section title="Admin" collapsible>
      <SubSection title="Manage Admins">
        <ManageAdminsButton viewerUserId={props.user.userId} />
      </SubSection>
      <SubSection title="Manage Organization Plans">
        <p className="bp4-text-muted">
          Organization plans give Premium access to all users that can verify
          they have an email under a valid organization domain.
        </p>
        <OrganizationList />
      </SubSection>
      <SubSection title="Create Free Subscription">
        <p className="bp4-text-muted">
          Create a subscription without needing to input payment details.
        </p>
        <Button
          onClick={createFreeSubscriptionOperation.run}
          loading={createFreeSubscriptionOperation.inProgress}
        >
          Create free subscription
        </Button>
      </SubSection>
      <SubSection title="Set Stars">
        <p className="bp4-text-muted">
          Set stars won without needing to defeat boss bots.
        </p>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <StarSetter gameId="table-tennis" gameName="Table Tennis" />
          <StarSetter gameId="tic-tac-toe" gameName="Tic-Tac-Toe" />
          <StarSetter gameId="chess" gameName="Chess" />
        </div>
      </SubSection>
      <SubSection title="Impersonate">
        <p className="bp4-text-muted">
          Impersonate a user. This is useful for testing various scenarios but
          note the impersonation is not 100% accurate as access to the
          impersonated user's GitHub account is still restricted. Your own
          GitHub access token will be used instead of the impersonated user's
          token.
        </p>
        {props.user.impersonatorUserId ? (
          <Button
            loading={stopImpersonationOperation.inProgress}
            onClick={stopImpersonationOperation.run}
          >
            Stop Impersonating
          </Button>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              impersonateOperation.run(login);
            }}
          >
            <InputGroup
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              large
              leftIcon="hat"
              placeholder="Github username"
              disabled={impersonateOperation.inProgress}
              rightElement={
                <Button
                  minimal
                  rightIcon="arrow-right"
                  type="submit"
                  loading={impersonateOperation.inProgress}
                >
                  Impersonate
                </Button>
              }
            />
          </form>
        )}
      </SubSection>
    </Section>
  );
}

function StarSetter(props: { gameId: GameId; gameName: string }) {
  const starStore = StarStore.use();
  const starCount = starStore.getStarCount(props.gameId);

  const renderStar = (count: number) => {
    return (
      <Button
        active={starCount === count}
        minimal
        large
        onClick={() => {
          starStore.setStarCount(props.gameId, count, true);
        }}
        icon={
          count === 0 ? (
            "disable"
          ) : (
            <GiRoundStar
              color={starCount === count ? Colors.GOLD4 : Colors.GRAY4}
            />
          )
        }
        style={{ fontWeight: 600 }}
      >
        {count}
      </Button>
    );
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <div
        style={{ width: "100px", fontWeight: 600, color: Colors.LIGHT_GRAY4 }}
      >
        {props.gameName}
      </div>
      {renderStar(0)}
      {renderStar(1)}
      {renderStar(2)}
      {renderStar(3)}
    </div>
  );
}

function PremiumSummary({ user }: { user: UserResponse }) {
  let icon: IconName = "endorsed";
  let color = Colors.GREEN5;
  let text = "Premium Active";

  if (!user.hasPremium && !user.likelyHasPremium) {
    icon = "error";
    color = Colors.RED5;
    text = "Premium Inactive";
  }

  const { premiumSources, query } = UserStore.use();

  if (
    (user.likelyHasPremium || user.hasPremium) &&
    premiumSources.size === 1 &&
    premiumSources.has("organization-plan") &&
    user.organizationEmail?.status === "expiring-soon"
  ) {
    icon = "warning-sign";
    color = Colors.ORANGE5;
    text = "Premium Active (email expiring soon)";
  }

  const reasons: string[] = [];

  if (premiumSources.has("active-subscription")) {
    reasons.push("Subscription owner");
  }

  let multipleTeamMemberSubscriptions = false;

  if (premiumSources.has("team-membership")) {
    const teamAdminSubscriptionCount =
      user.subscriptions?.filter((subscription) =>
        subscription.teams.some((team) => team.role === "admin")
      ).length ?? 0;
    const teamMemberSubscriptionCount =
      user.subscriptions?.filter((subscription) =>
        subscription.teams.some((team) => team.role === "member")
      ).length ?? 0;
    const teamSubscriptionCount =
      teamAdminSubscriptionCount + teamMemberSubscriptionCount;

    if (teamSubscriptionCount > 1) {
      multipleTeamMemberSubscriptions = true;
      reasons.push(
        `Team ${
          teamAdminSubscriptionCount > 0 && teamMemberSubscriptionCount > 0
            ? "admin/member"
            : teamAdminSubscriptionCount > 0
            ? "admin"
            : "member"
        } (${plural(teamMemberSubscriptionCount, "subscription")})`
      );
    } else {
      reasons.push(
        `Team ${teamAdminSubscriptionCount > 0 ? "admin" : "member"}`
      );
    }
  }

  if (premiumSources.has("organization-plan")) {
    reasons.push("Organization provided");
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        gap: "10px",
        marginTop: "2px",
      }}
    >
      {query.isFetching ? (
        <Spinner size={20} />
      ) : (
        <Icon
          icon={icon}
          color={color}
          size={20}
          style={{ marginTop: reasons.length > 0 ? "2px" : "0px" }}
        />
      )}
      <div>
        <div style={{ fontWeight: 700, fontSize: "16px", color }}>{text}</div>
        {reasons.map((reason, index) => (
          <div key={index} style={{ color }}>
            {reason}
          </div>
        ))}
        {(reasons.length > 1 || multipleTeamMemberSubscriptions) && (
          <div className="bp4-text-muted" style={{ marginTop: "4px" }}>
            Premium access through multiple sources is unusual. Consider
            reviewing your access to ensure this is intentional.
          </div>
        )}
      </div>
    </div>
  );
}

function DataSection() {
  const [resetting, setResetting] = useState(false);
  const starStore = StarStore.use();

  return (
    <Section title="Data">
      <SubSection title="Reset Progress">
        <p className="bp4-text-muted" style={{ marginBottom: "15px" }}>
          Erase all game data. You'll lose any stars you've earned and defeated
          boss bots will be undefeated once more. Resetting your progress will
          not remove any bots you've already created.
        </p>
        <Popover
          isOpen={resetting ? false : undefined}
          content={
            <div style={{ padding: "15px", width: "260px" }}>
              <b>This action is irreversible.</b>
              <p style={{ marginBottom: "15px", marginTop: "5px" }}>
                Your progress data will be deleted. It will not be recoverable.
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button className={Classes.POPOVER_DISMISS}>Cancel</Button>
                <Button
                  intent="danger"
                  className={Classes.POPOVER_DISMISS}
                  onClick={() => {
                    api.manage.resetProgress
                      .mutate()
                      .then(() => {
                        starStore.reset();
                        toaster.show({
                          intent: "success",
                          message: "Progress reset.",
                        });
                      })
                      .catch((error) => {
                        console.error(error);
                        toaster.show({
                          intent: "danger",
                          message: "Unexpected error encountered.",
                        });
                      })
                      .finally(() => {
                        setResetting(false);
                      });
                  }}
                >
                  Reset Progress
                </Button>
              </div>
            </div>
          }
        >
          <Button loading={resetting}>Reset Progress</Button>
        </Popover>
      </SubSection>
    </Section>
  );
}

function PremiumSection(props: {
  user: UserResponse;
  route: Route<typeof routes.account>;
}) {
  let content: React.ReactNode;

  const premiumStore = PremiumStore.use();
  const premiumStoreRef = useRef(premiumStore);
  premiumStoreRef.current = premiumStore;
  const routeRef = useRef(props.route);
  routeRef.current = props.route;

  useEffect(() => {
    if (routeRef.current.params.premium) {
      premiumStoreRef.current.setSection("feature-overview");
      routes.account().replace();
    }
  }, []);

  if (
    (props.user.subscriptions ?? []).length > 0 ||
    props.user.organizationEmail !== null
  ) {
    content = (
      <>
        <PremiumSummary user={props.user} />
        <Teams user={props.user} />
        <Subscriptions user={props.user} />
        <Organization user={props.user} />
      </>
    );
  } else {
    content = <NoPremium />;
  }

  return <Section title="Premium">{content}</Section>;
}

function Subscriptions(props: { user: UserResponse }) {
  const ownedSubscriptions = (props.user.subscriptions ?? []).filter(
    (subscription) => subscription.ownerId === props.user.userId
  );
  const warnBuySubscription =
    ownedSubscriptions.length > 0 &&
    ownedSubscriptions.every(
      (subscription) =>
        subscription.status === "active" || subscription.status === "incomplete"
    );
  const premiumStore = PremiumStore.use();
  const prompt = PromptStore.usePrompt();

  return (
    <SubSection
      title={ownedSubscriptions.length > 1 ? "Subscriptions" : "Subscription"}
    >
      <p className="bp4-text-muted">
        Subscription owners unlock Premium for themselves and can give Premium
        to others through teams.
      </p>
      <SubSectionItems>
        {ownedSubscriptions.map((subscription) => {
          return (
            <Subscription subscription={subscription} key={subscription.id} />
          );
        })}
        <Button
          onClick={async () => {
            if (warnBuySubscription) {
              const result = await prompt<"continue">((props) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      padding: "30px",
                      flexDirection: "column",
                      gap: "28px",
                    }}
                  >
                    <div style={{ fontSize: "20px" }}>
                      Are you sure you want to purchase another subscription?
                      Adding seats to an existing subscription is typically
                      preferred.
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        large
                        intent="primary"
                        onClick={() => props.resolve(null)}
                      >
                        Cancel
                      </Button>
                      <Button large onClick={() => props.resolve("continue")}>
                        Buy Subscription
                      </Button>
                    </div>
                  </div>
                );
              });

              if (result !== "continue") {
                return;
              }
            }
            premiumStore.setSection("calculate-price");
          }}
        >
          Buy {warnBuySubscription ? "additional " : ""}subscription{" "}
          {warnBuySubscription && (
            <span className="bp4-text-muted">(not recommended)</span>
          )}
        </Button>
        <div className="bp4-text-muted">
          └ Need a hand?{" "}
          <a href="mailto:support@zilch.dev">support@zilch.dev</a>
        </div>
      </SubSectionItems>
    </SubSection>
  );
}

function NoPremium() {
  const premiumStore = PremiumStore.use();

  return (
    <SubSection title="Get Premium">
      <p style={{ marginBottom: "15px" }} className="bp4-text-muted">
        Looks like you don't have Premium yet. Premium unlocks all games,
        multiplayer mode, game maker and more. You may even qualify for free
        Premium through your organization.
      </p>
      <Button
        intent="primary"
        onClick={() => {
          premiumStore.setSection("feature-overview");
        }}
      >
        Learn more about Premium
      </Button>
    </SubSection>
  );
}
