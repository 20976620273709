.container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 40px 60px;
  padding-top: 64px;
}

.seatsInputOuterContainer {
  padding: 30px;
  background: var(--dark-gray2);
  border-radius: 8px;
}

.titleContainer {
  position: absolute;
  transition: all ease 0.3s 0.1s;
  left: 60px;
  right: 60px;
}

.titleContainerHidden {
  opacity: 0;
  transition: all ease 0.3s 0s;
  pointer-events: none;
  transform: scale(0.9) translateY(20px);
}

.title {
  font-size: 40px;
  text-align: center;
  font-weight: 900;
  margin-bottom: 8px;
}

.subTitle {
  font-size: 28px;
  color: var(--gray4);
  text-align: center;
  font-weight: 500;
}

.primaryActionContainer {
  margin: 0px 40px;
  margin-top: 30px;
  text-align: center;
}

.primaryAction {
  padding: 18px !important;
  font-size: 20px !important;
  border-radius: 5px !important;
  position: relative;
  overflow: hidden;
  height: 59px;
}

.primaryActionText {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all cubic-bezier(0.5, 1.5, 0.5, 1) 0.4s;
}

.primaryActionTextTopHidden {
  opacity: 0;
  transform: translateY(70px);
}

.primaryActionTextBottomHidden {
  opacity: 0;
  transform: translateY(-70px);
}

.bottomSection {
  position: absolute;
  bottom: 30px;
  right: 60px;
  left: 60px;
}

.navigateToOrganizationCheckSection {
  line-height: 35px;
  height: 35px;
  transition: all ease 0.3s;
  overflow: hidden;
}

.navigateToOrganizationCheckSectionHidden {
  height: 20px;
  line-height: 20px;
  opacity: 0;
  pointer-events: none;
}
