import type { editor } from "monaco-editor";

import { Colors } from "@blueprintjs/colors";

export const monacoZilchTheme: editor.IStandaloneThemeData = {
  base: "vs-dark",
  colors: {
    "editor.background": Colors.DARK_GRAY1,
    "editor.foreground": Colors.GRAY5,
  },
  inherit: false,
  rules: [
    token("attribute.name", Colors.ORANGE4),
    token("attribute.value", Colors.LIME4),
    token("comment", Colors.GRAY2),
    token("delimiter", Colors.LIGHT_GRAY3),
    token("function", Colors.BLUE4),
    token("identifier", Colors.TURQUOISE3),
    token("keyword", Colors.VIOLET4),
    token("number", Colors.ROSE4),
    token("operator", Colors.VIOLET5),
    token("string", Colors.LIME4),
    token("tag", Colors.FOREST3),
    token("type.identifier", Colors.GOLD5),
  ],
};

function token(tokenName: string, color: string) {
  return { token: tokenName, foreground: color };
}
