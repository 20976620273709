import { Button, Colors, Icon, type IconName } from "@blueprintjs/core";
import { fireConfetti } from "@zilch/confetti";
import { classes, delayCss, transitionInFromCss } from "@zilch/css-utils";
import React, { useEffect } from "react";
import { GiStarsStack } from "react-icons/gi";
import { routes } from "../../router";
import { PremiumStore } from "../../stores/PremiumStore";
import css from "./FeatureOverviewSection.module.css";
import { useTransitionStyle } from "./useTransitionStyle";
import premiumIconPng from "./premiumIcon.png";
import { transitionScreen } from "../../transitionScreen";

export function FeatureOverviewSection(props: {
  show: boolean;
  alreadyHasPremium: boolean;
}) {
  const premiumStore = PremiumStore.use();

  useEffect(() => {
    if (props.alreadyHasPremium) {
      fireConfetti();
    }
  }, [props.alreadyHasPremium]);

  return (
    <div
      className={css.featureOverview}
      style={useTransitionStyle(props.show, "top")}
    >
      {props.alreadyHasPremium && (
        <div className={css.premiumIcon}>
          <GiStarsStack size={64} color={Colors.GRAY5} />
        </div>
      )}
      <div
        className={css.premiumTitle}
        style={{ marginBottom: props.alreadyHasPremium ? "50px" : "40px" }}
      >
        {props.alreadyHasPremium ? "Welcome to Premium!" : "Premium has it all"}
      </div>
      <div className={css.featureList}>
        <Feature
          iconName="unlock"
          title="All boss bots unlocked"
          wide
          href="/premium#all-boss-bots-unlocked"
        >
          <img
            style={{
              position: "absolute",
              pointerEvents: "none",
              bottom: 2,
              right: 40,
              height: "86px",
            }}
            src={premiumIconPng}
          />
        </Feature>
        <Feature
          iconName="people"
          title="Multiplayer"
          href="/premium#multiplayer"
        />
        <Feature
          iconName="new-grid-item"
          title="Bot avatar designer"
          href="/premium#bot-avatar-designer"
        />
        <Feature
          iconName="many-to-one"
          title="Tournament mode"
          href="/premium#tournament-mode"
        />
        <Feature
          iconName="chart"
          title="Leaderboard"
          comingSoon
          href="/premium#leaderboard"
        />
        <Feature
          iconName="application"
          title="Game maker"
          href="/premium#game-maker"
        />
        <Feature
          iconName="tag"
          comingSoon
          title="Merch discounts"
          href="/premium#merch-discounts"
        />

        {!props.alreadyHasPremium && (
          <>
            <Action
              action={
                <Button
                  large
                  intent="primary"
                  onClick={() => {
                    premiumStore.setSection("email-check");
                  }}
                >
                  Check for free access
                </Button>
              }
              description={
                <>
                  <b style={{ color: Colors.WHITE }}>Free Premium</b> is
                  available to students and employees of select organizations.
                </>
              }
            />
            <Action
              action={
                <Button
                  large
                  onClick={() => {
                    premiumStore.setSection("calculate-price");
                  }}
                >
                  Buy subscription
                </Button>
              }
              description={
                <>
                  Zilch is better together. That's why every Premium
                  subscription comes with a two seat minimum.
                </>
              }
            />
          </>
        )}
      </div>
      {props.alreadyHasPremium && (
        <div className={css.getStartedContainer}>
          <Button
            large
            intent="primary"
            className={css.getStarted}
            fill
            onClick={() => {
              premiumStore.setSection(null);
            }}
          >
            Get Started
          </Button>
        </div>
      )}
      {props.alreadyHasPremium && (
        <Button
          minimal
          rightIcon="arrow-right"
          style={{ position: "absolute", bottom: 10, right: 10 }}
          className={classes(transitionInFromCss.bottom, delayCss["500"])}
          onClick={() => {
            premiumStore.setSection(null);
            transitionScreen(routes.account());
          }}
        >
          <span className="bp4-text-muted">Manage Plan</span>
        </Button>
      )}
      {!props.alreadyHasPremium && (
        <div className={classes("bp4-text-muted", css.featureFooter)}>
          <div>Want Premium for your entire school/business?</div>
          <a href="/premium/organization" target="_blank" rel="noreferrer">
            Learn about the Zilch Organization plan.
          </a>
        </div>
      )}
    </div>
  );
}

function Action(props: {
  action: React.ReactNode;
  description: React.ReactNode;
}) {
  return (
    <div className={css.actionContainer}>
      {props.action}
      <div className="bp4-text-muted">{props.description}</div>
    </div>
  );
}

function Feature(props: {
  title: string;
  comingSoon?: boolean;
  wide?: boolean;
  iconName: IconName;
  children?: React.ReactNode;
  href: string;
}) {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={props.href}
      className={css.feature}
      style={{
        gridColumn: props.wide ? "1 / span 2" : undefined,
      }}
    >
      <div className={css.featureContent}>
        <Icon icon={props.iconName} className={css.featureLeftIcon} />
        <span>{props.title}</span>
      </div>
      <Icon className={css.featureRightIcon} icon="chevron-right" />
      {props.comingSoon && <div className={css.soon}>Coming soon</div>}
      {props.children}
    </a>
  );
}
