.playbook {
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-right: -1px !important;
}

.menuItemWithTip {
  outline: 2px solid var(--blue5) !important;
  outline-offset: 2px !important;
}

.attentionDot {
  position: absolute;
  width: 10px;
  height: 10px;
  right: -3px;
  top: -3px;
  border-radius: 100%;
  transition: all cubic-bezier(0.5, 1.8, 0.5, 1) 0.3s 0.3s;
  box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2);
  z-index: 2;
  background: var(--blue4);
  opacity: 0;
  transform: scale(0);
}

.attentionDotVisible {
  opacity: 1;
  transform: scale(1);
}
