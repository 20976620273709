import { sample } from "lodash";

import { generateBotAvatar } from "@zilch/bot-avatar";
import { BotColor, type BotAvatarAndColor } from "@zilch/bot-models";

const possibleBotColors = Object.keys(BotColor) as BotColor[];

export function generateBotAvatarAndColor(
  availableColors = possibleBotColors
): BotAvatarAndColor {
  return {
    avatar: generateBotAvatar(),
    // `sample` only return undefined if the source array has length zero so
    // since we know `possibleBotColors.length` > 0 a non-null assertion here
    // is correct.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    preferredColor: sample(availableColors) ?? sample(possibleBotColors)!,
  };
}
