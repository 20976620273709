import { avatarSerializer } from "./avatarSerializer";
import { expandGrid } from "./expandGrid";
import { getShapes } from "./getShapes";
import { padGrid } from "./padGrid";

export function getNumSquaresFilled(avatar: string) {
  return getShapes(
    expandGrid(padGrid(avatarSerializer.fromString(avatar) ?? []))
  )
    .map((row) => row.length)
    .reduce((current, total) => current + total, 0);
}
