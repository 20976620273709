import { Button } from "@blueprintjs/core";
import { classes } from "@zilch/css-utils";
import { useWindowSizeDerivedValue } from "@zilch/window-size";
import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useStorage } from "../../useStorage";
import css from "./Divider.module.css";

const INITIAL_BOT_TERMINALS_SIZE = 450;
const MIN_ARENA_SIZE = 70;
const MIN_BOT_TERMINALS_SIZE = 300;

interface Props {
  left: React.ReactNode;
  right: React.ReactNode;
  disabled: boolean;
  shouldOpenIfClosed: boolean;
  onChangeRightSideWidth(width: number): void;
}

export function Divider(props: Props) {
  const [dragging, setDragging] = useState(false);
  const storage = useStorage();
  const storageRef = useRef(storage);
  storageRef.current = storage;
  const [targetWidth, setTargetWidth] = useState(() => {
    return storage.divider.width.get() ?? INITIAL_BOT_TERMINALS_SIZE;
  });
  const restoreWidthRef = useRef(targetWidth);
  const windowSize = useWindowSizeDerivedValue();
  const maxWidth = windowSize.width - MIN_ARENA_SIZE;

  let width = targetWidth;
  const cursor =
    targetWidth < MIN_BOT_TERMINALS_SIZE ? "w-resize" : "col-resize";

  if (width < MIN_BOT_TERMINALS_SIZE / 2) {
    width = 0;
  } else if (width < MIN_BOT_TERMINALS_SIZE) {
    width = MIN_BOT_TERMINALS_SIZE;
  } else if (width > maxWidth) {
    width = maxWidth;
  }

  const isClosedRef = useRef(width === 0);
  isClosedRef.current = width === 0;

  useEffect(() => {
    if (props.shouldOpenIfClosed && isClosedRef.current) {
      setTargetWidth(INITIAL_BOT_TERMINALS_SIZE);
    }
  }, [props.shouldOpenIfClosed]);

  useEffect(() => {
    if (dragging) {
      return;
    }

    storageRef.current.divider.width.set(targetWidth);
  }, [dragging, targetWidth]);

  const widthRef = useRef(width);
  widthRef.current = width;
  useEffect(() => {
    setTargetWidth(widthRef.current);
  }, [windowSize.width]);

  const startDrag = useCallback(() => {
    setDragging(true);
    restoreWidthRef.current = widthRef.current;

    const handleMouseUp = () => {
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousemove", handleMouseMove);
      setDragging(false);
    };

    const handleMouseMove = (event: MouseEvent) => {
      setTargetWidth(window.innerWidth - event.clientX);
    };

    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousemove", handleMouseMove);
  }, []);

  useEffect(() => {
    if (dragging) {
      document.body.classList.add("resizing");
      document.body.classList.remove(
        cursor === "w-resize" ? "col-resize" : "w-resize"
      );
      document.body.classList.add(cursor);
    } else {
      document.body.classList.remove("resizing");
      document.body.classList.remove("col-resize");
      document.body.classList.remove("w-resize");
    }
  }, [dragging, cursor]);

  let transition = "none";

  if (!dragging || targetWidth < MIN_BOT_TERMINALS_SIZE) {
    transition = "all ease .2s";
  }

  const disabledAwareWidth = props.disabled ? 0 : width;

  const onChangeRightSideWidthRef = useRef(props.onChangeRightSideWidth);
  useEffect(() => {
    onChangeRightSideWidthRef.current(disabledAwareWidth);
  }, [disabledAwareWidth]);

  return (
    <div className={css.container}>
      <div
        className={classes(css.section, css.leftSection)}
        style={{ width: `calc(100vw - ${disabledAwareWidth}px)`, transition }}
      >
        {props.left}
      </div>
      <div
        className={classes(css.section, css.rightSection)}
        style={{ width: `${disabledAwareWidth}px`, transition }}
      >
        <Button
          className={classes(
            css.toggle,
            (props.disabled || width > 0) && css.toggleHidden
          )}
          small
          tabIndex={disabledAwareWidth > 0 ? -1 : 0}
          icon="chevron-left"
          onClick={() => {
            setTargetWidth(
              Math.max(restoreWidthRef.current, MIN_BOT_TERMINALS_SIZE)
            );
          }}
        />
        <div
          className={css.handle}
          onMouseDown={props.disabled ? undefined : startDrag}
          style={{
            cursor: props.disabled ? undefined : cursor,
            pointerEvents: props.disabled ? "none" : undefined,
          }}
        />
        {props.right}
      </div>
    </div>
  );
}
