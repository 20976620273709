@keyframes InAnimation {
  from {
    opacity: 0;
    transform: scale(0) translateY(200px);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.pickGameMessage {
  font-weight: 700;
  font-size: 28px;
  padding: 40px;
  margin-top: 20px;
  max-width: 1000px;
  color: var(--light-gray2);
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 1);
  text-align: center;
  animation: InAnimation 0.7s cubic-bezier(0.5, 1.5, 0.5, 1) both;
  animation-delay: 0.5s;
}

.logo {
  animation: InAnimation 1s cubic-bezier(0.5, 1.5, 0.5, 1) both;
  animation-delay: 0.1s;
}
