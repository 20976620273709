import { Button } from "@blueprintjs/core";
import { useState } from "react";
import { Team } from "../../../backend/db/subscription";
import type { SubscriptionWithTeams } from "../../../backend/db/subscription";
import type { UserResponse } from "../../../backend/procedures/user";
import { UserStore } from "../../stores/UserStore";
import { CreateTeam } from "./CreateTeam";
import { SubSection } from "./SubSection";
import { SubSectionItems } from "./SubSectionItems";
import { DetailedButton } from "./DetailedButton";
import { UpdateTeam } from "./UpdateTeam";
import { Popover } from "../common/Popover";
import { PremiumStore } from "../../stores/PremiumStore";
import { useDelay } from "@zilch/delay";
import css from "./Teams.module.css";

interface Props {
  user: UserResponse;
}

export type TeamDialogState =
  | { type: "new" }
  | { type: "existing"; teamId: string };

export function Teams(props: Props) {
  const userStore = UserStore.use();

  const subscriptions = props.user.subscriptions ?? [];
  const [teamDialogState, setTeamDialog] = useState<TeamDialogState | null>(
    null
  );

  const teams = subscriptions.flatMap((subscription) => {
    return subscription.teams.map((team) => {
      return {
        ...team,
        subscription,
      };
    });
  });

  const isSubscriptionOwnerOrTeamAdmin = subscriptions.some(
    (subscription) =>
      subscription.ownerId === props.user.userId ||
      subscription.teams.some((team) => team.role === "admin")
  );

  const ownedSubscriptions = subscriptions.filter(
    (subscription) => subscription.ownerId === props.user.userId
  );

  const showCreateTeamPrompt =
    ownedSubscriptions.length > 0 &&
    ownedSubscriptions.every((subscription) => {
      return subscription.teams.length === 0;
    });

  const showCreateTeamPromptDelay = useDelay(300);

  const premiumStore = PremiumStore.use();

  return (
    <>
      <SubSection title="Teams">
        <p className="bp4-text-muted">
          Subscription owners and team admins can give Premium to friends,
          colleagues, students, and classmates through teams.
        </p>
        <SubSectionItems>
          {teams.map((team) => {
            return (
              <Team
                key={team.id}
                team={team}
                subscription={team.subscription}
                onOpen={() => {
                  setTeamDialog({ type: "existing", teamId: team.id });
                }}
              />
            );
          })}
          {isSubscriptionOwnerOrTeamAdmin && (
            <Popover
              position="left"
              background="blue"
              isOpen={
                premiumStore.section === null &&
                showCreateTeamPrompt &&
                showCreateTeamPromptDelay &&
                teamDialogState === null
              }
              portalClassName={css.popover}
              enforceFocus={false}
              content={
                <div style={{ padding: "10px", width: "124px" }}>
                  <div style={{ fontWeight: 600 }}>Next step:</div>
                  Share Premium with others!
                </div>
              }
            >
              <Button
                intent={showCreateTeamPrompt ? "primary" : "none"}
                onClick={() => {
                  setTeamDialog({ type: "new" });
                }}
              >
                Create Team
              </Button>
            </Popover>
          )}
          {teams.length === 0 && !isSubscriptionOwnerOrTeamAdmin && (
            <div className="bp4-text-muted">You're not part of any teams.</div>
          )}
        </SubSectionItems>
      </SubSection>
      <UpdateTeam
        user={props.user}
        teamDialogState={teamDialogState}
        onClose={() => {
          setTeamDialog(null);
        }}
        onUpdate={(close) => {
          if (close) {
            setTeamDialog(null);
          }
          userStore.query.refetch();
        }}
      />
      <CreateTeam
        user={props.user}
        teamDialogState={teamDialogState}
        onClose={() => {
          setTeamDialog(null);
        }}
        onCreate={(teamId) => {
          userStore.query.refetch();
          setTeamDialog({ teamId, type: "existing" });
        }}
      />
    </>
  );
}

function Team(props: {
  team: Team;
  subscription: SubscriptionWithTeams;
  onOpen(): void;
}) {
  const limitExceeded =
    props.subscription.usedSeatCount > props.subscription.seatCount;
  const subscriptionInactive =
    props.subscription.status !== "active" &&
    props.subscription.status !== "incomplete";

  let secondLineText = { owner: "Owner", admin: "Admin", member: "Member" }[
    props.team.role
  ];

  if (subscriptionInactive) {
    secondLineText = "Subscription Inactive";
  } else if (limitExceeded) {
    secondLineText = "Limit Exceeded";
  }

  return (
    <DetailedButton
      titleLabel="Team Name"
      title={props.team.name}
      icon="people"
      detailsIntent={limitExceeded || subscriptionInactive ? "danger" : "none"}
      details={
        <>
          <div>
            <b>{props.team.memberCount}</b>{" "}
            {props.team.memberCount === 1 ? "member" : "members"}
          </div>
          <div>{secondLineText}</div>
        </>
      }
      footer={{
        subscriptionId: props.subscription.shortId,
      }}
      onOpen={props.onOpen}
    />
  );
}
