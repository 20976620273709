.title {
  margin-left: 20px !important;
  margin-right: 50px !important;
  margin-top: 10px !important;
  display: flex;
  height: 30px;
  align-items: center;
  margin-bottom: 10px !important;
  transform: translateY(3px);
}

.header {
  border-bottom: 1px solid var(--dark-gray4);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-top: 0px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid var(--dark-gray3);
}

.content {
  background: var(--dark-gray1);
  margin-right: 1px;
  margin-left: 1px;
  margin-bottom: 1px;
  height: 400px;
  overflow-y: overlay;
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}