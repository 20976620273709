import type { PanelProps } from "@blueprintjs/core";
import { Button } from "@blueprintjs/core";
import React from "react";
import css from "./BotCreatorPanel.module.css";
import { useContext } from "react";
import { BotCreationStore } from "./BotCreator";
import { UserStore } from "../../stores/UserStore";

interface Props {
  children?: React.ReactNode;
  className?: string;
  backText?: React.ReactNode;
}

export function BotCreatorPanel(props: Partial<PanelProps<Props>>) {
  const store = useContext(BotCreationStore);
  const userStore = UserStore.use();
  return (
    <div className={css.panelContainer}>
      <header className={css.panelHeader}>
        {props.closePanel && (
          <Button
            disabled={store?.creating || userStore.signingIn !== false}
            minimal
            icon="chevron-left"
            onClick={props.closePanel}
          >
            {props.backText ?? "Back"}
          </Button>
        )}
      </header>
      <div className={props.className}>{props.children}</div>
    </div>
  );
}
