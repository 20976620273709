.feature {
  background: var(--dark-gray2);
  padding: 10px 18px;
  font-size: 17px;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 14px;
  position: relative;
  justify-content: space-between;
  text-decoration: none !important;
  transition: all ease 0.2s;
  color: var(--light-gray4) !important;
  box-shadow:
    0 0 0 1px transparent,
    0 0 0 2px transparent;
}

.feature:hover {
  color: var(--white) !important;
  background: var(--dark-gray4);
  box-shadow:
    0 0 0 2px var(--gray5),
    0 0 0 6px var(--dark-gray4);
}

.actionContainer {
  background: var(--dark-gray2);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.featureContent {
  display: flex;
  align-items: center;
  gap: 14px;
}

.featureLeftIcon {
  transition: all ease 0.2s;
  color: var(--gray5) !important;
}

.feature:hover .featureLeftIcon {
  color: var(--light_gray5) !important;
}

.featureRightIcon {
  transition: all ease 0.2s;
  color: var(--gray1) !important;
}

.feature:hover .featureRightIcon {
  transform: translateX(3px);
  color: var(--light-gray5) !important;
}

.featureOverview {
  padding: 40px 60px !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.featureList {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}

.premiumTitle {
  font-size: 40px;
  margin-top: 20px;
  text-align: center;
  font-weight: 900;
}

.featureFooter {
  grid-column: 1 / span 2;
  text-align: center;
  margin-top: 40px;
}

.getStartedContainer {
  margin: 40px;
  margin-bottom: 0px;
}

.getStarted {
  padding: 18px !important;
  font-size: 20px !important;
  border-radius: 5px !important;
}

.premiumIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

.soon {
  color: var(--gray1);
  position: absolute;
  font-size: 10px;
  line-height: 14px;
  border-radius: 7px;
  padding: 0px 7px;
  background: var(--dark-gray2);
  border-top-right-radius: 0px;
  right: 0px;
  bottom: -5px;
  font-weight: 400;
  transition: all ease 0.2s;
}

.feature:hover .soon {
  color: var(--light-gray4);
  background: var(--dark-gray4);
}
