.topFade {
  position: absolute;
  left: 0;
  right: 14px;
  top: 0;
  z-index: 2;
  height: 110px;
  background: linear-gradient(var(--dark-gray3), transparent);
  pointer-events: none;
}

.container {
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 30px;
  position: relative;
}
