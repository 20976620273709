.container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 768px;
  width: calc(100% - 40px);
  margin-top: 200px;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.columnTitle {
  font-weight: 600;
}

.columnLink {
  color: var(--light-gray2) !important;
}

.bottomSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 40px;
  color: var(--gray2);
}
