import { Button, Colors, Icon } from "@blueprintjs/core";
import type {
  GameBotConfig,
  SlotSelection,
  TransitionSlotSelection,
} from "@zilch/bot-models";
import {
  BotType,
  zilchBotAvatars,
  zilchBotName,
  zilchBotPreferredColor,
  BotColor,
  botColors,
} from "@zilch/bot-models";
import type { GameId } from "@zilch/game-config";
import { useUserBotConfigListQuery } from "../game/useUserBotConfigListQuery";
import { useCallback } from "react";
import * as csx from "csx";
import { GiLaurelsTrophy } from "react-icons/gi";
import { BotCard } from "../game/BotCard";
import css from "./TournamentSlot.module.css";
import { PromptStore } from "../../stores/PromptStore";
import { sample } from "lodash";
import { DataTransition, type TransitionState } from "./DataTransition";
import type { SlotStats, TournamentProgress } from "./TournamentScreen";
import { classes } from "@zilch/css-utils";
import { useDelayedValue } from "@zilch/delay";
import { Card } from "../common/Card";
import { Popover } from "../common/Popover";

interface Props {
  usedColors: Set<BotColor>;
  slotSelection: NonNullable<TransitionSlotSelection>;
  gameId: GameId;
  active: boolean;
  slotStats: SlotStats | null;
  sortIndex: number;
  transitionState: TransitionState;
  progress: TournamentProgress;
  onSetDifficulty(difficulty: "easy" | "medium" | "hard"): void;
  onRemove(): void;
  onSwap(): void;
  onChangeColor(color: BotColor): void;
  onViewStats(): void;
  hideViewStatsIndicator: boolean;
}

export function TournamentSlot({
  slotSelection,
  gameId,
  onRemove,
  onSwap,
  slotStats,
  onChangeColor,
  usedColors,
  sortIndex,
  transitionState,
  onSetDifficulty,
  progress,
  active,
  onViewStats,
  hideViewStatsIndicator,
}: Props) {
  const botConfig = useBotConfig(slotSelection, gameId);
  const prompt = PromptStore.usePrompt();

  const delayedSortIndex = useDelayedValue(sortIndex, { delay: 250 });
  const fullDelayedSortIndex = useDelayedValue(sortIndex, { delay: 600 });

  const justRemovedSlot =
    useDelayedValue(progress.slots.length, { delay: 300 }) >
    progress.slots.length;

  if (!botConfig) {
    return null;
  }

  return (
    <div
      className={css.outerContainer}
      style={{
        zIndex:
          fullDelayedSortIndex < sortIndex
            ? 1
            : fullDelayedSortIndex > sortIndex
            ? 3
            : 2,
        transform:
          progress.state === "in-progress"
            ? `translateY(${
                ((sortIndex - delayedSortIndex) / 2 + delayedSortIndex) * 87 -
                30
              }px) scale(${
                delayedSortIndex < sortIndex
                  ? 0.9
                  : sortIndex < delayedSortIndex
                  ? 1.1
                  : 1
              })`
            : `translateY(${sortIndex * 87}px)`,
        transition:
          progress.state === "in-progress"
            ? `all ${
                delayedSortIndex !== sortIndex
                  ? "cubic-bezier(.4,0,1,.6)"
                  : "cubic-bezier(0,.4,.6,1)"
              } 250ms`
            : `all ease .3s ${justRemovedSlot ? ".3s" : "0s"}`,
      }}
    >
      <div
        className={css.shadow}
        style={{
          transition:
            progress.state === "in-progress" && fullDelayedSortIndex > sortIndex
              ? "all ease 0.1s"
              : "all ease .3s",
          opacity:
            progress.state === "in-progress" && fullDelayedSortIndex > sortIndex
              ? 1
              : 0,
        }}
      />
      <div
        className={css.innerContainer}
        style={{
          opacity: transitionState === "entered" ? 1 : 0,
          transition:
            transitionState === "exited"
              ? "all .3s cubic-bezier(0.5, 0, 0.8, -0.5) 0s"
              : "all .3s cubic-bezier(0.5, 1.2, 0.5, 1) .3s",
          transform: `scale(${
            transitionState === "entered" ? 1 : 0.8
          }) translateX(${transitionState === "entered" ? 0 : -160}%)`,
        }}
      >
        <div
          className={classes(
            css.slotActions,
            progress.state === "in-progress" && css.hidden
          )}
        >
          <Button
            disabled={progress.state === "in-progress"}
            minimal
            icon={<Icon icon="tint" color={BotColor[slotSelection.color]} />}
            small
            onClick={async () => {
              const pick = await prompt<BotColor>(
                (props) => {
                  return (
                    <div className={css.colorPickerContainer}>
                      {botColors.map((color) => (
                        <button
                          key={color}
                          onClick={() => props.resolve(color)}
                          className={css.colorPickerButton}
                          style={{
                            background: BotColor[color],
                            boxShadow:
                              color === slotSelection.color
                                ? `inset 0 0 0 6px rgba(255,255,255,.5)`
                                : "none",
                          }}
                        >
                          <Icon
                            icon={usedColors.has(color) ? "tick" : "blank"}
                          />
                        </button>
                      ))}
                      <button
                        className={css.colorPickerButton}
                        style={{ background: Colors.DARK_GRAY1 }}
                        onClick={() => {
                          props.resolve(
                            sample(
                              botColors.filter(
                                (color) => color !== slotSelection.color
                              )
                            ) ?? null
                          );
                        }}
                      >
                        <Icon icon="random" />
                      </button>
                    </div>
                  );
                },
                { width: 360 }
              );

              if (pick) {
                onChangeColor(pick);
              }
            }}
          />
          <Button
            disabled={progress.state === "in-progress"}
            minimal
            icon="swap-vertical"
            small
            onClick={onSwap}
          />
          <Button
            disabled={progress.state === "in-progress"}
            minimal
            icon="cross"
            small
            onClick={onRemove}
          />
        </div>

        <div className={css.botCardContainer}>
          <BotCard
            botConfig={{
              slotSelection,
              transition: false,
              value: botConfig,
            }}
            gameId={gameId}
            onSetDifficulty={onSetDifficulty}
          />
        </div>

        <Rank
          slotStats={slotStats}
          hideViewStatsIndicator={hideViewStatsIndicator}
          slotSelection={slotSelection}
          progress={progress}
          onViewStats={onViewStats}
        />

        <DataTransition
          items={active ? [active] : []}
          exitDuration={300}
          getItemKey={() => "active"}
          render={(_, transitionState) => {
            return (
              <div
                className={css.activeIndicator}
                style={{
                  transform: `scale(${transitionState === "entered" ? 1 : 0})`,
                  background: BotColor[slotSelection.color],
                  boxShadow: `inset 0 0 0 1px rgba(255,255,255,.2), 0 0 5px ${
                    BotColor[slotSelection.color]
                  }`,
                }}
              />
            );
          }}
        />
      </div>
    </div>
  );
}

function Rank({
  slotSelection,
  slotStats,
  progress,
  onViewStats,
  hideViewStatsIndicator,
}: {
  progress: TournamentProgress;
  slotSelection: NonNullable<TransitionSlotSelection>;
  slotStats: SlotStats | null;
  hideViewStatsIndicator: boolean;
  onViewStats(): void;
}) {
  const winCount = slotStats
    ? slotStats.computeTimeWins + slotStats.outrightWins
    : 0;
  const winPercentage = slotStats ? winCount / (slotStats.gamesPlayed || 1) : 0;
  const currentRank =
    slotStats && slotStats.gamesPlayed > 0 ? slotStats.rank : null;
  const delayedRank = useDelayedValue(currentRank, { delay: 500 });

  const shouldShowStatsTooltip =
    progress.state === "done" && slotStats?.rank === 0;
  const showStatsTooltip =
    useDelayedValue(shouldShowStatsTooltip, { delay: 500 }) &&
    shouldShowStatsTooltip;

  return (
    <Popover
      content={
        <button
          onClick={onViewStats}
          className={css.viewStats}
          style={{
            color: csx
              .color(BotColor[slotSelection.color])
              .mix(Colors.WHITE, 0.5)
              .toString(),
          }}
        >
          View
          <br />
          stats
        </button>
      }
      position="top"
      isOpen={showStatsTooltip && !hideViewStatsIndicator}
    >
      <Card interactive className={css.rankContainer} onClick={onViewStats}>
        <div className={css.winPercentageContainer}>
          <div
            className={css.winPercentage}
            style={{
              opacity: progress.state === "done" ? 0.6 : 0.3,
              background: BotColor[slotSelection.color],
              transform: `scaleY(${winPercentage})`,
            }}
          />
        </div>
        <GiLaurelsTrophy
          size={32}
          className={css.trophy}
          style={{
            background: BotColor[slotSelection.color],
          }}
        />
        <DataTransition
          items={
            slotStats === null || delayedRank === null ? [] : [delayedRank]
          }
          exitDuration={400}
          getItemKey={(rank) => rank.toString()}
          render={(rank, transitionState) => {
            return (
              <div
                className={css.rank}
                style={{
                  background: csx
                    .color(BotColor[slotSelection.color])
                    .mix(
                      Colors.DARK_GRAY2,
                      progress.state === "in-progress" ? 0.1 : 0.8
                    )
                    .toString(),
                  color:
                    progress.state === "in-progress"
                      ? BotColor[slotSelection.color]
                      : Colors.WHITE,
                  width: rank >= 9 ? "22px" : "18px",
                  border: `1px solid ${csx
                    .color(BotColor[slotSelection.color])
                    .mix(
                      Colors.DARK_GRAY2,
                      progress.state === "in-progress" ? 0.4 : 1
                    )
                    .toString()}`,
                  transform: `scaleX(${transitionState === "entered" ? 1 : 0})`,
                }}
              >
                {rank + 1}
              </div>
            );
          }}
        />
      </Card>
    </Popover>
  );
}

export function useBotConfig(slotSelection: SlotSelection, gameId: GameId) {
  const botConfigListQuery = useUserBotConfigListQuery(
    slotSelection?.type === "user" ? slotSelection.owner : null,
    gameId
  );

  const gameBot = useCallback((): GameBotConfig | null => {
    if (
      slotSelection?.type !== BotType.Boss &&
      slotSelection?.type !== BotType.Practice
    ) {
      return null;
    }

    return {
      avatar: zilchBotAvatars[slotSelection.type],
      game: gameId,
      language: null,
      name: zilchBotName[slotSelection.type],
      preferredColor: zilchBotPreferredColor[slotSelection.type],
      type: slotSelection.type,
      v: 1,
      run: "",
    };
  }, [slotSelection, gameId])();

  if (gameBot) {
    return gameBot;
  }

  if (botConfigListQuery.data === "nonexistent-user") {
    return null;
  }

  return slotSelection
    ? botConfigListQuery.data?.bySlot(slotSelection) ?? null
    : null;
}
