import { Button, Colors, Icon } from "@blueprintjs/core";
import css from "./ForOrganizations.module.css";

export function ForOrganizations() {
  return (
    <a className={css.container} href="/premium/organization">
      <div className={css.header}>
        <div className={css.headerTitle}>
          <Icon icon="office" />
          <div>For School & Business</div>
        </div>
        <Button
          minimal
          large
          rightIcon="arrow-right"
          intent="primary"
          style={{
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          Learn more
        </Button>
      </div>
      <div className={css.content}>
        <span style={{ color: Colors.FOREST5 }}>
          Experience motivation at scale.
        </span>{" "}
        Unlock the latent technical talent of your students or team with the
        Zilch Organization plan.
      </div>
    </a>
  );
}
