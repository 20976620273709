import { useQuery } from "@tanstack/react-query";
import { classes } from "@zilch/css-utils";
import { api } from "../../api";

interface Props {
  userId: string;
  render?: (login: string) => React.ReactNode;
}

export function Login({ userId, render }: Props) {
  const login = useQuery(["manage.getUserLogin", userId], () =>
    api.manage.getUserLogin.query({ userId })
  );

  return (
    <span className={classes(login.isLoading && "bp4-skeleton")}>
      {
        {
          error: "Error",
          loading: "Loading",
          success: render && login.data ? render(login.data) : null,
        }[login.status]
      }
    </span>
  );
}
