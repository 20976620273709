import { useEffect, useRef, useState } from "react";

export function useAnimatedNumber(
  target: number,
  direction: "down" | "up" | "both" = "both"
) {
  const valueRef = useRef(target);
  const listenersRef = useRef(new Set<(value: number) => void>());
  const animationDirectionRef = useRef(direction);
  animationDirectionRef.current = direction;

  useEffect(() => {
    const threshold = Math.abs(target - valueRef.current) * 0.005;

    const interval = setInterval(() => {
      if (
        Math.abs(target - valueRef.current) < threshold ||
        (animationDirectionRef.current === "up" &&
          target - valueRef.current < 0) ||
        (animationDirectionRef.current === "down" &&
          target - valueRef.current > 0)
      ) {
        clearInterval(interval);
        valueRef.current = target;
      } else {
        valueRef.current += (target - valueRef.current) / 10;
      }

      listenersRef.current.forEach((listener) => {
        listener(valueRef.current);
      });
    }, 16);

    return () => clearInterval(interval);
  }, [target]);

  const [AnimatedNumber] = useState(() => {
    return function AnimatedNumber(props: {
      render(value: number): React.ReactNode;
    }) {
      const [value, setValue] = useState(valueRef.current);

      useEffect(() => {
        const handleChange = (value: number) => setValue(value);
        listenersRef.current.add(handleChange);
        return () => {
          listenersRef.current.delete(handleChange);
        };
      }, []);

      return <>{props.render(value)}</>;
    };
  });

  return AnimatedNumber;
}
