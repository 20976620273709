.container {
  transition: all ease 0.3s;
}

.containerHidden {
  opacity: 0;
  pointer-events: none;
}

.slotHeader {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  gap: 5px;
  width: 230px;
}

.slotButton {
  width: 230px;
  height: 76px;
  border: 1px dashed var(--gray1) !important;
  transition: all ease 0.3s;
}

.slotButtonHasBot {
  pointer-events: none;
  opacity: 0.5;
}

.slotButtonContainer {
  display: flex;
  align-items: center;
}

.slotButtonBackground {
  border-radius: 2px;
  background: rgba(47, 52, 60, 0.8);
}

.swapButtonContainer {
  transition: all ease 0.3s;
  position: relative;
  height: 30px;
}

.swapButton {
  border-radius: 100% !important;
  position: absolute;
  left: 50%;
  transition: opacity 0.5s ease;
  transform: translateX(-50%);
  background: rgba(47, 52, 60, 0.5) !important;
}

.swapButton:hover {
  background: rgba(64, 72, 84, 0.7) !important;
}

.swapButton:active {
  background: rgba(95, 107, 124, 0.7) !important;
}

.swapButtonHidden {
  transition: opacity 0.2s ease;
  opacity: 0;
}

.slotActions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  width: 230px;
  border-radius: 2px;
  overflow: hidden;
  background: rgba(47, 52, 60, 0.8);
  border: 1px solid rgba(95, 107, 124, 0.4);
  transition: all ease 0.3s;
}

.colorButton {
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  background: var(--dark-gray2);
  transition: all ease 0.2s;
}

.colorButton:hover {
  transform: scale(1.1);
}

.colorButton:active {
  transform: scale(0.9);
}

.colorOptionsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 10px;
  padding: 10px;
}
