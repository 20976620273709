import { useEffect, useMemo, useRef, useState } from "react";
import createBezierEase from "bezier-easing";

interface Params<T> {
  initial: T;
  clone(value: T): T;
  animate(easing: number, target: T, initial: T): T;
  duration?: number;
  curve?: [x1: number, y1: number, x2: number, y2: number];
}

export function useAnimatedState<T>(params: Params<T>) {
  const duration = params.duration ?? 300;
  const [x1, y1, x2, y2] = params.curve ?? [0.17, 0.67, 0.83, 0.67];

  const [target, setTarget] = useState(params.initial);
  const [value, setValue] = useState(target);
  const valueRef = useRef(value);
  valueRef.current = value;

  const cloneRef = useRef(params.clone);
  cloneRef.current = params.clone;

  const animateRef = useRef(params.animate);
  animateRef.current = params.animate;

  const ease = useMemo(() => {
    return createBezierEase(x1, y1, x2, y2);
  }, [x1, y1, x2, y2]);

  useEffect(() => {
    const startTime = performance.now();
    const initial = cloneRef.current(valueRef.current);

    const animate = () => {
      const currentTime = performance.now();
      const completionPercentage = Math.min(
        1,
        (currentTime - startTime) / duration
      );

      setValue(animateRef.current(ease(completionPercentage), target, initial));

      if (completionPercentage < 1) {
        animationFrame = requestAnimationFrame(animate);
      }
    };

    let animationFrame = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, [target, ease, duration]);

  return [value, setTarget] as const;
}
