import type { GameId } from "@zilch/game-config";
import { DiskGameConfig } from "@zilch/game-config";
import type { IDBPDatabase } from "idb";
import { openDB } from "idb";
import JSZip from "jszip";

let openOperation: Promise<IDBPDatabase<unknown>> | null = null;

async function getDb() {
  if (!openOperation) {
    openOperation = openDB("zilch", 1, {
      upgrade(database) {
        database.createObjectStore(".zilchgame");
      },
    });
  }

  return await openOperation;
}

export const devFileManager = {
  async add(gameId: GameId, file: ArrayBuffer) {
    const zip = await new JSZip().loadAsync(file);
    const gameConfig = DiskGameConfig.parse(
      JSON.parse((await zip.files["out/game.json"]?.async("string")) as string)
    );

    const tx = (await getDb()).transaction(".zilchgame", "readwrite");
    await tx.store.clear();
    await Promise.all([
      tx.store.add(gameConfig, getKey(gameId, "gameConfig")),
      tx.store.add(file, getKey(gameId, "file")),
      tx.done,
    ]);
  },

  async getGameConfig(gameId: GameId) {
    const db = await getDb();
    const gameConfig = await db.get(".zilchgame", getKey(gameId, "gameConfig"));
    if (!gameConfig) return null;
    return DiskGameConfig.parse(gameConfig);
  },

  async getFile(gameId: GameId) {
    const db = await getDb();
    const file = (await db.get(".zilchgame", getKey(gameId, "file"))) as
      | ArrayBuffer
      | undefined;
    if (!file) return null;
    return file;
  },
};

function getKey(gameId: GameId, key: string) {
  return `${
    typeof gameId === "string" ? gameId : `${gameId.owner}.${gameId.repo}`
  }.${key}`;
}
