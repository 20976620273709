.container {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  transition: transform cubic-bezier(0.5, 1.4, 0.5, 1) 0.5s;
  transform: translateY(0px);
}

.hidden {
  transform: translateY(180px);
  pointer-events: none;
  transition: transform ease 0.2s;
}

.startTournament {
  height: 60px;
  width: 230px !important;
  z-index: 1;
  white-space: nowrap;
  transition: all ease 0.3s;
  overflow: hidden;
  position: relative;
}

.buttonText {
  display: flex;
  transition: all cubic-bezier(0.5, 1.5, 0.5, 1) 0.4s;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.configFormContainer {
  margin-top: 10px;
  background: rgba(17, 20, 24, 0.8);
  border: 1px solid rgba(95, 107, 124, 0.4);
  border-radius: 2px;
  animation-delay: 350ms;
}

.gamesPerMatchupEditorContainer {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: radial-gradient(at top, var(--dark-gray2), transparent);
}

.gamesPerMatchupText {
  font-size: 200px;
  margin-bottom: 20px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
  color: rgba(255, 255, 255, 0.1);
  text-shadow:
    0 15px 20px rgba(0, 0, 0, 0.3),
    0px -2px 0 white,
    0px 1px 0 var(--dark-gray1);
}

.gamesPerMatchupBottomSlider {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.gamesPerMatchupBottomFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
