import { Button, type ButtonProps, Classes } from "@blueprintjs/core";
import { api } from "../../api";
import { Popover } from "../common/Popover";
import { useOperation } from "../common/useOperation";
import css from "./RemoveTeamMemberButton.module.css";

interface Props {
  teamId: string;
  userId: string;
  userRole: "owner" | "admin" | "member";
  viewerRole: "owner" | "admin" | "member" | null;
  viewerUserId: string | null;
  onRemoved(): Promise<void>;
  buttonProps: ButtonProps;
}

export function RemoveTeamMemberButton({
  teamId,
  userId,
  userRole,
  viewerRole,
  viewerUserId,
  onRemoved,
  buttonProps,
}: Props) {
  const removeOperation = useOperation({
    async run() {
      await api.manage.removeTeamMember.mutate({ teamId, userId });
      await onRemoved();
    },
  });

  const confirm = userRole !== "owner" && userId === viewerUserId;

  return (
    <Popover
      isOpen={confirm ? undefined : false}
      content={
        <div className={css.content}>
          <div>
            Leaving this team may result you no longer having access to Premium.
          </div>
          <div className={css.footer}>
            <Button small className={Classes.POPOVER_DISMISS}>
              Cancel
            </Button>
            <Button
              small
              intent="danger"
              onClick={removeOperation.run}
              className={Classes.POPOVER_DISMISS}
            >
              Leave Team
            </Button>
          </div>
        </div>
      }
    >
      <Button
        {...buttonProps}
        loading={removeOperation.inProgress}
        disabled={
          userRole === "owner" ||
          (viewerRole === "member" && userId !== viewerUserId)
        }
        onClick={() => {
          if (!confirm) {
            removeOperation.run();
          }
        }}
      />
    </Popover>
  );
}
