import { Colors } from "@blueprintjs/core";
import type { Route } from "type-route";
import type { routes } from "./router";

const transitionDuration = 300;

const screenTransition = document.createElement("div");
screenTransition.style.position = "absolute";
screenTransition.style.opacity = "0";
screenTransition.style.left = "0px";
screenTransition.style.right = "0px";
screenTransition.style.top = "0px";
screenTransition.style.bottom = "0px";
screenTransition.style.background = Colors.DARK_GRAY3;
screenTransition.style.transition = `opacity ease ${transitionDuration}ms`;
screenTransition.style.zIndex = "7";
screenTransition.style.pointerEvents = "none";

document.body.appendChild(screenTransition);

let transitionTimeout: NodeJS.Timeout;

export function transitionScreen(
  to: Route<typeof routes> | null,
  onTransition?: () => void
) {
  if (to?.href === new URL(location.href).pathname) {
    return;
  }

  clearTimeout(transitionTimeout);
  screenTransition.style.opacity = "1";
  screenTransition.style.pointerEvents = "all";
  transitionTimeout = setTimeout(() => {
    to?.push();
    onTransition?.();
    transitionTimeout = setTimeout(() => {
      screenTransition.style.pointerEvents = "none";
      screenTransition.style.opacity = "0";
    }, 100);
  }, transitionDuration);
}
