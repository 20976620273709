.selectAvatarButtonsContainer {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 100px);
  grid-template-rows: 100px;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (max-width: 399px) {
  .selectAvatarButtonsContainer {
    gap: 10px;
    grid-template-columns: repeat(3, 70px);
    grid-template-rows: 70px;
    margin-bottom: 20px;
  }
}

.or {
  margin-left: 10px;
  margin-right: 10px;
}

.additionalOptions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
