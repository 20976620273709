.avatar {
  transition: all ease 0.2s;
}

.avatarBotNotStarted {
  transform: scale(0.5);
}

.spinner {
  position: absolute;
  transition: all ease 0.2s;
}

.spinnerHidden {
  opacity: 0;
  transform: scale(0.5);
}

.avatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  position: relative;
}

.container {
  pointer-events: none;
  display: flex;
  align-items: center;
  gap: 30px;
  background: rgba(47, 52, 60, 0.8);
  border-radius: 20px;
  padding: 20px 30px;
  top: calc(50% - 50px);
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 2;
  box-shadow: 0 5px 20px -1px rgba(0, 0, 0, 0.8);
  transition: all ease 0.2s;
}

.containerHidden {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.1);
}
