import React from "react";
import css from "./SubSectionItems.module.css";

interface Props {
  children: React.ReactNode;
}

export function SubSectionItems(props: Props) {
  return <div className={css.container}>{props.children}</div>;
}
