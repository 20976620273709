import { Colors } from "@blueprintjs/core";
import React from "react";

interface Props {
  foregroundColor?: string;
  backgroundColor?: string;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}

export function ZilchSvg(props: Props) {
  return (
    <svg
      viewBox="0 0 12 12"
      height="100%"
      width="100%"
      className={props.className}
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        height: props.size,
        width: props.size,
        ...props.style,
      }}
    >
      <path
        fillRule="evenodd"
        d="M 2,0 10,0 10,2 11,2 11,4 12,4 12,9 11,9 11,12 1,12 1,9 0,9 0,4 1,4 1,2 2,2z"
        style={{
          fill: props.backgroundColor ?? Colors.BLACK,
        }}
      />
      <path
        fillRule="evenodd"
        d="M 3,1 9,1 9,3 10,3 10,5 9,5 9,7 8,7 8,8 9,8 9,10 10,10 10,11 7,11 7,10 8,10 8,9 4,9 4,10 5,10 5,11 2,11 2,10 3,10 3,8 4,8 4,7 3,7 3,5 2,5 2,3 3,3z M 4,2 8,2 8,6 4,6z"
        style={{
          fill: props.foregroundColor ?? Colors.WHITE,
        }}
      />
      <path
        fillRule="evenodd"
        d="M 5,3 6,3 6,4 5,4z"
        style={{
          fill: Colors.FOREST5,
        }}
      />
      <path
        fillRule="evenodd"
        d="M 1,5 2,5 2,8 1,8z"
        style={{
          fill: props.foregroundColor ?? Colors.WHITE,
        }}
      />
      <path
        fillRule="evenodd"
        d="M 10,5 11,5 11,8 10,8z"
        style={{
          fill: props.foregroundColor ?? Colors.WHITE,
        }}
      />
    </svg>
  );
}
