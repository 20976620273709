import React from "react";
import { Card as BpCard, type CardProps } from "@blueprintjs/core";
import css from "./Card.module.css";
import { classes } from "@zilch/css-utils";

export const Card = React.forwardRef<BpCard, CardProps>((props, ref) => {
  return (
    <BpCard
      {...props}
      className={classes(css.container, props.className)}
      ref={ref}
    />
  );
});
