import { Spinner } from "@blueprintjs/core";
import {
  BotType,
  zilchBotAvatars,
  type SlotSelection,
  BotColor,
} from "@zilch/bot-models";
import React from "react";
import { useUserBotConfigListQuery } from "./useUserBotConfigListQuery";
import type { GameId } from "@zilch/game-config";
import { BotAvatarSvg } from "@zilch/bot-avatar-svg";
import css from "./NotStartedBots.module.css";
import { classes } from "@zilch/css-utils";
import { useDelayedValue } from "@zilch/delay";
import { FallbackAvatar } from "../common/FallbackAvatar";

interface Props {
  slotSelections: SlotSelection[];
  notStartedBots: Set<number>;
  gameId: GameId;
}

export function NotStartedBots(props: Props) {
  const shouldShow = props.notStartedBots.size > 0;
  const show = useDelayedValue(shouldShow, { delay: 400 }) || shouldShow;

  return (
    <div className={classes(css.container, !show && css.containerHidden)}>
      {props.slotSelections.map((selection, index) => {
        if (!selection) {
          return <React.Fragment key={index} />;
        }

        return (
          <BotStartIndicator
            key={index}
            slotSelection={selection}
            notStarted={props.notStartedBots.has(index)}
            gameId={props.gameId}
          />
        );
      })}
    </div>
  );
}

function BotStartIndicator({
  slotSelection,
  notStarted,
  gameId,
}: {
  slotSelection: NonNullable<SlotSelection>;
  notStarted: boolean;
  gameId: GameId;
}) {
  const botConfigListQuery = useUserBotConfigListQuery(
    slotSelection.type === "user" ? slotSelection.owner : null,
    gameId
  );

  let avatar: string | null = null;

  if (
    slotSelection.type === BotType.Boss ||
    slotSelection.type === BotType.Practice
  ) {
    avatar = zilchBotAvatars[slotSelection.type];
  } else if (botConfigListQuery.data !== "nonexistent-user") {
    avatar = botConfigListQuery.data?.bySlot(slotSelection)?.avatar ?? null;
  }

  return (
    <div className={css.avatarContainer}>
      <Spinner
        className={classes(css.spinner, !notStarted && css.spinnerHidden)}
        size={60}
      />
      {avatar ? (
        <BotAvatarSvg
          className={classes(css.avatar, notStarted && css.avatarBotNotStarted)}
          avatar={avatar}
          color={BotColor[slotSelection.color]}
          size={48}
        />
      ) : (
        <FallbackAvatar
          color={BotColor[slotSelection.color]}
          className={classes(css.avatar, notStarted && css.avatarBotNotStarted)}
          size={48}
        />
      )}
    </div>
  );
}
