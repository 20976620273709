import { AnchorButton, Button, Icon } from "@blueprintjs/core";
import { useState } from "react";
import { RiChromeFill } from "react-icons/ri";
import { isMobileDevice } from "./SmallScreenMessage";
import { useStorage } from "../../useStorage";
import { Reveal } from "./Reveal";
import { transitionInFromCss } from "@zilch/css-utils";
import { isCrawler } from "@zilch/is-crawler";
import { groups, useRoute } from "../../router";

function isChrome() {
  // @ts-ignore
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  // @ts-ignore
  const isOpera = typeof window.opr !== "undefined";
  const isIEedge = winNav.userAgent.indexOf("Edg") > -1;
  const isIOSChrome = winNav.userAgent.match("CriOS");

  return (
    isIOSChrome ||
    (isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      isOpera === false &&
      isIEedge === false)
  );
}

export function BrowserMessage(props: { children: React.ReactNode }) {
  const storage = useStorage();
  const route = useRoute();

  const [show, setShow] = useState(() => {
    if (
      isCrawler() ||
      groups.githubAuthCallback.has(route) ||
      groups.githubBotAuthCallback.has(route)
    ) {
      return false;
    }

    return (
      !isChrome() && !isMobileDevice() && !storage.dontShowBrowserPrompt.get()
    );
  });

  if (show) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
        className={transitionInFromCss.bottom}
      >
        <div style={{ width: "calc(100vw - 40px)", maxWidth: "400px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "40px",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <Icon icon="modal" size={50} />
            <Icon icon="arrow-right" size={20} />
            <RiChromeFill size={60} />
          </div>
          <Reveal>
            {(text) => {
              return (
                <div
                  style={{
                    margin: "30px 0px",
                    fontSize: "24px",
                    fontWeight: 600,
                  }}
                >
                  {text(
                    "Zilch's 3D graphics haven't been optimized for your browser"
                  )}{" "}
                  <u>{text("yet")}</u>
                  {text(
                    ". You may experience some bugs and reduced performance. Consider switching to"
                  )}{" "}
                  <a href="https://www.google.com/chrome/" rel="noreferrer">
                    {text("Chrome")}
                  </a>{" "}
                  {text("while compatibility is improved.")}
                </div>
              );
            }}
          </Reveal>
          <AnchorButton
            large
            fill
            intent="primary"
            href="https://www.google.com/chrome/"
            rel="noreferrer"
            style={{ marginBottom: "10px", height: "60px" }}
          >
            <span style={{ fontWeight: 600, fontSize: "18px" }}>
              Switch to Chrome
            </span>
          </AnchorButton>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              minimal
              onClick={() => {
                setShow(false);
                storage.dontShowBrowserPrompt.set(true);
              }}
            >
              <span className="bp4-text-muted">Continue without switching</span>
            </Button>
            <AnchorButton minimal intent="primary" href="/introducing-zilch">
              <span style={{ fontWeight: 600 }}>What is Zilch?</span>
            </AnchorButton>
          </div>
        </div>
      </div>
    );
  } else {
    return <>{props.children}</>;
  }
}
