import React from "react";
import { escapeRegExp } from "lodash";
import css from "./Highlight.module.css";

export function Highlight({
  text,
  highlight,
  highlightClassName,
}: {
  text: string;
  highlight: string;
  highlightClassName?: string;
}) {
  if (highlight === "") {
    return <span>{text}</span>;
  }

  const highlightRegexp = new RegExp(escapeRegExp(highlight), "ig");
  const parts = text.split(highlightRegexp);
  const joiners = Array.from(text.matchAll(highlightRegexp)).map(
    ([match]) => match
  );
  const result: React.ReactNode[] = [];

  for (let i = 0; i < parts.length; i++) {
    result.push(<span key={i}>{parts[i]}</span>);
    if (i < joiners.length) {
      result.push(
        <span
          className={highlightClassName ?? css.highlight}
          key={`${i}-highlight`}
        >
          {joiners[i]}
        </span>
      );
    }
  }

  return <span>{result}</span>;
}
