.container {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.leftSection {
  display: flex;
  align-items: center;
  gap: 10px;
}

.backButton {
  border-radius: 100% !important;
  margin-right: 6px;
}

.verificationInputContainer {
  position: relative;
  height: 40px;
  margin-bottom: 0px !important;
}

.email {
  color: var(--white);
}
