.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

:global(html) {
  height: 100%;
}

:global(body) {
  overflow: hidden;
  height: 100%;
  transition: all cubic-bezier(0.5, 2, 0.5, 1) 0.6s;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.2);
}

:global(body.scrolling) {
  transition: all ease 0.3s;
  transform: scale(0.84);
}

:global(#root) {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.dropIndicator {
  opacity: 0;
  position: absolute;
  left: 30px;
  top: 30px;
  right: 30px;
  bottom: 30px;
  border: 5px dashed var(--blue5);
  border-radius: 10px;
  pointer-events: none;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: 800;
  color: var(--blue5);
  transform: scale(0.9);
  transition: all ease 0.2s;
  text-align: center;
  padding: 0px 60px;
  text-shadow: 0px 2px 7px rgba(0, 0, 0, 0.3);
}

:global(#root) > *:not(.dropIndicator) {
  transition: opacity 0.3s ease;
}

:global(#root.dragging) > *:not(.dropIndicator) {
  opacity: 0.1;
}

:global(#root.dragging) > .dropIndicator {
  transition: all ease 0.5s;
  opacity: 1;
  transform: scale(1);
}

:global(*) {
  user-select: none;
}

:global(.selectable),
:global(.selectable *) {
  user-select: text;
}

:global(*::-webkit-scrollbar) {
  background-color: transparent;
  width: 14px;
  height: 14px;
}

:global(*::-webkit-scrollbar-track) {
  background-color: transparent;
}

:global(*::-webkit-scrollbar-thumb) {
  border-radius: 14px;
  border: 4px solid transparent;
  box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.3);
}

:global(*::-webkit-scrollbar-thumb:hover) {
  box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.6);
}

:global(*::-webkit-scrollbar-button) {
  display: none;
}

:global(*::-webkit-scrollbar-corner) {
  background: transparent;
}

:global(.bp4-toast) {
  /* Toasts have a blur animation on exit by default and it isn't very performant so we disable it here */
  filter: none !important;
}

:global(
    div:not(.bp4-intent-danger) > .bp4-input:not(:focus):not(.bp4-intent-danger)
  ) {
  /* Inputs have a more emphasized bottom border which is unsightly so we remove that here */
  box-shadow:
    0 0 0 0 rgb(76 144 240 / 0%),
    0 0 0 0 rgb(76 144 240 / 0%),
    0 0 0 0 rgb(76 144 240 / 0%),
    inset 0 0 0 1px rgb(255 255 255 / 20%) !important;
}

:global(.bp4-overlay-start-focus-trap),
:global(.bp4-overlay-end-focus-trap) {
  outline: none !important;
}

:global(
    .bp4-html-select.bp4-disabled > .bp4-icon.bp4-icon-double-caret-vertical
  ) {
  opacity: 0.4;
}
