.form {
  padding: 14px;
  width: 386px;
}

.icon {
  position: absolute;
  padding: 11px;
}

.errorContainer {
  padding-top: 10px;
  color: var(--red5);
}