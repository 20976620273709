import { Button } from "@blueprintjs/core";
import { classes } from "@zilch/css-utils";
import { useDelayedValue } from "@zilch/delay";
import { PremiumStore } from "../../stores/PremiumStore";
import css from "./BuySubscriptionSection.module.css";
import { PricePerUnit } from "./PricePerUnit";
import { PricingOverview } from "./PricingOverview";
import { RecommendationGroup } from "./RecommendationGroup";
import { SeatsInput } from "./SeatsInput";
import { useTransitionStyle } from "./useTransitionStyle";
import { Reveal } from "../common/Reveal";

interface Props {
  show: boolean;
}

export function BuySubscriptionSection(props: Props) {
  const premiumStore = PremiumStore.use();

  const justChangedSection =
    useDelayedValue(premiumStore.section, { delay: 300 }) !==
    premiumStore.section;

  return (
    <div
      className={css.container}
      style={useTransitionStyle(props.show, "right")}
    >
      <PricePerUnit />
      <Reveal delay={300}>
        {(text) => {
          return (
            <div
              className={classes(
                css.titleContainer,
                premiumStore.section === "checkout" && css.titleContainerHidden
              )}
            >
              <div className={css.title}>
                {text("Zilch is better together.")}
              </div>
              <div className={css.subTitle}>
                {text(
                  "That's why every Premium subscription comes with a two seat minimum."
                )}
              </div>
            </div>
          );
        }}
      </Reveal>
      <div className={css.bottomSection}>
        <div className={css.seatsInputOuterContainer}>
          <SeatsInput />
          <PricingOverview />
          <RecommendationGroup />
        </div>
        <div className={css.primaryActionContainer}>
          <div
            style={{
              borderRadius: "2px",
            }}
          >
            <Button
              className={css.primaryAction}
              large
              intent="primary"
              fill
              loading={premiumStore.pendingCheckoutSuccess}
              disabled={premiumStore.seats < 2 || justChangedSection}
              onClick={async () => {
                if (premiumStore.section === "calculate-price") {
                  premiumStore.setSection("checkout");
                } else {
                  window.open(
                    `${ClientEnv.ORIGIN}/api/stripe/checkout?seats=${premiumStore.seats}`
                  );
                }
              }}
            >
              <div
                className={classes(
                  css.primaryActionText,
                  premiumStore.section === "checkout" &&
                    css.primaryActionTextTopHidden
                )}
              >
                Calculate Price
              </div>
              <div
                className={classes(
                  css.primaryActionText,
                  premiumStore.section === "calculate-price" &&
                    css.primaryActionTextBottomHidden
                )}
              >
                Proceed to Checkout
              </div>
            </Button>
          </div>
          <div
            className={classes(
              "bp4-text-muted",
              css.navigateToOrganizationCheckSection,
              premiumStore.section === "checkout" &&
                css.navigateToOrganizationCheckSectionHidden
            )}
          >
            Or check if you qualify for{" "}
            <a
              onClick={() => premiumStore.setSection("email-check")}
              tabIndex={premiumStore.section === "calculate-price" ? 0 : -1}
            >
              free Premium
            </a>{" "}
            through your organization.
          </div>
        </div>
      </div>
    </div>
  );
}
