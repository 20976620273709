import stringify from "fast-json-stable-stringify";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";

const resizeListeners = new Set<(width: number, height: number) => void>();

window.addEventListener(
  "resize",
  debounce(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    resizeListeners.forEach((listener) => listener(width, height));
  }, 100)
);

function getWindowSize(width: number, height: number) {
  return { width, height };
}

export function useWindowSizeDerivedValue<
  T = { width: number; height: number },
>(getValue?: (width: number, height: number) => T) {
  const definitelyDefinedGetValue = getValue ?? getWindowSize;
  const getValueRef = useRef(definitelyDefinedGetValue);
  getValueRef.current = definitelyDefinedGetValue;

  const [value, setValue] = useState(
    () => getValueRef.current(window.innerWidth, window.innerHeight) as T
  );

  useEffect(() => {
    resizeListeners.add(handleResize);
    return () => {
      resizeListeners.delete(handleResize);
    };

    function handleResize(width: number, height: number) {
      setValue((current) => {
        const newValue = getValueRef.current(width, height) as T;
        if (stringify(newValue) === stringify(current)) {
          return current;
        } else {
          return newValue;
        }
      });
    }
  }, []);

  return value;
}
