.validation {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid var(--dark-gray2);
  padding: 6px 15px;
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  background: var(--dark-gray1);
}

.formHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 20px;
}

.configContainer {
  margin: 0px 20px;
  background: var(--dark-gray1);
  border-radius: 4px;
  border: 1px solid var(--dark-gray4);
  position: relative;
  padding-bottom: 30px;
}

.configHeader {
  padding: 6px 15px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialogFooter {
  display: flex;
  margin: 20px;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.validationError {
  color: var(--red4);
}

.timeLimitSelectContainer {
  margin-bottom: 0px !important;
}

.timeLimitPopover {
  width: 100%;
}

.timeLimitFormBottomSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timeLimitFormContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  padding-bottom: 10px;
  width: 230px;
}

.timeLimitUnit {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  padding: 0px 10px;
}