import { Button, Spinner } from "@blueprintjs/core";
import type { TournamentManager } from "./TournamentScreen";
import { useDelay } from "@zilch/delay";
import css from "./TournamentControls.module.css";
import { classes } from "@zilch/css-utils";
import { useMemo } from "react";
import { HOUR, MINUTE, SECOND } from "@zilch/time";

interface Props {
  tournamentManager: TournamentManager;
}

export function TournamentControls(props: Props) {
  const { tournamentManager } = props;
  const { progress } = tournamentManager;
  const visible =
    useDelay(200) && tournamentManager.progress.state === "in-progress";

  const { totalGames, completedGames, estTimeRemaining } = useMemo(() => {
    const totalMatchups =
      progress.slots.length * ((progress.slots.length - 1) / 2);
    const totalGames = tournamentManager.gamesPerMatchup * totalMatchups;
    let completedGames = 0;
    let totalTime = 0;
    for (const matchup of progress.matchups) {
      for (const game of matchup.games) {
        if (game === "not-started" || game === "in-progress") {
          continue;
        }
        completedGames++;
        totalTime += game.totalTime;
      }
    }
    const avgTimePerGame = (totalTime || 1) / completedGames;
    const remainingGames = totalGames - completedGames;
    const estTimeRemaining = humanizeTime(remainingGames * avgTimePerGame);
    return { totalGames, completedGames, estTimeRemaining };
  }, [progress, tournamentManager.gamesPerMatchup]);

  return (
    <div className={classes(css.container, !visible && css.hidden)}>
      <div className={css.leftSection}>
        <div style={{ fontWeight: 600 }} className={css.leftSectionText}>
          Running game {Math.min(totalGames, completedGames + 1)}/{totalGames}
        </div>
        <div className={classes("bp4-text-muted", css.leftSectionText)}>
          {completedGames < 5 || completedGames / totalGames < 0.02
            ? "Calculating time remaining"
            : `Est. ${estTimeRemaining} remaining`}
        </div>
      </div>
      <div className={css.stopButtonContainer}>
        <Spinner style={{ position: "absolute", opacity: 0.5 }} size={46} />
        <Spinner
          style={{ position: "absolute", opacity: 0.6 }}
          value={completedGames / (totalGames || 1)}
          intent="primary"
          size={46}
        />
        <Button
          large
          icon="stop"
          minimal
          onClick={() => {
            props.tournamentManager.stop();
          }}
          style={{
            borderRadius: "50%",
            position: "absolute",
          }}
        />
      </div>
    </div>
  );
}

function humanizeTime(ms: number) {
  if (ms < SECOND) {
    return "1s";
  } else if (ms < MINUTE - 5 * SECOND) {
    return 5 * Math.ceil(ms / SECOND / 5) + "s";
  } else if (ms < 2 * HOUR) {
    return plural(Math.round(ms / MINUTE), "minute");
  } else {
    return plural(Math.round(ms / HOUR), "hour");
  }
}

function plural(num: number, unit: string) {
  if (num === 1) {
    return `${num} ${unit}`;
  } else {
    return `${num} ${unit}s`;
  }
}
