import _classes from "classnames";
import css from "./index.module.css";

export const transitionInFromCss = {
  left: css.left,
  right: css.right,
  top: css.top,
  bottom: css.bottom,
};

// TODO how useful is this? Should it be nixed?
export const delayCss = {
  [30]: css.delay30,
  [60]: css.delay60,
  [90]: css.delay90,
  [100]: css.delay100,
  [120]: css.delay120,
  [150]: css.delay150,
  [180]: css.delay180,
  [200]: css.delay200,
  [240]: css.delay240,
  [300]: css.delay300,
  [350]: css.delay350,
  [450]: css.delay450,
  [500]: css.delay500,
};

export const classes = _classes;
