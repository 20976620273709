import {
  AnchorButton,
  Button,
  Dialog,
  FormGroup,
  Menu,
  NumericInput,
  Switch,
} from "@blueprintjs/core";
import { useEffect, useRef, useState } from "react";
import { panic } from "@zilch/panic";
import { useQuery } from "@tanstack/react-query";
import { Editor, loader, useMonaco } from "@monaco-editor/react";
import { monacoZilchTheme } from "./monacoZilchTheme";
import { Popover } from "../common/Popover";
import { MenuItem2 } from "@blueprintjs/popover2";
import { useDelayedValue } from "@zilch/delay";
import { type GameConfigOptions, groups, routes, useRoute } from "../../router";
import { classes } from "@zilch/css-utils";
import css from "./GameConfigForm.module.css";
import {
  type GameEngineInstance,
  type GameOutcome,
  GameSpeed,
} from "./GameEngine";

loader.config({
  paths: {
    vs: `${ClientEnv.ORIGIN}/monaco/${MONACO_VERSION}/min/vs`,
  },
});

interface Props {
  gameEngineInstance: GameEngineInstance;
  gameOutcome: GameOutcome;
  onSetOutcome(value: GameOutcome): void;
  sandbox: boolean;
}

export function GameConfigForm(props: Props) {
  const [open, setOpen] = useState(false);
  const monaco = useMonaco();

  const delayedOpen = useDelayedValue(open, { delay: 400 });

  useEffect(() => {
    if (!monaco) {
      return;
    }

    monaco.editor.defineTheme("monacoZilchTheme", monacoZilchTheme);

    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      schemas: [
        {
          fileMatch: ["*"],
          uri: "*",
          schema: props.gameEngineInstance.configSchema,
        },
      ],
      validate: true,
      schemaValidation: "error",
      allowComments: true,
      trailingCommas: "ignore",
    });
  }, [props.gameEngineInstance, monaco]);

  const config = useConfig(props.gameEngineInstance);

  const gameTimeLimit = undefinedCoalesce(
    config.get()?.gameTimeLimit,
    props.gameEngineInstance.gameConfig.defaultTimeLimitMilliseconds.game
  );
  const moveTimeLimit = undefinedCoalesce(
    config.get()?.moveTimeLimit,
    props.gameEngineInstance.gameConfig.defaultTimeLimitMilliseconds.move
  );

  const parsedConfigQuery = useParsedConfigQuery(
    config.get()?.json ??
      getDefaultPreset(props.gameEngineInstance.configPresets),
    props.gameEngineInstance
  );

  useEffect(() => {
    if (!parsedConfigQuery.data) {
      return;
    }

    props.gameEngineInstance.setConfigAndTimeLimit(
      parsedConfigQuery.data.serializedConfig,
      parsedConfigQuery.data.config,
      {
        game: gameTimeLimit,
        move: moveTimeLimit,
      }
    );
  }, [
    gameTimeLimit,
    moveTimeLimit,
    parsedConfigQuery.data,
    props.gameEngineInstance,
  ]);

  const configSummary = (
    parsedConfigQuery.isSuccess
      ? parsedConfigQuery.data.summary
      : parsedConfigQuery.isError
      ? parsedConfigQuery.error.message
      : ""
  ).trim();

  const shouldShowTimeLimitAdjustPrompt =
    props.gameOutcome.status === "done" &&
    props.gameOutcome.primary?.type === "time-limit-exceeded";
  const [showTimeLimitAdjustPrompt, setShowTimeLimitAdjustPrompt] =
    useState(false);

  useEffect(() => {
    if (shouldShowTimeLimitAdjustPrompt) {
      setShowTimeLimitAdjustPrompt(true);
    }
  }, [shouldShowTimeLimitAdjustPrompt]);

  const delayedShowTimeLimitAdjustPrompt =
    useDelayedValue(showTimeLimitAdjustPrompt, { delay: 8100 }) &&
    showTimeLimitAdjustPrompt;

  return (
    <>
      <Popover
        onClose={() => {
          setShowTimeLimitAdjustPrompt(false);
        }}
        isOpen={delayedShowTimeLimitAdjustPrompt}
        position="right"
        background="blue"
        content={<div style={{ padding: "10px" }}>Adjust time limit.</div>}
      >
        <Button
          minimal
          fill
          alignText="left"
          rightIcon={parsedConfigQuery.isError ? "error" : "cog"}
          onClick={() => {
            setOpen(true);
          }}
          style={{ height: "46px" }}
          intent={parsedConfigQuery.isError ? "danger" : "none"}
          outlined={parsedConfigQuery.isError}
        >
          <div
            style={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
              width: "160px",
            }}
            className={parsedConfigQuery.isError ? undefined : "bp4-text-muted"}
          >
            {gameTimeLimit === null && moveTimeLimit === null
              ? "No time limit"
              : gameTimeLimit === null
              ? stringifyTimeLimit(moveTimeLimit, "move")
              : moveTimeLimit === null
              ? stringifyTimeLimit(gameTimeLimit, "game")
              : stringifyTimeLimit(gameTimeLimit, "game") +
                ", " +
                stringifyTimeLimit(moveTimeLimit, "move")}
            {configSummary ? ", " + configSummary : ""}
          </div>
        </Button>
      </Popover>
      {(open || delayedOpen) && (
        <DialogForm
          open={open}
          gameEngineInstance={props.gameEngineInstance}
          onClose={() => setOpen(false)}
          onSetOutcome={props.onSetOutcome}
        />
      )}
    </>
  );
}

function useParsedConfigQuery(
  configJson: string,
  gameEngineInstance: GameEngineInstance
) {
  return useQuery<
    { config: unknown; serializedConfig: string; summary: string },
    Error
  >(
    ["parsedConfig", configJson, gameEngineInstance.gameConfig.gameId],
    async () => {
      try {
        const result = await gameEngineInstance.parseConfig(configJson);

        if ("error" in result) {
          throw new Error(result.error ?? "unexpected error");
        }

        return result;
      } catch (error) {
        if (error instanceof Error) {
          throw error;
        } else {
          throw new Error("unexpected problem");
        }
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
    }
  );
}

function useConfig(gameEngineInstance: GameEngineInstance) {
  const route = useRoute();

  if (!groups.game.has(route)) {
    panic("Expected route to be a game route.");
  }

  return {
    get() {
      return route.params.config;
    },
    set(config: GameConfigOptions) {
      let configToSet: GameConfigOptions | undefined = config;

      if (
        config.json === getDefaultPreset(gameEngineInstance.configPresets) &&
        config.gameTimeLimit ===
          gameEngineInstance.gameConfig.defaultTimeLimitMilliseconds.game &&
        config.moveTimeLimit ===
          gameEngineInstance.gameConfig.defaultTimeLimitMilliseconds.move
      ) {
        configToSet = undefined;
      }

      const bots =
        route.params.bots.length === 0 ? undefined : route.params.bots;
      const speed =
        route.params.speed === GameSpeed.Normal
          ? undefined
          : route.params.speed;
      if (route.name === routes.externalGame.name) {
        routes
          .externalGame({
            ...route.params,
            bots,
            speed,
            config: configToSet,
          })
          .replace();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      } else if (route.name === routes.internalGame.name) {
        routes
          .internalGame({
            ...route.params,
            bots,
            speed,
            config: configToSet,
          })
          .replace();
      }
    },
  };
}

function getDefaultPreset(configPresets: { name: string; value: string }[]) {
  return configPresets[0]?.value ?? "";
}

function undefinedCoalesce<T>(value: T | undefined, fallbackValue: T): T {
  if (value === undefined) {
    return fallbackValue;
  } else {
    return value;
  }
}

function DialogForm({
  open,
  onClose,
  gameEngineInstance,
  onSetOutcome,
}: {
  open: boolean;
  onClose(): void;
  gameEngineInstance: GameEngineInstance;
  onSetOutcome(gameOutcome: GameOutcome): void;
}) {
  const config = useConfig(gameEngineInstance);
  const defaultPreset = getDefaultPreset(gameEngineInstance.configPresets);
  const [draftConfigJson, setDraftConfigJson] = useState(
    config.get()?.json || defaultPreset
  );
  const [draftTimeLimit, setDraftTimeLimit] = useState({
    move: undefinedCoalesce(
      config.get()?.moveTimeLimit,
      gameEngineInstance.gameConfig.defaultTimeLimitMilliseconds.move
    ),
    game: undefinedCoalesce(
      config.get()?.gameTimeLimit,
      gameEngineInstance.gameConfig.defaultTimeLimitMilliseconds.game
    ),
  });

  const allDefaultValues =
    defaultPreset === draftConfigJson &&
    gameEngineInstance.gameConfig.defaultTimeLimitMilliseconds.game ===
      draftTimeLimit.game &&
    gameEngineInstance.gameConfig.defaultTimeLimitMilliseconds.move ===
      draftTimeLimit.move;

  const parsedConfigQuery = useParsedConfigQuery(
    draftConfigJson,
    gameEngineInstance
  );

  const onRevertToDefaults = () => {
    setDraftConfigJson(defaultPreset);
    setDraftTimeLimit({
      game: gameEngineInstance.gameConfig.defaultTimeLimitMilliseconds.game,
      move: gameEngineInstance.gameConfig.defaultTimeLimitMilliseconds.move,
    });
  };

  const onSave = () => {
    if (!parsedConfigQuery.isSuccess) {
      return;
    }

    config.set({
      json: draftConfigJson,
      gameTimeLimit: draftTimeLimit.game,
      moveTimeLimit: draftTimeLimit.move,
    });

    gameEngineInstance.setStatus("not-started");
    onSetOutcome({
      status: "not-started",
    });

    onClose();
  };
  const onSaveRef = useRef(onSave);
  onSaveRef.current = onSave;

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      const modifier =
        navigator.platform.includes("Mac") || navigator.platform === "iPhone"
          ? e.metaKey
          : e.ctrlKey;
      if (e.key.toLowerCase() === "s" && modifier) {
        e.preventDefault();
        onSaveRef.current();
      }
    };

    window.addEventListener("keydown", handleKeydown);

    return () => window.removeEventListener("keydown", handleKeydown);
  }, []);

  let owner: string;
  let repo: string;

  if (typeof gameEngineInstance.gameConfig.gameId === "string") {
    owner = "zilch";
    repo = gameEngineInstance.gameConfig.gameId;
  } else {
    owner = gameEngineInstance.gameConfig.gameId.owner;
    repo = gameEngineInstance.gameConfig.gameId.repo;
  }

  return (
    <Dialog
      isOpen={open}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      onClose={onClose}
      style={{ paddingBottom: "0px" }}
    >
      <div className={css.formHeader}>
        <TimeLimitSelect
          label="Game Time Limit"
          type="game"
          limit={draftTimeLimit.game}
          defaultValue={
            gameEngineInstance.gameConfig.defaultTimeLimitMilliseconds.game
          }
          onSetLimit={(limit) => {
            setDraftTimeLimit((value) => {
              return {
                game: limit,
                move: value.move,
              };
            });
          }}
        />
        <TimeLimitSelect
          label="Move Time Limit"
          type="move"
          defaultValue={
            gameEngineInstance.gameConfig.defaultTimeLimitMilliseconds.move
          }
          limit={draftTimeLimit.move}
          onSetLimit={(limit) => {
            setDraftTimeLimit((value) => {
              return {
                move: limit,
                game: value.game,
              };
            });
          }}
        />
      </div>
      <div className={css.configContainer}>
        <div className={css.configHeader}>
          <div style={{ fontWeight: 600 }}>Config.json</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {gameEngineInstance.configPresets.length > 0 && (
              <Popover
                placement="bottom"
                content={
                  <Menu>
                    {gameEngineInstance.configPresets.map(
                      ({ name, value }, index) => (
                        <MenuItem2
                          key={index}
                          text={name}
                          label={index === 0 ? "Default" : undefined}
                          icon={draftConfigJson === value ? "tick" : "blank"}
                          onClick={() => {
                            setDraftConfigJson(value);
                          }}
                        />
                      )
                    )}
                  </Menu>
                }
              >
                <Button minimal intent="primary" rightIcon="caret-down">
                  Presets
                </Button>
              </Popover>
            )}
            <AnchorButton
              icon="help"
              minimal
              href={`https://github.com/${owner}/${repo}/wiki`}
              target="_blank"
            />
          </div>
        </div>
        <Editor
          theme="monacoZilchTheme"
          height={300}
          options={{
            fontSize: 13,
            scrollBeyondLastLine: false,
            minimap: {
              enabled: false,
            },
            wordWrap: "on",
            scrollbar: {
              useShadows: true,
            },
            acceptSuggestionOnEnter: "on",
          }}
          language="json"
          value={draftConfigJson}
          onChange={(value) => setDraftConfigJson(value ?? "")}
        />
        <div
          className={classes(
            css.validation,
            parsedConfigQuery.isError ? css.validationError : "bp4-text-muted"
          )}
        >
          {parsedConfigQuery.isError
            ? "Validation status: " + parsedConfigQuery.error.message
            : "Validation status: Ok"}
        </div>
      </div>
      <div className={css.dialogFooter}>
        <Button large minimal onClick={onClose}>
          <span className="bp4-text-muted">Cancel</span>
        </Button>
        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <Button
            large
            disabled={allDefaultValues}
            onClick={onRevertToDefaults}
          >
            Reset
          </Button>
          <Button
            intent="primary"
            large
            disabled={!parsedConfigQuery.isSuccess}
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export function stringifyTimeLimit(
  limit: number | null,
  type: "move" | "game"
) {
  if (limit === null) {
    return `No time limit`;
  }

  if (limit < 1000) {
    return Math.round(limit) + "ms / " + type;
  }

  let numAsString = (limit / 1000).toFixed(1);
  if (numAsString.endsWith(".0")) {
    numAsString = numAsString.slice(0, -2);
  }

  return numAsString + "s / " + type;
}

function TimeLimitSelect({
  limit,
  onSetLimit,
  label,
  type,
  defaultValue,
}: {
  label: string;
  type: "game" | "move";
  limit: number | null;
  defaultValue: number | null;
  onSetLimit(value: number | null): void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const numericInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        numericInputRef.current?.focus();
      });
    }
  }, [isOpen]);

  return (
    <FormGroup
      label={
        <span>
          <span style={{ fontWeight: 600 }}>{label}</span>{" "}
          <span className="bp4-text-muted">(per bot)</span>
        </span>
      }
      className={css.timeLimitSelectContainer}
    >
      <Popover
        placement="bottom"
        className={css.timeLimitPopover}
        isOpen={isOpen}
        onInteraction={(value) => setIsOpen(value)}
        content={
          <div className={css.timeLimitFormContainer}>
            <NumericInput
              fill
              large
              inputRef={numericInputRef}
              min={1}
              clampValueOnBlur
              allowNumericCharactersOnly
              selectAllOnFocus
              disabled={limit === null}
              value={limit ?? defaultValue ?? 1000}
              onValueChange={(value) => {
                if (!Number.isNaN(value)) {
                  onSetLimit(value);
                }
              }}
              selectAllOnIncrement
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setIsOpen(false);
                }
              }}
              rightElement={
                <div
                  className={classes(
                    css.timeLimitUnit,
                    "bp4-text-muted bp4-text-large"
                  )}
                  style={{ opacity: limit === null ? 0.5 : 1 }}
                >
                  ms
                </div>
              }
            />
            <div className={css.timeLimitFormBottomSection}>
              <Switch
                checked={limit === null}
                large
                onChange={() => {
                  if (limit === null) {
                    onSetLimit(defaultValue ?? 1000);
                    setTimeout(() => {
                      numericInputRef.current?.focus();
                    });
                  } else {
                    onSetLimit(null);
                  }
                }}
                style={{ marginBottom: "0px" }}
                label="No time limit"
              />
              <Button
                minimal
                icon="reset"
                disabled={limit === defaultValue}
                onClick={() => {
                  if (limit !== defaultValue) {
                    onSetLimit(defaultValue);
                  }
                }}
              />
            </div>
          </div>
        }
      >
        <Button
          fill
          large
          rightIcon="chevron-down"
          alignText="left"
          active={isOpen}
        >
          {stringifyTimeLimit(limit, type)}
        </Button>
      </Popover>
    </FormGroup>
  );
}
