import {
  Button,
  Dialog,
  FormGroup,
  H3,
  HTMLSelect,
  InputGroup,
} from "@blueprintjs/core";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import type { UserResponse } from "../../../backend/procedures/user";
import { api } from "../../api";
import { getSubscriptionStatusLabel } from "./Subscription";
import type { TeamDialogState } from "./Teams";
import css from "./CreateTeam.module.css";
import { useOperation } from "../common/useOperation";

export function CreateTeam(props: {
  teamDialogState: TeamDialogState | null;
  onClose(): void;
  onCreate(teamId: string): void;
  user: UserResponse;
}) {
  const [teamName, setTeamName] = useState("");

  const ownedOrTeamAdminSubscriptions =
    props.user.subscriptions
      ?.filter(
        (subscription) =>
          subscription.ownerId === props.user.userId ||
          subscription.teams.some((team) => team.role === "admin")
      )
      .sort((a, b) => {
        if (a.status === b.status) {
          if (a.cancelAtPeriodEnd === b.cancelAtPeriodEnd) {
            return (
              a.usedSeatCount / a.seatCount - b.usedSeatCount / b.seatCount
            );
          } else {
            return a.cancelAtPeriodEnd ? -1 : 1;
          }
        }

        if (a.status === "active" || a.status === "incomplete") {
          return 1;
        } else if (b.status === "active" || b.status === "incomplete") {
          return -1;
        } else {
          return 0;
        }
      }) ?? null;

  const [error, setError] = useState("");

  const [selectedSubscriptionId, setSelectedSubscription] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (props.user.subscriptions === undefined) {
      setError("");
      setSelectedSubscription(undefined);
    } else if (props.user.subscriptions.length === 0) {
      setError("No subscriptions.");
      setSelectedSubscription(undefined);
    } else {
      setError("");
      setSelectedSubscription(props.user.subscriptions[0]?.id);
    }
  }, [props.user.subscriptions]);

  const createOperation = useOperation({
    run: (subscriptionId: string) => {
      return api.manage.createTeam
        .mutate({
          subscriptionId,
          teamName: teamName.trim(),
        })
        .then((teamId) => {
          props.onCreate(teamId);
        });
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!selectedSubscriptionId) {
      setError("No subscription selected");
      return;
    }

    if (teamName.trim().length === 0) {
      setError("Enter a team name");
      return;
    }

    createOperation.run(selectedSubscriptionId);
  };

  const isOpen = props.teamDialogState?.type === "new";

  const firstSubscriptionId = props.user.subscriptions?.[0]?.id;
  const firstSubscriptionIdRef = useRef(firstSubscriptionId);
  firstSubscriptionIdRef.current = firstSubscriptionId;

  useEffect(() => {
    if (isOpen) {
      setSelectedSubscription(firstSubscriptionIdRef.current);
      setTeamName("");
    }
  }, [isOpen]);

  return (
    <Dialog isOpen={isOpen} className={css.dialog} onClose={props.onClose}>
      <form className={css.form} onSubmit={handleSubmit}>
        <H3 className={css.header}>Create Team</H3>
        {(ownedOrTeamAdminSubscriptions ?? []).length > 1 && (
          <FormGroup label="Subscription">
            <HTMLSelect
              value={selectedSubscriptionId}
              fill
              onChange={(e) => {
                setSelectedSubscription(e.target.value);
                setError("");
              }}
              options={(ownedOrTeamAdminSubscriptions ?? []).map(
                (subscription) => {
                  const date =
                    "Created " + format(subscription.created, "MMM do, yyyy");
                  const seatsFilled = `${subscription.usedSeatCount} / ${subscription.seatCount} seats filled`;
                  const status = getSubscriptionStatusLabel(subscription);
                  const role =
                    subscription.ownerId === props.user.userId
                      ? "Owner"
                      : "Admin";
                  const id = subscription.shortId;

                  return {
                    label: `${id} · ${role} · ${date} · ${seatsFilled} · ${status}`,
                    value: subscription.id,
                  };
                }
              )}
            />
          </FormGroup>
        )}
        <FormGroup label="Team Name">
          <InputGroup
            value={teamName}
            autoFocus
            placeholder={`e.g. "${props.user.likelyLogin}'s team"`}
            large
            onChange={(e) => {
              setTeamName(e.target.value);
              setError("");
            }}
          />
        </FormGroup>
        <div className={css.footer}>
          <div className={css.error}>{error}</div>
          <div className={css.actions}>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button
              intent="primary"
              type="submit"
              disabled={error.length > 0}
              loading={createOperation.inProgress}
            >
              Create
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
}
