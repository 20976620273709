import { Colors } from "@blueprintjs/core";
import { GameId } from "@zilch/game-config";
import { z } from "zod";

export type BotColor = keyof typeof BotColor;
export const BotColor = {
  vermilion: Colors.VERMILION4,
  red: Colors.RED4,
  rose: Colors.ROSE4,
  violet: Colors.VIOLET4,
  indigo: Colors.INDIGO4,
  blue: Colors.BLUE4,
  cerulean: Colors.CERULEAN4,
  turquoise: Colors.TURQUOISE4,
  green: Colors.GREEN4,
  forest: Colors.FOREST4,
  lime: Colors.LIME4,
  gold: Colors.GOLD4,
  orange: Colors.ORANGE4,
  sepia: Colors.SEPIA4,
};
export const botColors = Object.keys(BotColor) as BotColor[];

export const BotOutcome = {
  Victory: "victory",
  Defeat: "defeat",
  Draw: "draw",
  Error: "error",
  None: "none",
  Unimplemented: "unimplemented",
  TimeLimitExceeded: "time-limit-exceeded",
  ConnectionProblem: "connection-problem",
  GameError: "game-error",
  GameLocked: "game-locked",
} as const;
export type BotOutcome = (typeof BotOutcome)[keyof typeof BotOutcome];

export const BotType = {
  User: "user",
  Practice: "practice",
  Boss: "boss",
} as const;
export type BotType = (typeof BotType)[keyof typeof BotType];

export const zilchBotAvatars = {
  [BotType.Boss]: "pWHgMyAHB9NK_ceC4",
  [BotType.Practice]: "p5G0rqLFujrfGZ-aa",
};

export const zilchBotPreferredColor = {
  [BotType.Boss]: "red" as const,
  [BotType.Practice]: "gold" as const,
};

export const zilchBotName = {
  [BotType.Boss]: "Boss Bot",
  [BotType.Practice]: "Practice Bot",
};

interface UserBotSlotSelection {
  color: BotColor;
  owner: string;
  repo: string;
  type: typeof BotType.User;
}

export interface TransitionUserBotSlotSelection extends UserBotSlotSelection {
  transitionData: SlotSelectionTransitionData;
}

interface PracticeBotSlotSelection {
  color: BotColor;
  type: typeof BotType.Practice;
}

interface BossBotSlotSelection {
  color: BotColor;
  type: typeof BotType.Boss;
  difficulty: "easy" | "medium" | "hard";
}

type GameBotSlotSelection = PracticeBotSlotSelection | BossBotSlotSelection;

export type SlotSelection =
  | UserBotSlotSelection
  | GameBotSlotSelection
  | null
  | undefined;

export interface SlotSelectionTransitionData {
  slotId: string;
  avatar?: string;
  name?: string;
  owner?: string;
  language?: string | null;
  transitionOrigin?: { x: number; y: number };
}

export type TransitionSlotSelection =
  | (NonNullable<SlotSelection> & {
      transitionData: SlotSelectionTransitionData;
    })
  | null
  | undefined;

const validBotColors = new Set(Object.keys(BotColor));

export function isValidBotColor(value: string): value is BotColor {
  return validBotColors.has(value);
}

export type DiskBotConfig = z.infer<typeof DiskBotConfig>;
export const DiskBotConfig = z.object({
  v: z.literal(1),
  name: z.string(),
  avatar: z.string(),
  preferredColor: z.string().refine(isValidBotColor),
  language: z.string().nullable(),
  game: GameId,
  run: z.string(),
  build: z.string().optional(),
});

export interface BotAvatarAndColor {
  avatar: string;
  preferredColor: BotColor;
}

export interface UserBotConfig extends DiskBotConfig {
  type: typeof BotType.User;
  owner: string;
  repo: string;
  latestCommit: string;
}

export interface GameBotConfig extends DiskBotConfig {
  type: typeof BotType.Practice | typeof BotType.Boss;
}

export type BotConfig = UserBotConfig | GameBotConfig;
