import { Colors } from "@blueprintjs/core";
import { BotAvatarSvg } from "@zilch/bot-avatar-svg";

export function FallbackAvatar(props: {
  color?: string;
  className?: string;
  size?: number;
}) {
  return (
    <BotAvatarSvg
      color={props.color ?? Colors.GRAY4}
      className={props.className}
      avatar="hG_dmaWha4daaaWda"
      size={props.size ?? 44}
    />
  );
}
