import { Button, Classes } from "@blueprintjs/core";
import { api } from "../../api";
import { Popover } from "../common/Popover";
import { useOperation } from "../common/useOperation";
import css from "./DeleteTeamButton.module.css";

export function DeleteTeamButton({
  teamId,
  onDeleted,
}: {
  teamId: string;
  onDeleted(): void;
}) {
  const deleteOperation = useOperation({
    async run() {
      await api.manage.deleteTeam.mutate({ teamId });
      onDeleted();
    },
  });

  return (
    <Popover
      content={
        <div className={css.content}>
          <div>
            Deleting this team may result in members no longer having access to
            Premium.
          </div>
          <div className={css.footer}>
            <Button small className={Classes.POPOVER_DISMISS}>
              Cancel
            </Button>
            <Button
              small
              intent="danger"
              onClick={deleteOperation.run}
              className={Classes.POPOVER_DISMISS}
            >
              Delete Team
            </Button>
          </div>
        </div>
      }
    >
      <Button icon="trash" minimal loading={deleteOperation.inProgress}>
        <span className="bp4-text-muted">Delete</span>
      </Button>
    </Popover>
  );
}
