import { Icon } from "@blueprintjs/core";
import { loadImg } from "@zilch/load-img";
import { useEffect, useState } from "react";
import gameMakerPng from "../../resources/icons/game-maker.png";
import type { GameConfig } from "@zilch/game-config";
import type { GameEngine, GameOutcome } from "./GameEngine";
import { getGameIconSrc } from "./GameEngine";
import { useDelayedValue } from "@zilch/delay";
import css from "./GameLessons.module.css";
import { usePlaybook } from "../playbook/Playbook";
import { buildingFirstBotVideoRelativeLink } from "@zilch/video-links";

export function GameLessons(props: {
  engine: GameEngine;
  outcome: GameOutcome;
}) {
  const playbookStore = usePlaybook();
  const gameConfig =
    props.engine.status === "created" ? props.engine.instance.gameConfig : null;
  const iconSrc = useGameIconSrc(gameConfig);

  const shouldShow =
    props.engine.status === "created" &&
    (props.outcome.status === "not-started" ||
      (props.outcome.status === "done" &&
        props.outcome.replayProgress === null));

  const show = useDelayedValue(shouldShow, { delay: 30 });
  const delayedShow = useDelayedValue(show, { delay: 360 });

  if (!shouldShow && !delayedShow) {
    return null;
  }

  return (
    <>
      <button
        className={css.button}
        style={{ opacity: show ? 1 : 0 }}
        onClick={() => {
          if (props.engine.instance?.gameConfig.gameId === "chess") {
            playbookStore.setSection({ name: "chess" });
          } else if (
            props.engine.instance?.gameConfig.gameId === "table-tennis"
          ) {
            window.open(
              buildingFirstBotVideoRelativeLink,
              "_blank",
              "noreferrer"
            );
          } else if (
            props.engine.instance?.gameConfig.gameId === "tic-tac-toe"
          ) {
            playbookStore.setSection({ name: "tic-tac-toe" });
          } else {
            playbookStore.setSection({ name: "overview" });
          }
        }}
      >
        <div
          style={{
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          How to build {gameConfig?.name}&nbsp;bots.
        </div>
        <div
          style={{
            fontSize: "14px",
            fontWeight: 600,
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            gap: "6px",
            opacity: 0.7,
          }}
        >
          <Icon icon="video" />
          <span>Tutorial</span>
          <Icon
            icon="chevron-right"
            style={{ transform: "translate(-1px, 1px)" }}
            size={14}
          />
        </div>
        {iconSrc && (
          <img
            style={{
              position: "absolute",
              bottom: -20,
              right: -20,
              height: "100px",
            }}
            src={iconSrc}
          />
        )}
      </button>
    </>
  );
}

function useGameIconSrc(gameConfig: GameConfig | null) {
  const [loadedIconSrc, setLoadedIconSrc] = useState<string | null>(null);
  const iconSrc = gameConfig ? getGameIconSrc(gameConfig) : null;

  useEffect(() => {
    const targetIconSrc = iconSrc;

    if (!targetIconSrc) {
      return;
    }

    let cancelled = false;

    loadImg(targetIconSrc)
      .then(() => {
        if (cancelled) return;
        setLoadedIconSrc(targetIconSrc);
      })
      .catch(() => {
        if (cancelled) return;
        loadImg(gameMakerPng).then(() => {
          if (cancelled) return;
          setLoadedIconSrc(gameMakerPng);
        });
      });

    return () => {
      cancelled = true;
    };
  }, [iconSrc]);

  return loadedIconSrc;
}
