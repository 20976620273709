.container {
  border-bottom: 1px solid var(--dark-gray4);
}

.teamName {
  margin-left: 20px !important;
  margin-right: 50px !important;
  margin-top: 10px !important;
  display: flex;
  height: 30px;
  align-items: center;
  margin-bottom: 10px !important;
  transform: translateY(3px);
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 11px;
  margin-top: 0px;
  justify-content: space-between;
  height: 30px;
}

.actionsLeft {
  display: flex;
  align-items: center;
  gap: 5px;
}