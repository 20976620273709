import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import { transitionInFromCss } from "@zilch/css-utils";
import { sleep } from "@zilch/sleep";
import React, { useEffect, useRef, useState } from "react";
import type { VerifyEmailState } from "./GetPremium";
import css from "./EmailForm.module.css";
import { useOperation } from "../common/useOperation";
import { UserStore } from "../../stores/UserStore";

interface Props {
  verifyEmailState: VerifyEmailState;
  onVerify(email: string): Promise<void>;
}

export function EmailForm({ verifyEmailState, onVerify }: Props) {
  const userStore = UserStore.use();
  const [email, setEmail] = useState(
    userStore.query.data?.type === "authenticated"
      ? userStore.query.data.organizationEmail?.email ?? ""
      : ""
  );
  const verifyOperation = useOperation({
    run: async () => {
      await sleep(300);
      return onVerify(email);
    },
  });
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    inputRef.current?.focus({
      preventScroll: true,
    });
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    verifyOperation.run();
  };

  return (
    <form onSubmit={onSubmit}>
      <FormGroup className={transitionInFromCss.bottom}>
        <div className={css.container}>
          <InputGroup
            leftIcon="envelope"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            large
            inputRef={inputRef}
            disabled={verifyOperation.inProgress}
            placeholder="you@school-or-work-domain.com"
            fill
          />

          <Button
            intent={
              userStore.premiumSources.size === 0 &&
              verifyEmailState.checkNum > 0 &&
              verifyEmailState.result?.status !== "error"
                ? "none"
                : "primary"
            }
            large
            className={css.checkButton}
            style={{ width: verifyEmailState.checkNum > 0 ? 150 : 100 }}
            type="submit"
            loading={verifyOperation.inProgress}
          >
            Check{verifyEmailState.checkNum > 0 ? " again" : ""}
          </Button>
        </div>
      </FormGroup>
    </form>
  );
}
