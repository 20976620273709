import forbiddenWords from "./resources/forbiddenWords.json";

const lowerCaseForbiddenWords = forbiddenWords.map((word) =>
  word.toLowerCase()
);

export function getForbiddenWordsInText(
  text: string,
  additionalForbiddenWords: string[] = []
) {
  return [
    ...lowerCaseForbiddenWords,
    ...additionalForbiddenWords.map((word) => word.toLowerCase()),
  ].filter((word) => ` ${text} `.toLowerCase().includes(` ${word} `));
}
