.title {
  font-size: 27px;
  font-weight: 700;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subTitle {
  font-size: 22px;
  margin-bottom: 30px;
  font-weight: 400;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  line-height: 28px;
  height: 56px;
}
