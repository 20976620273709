@keyframes FadeIn {
  from {
    opacity: 0.15;
  }
  to {
    opacity: 1;
  }
}

.character {
  animation-name: FadeIn;
  animation-timing-function: ease;
  animation-duration: 100ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
