.topContent {
  display: flex;
  overflow-x: scroll;
  padding: 16px;
  gap: 16px;
  padding-bottom: 2px;
}

.selectedButton {
  border-radius: 15px !important;
  white-space: nowrap;
  font-weight: 600;
  width: 130px !important;
  min-width: 130px !important;
}

.colorButton {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  transition: all ease 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slotLabelContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.slotLabelMarker {
  height: 20px;
  width: 4px;
  border-radius: 2px;
}

.startTournamentContainer {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.startTournamentContainer.small {
  right: 70px;
  top: 23px;
}
