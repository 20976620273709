import stringify from "fast-json-stable-stringify";
import React, { useMemo } from "react";
import { useEffect, useRef, useState } from "react";

export type TransitionState = "initial" | "entered" | "exited";

interface TransitionItem<T> {
  key: string;
  data: T;
  index: number;
  state: TransitionState;
  exitTimeout: number | null;
  enterTimeout: number | null;
}

interface Props<T> {
  items: T[];
  getItemKey(item: T): string;
  render(
    item: T,
    transitionState: TransitionState,
    index: number
  ): React.ReactNode;
  enterDelay?: number;
  exitDuration: number;
  skipInitialEnterTransition?: boolean;
}

export function DataTransition<T>(props: Props<T>) {
  const [state, setState] = useState(new Map<string, TransitionItem<T>>());

  const stateRef = useRef(state);
  stateRef.current = state;

  const exitDurationRef = useRef(props.exitDuration);
  exitDurationRef.current = props.exitDuration;

  const enterDelayRef = useRef(props.enterDelay);
  enterDelayRef.current = props.enterDelay;

  const getItemKeyRef = useRef(props.getItemKey);
  getItemKeyRef.current = props.getItemKey;

  const skipInitialEnterTransitionRef = useRef(
    props.skipInitialEnterTransition ?? false
  );
  skipInitialEnterTransitionRef.current =
    props.skipInitialEnterTransition ?? false;

  const firstRenderRef = useRef(true);

  const { keys, itemsByKey } = useMemo(() => {
    const itemsByKey = new Map<string, T>();
    const keys: string[] = [];

    for (const item of props.items) {
      const key = getItemKeyRef.current(item);
      itemsByKey.set(key, item);
      keys.push(key);
    }

    return { itemsByKey, keys };
  }, [props.items]);
  const stableKeys = useStableValue(keys, [stringify(keys)]);

  const itemsByKeyRef = useRef(itemsByKey);
  itemsByKeyRef.current = itemsByKey;

  useEffect(() => {
    const keysToAdd = new Set(stableKeys);
    const idsToRemove = new Set<string>();
    const idsToAbortExit = new Set<string>();
    const targetKeys = new Set(stableKeys);
    const keyIndices = new Map(stableKeys.map((key, index) => [key, index]));

    for (const [id, transitionItem] of stateRef.current) {
      if (targetKeys.has(transitionItem.key)) {
        if (transitionItem.state === "exited") {
          idsToAbortExit.add(id);
        } else {
          keysToAdd.delete(transitionItem.key);
        }
      } else {
        idsToRemove.add(id);
      }
    }

    setState((state) => {
      const nextState = new Map(state);

      for (const id of idsToRemove) {
        const transitionItem = nextState.get(id);
        if (transitionItem && transitionItem.state !== "exited") {
          const exitTimeout = window.setTimeout(() => {
            setState((state) => {
              const nextState = new Map(state);
              nextState.delete(id);
              return nextState;
            });
          }, exitDurationRef.current);

          nextState.set(id, {
            key: transitionItem.key,
            index: transitionItem.index,
            data: transitionItem.data,
            state: "exited",
            exitTimeout,
            enterTimeout: null,
          });
        }
      }

      for (const id of idsToAbortExit) {
        const transitionItem = nextState.get(id);

        if (!transitionItem) {
          continue;
        }

        if (transitionItem.exitTimeout !== null) {
          window.clearTimeout(transitionItem.exitTimeout);
        }

        nextState.set(id, {
          exitTimeout: null,
          data: transitionItem.data,
          index: transitionItem.index,
          key: transitionItem.key,
          state: "entered",
          enterTimeout: null,
        });
      }

      for (const key of keysToAdd) {
        const enterTimeout = window.setTimeout(
          () => {
            setState((state) => {
              const nextState = new Map(state);
              const transitionItem = nextState.get(id);
              if (transitionItem && transitionItem.state === "initial") {
                nextState.set(id, {
                  index: transitionItem.index,
                  key: transitionItem.key,
                  data: transitionItem.data,
                  state: "entered",
                  exitTimeout: null,
                  enterTimeout: null,
                });
              }
              return nextState;
            });
          },
          30 + (enterDelayRef.current ?? 0)
        );

        const id = crypto.randomUUID();
        const data = itemsByKeyRef.current.get(key);

        if (data !== undefined) {
          nextState.set(id, {
            key,
            index: keyIndices.get(key) ?? 0,
            data,
            state:
              firstRenderRef.current && skipInitialEnterTransitionRef.current
                ? "entered"
                : "initial",
            exitTimeout: null,
            enterTimeout,
          });
        }
      }

      for (const [id, transitionItem] of nextState) {
        const expectedIndex = keyIndices.get(transitionItem.key);
        const transitionState = nextState.get(id)?.state;

        if (
          typeof expectedIndex === "number" &&
          expectedIndex !== transitionItem.index &&
          transitionState &&
          transitionState !== "exited"
        ) {
          nextState.set(id, {
            key: transitionItem.key,
            state: transitionState,
            data: transitionItem.data,
            index: expectedIndex,
            exitTimeout: transitionItem.exitTimeout,
            enterTimeout: transitionItem.enterTimeout,
          });
        }
      }

      firstRenderRef.current = false;

      return nextState;
    });
  }, [stableKeys]);

  useEffect(() => {
    return () => {
      for (const transitionItem of stateRef.current.values()) {
        clearTimeout(transitionItem.enterTimeout);
        clearTimeout(transitionItem.exitTimeout);
      }
    };
  }, []);

  return (
    <>
      {Array.from(state.entries()).map(([id, transitionItem]) => {
        return (
          <React.Fragment key={id}>
            {props.render(
              itemsByKey.get(transitionItem.key) ?? transitionItem.data,
              transitionItem.state,
              transitionItem.index
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}

function useStableValue<T>(value: T, deps: unknown[]) {
  const depsRef = useRef(deps);
  const valueRef = useRef(value);

  if (
    depsRef.current.length === deps.length &&
    depsRef.current.every((value, index) => value === deps[index])
  ) {
    return valueRef.current;
  }

  valueRef.current = value;
  depsRef.current = deps;

  return value;
}
