.premiumDialog {
  min-width: 690px !important;
  width: 690px !important;
  height: 670px !important;
  position: relative;
  overflow: hidden;
  background: var(--dark-gray3) !important;
}

@media (max-width: 690px) {
  .premiumDialogContainer :global(.bp4-dialog-container) {
    justify-content: start !important;
  }
}

.premiumDialogExit {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 100% !important;
  z-index: 1;
}

.emailCheckOrSubscriptionUpButton {
  position: absolute;
  top: 10px;
  z-index: 1;
  left: 10px;
  transition: opacity ease 0.3s;
}

.emailCheckOrSubscriptionContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.unauthenticatedIconContainer {
  width: 280px;
  background: radial-gradient(
    at 100px 100px,
    var(--dark-gray5) 40%,
    var(--black)
  );
  box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  margin-bottom: 20px;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unauthenticatedIcon {
  position: absolute;
  width: 350px;
  transform: translate(-2px, 4px);
}

.unauthenticatedTitle {
  margin-bottom: 6px;
  color: var(--white);
  font-weight: 700;
  font-size: 20px;
}
