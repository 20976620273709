import { Card, Collapse, Colors, H3, Icon } from "@blueprintjs/core";
import { classes } from "@zilch/css-utils";
import React from "react";
import { useState } from "react";
import css from "./Section.module.css";

interface Props {
  title: string;
  children: React.ReactNode;
  collapsible?: boolean;
}

export function Section(props: Props) {
  const [collapsed, setCollapsed] = useState(!!props.collapsible);
  return (
    <>
      <H3
        className={classes(
          css.title,
          props.collapsible && css.titleCollapsible
        )}
        onClick={() => {
          if (props.collapsible) {
            setCollapsed((value) => !value);
          }
        }}
        tabIndex={props.collapsible ? 0 : -1}
        role={props.collapsible ? "button" : undefined}
      >
        {props.title}{" "}
        {props.collapsible && (
          <Icon
            icon="chevron-right"
            style={{
              transition: "all ease .3s",
              transform: `translateX(2px) translateY(-3px) rotate(${
                collapsed ? 0 : 90
              }deg)`,
              color: Colors.GRAY5,
            }}
          />
        )}
      </H3>
      <Collapse isOpen={!collapsed}>
        <Card elevation={3} className={css.content}>
          {props.children}
        </Card>
      </Collapse>
    </>
  );
}
