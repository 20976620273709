.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.avatarContainer {
  margin-top: 10px;
  margin-bottom: 35px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
}

.avatarContainerCreating {
  transform: translateY(10px) scale(1.2);
}

.creationMessage {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 45px;
  transition: all ease 0.4s 0.2s;
  text-align: center;
  color: var(--light-gray1);
  font-size: 18px;
  overflow: hidden;
}

.creationMessageHidden {
  opacity: 0;
  pointer-events: none;
  transition: all ease 0.4s 0s;
  transform: translateY(-25px);
}

.avatar {
  transition: all ease 0.3s;
}

.avatarCreating {
  transform: scale(0.3);
}

.creationIndicator {
  transition: all ease 0.3s;
  position: absolute;
}

.creationIndicatorHidden {
  transform: scale(0.8);
  opacity: 0;
}

.formGroup {
  width: 370px;
}

@media (max-width: 480px) {
  .formGroup {
    width: 350px;
  }
}

@media (max-width: 460px) {
  .formGroup {
    width: 330px;
  }
}

@media (max-width: 440px) {
  .formGroup {
    width: 300px;
  }
}

@media (max-width: 370px) {
  .formGroup {
    width: 260px;
  }
}

.formGroupContainer {
  transition: all ease 0.3s;
}

.formGroupContainerHidden {
  transform: translateY(40px);
  opacity: 0;
  pointer-events: none;
}

.tooltip {
  display: block !important;
  width: 100%;
}
