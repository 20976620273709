import { Button, Classes } from "@blueprintjs/core";
import { BotType } from "@zilch/bot-models";
import { GiRoundStar } from "react-icons/gi";
import type { MaybeTransitionBotConfig } from "./BotCard";
import css from "./BossBotMenu.module.css";
import { classes } from "@zilch/css-utils";
import { useEffect, useState } from "react";

interface Props {
  botConfig: MaybeTransitionBotConfig;
  onSetDifficulty?(difficulty: Difficulty): void;
}

type Difficulty = "easy" | "medium" | "hard";

export function BossBotMenu(props: Props) {
  const [hoveredDifficulty, setHoveredDifficulty] = useState<Difficulty | null>(
    null
  );

  const difficulty =
    props.botConfig.slotSelection?.type === BotType.Boss
      ? props.botConfig.slotSelection.difficulty
      : "easy";

  const [chosenDifficulty, setChosenDifficulty] = useState(difficulty);

  useEffect(() => {
    setChosenDifficulty(difficulty);
  }, [difficulty]);

  const shownDifficulty = hoveredDifficulty ?? chosenDifficulty;

  const onSetDifficulty = (difficulty: Difficulty) => {
    setChosenDifficulty(difficulty);
    props.onSetDifficulty?.(difficulty);
  };

  return (
    <div
      style={{
        width: "230px",
        padding: "4px",
        position: "relative",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateRows: "30px",
      }}
    >
      <DifficultyOption
        value={shownDifficulty}
        difficulty="easy"
        onSetDifficulty={onSetDifficulty}
        onMouseOver={() => setHoveredDifficulty("easy")}
        onMouseOut={() => setHoveredDifficulty(null)}
      />
      <DifficultyOption
        value={shownDifficulty}
        difficulty="medium"
        onSetDifficulty={onSetDifficulty}
        onMouseOver={() => setHoveredDifficulty("medium")}
        onMouseOut={() => setHoveredDifficulty(null)}
      />
      <DifficultyOption
        value={shownDifficulty}
        difficulty="hard"
        onSetDifficulty={onSetDifficulty}
        onMouseOver={() => setHoveredDifficulty("hard")}
        onMouseOut={() => setHoveredDifficulty(null)}
      />
    </div>
  );
}

function DifficultyOption({
  value,
  difficulty,
  onSetDifficulty,
  onMouseOut,
  onMouseOver,
}: {
  value: Difficulty;
  difficulty: Difficulty;
  onSetDifficulty?(value: Difficulty): void;
  onMouseOver(): void;
  onMouseOut(): void;
}) {
  return (
    <Button
      minimal
      active={difficulty === value}
      className={Classes.POPOVER_DISMISS}
      onClick={() => {
        onSetDifficulty?.(difficulty);
      }}
      style={{ position: "relative" }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <GiRoundStar
        className={classes(
          css.star,
          difficultyToNum(value) >= difficultyToNum(difficulty) &&
            css.starFilled
        )}
        style={{
          animationDelay: difficultyToNum(difficulty) * 30 + 150 + "ms",
        }}
        size={230 / 3}
      />
      {
        {
          hard: "Hard",
          medium: "Medium",
          easy: "Easy",
        }[difficulty]
      }
    </Button>
  );
}

function difficultyToNum(difficulty: Difficulty) {
  return {
    easy: 0,
    medium: 1,
    hard: 2,
  }[difficulty];
}
