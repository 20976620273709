.dialog {
  position: relative;
  padding-bottom: 0px !important;
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 100% !important;
  z-index: 1;
}