.outcomeTitle {
  z-index: 1;
  position: absolute;
  top: 70px;
  left: 50%;
  border: 2px solid;
  white-space: nowrap;
  border-left-width: 0px;
  border-right-width: 0px;
  box-shadow: 0 5px 28px rgba(47, 52, 60, 1);
  border-radius: 3px;
  background: rgba(64, 72, 84, 0.9);
  text-align: center;
  padding: 2px 10px 4px 17px;
  text-shadow: 0px 1px 3px var(--dark-gray1), 0px 1px 10px var(--dark-gray1),
    0px 1px 20px var(--dark-gray1);
  color: var(--white);
  font-weight: 900;
  letter-spacing: 7px;
  font-size: 24px;
  text-transform: uppercase;
}

.outcomeAvatars {
  position: absolute;
  top: calc(100% + 25px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  filter: drop-shadow(0px 0px 2px var(--dark-gray3))
    drop-shadow(0px 0px 8px var(--dark-gray3));
}
