.drawerPortalCss {
  z-index: 19 !important;
}

.drawerCss {
  background: var(--dark-gray3) !important;
  display: grid !important;
  margin-bottom: -1px !important;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.topContent {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  transition: all ease 0.3s;
}

.selectedBotIndicator {
  position: absolute;
  right: -3px;
  top: -3px;
  transition: all ease 0.2s;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex !important;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.containerCss {
  height: 380px;
  display: grid;
  margin-left: 1px;
  grid-template-rows: 67px 1fr;
  position: relative;
}

.containerCss::after {
  content: "";
  position: absolute;
  left: 0;
  right: 14px;
  top: 67px;
  height: 20px;
  background: linear-gradient(var(--dark-gray3), transparent);
  z-index: 3;
  pointer-events: none;
}

.headerCss {
  padding: 20px;
  padding-left: 25px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}

.titleCss {
  margin-bottom: 8px !important;
  white-space: nowrap;
}

.resultAreaCss {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 20px;
  overflow-y: overlay;
  align-content: start;
}

.headerButtonContainer {
  min-width: 40px !important;
  border-radius: 100% !important;
  height: 40px;
  background: rgba(47, 52, 60, 0.8);
}

.newBotHeaderButtonCss {
  min-width: 40px !important;
  border-radius: 100% !important;
}

.newBotListButtonCss {
  width: 230px;
  min-width: 230px !important;
  height: 77px;
  border: 1px dashed var(--gray1) !important;
}

.headerCss > * {
  min-width: 0;
}
