import { panic } from "@zilch/panic";
import { usePrevious } from "@zilch/use-previous";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { groups, routes, useRoute } from "../router";
import { toaster } from "../toaster";
import { UserStore } from "./UserStore";
import { posthog } from "posthog-js";

type PremiumStore = ReturnType<typeof usePremiumStore>;

const context = createContext<PremiumStore | null>(null);

export const PremiumStore = {
  Provide: ProvidePremiumStore,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  use: () => useContext(context) ?? panic("PremiumStore not provided"),
};

function usePremiumStore() {
  const [section, setSection] = useState<
    "feature-overview" | "email-check" | "calculate-price" | "checkout" | null
  >(null);

  const [seats, setSeats] = useState(6);
  const previousSection = usePrevious(section);

  useEffect(() => {
    if (section) {
      posthog.capture("premium_flow_navigation", {
        section,
        previousSection,
      });
    }
  }, [section, previousSection]);

  const [pendingCheckoutSuccess, setPendingCheckoutSuccess] = useState(false);

  const route = useRoute();

  const userStore = UserStore.use();

  const hasPremium =
    userStore.query.isSuccess &&
    userStore.query.data.type === "authenticated" &&
    (userStore.query.data.likelyHasPremium || userStore.query.data.hasPremium);

  const refetchUserRef = useRef(userStore.query.refetch);
  refetchUserRef.current = userStore.query.refetch;

  useEffect(() => {
    function handleMessage(message: MessageEvent<unknown>) {
      if (message.data === routes.stripeCheckoutCancelCallback.name) {
        setSection("checkout");
      }

      if (message.data === routes.stripeCheckoutSuccessCallback.name) {
        setSection("checkout");
        refetchUserRef.current();
        setPendingCheckoutSuccess(true);
      }
    }

    window.addEventListener("message", handleMessage);

    return () => window.removeEventListener("message", handleMessage);
  }, []);

  useEffect(() => {
    if (!groups.stripeCallback.has(route)) {
      return;
    }

    const opener = window.opener as Window | null;

    if (opener?.location.origin === ClientEnv.ORIGIN) {
      opener.postMessage(route.name);
      window.close();
    } else if (route.name === routes.stripeManageCallback.name) {
      routes.account().replace();
    } else if (route.name === routes.stripeCheckoutSuccessCallback.name) {
      routes.home().replace();
      refetchUserRef.current();
      setPendingCheckoutSuccess(true);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (route.name === routes.stripeCheckoutCancelCallback.name) {
      routes.home().replace();
      setSection("checkout");
    }
  }, [route]);

  useEffect(() => {
    if (hasPremium && pendingCheckoutSuccess) {
      posthog.capture("premium_activated", {
        source: "subscription",
      });
      setPendingCheckoutSuccess(false);
      setSection("feature-overview");
    }

    if (pendingCheckoutSuccess && !hasPremium) {
      const timeout = setTimeout(() => {
        setPendingCheckoutSuccess(false);
        toaster.show({
          intent: "danger",
          message:
            "It's taking longer than expected to verify your purchase. Try refreshing the page. Reach out if the problem persists: zilch.dev/hello",
          timeout: 0,
          action: {
            text: "Refresh",
            onClick: () => {
              window.location.reload();
            },
          },
        });
      }, 4000);

      return () => {
        clearTimeout(timeout);
      };
    }

    return;
  }, [pendingCheckoutSuccess, hasPremium]);

  return {
    hasPremium,
    section,
    previousSection,
    pendingCheckoutSuccess,
    setSection,
    seats,
    setSeats: (value: number) => setSeats(Math.min(999, value)),
  };
}

function ProvidePremiumStore({ children }: { children: React.ReactNode }) {
  const premiumStore = usePremiumStore();
  return <context.Provider value={premiumStore}>{children}</context.Provider>;
}
