import type { GameId, InternalGameId } from "@zilch/game-config";
import { RawDiskGameConfig, internalGameIds } from "@zilch/game-config";
import { PromptStore } from "../../stores/PromptStore";
import { useStorage, type GameSelectorItem } from "../../useStorage";
import chessPng from "../../resources/icons/chess.png";
import tableTennisPng from "../../resources/icons/table-tennis.png";
import ticTacToePng from "../../resources/icons/tic-tac-toe.png";
import gameMakerPng from "../../resources/icons/game-maker.png";
import { UserStore } from "../../stores/UserStore";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../api";
import { parse } from "jsonc-parser";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { toaster } from "../../toaster";
import css from "./useGameSelector.module.css";
import {
  Button,
  FormGroup,
  type IconName,
  InputGroup,
  NonIdealState,
  Spinner,
  Colors,
} from "@blueprintjs/core";
import { classes, delayCss, transitionInFromCss } from "@zilch/css-utils";
import { useDelay, useDelayedValue } from "@zilch/delay";
import { SiGithub } from "react-icons/si";
import { PremiumStore } from "../../stores/PremiumStore";
import { Tooltip2, Popover2InteractionKind } from "@blueprintjs/popover2";
import { loadImg } from "@zilch/load-img";
import { useWindowSizeDerivedValue } from "@zilch/window-size";
import { devFileManager } from "../game/devFileManager";

export function useGameSelector() {
  const prompt = PromptStore.usePrompt();

  return (title: string, hideZilchGames: boolean = false) => {
    return prompt<{ gameId: GameId; dev?: boolean }>(
      (props) => {
        return (
          <GameSelector
            title={title}
            hideZilchGames={hideZilchGames}
            onSelect={async (owner, repo, dev) => {
              if (
                owner === "zilch" &&
                internalGameIds.includes(repo as InternalGameId)
              ) {
                props.resolve({ gameId: repo as InternalGameId, dev });
              } else {
                props.resolve({ gameId: { owner, repo }, dev });
              }
            }}
          />
        );
      },
      { width: 590, lowerZIndex: true }
    );
  };
}

function GameSelector(props: {
  title: string;
  hideZilchGames: boolean;
  onSelect(owner: string, repo: string, dev?: boolean): Promise<void>;
}) {
  const [view, setView] = useState<"list" | "create">("list");

  const [transitionInFromCreateView, setTransitionInFromCreateView] =
    useState(false);
  const showListView =
    useDelayedValue(view === "list", { delay: 400 }) || view === "list";
  const showCreateView =
    useDelayedValue(view === "create", { delay: 400 }) || view === "create";

  const [listViewHeight, setListViewHeight] = useState(70);

  return (
    <div
      className={css.container}
      style={{ height: view === "list" ? listViewHeight : 440 + "px" }}
    >
      {showListView && (
        <ListView
          onSetListViewHeight={setListViewHeight}
          transitionOut={view !== "list"}
          hideZilchGames={props.hideZilchGames}
          transitionInFromCreateView={transitionInFromCreateView}
          onCreateGame={() => {
            setView("create");
          }}
          title={props.title}
          onSelect={(owner, repo, dev) => props.onSelect(owner, repo, dev)}
        />
      )}
      {showCreateView && (
        <CreateView
          onBack={() => {
            setTransitionInFromCreateView(true);
            setView("list");
          }}
          onSelect={(owner, repo) => props.onSelect(owner, repo)}
          transitionOut={view !== "create"}
        />
      )}
    </div>
  );
}

function CreateView(props: {
  onBack(): void;
  onSelect(owner: string, repo: string): void;
  transitionOut: boolean;
}) {
  const mounted = useDelay(20);
  const userStore = UserStore.use();
  const authenticated = userStore.query.data?.type === "authenticated";
  const premiumStore = PremiumStore.use();
  const [creating, setCreating] = useState(false);
  const [problem, setProblem] = useState("");
  const [showProblem, setShowProblem] = useState(false);

  const gameNameInputRef = useRef<HTMLInputElement | null>(null);

  const transitionedIn = useDelay(300);

  useEffect(() => {
    if (transitionedIn) {
      gameNameInputRef.current?.focus();
    }
  }, [transitionedIn]);

  let content;

  if (!authenticated) {
    content = (
      <React.Fragment key="unauthenticated">
        <div
          className={classes(transitionInFromCss.bottom, css.gameMakerTitle)}
        >
          Sign in to create a game.
        </div>
        <Button
          className={classes(delayCss[150], transitionInFromCss.bottom)}
          disabled={
            !!userStore.signingIn && userStore.signingIn !== "gameCreator"
          }
          loading={userStore.signingIn === "gameCreator"}
          icon={<SiGithub />}
          large
          intent="primary"
          onClick={() => {
            userStore.signIn("gameCreator");
          }}
        >
          Sign in / create account
        </Button>
      </React.Fragment>
    );
  } else if (!premiumStore.hasPremium) {
    content = (
      <React.Fragment key="no-premium">
        <div
          className={classes(transitionInFromCss.bottom, css.gameMakerTitle)}
        >
          Unlock Game Maker with Premium.
        </div>
        <Button
          large
          className={classes(delayCss[150], transitionInFromCss.bottom)}
          intent="primary"
          onClick={() => {
            premiumStore.setSection("feature-overview");
          }}
        >
          Learn more about Premium
        </Button>
      </React.Fragment>
    );
  } else {
    content = (
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          const gameName = gameNameInputRef.current?.value.trim() ?? "";

          if (gameName === "") {
            setProblem("Game name required");
            setShowProblem(true);
            return;
          }

          setCreating(true);

          await api.game.createGame
            .mutate({
              gameName,
            })
            .then((result) => {
              props.onSelect(result.owner, result.repo);
            })
            .catch((error) => {
              setCreating(false);
              setProblem(
                error instanceof Error
                  ? error.message
                  : "Unexpected problem encountered."
              );
              setShowProblem(true);
            });
        }}
      >
        <FormGroup
          label="Game name"
          key="game-maker"
          style={{ maxWidth: "440px", width: "calc(100vw - 80px)" }}
          className={classes(delayCss[150], transitionInFromCss.bottom)}
          helperText={
            <>
              Creates a new repo based off{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://github.com/zilch/custom-game-template"
              >
                <b>zilch/custom-game-template</b>
              </a>
            </>
          }
        >
          <Tooltip2
            placement="top"
            fill
            isOpen={showProblem}
            content={problem}
            // @ts-expect-error
            interactionKind={Popover2InteractionKind.CLICK}
            onClose={() => {
              setShowProblem(false);
            }}
            className={css.tooltip}
          >
            <InputGroup
              large
              disabled={creating}
              inputRef={gameNameInputRef}
              intent={showProblem ? "danger" : "none"}
              fill
              placeholder="Something short and memorable"
              rightElement={
                <Button loading={creating} intent="primary" type="submit">
                  Create
                </Button>
              }
            />
          </Tooltip2>
        </FormGroup>
      </form>
    );
  }

  return (
    <div
      style={{
        opacity: !mounted || props.transitionOut ? 0 : 1,
        transform: `translateX(${
          !mounted || props.transitionOut ? 100 : 0
        }%) scale(${!mounted || props.transitionOut ? 0.7 : 1})`,
      }}
      className={css.gameMakerView}
    >
      <Button
        icon="chevron-left"
        onClick={props.onBack}
        disabled={creating}
        minimal
        style={{ position: "absolute", top: "20px", left: "20px" }}
      >
        Back
      </Button>
      <img
        style={{
          width: "260px",
          marginBottom: "10px",
        }}
        src={gameMakerPng}
      />
      {content}
    </div>
  );
}

function ListView(props: {
  transitionOut: boolean;
  title: string;
  hideZilchGames: boolean;
  onCreateGame(): void;
  onSelect(owner: string, repo: string, dev?: boolean): Promise<void>;
  onSetListViewHeight(height: number): void;
  transitionInFromCreateView: boolean;
}) {
  const open = useDelay(200);
  const openDone = useDelay(700);
  const { games, gamesQuery } = useGames({
    hideZilchGames: props.hideZilchGames,
  });

  const [gamesBySearch, setGamesByUsername] = useState("");

  const userStore = UserStore.use();
  const authenticated = userStore.query.data?.type === "authenticated";
  const gamesByUsernameQuery = useGamesByUser(
    gamesBySearch === "zilch" ? null : gamesBySearch || null
  );
  const gamesByUsername =
    (gamesByUsernameQuery.data === "nonexistent-user"
      ? []
      : gamesByUsernameQuery.data) ?? [];

  const login =
    userStore.query.data?.type === "authenticated"
      ? userStore.query.data.likelyLogin
      : null;

  let height: number;

  if (!open) {
    height = 70;
  } else if (gamesQuery.isLoading) {
    height = 344;
  } else if (games.length < 4) {
    height = gamesBySearch === "" && games.length > 0 ? 344 : 418;
  } else {
    height = 524;
  }

  const setListViewHeightRef = useRef(props.onSetListViewHeight);
  setListViewHeightRef.current = props.onSetListViewHeight;
  useEffect(() => {
    setListViewHeightRef.current(height);
  }, [height]);

  let results: React.ReactNode;

  if (gamesByUsernameQuery.data === "nonexistent-user") {
    results = (
      <NonIdealResult
        icon="cross-circle"
        title="User not found"
        description="Check for typos :)"
      />
    );
  } else if (gamesByUsernameQuery.isSuccess && gamesByUsername.length === 0) {
    if (gamesBySearch === "zilch" && props.hideZilchGames) {
      results = (
        <NonIdealResult
          icon="calculator"
          title="Something doesn't add up."
          description={
            <div style={{ maxWidth: "300px" }}>
              Games by Zilch won't show up while searching for custom games.
            </div>
          }
        />
      );
    } else if (gamesBySearch === "zilch") {
      results = (
        <NonIdealResult
          icon="arrow-up"
          title="What you're looking for isn't here."
          description="Games by Zilch should already be displayed above."
        />
      );
    } else if (!authenticated) {
      results = (
        <NonIdealResult
          icon="cloud"
          title="Not signed in"
          description="Sign in to load custom games."
          action={
            <Button
              disabled={
                !!userStore.signingIn &&
                userStore.signingIn !== "gamesCreatedBy"
              }
              loading={userStore.signingIn === "gamesCreatedBy"}
              large
              icon={<SiGithub color={Colors.GRAY4} />}
              onClick={() => {
                userStore.signIn("gamesCreatedBy");
              }}
            >
              Sign in / create account
            </Button>
          }
        />
      );
    } else if (gamesBySearch === login) {
      results = (
        <NonIdealResult
          icon="cube-add"
          title="Hey there!"
          description="You haven't created any games yet."
          action={
            <Button
              onClick={() => {
                setGamesByUsername("");
                props.onCreateGame();
              }}
              large
            >
              Create Game
            </Button>
          }
        />
      );
    } else {
      results = (
        <NonIdealResult
          icon="cube"
          title={`No games by @${gamesBySearch}`}
          description="Ask them to make one!"
        />
      );
    }
  } else if (gamesByUsernameQuery.isError) {
    results = (
      <NonIdealResult
        icon="bug"
        title="Oops"
        description="Unexpected problem encountered."
      />
    );
  } else if (gamesByUsernameQuery.isLoading) {
    results = (
      <Spinner
        style={{ marginTop: "50px" }}
        className={classes(transitionInFromCss.bottom, delayCss[300])}
      />
    );
  } else if (gamesByUsername.length > 0) {
    results = (
      <div className={css.gameByResults}>
        {gamesByUsername.map((game, index) => {
          return (
            <GameListItem
              game={game}
              onClick={() => {
                props.onSelect(game.owner, game.repo);
              }}
              key={index}
              index={index}
            />
          );
        })}
      </div>
    );
  }

  const mounted = useDelay(20) || !props.transitionInFromCreateView;

  const reallySmall = useWindowSizeDerivedValue((width) => width < 360);
  const small = useWindowSizeDerivedValue((width) => width < 500);

  const [openingGameFile, setOpeningGameFile] = useState(false);

  return (
    <>
      <div
        className={css.gameSelectorListContainer}
        style={{
          overflowY: openDone ? "scroll" : "hidden",
          paddingRight: openDone ? "10px" : "24px",
          gridTemplateColumns: `repeat(${
            reallySmall ? 1 : small ? 2 : 3
          }, 1fr)`,
          transform: `translateX(${
            !mounted || props.transitionOut ? -100 : 0
          }%) scale(${!mounted || props.transitionOut ? 0.7 : 1})`,
        }}
      >
        <div className={css.gameSelectorListHeader}>
          <div>
            <div style={{ fontSize: "20px", fontWeight: 700 }}>
              {props.title}
            </div>
            <div
              style={{ fontSize: "13px", fontWeight: 600, marginTop: "-1px" }}
              className="bp4-text-muted"
            >
              Select game
            </div>
          </div>
          {open && props.hideZilchGames && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Button
                icon="export"
                minimal
                intent="primary"
                loading={openingGameFile}
                className={transitionInFromCss.top}
                onClick={async () => {
                  setOpeningGameFile(true);

                  try {
                    const result = await pickZilchGameFile();
                    if (result) {
                      await props.onSelect(result.owner, result.repo, true);
                    }
                  } finally {
                    setOpeningGameFile(false);
                  }
                }}
              >
                <span style={{ fontWeight: 600 }}>Load from File</span>
              </Button>
              <Button
                rightIcon="chevron-right"
                minimal
                intent="primary"
                className={transitionInFromCss.top}
                onClick={() => {
                  props.onCreateGame();
                }}
              >
                <span style={{ fontWeight: 600 }}>Create Game</span>
              </Button>
            </div>
          )}
        </div>
        {props.hideZilchGames && games.length === 0 && (
          <div
            style={{
              gridColumn: "1/-1",
            }}
          >
            {gamesQuery.isLoading && (
              <Spinner
                className={classes(transitionInFromCss.bottom, delayCss[200])}
              />
            )}
            {gamesQuery.isError && (
              <NonIdealResult
                icon="bug"
                title="Oops!"
                description="Unexpected error encountered loading games."
              />
            )}
            {gamesQuery.isSuccess && (
              <NonIdealResult
                icon="application"
                title="Hello there!"
                description={
                  <>
                    <div>Recently loaded custom games will appear here.</div>
                    <div>Create a new game or load one by a friend.</div>
                  </>
                }
              />
            )}
          </div>
        )}
        {games.map((game, index) => {
          return (
            <GameListItem
              onClick={() => {
                props.onSelect(game.owner, game.repo);
              }}
              index={index}
              game={game}
              key={index}
            />
          );
        })}
        <GamesByInput
          show={open}
          gamesByUsername={gamesBySearch}
          onSetGamesByUsername={(username) => {
            setGamesByUsername(username);
          }}
        />
      </div>
      <div
        className={css.gameByBackdrop}
        style={{
          pointerEvents: gamesBySearch ? undefined : "none",
          opacity: gamesBySearch ? 1 : 0,
        }}
        tabIndex={gamesBySearch ? 0 : -1}
        onClick={() => {
          setGamesByUsername("");
        }}
      />
      <div
        className={css.gameByContainer}
        style={{
          overflowY: gamesByUsername.length === 0 ? "hidden" : "scroll",
          paddingRight: gamesByUsername.length === 0 ? "14px" : "0px",
          pointerEvents: gamesBySearch ? undefined : "none",
          opacity: gamesBySearch ? 1 : 0.8,
          transform: gamesBySearch
            ? "translateY(0%)"
            : "translateY(calc(100% + 1px))",
        }}
      >
        <div className={css.gameByHeader}>
          <div className={css.createdBy}>
            Created by <b>@{gamesBySearch}</b>
          </div>
          <Button
            icon="chevron-down"
            minimal
            large
            style={{ borderRadius: "100%" }}
            onClick={() => {
              setGamesByUsername("");
            }}
          />
        </div>
        {results}
      </div>
    </>
  );
}

function NonIdealResult(props: {
  icon: IconName;
  title: string;
  description: React.ReactChild;
  action?: JSX.Element;
}) {
  return (
    <div style={{ height: "220px" }}>
      <NonIdealState className={transitionInFromCss.bottom} {...props} />
    </div>
  );
}

function GamesByInput(props: {
  onSetGamesByUsername: (username: string) => void;
  gamesByUsername: string;
  show: boolean;
}) {
  const [draftUsername, setDraftUsername] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const initialRef = useRef(true);

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false;
      return;
    }

    if (props.gamesByUsername === "") {
      setDraftUsername("");
      inputRef.current?.focus();
    }
  }, [props.gamesByUsername]);

  return (
    <form
      style={{ opacity: props.show ? 1 : 0 }}
      className={css.gameByInputForm}
      onSubmit={(e) => {
        e.preventDefault();
        if (draftUsername.trim().length > 0) {
          props.onSetGamesByUsername(draftUsername.trim().toLowerCase());
        }
      }}
    >
      <div className={css.formGroupContainer}>
        <FormGroup
          label={
            <span
              className="bp4-text-muted"
              style={{ fontWeight: 500, marginLeft: "10px" }}
            >
              Games created by
            </span>
          }
          className={css.formGroup}
        >
          <InputGroup
            leftIcon="search"
            round
            large
            inputRef={inputRef}
            value={draftUsername}
            onChange={(e) => {
              setDraftUsername(e.target.value);
            }}
            placeholder="GitHub username"
            rightElement={<Button icon="arrow-right" minimal type="submit" />}
          />
        </FormGroup>
      </div>
    </form>
  );
}

function GameListItem(props: {
  game: GameSelectorItem;
  onClick(): void;
  index: number;
}) {
  const [imgLoaded, setImgLoaded] = useState<"primary" | "fallback" | false>(
    false
  );

  useEffect(() => {
    let cancelled = false;

    setImgLoaded(false);

    loadImg(props.game.gameImage)
      .then(() => {
        if (!cancelled) {
          setImgLoaded("primary");
        }
      })
      .catch(() => {
        loadImg(gameMakerPng).then(() => {
          if (!cancelled) {
            setImgLoaded("fallback");
          }
        });
      });

    return () => {
      setImgLoaded(false);
      cancelled = true;
    };
  }, [props.game.gameImage]);

  return (
    <div
      className={classes(css.gameListItem, transitionInFromCss.bottom)}
      tabIndex={0}
      onClick={props.onClick}
      style={{ animationDelay: props.index * 30 + "ms" }}
    >
      <div className={css.gameListImageContainer}>
        <img
          style={{ opacity: imgLoaded ? 1 : 0 }}
          className={css.gameListImage}
          src={imgLoaded === "fallback" ? gameMakerPng : props.game.gameImage}
        />
      </div>
      <div>
        <div className={css.gameName}>{props.game.gameName}</div>
        <div className={classes("bp4-text-muted", css.gameBy)}>
          @{props.game.owner}
        </div>
      </div>
    </div>
  );
}

function useGames({ hideZilchGames }: { hideZilchGames: boolean }) {
  const games: GameSelectorItem[] = [];

  if (!hideZilchGames) {
    games.push(
      {
        gameName: "Table Tennis",
        owner: "zilch",
        repo: "table-tennis",
        gameImage: tableTennisPng,
      },
      {
        gameName: "Tic-Tac-Toe",
        owner: "zilch",
        repo: "tic-tac-toe",
        gameImage: ticTacToePng,
      },
      {
        gameName: "Chess",
        owner: "zilch",
        repo: "chess",
        gameImage: chessPng,
      }
    );
  }

  const userStore = UserStore.use();

  const query = useGamesByUser(
    userStore.query.data?.type === "authenticated"
      ? userStore.query.data.likelyLogin
      : null
  );

  const storage = useStorage();

  const storedGames = useMemo(() => {
    return storage.gameSelector.recentlyLoadedGames.getAll();
  }, [storage]);

  const nonExistentUserResponse = query.data === "nonexistent-user";

  useEffect(() => {
    if (nonExistentUserResponse) {
      toaster.show({
        intent: "danger",
        message: "Unexpected problem encountered while retrieving game list.",
      });
    }
  }, [nonExistentUserResponse]);

  if (query.isSuccess) {
    if (query.data !== "nonexistent-user") {
      games.push(...query.data);
    }

    games.push(...storedGames);
  }

  return { games, gamesQuery: query };
}

function useGamesByUser(user: string | null) {
  const authenticated = UserStore.use().query.data?.type === "authenticated";
  return useQuery(
    ["game.gamesByUser", authenticated ? user : null],
    async ({ signal }) => {
      if (!user) {
        return [];
      }

      return loadGameSelectorItems(user, signal);
    },
    { refetchOnWindowFocus: false }
  );
}

async function loadGameSelectorItems(owner: string, signal?: AbortSignal) {
  const index = await api.game.getGameIndex.query({ owner }, { signal });

  if (index === "nonexistent-user") {
    return index;
  }

  const gameConfigs = await Promise.all(
    index.map(async (game) => {
      return await fetch(
        `https://cdn.jsdelivr.net/gh/${owner}/${game.repo}@${game.latestCommit}/game.json`,
        {
          signal,
        }
      )
        .then(async (response): Promise<RawDiskGameConfig> => {
          return RawDiskGameConfig.parse(parse(await response.text()));
        })
        .then(async (diskGameConfig): Promise<GameSelectorItem | null> => {
          return {
            gameName: diskGameConfig.name,
            owner,
            repo: game.repo,
            gameImage: `https://cdn.jsdelivr.net/gh/${owner}/${game.repo}@${game.latestCommit}/assets/icon.png`,
          };
        })
        .catch(() => null);
    })
  );

  return gameConfigs.filter(
    (gameConfig) => gameConfig !== null
  ) as GameSelectorItem[];
}

export async function pickZilchGameFile() {
  const file = await openZilchGameFilePicker();

  if (!file) {
    return null;
  }

  return await processZilchGameFile(file);
}

export async function processZilchGameFile(file: File) {
  const parts = file.name.split(".");
  const owner = parts[0];
  const repo = parts[1];

  if (parts.length !== 3 || !owner || !repo || parts[2] !== "zilchgame") {
    toaster.show({
      intent: "danger",
      message: "Invalid file name",
    });
    return null;
  }

  try {
    await devFileManager.add({ owner, repo }, await file.arrayBuffer());
  } catch (error) {
    console.error(error);
    toaster.show({
      intent: "danger",
      message: "Unable to process game file",
    });
    return null;
  }

  return { owner, repo };
}

async function openZilchGameFilePicker() {
  const input = document.createElement("input");
  input.type = "file";
  input.accept = ".zilchgame";

  const file = await new Promise<File | null>((resolve) => {
    input.addEventListener("change", () => {
      const file = input.files?.item(0) ?? null;
      if (file) {
        resolve(file);
      }
    });
    input.addEventListener("cancel", () => {
      resolve(null);
    });
    input.click();
  });

  input.remove();

  return file;
}
