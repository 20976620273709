.container {
  max-width: 848px;
  margin-top: 20px;
  width: calc(100% - 40px);
  min-width: 300px;
  padding: 40px 40px 50px 52px;
  border-radius: 8px;
  background: radial-gradient(
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05)
  );
  display: flex;
  backdrop-filter: blur(5px);
  flex-direction: column;
  gap: 30px;
  box-shadow: 0 10px 20px -2px var(--black);
  text-decoration: none !important;
  color: var(--white) !important;
  transition: all ease 0.2s;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.container:hover {
  outline: 2px solid var(--blue5);
  outline-offset: 5px;
}

.content {
  font-size: 34px;
  font-weight: 700;
  padding-right: 12px;
  pointer-events: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
}

.headerTitle {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 10px;
}
