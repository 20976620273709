.speedIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px;
  background: rgba(28, 33, 39, 0.6);
  border-radius: 100%;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.5);
  transition: all ease 0.2s;
  margin-left: -70px;
  margin-top: -140px;
  z-index: 3;
  pointer-events: none;
}

.visibleSpeedIcon {
  opacity: 1;
  transform: scale(1);
}
