import { useContext } from "react";
import { BotCreationStore } from "./BotCreator";
import { BotCreatorPanel } from "./BotCreatorPanel";
import { BotAvatarCreator } from "./BotAvatarCreator";
import { BotCreatorNamePanel } from "./BotCreatorNamePanel";
import type { PanelActions } from "@blueprintjs/core/lib/esm/components/panel-stack2/panelTypes";

export function BotCreatorCustomAvatarPanel(props: PanelActions) {
  const store = useContext(BotCreationStore);

  if (!store) {
    return null;
  }

  return (
    <BotCreatorPanel {...props}>
      <BotAvatarCreator
        avatar={store.botConfig.avatar ?? ""}
        preferredColor={store.botConfig.preferredColor ?? "blue"}
        onUpdateAvatar={(avatar, preferredColor) => {
          store.onSetBotConfig((config) => {
            return {
              ...config,
              avatar,
              preferredColor,
            };
          });
        }}
        onDone={() => {
          props.openPanel({
            renderPanel: (props) => <BotCreatorNamePanel {...props} />,
          });
        }}
      />
    </BotCreatorPanel>
  );
}
