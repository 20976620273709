import { BotOutcome } from "@zilch/bot-models";
import React, { useRef } from "react";
import { GiLaurelsTrophy } from "react-icons/gi";
import { MdConstruction } from "react-icons/md";
import { Colors, Icon } from "@blueprintjs/core";
import { FaBalanceScale } from "react-icons/fa";
import { RiSwordFill } from "react-icons/ri";
import { AiFillBug } from "react-icons/ai";
import { classes } from "@zilch/css-utils";
import css from "./BotOutcomeDisplay.module.css";
import * as csx from "csx";

interface Props {
  isPrimaryOutcome: boolean;
  botOutcome: BotOutcome | null;
  type: "icon" | "text";
}

export function BotOutcomeDisplay({
  botOutcome,
  type,
  isPrimaryOutcome,
}: Props) {
  const botOutcomeRef = useRef(botOutcome);

  const showOutcome =
    botOutcome !== null &&
    botOutcome !== BotOutcome.GameError &&
    botOutcome !== BotOutcome.GameLocked;

  if (showOutcome) {
    botOutcomeRef.current = botOutcome;
  }

  let outcomeIcon: React.ReactNode = null;
  let outcomeText: string = "";

  let primaryOutcomeColor = Colors.GRAY3;

  if (botOutcomeRef.current === BotOutcome.Error) {
    primaryOutcomeColor = Colors.LIGHT_GRAY1;
    outcomeText = "Error";
  } else if (botOutcomeRef.current === BotOutcome.ConnectionProblem) {
    primaryOutcomeColor = Colors.LIGHT_GRAY1;
    outcomeText = "Offline";
  } else if (botOutcomeRef.current === BotOutcome.Defeat) {
    primaryOutcomeColor = Colors.RED5;
    outcomeText = "Defeat";
  } else if (botOutcomeRef.current === BotOutcome.Draw) {
    primaryOutcomeColor = Colors.BLUE5;
    outcomeText = "Draw";
  } else if (botOutcomeRef.current === BotOutcome.TimeLimitExceeded) {
    primaryOutcomeColor = Colors.RED5;
    outcomeText = "Time's Up";
  } else if (botOutcomeRef.current === BotOutcome.Unimplemented) {
    primaryOutcomeColor = Colors.LIGHT_GRAY1;
    outcomeText = "New Bot";
  } else if (botOutcomeRef.current === BotOutcome.Victory) {
    primaryOutcomeColor = Colors.GOLD5;
    outcomeText = "Victory";
  } else if (botOutcomeRef.current === BotOutcome.None) {
    primaryOutcomeColor = Colors.GRAY5;
    outcomeText = "Stopped";
  }

  const iconColor = isPrimaryOutcome ? primaryOutcomeColor : Colors.GRAY3;

  if (botOutcomeRef.current === BotOutcome.Error) {
    outcomeIcon = <AiFillBug size={18} color={iconColor} />;
  } else if (botOutcomeRef.current === BotOutcome.ConnectionProblem) {
    outcomeIcon = <Icon icon="offline" size={15} color={iconColor} />;
  } else if (botOutcomeRef.current === BotOutcome.Defeat) {
    outcomeIcon = <RiSwordFill size={16} color={iconColor} />;
  } else if (botOutcomeRef.current === BotOutcome.Draw) {
    outcomeIcon = <FaBalanceScale size={17} color={iconColor} />;
  } else if (botOutcomeRef.current === BotOutcome.TimeLimitExceeded) {
    outcomeIcon = <Icon icon="stopwatch" size={15} color={iconColor} />;
  } else if (botOutcomeRef.current === BotOutcome.Unimplemented) {
    outcomeIcon = <MdConstruction size={18} color={iconColor} />;
  } else if (botOutcomeRef.current === BotOutcome.Victory) {
    outcomeIcon = <GiLaurelsTrophy size={18} color={iconColor} />;
  }

  if (type === "text") {
    return (
      <div
        className={classes(
          css.textContainer,
          isPrimaryOutcome && css.textContainerPrimary,
          !showOutcome && css.textContainerHidden
        )}
      >
        {outcomeText}
      </div>
    );
  }

  return (
    <div
      className={classes(
        css.iconContainer,
        (!showOutcome || botOutcomeRef.current === BotOutcome.None) &&
          css.iconContainerHidden
      )}
      style={{
        borderColor: isPrimaryOutcome
          ? csx.color(primaryOutcomeColor).fade(0.3).toString()
          : undefined,
      }}
    >
      {outcomeIcon}
    </div>
  );
}
