import {
  type SlotSelection,
  BotType,
  isValidBotColor,
} from "@zilch/bot-models";
import { internalGameIds } from "@zilch/game-config";
import { panic } from "@zilch/panic";
import {
  createGroup,
  createRouter,
  defineRoute,
  noMatch,
  param,
  type ValueSerializer,
} from "type-route";
import type { ZodType } from "zod";
import { z } from "zod";
import {
  compressToEncodedURIComponent,
  decompressFromEncodedURIComponent,
} from "lz-string";

function createZodSerializer<T>(zod: ZodType<T>): ValueSerializer<T> {
  const def = zod._def as unknown;
  const isStringList =
    def !== null &&
    typeof def === "object" &&
    "typeName" in def &&
    def.typeName === "ZodEnum" &&
    "values" in def &&
    Array.isArray(def.values) &&
    def.values.every((value) => typeof value === "string");

  if (isStringList) {
    return {
      parse(raw) {
        try {
          return zod.parse(raw);
        } catch {
          return noMatch;
        }
      },
      stringify(value) {
        if (typeof value !== "string") {
          panic("Unexpected typeof result");
        }
        return value;
      },
    };
  }

  return {
    parse(raw) {
      try {
        return zod.parse(JSON.parse(raw));
      } catch {
        return noMatch;
      }
    },
    stringify(value) {
      return JSON.stringify(value);
    },
  };
}

const gameSpeedValueSerializer = createZodSerializer(
  z.enum(["normal", "paused", "fast", "step"])
);

const internalGameIdSerializer = createZodSerializer(z.enum(internalGameIds));

const botSlotSerializer: ValueSerializer<SlotSelection | undefined> = {
  stringify(slot) {
    if (slot === undefined || slot === null) {
      return "";
    }

    if (slot.type === BotType.Practice) {
      return `${slot.type}.${slot.color}`;
    }

    if (slot.type === BotType.Boss) {
      const difficulty = { easy: 1, medium: 2, hard: 3 }[slot.difficulty];
      return `${slot.type}${difficulty}.${slot.color}`;
    }

    return `${slot.owner}.${slot.repo}.${slot.color}`;
  },
  parse(raw) {
    if (raw === "") {
      return undefined;
    }

    const parts = raw.split(".");

    const color = parts.length === 2 ? parts[1] : parts[2];

    if (!color || !isValidBotColor(color)) {
      return noMatch;
    }

    if (parts.length === 2) {
      const type = parts[0];

      if (type === BotType.Practice) {
        return { type, color };
      }

      if (type === BotType.Boss + "1") {
        return { type: BotType.Boss, color, difficulty: "easy" };
      } else if (type === BotType.Boss + "2") {
        return { type: BotType.Boss, color, difficulty: "medium" };
      } else if (type === BotType.Boss + "3") {
        return { type: BotType.Boss, color, difficulty: "hard" };
      } else {
        return noMatch;
      }
    } else if (parts.length === 3) {
      const owner = parts[0];
      const repo = parts[1];

      if (!owner || !repo) {
        return noMatch;
      }

      return {
        type: BotType.User,
        color,
        owner,
        repo,
      };
    } else {
      return noMatch;
    }
  },
};

export interface GameConfigOptions {
  gameTimeLimit: number | null;
  moveTimeLimit: number | null;
  json: string;
}

const configSerializer: ValueSerializer<GameConfigOptions> = {
  stringify(config) {
    return compressToEncodedURIComponent(
      JSON.stringify([config.gameTimeLimit, config.moveTimeLimit, config.json])
    );
  },
  parse(raw) {
    try {
      const [gameTimeLimit, moveTimeLimit, json] = z
        .tuple([z.number().nullable(), z.number().nullable(), z.string()])
        .parse(JSON.parse(decompressFromEncodedURIComponent(raw)));
      return {
        gameTimeLimit,
        moveTimeLimit,
        json,
      };
    } catch {
      return noMatch;
    }
  },
};

const game = defineRoute(
  {
    release: param.query.optional.string,
    refreshKey: param.state.optional.number,
    showMultiplayerGuide: param.state.optional.boolean,
    dev: param.query.optional.boolean,
    bots: param.query.optional.array.ofType(botSlotSerializer).default([]),
    config: param.query.optional.ofType(configSerializer),
    speed: param.query.optional
      .ofType(gameSpeedValueSerializer)
      .default("normal"),
  },
  () => "/"
);

export const { RouteProvider, routes, useRoute, session } = createRouter({
  stripeCheckoutCancelCallback: defineRoute("/stripe/checkout/cancel"),
  stripeCheckoutSuccessCallback: defineRoute("/stripe/checkout/success"),
  stripeManageCallback: defineRoute("/stripe/manage/callback"),
  githubBotAuthSuccessCallback: defineRoute(
    {
      state: param.query.string,
      code: param.query.string,
    },
    () => "/github/bot-auth/callback"
  ),
  githubBotAuthErrorCallback: defineRoute(
    {
      state: param.query.string,
      error: param.query.string,
      error_description: param.query.optional.string,
      error_uri: param.query.optional.string,
    },
    () => "/github/bot-auth/callback"
  ),
  githubAuthSuccessCallback: defineRoute(
    {
      state: param.query.string,
      code: param.query.string,
    },
    () => "/github/auth/callback"
  ),
  githubAuthErrorCallback: defineRoute(
    {
      state: param.query.string,
      error: param.query.string,
      error_description: param.query.optional.string,
      error_uri: param.query.optional.string,
    },
    () => "/github/auth/callback"
  ),
  home: defineRoute("/"),
  store: defineRoute("/store"),
  account: defineRoute(
    { premium: param.query.optional.boolean.default(false) },
    () => "/account"
  ),
  tournamentInternalGame: defineRoute(
    {
      gameId: param.path.ofType(internalGameIdSerializer),
    },
    (p) => `/tournament/${p.gameId}`
  ),
  tournamentExternalGame: defineRoute(
    {
      owner: param.path.string,
      repo: param.path.string,
    },
    (p) => `/tournament/${p.owner}/${p.repo}`
  ),
  internalGame: game.extend(
    {
      gameId: param.path.ofType(internalGameIdSerializer),
    },
    (p) => `/${p.gameId}`
  ),
  externalGame: game.extend(
    {
      owner: param.path.string,
      repo: param.path.string,
    },
    (p) => `/${p.owner}/${p.repo}`
  ),
});

export const groups = {
  tournament: createGroup([
    routes.tournamentInternalGame,
    routes.tournamentExternalGame,
  ]),
  game: createGroup([routes.internalGame, routes.externalGame]),
  stripeCallback: createGroup([
    routes.stripeCheckoutCancelCallback,
    routes.stripeCheckoutSuccessCallback,
    routes.stripeManageCallback,
  ]),
  githubAuthCallback: createGroup([
    routes.githubAuthErrorCallback,
    routes.githubAuthSuccessCallback,
  ]),
  githubBotAuthCallback: createGroup([
    routes.githubBotAuthErrorCallback,
    routes.githubBotAuthSuccessCallback,
  ]),
};
