import { Button, Icon } from "@blueprintjs/core";
import { useCallback, useState } from "react";
import { z } from "zod";
import { api } from "../../api";
import { EmailCheckSectionTitle } from "./EmailCheckSectionTitle";
import { EmailForm } from "./EmailForm";
import type { VerifyEmailState } from "./GetPremium";
import { VerificationForm } from "./VerificationForm";
import css from "./EmailCheckSection.module.css";
import { EmailCheckVisual } from "./EmailCheckVisual";
import { classes } from "@zilch/css-utils";
import { useTransitionStyle } from "./useTransitionStyle";
import { PremiumStore } from "../../stores/PremiumStore";
import { UserStore } from "../../stores/UserStore";
import { posthog } from "posthog-js";

interface Props {
  show: boolean;
}

export function EmailCheckSection(props: Props) {
  const premiumStore = PremiumStore.use();
  const userStore = UserStore.use();

  const [verifyEmailState, setVerifyEmailState] = useState<VerifyEmailState>({
    result: null,
    checkNum: 0,
  });

  const verifyEmail = useCallback(async (email: string) => {
    const emailValidationResult = z.string().email().safeParse(email);
    if (!emailValidationResult.success) {
      setVerifyEmailState((state) => {
        return {
          result: {
            status: "error",
            email,
            message: "Enter a valid email address.",
          },
          checkNum: state.checkNum + 1,
        };
      });
      return;
    }

    const result = await api.organization.verifyEmail
      .mutate({ email: emailValidationResult.data })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return "error" as const;
      });

    if (result === "error") {
      setVerifyEmailState((state) => {
        return {
          result: {
            status: "error",
            email,
            message: "Unexpected error encountered checking email.",
          },
          checkNum: state.checkNum + 1,
        };
      });
      return;
    }

    posthog.capture("organization_premium_sign_up_attempted", {
      email,
      domain: email.split("@")[1],
      result: result.status,
    });

    setVerifyEmailState((state) => {
      return {
        result: result,
        checkNum: state.checkNum + 1,
      };
    });
  }, []);

  let primaryActionIsBuySubscription = false;

  if (
    userStore.premiumSources.size === 0 &&
    (verifyEmailState.result?.status === "no-org-for-domain" ||
      verifyEmailState.result?.status === "org-plan-expired" ||
      verifyEmailState.result?.status === "org-plan-not-started")
  ) {
    primaryActionIsBuySubscription = true;
  }

  return (
    <div
      className={css.container}
      style={useTransitionStyle(props.show, "left")}
    >
      <EmailCheckVisual />
      <EmailCheckSectionTitle verifyEmailState={verifyEmailState} />

      {verifyEmailState.result?.status === "verification-code-sent" && (
        <VerificationForm
          onBack={() => {
            setVerifyEmailState({
              checkNum: 0,
              result: null,
            });
          }}
          email={verifyEmailState.result.email}
        />
      )}

      {verifyEmailState.result?.status !== "verification-code-sent" && (
        <EmailForm onVerify={verifyEmail} verifyEmailState={verifyEmailState} />
      )}

      <div className={css.footer}>
        <div className="bp4-text-large bp4-text-muted">
          {userStore.premiumSources.has("active-subscription")
            ? "Enrolling in organization provided premium isn't necessary for your account since you already own an active Premium subscription."
            : userStore.premiumSources.has("organization-plan")
            ? "Want a Premium subscription instead?"
            : "Don't have organization provided Premium?"}
        </div>
        {!userStore.premiumSources.has("active-subscription") && (
          <Button
            large
            className={classes(
              css.buySubscription,
              primaryActionIsBuySubscription && css.buySubscriptionPrimary
            )}
            onClick={() => premiumStore.setSection("calculate-price")}
            intent={primaryActionIsBuySubscription ? "primary" : "none"}
          >
            <div
              className={css.buySubscriptionContent}
              style={{
                transform: `translateX(${
                  primaryActionIsBuySubscription ? -10 : 0
                }px)`,
              }}
            >
              Buy subscription
            </div>
            <Icon
              icon="chevron-right"
              className={css.buySubscriptionArrow}
              style={{
                opacity: primaryActionIsBuySubscription ? 1 : 0,
                transform: `translateX(${
                  primaryActionIsBuySubscription ? 0 : -30
                }px)`,
              }}
            />
          </Button>
        )}
      </div>
    </div>
  );
}
