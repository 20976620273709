import { useEffect, useState } from "react";

export function useDelay(delay: number) {
  const [value, setValue] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setValue(true), delay);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return value;
}

export function useDelayedValue<T>(
  value: T,
  options: { delay: number; initialValue?: T }
) {
  const [delayedValue, setDelayedValue] = useState(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    "initialValue" in options ? options.initialValue! : value
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayedValue(value);
    }, options.delay);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return delayedValue;
}
