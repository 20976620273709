import { Button, Collapse, Colors, InputGroup } from "@blueprintjs/core";
import { useEffect, useRef, useState } from "react";
import { SiGithub } from "react-icons/si";
import { Popover } from "../common/Popover";
import { useOperation } from "../common/useOperation";
import css from "./AddUsersButton.module.css";

interface Props {
  label: string;
  onAdd(
    logins: string[]
  ): Promise<{ success: true } | { success: false; error?: string }>;
}

export function AddUsersButton(props: Props) {
  const [error, setError] = useState("");
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const addOperation = useOperation({
    async run(logins: string[]) {
      const result = await props.onAdd(logins);

      setValue("");

      if (result.success) {
        setOpen(false);
      } else if (result.error) {
        setError(result.error);
      }
    },
    onError() {
      setValue("");
    },
  });

  const definiteErrorRef = useRef(error);
  if (error.length > 0) {
    definiteErrorRef.current = error;
  }

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        inputRef.current?.focus({ preventScroll: true });
      }, 100);
    }
    setError("");
    setValue("");
  }, [open]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");

    const logins = value
      .split(",")
      .map((login) => login.trim())
      .filter((login) => login.length > 0);

    if (logins.length === 0) {
      setError("No users to add");
      return;
    }

    addOperation.run(logins);
  };

  return (
    <Popover
      onInteraction={(value) => setOpen(value)}
      isOpen={open}
      content={
        <form className={css.form} onSubmit={handleSubmit}>
          <InputGroup
            fill
            inputRef={inputRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            leftIcon={
              <SiGithub size={40} color={Colors.WHITE} className={css.icon} />
            }
            placeholder="Github usernames, comma separated"
            large
            disabled={addOperation.inProgress}
            rightElement={
              <Button
                minimal
                icon="arrow-right"
                loading={addOperation.inProgress}
                type="submit"
              />
            }
          />
          <Collapse isOpen={error.length > 0}>
            <div className={css.errorContainer}>{definiteErrorRef.current}</div>
          </Collapse>
        </form>
      }
      position="bottom-right"
    >
      <Button active={open} intent="primary">
        {props.label}
      </Button>
    </Popover>
  );
}
