import type { Intent } from "@blueprintjs/core";
import { classes } from "@zilch/css-utils";
import React from "react";
import { useEffect, useRef } from "react";
import css from "./index.module.css";

const validNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] as const;
const validCharacters = new Map(
  validNumbers.map((value) => [value.toString(), value])
);

type ValidNumber = (typeof validNumbers)[number];

export type Code = [
  ValidNumber | null,
  ValidNumber | null,
  ValidNumber | null,
  ValidNumber | null,
  ValidNumber | null,
  ValidNumber | null,
];

interface Props {
  value: Code;
  onChange(value: Code): void;
  className?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  intent?: Intent;
  large?: boolean;
  focusRef?: React.MutableRefObject<(() => void) | undefined>;
}

export function VerificationCodeInput(props: Props) {
  const inputsRef = useRef(new Map<number, HTMLInputElement | null>());

  const createOnChangeHandler = (index: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const entry = e.target.value[e.target.value.length - 1];
      const character = entry ? validCharacters.get(entry) : null;

      if (character === undefined) {
        return;
      }

      const nextValue = [...props.value] as Code;
      nextValue[index] = character;
      props.onChange(nextValue);

      if (character === null && index > 0) {
        inputsRef.current.get(index - 1)?.focus();
      } else if (character !== null && index < 5) {
        inputsRef.current.get(index + 1)?.focus();
      }
    };
  };

  const createOnKeyDownHandler = (index: number) => {
    return (e: React.KeyboardEvent<HTMLInputElement>) => {
      const num = validCharacters.get(e.key);

      if (num !== undefined && num === props.value[index] && index < 5) {
        e.preventDefault();
        inputsRef.current.get(index + 1)?.focus();
      } else if (e.key === "ArrowLeft" && index > 0) {
        e.preventDefault();
        inputsRef.current.get(index - 1)?.focus();
      } else if (e.key === "ArrowRight" && index < 5) {
        e.preventDefault();
        inputsRef.current.get(index + 1)?.focus();
      } else if (
        e.key === "Backspace" &&
        props.value[index] === null &&
        index > 0
      ) {
        e.preventDefault();
        inputsRef.current.get(index - 1)?.focus();
      }
    };
  };

  const createPasteHandler = (index: number) => {
    return (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const clipboardData = e.clipboardData.getData("text/plain").trim();
      const nextValue = [...props.value] as Code;
      let i = index;
      let j = 0;

      while (i < 6) {
        const character = validCharacters.get(clipboardData[j++] ?? "");

        if (character === undefined) {
          break;
        }

        nextValue[i++] = character;
      }

      inputsRef.current.get(Math.min(5, i))?.focus();
      props.onChange(nextValue);
    };
  };

  const focus = () => {
    inputsRef.current.get(0)?.focus({ preventScroll: true });
  };

  if (props.focusRef) {
    props.focusRef.current = focus;
  }

  const autoFocusRef = useRef(props.autoFocus);

  useEffect(() => {
    if (autoFocusRef.current) {
      focus();
    }
  }, []);

  return (
    <div className={classes(css.container, props.className)}>
      {[0, 1, 2, 3, 4, 5].map((index) => {
        return (
          <input
            className={classes(
              "bp4-input",
              css.input,
              props.large && css.large,
              props.intent ? `bp4-intent-${props.intent}` : undefined
            )}
            key={index}
            style={{
              marginLeft: index === 0 ? undefined : "-1px",
              borderTopRightRadius: index === 5 ? undefined : 0,
              borderBottomRightRadius: index === 5 ? undefined : 0,
              borderTopLeftRadius: index === 0 ? undefined : 0,
              borderBottomLeftRadius: index === 0 ? undefined : 0,
            }}
            type="text"
            disabled={props.disabled}
            ref={(input) => inputsRef.current.set(index, input)}
            onFocus={(e) => e.target.select()}
            value={props.value[index] ?? ""}
            onPaste={createPasteHandler(index)}
            onKeyDown={createOnKeyDownHandler(index)}
            onChange={createOnChangeHandler(index)}
          />
        );
      })}
    </div>
  );
}
