import { Colors } from "@blueprintjs/core";
import React from "react";

interface Props {
  size?: number;
  backgroundColor?: string;
  foregroundColor?: string;
  eyeColor?: string;
  style?: React.CSSProperties;
}

export function FullLogo(props: Props) {
  const size = props.size ?? 34 * 2;
  return (
    <div
      style={{
        ...props.style,
        width: size + "px",
        height: size + "px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 34 32"
        fillRule="evenodd"
      >
        <path
          d="M10 1v4H8v4H6v8h2v6H1v8h32v-8h-7v-6h2V9h-2V5h-2V1H10z"
          fill={props.foregroundColor ?? Colors.LIGHT_GRAY5}
        />
        <path
          d="M7 22H0v10h34V22h-7v-4h2V8h-2V4h-2V0H9v4H7v4H5v10h2v4zm5 2h-2v6h2v-6zm2 6h4v-2h-2v-4h-2v6zm6 0h4v-2h-2v-2h2v-2h-4v6zm8-4v-2h-2v6h2v-2h2v2h2v-6h-2v2h-2zM2 24h6l-2.438 4H8v2H2l2.438-4H2v-2zm7-8v-6h2v4h2v2h-2v4H9v2h6v-2h-2v-2h8v2h-2v2h6v-2h-2v-4h-2v-2h2v-4h2V6h-2V2H11v4H9v4H7v6h2zm18-6h-2v6h2v-6zM13 4v8h8V4h-8z"
          fill={props.backgroundColor ?? Colors.BLACK}
        />
        <path d="M15 6h2v2h-2z" fill={props.eyeColor ?? Colors.FOREST5} />
      </svg>
    </div>
  );
}
