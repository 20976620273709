import type { GameId } from "@zilch/game-config";
import type { SlotStats, TournamentProgress } from "./TournamentScreen";
import { useBotConfig } from "./TournamentSlot";
import { Popover } from "../common/Popover";
import { Button, Colors, FormGroup, Icon, Menu } from "@blueprintjs/core";
import { MenuItem2 } from "@blueprintjs/popover2";
import { BotAvatarSvg } from "@zilch/bot-avatar-svg";
import type { SlotSelection } from "@zilch/bot-models";
import { BotColor } from "@zilch/bot-models";
import { transitionInFromCss } from "@zilch/css-utils";
import { useMemo } from "react";

interface Props {
  progress: TournamentProgress;
  selectedIndex: "all" | number;
  onSetSelectedIndex(selectedIndex: "all" | number): void;
  gameId: GameId;
}

export function TournamentSummaryBotMenu(props: Props) {
  const selectedSlot =
    props.selectedIndex === "all"
      ? null
      : props.progress.slots[props.selectedIndex] ?? null;
  const botConfig = useBotConfig(selectedSlot, props.gameId);
  const selectedSlotStats =
    props.selectedIndex === "all"
      ? null
      : props.progress.statsBySlot[props.selectedIndex] ?? null;

  const rankSortedSlots = [...props.progress.slots]
    .map((slot, slotIndex) => {
      return {
        slot,
        slotIndex,
        rank: props.progress.statsBySlot[slotIndex]?.rank ?? 0,
      };
    })
    .sort((a, b) => a.rank - b.rank);

  return (
    <FormGroup
      label={
        <span style={{ fontWeight: 500, color: Colors.GRAY4 }}>Games by</span>
      }
      style={{ marginBottom: "0px" }}
    >
      <Popover
        minimal
        placement="bottom"
        fill
        matchTargetWidth
        content={
          <Menu large>
            {props.selectedIndex !== "all" && (
              <MenuItem2
                icon={
                  <Icon icon="layers" size={16} style={{ padding: "3px" }} />
                }
                text="All bots"
                onClick={() => props.onSetSelectedIndex("all")}
              />
            )}
            {rankSortedSlots.map(({ slot, slotIndex }) => {
              if (props.selectedIndex === slotIndex) {
                return null;
              }

              return (
                <BotMenuItem
                  onClick={() => {
                    props.onSetSelectedIndex(slotIndex);
                  }}
                  slotStats={props.progress.statsBySlot[slotIndex] ?? null}
                  slot={slot}
                  key={slot.transitionData.slotId}
                  gameId={props.gameId}
                />
              );
            })}
          </Menu>
        }
      >
        <Button
          large
          fill
          alignText="left"
          rightIcon="caret-down"
          key={selectedSlot?.transitionData.slotId ?? ""}
          icon={
            botConfig && selectedSlot ? (
              <BotAvatarSvg
                key={selectedSlot.transitionData.slotId}
                className={transitionInFromCss.bottom}
                avatar={botConfig.avatar}
                color={BotColor[selectedSlot.color]}
                size={22}
              />
            ) : (
              <Icon
                icon="layers"
                size={16}
                style={{ padding: "3px" }}
                className={transitionInFromCss.bottom}
              />
            )
          }
        >
          <div
            className={transitionInFromCss.bottom}
            key={selectedSlot?.transitionData.slotId ?? 0}
          >
            {props.selectedIndex === "all" ? (
              "All bots"
            ) : (
              <>
                {botConfig?.name}{" "}
                {selectedSlot?.type === "boss" ? (
                  <>
                    <span style={{ color: Colors.GRAY4 }}>
                      ({selectedSlot.difficulty})
                    </span>{" "}
                  </>
                ) : (
                  ""
                )}
                {selectedSlotStats && <BotRank rank={selectedSlotStats.rank} />}
              </>
            )}
          </div>
        </Button>
      </Popover>
    </FormGroup>
  );
}

function BotMenuItem(props: {
  slot: NonNullable<SlotSelection>;
  slotStats: SlotStats | null;
  gameId: GameId;
  onClick(): void;
}) {
  const botConfig = useBotConfig(props.slot, props.gameId);

  if (!botConfig) {
    return null;
  }

  return (
    <MenuItem2
      style={{
        position: "relative",
        zIndex: 1,
      }}
      icon={
        <BotAvatarSvg
          avatar={botConfig.avatar}
          size={22}
          color={BotColor[props.slot.color]}
        />
      }
      onClick={props.onClick}
      text={
        <>
          {botConfig.name}{" "}
          {props.slot.type === "boss" ? (
            <>
              <span style={{ color: Colors.GRAY4 }}>
                ({props.slot.difficulty})
              </span>{" "}
            </>
          ) : (
            ""
          )}
          <BotRank rank={props.slotStats?.rank ?? null} />
        </>
      }
    />
  );
}

function BotRank(props: { rank: number | null }) {
  const ordinalSuffix = useMemo(() => {
    if (props.rank === null) {
      return "";
    }
    const j = (props.rank + 1) % 10;
    const k = (props.rank + 1) % 100;
    if (j == 1 && k != 11) {
      return "st";
    }
    if (j == 2 && k != 12) {
      return "nd";
    }
    if (j == 3 && k != 13) {
      return "rd";
    }
    return "th";
  }, [props.rank]);

  if (props.rank === null) {
    return null;
  }

  return (
    <span
      style={{
        marginLeft: "4px",
        fontWeight: 500,
        color: Colors.GRAY4,
        fontSize: "14px",
      }}
    >
      {props.rank + 1}
      {ordinalSuffix}
    </span>
  );
}
