import { Colors, FormGroup } from "@blueprintjs/core";
import { PremiumStore } from "../../stores/PremiumStore";
import css from "./PricingOverview.module.css";
import { GiLaurelsTrophy } from "react-icons/gi";

export function PricingOverview() {
  const premiumStore = PremiumStore.use();

  const displayPrice = (
    Math.max(2, premiumStore.seats) * ClientEnv.YEARLY_PREMIUM_PRICE_PER_SEAT
  ).toFixed(2);

  return (
    <div
      className={css.pricingOuterContainer}
      style={{
        height: premiumStore.section === "checkout" ? 105 : 0,
        transition:
          premiumStore.section === "checkout"
            ? "all cubic-bezier(.4,1.5,.4,1) .4s .3s"
            : "all ease .3s 0s",
        opacity: premiumStore.section === "checkout" ? 1 : 0,
      }}
    >
      <div className={css.pricingContainer}>
        <FormGroup
          label={
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <GiLaurelsTrophy color={Colors.GOLD5} size={20} />
              <b style={{ color: Colors.GOLD5 }}>One year of Zilch Premium</b>
              <span className="bp4-text-muted">(cancel anytime)</span>
            </div>
          }
          style={{ marginBottom: "0px", width: "100%" }}
        >
          <div
            style={{
              lineHeight: "40px",
              fontWeight: 600,
              fontSize: "18px",
              transition: "all ease .3s",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              color: Colors.LIGHT_GRAY2,
            }}
          >
            <span>
              ${ClientEnv.YEARLY_PREMIUM_PRICE_PER_SEAT.toFixed(2)} per seat
            </span>
            <span className="bp4-text-muted">×</span>
            <span>{premiumStore.seats} seats</span>
            <span className="bp4-text-muted">=</span>
            <span>${displayPrice} due today</span>
          </div>
        </FormGroup>
      </div>
    </div>
  );
}
