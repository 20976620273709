.playButtonContainer {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  background: rgba(17, 20, 24, 0.6);
  backdrop-filter: blur(5px);
  margin-top: -35px;
  margin-left: -35px;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: all ease 0.3s;
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
}

.playButton {
  transform: translateX(1px);
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
}

.video:hover .playButtonContainer {
  transform: scale(1.3);
}

.video:hover {
  outline: 2px solid var(--blue5);
  outline-offset: 5px;
}

.video:active .playButtonContainer {
  transform: scale(0.9);
}

.video {
  width: 100%;
  display: block;
  cursor: pointer;
  background-size: cover;
  border-radius: 8px;
  box-shadow: 0 7px 15px -2px var(--black);
  position: relative;
  transition: all ease 0.2s;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.time {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-weight: 700;
  padding: 4px 10px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  backdrop-filter: blur(5px);
  background: rgba(17, 20, 24, 0.7);
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
}
