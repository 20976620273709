import type { GameId } from "@zilch/game-config";
import css from "./TournamentSlotList.module.css";
import type { BotColor } from "@zilch/bot-models";
import { Button } from "@blueprintjs/core";
import { TournamentSlot } from "./TournamentSlot";
import { classes } from "@zilch/css-utils";
import { Popover } from "../common/Popover";
import { GuideContent } from "../game/GuideContent";
import { useDelayedValue } from "@zilch/delay";
import React, { useMemo, useState } from "react";
import { DataTransition } from "./DataTransition";
import stringify from "fast-json-stable-stringify";
import type { TournamentManager } from "./TournamentScreen";
import { usePulse } from "@zilch/use-pulse";
import { PremiumStore } from "../../stores/PremiumStore";

interface Props {
  tournamentManager: TournamentManager;
  gameId: GameId | null;
  onOpenBotSelector(color?: BotColor): void;
  onRemove(slotIndex: number): void;
  onSetDifficulty(
    slotIndex: number,
    difficulty: "easy" | "medium" | "hard"
  ): void;
  onChangeColor(slotIndex: number, color: BotColor): void;
  botSelectorOpen: boolean;
  triggerNotEnoughBotsIndicatorRef: React.MutableRefObject<(() => void) | null>;
  viewStatsOpen: boolean;
  onViewStats(slotIndex: number): void;
  small: boolean;
}

export function TournamentSlotList(props: Props) {
  const progress = props.tournamentManager.progress;
  const sectionVisible =
    useDelayedValue(props.gameId !== null, { delay: 300 }) &&
    props.gameId !== null;

  const noSlots = progress.slots.length === 0;
  const delayedNoSlots = useDelayedValue(noSlots, { delay: 400 });

  const showNoBotsMessage =
    delayedNoSlots && noSlots && sectionVisible && !props.botSelectorOpen;

  const usedColors = useMemo(() => {
    return new Set(progress.slots.map((slot) => slot.color));
  }, [progress.slots]);

  const activeBots = useMemo(() => {
    const activeBots = new Set<number>();

    if (progress.state !== "in-progress") {
      return activeBots;
    }

    for (const matchup of progress.matchups) {
      if (matchup.games.some((game) => game === "in-progress")) {
        activeBots.add(matchup.bot1SlotIndex);
        activeBots.add(matchup.bot2SlotIndex);
      }
    }
    return activeBots;
  }, [progress]);

  const [renderPulse, triggerPulse] = usePulse("gold", { borderRadius: 2 });
  props.triggerNotEnoughBotsIndicatorRef.current = triggerPulse;

  const premiumStore = PremiumStore.use();

  const [scrolledPast50, setScrolledPast50] = useState(false);

  return (
    <div
      className={css.outerContainer}
      onScroll={(e) => {
        setScrolledPast50((e.target as HTMLDivElement).scrollTop > 50);
      }}
    >
      <div
        className={classes(
          css.innerContainer,
          props.small && css.smallInnerContainer,
          props.gameId === null && css.hidden
        )}
        style={{
          minHeight: `max(100%, ${
            progress.slots.length * 87 + (props.small ? 250 : 170)
          }px)`,
        }}
      >
        <Popover
          placement="bottom"
          isOpen={showNoBotsMessage && premiumStore.hasPremium}
          className={classes(
            css.topSection,
            progress.state === "in-progress" && css.topSectionHidden
          )}
          content={
            <GuideContent
              title="Add some bots..."
              message={
                <>
                  Select at least <b>2</b> bots to start the tournament.
                </>
              }
            />
          }
          background="blue"
        >
          {renderPulse(
            <Button
              style={{
                width: progress.slots.length === 0 ? "230px" : "300px",
              }}
              minimal
              large
              icon="plus"
              disabled={
                !premiumStore.hasPremium ||
                !props.gameId ||
                progress.state === "in-progress"
              }
              onClick={() => props.onOpenBotSelector()}
            >
              Add Bots
            </Button>
          )}
        </Popover>
        <DataTransition
          exitDuration={500}
          items={progress.slots}
          getItemKey={(slot) => stringify(slot)}
          render={(slot, transitionState, slotIndex) => {
            if (!props.gameId) {
              return null;
            }

            return (
              <TournamentSlot
                progress={progress}
                usedColors={usedColors}
                hideViewStatsIndicator={
                  props.botSelectorOpen || props.viewStatsOpen || scrolledPast50
                }
                slotStats={progress.statsBySlot[slotIndex] ?? null}
                gameId={props.gameId}
                slotSelection={slot}
                onSetDifficulty={(difficulty) => {
                  props.onSetDifficulty(slotIndex, difficulty);
                }}
                onViewStats={() => props.onViewStats(slotIndex)}
                active={activeBots.has(slotIndex)}
                transitionState={transitionState}
                sortIndex={progress.statsBySlot[slotIndex]?.rank ?? slotIndex}
                onRemove={() => props.onRemove(slotIndex)}
                onChangeColor={(color) => props.onChangeColor(slotIndex, color)}
                onSwap={() => props.onOpenBotSelector(slot.color)}
              />
            );
          }}
        />
      </div>
    </div>
  );
}
