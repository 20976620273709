.container {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  transition: transform cubic-bezier(0.5, 1.4, 0.5, 1) 0.5s;
  transform: translateY(0px);
  background: rgba(17, 20, 24, 0.8);
  border: 1px solid rgba(95, 107, 124, 0.2);
  border-radius: 29px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-top: 5px;
  padding-left: 15px;
  padding-bottom: 5px;
  padding-right: 5px;
}

.hidden {
  transform: translateY(180px);
  pointer-events: none;
  transition: transform ease 0.2s;
}

.leftSection {
  width: 180px;
}

.leftSectionText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.stopButtonContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background: var(--dark-gray3);
  border-radius: 50%;
}
