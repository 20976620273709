.entireOrganizationLink {
  border: 1px solid var(--dark-gray4);
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  padding: 10px 14px;
  display: flex;
  justify-content: space-between;
  text-decoration: none !important;
  position: relative;
  margin-top: -1px;
  cursor: pointer;
}

.entireOrganizationLink:hover {
  border-color: var(--gray1);
  z-index: 1;
}

.label {
  margin-bottom: 6px;
  margin-top: 16px;
  color: var(--light-gray3);
}

.recommendationsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.recommendation {
  border: 1px solid var(--dark-gray4);
  padding: 10px 14px;
  color: var(--gray5);
  position: relative;
  font-weight: 500;
  cursor: pointer;
}

.recommendationActive,
.recommendation:hover {
  border-color: var(--gray1);
  color: var(--light-gray5);
  z-index: 1;
}

.recommendationSeats {
  font-size: 24px;
  font-weight: 700;
}

.container {
  height: 150px;
  overflow: hidden;
}

.containerHidden {
  height: 0px;
  pointer-events: none;
  opacity: 0;
}

.entireOrganizationLabel {
  color: var(--gray5);
  font-weight: 500;
}

.entireOrganizationLink:hover .entireOrganizationLabel {
  color: var(--light-gray5);
}

.entireOrganizationSecondaryLabel {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--gray4);
}

.entireOrganizationLink:hover .entireOrganizationSecondaryLabel {
  color: var(--light-gray5);
}
