import { Button, Dialog as BpDialog } from "@blueprintjs/core";
import React from "react";
import css from "./Dialog.module.css";

interface Props {
  isOpen: boolean;
  width: number;
  onClose(): void;
  children: React.ReactNode;
}

export function Dialog(props: Props) {
  return (
    <BpDialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      className={css.dialog}
      style={{ width: props.width }}
    >
      <Button
        icon="cross"
        className={css.closeButton}
        onClick={props.onClose}
      />
      {props.children}
    </BpDialog>
  );
}
