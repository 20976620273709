.title {
  margin-top: 40px !important;
  margin-bottom: 20px !important;
}

.titleCollapsible {
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}