.dialog {
  display: grid !important;
  background: var(--dark-gray3) !important;
  width: 533px !important;
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 20px);
  min-width: 300px;
  grid-template-columns: auto;
  grid-template-rows: 395px;
  padding-bottom: 0px !important;
  overflow: hidden;
}

.exitButton {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 100% !important;
  z-index: 10;
}

.stackContainer {
  height: 100%;
}

.dialogContent {
  width: 100%;
  height: 100%;
  position: relative;
}

.stackContainer > :global(.bp4-panel-stack-view) {
  background: transparent !important;
}
