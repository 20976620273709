import { forwardRef } from "react";
import { Popover2, type Popover2Props } from "@blueprintjs/popover2";
import css from "./Popover.module.css";
import { classes } from "@zilch/css-utils";

export const Popover = forwardRef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Popover2<any>,
  Popover2Props & {
    children?: React.ReactNode;
    background?: "blue" | "green" | "red";
  }
>(({ background, ...props }, ref) => {
  return (
    <Popover2
      {...props}
      ref={ref}
      modifiers={{
        ...props.modifiers,
        preventOverflow: props.modifiers?.preventOverflow ?? {
          options: {
            altAxis: true,
            padding: 15,
          },
        },
      }}
      popoverClassName={classes(
        props.popoverClassName,
        background === "blue" && css.blue,
        background === "green" && css.green,
        background === "red" && css.red
      )}
    />
  );
});
