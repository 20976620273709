.container {
  padding: 5px 5px 5px 6px;
  display: grid;
  height: 100%;
  min-width: 290px;
  gap: 5px;
}

.outerTerminalContainer {
  border-radius: 4px;
  border: 1px solid;
  transition: all ease .3s;
  position: relative;
}

.terminalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  text-transform: uppercase;
  gap: 4px;
  padding: 2px;
  padding-right: 6px;
  font-weight: 700;
  letter-spacing: 1px;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.timeLimitExceeded {
  color: var(--red5) !important;
  font-weight: 900 !important;
  transition: all ease .3s;
}

.botTime {
  top: 1px;
  right: 1px;
  bottom: 1px;
  border-radius: 2px;
  transition: none;
  white-space: nowrap;
  position: absolute;
  font-size: 12px;
  letter-spacing: 0px;
  padding-left: 20px;
  line-height: 18px;
  font-weight: 500;
  font-family: monospace;
  text-transform: none;
  padding-right: 6px;
  background: linear-gradient(to right, transparent, var(--dark-gray1) 20px, var(--dark-gray1) 100%);
}

.terminalToggle {
  display: flex;
  align-items: center;
  gap: 3px;
  white-space: nowrap;
  cursor: pointer;
  opacity: 0.9;
  padding: 2px 4px;
  border-radius: 2px;
  transition: all ease .3s;
}

.terminalToggle:hover {
  background: var(--dark-gray4);
}

.terminalToggle:active {
  background: var(--dark-gray3);
}

.innerTerminalContainer {
  position: absolute;
  overflow: hidden;
  left: 10px;
  right: 1px;
  top: 24px;
  bottom: 1px;
}

.terminal {
  width: 100%;
  height: 100%;
}