import { Button, Colors, H6 } from "@blueprintjs/core";
import { plural } from "@zilch/plural";
import type {
  SubscriptionWithTeams,
  Team,
} from "../../../backend/db/subscription";
import { Popover } from "../common/Popover";
import { Login } from "./Login";
import { Subscription } from "./Subscription";

interface Props {
  team: Team;
  subscription: SubscriptionWithTeams;
}

export function SubscriptionSeatInfoButton({ team, subscription }: Props) {
  const exceededSeatCount = subscription.usedSeatCount > subscription.seatCount;
  const subscriptionInactive =
    subscription.status !== "active" && subscription.status !== "incomplete";
  const seatsOpen = subscription.seatCount - subscription.usedSeatCount;

  const content = (
    <div style={{ padding: "15px", width: "440px" }}>
      <H6>Overview</H6>
      <div style={{ marginBottom: "15px" }} className="bp4-text-muted">
        <b style={{ color: Colors.WHITE }}>{team.name}</b> shares its open seat
        count with all teams created under{" "}
        <b style={{ color: Colors.WHITE }}>
          subscription <code>{subscription.shortId}</code>
        </b>
        . Users on multiple teams do not take multiple seats. Admins can
        add/remove team members but only the subscription owner{" "}
        <Login
          userId={subscription.ownerId}
          render={(login) => (
            <a
              style={{ fontWeight: 600 }}
              href={`https://www.github.com/${login}`}
            >
              {login}
            </a>
          )}
        />{" "}
        can purchase more seats and manage billing details.
      </div>
      <H6>Subscription</H6>
      <Subscription
        subscription={subscription}
        disabled={team.role !== "owner"}
      />
    </div>
  );

  return (
    <Popover position="bottom-left" content={content}>
      <Button minimal icon="people">
        <span className="bp4-text-muted">
          {plural(team.memberCount, "member")} ·{" "}
          {subscriptionInactive || exceededSeatCount ? (
            <span style={{ color: Colors.RED5 }}>
              {exceededSeatCount ? "Limit exceeded" : "Subscription inactive"}
            </span>
          ) : (
            <span style={{ color: seatsOpen > 0 ? Colors.GREEN5 : undefined }}>
              {plural(seatsOpen, "seat")} open
            </span>
          )}
        </span>
      </Button>
    </Popover>
  );
}
