import type { Intent } from "@blueprintjs/core";
import { Button, Colors, Icon, type IconName } from "@blueprintjs/core";
import { classes } from "@zilch/css-utils";
import React from "react";
import { useState } from "react";
import css from "./DetailedButton.module.css";

interface Props {
  icon: IconName;
  titleLabel: React.ReactNode;
  title: React.ReactNode;
  detailsIntent?: Intent;
  details: React.ReactNode;
  disabled?: boolean;
  footer?: {
    subscriptionId: string;
  };
  onOpen: () => void | Promise<void>;
}

export function DetailedButton(props: Props) {
  const [opening, setOpening] = useState(false);

  const intentColor = {
    none: undefined,
    danger: Colors.RED5,
    success: Colors.GREEN5,
    warning: Colors.ORANGE5,
    primary: Colors.BLUE5,
  }[props.detailsIntent ?? "none"];

  return (
    <div
      className={classes(
        css.container,
        props.disabled && css.containerDisabled
      )}
    >
      <Button
        loading={opening}
        alignText="left"
        fill
        disabled={props.disabled}
        icon={<Icon icon={props.icon} className={css.icon} />}
        rightIcon={<Icon color={intentColor} icon="chevron-right" />}
        onClick={() => {
          const action = props.onOpen();
          if (action && typeof action.finally === "function") {
            setOpening(true);
            action.finally(() => setOpening(false));
          }
        }}
      >
        <div className={css.contentContainer}>
          <div className={css.titleContainer}>
            <div className={css.created}>{props.titleLabel}</div>
            <b className={css.title}>{props.title}</b>
          </div>
          <div style={{ color: intentColor }} className={css.rightSection}>
            {props.details}
          </div>
        </div>
      </Button>
      {props.footer && (
        <div className={classes(css.footer, "bp4-text-muted")}>
          <span>Subscription ID</span>
          <code>{props.footer.subscriptionId}</code>
        </div>
      )}
    </div>
  );
}
