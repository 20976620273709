import { Button, Colors, Drawer, Icon, Menu } from "@blueprintjs/core";
import { panic } from "@zilch/panic";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import css from "./Playbook.module.css";
import { MenuItem2 } from "@blueprintjs/popover2";
import { SiDiscord } from "react-icons/si";
import { discordJoinLink } from "@zilch/discord";
import { MenuTag } from "../common/MenuTag";
import { Popover } from "../common/Popover";
import { GuideContent } from "../game/GuideContent";
import { useDelay } from "@zilch/delay";
import type { TransitionState } from "../tournament/DataTransition";
import { DataTransition } from "../tournament/DataTransition";
import type { BotColor } from "@zilch/bot-models";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import { BuildingYourFirstZilchBot } from "./BuildingYourFirstZilchBot";
import { usePrevious } from "@zilch/use-previous";
import { Reveal } from "../common/Reveal";
import { buildingFirstBotVideoRelativeLink } from "@zilch/video-links";

export interface Tip {
  type: "building-your-first-bot";
  target: "codespace" | "github";
  botName: string;
  avatar: string;
  preferredColor: BotColor;
  owner: string;
  repo: string;
}

type PlaybookSection =
  | { name: "overview"; tip?: Tip }
  | { name: "building-your-first-bot"; tip: Tip }
  | { name: "table-tennis"; tip?: Tip }
  | { name: "capture-the-flag"; tip?: Tip }
  | { name: "chess"; tip?: Tip }
  | { name: "tic-tac-toe"; tip?: Tip }
  | { name: "none"; tip?: Tip };

interface PlaybookStore {
  emphasizeClosePlaybook: boolean;
  setEmphasizeClosePlaybook(value: boolean): void;

  emphasizeStartGame: boolean;
  setEmphasizeStartGame(value: boolean): void;

  section: PlaybookSection;
  setSection(
    value: PlaybookSection | ((value: PlaybookSection) => PlaybookSection)
  ): void;
}

const context = createContext<PlaybookStore | null>(null);

export function ProvidePlaybook({ children }: { children: React.ReactNode }) {
  const [section, setSection] = useState<PlaybookSection>({ name: "none" });
  const [emphasizeStartGame, setEmphasizeStartGame] = useState(false);
  const [emphasizeClosePlaybook, setEmphasizeClosePlaybook] = useState(false);

  const store: PlaybookStore = {
    section,
    setSection,
    emphasizeStartGame,
    setEmphasizeStartGame,
    emphasizeClosePlaybook,
    setEmphasizeClosePlaybook,
  };

  useEffect(() => {
    setEmphasizeClosePlaybook(false);
  }, [section.name]);

  return (
    <context.Provider value={store}>
      {children}
      <PlaybookDrawer store={store} />
    </context.Provider>
  );
}

export function usePlaybook() {
  return useContext(context) ?? panic("playbook must be provided");
}

function PlaybookDrawer({ store }: { store: PlaybookStore }) {
  return (
    <Drawer
      position="right"
      isOpen={store.section.name !== "none"}
      onClose={() => {
        store.setSection((section) => {
          return { ...section, name: "none" };
        });
      }}
      size="400px"
      className={css.playbook}
    >
      <PlaybookContent />
    </Drawer>
  );
}

function PlaybookHeader() {
  const playbook = usePlaybook();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "18px",
        justifyContent: "space-between",
        padding: "20px",
        margin: "1px",
        borderBottom: `1px solid rgba(255,255,255,.1)`,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "18px",
        }}
      >
        <Icon
          icon="playbook"
          size={20}
          style={{
            padding: "14px",
            color: Colors.GRAY4,
            borderRadius: "100%",
            background: Colors.DARK_GRAY2,
          }}
        />
        <div>
          <div style={{ fontSize: "18px", fontWeight: 600 }}>Playbook</div>
          <div className="bp4-text-muted">Helpful resources</div>
        </div>
      </div>
      <Button
        large
        minimal={!playbook.emphasizeClosePlaybook}
        icon="cross"
        intent={playbook.emphasizeClosePlaybook ? "primary" : "none"}
        style={{ borderRadius: "100%" }}
        onClick={() => {
          playbook.setSection((section) => ({ ...section, name: "none" }));
        }}
      />
    </div>
  );
}

function PlaybookContent() {
  const playbook = usePlaybook();
  const playbookSectionRef = useRef(
    playbook.section.name === "none"
      ? { name: "overview" as const, tip: playbook.section.tip }
      : playbook.section
  );
  if (playbook.section.name !== "none") {
    playbookSectionRef.current = playbook.section;
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "89px 1fr",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <PlaybookHeader />
      <div style={{ overflow: "hidden", position: "relative" }}>
        <DataTransition
          skipInitialEnterTransition
          items={[playbookSectionRef.current]}
          exitDuration={300}
          getItemKey={(section) =>
            section.name === "building-your-first-bot"
              ? "building-your-first-bot"
              : "overview"
          }
          render={(section, transitionState) => {
            if (section.name === "building-your-first-bot") {
              return (
                <BuildingYourFirstZilchBot
                  tip={section.tip}
                  transitionState={transitionState}
                />
              );
            } else {
              return <PlaybookOverview transitionState={transitionState} />;
            }
          }}
        />
      </div>
    </div>
  );
}

export function PlaybookButton() {
  const playbook = usePlaybook();
  const [open, setOpen] = useState(false);
  const alreadyShowedPlaybookClosedMessageForThisTip = useRef(false);

  useEffect(() => {
    if (
      playbook.section.name === "none" &&
      playbook.section.tip &&
      !alreadyShowedPlaybookClosedMessageForThisTip.current
    ) {
      alreadyShowedPlaybookClosedMessageForThisTip.current = true;
      setOpen(true);
    }
  }, [playbook.section]);

  const hasTip = !!playbook.section.tip;
  const previousHasTip = usePrevious(hasTip);
  const resetAlreadyShowedPlaybookClosedMessage =
    !hasTip &&
    previousHasTip &&
    alreadyShowedPlaybookClosedMessageForThisTip.current;

  useEffect(() => {
    if (resetAlreadyShowedPlaybookClosedMessage) {
      alreadyShowedPlaybookClosedMessageForThisTip.current = false;
    }
  }, [resetAlreadyShowedPlaybookClosedMessage]);

  return (
    <Popover
      isOpen={open}
      onClose={() => {
        setOpen(false);
      }}
      content={
        <Reveal>
          {(text) => (
            <GuideContent
              title={text("Playbook closed...")}
              message={text("Open it again whenever you need a hand.")}
            />
          )}
        </Reveal>
      }
      background="blue"
    >
      <div style={{ position: "relative" }}>
        <Button
          minimal
          active={open}
          onClick={() =>
            playbook.setSection((section) => {
              return { ...section, name: "overview" };
            })
          }
          icon={<Icon size={18} icon="playbook" color={Colors.GRAY4} />}
          className={transitionInFromCss.top}
        />
        <div
          className={classes(
            css.attentionDot,
            playbook.section.name === "none" &&
              !!playbook.section.tip &&
              !open &&
              css.attentionDotVisible
          )}
        />
      </div>
    </Popover>
  );
}

function PlaybookOverview({
  transitionState,
}: {
  transitionState: TransitionState;
}) {
  const playbook = usePlaybook();
  return (
    <div
      style={{
        overflowY: "scroll",
        padding: "14px",
        paddingRight: "0px",
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        transition: "all ease .3s",
        opacity: transitionState === "entered" ? 1 : 0,
        transform:
          transitionState === "entered"
            ? "translateX(0%)"
            : "translateX(-100%)",
      }}
    >
      <Menu large style={{ background: "transparent" }}>
        <div
          className={
            playbook.section.tip ? "bp4-focus-style-manager-ignore" : undefined
          }
        >
          <MenuItem2
            href={
              playbook.section.tip
                ? undefined
                : "/building-your-first-zilch-bot"
            }
            target="_blank"
            onClick={() => {
              if (playbook.section.tip) {
                playbook.setSection({
                  name: "building-your-first-bot",
                  tip: playbook.section.tip,
                });
              }
            }}
            className={
              playbook.section.tip?.type === "building-your-first-bot"
                ? css.menuItemWithTip
                : undefined
            }
            labelElement={
              playbook.section.tip?.type === "building-your-first-bot" ? (
                <Icon
                  icon="chevron-right"
                  style={{ transform: "translateY(-1px)" }}
                  color={Colors.GRAY4}
                />
              ) : undefined
            }
            icon="star"
            text="Building your First Zilch Bot"
          />
        </div>
        <MenuItem2
          target="_blank"
          href="/introducing-zilch"
          icon="manual"
          text="Introducing Zilch"
        />
        <MenuItem2
          icon="video"
          href={buildingFirstBotVideoRelativeLink}
          target="_blank"
          text="Table Tennis"
          labelElement={<MenuTag>New</MenuTag>}
        />
        <LessonsComingSoon
          section={{ name: "tic-tac-toe" }}
          gameName="Tic-Tac-Toe"
        />
        <LessonsComingSoon section={{ name: "chess" }} gameName="Chess" />
        <LessonsComingSoon
          section={{ name: "capture-the-flag" }}
          gameName="Capture the Flag"
        />
        <MenuItem2
          icon={<SiDiscord />}
          text="Discord"
          target="_blank"
          rel="noreferrer"
          href={discordJoinLink}
        />
        <div
          style={{ padding: "5px", marginTop: "5px", fontSize: "16px" }}
          className="bp4-text-muted"
        >
          More Playbook content coming soon.
        </div>
      </Menu>
    </div>
  );
}

function LessonsComingSoon(props: {
  gameName: string;
  section: PlaybookSection;
}) {
  const playbook = usePlaybook();

  const openDelay = useDelay(300);

  return (
    <Popover
      background="blue"
      position="left"
      fill
      isOpen={openDelay && playbook.section.name === props.section.name}
      onClose={() => {
        playbook.setSection((section) => {
          if (section.name === props.section.name) {
            return { name: "overview" };
          } else {
            return section;
          }
        });
      }}
      content={
        <GuideContent
          title="Coming soon"
          message="What do you think would be helpful here?"
          action={{
            text: "Share feedback",
            href: "mailto:hello@zilch.dev",
          }}
        />
      }
    >
      <MenuItem2
        icon="video"
        onClick={() => {
          playbook.setSection(props.section);
        }}
        text={props.gameName}
        labelElement={<MenuTag>Coming soon</MenuTag>}
      />
    </Popover>
  );
}
