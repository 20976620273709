import { Button, Colors, Icon, Slider } from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useDelay } from "@zilch/delay";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import { kebabCase } from "lodash";
import type { TournamentManager } from "./TournamentScreen";
import css from "./TournamentSetup.module.css";
import { PromptStore } from "../../stores/PromptStore";
import { DataTransition } from "./DataTransition";
import { PremiumStore } from "../../stores/PremiumStore";

interface Props {
  tournamentManager: TournamentManager;
  botSelectorOpen: boolean;
  onShowNotEnoughBotsIndicator(): void;
}

export function TournamentSetup(props: Props) {
  const { tournamentManager } = props;

  const visible =
    useDelay(200) &&
    tournamentManager.progress.state !== "in-progress" &&
    tournamentManager.engine.instance;

  const prompt = PromptStore.usePrompt();

  const premiumStore = PremiumStore.use();

  return (
    <div className={classes(css.container, !visible && css.hidden)}>
      <StartTournamentButton {...props} />
      <div
        className={classes(transitionInFromCss.top, css.configFormContainer)}
      >
        <Button
          rightIcon="cog"
          disabled={!premiumStore.hasPremium}
          fill
          minimal
          alignText="left"
          onClick={async () => {
            const gamesPerMatchup = await prompt<number>((props) => {
              return (
                <GamesPerMatchupEditor
                  gamesPerMatchup={tournamentManager.gamesPerMatchup}
                  onUpdate={props.resolve}
                  bots={tournamentManager.progress.slots.length}
                />
              );
            });

            if (gamesPerMatchup !== null) {
              tournamentManager.setGamesPerMatchup(gamesPerMatchup);
              tournamentManager.setProgress((progress) => {
                return {
                  ...progress,
                  statsBySlot: [],
                  state: "not-started",
                };
              });
            }
          }}
        >
          <span
            className={premiumStore.hasPremium ? "bp4-text-muted" : undefined}
          >
            Run{" "}
            <b
              style={{
                color: premiumStore.hasPremium ? Colors.WHITE : undefined,
              }}
            >
              {tournamentManager.gamesPerMatchup}
            </b>{" "}
            games per matchup
          </span>
        </Button>
      </div>
    </div>
  );
}

function StartTournamentButton({
  tournamentManager,
  botSelectorOpen,
  onShowNotEnoughBotsIndicator,
}: Props) {
  const [showLeftToPick, setShowLeftToPick] = useState(false);

  const numFilledSlots = tournamentManager.progress.slots.length;
  const minSlots = 2; // TODO min slots must be two to be compatible with tournament mode
  const leftToFill = Math.max(0, minSlots - numFilledSlots);
  const readyToStart = numFilledSlots >= minSlots && !botSelectorOpen;

  const premiumStore = PremiumStore.use();

  const [startTournamentText, setStartTournamentText] = useState<
    { node: React.ReactNode; key: string } | string
  >("Start Tournament");

  useEffect(() => {
    if (readyToStart) {
      setShowLeftToPick(false);
    }
  }, [readyToStart]);

  let buttonContents: {
    key: string;
    text: React.ReactNode;
  } = {
    key:
      "start-tournament-" +
      kebabCase(
        typeof startTournamentText === "string"
          ? startTournamentText
          : startTournamentText.key
      ),
    text: (
      <span style={{ fontWeight: 600 }}>
        {typeof startTournamentText === "string"
          ? startTournamentText
          : startTournamentText.node}
      </span>
    ),
  };

  if (showLeftToPick) {
    buttonContents = {
      key: "left-to-pick-" + leftToFill,
      text: (
        <span className="bp4-text-muted">
          Pick <b>{leftToFill}</b> more {leftToFill === 1 ? "bot" : "bots"}
        </span>
      ),
    };
  } else if (tournamentManager.progress.state === "done") {
    buttonContents = {
      key: "play-again",
      text: <span style={{ fontWeight: 600 }}>Rerun Tournament</span>,
    };
  }

  const notStarted = tournamentManager.progress.state === "not-started";
  const notStartedRef = useRef(notStarted);
  notStartedRef.current = notStarted;

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (readyToStart && notStartedRef.current) {
      timeout = setTimeout(() => {
        setStartTournamentText({
          key: "thumbs-up",
          node: <span style={{ fontSize: "24px" }}>👍</span>,
        });
      }, 200);
    } else {
      setStartTournamentText("Start Tournament");
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [readyToStart]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const setStartTournamentTextWithDelay = (text: string) => {
      timeout = setTimeout(() => {
        setStartTournamentText(text);
      }, 500);
    };

    if (buttonContents.key === "start-tournament-thumbs-up") {
      setStartTournamentTextWithDelay("Ready");
    } else if (buttonContents.key === "start-tournament-ready") {
      setStartTournamentTextWithDelay("to");
    } else if (buttonContents.key === "start-tournament-to") {
      setStartTournamentTextWithDelay("Start Tournament");
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [buttonContents.key]);

  const buttonContentsRef = useRef(buttonContents);
  if (tournamentManager.progress.state !== "in-progress") {
    buttonContentsRef.current = buttonContents;
  }

  const [buttonContentsList, setButtonContentsList] = useState<
    { text: React.ReactNode; key: number }[]
  >([]);

  useEffect(() => {
    setButtonContentsList((list) => {
      return [
        { text: buttonContentsRef.current.text, key: (list[0]?.key ?? 0) + 1 },
        ...list,
      ];
    });
    setTimeout(() => {
      setButtonContentsList((list) =>
        list.length > 1 ? list.slice(0, -1) : list
      );
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonContentsRef.current.key]);

  return (
    <Button
      large
      fill
      disabled={!premiumStore.hasPremium}
      className={css.startTournament}
      intent={showLeftToPick || !premiumStore.hasPremium ? "none" : "primary"}
      onClick={() => {
        if (readyToStart) {
          tournamentManager.start();
        } else {
          setShowLeftToPick(true);
          onShowNotEnoughBotsIndicator();
        }
      }}
    >
      {buttonContentsList.map((buttonContent, index) => {
        return (
          <ButtonText
            key={buttonContent.key}
            text={buttonContent.text}
            transitionOut={index > 0}
          />
        );
      })}
    </Button>
  );
}

function ButtonText({
  text,
  transitionOut,
}: {
  text: React.ReactNode;
  transitionOut: boolean;
}) {
  const mounted = useDelay(16);
  return (
    <div
      className={css.buttonText}
      style={{
        opacity: mounted && !transitionOut ? 1 : 0,
        transform: `translateY(${transitionOut ? -60 : mounted ? 0 : 60}px)`,
      }}
    >
      {text}
    </div>
  );
}

function GamesPerMatchupEditor(props: {
  bots: number;
  onUpdate(value: number): void;
  gamesPerMatchup: number;
}) {
  const [gamesPerMatchup, setGamesPerMatchup] = useState(props.gamesPerMatchup);
  const matchupCount = props.bots * ((props.bots - 1) / 2);
  return (
    <div className={css.gamesPerMatchupEditorContainer}>
      <div
        style={{
          position: "absolute",
          left: "30px",
          top: "30px",
          fontWeight: 700,
        }}
        className="bp4-text-muted"
      >
        Games per
        <br />
        Matchup
      </div>
      <div className={css.gamesPerMatchupText}>
        <DataTransition
          items={[gamesPerMatchup]}
          getItemKey={(value) => value.toString()}
          exitDuration={300}
          render={(value, transitionState) => {
            return (
              <span
                style={{
                  position: "absolute",
                  opacity: transitionState === "entered" ? 1 : 0,
                  transform: `scale(${
                    transitionState === "entered" ? 1 : 0.5
                  })`,
                  transition:
                    transitionState === "exited"
                      ? "all .2s cubic-bezier(0.5, 0, 0.5, -0.5) 0s"
                      : "all .3s cubic-bezier(0.5, 2, 0.5, 1) .1s",
                }}
              >
                {value}
              </span>
            );
          }}
        />
      </div>

      <div className={css.gamesPerMatchupBottomSlider}>
        <Button
          icon="minus"
          style={{ borderRadius: "100%" }}
          outlined
          onClick={() => {
            setGamesPerMatchup((value) => Math.max(2, value - 2));
          }}
        />
        <div style={{ paddingTop: "22px", flexGrow: 1 }}>
          <Slider
            min={2}
            max={20}
            stepSize={2}
            labelStepSize={2}
            value={gamesPerMatchup}
            onChange={(value) => {
              setGamesPerMatchup(value);
            }}
          />
        </div>
        <Button
          icon="plus"
          style={{ borderRadius: "100%" }}
          outlined
          onClick={() => {
            setGamesPerMatchup((value) => Math.min(20, value + 2));
          }}
        />
      </div>
      <div className={css.gamesPerMatchupBottomFooter}>
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <Icon icon="calculator" size={24} color={Colors.GRAY3} />
          <div>
            <div className="bp4-text-muted">
              {props.bots} {props.bots === 1 ? "bot" : "bots"}, {matchupCount}{" "}
              {matchupCount === 1 ? "matchup" : "matchups"}, {gamesPerMatchup}{" "}
              games per matchup
            </div>
            <div style={{ fontWeight: 700 }}>
              {gamesPerMatchup * matchupCount} games total
            </div>
          </div>
        </div>
        <Button
          large
          intent="primary"
          onClick={() => {
            props.onUpdate(
              2 * Math.min(10, Math.max(1, Math.round(gamesPerMatchup) / 2))
            );
          }}
        >
          Update
        </Button>
      </div>
    </div>
  );
}
