.container {
  position: relative;
}

.pulse {
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

@keyframes GoldPulseAnimation {
  0% {
    background: rgba(240, 183, 38, 0.1);
    box-shadow: 0 0 0 0px rgba(240, 183, 38, 0.5),
      0 0 0 0px rgba(240, 183, 38, 0.7);
  }
  100% {
    box-shadow: 0 0 0 11px rgba(240, 183, 38, 0),
      0 0 0 4px rgba(240, 183, 38, 0);
    background: rgba(240, 183, 38, 0);
  }
}

@keyframes DangerPulseAnimation {
  0% {
    background: rgba(250, 153, 156, 0.1);
    box-shadow: 0 0 0 0px rgba(250, 153, 156, 0.5),
      0 0 0 0px rgba(250, 153, 156, 0.7);
  }
  100% {
    box-shadow: 0 0 0 11px rgba(250, 153, 156, 0),
      0 0 0 4px rgba(250, 153, 156, 0);
    background: rgba(250, 153, 156, 0);
  }
}

.gold {
  animation-fill-mode: both;
  animation-duration: 0.6s;
  animation-timing-function: ease-out;
  animation-name: GoldPulseAnimation;
}

.danger {
  animation-fill-mode: both;
  animation-duration: 0.6s;
  animation-timing-function: ease-out;
  animation-name: DangerPulseAnimation;
}
