import { Button, ButtonGroup } from "@blueprintjs/core";
import type { SlotSelection } from "@zilch/bot-models";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import { useDelay } from "@zilch/delay";
import css from "./GameControls.module.css";
import type { GameEngineInstance, GameOutcome, GameSpeed } from "./GameEngine";

interface Props {
  outcome: GameOutcome;
  speed: GameSpeed;
  onSetSpeed(speed: GameSpeed): void;
  engine: GameEngineInstance;
  slotSelections: SlotSelection[];
}

export function GameControls(props: Props) {
  const visible = useDelay(200) && props.outcome.status === "in-progress";

  return (
    <div className={classes(css.container, !visible && css.hidden)}>
      <ButtonGroup>
        <Button
          icon="pause"
          active={props.speed === "paused"}
          onClick={() => props.onSetSpeed("paused")}
        />
        <Button
          icon="step-forward"
          active={props.speed === "step"}
          onClick={() => props.onSetSpeed("step")}
        />
        <Button
          icon="play"
          active={props.speed === "normal"}
          onClick={() => props.onSetSpeed("normal")}
        />
        <Button
          icon="fast-forward"
          active={props.speed === "fast"}
          onClick={() => props.onSetSpeed("fast")}
        />
      </ButtonGroup>
      <div className={classes(css.bottomButtons, transitionInFromCss.top)}>
        <Button minimal icon="stop" onClick={props.engine.stop} />
        <Button
          minimal
          icon="reset"
          onClick={() => {
            props.engine.playGame(
              props.slotSelections.filter(
                (selection) => !!selection
              ) as NonNullable<SlotSelection>[]
            );
          }}
        />
      </div>
    </div>
  );
}
