import type { TransitionSlotSelection } from "@zilch/bot-models";
import { BotColor } from "@zilch/bot-models";
import type { GameConfig } from "@zilch/game-config";
import { useEffect, useRef, useState } from "react";
import { BotSelector } from "../game/BotSelector";
import { Button, Colors, Icon } from "@blueprintjs/core";
import * as csx from "csx";
import { capitalize } from "lodash";
import css from "./TournamentBotSelector.module.css";
import { Popover } from "../common/Popover";
import { GuideContent } from "../game/GuideContent";
import { classes } from "@zilch/css-utils";

interface Props {
  gameConfig: GameConfig | null;
  onClose(): void;
  open: BotColor | boolean;
  slots: NonNullable<TransitionSlotSelection>[];
  onSetSlot(
    slot: NonNullable<TransitionSlotSelection>,
    selected: boolean
  ): void;
  onStartTournament(): void;
}

export function TournamentBotSelector(props: Props) {
  const [selectorColor, setSelectorColor] = useState<BotColor>("vermilion");

  const slotsRef = useRef(props.slots);
  slotsRef.current = props.slots;

  useEffect(() => {
    if (props.open) {
      let targetColor: BotColor;

      if (props.open === true) {
        const usedColors = new Set(slotsRef.current.map((slot) => slot.color));
        const availableColors = pickOrderBotColors.filter(
          (color) => !usedColors.has(color)
        );
        targetColor = availableColors[0] ?? "vermilion";
      } else {
        targetColor = props.open;
      }

      setSelectorColor(targetColor);
    }
  }, [props.open]);

  if (!props.gameConfig) {
    return null;
  }

  return (
    <BotSelector
      createBot={false}
      gameConfig={props.gameConfig}
      selectedSlot={props.slots.find((slot) => slot.color === selectorColor)}
      forceColor={selectorColor}
      onClose={props.onClose}
      onSetSlotSelection={(slot, selected) => {
        if (!slot) {
          return;
        }

        props.onSetSlot(
          {
            ...slot,
            color: selectorColor,
          },
          selected
        );

        if (!selected) {
          return;
        }

        if (props.slots.length === 13 || props.open !== true) {
          props.onClose();
        } else {
          const nextColor = getNextColor(selectorColor, props.slots);

          if (nextColor) {
            setSelectorColor(nextColor);
          }
        }
      }}
      open={!!props.open}
      topContent={
        <TopContent
          slots={props.slots}
          onClose={props.onClose}
          setSelectorColor={setSelectorColor}
          selectorColor={selectorColor}
        />
      }
      slotLabel={(small) => (
        <SlotLabel
          small={small}
          selectorColor={selectorColor}
          onStartTournament={props.onStartTournament}
          slots={props.slots}
          onClose={props.onClose}
        />
      )}
    />
  );
}

function SlotLabel(props: {
  selectorColor: BotColor;
  small: boolean;
  slots: NonNullable<TransitionSlotSelection>[];
  onStartTournament(): void;
  onClose(): void;
}) {
  const [showPickMoreMessage, setShowPickMoreMessage] = useState(false);
  const botsLeftToPick = Math.max(0, 2 - props.slots.length);

  return (
    <>
      <div className={css.slotLabelContainer}>
        <div
          className={css.slotLabelMarker}
          style={{ background: BotColor[props.selectorColor] }}
        />
        <div>{capitalize(props.selectorColor)}</div>
      </div>
      <div
        className={classes(
          css.startTournamentContainer,
          props.small && css.small
        )}
      >
        <Popover
          isOpen={showPickMoreMessage}
          content={
            <GuideContent
              title="Almost ready!"
              message={
                <>
                  Pick at least <b>{botsLeftToPick}</b> more bot
                  {botsLeftToPick === 1 ? "" : "s"} to start the tournament.
                </>
              }
            />
          }
          background="blue"
          position="top"
          onClose={() => setShowPickMoreMessage(false)}
        >
          <Button
            intent={props.slots.length > 1 ? "primary" : "none"}
            style={{
              transition: "all ease .3s",
              fontWeight: 600,
            }}
            large
            onClick={() => {
              if (props.slots.length >= 2) {
                props.onStartTournament();
              } else {
                setShowPickMoreMessage(true);
              }
            }}
          >
            Start Tournament
          </Button>
        </Popover>
        {!props.small && (
          <Button
            icon="chevron-down"
            large
            minimal
            onClick={props.onClose}
            style={{ borderRadius: "100%" }}
          />
        )}
      </div>
    </>
  );
}

function TopContent(props: {
  setSelectorColor(color: BotColor): void;
  onClose(): void;
  slots: NonNullable<TransitionSlotSelection>[];
  selectorColor: BotColor;
}) {
  const usedColors = new Set(props.slots.map((slot) => slot.color));
  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={containerRef}
      className={css.topContent}
      onWheel={(e) => {
        containerRef.current?.scrollBy({
          left: e.deltaY,
        });
      }}
    >
      <Button
        icon="chevron-down"
        className={css.selectedButton}
        onClick={props.onClose}
      >
        {props.slots.length} selected
      </Button>
      {pickOrderBotColors.map((color) => {
        return (
          <button
            onClick={() => props.setSelectorColor(color)}
            className={css.colorButton}
            key={color}
            style={{
              borderRadius: color === props.selectorColor ? "4px" : "15px",
              background: csx
                .color(BotColor[color])
                .mix(Colors.BLACK, color === props.selectorColor ? 1 : 0.9)
                .toString(),
              transform:
                color === props.selectorColor ? "scale(1.1)" : "scale(1)",
              boxShadow:
                (color === props.selectorColor
                  ? `inset 0 0 0 2px white, `
                  : "") + "0 3px 10px rgba(0,0,0,.2)",
            }}
          >
            {usedColors.has(color) && (
              <Icon
                style={{
                  transition: "all ease .2s",
                  opacity: props.selectorColor === color ? 1 : 0.9,
                }}
                icon="small-tick"
              />
            )}
          </button>
        );
      })}
    </div>
  );
}

const pickOrderBotColors: BotColor[] = [
  "vermilion",
  "violet",
  "cerulean",
  "forest",
  "orange",
  "rose",
  "blue",
  "green",
  "gold",
  "sepia",
  "red",
  "indigo",
  "turquoise",
  "lime",
];

function getNextColor(
  current: BotColor,
  slots: NonNullable<TransitionSlotSelection>[]
) {
  const originalIndex = pickOrderBotColors.indexOf(current);
  let index = originalIndex;
  const usedColors = new Set(slots.map((slot) => slot.color));

  do {
    index = index >= pickOrderBotColors.length ? 0 : index + 1;
    const nextColor = pickOrderBotColors[index];
    if (nextColor && !usedColors.has(nextColor)) {
      return nextColor;
    }
  } while (index !== originalIndex);

  return null;
}
