.container {
  display: flex;
  align-items: center;
}

.input {
  position: relative;
  padding: 10px !important;
  width: 40px !important;
  height: 40px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-align: center;
}

.input.large {
  width: 60px !important;
  height: 60px !important;
  font-size: 22px !important;
  border-radius: 5px;
}

.input:focus {
  z-index: 1;
}
