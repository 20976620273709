import { z } from "zod";

export type InternalGameId = (typeof internalGameIds)[number];
export const internalGameIds = [
  "table-tennis",
  "tic-tac-toe",
  "chess",
] as const;

const internalGameIdsSet = new Set<string>(internalGameIds);

export type GameId = z.infer<typeof GameId>;
export const GameId = z
  .string()
  .refine((value): value is InternalGameId => internalGameIdsSet.has(value))
  .or(
    z.object({
      owner: z.string().regex(/[\w.-]+/),
      repo: z.string().regex(/[\w.-]+/),
    })
  );

export interface GameConfig extends DiskGameConfig {
  url: string;
  gameId: GameId;
  release: string | null;
  dev: boolean;
}

export type RawDiskGameConfig = z.infer<typeof RawDiskGameConfig>;
export const RawDiskGameConfig = z.object({
  name: z.string(),
  v: z.literal(1),
  slots: z.array(z.string()),
  minSlots: z.number().int().positive(),
  defaultTimeLimitMilliseconds: z.object({
    move: z.number().int().positive().nullable(),
    game: z.number().int().positive().nullable(),
  }),
  moveDelay: z.number().int().nonnegative(),
});

export type DiskGameConfig = z.infer<typeof DiskGameConfig>;
export const DiskGameConfig = RawDiskGameConfig.extend({
  templates: z.array(z.string()),
});

const internalPrefix = "internal:";
const externalPrefix = "external:";

export function parseGameId(raw: string): GameId {
  let gameId: GameId;

  if (raw.startsWith(internalPrefix)) {
    gameId = raw.slice(internalPrefix.length) as InternalGameId;
  } else {
    const [owner, repo] = raw.slice(externalPrefix.length).split("/");
    gameId = {
      owner: owner ?? "",
      repo: repo ?? "",
    };
  }

  return GameId.parse(gameId);
}

export function stringifyGameId(gameId: GameId) {
  if (typeof gameId === "string") {
    return `${internalPrefix}${gameId}`;
  } else {
    return `${externalPrefix}${gameId.owner}/${gameId.repo}`;
  }
}
