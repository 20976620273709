import { useDelay } from "@zilch/delay";
import { PremiumStore } from "../../stores/PremiumStore";

export function useTransitionStyle(
  show: boolean,
  offscreen: "top" | "bottom" | "left" | "right"
): React.CSSProperties {
  const mounted = useDelay(0);

  const vertical = offscreen === "top" || offscreen === "bottom";

  const { section, previousSection } = PremiumStore.use();

  if (vertical) {
    return {
      transition:
        mounted && show ? "all cubic-bezier(.4,1.3,.4,1) .5s" : "all ease .5s",
      transform: `translateY(${
        mounted && show ? 0 : offscreen === "top" ? -100 : 100
      }%) scale(${mounted && show ? 1 : 0.8})`,
      opacity: mounted && show ? 1 : 0,
    };
  }

  const verticalTransition =
    section === "feature-overview" ||
    previousSection === "feature-overview" ||
    previousSection === null;

  if (verticalTransition) {
    return {};
  }

  return {
    transition:
      mounted && show ? "all cubic-bezier(.4,1.3,.4,1) .4s" : "all ease .3s",
    transform: `translateX(${
      mounted && show ? 0 : offscreen === "left" ? -100 : 100
    }%)`,
    opacity: mounted && show ? 1 : 0,
  };
}
