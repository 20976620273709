.orgDialog {
  padding: 20px;
}

.orgDialogFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.orgDialogFooterRight {
  display: flex;
  align-items: center;
  gap: 10px;
}

.deleteConfirmContainer {
  padding: 10px;
}

.deleteConfirmFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}