import {
  type BotConfig,
  BotType,
  type TransitionSlotSelection,
} from "@zilch/bot-models";
import { panic } from "@zilch/panic";

export function createSlotSelection(
  botConfig: BotConfig,
  transitionOrigin?: { x: number; y: number },
  bossDifficulty?: "easy" | "medium" | "hard"
): TransitionSlotSelection {
  const slotId = crypto.randomUUID();

  if (botConfig.type === BotType.Practice) {
    return {
      type: botConfig.type,
      color: botConfig.preferredColor,
      transitionData: {
        slotId,
        avatar: botConfig.avatar,
        language: botConfig.language,
        name: botConfig.name,
        transitionOrigin,
      },
    };
  } else if (botConfig.type === BotType.Boss) {
    return {
      type: botConfig.type,
      difficulty: bossDifficulty ?? "easy",
      color: botConfig.preferredColor,
      transitionData: {
        slotId,
        avatar: botConfig.avatar,
        language: botConfig.language,
        name: botConfig.name,
        transitionOrigin,
      },
    };
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (botConfig.type === BotType.User) {
    return {
      type: BotType.User,
      owner: botConfig.owner,
      repo: botConfig.repo,
      color: botConfig.preferredColor,
      transitionData: {
        slotId,
        avatar: botConfig.avatar,
        owner: botConfig.owner,
        language: botConfig.language,
        name: botConfig.name,
        transitionOrigin,
      },
    };
  } else {
    panic("Unexpected bot type", botConfig.type);
  }
}
