import type { Point } from "./Point";
import { serializePoint } from "./serializePoint";

type Shape = Point[];

export function getShapes(grid: boolean[][]): Shape[] {
  const visitedPoints = new Set<string>();
  const shapes: Shape[] = [];

  for (let y = 0; y < grid.length; y++) {
    for (let x = 0; x < grid[y]!.length; x++) {
      const connectedPoints = getConnectedPoints({ x, y });

      if (connectedPoints.length > 0) {
        shapes.push(connectedPoints);
      }
    }
  }

  return shapes;

  function getConnectedPoints(point: Point): Point[] {
    const serializedPoint = serializePoint(point);

    if (visitedPoints.has(serializedPoint) || !grid[point.y]?.[point.x]) {
      return [];
    }

    visitedPoints.add(serializedPoint);

    return [
      point,
      ...getConnectedPoints({ x: point.x - 1, y: point.y }),
      ...getConnectedPoints({ x: point.x + 1, y: point.y }),
      ...getConnectedPoints({ x: point.x, y: point.y - 1 }),
      ...getConnectedPoints({ x: point.x, y: point.y + 1 }),
    ];
  }
}
