import { Colors } from "@blueprintjs/core";
import type {
  SlotStats,
  TournamentManager,
  TournamentMatchup,
  TournamentProgress,
} from "./TournamentScreen";
import type { TransitionSlotSelection } from "@zilch/bot-models";
import { BotColor } from "@zilch/bot-models";
import { useDelay, useDelayedValue } from "@zilch/delay";
import { useAnimatedState } from "@zilch/use-animated-state";
import { useMemo, useEffect, useRef, useState } from "react";
import * as csx from "csx";
import { loadImg } from "@zilch/load-img";
import gameMakerPng from "../../resources/icons/game-maker.png";
import { classes, delayCss, transitionInFromCss } from "@zilch/css-utils";
import { PremiumStore } from "../../stores/PremiumStore";
import css from "./TournamentVisualization.module.css";

interface Props {
  tournamentManager: TournamentManager;
}

const containerSize = 200;
const midPoint: Point = {
  x: containerSize / 2,
  y: containerSize / 2,
};
const circleRadius = 61.3;
const pathRadius = 60.67;

function getPathEndpoint(bot: number, opponent: number, totalBots: number) {
  const segmentFrom = (360 * bot) / totalBots;
  const segmentTo = (360 * (bot + 1)) / totalBots;
  const opponentIndex =
    totalBots - (opponent > bot ? opponent - 1 : opponent) - 2;

  return (
    ((opponentIndex + 0.5) * (segmentTo - segmentFrom)) / (totalBots - 1) +
    segmentFrom
  );
}

function distToDeg(distance: number, radius: number) {
  const circumference = 2 * Math.PI * radius;
  return (360 * distance) / circumference;
}

export function TournamentVisualization(props: Props) {
  const [iconSrc, setIconSrc] = useState<string | null>(null);
  const showIcon =
    useDelayedValue(iconSrc !== null, { delay: 400 }) &&
    iconSrc !== null &&
    props.tournamentManager.progress.state === "not-started";

  useEffect(() => {
    const targetIconSrc =
      props.tournamentManager.engine.loadingScreenData?.iconSrc;
    if (!targetIconSrc) {
      return;
    }
    let cancelled = false;

    loadImg(targetIconSrc)
      .then(() => {
        if (cancelled) return;
        setIconSrc(targetIconSrc);
      })
      .catch(() => {
        if (cancelled) return;
        loadImg(gameMakerPng).then(() => {
          if (cancelled) return;
          setIconSrc(gameMakerPng);
        });
      });

    return () => {
      cancelled = true;
    };
  }, [props.tournamentManager.engine.loadingScreenData?.iconSrc]);

  const premiumStore = PremiumStore.use();

  return (
    <div
      className={css.container}
      style={{
        filter: premiumStore.hasPremium ? undefined : "grayscale(100%)",
        opacity: premiumStore.hasPremium ? 1 : 0.2,
      }}
    >
      <Visual {...props} />
      {iconSrc && (
        <img
          src={iconSrc}
          className={css.img}
          style={{
            opacity: showIcon ? 1 : 0,
            transform: `translateY(-10px) scale(${showIcon ? 1 : 0})`,
          }}
        />
      )}
      {props.tournamentManager.engine.status !== "created" && (
        <div
          className={classes(
            transitionInFromCss.bottom,
            delayCss[200],
            css.loadStatus
          )}
        >
          {props.tournamentManager.engine.status === "error"
            ? "Error"
            : "Loading"}
        </div>
      )}
    </div>
  );
}

function getStableOrderProgress(
  progress: TournamentProgress
): TournamentProgress {
  const slotIndexMap = new Map<number, number>();
  const statsBySlot: SlotStats[] = [];
  const slots = progress.slots
    .map((slot, slotIndex) => {
      return { slot, slotIndex };
    })
    .sort((a, b) => {
      return a.slot.transitionData.slotId.localeCompare(
        b.slot.transitionData.slotId
      );
    })
    .map(({ slot, slotIndex }, newIndex) => {
      const stats = progress.statsBySlot[slotIndex];
      if (stats) {
        statsBySlot.push(stats);
      }
      slotIndexMap.set(slotIndex, newIndex);
      return slot;
    });

  return {
    slots,
    state: progress.state,
    updateId: progress.updateId,
    statsBySlot,
    matchups: progress.matchups.map((matchup): TournamentMatchup => {
      return {
        bot1SlotIndex: slotIndexMap.get(matchup.bot1SlotIndex) ?? 0,
        bot2SlotIndex: slotIndexMap.get(matchup.bot2SlotIndex) ?? 0,
        games: matchup.games,
      };
    }),
  };
}

function Visual(props: Props) {
  const progress = useMemo(
    () => getStableOrderProgress(props.tournamentManager.progress),
    [props.tournamentManager.progress]
  );
  const totalBots = progress.slots.length;

  const background = useMemo(() => {
    const inProgressMatchup = progress.matchups.find((matchup) => {
      return matchup.games.some((game) => game === "in-progress");
    });
    let backgroundBot: number;
    if (inProgressMatchup) {
      const bot1Rank =
        progress.statsBySlot[inProgressMatchup.bot1SlotIndex]?.rank ?? 0;
      const bot2Rank =
        progress.statsBySlot[inProgressMatchup.bot2SlotIndex]?.rank ?? 0;
      backgroundBot =
        bot1Rank < bot2Rank
          ? inProgressMatchup.bot1SlotIndex
          : inProgressMatchup.bot2SlotIndex;
    } else {
      backgroundBot = progress.statsBySlot.findIndex(
        (stats) => stats.rank === 0
      );
    }

    if (backgroundBot === -1) {
      return Colors.DARK_GRAY4;
    } else {
      const color = BotColor[progress.slots[backgroundBot]?.color ?? "blue"];
      const gamesPlayed = progress.statsBySlot[backgroundBot]?.gamesPlayed ?? 0;
      const gamesWon = progress.statsBySlot[backgroundBot]?.gamesWon ?? 0;
      const intensity = gamesWon / (gamesPlayed || 1);
      const done = progress.matchups.every((matchup) =>
        matchup.games.every((game) => typeof game !== "string")
      );
      return csx
        .color(color)
        .fade(intensity * (done ? 0.8 : 0.4))
        .toString();
    }
  }, [progress]);

  const slots: TransitionSlotSelection[] = [...progress.slots];
  while (slots.length < 2) {
    slots.push(null);
  }

  return (
    <div className={css.svgContainer} style={{ background }}>
      <svg
        className={css.svg}
        viewBox={`0 0 ${containerSize} ${containerSize}`}
      >
        {progress.matchups.map((matchup) => {
          return (
            <MatchupPaths
              key={matchup.bot1SlotIndex + "." + matchup.bot2SlotIndex}
              matchup={matchup}
              totalBots={totalBots}
              progress={progress}
            />
          );
        })}
        {slots.map((slot, botIndex) => {
          const segmentFrom =
            (360 * botIndex) / Math.max(2, totalBots) +
            distToDeg(0.2, circleRadius);
          const segmentTo =
            (360 * (botIndex + 1)) / Math.max(2, totalBots) -
            distToDeg(0.2, circleRadius);
          return (
            <Segment
              key={`segment-${botIndex}`}
              from={segmentFrom}
              to={segmentTo}
              color={slot ? BotColor[slot.color] : Colors.GRAY1}
            />
          );
        })}
      </svg>
      <div className={css.svgOverlay} />
    </div>
  );
}
function getWinSize(percentage: number) {
  return percentage * percentage * 25;
}

function animateNum(easing: number, target: number, initial: number) {
  return easing * (target - initial) + initial;
}

function useCirclePoints(from: number, to: number, radius: number) {
  const midPointTarget = (to - from) / 2 + from;

  const curve = [0.5, 1.6, 0.5, 1] as [number, number, number, number];
  const duration = 500;

  const [midPoint, setMidPoint] = useAnimatedState({
    initial: midPointTarget,
    clone: (value) => value,
    animate: animateNum,
    curve,
    duration,
  });

  useEffect(() => {
    setMidPoint(midPointTarget);
  }, [midPointTarget, setMidPoint]);

  return useMemo(() => {
    const diff = (to - from) / 2;
    return {
      p1: getCirclePoint(midPoint - diff, radius),
      p2: getCirclePoint(midPoint + diff, radius),
    };
  }, [midPoint, radius, from, to]);
}

function useLinePoints(from: number, to: number, radius: number, size: number) {
  const midPointTarget = (to - from) / 2 + from;

  const curve = [0.5, 1.6, 0.5, 1] as [number, number, number, number];
  const duration = 500;

  const [midPoint, setMidPoint] = useAnimatedState({
    initial: midPointTarget,
    clone: (value) => value,
    animate: animateNum,
    curve,
    duration,
  });

  useEffect(() => {
    setMidPoint(midPointTarget);
  }, [midPointTarget, setMidPoint]);

  return useMemo(() => {
    return {
      p1: getCirclePoint(midPoint, radius),
      p2: getCirclePoint(midPoint, radius + size),
    };
  }, [midPoint, radius, size]);
}

function Segment(props: { from: number; to: number; color: string }) {
  const visible = useDelay(100);
  const { p1, p2 } = useCirclePoints(props.from, props.to, circleRadius);

  const d = useMemo(() => {
    return `M${p1.x},${p1.y} A${circleRadius},${circleRadius} 0 0 1 ${p2.x},${p2.y}`;
  }, [p1, p2]);

  const style: React.CSSProperties = {
    pointerEvents: "auto",
    opacity: visible ? 1 : 0,
    transform: visible ? "scale(1)" : "scale(.9)",
    transition:
      "opacity ease .4s .1s, transform cubic-bezier(0.5, 1.6, 0.5, 1) .4s .1s, stroke ease .5s",
    transformOrigin: "50% 50%",
  };

  return (
    <path
      d={d}
      style={style}
      stroke={props.color}
      strokeWidth="1"
      fill="transparent"
    />
  );
}

function bound(min: number, max: number, percentage: number) {
  return min + (max - min) * percentage;
}

function MatchupPaths({
  matchup,
  totalBots,
  progress,
}: {
  matchup: TournamentMatchup;
  totalBots: number;
  progress: TournamentProgress;
}) {
  const from = getPathEndpoint(
    matchup.bot1SlotIndex,
    matchup.bot2SlotIndex,
    totalBots
  );
  const to = getPathEndpoint(
    matchup.bot2SlotIndex,
    matchup.bot1SlotIndex,
    totalBots
  );

  let bot1Percentage: number;
  let bot2Percentage: number;

  if (matchup.games.every((game) => game === "not-started")) {
    bot1Percentage = 0;
    bot2Percentage = 0;
  } else if (
    matchup.games.some((game) => game === "in-progress") &&
    matchup.games.every((game) => typeof game === "string")
  ) {
    bot1Percentage = 0.5;
    bot2Percentage = 0.5;
  } else {
    const bot1Wins = matchup.games.filter((game) => {
      return typeof game !== "string" && game.winner === "bot1";
    }).length;
    const bot2Wins = matchup.games.filter((game) => {
      return typeof game !== "string" && game.winner === "bot2";
    }).length;
    const gamesPlayed = bot1Wins + bot2Wins;
    const factor = gamesPlayed / matchup.games.length;
    bot1Percentage = 0.5 + factor * (bot1Wins / gamesPlayed - 0.5);
    bot2Percentage = 0.5 + factor * (bot2Wins / gamesPlayed - 0.5);
  }

  const active = matchup.games.some((game) => game === "in-progress");

  const bot1Path = (
    <Path
      key="bot1Path"
      from={from}
      to={to}
      active={active}
      bots={totalBots}
      disableControlPointTweaking={totalBots < 3}
      percentage={bot1Percentage}
      color={BotColor[progress.slots[matchup.bot1SlotIndex]?.color ?? "blue"]}
    />
  );

  const bot2Path = (
    <Path
      key="bot2Path"
      from={to}
      to={from}
      active={active}
      disableControlPointTweaking={totalBots < 3}
      bots={totalBots}
      percentage={bot2Percentage}
      color={BotColor[progress.slots[matchup.bot2SlotIndex]?.color ?? "blue"]}
    />
  );

  if (bot1Percentage > bot2Percentage) {
    return (
      <>
        {bot1Path}
        {bot2Path}
      </>
    );
  } else {
    return (
      <>
        {bot2Path}
        {bot1Path}
      </>
    );
  }
}

function Path(props: {
  from: number;
  to: number;
  color: string;
  active: boolean;
  bots: number;
  percentage: number;
  disableControlPointTweaking: boolean;
}) {
  const circumference = 2 * Math.PI * pathRadius;
  const segmentSize = circumference / props.bots / (props.bots - 1);
  const segmentDegrees = Math.min(
    props.bots === 2 ? 45 : 15,
    distToDeg(segmentSize / 2, pathRadius)
  );
  const fromStart = props.from - segmentDegrees;
  const fromEnd = props.from + segmentDegrees;
  const toStart = props.to - segmentDegrees;
  const toEnd = props.to + segmentDegrees;

  const { p1: fromP1, p2: fromP2 } = useCirclePoints(
    fromStart,
    fromEnd,
    pathRadius
  );
  const { p1: toP1, p2: toP2 } = useCirclePoints(toStart, toEnd, pathRadius);

  const [percentage, setPercentage] = useAnimatedState({
    initial: props.percentage,
    clone: (value) => value,
    animate: animateNum,
    curve: [
      0.5,
      props.percentage > 0.9 || props.percentage <= 0.1 ? 1 : 2,
      0.5,
      1,
    ],
    duration: 500,
  });
  const setPercentageRef = useRef(setPercentage);
  setPercentageRef.current = setPercentage;
  useEffect(() => {
    setPercentageRef.current(props.percentage);
  }, [props.percentage]);
  const boundedPercentage = Math.min(1, Math.max(0, percentage));

  const line = useLinePoints(
    fromStart,
    fromEnd,
    circleRadius,
    getWinSize(boundedPercentage)
  );

  const d = useMemo(() => {
    let control1 = midPoint;
    let control2 = midPoint;

    if (!props.disableControlPointTweaking) {
      const peak1 = getCurvePeak(fromP1, midPoint, toP2);
      const peak2 = getCurvePeak(toP1, midPoint, fromP2);
      const peak1Nearer = dist(peak1, midPoint) > dist(peak2, midPoint);

      const pushOutDist = -2 * bound(6, 1, Math.min(1, props.bots / 10)) ** 1.2;

      if (peak1Nearer && props.bots > 2) {
        control1 = pushOut(
          midPoint,
          getCurveControl(fromP1, peak2, toP2),
          pushOutDist
        );
      } else {
        control2 = pushOut(
          midPoint,
          getCurveControl(toP1, peak1, fromP2),
          pushOutDist
        );
      }
    }

    const curve1 = getPartialCurve(fromP1, control1, toP2, boundedPercentage);
    const curve2 = getPartialCurve(fromP2, control2, toP1, boundedPercentage);

    const multiplier =
      Math.abs(Math.abs(boundedPercentage - 0.5) - 0.5) * 30 + 1;
    const capRadius = multiplier * pathRadius;

    return [
      `M${curve1.start.x},${curve1.start.y}`,
      `Q${curve1.control.x},${curve1.control.y} ${curve1.end.x},${curve1.end.y}`,
      `A${capRadius},${capRadius} 1 0 ${boundedPercentage >= 0.5 ? 0 : 1} ${
        curve2.end.x
      },${curve2.end.y}`,
      `Q${curve2.control.x},${curve2.control.y} ${curve2.start.x},${curve2.start.y}`,
      `A${pathRadius},${pathRadius} 1 0 0 ${curve1.start.x},${curve1.start.y}`,
    ].join(" ");
  }, [
    fromP1,
    fromP2,
    toP1,
    toP2,
    props.bots,
    boundedPercentage,
    props.disableControlPointTweaking,
  ]);

  return (
    <>
      <path
        d={`M${line.p1.x},${line.p1.y} L${line.p2.x},${line.p2.y}`}
        strokeWidth={0.15}
        style={{
          pointerEvents: "auto",
          strokeLinecap: "round",
        }}
        stroke={props.color}
      />
      <path
        d={d}
        style={{
          pointerEvents: "auto",
          strokeLinecap: "round",
          transition: "fill-opacity ease .3s",
          fillOpacity: props.active ? 1 : boundedPercentage * 0.7 + 0.2,
        }}
        stroke={Colors.DARK_GRAY3}
        strokeOpacity={0.6}
        strokeWidth={".5"}
        fill={props.color}
      />
    </>
  );
}

function pushOut(from: Point, to: Point, distance: number): Point {
  const len = dist(from, to);
  return {
    x: to.x + ((to.x - from.x) / len) * distance,
    y: to.y + ((to.y - from.y) / len) * distance,
  };
}

function getCirclePoint(degrees: number, radius: number): Point {
  return {
    x: Math.cos(toRadians(degrees)) * radius + midPoint.x,
    y: Math.sin(toRadians(degrees)) * radius + midPoint.y,
  };
}

function dist(p1: Point, p2: Point) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

function toRadians(degrees: number) {
  return (degrees + 90) * (Math.PI / 180);
}

interface Point {
  x: number;
  y: number;
}

function getPartialCurve(start: Point, control: Point, end: Point, t: number) {
  const partialMid = getCurvePoint(start, control, end, t / 2);
  const partialEnd = getCurvePoint(start, control, end, t);
  const partialControl = getCurveControl(start, partialMid, partialEnd);

  return {
    start,
    control: partialControl,
    end: partialEnd,
  };
}

function getCurvePoint(
  start: Point,
  control: Point,
  end: Point,
  t: number
): Point {
  return {
    x:
      (1 - t) * (1 - t) * start.x + 2 * (1 - t) * t * control.x + t * t * end.x,
    y:
      (1 - t) * (1 - t) * start.y + 2 * (1 - t) * t * control.y + t * t * end.y,
  };
}

function getCurvePeak(start: Point, control: Point, end: Point): Point {
  return {
    x: start.x / 4 + control.x / 2 + end.x / 4,
    y: start.y / 4 + control.y / 2 + end.y / 4,
  };
}

function getCurveControl(start: Point, peak: Point, end: Point): Point {
  return {
    x: 2 * (peak.x - start.x / 4 - end.x / 4),
    y: 2 * (peak.y - start.y / 4 - end.y / 4),
  };
}
