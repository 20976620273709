import {
  DiskGameConfig,
  type GameConfig,
  type InternalGameId,
} from "@zilch/game-config";
import { panic } from "@zilch/panic";
import stringify from "fast-json-stable-stringify";
import { parse } from "jsonc-parser";
import { useMemo } from "react";
import { z } from "zod";
import { api } from "../../api";
import { groups, routes, useRoute } from "../../router";
import { devFileManager } from "./devFileManager";

// TODO remember that weird bug in Safari where the glb textures couldn't load? But in Chrome it was fine.
export async function loadGameConfig(
  source: Source,
  signal: AbortSignal
): Promise<GameConfig> {
  if (source.dev) {
    const diskGameConfig = await devFileManager
      .getGameConfig({
        owner: source.owner,
        repo: source.repo,
      })
      .catch((err) => {
        return { err };
      });

    if (!diskGameConfig || "err" in diskGameConfig) {
      throw {
        type: "devGameConfigResponseError",
        err: diskGameConfig?.err,
      };
    }

    return {
      ...diskGameConfig,
      dev: true,
      gameId: { owner: source.owner, repo: source.repo },
      release: null,
      url: `${ClientEnv.ALTERNATE_ORIGIN}/game-maker/${source.owner}/${source.repo}`,
    };
  }

  const url = await getSourceUrl(source, signal);

  const configResponse = await fetch(`${url.value}/out/game.json`).catch(
    (error) => {
      return {
        ok: false as const,
        error,
      };
    }
  );

  if (!configResponse.ok) {
    throw { type: "gameConfigResponseError", configResponse };
  }

  const config: GameConfig = {
    ...DiskGameConfig.parse(parse(await configResponse.text())),
    dev: false,
    url: url.value,
    release: url.release.tag,
    gameId:
      source.owner === "zilch"
        ? (source.repo as InternalGameId)
        : {
            owner: source.owner,
            repo: source.repo,
          },
  };

  return config;
}

export function useSource() {
  const route = useRoute();

  let source: Source;

  if (groups.game.has(route)) {
    source = {
      owner:
        route.name === routes.internalGame.name ? "zilch" : route.params.owner,
      repo:
        route.name === routes.internalGame.name
          ? route.params.gameId
          : route.params.repo,
      releaseTag: route.params.release,
      dev: route.params.dev ?? false,
    };
  } else if (groups.tournament.has(route)) {
    source = {
      dev: false,
      owner:
        route.name === routes.tournamentInternalGame.name
          ? "zilch"
          : route.params.owner,
      repo:
        route.name === routes.tournamentInternalGame.name
          ? route.params.gameId
          : route.params.repo,
    };
  } else {
    panic("Expected route to be game or tournament");
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => source, [stringify(source)]);
}

export interface Source {
  owner: string;
  repo: string;
  releaseTag?: string;
  dev: boolean;
}

async function getSourceUrl(source: Source, signal: AbortSignal) {
  const release = await getRelease(
    source.owner,
    source.repo,
    source.releaseTag ?? null,
    signal
  );

  if (release === "not-found") {
    throw release;
  }

  return {
    type: "release" as const,
    release,
    value: `https://cdn.jsdelivr.net/gh/${source.owner}/${source.repo}@${release.id}`,
  };
}

async function getRelease(
  owner: string,
  repo: string,
  releaseTag: string | null,
  signal: AbortSignal
) {
  // First do this client-side so we don't make a bunch of unnecessary requests
  // to backend and incur expenses. Then fallback if we're rate limited on the client
  // to the backend (which will have a high rate limit b/c of the user's token)
  const response = await fetch(
    releaseTag
      ? `https://api.github.com/repos/${owner}/${repo}/releases/tags/${releaseTag}`
      : `https://api.github.com/repos/${owner}/${repo}/releases/latest`,
    { signal }
  );

  let release:
    | {
        id: string;
        tag: string;
      }
    | "not-found";

  if (response.ok) {
    const result = z
      .object({
        node_id: z.string(),
        tag_name: z.string(),
      })
      .parse(await response.json());

    release = {
      id: result.node_id,
      tag: result.tag_name,
    };
  } else if (response.headers.get("x-ratelimit-remaining") === "0") {
    release = await api.game.getRelease.query({ owner, repo, releaseTag });
  } else if (response.status === 404) {
    return "not-found" as const;
  } else {
    throw response;
  }

  return release;
}
