import { Colors } from "@blueprintjs/core";
import { classes } from "@zilch/css-utils";
import { GiRoundStar } from "react-icons/gi";
import css from "./StoreCoins.module.css";
import { useAnimatedNumber } from "@zilch/animated-number";
import { useDelay } from "@zilch/delay";
import { StarStore } from "../../stores/StarStore";

export function StoreCoins() {
  const delay = useDelay(200);
  const starStore = StarStore.use();
  const AnimatedNumber = useAnimatedNumber(
    delay ? starStore.totalStarCount * 285 : 0
  );

  return (
    <div className={css.container}>
      <div className={css.leftSection}>
        <GiRoundStar size={38} color={Colors.GOLD4} className={css.star} />
        <div>
          <div className={css.sectionText} style={{ fontWeight: 800 }}>
            Win stars, earn Zilch coin, unlock discounts.
          </div>
          <div
            className={classes(css.sectionText, "bp4-text-muted")}
            style={{ fontWeight: 500 }}
          >
            Get up to half off on all Zilch merch.
          </div>
        </div>
      </div>
      <div className={css.rightSection}>
        <span className={css.coinAmount}>
          <AnimatedNumber
            render={(value) => Math.round(value).toLocaleString()}
          />
        </span>
        <span className={css.coinIcon}>Z</span>
      </div>
    </div>
  );
}
