.container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 0.5s ease;
}

.iframe {
  border: none;
  width: 100%;
  height: 100%;
}

:global(body.resizing) .iframe,
:global(#root.dragging) .iframe {
  pointer-events: none;
}

.containerHidden {
  visibility: hidden;
}
