import { Button } from "@blueprintjs/core";
import { PromptStore } from "../../stores/PromptStore";
import premiumPng from "../../resources/icons/premium.png";
import { PremiumStore } from "../../stores/PremiumStore";
import { classes, delayCss, transitionInFromCss } from "@zilch/css-utils";
import css from "./useShowGameLockedMessage.module.css";
import type { GameId } from "@zilch/game-config";

type Reason = { type: "custom-game" } | { type: "boss"; gameId: GameId };

// TODO custom games should be locked
export function useShowGameLockedMessage() {
  const prompt = PromptStore.usePrompt();

  return async (reason: Reason) => {
    await prompt<null>(
      (props) => (
        <LockedMessage
          reason={reason}
          onClose={() => {
            props.resolve(null);
          }}
        />
      ),
      { width: 440, borderRadius: 16 }
    );
  };
}

function LockedMessage(props: { onClose(): void; reason: Reason }) {
  const premiumStore = PremiumStore.use();

  const onLearnMore = () => {
    props.onClose();
    premiumStore.setSection("feature-overview");
  };

  let message: React.ReactNode;

  if (props.reason.type === "boss") {
    let gameName: string = "";

    if (props.reason.gameId === "chess") {
      gameName = "Chess";
    } else if (props.reason.gameId === "table-tennis") {
      gameName = "hard Table Tennis";
    } else if (props.reason.gameId === "tic-tac-toe") {
      gameName = "Tic-Tac-Toe";
    }

    message = (
      <>You need Premium to play against the {gameName} boss&nbsp;bot.</>
    );
  } else {
    message = <>You need Premium to play and develop custom&nbsp;games.</>;
  }

  return (
    <div className={css.container}>
      <div
        className={classes(
          transitionInFromCss.bottom,
          delayCss[200],
          css.topSection
        )}
      >
        <div style={{ marginBottom: "15px", fontWeight: 800 }}>{message}</div>
        <span className="bp4-text-muted">
          <a onClick={onLearnMore}>Premium is free</a> for students and
          employees of select organizations.
        </span>
        <img src={premiumPng} className={css.premiumIcon} />
      </div>
      <div
        className={classes(
          transitionInFromCss.bottom,
          delayCss[240],
          css.bottomSection
        )}
      >
        <Button
          intent="primary"
          large
          className={css.button}
          fill
          onClick={onLearnMore}
        >
          Learn more about Premium
        </Button>
      </div>
    </div>
  );
}
