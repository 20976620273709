import type { PanelActions } from "@blueprintjs/core/lib/esm/components/panel-stack2/panelTypes";
import { BotCreatorPanel } from "./BotCreatorPanel";
import css from "./BotCreatorOtherLanguagePanel.module.css";
import { Button, Colors, Icon } from "@blueprintjs/core";
import { BotCreatorAvatarPanel } from "./BotCreatorAvatarPanel";

export function BotCreatorOtherLanguagePanel(props: PanelActions) {
  return (
    <BotCreatorPanel {...props} className={css.container}>
      <div className={css.header}>
        <Icon icon="box" size={34} color={Colors.GRAY4} />
        <div
          style={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          <div>Didn't see the language you wanted?</div>
          <a href="mailto:hello@zilch.dev">Email hello@zilch.dev</a>
        </div>
      </div>
      <div
        style={{ fontSize: "16px", marginBottom: "30px" }}
        className="bp4-text-muted"
      >
        In the meantime (with a bit of effort) you can still integrate any
        language or technology into Zilch. The docs on how to do this are still
        being developed, so if you're interested reach out for some 1:1 help.
      </div>
      <Button
        large
        onClick={() => {
          props.openPanel({
            renderPanel: (props) => <BotCreatorAvatarPanel {...props} />,
          });
        }}
      >
        Build Custom Integration
      </Button>
    </BotCreatorPanel>
  );
}
