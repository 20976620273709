import { format } from "date-fns";
import type { SubscriptionWithTeams } from "../../../backend/db/subscription";
import { api } from "../../api";
import { DetailedButton } from "./DetailedButton";
import { PromptStore } from "../../stores/PromptStore";
import { AdminSubscriptionPrompt } from "./AdminSubscriptionPrompt";
import { UserStore } from "../../stores/UserStore";

export function getSubscriptionStatusLabel(
  subscription: SubscriptionWithTeams
) {
  if (
    (subscription.status === "active" ||
      subscription.status === "incomplete") &&
    subscription.cancelAtPeriodEnd
  ) {
    return "Active, renewal canceled";
  }

  if (
    (subscription.status === "active" ||
      subscription.status === "incomplete") &&
    subscription.usedSeatCount > subscription.seatCount
  ) {
    return "Need more seats";
  }

  return {
    active: "Active",
    canceled: "Canceled",
    past_due: "Past Due",
    unpaid: "Unpaid",
    paused: "Paused",
    incomplete: "Active (payment processing)",
    incomplete_expired: "Payment Failed",
    trialing: "Trialing",
  }[subscription.status];
}

export function Subscription(props: {
  subscription: SubscriptionWithTeams;
  disabled?: boolean;
}) {
  const { subscription } = props;
  const prompt = PromptStore.usePrompt();
  const userStore = UserStore.use();

  return (
    <DetailedButton
      icon="credit-card"
      titleLabel="Created"
      title={format(subscription.created, "MMM do, yyyy")}
      disabled={props.disabled}
      detailsIntent={
        subscription.usedSeatCount > subscription.seatCount ||
        (subscription.status !== "active" &&
          subscription.status !== "incomplete")
          ? "danger"
          : "none"
      }
      details={
        <>
          <div>
            <b>
              {subscription.usedSeatCount} / {subscription.seatCount}
            </b>{" "}
            seats filled
          </div>
          <div>{getSubscriptionStatusLabel(subscription)}</div>
        </>
      }
      footer={{
        subscriptionId: subscription.shortId,
      }}
      onOpen={async () => {
        if (subscription.mode === "free") {
          const result = await prompt<number | "delete">((props) => {
            return (
              <AdminSubscriptionPrompt
                onResolve={(result) => {
                  if (result.action === "setSeatCount") {
                    props.resolve(result.value);
                  } else {
                    props.resolve("delete");
                  }
                }}
              />
            );
          });

          if (result === null) {
            return;
          }

          if (result === "delete") {
            await api.admin.deleteFreeSubscription.mutate({
              subscriptionId: subscription.id,
            });
          } else {
            await api.admin.updateFreeSubscription.mutate({
              subscriptionId: subscription.id,
              seats: result,
            });
          }

          await userStore.query.refetch();
        } else {
          window.open(`${ClientEnv.ORIGIN}/api/stripe/manage`);
        }
      }}
    />
  );
}
