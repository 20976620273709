.button {
  position: absolute;
  right: 20px;
  top: 58px;
  z-index: 2;
  border: none;
  text-align: left;
  padding: 15px 18px;
  width: 206px;
  height: 130px;
  display: flex;
  flex-direction: column;
  color: var(--light-gray2);
  border-radius: 5px;
  cursor: pointer;
  background: rgba(47, 52, 60, 0.3);
  box-shadow:
    0px 2px 10px -1px rgba(0, 0, 0, 0.1),
    inset 0px 0px 0px 1px rgba(255, 255, 255, 0.075);
  text-shadow: 0px 1px 3px rgba(47, 52, 60, 0.9);
  backdrop-filter: blur(5px);
  transition: all ease 0.3s;
}

.button:hover {
  color: var(--white);
  background: rgba(56, 62, 71, 0.4);
  box-shadow:
    0px 2px 10px -1px rgba(0, 0, 0, 0.2),
    inset 0px 0px 0px 2px rgba(255, 255, 255, 0.6);
}
