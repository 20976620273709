import { Dialog } from "@blueprintjs/core";
import { panic } from "@zilch/panic";
import React, { createContext, useCallback, useContext, useState } from "react";
import css from "./PromptStore.module.css";

interface Props<T> {
  width?: number | string;
  resolve(value: T | null): void;
  borderRadius?: number;
}

type Render<T> = (props: Props<T>) => React.ReactNode;

function DialogPrompt<T>(
  props: Props<T> & { lowerZIndex: boolean; render: Render<T> }
) {
  const [open, setOpen] = useState(true);

  const resolve = (value: T | null) => {
    setOpen(false);
    props.resolve(value);
  };

  return (
    <Dialog
      onClose={() => resolve(null)}
      isOpen={open}
      portalClassName={props.lowerZIndex ? css.lowerZIndex : undefined}
      className={css.dialog}
      style={{
        width:
          typeof props.width === "string"
            ? props.width
            : (props.width ?? 533) + "px",
        borderRadius:
          props.borderRadius === undefined
            ? undefined
            : props.borderRadius + "px",
      }}
      enforceFocus
    >
      {props.render({ resolve })}
    </Dialog>
  );
}

type Prompt = <T>(
  render: Render<T>,
  options?: {
    lowerZIndex?: boolean;
    width?: number | string;
    borderRadius?: number;
  }
) => Promise<T | null>;

const context = createContext<Prompt | null>(null);

export const PromptStore = {
  Provide: ProvidePromptStore,
  usePrompt: usePromptFromContext,
};

function usePromptFromContext() {
  return useContext(context) ?? panic("Context should be provided");
}

let dialogIdCounter = 0;

function ProvidePromptStore({
  children,
}: {
  children: (prompts: React.ReactNode) => React.ReactNode;
}) {
  const [dialogs, setDialogs] = useState(new Map<number, React.ReactNode>());

  const prompt: Prompt = useCallback((render, options) => {
    const id = dialogIdCounter++;

    return new Promise((resolve) => {
      setDialogs((dialogs) => {
        const newDialogs = new Map(dialogs);

        const dialog = (
          <DialogPrompt
            render={render}
            lowerZIndex={options?.lowerZIndex ?? false}
            width={options?.width}
            resolve={(value) => {
              // Let transition out then remove
              setTimeout(() => {
                setDialogs((dialogs) => {
                  const newDialogs = new Map(dialogs);
                  newDialogs.delete(id);
                  return newDialogs;
                });
              }, 300);
              resolve(value);
            }}
            borderRadius={options?.borderRadius}
          />
        );

        newDialogs.set(id, dialog);
        return newDialogs;
      });
    });
  }, []);

  return (
    <>
      <context.Provider value={prompt}>
        {children(
          <>
            {Array.from(dialogs.entries()).map(([key, element]) => {
              return <React.Fragment key={key}>{element}</React.Fragment>;
            })}
          </>
        )}
      </context.Provider>
    </>
  );
}
