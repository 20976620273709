export function loadImg(src: string) {
  return new Promise<void>((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      resolve();
      image.remove();
    };
    image.onerror = () => {
      reject();
      image.remove();
    };
  });
}
