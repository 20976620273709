import {
  AnchorButton,
  Colors,
  Icon,
  Menu,
  MenuDivider,
  Tag,
} from "@blueprintjs/core";
import { MenuItem2 } from "@blueprintjs/popover2";
import { Select2 } from "@blueprintjs/select";
import { useQuery } from "@tanstack/react-query";
import { classes } from "@zilch/css-utils";
import { useDelay } from "@zilch/delay";
import type { GameConfig } from "@zilch/game-config";
import { panic } from "@zilch/panic";
import React, { useEffect, useRef, useState } from "react";
import { SiGithub } from "react-icons/si";
import { api } from "../../api";
import { groups, routes, useRoute } from "../../router";
import { StarStore } from "../../stores/StarStore";
import { transitionScreen } from "../../transitionScreen";
import { AccountButton } from "./AccountButton";
import { Highlight } from "./Highlight";
import css from "./TitleBar.module.css";
import { preventDefaultLinkClickBehavior } from "type-route";
import { UserStore } from "../../stores/UserStore";
import { pickZilchGameFile } from "./useGameSelector";
import { PlaybookButton } from "../playbook/Playbook";
import { Popover } from "./Popover";
import { HomeScreenTitle } from "../home/HomeScreenTitle";

export function TitleBar({
  gameConfig,
  onSetTitlebarPaddingRightChangeListener,
}: {
  gameConfig: GameConfig | null;
  onSetTitlebarPaddingRightChangeListener(
    listener: (paddingRight: number) => void
  ): void;
}) {
  let title: React.ReactNode;
  let documentTitle: string;

  const route = useRoute();

  let titleKey: string = route.name || "";

  if (
    groups.githubAuthCallback.has(route) ||
    groups.githubBotAuthCallback.has(route) ||
    groups.stripeCallback.has(route)
  ) {
    title = "";
    documentTitle = "Zilch";
  } else if (route.name === routes.home.name) {
    title = <HomeScreenTitle />;
    documentTitle = "Zilch · Level up your coding skills.";
  } else if (groups.game.has(route)) {
    if (gameConfig) {
      titleKey = [
        route.name,
        JSON.stringify(gameConfig.gameId),
        route.params.release,
      ].join();

      if (gameConfig.dev) {
        const latestRoute =
          route.name === routes.internalGame.name
            ? routes.internalGame({
                gameId: route.params.gameId,
              })
            : routes.externalGame({
                owner: route.params.owner,
                repo: route.params.repo,
              });

        title = (
          <>
            <a
              href={latestRoute.href}
              onClick={(e) => {
                if (preventDefaultLinkClickBehavior(e)) {
                  latestRoute.push();
                }
              }}
              className={css.titleLink}
            >
              {gameConfig.name}
            </a>
            <span className="bp4-text-muted" style={{ opacity: 0.8 }}>
              /
            </span>
            <span className="bp4-text-muted">Game Maker</span>
            <PickGameFileButton />
          </>
        );
      } else {
        let docsUrl = "https://github.com";

        if (typeof gameConfig.gameId === "string") {
          docsUrl += "/zilch/" + gameConfig.gameId;
        } else {
          docsUrl +=
            "/" + gameConfig.gameId.owner + "/" + gameConfig.gameId.repo;
        }

        docsUrl += "/wiki";

        title = (
          <>
            {gameConfig.name}
            <Tag
              icon={<Icon color={Colors.GRAY4} size={12} icon="one-to-many" />}
              minimal
              interactive
              style={{
                color: Colors.GRAY4,
                fontWeight: 600,
              }}
              onClick={() => {
                transitionScreen(
                  typeof gameConfig.gameId === "string"
                    ? routes.tournamentInternalGame({
                        gameId: gameConfig.gameId,
                      })
                    : routes.tournamentExternalGame({
                        owner: gameConfig.gameId.owner,
                        repo: gameConfig.gameId.repo,
                      })
                );
              }}
            >
              Tournament
            </Tag>
            <GameVersionSelector gameConfig={gameConfig} />
            <Popover
              placement="bottom"
              content={
                <Menu>
                  <MenuItem2
                    icon="manual"
                    text="Documentation"
                    href={docsUrl}
                    target="_blank"
                    rel="noreferrer"
                  />
                  <MenuItem2
                    icon="tick-circle"
                    text="Attribution"
                    href="/attribution"
                    target="_blank"
                  />
                </Menu>
              }
            >
              <Tag
                minimal
                interactive
                icon={<Icon color={Colors.GRAY4} size={12} icon="more" />}
              />
            </Popover>
          </>
        );
      }

      documentTitle = `Zilch · ${gameConfig.name}`;
    } else {
      title = "";
      documentTitle = "Zilch";
    }
  } else if (route.name === routes.account.name) {
    title = "Account";
    documentTitle = "Zilch · Account";
  } else if (route.name === routes.store.name) {
    title = "Store";
    documentTitle = "Zilch · Store";
  } else if (groups.tournament.has(route)) {
    if (gameConfig) {
      const route =
        typeof gameConfig.gameId === "string"
          ? routes.internalGame({ gameId: gameConfig.gameId })
          : routes.externalGame({
              owner: gameConfig.gameId.owner,
              repo: gameConfig.gameId.repo,
            });
      titleKey = [route.name, JSON.stringify(gameConfig.gameId)].join();
      title = (
        <>
          <a
            href={route.href}
            onClick={(e) => {
              if (preventDefaultLinkClickBehavior(e)) {
                transitionScreen(route);
              }
            }}
            className={css.titleLink}
          >
            {gameConfig.name}
          </a>
          <span className="bp4-text-muted" style={{ opacity: 0.8 }}>
            /
          </span>
          <span className="bp4-text-muted">Tournament</span>
        </>
      );
      documentTitle = `Zilch · ${gameConfig.name} Tournament`;
    } else {
      title = "";
      documentTitle = "Zilch";
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (route.name === false) {
    title = "404";
    documentTitle = "Zilch · 404";
  } else {
    panic("Unhandled route", route);
  }

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const onSetTitlebarPaddingRightChangeListenerRef = useRef(
    onSetTitlebarPaddingRightChangeListener
  );
  onSetTitlebarPaddingRightChangeListenerRef.current =
    onSetTitlebarPaddingRightChangeListener;
  useEffect(() => {
    onSetTitlebarPaddingRightChangeListenerRef.current((paddingRight) => {
      if (containerRef.current) {
        const transition =
          containerRef.current.style.right === "0px" || paddingRight === 0;
        containerRef.current.style.right = paddingRight + "px";
        containerRef.current.style.transition = transition
          ? "all ease .2s"
          : "none";
      }
    });
  }, []);

  const starStore = StarStore.use();
  const mounted = useDelay(0);

  return (
    <>
      <div
        className={classes(
          css.backButtonContainer,
          (!mounted || route.name === routes.home.name) &&
            css.backButtonContainerHidden
        )}
      >
        <AnchorButton
          icon="arrow-left"
          outlined
          tabIndex={route.name === routes.home.name ? -1 : 0}
          href={routes.home().href}
          onClick={(e) => {
            if (preventDefaultLinkClickBehavior(e)) {
              transitionScreen(routes.home());
            }
          }}
          className={css.backButton}
          large
        />
      </div>
      <div
        ref={containerRef}
        className={classes(
          css.container,
          route.name !== routes.home.name && css.containerWithBackButton
        )}
      >
        <div className={css.leftSection}>
          <AnimatedTitle title={title} titleKey={titleKey} />
        </div>
        <div className={css.rightSection}>
          {starStore.renderStarButton()}
          <AccountButton />
          <PlaybookButton />
        </div>
      </div>
    </>
  );
}

function AnimatedTitle({
  title,
  titleKey,
}: {
  title: React.ReactNode;
  titleKey: string;
}) {
  const [titles, setTitles] = useState<
    { key: number; value: React.ReactNode }[]
  >([]);

  useEffect(() => {
    setTitles((list) => {
      return [{ value: title, key: (list[0]?.key ?? 0) + 1 }, ...list];
    });
    setTimeout(() => {
      setTitles((list) => (list.length > 1 ? list.slice(0, -1) : list));
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleKey]);

  return (
    <>
      {titles.map((title, index) => {
        return (
          <Title
            key={title.key}
            title={title.value}
            transitionOut={index > 0}
          />
        );
      })}
    </>
  );
}

function Title({
  title,
  transitionOut,
}: {
  title: React.ReactNode;
  transitionOut: boolean;
}) {
  const mounted = useDelay(16);
  return (
    <div
      style={{
        position: "absolute",
        transition: transitionOut
          ? "all ease .1s"
          : "all cubic-bezier(.5,1.6,.5,1) .4s .3s",
        transform:
          transitionOut || mounted
            ? "translateY(0px) scale(1)"
            : "translateY(-20px) scale(1.1)",
        opacity: mounted && !transitionOut ? 1 : 0,
        display: "flex",
        alignItems: "center",
        gap: "12px",
      }}
    >
      {title}
    </div>
  );
}

export interface Release {
  tagName: string;
  latest: boolean;
  preRelease: boolean;
  createdAt: number;
}

function GameVersionSelector(props: { gameConfig: GameConfig }) {
  const [hasOpened, setHasOpened] = useState(false);
  const [showList, setShowList] = useState(false);
  const route = useRoute();

  const userStore = UserStore.use();
  const authenticated = userStore.query.data?.type === "authenticated";

  const releaseListQuery = useQuery(
    ["getReleases", props.gameConfig.gameId, authenticated],
    ({ signal }) => {
      if (!authenticated) {
        return [];
      }

      return api.game.getReleaseList.query(
        {
          owner:
            typeof props.gameConfig.gameId === "string"
              ? "zilch"
              : props.gameConfig.gameId.owner,
          repo:
            typeof props.gameConfig.gameId === "string"
              ? props.gameConfig.gameId
              : props.gameConfig.gameId.repo,
        },
        { signal }
      );
    },
    {
      enabled: hasOpened && showList,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
    }
  );

  const [isDefinitelyLatestRelease] = useState(
    groups.game.has(route) ? !route.params.release : false
  );

  const onSetRelease = (release: Release | null) => {
    if (!groups.game.has(route)) {
      return;
    }

    const releaseTag =
      release === null || release.latest ? undefined : release.tagName;

    if (route.name === routes.internalGame.name) {
      routes
        .internalGame({
          ...route.params,
          dev: undefined,
          release: releaseTag,
        })
        .replace();
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (route.name === routes.externalGame.name) {
      routes
        .externalGame({
          ...route.params,
          dev: undefined,
          release: releaseTag,
        })
        .replace();
    } else {
      panic("Unexpected route", route);
    }
  };

  return (
    <Select2<Release>
      items={releaseListQuery.data ?? []}
      inputProps={{
        placeholder: "Version",
        autoFocus: authenticated,
        disabled: !authenticated,
      }}
      popoverProps={{
        placement: "bottom",
        onOpening: () => {
          setShowList(false);
          setHasOpened(true);
        },
        popoverClassName: css.versionSelector,
      }}
      itemPredicate={(query, release) =>
        release.tagName.includes(query.trim().toLowerCase())
      }
      noResults={
        !authenticated ? (
          <MenuItem2
            text="Sign in to load release list"
            labelElement={<Icon icon="arrow-right" />}
            onClick={() => userStore.signIn("titleBar")}
          />
        ) : releaseListQuery.isFetching ? (
          <MenuItem2 text="Loading..." disabled />
        ) : releaseListQuery.isError ? (
          <MenuItem2 text="Error encountered" disabled />
        ) : (
          <MenuItem2 text="No results" disabled />
        )
      }
      filterable={showList}
      itemListRenderer={
        showList
          ? undefined
          : () => {
              const owner =
                typeof props.gameConfig.gameId === "string"
                  ? "zilch"
                  : props.gameConfig.gameId.owner;
              const repo =
                typeof props.gameConfig.gameId === "string"
                  ? props.gameConfig.gameId
                  : props.gameConfig.gameId.repo;
              const repoLink = `https://github.com/${owner}/${repo}`;
              return (
                <Menu>
                  {!isDefinitelyLatestRelease && (
                    <div className={css.versionWarning}>
                      Select the latest version to ensure an optimal game play
                      experience.
                    </div>
                  )}
                  <MenuDivider title="Select Version" />
                  <MenuItem2
                    text="Latest"
                    icon="arrow-right"
                    label={
                      isDefinitelyLatestRelease && props.gameConfig.release
                        ? props.gameConfig.release
                        : "Recommended"
                    }
                    onClick={() => {
                      onSetRelease(null);
                    }}
                  />
                  <MenuItem2
                    text="Past or pre-release..."
                    icon="history"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowList(true);
                    }}
                  />
                  <MenuDivider title="Game maker" />
                  <MenuItem2
                    icon={<SiGithub color={Colors.GRAY4} />}
                    text={`${props.gameConfig.name} source code`}
                    href={repoLink}
                    target="_blank"
                    rel="noreferrer"
                  />
                  <MenuItem2
                    icon="export"
                    text="Upload .zilchgame file..."
                    onClick={async () => {
                      const result = await pickZilchGameFile();
                      if (result) {
                        routes
                          .externalGame({
                            owner: result.owner,
                            repo: result.repo,
                            dev: true,
                          })
                          .push();
                      }
                    }}
                  />
                </Menu>
              );
            }
      }
      itemRenderer={(value, { query, handleClick, handleFocus, modifiers }) => {
        return (
          <MenuItem2
            active={modifiers.active}
            disabled={modifiers.disabled}
            onClick={handleClick}
            onFocus={handleFocus}
            text={<Highlight text={value.tagName} highlight={query} />}
            key={value.tagName}
            icon={
              (props.gameConfig.release === null && value.latest) ||
              props.gameConfig.release === value.tagName
                ? "small-tick"
                : "blank"
            }
            labelElement={
              <span
                style={{ color: modifiers.active ? Colors.BLUE5 : undefined }}
              >
                {value.latest
                  ? "latest"
                  : value.preRelease
                  ? "pre-release"
                  : undefined}
              </span>
            }
          />
        );
      }}
      onItemSelect={(release) => {
        onSetRelease(release);
      }}
    >
      <Tag
        interactive
        intent={isDefinitelyLatestRelease ? undefined : "warning"}
        rightIcon={
          <Icon
            icon="chevron-down"
            color={isDefinitelyLatestRelease ? Colors.GRAY4 : undefined}
            size={12}
          />
        }
        minimal
        icon={
          isDefinitelyLatestRelease ? undefined : (
            <Icon
              icon="warning-sign"
              size={12}
              color={Colors.ORANGE5}
              style={{ marginRight: "4px" }}
            />
          )
        }
        style={{
          color: isDefinitelyLatestRelease ? Colors.GRAY4 : undefined,
          fontWeight: 600,
        }}
      >
        {props.gameConfig.release}
      </Tag>
    </Select2>
  );
}

function PickGameFileButton() {
  const route = useRoute();
  const [pickingGameFile, setPickingGameFile] = useState(false);

  return (
    <button
      disabled={pickingGameFile}
      className={css.gameMakerUpload}
      onClick={() => {
        setPickingGameFile(true);
        pickZilchGameFile()
          .then((result) => {
            if (result) {
              if (
                route.name !== routes.externalGame.name ||
                route.params.owner !== result.owner ||
                route.params.repo !== result.repo ||
                route.params.dev !== true
              ) {
                routes
                  .externalGame({
                    owner: result.owner,
                    repo: result.repo,
                    dev: true,
                  })
                  .push();
              } else {
                routes
                  .externalGame({
                    owner: result.owner,
                    repo: result.repo,
                    refreshKey: (route.params.refreshKey ?? 0) + 1,
                    bots: route.params.bots,
                    speed: route.params.speed,
                    dev: true,
                  })
                  .replace();
              }
            }
          })
          .finally(() => {
            setPickingGameFile(false);
          });
      }}
    >
      <span style={{ color: Colors.BLUE5, fontWeight: 600 }}>
        Update .zilchgame file
      </span>
      &nbsp;&nbsp;
      <span className="bp4-text-muted">(drag/drop)</span>
    </button>
  );
}
