@keyframes Pulse {
  0%,
  100% {
    opacity: 0.75;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
}

.spinner {
  transition: all ease-in 0.3s;
}

.spinnerHidden {
  opacity: 0;
  transform: scale(0.5);
}

.gameLoadingContainer {
  transition: all ease 1.9s;
  position: absolute;
  left: 0;
  background: var(--dark-gray3);
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
}

.gameLoadingContainerHidden {
  opacity: 0;
}

.gameLoadingName {
  font-weight: 600;
  font-size: 18px;
  white-space: nowrap;
  background: rgba(64, 72, 84, 0.6);
  backdrop-filter: blur(5px);
  padding: 6px 15px;
  color: var(--light-gray5);
  border-radius: 18px;
  box-shadow: 0 1px 16px -2px rgba(0, 0, 0, 0.3);
}

.gameLoadingTopSection {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gameLoadingBottomSection {
  position: absolute;
  transition: all ease 0.4s;
}

.gameLoadingIcon {
  animation: Pulse 2s ease 0.3s infinite both;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gameLoadingIconContainer {
  position: absolute;
  width: 340px;
  height: 340px;
}
