.iconContainer {
  position: absolute;
  top: -7px;
  right: -7px;
  z-index: 3;
  display: flex;
  height: 30px;
  width: 30px;
  background: rgb(42, 47, 55);
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  border: 1px solid rgb(80, 90, 104);
  transition: all ease .3s .4s;
}

.iconContainerHidden {
  opacity: 0;
  transition: all ease .2s 0s;
  transform: scale(.1);
}

.textContainer {
  height: 22px;
  box-shadow: inset 0 0 0 1px rgba(64, 72, 84, 0.6);
  overflow: hidden;
  background: rgba(28, 33, 39, 0.95);
  border-radius: 3px;
  color: var(--gray3);
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 2px;
  padding-left: 2px;
  font-size: 11px;
  border-top-right-radius: 0px;
  width: 230px;
  transition: all ease .3s .4s;
  border-top-left-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textContainerPrimary {
  color: var(--light-gray5);
}

.textContainerHidden {
  opacity: 0;
  transition: all ease .2s 0s;
  height: 0px;
}