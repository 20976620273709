export function expandGrid(avatar: boolean[][]) {
  const newIcon = avatar.map((row) => [...row]);
  for (let y = 0; y < avatar.length; y++) {
    for (let x = 0; x < avatar[y]!.length; x++) {
      if (
        avatar[y - 1]?.[x] ||
        avatar[y + 1]?.[x] ||
        avatar[y]![x - 1] ||
        avatar[y]![x + 1] ||
        avatar[y - 1]?.[x - 1] ||
        avatar[y + 1]?.[x - 1] ||
        avatar[y - 1]?.[x + 1] ||
        avatar[y + 1]?.[x + 1]
      ) {
        newIcon[y]![x] = true;
      }
    }
  }
  return newIcon;
}
