@keyframes TransitionIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.outerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 310px;
  animation: TransitionIn 0.4s cubic-bezier(0.5, 1.5, 0.5, 1) 1 both;
}

@keyframes TopLeftCornerAnimation {
  0%,
  100% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(-10px, -10px);
  }
}

@keyframes TopRightCornerAnimation {
  0%,
  100% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(10px, -10px);
  }
}

@keyframes BottomLeftCornerAnimation {
  0%,
  100% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(-10px, 10px);
  }
}

@keyframes BottomRightCornerAnimation {
  0%,
  100% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(10px, 10px);
  }
}

.corner {
  width: 20px;
  pointer-events: none;
  height: 20px;
  border: 2px solid var(--light-gray1);
  position: absolute;
  transition: all ease 0.3s;
  z-index: 1;
}

.cornerRecommendationsSatisfied {
  animation-play-state: paused !important;
  opacity: 0;
}

.topLeftCorner {
  top: 20px;
  left: 20px;
  border-bottom: none;
  border-right: none;
  animation: TopLeftCornerAnimation 1.5s ease infinite both;
}

.topRightCorner {
  top: 20px;
  right: 20px;
  border-bottom: none;
  border-left: none;
  animation: TopRightCornerAnimation 1.5s ease infinite both;
}

.bottomLeftCorner {
  bottom: 20px;
  left: 20px;
  border-top: none;
  border-right: none;
  animation: BottomLeftCornerAnimation 1.5s ease infinite both;
}

.bottomRightCorner {
  bottom: 20px;
  right: 20px;
  border-top: none;
  border-left: none;
  animation: BottomRightCornerAnimation 1.5s ease infinite both;
}

.innerContainer {
  max-width: 560px;
  width: calc(100% - 40px);
  position: relative;
}

.message {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
  transition: all ease 0.3s;
}

@media (max-width: 365px) or (max-height: 300px) {
  .message {
    font-size: 20px;
  }
}

.emphasizedText {
  color: var(--forest5);
  font-weight: 800;
}

.sizeBarSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding-top: 15px;
}

.bottomSection {
  position: relative;
  height: 40px;
  margin-top: 40px;
  padding-top: 10px;
  border-top: 1px solid var(--dark-gray5);
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 10px;
}

.continueButtonContainer {
  position: absolute;
  right: 0px;
  top: 0px;
}

.popoverContent {
  padding: 10px 15px;
  font-weight: 600;
  max-width: calc(100vw - 20px);
}

@media (max-width: 340px) {
  .popoverContent {
    max-width: min(200px, calc(100vw - 20px));
  }
}

.sizeBarTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  transition: all ease 0.3s;
}

.continueButton {
  height: 50px;
  border-radius: 10px !important;
  width: 130px;
  transition: all ease 0.3s;
}

.sizeBarBacking {
  overflow: hidden;
  height: 6px;
  background: var(--dark-gray1);
  border-radius: 3px;
}

.sizeBar {
  height: 100%;
  transition: all ease 0.3s;
  width: 100%;
  transform-origin: 0px 0px;
}

.continueText {
  font-weight: 600;
  font-size: 18px;
}

.sizeBarContainer {
  padding: 15px;
  padding-top: 13px;
  background: var(--dark-gray2);
  border-radius: 10px;
}

@media (max-width: 480px) or (max-height: 375px) {
  .message {
    font-size: 24px;
    margin-bottom: 15px;
  }
}
