import { Colors, Icon } from "@blueprintjs/core";
import { BotAvatarSvg } from "@zilch/bot-avatar-svg";
import {
  BotColor,
  BotType,
  type SlotSelection,
  zilchBotAvatars,
} from "@zilch/bot-models";
import { useMemo, useRef } from "react";
import type { GameOutcome } from "./GameEngine";
import css from "./GameOutcomeDisplay.module.css";
import { AiFillBug } from "react-icons/ai";
import { RiSwordFill } from "react-icons/ri";
import { GiLaurelsTrophy } from "react-icons/gi";
import { MdConstruction } from "react-icons/md";
import { FaBalanceScale } from "react-icons/fa";
import { useUserBotConfigListQuery } from "./useUserBotConfigListQuery";
import { useSource } from "./loadGameConfig";
import type { GameId, InternalGameId } from "@zilch/game-config";
import { FallbackAvatar } from "../common/FallbackAvatar";
import { groups, useRoute } from "../../router";

interface Props {
  outcome: GameOutcome;
  translateY?: string;
  offsetLeft?: number;
}

export function GameOutcomeDisplay(props: Props) {
  const outcomeRef = useRef(props.outcome);
  if (props.outcome.status === "done") {
    outcomeRef.current = props.outcome;
  }

  return (
    <Outcome
      outcome={outcomeRef.current}
      translateY={props.translateY}
      offsetLeft={props.offsetLeft ?? 0}
      transitionOut={
        props.outcome.status !== "done" ||
        (props.outcome.replayProgress !== null &&
          props.outcome.replayProgress !== props.outcome.gameLength)
      }
    />
  );
}

function Outcome({
  outcome,
  transitionOut,
  offsetLeft,
  translateY,
}: {
  outcome: GameOutcome;
  transitionOut: boolean;
  offsetLeft: number;
  translateY?: string;
}) {
  const primaryOutcome = outcome.status === "done" ? outcome.primary : null;
  const plural = primaryOutcome?.subjectIndices.length !== 1;

  const winText = groups.tournament.has(useRoute()) ? "Winner" : "Victory";

  const title = primaryOutcome
    ? {
        "bug-detected": "Oops",
        defeat: "Defeat",
        unimplemented: "New Bot" + (plural ? "s" : ""),
        "defeat-by-boss": "Defeat",
        "defeat-by-practice": "Defeat",
        "victory-over-boss": winText,
        "victory-over-practice": winText,
        "victory-over-boss-but-incompatible-config": (
          <>
            &nbsp;Victory<span style={{ fontWeight: 500 }}>*</span>
          </>
        ),
        "time-limit-exceeded": "Time's Up",
        victory: winText,
        draw: "Draw",
        stopped: "Stopped",
        "game-locked": "Game Locked",
        "game-error": "Error",
        "connection-problem": "Offline",
      }[primaryOutcome.type]
    : "";

  const outcomeColor = primaryOutcome
    ? {
        "bug-detected": Colors.GRAY3,
        defeat: Colors.RED5,
        unimplemented: Colors.GREEN5,
        "defeat-by-boss": Colors.RED5,
        "victory-over-boss": Colors.GOLD5,
        "defeat-by-practice": Colors.RED5,
        "victory-over-practice": Colors.GOLD5,
        "victory-over-boss-but-incompatible-config": Colors.GOLD5,
        draw: Colors.BLUE5,
        stopped: Colors.GRAY3,
        "time-limit-exceeded": Colors.RED5,
        victory: Colors.GOLD5,
        "game-error": Colors.GRAY3,
        "connection-problem": Colors.GRAY3,
        "game-locked": Colors.GRAY3,
      }[primaryOutcome.type]
    : "";

  const outcomeIcon = primaryOutcome?.type
    ? {
        "bug-detected": <AiFillBug size={22} color={Colors.GRAY5} />,
        defeat: <RiSwordFill size={20} color={Colors.GRAY5} />,
        "defeat-by-boss": <RiSwordFill size={20} color={Colors.GRAY5} />,
        "defeat-by-practice": <RiSwordFill size={20} color={Colors.GRAY5} />,
        unimplemented: <MdConstruction size={24} color={Colors.GRAY5} />,
        "victory-over-boss": <GiLaurelsTrophy size={22} color={Colors.GRAY5} />,
        "victory-over-practice": (
          <GiLaurelsTrophy size={22} color={Colors.GRAY5} />
        ),
        "victory-over-boss-but-incompatible-config": (
          <GiLaurelsTrophy size={22} color={Colors.GRAY5} />
        ),
        draw: <FaBalanceScale size={22} color={Colors.GRAY5} />,
        victory: <GiLaurelsTrophy size={22} color={Colors.GRAY5} />,
        stopped: <Icon icon="stop" size={18} color={Colors.GRAY5} />,
        "time-limit-exceeded": (
          <Icon icon="stopwatch" size={18} color={Colors.GRAY5} />
        ),
        "game-locked": <Icon icon="lock" size={16} color={Colors.GRAY5} />,
        "game-error": <Icon icon="offline" size={16} color={Colors.GRAY5} />,
        "connection-problem": (
          <Icon icon="offline" size={16} color={Colors.GRAY5} />
        ),
      }[primaryOutcome.type]
    : null;

  return (
    <OutcomeTitle
      outcome={outcome}
      transitionOut={transitionOut}
      outcomeColor={outcomeColor}
      outcomeIcon={outcomeIcon}
      title={title}
      offsetLeft={offsetLeft}
      translateY={translateY}
    />
  );
}

function OutcomeTitle({
  outcomeColor,
  transitionOut,
  outcomeIcon,
  title,
  outcome,
  offsetLeft,
  translateY,
}: {
  title: string | JSX.Element;
  outcomeIcon: React.ReactNode;
  outcomeColor: string;
  transitionOut: boolean;
  outcome: GameOutcome;
  offsetLeft: number;
  translateY?: string;
}) {
  const renderOutcomeIconForOutcomeAvatars = (side: -1 | 1) => {
    return (
      <div
        style={{
          transition: `all .7s cubic-bezier(.4,1.5,.4,1) ${
            transitionOut ? "0s" : ".6s"
          }`,
          transform: `translateX(${transitionOut ? -10 * side : 0}px)`,
          opacity: transitionOut ? 0 : 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {outcomeIcon}
      </div>
    );
  };

  const primaryOutcomeSlotSelections =
    outcome.status === "done"
      ? outcome.botOutcomes
          .filter(
            (botOutcome) =>
              outcome.primary?.subjectIndices.includes(botOutcome.index)
          )
          .map(({ slot }) => slot)
      : [];

  return (
    <div
      className={css.outcomeTitle}
      style={{
        borderBottomColor: outcomeColor,
        borderTopColor: outcomeColor,
        transition: transitionOut
          ? "all ease-in .2s"
          : "all .7s cubic-bezier(.4,1.5,.4,1)",
        opacity: transitionOut ? 0 : 1,
        transform: `translateY(${
          transitionOut ? "-116px" : translateY ? translateY : "0px"
        }) translateX(calc(-50% + ${offsetLeft / 2}px))`,
        transitionDelay: transitionOut ? "0s" : ".5s",
      }}
    >
      {title}
      <div className={css.outcomeAvatars}>
        {renderOutcomeIconForOutcomeAvatars(-1)}
        {primaryOutcomeSlotSelections.map((slotSelection, index) => {
          return <OutcomeAvatar key={index} slotSelection={slotSelection} />;
        })}
        {renderOutcomeIconForOutcomeAvatars(1)}
      </div>
    </div>
  );
}

function OutcomeAvatar(props: { slotSelection: SlotSelection }) {
  const source = useSource();
  const gameId = useMemo((): GameId => {
    return source.owner === "zilch"
      ? (source.repo as InternalGameId)
      : { owner: source.owner, repo: source.repo };
  }, [source]);

  const userBotConfigListQuery = useUserBotConfigListQuery(
    props.slotSelection?.type === BotType.User
      ? props.slotSelection.owner
      : null,
    gameId
  );

  if (
    !props.slotSelection ||
    !userBotConfigListQuery.isSuccess ||
    userBotConfigListQuery.data === "nonexistent-user"
  ) {
    return (
      <FallbackAvatar
        color={
          props.slotSelection ? BotColor[props.slotSelection.color] : undefined
        }
      />
    );
  }

  if (props.slotSelection.type !== BotType.User) {
    return (
      <BotAvatarSvg
        color={BotColor[props.slotSelection.color]}
        avatar={zilchBotAvatars[props.slotSelection.type]}
        size={44}
      />
    );
  }

  const avatar = userBotConfigListQuery.data.bySlot(props.slotSelection)
    ?.avatar;

  if (!avatar) {
    return <FallbackAvatar color={BotColor[props.slotSelection.color]} />;
  }

  return (
    <BotAvatarSvg
      color={BotColor[props.slotSelection.color]}
      avatar={avatar}
      size={44}
    />
  );
}
