import { createAvatarSvgShapes } from "./createAvatarSvgShapes";
import type { SvgShape } from "./SvgShape";

export function createSvgPaths({
  avatar,
  center = true,
}: {
  center?: boolean;
  avatar: string;
}) {
  const shapes = createAvatarSvgShapes(avatar);

  if (shapes === null) {
    return null;
  }

  let offsetX = 0;
  let offsetY = 0;

  if (center) {
    const points = shapes.background.flatMap((shape) =>
      shape.flatMap((path) => path)
    );

    const Xs = points.map((point) => point.x);
    const Ys = points.map((point) => point.y);

    const minX = Math.min(...Xs);
    const maxX = Math.max(...Xs);
    const minY = Math.min(...Ys);
    const maxY = Math.max(...Ys);

    offsetX = (12 - (maxX - minX)) / 2 - minX;
    offsetY = (12 - (maxY - minY)) / 2 - minY;
  }

  return {
    background: shapes.background.map((shape) =>
      renderPath(shape, offsetX, offsetY)
    ),
    foreground: shapes.foreground.map((shape) =>
      renderPath(shape, offsetX, offsetY)
    ),
  };
}

function renderPath(shape: SvgShape, offsetX: number, offsetY: number) {
  return shape
    .map(
      (p) =>
        "M " +
        p.map(({ x, y }) => `${x + offsetX},${y + offsetY}`).join(" ") +
        "z"
    )
    .join(" ");
}
