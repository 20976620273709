.dialog {
  padding: 0px !important;
}

.form {
  padding: 30px;
}

.header {
  margin-bottom: 20px !important;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.error {
  color: var(--red5);
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;
}