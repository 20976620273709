.virtualListItem {
  position: absolute;
  left: 0;
  right: 0;
}

.container {
  position: relative;
  overflow-y: scroll;
}
