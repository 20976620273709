import { panic } from "@zilch/panic";
import { StarStore } from "../../stores/StarStore";
import css from "./StoreTitle.module.css";

const levelTitles = [
  "Novice",
  "Ninja",
  "Professional",
  "Expert",
  "Guru",
  "Legend",
];

export function StoreTitle() {
  const starStore = StarStore.use();
  const levelTitle =
    levelTitles[Math.floor(starStore.totalStarCount / 3)] ??
    levelTitles[levelTitles.length - 1] ??
    panic("Expected at least one title");

  return (
    <div className={css.container}>
      <div className={css.level}>Level {starStore.totalStarCount}</div>
      <div className={css.title}>{levelTitle}</div>
    </div>
  );
}
