import { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Colors,
  FormGroup,
  InputGroup,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import { Popover2InteractionKind, Tooltip2 } from "@blueprintjs/popover2";
import { BotAvatarSvg } from "@zilch/bot-avatar-svg";
import { BotCreatorPanel } from "./BotCreatorPanel";
import { BotCreationStore } from "./BotCreator";
import { validateBotName } from "../../validateBotName";
import { BotColor, BotType } from "@zilch/bot-models";
import type { PanelActions } from "@blueprintjs/core/lib/esm/components/panel-stack2/panelTypes";
import css from "./BotCreatorNamePanel.module.css";
import { classes, delayCss, transitionInFromCss } from "@zilch/css-utils";
import { generateBotNameSuggestion } from "../../generateBotNameSuggestion";
import { api } from "../../api";
import { UserStore } from "../../stores/UserStore";
import { useUserBotConfigListQuery } from "./useUserBotConfigListQuery";
import type { GameConfig } from "@zilch/game-config";
import { sleep } from "@zilch/sleep";
import { toaster } from "../../toaster";
import { useDelayedValue } from "@zilch/delay";

export function BotCreatorNamePanel(props: PanelActions) {
  const store = useContext(BotCreationStore);
  const userStore = UserStore.use();

  const [nameSuggestion, setNameSuggestion] = useState(() =>
    generateBotNameSuggestion()
  );
  const [validationMessageOpen, setValidationMessageOpen] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null
  );
  const validationMessageOpenDelayed =
    useDelayedValue(validationMessageOpen, {
      delay: 400,
    }) && validationMessageOpen;
  const [created, setCreated] = useState(false);
  const creatingOrCreated = store?.creating || created;
  const creatingCreatedOrSigningIn = creatingOrCreated || !!userStore.signingIn;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const unmountedRef = useRef(false);
  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  const [creationProgress, setCreationProgress] = useState(0);

  useEffect(() => {
    if (!creatingOrCreated) {
      setCreationProgress(0);
      return;
    }

    setCreationProgress(0);

    const interval = setInterval(() => {
      setCreationProgress((progress) => {
        const newProgress = Math.min(1, progress + 0.0095);
        if (newProgress === 1) {
          clearInterval(interval);
        }
        return newProgress;
      });
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [creatingOrCreated]);

  const botConfigListQuery = useUserBotConfigListQuery(
    userStore.query.data?.type === "authenticated"
      ? userStore.query.data.likelyLogin
      : null,
    store?.gameConfig.gameId ?? null
  );

  return (
    <BotCreatorPanel {...props} className={css.container}>
      <div
        className={classes(
          css.avatarContainer,
          creatingOrCreated && css.avatarContainerCreating
        )}
      >
        <Spinner
          className={classes(
            css.creationIndicator,
            !creatingOrCreated && css.creationIndicatorHidden
          )}
          size={150}
          intent="primary"
          value={creationProgress === 1 ? undefined : creationProgress}
        />
        <div
          className={classes(
            css.avatar,
            creatingOrCreated && css.avatarCreating
          )}
        >
          <BotAvatarSvg
            size={140}
            avatar={store?.botConfig.avatar ?? ""}
            color={BotColor[store?.botConfig.preferredColor ?? "blue"]}
            className={classes(transitionInFromCss.bottom, delayCss["350"])}
          />
        </div>
      </div>
      <div
        className={classes(
          css.creationMessage,
          (!creatingOrCreated || creationProgress >= 0.35) &&
            css.creationMessageHidden
        )}
      >
        <div style={{ marginBottom: "15px" }}>
          {creatingOrCreated && (
            <Tag
              minimal
              round
              intent="primary"
              style={{ fontWeight: 600, animationDelay: ".35s" }}
              className={transitionInFromCss.bottom}
            >
              About 10s remaining
            </Tag>
          )}
        </div>
        <div style={{ whiteSpace: "nowrap" }}>
          Creating GitHub repository for
        </div>
        <span
          style={{ fontWeight: 600, color: Colors.WHITE, whiteSpace: "nowrap" }}
        >
          {store?.botConfig.name ?? "your bot"}
        </span>
      </div>
      <div
        className={classes(
          css.creationMessage,
          (!creatingOrCreated || creationProgress < 0.35) &&
            css.creationMessageHidden
        )}
      >
        <div style={{ marginBottom: "15px" }}>
          {creatingOrCreated && (
            <Tag
              minimal
              round
              intent="primary"
              style={{ fontWeight: 600, animationDelay: "4.1s" }}
              className={transitionInFromCss.bottom}
            >
              Tip
            </Tag>
          )}
        </div>
        <div style={{ whiteSpace: "nowrap" }}>
          Get{" "}
          <span style={{ fontWeight: 600, color: Colors.WHITE }}>
            {store?.botConfig.name ?? "your bot"}
          </span>{" "}
          up and running
        </div>
        <div style={{ whiteSpace: "nowrap" }}>
          quickly with{" "}
          <a
            rel="noreferrer"
            target="_blank"
            style={{ fontWeight: 600 }}
            href="https://github.com/features/codespaces"
          >
            GitHub Codespaces.
          </a>
        </div>
      </div>
      <div
        className={classes(
          css.formGroupContainer,
          creatingOrCreated && css.formGroupContainerHidden
        )}
      >
        <FormGroup
          className={classes(
            css.formGroup,
            transitionInFromCss.bottom,
            delayCss["350"]
          )}
          helperText={
            <>
              Need inspiration? How about{" "}
              <a
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    commitSuggestion();
                  }
                }}
                style={{
                  opacity: creatingCreatedOrSigningIn ? 0.5 : 1,
                  textDecoration: creatingCreatedOrSigningIn
                    ? "none"
                    : undefined,
                }}
                onClick={commitSuggestion}
              >
                <b>{nameSuggestion}</b>
              </a>
              ?
            </>
          }
          label="Bot Name"
        >
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Tooltip2
              content={validationMessage ?? "Try another name"}
              placement="top"
              isOpen={validationMessageOpenDelayed}
              onClose={() => {
                setValidationMessageOpen(false);
              }}
              // @ts-expect-error
              interactionKind={Popover2InteractionKind.CLICK}
              className={css.tooltip}
            >
              <InputGroup
                placeholder="Something short and memorable"
                autoFocus
                disabled={creatingCreatedOrSigningIn}
                large
                fill
                intent={validationMessageOpen ? "danger" : "none"}
                inputRef={(element) => (inputRef.current = element)}
                value={store?.botConfig.name ?? ""}
                onChange={(event) => {
                  store?.onSetBotConfig((config) => {
                    return {
                      ...config,
                      name: event.target.value,
                    };
                  });
                  setValidationMessageOpen(false);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    submit(store?.botConfig.name ?? "");
                  }
                }}
                rightElement={
                  <Button
                    style={{ minWidth: "80px" }}
                    loading={!!userStore.signingIn}
                    disabled={
                      creatingCreatedOrSigningIn ||
                      (store?.botConfig.name?.trim().length ?? 0) === 0
                    }
                    intent={validationMessageOpen ? "danger" : "primary"}
                    onClick={() => submit(store?.botConfig.name ?? "")}
                  >
                    Create
                  </Button>
                }
              />
            </Tooltip2>
          </div>
        </FormGroup>
      </div>
    </BotCreatorPanel>
  );

  function commitSuggestion() {
    if (creatingCreatedOrSigningIn) {
      return;
    }

    store?.onSetBotConfig((config) => {
      return { ...config, name: nameSuggestion };
    });

    setNameSuggestion(generateBotNameSuggestion());
  }

  async function submit(botName: string) {
    if (!userStore.query.isSuccess) {
      alert("Try again after verifying you've signed in successfully.");
      return;
    }

    let owner: string | null = null;

    if (userStore.query.data.type === "authenticated") {
      owner = userStore.query.data.likelyLogin;
    } else {
      const signInResult = await userStore.signIn("botCreator");

      if (signInResult?.data?.type === "authenticated") {
        owner = signInResult.data.likelyLogin;
      } else {
        return;
      }
    }

    if (unmountedRef.current) {
      // If the user has left this screen don't go through with creation
      // but do let them know they've been signed in.
      toaster.show({
        intent: "success",
        icon: "tick",
        message: "Sign in successful. You can create bots now!",
      });
      return;
    }

    store?.onSetCreating(true);

    const start = Date.now();

    const normalizedName = botName.trim().replace(/\s+/g, " ");
    const { problems } = validateBotName(normalizedName);

    if (problems.length > 0) {
      setValidationMessageOpen(true);
      setValidationMessage(problems[0]!);
      inputRef.current?.focus();
      store?.onSetCreating(false);
      return;
    }

    store?.onSetBotConfig((config) => {
      return {
        ...config,
        name: normalizedName,
      };
    });

    if (
      !store ||
      !store.botConfig.avatar ||
      !store.botConfig.game ||
      !store.botConfig.preferredColor ||
      !store.botConfig.name
    ) {
      store?.onSetCreating(false);
      return;
    }

    const repositoryName = store.botConfig.name
      .replace(/[^a-zA-Z0-9]/g, "-")
      .toLowerCase();

    const template = await loadTemplate(
      store.gameConfig,
      store.botConfig.language ?? null
    ).catch((error) => {
      setValidationMessage(
        error instanceof Error
          ? error.message
          : "Unexpected problem encountered."
      );
      setValidationMessageOpen(true);
      console.error(error);
      return "error" as const;
    });

    if (template === "error") {
      store.onSetCreating(false);
      return;
    }

    const result = await api.bot.createBot
      .mutate({
        config: {
          avatar: store.botConfig.avatar,
          game: store.botConfig.game,
          language: store.botConfig.language ?? null,
          name: normalizedName,
          preferredColor: store.botConfig.preferredColor,
          v: 1,
          run: template.run,
          build: template.build,
        },
        gameName: store.gameConfig.name,
        files: template.files,
        repositoryName,
      })
      .catch((error) => {
        setValidationMessage(
          error instanceof Error
            ? error.message
            : "Unexpected problem encountered."
        );
        setValidationMessageOpen(true);
        return "error" as const;
      });

    if (result === "error") {
      store.onSetCreating(false);
      return;
    }

    await sleep(1_000);

    await botConfigListQuery.refetch();

    const timeElapsed = Date.now() - start;

    // API for fetching bots doesn't always return new ones immediately after
    // one is created so a small delay here mitigates that issue.
    await sleep(Math.max(0, 7_000 - timeElapsed) + 3_000);

    store.onSetCreating(false);

    setCreated(true);
    store.onCreate(
      {
        transitionData: {
          slotId: crypto.randomUUID(),
          name: normalizedName,
          avatar: store.botConfig.avatar,
          owner,
          language: store.botConfig.language,
        },
        type: BotType.User,
        color: store.botConfig.preferredColor,
        owner,
        repo: repositoryName,
      },
      store.botConfig.language ?? "other"
    );
  }
}

const otherLanguageTemplate = `
\`\`\`sh config=run
./run.sh
\`\`\`

\`\`\`sh config=build
./build.sh
\`\`\`

\`\`\`js file=/run.sh mode=755
# This command should start your bot. Communication between your bot and Zilch happens over stdout/stdin.
# Docs still need to be written on the exact interface and how to support it. In the meantime checkout
# the source code for other bots to see how this is done.
echo "Enter your run command here"
\`\`\`

\`\`\`js file=/build.sh mode=755
# This command should compile your code. Languages that don't need to be compiled can skip this step.
echo "Enter your build command here"
\`\`\`
`;

async function loadTemplate(
  gameConfig: GameConfig,
  language: string | null
): Promise<{
  run: string;
  build?: string;
  files: {
    [path: string]: { mode: string; contents: string; hidden: boolean };
  };
}> {
  let contents = otherLanguageTemplate;

  if (language !== null) {
    const response = await fetch(
      gameConfig.url + "/templates/" + language + ".md"
    );

    if (!response.ok) {
      throw response;
    }

    contents = "\n" + (await response.text());
  }

  const codeBlocks = contents
    .split("\n```")
    .filter((_, index) => index % 2 === 1)
    .map((code) => {
      const newLineIndex = code.indexOf("\n");
      const infoString = newLineIndex > 0 ? code.slice(0, newLineIndex) : "";
      const infoStringParts = infoString.split(/\s+/g);
      const file =
        infoStringParts
          .find((part) => part.startsWith("file="))
          ?.slice("file=".length) ?? null;
      const mode =
        infoStringParts
          .find((part) => part.startsWith("mode="))
          ?.slice("mode=".length) ?? null;
      const config =
        infoStringParts
          .find((part) => part.startsWith("config="))
          ?.slice("config=".length) ?? null;
      const hidden = infoStringParts.includes("hidden=true");

      return {
        code: code.slice(newLineIndex).trim().replaceAll("\\`", "`") + "\n",
        file,
        mode,
        config,
        hidden,
      };
    });

  const files: {
    [path: string]: { mode: string; contents: string; hidden: boolean };
  } = {};

  let run: string | undefined = undefined;
  let build: string | undefined = undefined;

  for (const block of codeBlocks) {
    if (block.config) {
      if (block.config === "run") {
        if (run !== undefined) {
          throw new Error("Template Error: More than one run config provided");
        }
        run = block.code.trim();
      }

      if (block.config === "build") {
        if (build !== undefined) {
          throw new Error(
            "Template Error: More than one build config provided"
          );
        }
        build = block.code.trim();
      }

      continue;
    }
    if (!block.file) {
      continue;
    }

    files[block.file] = {
      contents: block.code,
      mode: block.mode ?? "644",
      hidden: block.hidden,
    };
  }

  if (!run) {
    throw new Error("Template Error: No run config provided");
  }

  return { run, build, files };
}
