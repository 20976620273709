import { panic } from "@zilch/panic";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useStorage } from "../useStorage";

interface NewBotStore {
  setBotNewValue(owner: string, repo: string, isNew: boolean): void;
  isBotNew(owner: string, repo: string): boolean;
}

const context = createContext<NewBotStore | null>(null);

export const NewBotStore = {
  Provide: ProvideNewBotStore,
  use: useNewBotStoreFromContext,
};

function useNewBotStoreFromContext() {
  return useContext(context) ?? panic("NewBotStore context should be provided");
}

function ProvideNewBotStore({ children }: { children: React.ReactNode }) {
  const newBotStore = useNewBotStore();
  return <context.Provider value={newBotStore}>{children}</context.Provider>;
}

function useNewBotStore(): NewBotStore {
  const storage = useStorage();
  const storageRef = useRef(storage);
  storageRef.current = storage;

  const [newBots, setNewBots] = useState(() => storage.newBots.get());

  useEffect(() => {
    setNewBots(storage.newBots.get());
  }, [storage]);

  useEffect(() => {
    storageRef.current.newBots.set(newBots);
  }, [newBots]);

  return {
    isBotNew(owner, repo) {
      return newBots.has(`${owner}.${repo}`);
    },
    setBotNewValue(owner, repo, isNew) {
      setNewBots((current) => {
        const newValue = new Set(current);
        const key = `${owner}.${repo}`;
        if (isNew) {
          if (current.has(key)) {
            return current;
          }
          newValue.add(key);
        } else {
          if (!current.has(key)) {
            return current;
          }
          newValue.delete(key);
        }
        return newValue;
      });
    },
  };
}
