import { useRef } from "react";
import { UserStore } from "../../stores/UserStore";
import { Footer } from "./Footer";
import { HomeScreenContainer } from "./HomeScreenContainer";
import { GameList } from "./GameList";
import { FullLogo } from "../common/FullLogo";
import { ForOrganizations } from "./ForOrganizations";
import { useWindowSizeDerivedValue } from "@zilch/window-size";
import css from "./HomeScreen.module.css";
import { Button } from "@blueprintjs/core";
import { PromptStore } from "../../stores/PromptStore";
import { useDelay } from "@zilch/delay";

// TODO some sort of attribution for the 3d assets used??? and open source stuff could
// be nice to mention I suppose.
export function HomeScreen() {
  const userStore = UserStore.use();
  const isAuthenticated =
    userStore.query.isSuccess && userStore.query.data.type === "authenticated";
  const isAuthenticatedRef = useRef(isAuthenticated);
  isAuthenticatedRef.current = isAuthenticated;

  const small = useWindowSizeDerivedValue((width) => width < 1140);

  const prompt = PromptStore.usePrompt();

  const pickAGameMessage = (
    <div
      style={{
        marginBottom: small ? "60px" : undefined,
      }}
      className={css.pickGameMessage}
    >
      <div>
        Pick a game.{small ? <br /> : <>&nbsp; </>}Level up your
        coding&nbsp;skills.
      </div>
      <Button
        intent="primary"
        large
        rightIcon="play"
        style={{
          fontSize: "20px",
          borderRadius: "10px",
          width: "300px",
          marginTop: "30px",
          maxWidth: "calc(100% - 20px)",
          height: "60px",
        }}
        onClick={() => {
          prompt<null>(
            (props) => <IntroVideo onClose={() => props.resolve(null)} />,
            {
              width: "calc(100vw - 60px)",
              borderRadius: 10,
            }
          );
        }}
      >
        Watch: <b>What is Zilch?</b>
      </Button>
    </div>
  );

  return (
    <HomeScreenContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          minHeight: "800px",
          height: small ? undefined : "100vh",
          marginTop: small ? "40px" : undefined,
          marginBottom: small ? "100px" : undefined,
        }}
      >
        {small && pickAGameMessage}
        {!small && (
          <div className={css.logo}>
            <FullLogo
              size={102}
              backgroundColor="var(--dark-gray1)"
              style={{
                margin: "40px",
                marginTop: "0px",
              }}
            />
          </div>
        )}
        <GameList />
        {!small && pickAGameMessage}
      </div>
      {!small && <ForOrganizations />}
      <Footer />
    </HomeScreenContainer>
  );
}

function IntroVideo({ onClose }: { onClose(): void }) {
  const show = useDelay(500);

  return (
    <>
      <Button
        icon="cross"
        large
        onClick={onClose}
        style={{
          borderRadius: "100%",
          position: "fixed",
          top: "15px",
          right: "15px",
          opacity: show ? 1 : 0.001,
          transition: "all ease .5s",
        }}
      />
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 60px)",
          overflow: "hidden",
          borderRadius: "10px",
          opacity: show ? 1 : 0.001,
          transition: "all ease .5s",
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube-nocookie.com/embed/uAsWdtWx9aE?si=tefLieJN6Z3vaLKI&rel=0&autoplay=1&color=white"
          title="What is Zilch?"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
    </>
  );
}
