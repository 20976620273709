import { AnchorButton, Button, Card, Spinner, Tag } from "@blueprintjs/core";
import { useQuery } from "@tanstack/react-query";
import { TRPCClientError } from "@trpc/client";
import { classes, transitionInFromCss } from "@zilch/css-utils";
import { useState } from "react";
import type { API } from "../../../backend/routes/trpc";
import { api } from "../../api";
import { routes } from "../../router";
import { NonIdealState } from "../common/NonIdealState";
import css from "./OrganizationList.module.css";
import { format } from "date-fns";
import { OrganizationDialog } from "./OrganizationDialog";
import { transitionScreen } from "../../transitionScreen";
import { preventDefaultLinkClickBehavior } from "type-route";

export type OrgId = { type: "existing"; id: string } | { type: "new" } | null;

export function OrganizationList() {
  const [orgId, setOrgId] = useState<OrgId>(null);

  const organizationQuery = useQuery(
    ["admin.getOrganizationList"],
    async () => {
      return await api.admin.getOrganizationList
        .query()
        .catch((error: TRPCClientError<API>) => {
          if (
            error instanceof TRPCClientError &&
            error.data?.code === "FORBIDDEN"
          ) {
            return "forbidden" as const;
          }

          throw error;
        });
    }
  );

  if (organizationQuery.isInitialLoading) {
    return <NonIdealState key="loading" icon={<Spinner />} />;
  }

  const goToGameListAction = (
    <AnchorButton
      href={routes.home().href}
      onClick={(e) => {
        if (preventDefaultLinkClickBehavior(e)) {
          transitionScreen(routes.home());
        }
      }}
    >
      Go to Game List
    </AnchorButton>
  );

  if (organizationQuery.data === "forbidden") {
    return (
      <NonIdealState
        key="forbidden"
        icon="lock"
        title="Forbidden"
        description="Insufficient permissions."
        action={goToGameListAction}
      />
    );
  }

  if (organizationQuery.isError) {
    return (
      <NonIdealState
        key="error"
        icon="error"
        title="Whoops"
        description="Unexpected error encountered."
        action={goToGameListAction}
      />
    );
  }

  return (
    <>
      <Card
        style={{ padding: 0 }}
        className={classes(transitionInFromCss.bottom)}
      >
        <table
          className="bp4-html-table bp4-html-table-striped bp4-interactive"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>
                <div
                  className={css.headerCellContent}
                  style={{ paddingLeft: "8px" }}
                >
                  Name
                </div>
              </th>
              <th>
                <div className={css.headerCellContent}>Expiration</div>
              </th>
              <th>
                <div className={css.headerCellContent}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <div>Domains</div>
                    <Button
                      onClick={() => setOrgId({ type: "new" })}
                      intent="primary"
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {organizationQuery.data?.length === 0 && (
              <tr>
                <td colSpan={4}>
                  <NonIdealState icon="search" title="No results" />
                </td>
              </tr>
            )}
            {organizationQuery.data?.map((organization) => {
              return (
                <tr
                  key={organization.organizationId}
                  tabIndex={0}
                  onClick={() => {
                    setOrgId({
                      type: "existing",
                      id: organization.organizationId,
                    });
                  }}
                >
                  <td>
                    <a style={{ marginLeft: "10px" }}>{organization.name}</a>
                  </td>
                  <td>
                    <Tag minimal>
                      <span
                        className={classes(
                          !organization.planExpiration && "bp4-text-muted"
                        )}
                      >
                        {organization.planExpiration
                          ? format(
                              organization.planExpiration,
                              "MM / dd / yyyy"
                            )
                          : "-"}
                      </span>
                    </Tag>
                  </td>
                  <td>
                    <div
                      style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                    >
                      {organization.domains.length === 0 && (
                        <Tag minimal>
                          <span className="bp4-text-muted">-</span>
                        </Tag>
                      )}
                      {organization.domains.map((domain) => (
                        <Tag minimal key={domain}>
                          {domain}
                        </Tag>
                      ))}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
      <OrganizationDialog
        orgId={orgId}
        onClose={() => {
          organizationQuery.refetch();
          setOrgId(null);
        }}
      />
    </>
  );
}
