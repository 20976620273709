import { Icon, Tag } from "@blueprintjs/core";
import { classes } from "@zilch/css-utils";
import { PremiumStore } from "../../stores/PremiumStore";
import css from "./RecommendationGroup.module.css";

export function RecommendationGroup() {
  const premiumStore = PremiumStore.use();
  const isCalculatePrice = premiumStore.section === "calculate-price";
  return (
    <div
      className={classes(
        css.container,
        !isCalculatePrice && css.containerHidden
      )}
      style={{
        transition: isCalculatePrice
          ? "all cubic-bezier(.4,1.5,.4,1) .4s .3s"
          : "all ease .3s 0s",
      }}
    >
      <div className={css.label}>Need help deciding?</div>
      <div className={css.recommendationsContainer}>
        <Recommendation
          onSetSeats={premiumStore.setSeats}
          notTabbable={!isCalculatePrice}
          position="left"
          label="You + friend"
          amount={2}
        />
        <Recommendation
          onSetSeats={premiumStore.setSeats}
          notTabbable={!isCalculatePrice}
          position="middle"
          label="Team"
          secondaryLabel="Popular"
          amount={6}
        />
        <Recommendation
          onSetSeats={premiumStore.setSeats}
          position="right"
          notTabbable={!isCalculatePrice}
          label="Classroom"
          amount={30}
        />
      </div>
      <a
        className={css.entireOrganizationLink}
        tabIndex={isCalculatePrice ? 0 : -1}
        href="/premium/organization"
        target="_blank"
        rel="noreferrer"
      >
        <span className={css.entireOrganizationLabel}>
          Entire School or Business
        </span>
        <span className={css.entireOrganizationSecondaryLabel}>
          <span>Learn more</span>
          <Icon icon="chevron-right" />
        </span>
      </a>
    </div>
  );
}

function Recommendation(props: {
  label: string;
  secondaryLabel?: string;
  amount: number;
  position: "left" | "middle" | "right";
  notTabbable: boolean;
  onSetSeats(seats: number): void;
}) {
  const { seats } = PremiumStore.use();
  return (
    <div
      className={classes(
        css.recommendation,
        seats === props.amount && css.recommendationActive
      )}
      style={{
        marginRight: props.position === "left" ? -1 : 0,
        marginLeft: props.position === "right" ? -1 : 0,
        borderTopRightRadius: props.position === "right" ? 5 : 0,
        borderTopLeftRadius: props.position === "left" ? 5 : 0,
      }}
      tabIndex={props.notTabbable ? -1 : 0}
      onClick={() => props.onSetSeats(props.amount)}
    >
      <div
        style={{
          position: "relative",
        }}
      >
        <div>{props.label}</div>
        {props.secondaryLabel && (
          <Tag
            minimal
            intent="success"
            style={{ position: "absolute", right: "-3px", top: "-1px" }}
          >
            {props.secondaryLabel}
          </Tag>
        )}
      </div>
      <div className={css.recommendationSeats}>{props.amount} seats</div>
    </div>
  );
}
