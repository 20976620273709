.inputContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  width: 225px;
}

.button {
  border-radius: 100% !important;
  position: absolute;
  z-index: 1;
  transition: all ease .2s;
}

.prompt {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.promptSubLabel {
  font-size: 14px;
  color: var(--gray4);
}

.container {
  display: grid;
  grid-template-columns: 1fr 225px;
  gap: 30px;
  align-items: center;
  margin-bottom: 16px;
}

.seeRecommendations {
  position: absolute;
  transition: all ease .2s;
  margin-top: 2px;
}

.input input {
  height: 80px !important;
  line-height: 80px !important;
  width: 225px !important;
  padding-left: 75px !important;
  padding-right: 75px !important;
  text-align: center;
  font-size: 38px !important;
  border-radius: 40px;
  font-weight: 600;
}