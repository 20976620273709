.container {
  position: absolute;
  bottom: 21px;
  right: 20px;
  transition: transform cubic-bezier(.5,1.4,.5,1) .5s;
  display: flex;
  transform: translateY(0px);
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.hidden {
  transform: translateY(140px);
  pointer-events: none;
  transition: transform ease .2s;
}

.bottomButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 37px;
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px rgba(95, 107, 124, 0.4);
  transition: all ease .3s;
  background: rgba(47, 52, 60, 0.8);
  animation-delay: 350ms;
}