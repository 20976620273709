import { Colors } from "@blueprintjs/core";
import { useWindowSizeDerivedValue } from "@zilch/window-size";
import { GiRoundStar } from "react-icons/gi";
import ticTacToeSrc from "../../resources/icons/tic-tac-toe.png";
import tableTennisSrc from "../../resources/icons/table-tennis.png";
import chessSrc from "../../resources/icons/chess.png";
import flagSrc from "../../resources/icons/flag.png";
import { StarStore } from "../../stores/StarStore";
import css from "./StoreGames.module.css";
import { routes } from "../../router";
import { transitionScreen } from "../../transitionScreen";
import type { InternalGameId } from "@zilch/game-config";

interface ProgressItemProps {
  imgSrc: string;
  imgSize: number;
  gameId: InternalGameId | false;
}

export function StoreGames() {
  const games: ProgressItemProps[] = [
    {
      imgSize: 95,
      imgSrc: tableTennisSrc,
      gameId: "table-tennis",
    },
    {
      imgSize: 130,
      imgSrc: ticTacToeSrc,
      gameId: "tic-tac-toe",
    },
    {
      imgSize: 97,
      imgSrc: chessSrc,
      gameId: "chess",
    },
    {
      imgSize: 114,
      imgSrc: flagSrc,
      gameId: false,
    },
  ];

  const gridTemplateColumns = useWindowSizeDerivedValue((width) => {
    const maxColumns = Math.max(1, Math.floor(width / 280));
    const rows = Math.ceil(games.length / maxColumns);

    let columns = maxColumns;

    while (columns > 2 && Math.ceil(games.length / (columns - 1)) === rows) {
      columns--;
    }

    return `repeat(${columns}, 1fr)`;
  });

  return (
    <div className={css.outerContainer}>
      <div className={css.container} style={{ gridTemplateColumns }}>
        {games.map((value, index) => {
          return <ProgressItem key={index} {...value} />;
        })}
      </div>
    </div>
  );
}

function ProgressItem(props: ProgressItemProps) {
  const starStore = StarStore.use();
  const stars = props.gameId ? starStore.getStarCount(props.gameId) : false;
  return (
    <div className={css.game}>
      <div className={css.stars}>
        {stars === false ? (
          <span
            style={{
              fontWeight: 600,
              color: Colors.GRAY2,
            }}
          >
            Coming Soon
          </span>
        ) : (
          <>
            <GiRoundStar
              size={15}
              color={stars > 0 ? Colors.GOLD4 : Colors.GRAY1}
            />
            <GiRoundStar
              size={15}
              color={stars > 1 ? Colors.GOLD4 : Colors.GRAY1}
            />
            <GiRoundStar
              size={15}
              color={stars > 2 ? Colors.GOLD4 : Colors.GRAY1}
            />
          </>
        )}
      </div>
      <div
        className={css.gameIcon}
        tabIndex={0}
        onClick={() => {
          if (props.gameId) {
            transitionScreen(routes.internalGame({ gameId: props.gameId }));
          }
        }}
      >
        <img
          src={props.imgSrc}
          style={{
            position: "absolute",
            width: props.imgSize + "px",
            height: props.imgSize + "px",
            filter: `${
              props.gameId ? "" : "grayscale(100%)"
            } drop-shadow(0px 10px 10px rgba(0,0,0,.4))`,
          }}
        />
      </div>
    </div>
  );
}
