import { AnchorButton, Button, Code, Colors, Spinner } from "@blueprintjs/core";
import { NonIdealState } from "../common/NonIdealState";
import { Divider } from "./Divider";
import { BotList } from "./BotList";
import type { Route } from "type-route";
import type { groups } from "../../router";
import { routes } from "../../router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  BotType,
  type SlotSelection,
  type SlotSelectionTransitionData,
  type TransitionSlotSelection,
} from "@zilch/bot-models";
import { panic } from "@zilch/panic";
import { useDelayedValue } from "@zilch/delay";
import { GameSetup } from "./GameSetup";
import type { PrimaryOutcomeType, GameSpeed } from "./GameEngine";
import { GameEngine, type GameOutcome } from "./GameEngine";
import { BotTerminals } from "./BotTerminals";
import { GameControls } from "./GameControls";
import { GameOutcomeDisplay } from "./GameOutcomeDisplay";
import gameMakerPng from "../../resources/icons/game-maker.png";
import stringify from "fast-json-stable-stringify";
import { GuideContent } from "./GuideContent";
import { StarStore } from "../../stores/StarStore";
import type { GameConfig, GameId } from "@zilch/game-config";
import { fireConfetti } from "@zilch/confetti";
import { PromptStore } from "../../stores/PromptStore";
import { times } from "lodash";
import { classes, delayCss, transitionInFromCss } from "@zilch/css-utils";
import { SpeedIndicator } from "./SpeedIndicator";
import css from "./GameScreen.module.css";
import { ReplayControls } from "./ReplayControls";
import { UserStore } from "../../stores/UserStore";
import { NotStartedBots } from "./NotStartedBots";
import { loadImg } from "@zilch/load-img";
import { pickZilchGameFile } from "../common/useGameSelector";
import { posthog } from "posthog-js";
import { getGameIdForAnalytics } from "../../analytics";
import { GameLessons } from "./GameLessons";
import { usePlaybook } from "../playbook/Playbook";
import { usePrevious } from "@zilch/use-previous";
import { useShowGameLockedMessage } from "./useShowGameLockedMessage";

interface Props {
  route: Route<typeof groups.game>;
  onChangeBotTerminalsWidth(width: number): void;
  onSetGameConfig(gameConfig: GameConfig | null): void;
}

export function GameScreen(props: Props) {
  return (
    <GameEngine.Provide>
      <GameScreenWithGameEngine {...props} />
    </GameEngine.Provide>
  );
}

function useGameOutcomePlaybookSync(outcome: GameOutcome) {
  const playbook = usePlaybook();
  const playbookRef = useRef(playbook);
  playbookRef.current = playbook;

  const previousOutcome = usePrevious(outcome);

  const shouldClearPlaybookTip =
    previousOutcome?.status === "done" &&
    outcome.status !== "done" &&
    playbook.section.tip?.type === "building-your-first-bot" &&
    playbook.section.name === "none";

  const shouldClearStartGameEmphasis =
    previousOutcome?.status === "done" &&
    outcome.status !== "done" &&
    playbook.emphasizeStartGame &&
    playbook.section.name === "none";

  useEffect(() => {
    if (shouldClearPlaybookTip) {
      playbookRef.current.setSection({ name: "none" });
    }
  }, [shouldClearPlaybookTip]);

  useEffect(() => {
    if (shouldClearStartGameEmphasis) {
      playbookRef.current.setEmphasizeStartGame(false);
    }
  }, [shouldClearStartGameEmphasis]);

  useEffect(() => {
    return () => {
      playbookRef.current.setEmphasizeStartGame(false);
      playbookRef.current.setSection({ name: "none" });
    };
  }, []);
}

function GameScreenWithGameEngine({
  route,
  onChangeBotTerminalsWidth,
  onSetGameConfig,
}: Props) {
  const engine = GameEngine.use();

  const analyticsGameId = getGameIdForAnalytics(route);
  useEffect(() => {
    posthog.capture("game_opened", {
      gameId: analyticsGameId,
    });
  }, [analyticsGameId]);

  const gameConfig =
    engine.status === "created" ? engine.instance.gameConfig : null;

  const onSetGameConfigRef = useRef(onSetGameConfig);
  onSetGameConfigRef.current = onSetGameConfig;
  useEffect(() => {
    onSetGameConfigRef.current(gameConfig);
  }, [gameConfig]);

  useEffect(() => {
    return () => onSetGameConfigRef.current(null);
  }, []);

  const userStore = UserStore.use();

  const [slotMessage, setSlotMessage] = useState<{
    message: React.ReactNode;
    index: number;
  } | null>(null);

  const [outcome, setOutcome] = useState<GameOutcome>({
    status: "not-started",
  });
  const { slotSelections, setSlotSelections } = useSlotSelections(route);
  const [times, setTimes] = useState<number[]>([]);
  const [activeBots, setActiveBots] = useState(new Set<number>());
  const [notStartedBots, setNotStartedBots] = useState(new Set<number>());
  const isDevMode = !!route.params.dev;
  const [sandbox, setSandbox] = useState(isDevMode);

  useEffect(() => {
    if (isDevMode) {
      setSandbox(true);
    }
  }, [isDevMode]);

  const gameSpeed: GameSpeed = route.params.speed;

  useGameOutcomePlaybookSync(outcome);
  useSystemErrorPrompt(outcome);

  const routeRef = useRef(route);
  routeRef.current = route;
  const setGameSpeed = useCallback((speed: GameSpeed) => {
    const bots = routeRef.current.params.bots;
    if (routeRef.current.name === routes.internalGame.name) {
      routes
        .internalGame({
          ...routeRef.current.params,
          bots,
          speed,
        })
        .replace();
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (routeRef.current.name === routes.externalGame.name) {
      routes
        .externalGame({
          ...routeRef.current.params,
          bots,
          speed,
        })
        .replace();
    } else {
      panic("Unexpected route", routeRef.current);
    }
  }, []);

  useEffect(() => {
    setOutcome((outcome) => {
      if (outcome.status === "not-started") {
        return outcome;
      } else {
        return { status: "not-started" };
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringify(slotSelections), route.params.config]);

  const shouldShowGameSetup =
    outcome.status !== "in-progress" &&
    (outcome.status !== "done" || outcome.replayProgress === null);
  const showGameSetup =
    useDelayedValue(shouldShowGameSetup, { delay: 300 }) || shouldShowGameSetup;

  const showGameControls =
    useDelayedValue(outcome.status === "in-progress", { delay: 200 }) ||
    outcome.status === "in-progress";

  const shouldShowReplayControls =
    outcome.status === "done" && outcome.replayProgress !== null;
  const showReplayControls =
    useDelayedValue(shouldShowReplayControls, { delay: 200 }) ||
    shouldShowReplayControls;

  useEffect(() => {
    engine.instance?.setGameSpeed(gameSpeed);
  }, [engine.instance, gameSpeed]);

  const botTerminalSlotSelections = useRef(slotSelections);
  if (outcome.status === "in-progress") {
    botTerminalSlotSelections.current = slotSelections;
  }

  const outcomeStatus = outcome.status;

  useUpdateStarCount(
    engine.instance?.gameConfig.gameId ?? null,
    outcome,
    slotSelections
  );

  useGameOutcomeAnalyticsLogger(
    engine.instance?.gameConfig.gameId ?? null,
    outcome,
    slotSelections
  );

  useEffect(() => {
    if (outcomeStatus === "in-progress" || outcomeStatus === "not-started") {
      engine.instance?.resetGameState();
    }
  }, [outcomeStatus, engine.instance]);

  useEffect(() => {
    engine.instance?.setStatus(outcome.status);
  }, [engine.instance, outcome.status]);

  const replayProgress =
    outcome.status === "done" ? outcome.replayProgress : null;
  useEffect(() => {
    engine.instance?.setReplayProgress(replayProgress);
  }, [engine.instance, replayProgress]);

  useEffect(() => {
    engine.instance?.setSlotSelections(route.params.bots);
  }, [engine.instance, route.params.bots]);

  const reloadPageButton = (
    <Button
      intent="primary"
      onClick={() => {
        let newRoute;

        if (route.name === routes.internalGame.name) {
          newRoute = routes.internalGame({
            gameId: route.params.gameId,
          });
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (route.name === routes.externalGame.name) {
          newRoute = routes.externalGame({
            owner: route.params.owner,
            repo: route.params.repo,
          });
        } else {
          panic("Unexpected route");
        }

        if (newRoute.href === window.location.href) {
          window.location.reload();
        } else {
          window.location.href = newRoute.href;
        }
      }}
    >
      Reload Page
    </Button>
  );

  const showBossLockedMessage = useShowGameLockedMessage();

  return (
    <Divider
      onChangeRightSideWidth={onChangeBotTerminalsWidth}
      shouldOpenIfClosed={
        outcome.status === "done" &&
        (outcome.primary?.type === "bug-detected" ||
          outcome.primary?.type === "time-limit-exceeded" ||
          outcome.primary?.type === "victory-over-boss-but-incompatible-config")
      }
      disabled={outcome.status === "not-started"}
      left={
        <>
          <SpeedIndicator
            gameSpeed={gameSpeed}
            outcome={outcome}
            hasNotStartedBots={notStartedBots.size > 0}
          />
          {engine.status === "created" && (
            <NotStartedBots
              gameId={engine.instance.gameConfig.gameId}
              notStartedBots={notStartedBots}
              slotSelections={slotSelections}
            />
          )}
          <GameOutcomeDisplay outcome={outcome} />
          <GameLessons outcome={outcome} engine={engine} />
          <GameLoadingDisplay engine={engine} />
          {engine.status === "error" &&
            engine.error !== "not-found" &&
            (route.params.dev ? (
              <NonIdealState
                icon="application"
                title="Welcome to Game Maker"
                description={
                  <div style={{ maxWidth: "400px" }}>
                    Clone{" "}
                    <Code>
                      {route.name === routes.externalGame.name
                        ? `https://github.com/${route.params.owner}/${route.params.repo}.git`
                        : `https://github.com/zilch/${route.params.gameId}.git`}
                    </Code>{" "}
                    locally then execute <Code>npm run build</Code> and upload
                    the resulting <Code>.zilchgame</Code> file to start
                    developing your game.
                    <br />
                    <br />
                    <div
                      style={{
                        padding: "50px 0px",
                        border: `1px dashed ${Colors.GRAY1}`,
                        borderRadius: "2px",
                      }}
                    >
                      <Button
                        intent="primary"
                        minimal
                        onClick={async () => {
                          const result = await pickZilchGameFile();
                          if (result) {
                            if (
                              route.name !== routes.externalGame.name ||
                              result.owner !== route.params.owner ||
                              result.repo !== route.params.repo ||
                              route.params.dev !== true
                            ) {
                              routes
                                .externalGame({
                                  owner: result.owner,
                                  repo: result.repo,
                                  dev: true,
                                })
                                .push();
                            } else {
                              routes
                                .externalGame({
                                  owner: result.owner,
                                  repo: result.repo,
                                  refreshKey:
                                    (route.params.refreshKey ?? 0) + 1,
                                  dev: true,
                                })
                                .replace();
                            }
                          }
                        }}
                      >
                        <span style={{ fontWeight: 600 }}>
                          Upload .zilchgame file
                        </span>
                      </Button>
                      <br />
                      or drag and drop
                    </div>
                  </div>
                }
                action={
                  <Button
                    minimal
                    className={classes(
                      transitionInFromCss.bottom,
                      delayCss[100]
                    )}
                    onClick={() => {
                      if (route.name === routes.internalGame.name) {
                        routes
                          .internalGame({ gameId: route.params.gameId })
                          .replace();
                      } else {
                        routes
                          .externalGame({
                            owner: route.params.owner,
                            repo: route.params.repo,
                          })

                          .replace();
                      }
                    }}
                  >
                    <span className="bp4-text-muted">Exit game maker</span>
                  </Button>
                }
              />
            ) : (
              <NonIdealState
                icon="error"
                title="Oops"
                description={
                  <div style={{ maxWidth: "240px" }}>
                    Unexpected problem encountered loading the game.
                  </div>
                }
                action={reloadPageButton}
              />
            ))}
          {engine.status === "error" && engine.error === "not-found" && (
            <NonIdealState
              icon="search"
              title="Not found"
              description={
                <div style={{ maxWidth: "280px" }}>
                  We weren't able to find{" "}
                  {route.params.release ? (
                    <>
                      release <Code>{route.params.release}</Code>
                    </>
                  ) : (
                    "any releases"
                  )}{" "}
                  for{" "}
                  <Code>
                    github.com/
                    {route.name === routes.internalGame.name
                      ? "zilch/" + route.params.gameId
                      : route.params.owner + "/" + route.params.repo}
                  </Code>
                  <div style={{ marginTop: "10px" }}>
                    Is this a new game you're developing?
                  </div>
                </div>
              }
              action={
                route.params.release ? (
                  <Button
                    className={classes(
                      transitionInFromCss.bottom,
                      delayCss[100]
                    )}
                    intent="primary"
                    onClick={() => {
                      if (route.name === routes.internalGame.name) {
                        routes
                          .internalGame({ gameId: route.params.gameId })
                          .replace();
                      } else {
                        routes
                          .externalGame({
                            owner: route.params.owner,
                            repo: route.params.repo,
                          })

                          .replace();
                      }
                    }}
                  >
                    Switch to Latest Version
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      if (route.name === routes.internalGame.name) {
                        routes
                          .internalGame({
                            gameId: route.params.gameId,
                            dev: true,
                          })
                          .replace();
                      } else {
                        routes
                          .externalGame({
                            owner: route.params.owner,
                            repo: route.params.repo,
                            dev: true,
                          })

                          .replace();
                      }
                    }}
                  >
                    Launch Game Maker
                  </Button>
                )
              }
            />
          )}
          {engine.renderIFrame({
            mode: "standard",
            gameSpeed,
            onSetGameSpeed: setGameSpeed,
            onSetOutcome: setOutcome,
            onSetTimes: setTimes,
            onSetActiveBots: setActiveBots,
            onSetNotStartedBots: setNotStartedBots,
            sandbox,
          })}
          {engine.status === "created" && (
            <BotList
              outcome={outcome}
              showMultiplayerGuide={route.params.showMultiplayerGuide ?? false}
              onCloseMultiplayerGuide={() => {
                if (route.name === routes.internalGame.name) {
                  routes
                    .internalGame({ gameId: route.params.gameId })
                    .replace();
                } else {
                  routes
                    .externalGame({
                      owner: route.params.owner,
                      repo: route.params.repo,
                    })
                    .replace();
                }
              }}
              gameEngineInstance={engine.instance}
              slotSelections={slotSelections}
              slotMessage={slotMessage}
              onCloseSlotMessage={() => {
                setSlotMessage(null);
              }}
              onSetSlotSelections={(fn) => {
                setSlotSelections((current) => {
                  const { slotSelections, prompt } = fn(current);
                  if (prompt) {
                    setTimeout(() => {
                      setSlotMessage({
                        message: prompt.message,
                        index: prompt.slotIndex,
                      });
                    }, 200);
                  }
                  return slotSelections;
                });
              }}
              activeBots={activeBots}
            />
          )}
          {engine.status === "created" && showGameSetup && (
            <GameSetup
              sandbox={sandbox}
              outcome={outcome}
              onSetOutcome={setOutcome}
              gameEngineInstance={engine.instance}
              onStartGame={() => {
                setSlotSelections((slotSelections) => {
                  const newSlotSelections = slotSelections.filter(
                    (selection) => !!selection
                  ) as NonNullable<TransitionSlotSelection>[];

                  // TODO make sure this isn't flakey - need this b/c
                  // the change in slot selections can land after the game
                  // has started and if it does the game is cancelled due to
                  // the modification.
                  setTimeout(() => {
                    engine.instance.playGame(newSlotSelections);
                  });
                  return newSlotSelections;
                });
              }}
              speed={gameSpeed}
              onSetSpeed={setGameSpeed}
              onShowNotLoadedMessage={(index) => {
                setSlotMessage({
                  index,
                  message: (
                    <GuideContent
                      title="Unable to load"
                      message="This bot may not exist. If you know it does try refreshing the page."
                    />
                  ),
                });
              }}
              onShowCustomGameLockedMessage={() => {
                showBossLockedMessage({ type: "custom-game" });
              }}
              onShowBossLockedMessage={(gameId) => {
                showBossLockedMessage({ type: "boss", gameId });
              }}
              onShowSignInMessage={(index) => {
                setSlotMessage({
                  index,
                  message: userStore.query.isLoading ? (
                    <GuideContent
                      title="Just a moment."
                      message="We're working on loading some stuff. Try again in a few seconds."
                    />
                  ) : (
                    <GuideContent
                      title="Not signed in"
                      message="Sign in to play this bot."
                      action={{
                        text: "Sign in",
                        onClick() {
                          userStore.signIn("titleBar");
                        },
                      }}
                    />
                  ),
                });
              }}
              onShowMultiplayerLockedMessage={(index) => {
                setSlotMessage({
                  index,
                  message: (
                    <GuideContent
                      title="Multiplayer locked"
                      message="Get Premium to play against bots created by others."
                    />
                  ),
                });
              }}
              onShowNotEnoughBotsMessage={() => {
                let index = slotSelections.findIndex((selection) => !selection);
                if (index === -1) {
                  index = slotSelections.length;
                }
                setSlotMessage({
                  index,
                  message: (
                    <GuideContent
                      title="Want to start the game?"
                      message="First select a bot for this player slot."
                    />
                  ),
                });
              }}
              slotSelections={slotSelections}
            />
          )}
          {engine.status === "created" && showGameControls && (
            <GameControls
              slotSelections={slotSelections}
              outcome={outcome}
              engine={engine.instance}
              speed={gameSpeed}
              onSetSpeed={setGameSpeed}
            />
          )}
          {engine.status === "created" && showReplayControls && (
            <ReplayControls
              outcome={outcome}
              moveDelay={engine.instance.gameConfig.moveDelay}
              onSetReplayProgress={(replayProgress) => {
                setOutcome((outcome) => {
                  if (outcome.status !== "done") {
                    return outcome;
                  } else {
                    return {
                      ...outcome,
                      replayProgress,
                    };
                  }
                });
              }}
            />
          )}
        </>
      }
      right={
        engine.status === "created" ? (
          <BotTerminals
            times={times}
            gameEngineInstance={engine.instance}
            gameOutcome={outcome}
            slotSelections={botTerminalSlotSelections.current}
          />
        ) : null
      }
    />
  );
}

function GameLoadingDisplay({ engine }: { engine: GameEngine }) {
  // TODO Show fallback icon for custom games. Looking at the code this should work? But isn't?

  const showGameLoading =
    useDelayedValue(engine.status === "creating", {
      delay: engine.status === "creating" ? 0 : 2000,
    }) || engine.status === "creating";
  const [iconSrc, setIconSrc] = useState<string | null>(null);

  useEffect(() => {
    const targetIconSrc = engine.loadingScreenData?.iconSrc;
    if (!targetIconSrc) {
      return;
    }
    let cancelled = false;

    loadImg(targetIconSrc)
      .then(() => {
        if (cancelled) return;
        setIconSrc(targetIconSrc);
      })
      .catch(() => {
        if (cancelled) return;
        loadImg(gameMakerPng).then(() => {
          if (cancelled) return;
          setIconSrc(gameMakerPng);
        });
      });

    return () => {
      cancelled = true;
    };
  }, [engine.loadingScreenData?.iconSrc]);

  useEffect(() => {
    if (!showGameLoading) {
      setIconSrc(null);
    }
  }, [showGameLoading]);

  if (!showGameLoading) {
    return null;
  }

  const showLoadingScreenData =
    engine.status === "creating" && engine.loadingScreenData;

  return (
    <div
      className={classes(
        css.gameLoadingContainer,
        engine.status !== "creating" && css.gameLoadingContainerHidden
      )}
    >
      <div className={css.gameLoadingTopSection}>
        <div
          className={css.gameLoadingIconContainer}
          style={{
            opacity: iconSrc && engine.status === "creating" ? 1 : 0,
            transform:
              iconSrc && engine.status === "creating"
                ? "scale(1) translateY(-20px)"
                : "scale(.5) translateY(0px)",
            transition:
              iconSrc && engine.status === "creating"
                ? "all ease .3s .2s"
                : "all ease .2s",
          }}
        >
          {iconSrc && (
            <img key={iconSrc} className={css.gameLoadingIcon} src={iconSrc} />
          )}
        </div>
        <div
          className={classes(
            css.spinner,
            (engine.status !== "creating" || iconSrc) && css.spinnerHidden
          )}
        >
          <Spinner />
        </div>
      </div>
      <div
        className={css.gameLoadingBottomSection}
        style={{
          opacity: showLoadingScreenData ? 1 : 0,
          transform: showLoadingScreenData
            ? "scale(1) translateY(100px)"
            : "scale(.5) translateY(200px)",
        }}
      >
        <div className={css.gameLoadingName}>
          Loading {engine.loadingScreenData?.name}
          <DotDotDot />
        </div>
      </div>
    </div>
  );
}

function DotDotDot() {
  const [dots, setDots] = useState(-3);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDots((value) => (value > 3 ? -3 : value + 1));
    }, 250);
    return () => {
      clearTimeout(timeout);
    };
  }, [dots]);

  const renderDot = (dot: number) => {
    return (
      <span
        style={{
          transition: "all ease .3s",
          opacity: Math.abs(dots) > dot ? 0.6 : 0,
        }}
      >
        .
      </span>
    );
  };

  return (
    <>
      {renderDot(0)}
      {renderDot(1)}
      {renderDot(2)}
      {renderDot(3)}
    </>
  );
}

function useSlotSelections(route: Route<typeof groups.game>) {
  const [refreshKey, setRefreshKey] = useState(0);
  const transitionDataMap = useRef(
    new Map<number, SlotSelectionTransitionData>()
  );
  const routeRef = useRef(route);
  routeRef.current = route;

  const slotSelections = useMemo(() => {
    return route.params.bots.map((bot, index): TransitionSlotSelection => {
      if (!bot) {
        return null;
      }

      let transitionData = transitionDataMap.current.get(index);

      if (!transitionData) {
        transitionData = {
          slotId: crypto.randomUUID(),
        };
        transitionDataMap.current.set(index, transitionData);
      }

      if (bot.type === BotType.User) {
        return {
          type: BotType.User,
          color: bot.color,
          owner: bot.owner,
          repo: bot.repo,
          transitionData,
        };
      }

      if (bot.type === BotType.Practice) {
        return {
          type: BotType.Practice,
          color: bot.color,
          transitionData,
        };
      }

      return {
        type: BotType.Boss,
        difficulty: bot.difficulty,
        color: bot.color,
        transitionData,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params.bots, refreshKey]);

  const setSlotSelections = useCallback(
    (fn: (current: TransitionSlotSelection[]) => TransitionSlotSelection[]) => {
      const selections = fn(slotSelections);
      for (let i = 0; i < selections.length; i++) {
        const selection = selections[i];

        if (!selection) {
          transitionDataMap.current.delete(i);
          continue;
        }

        transitionDataMap.current.set(i, selection.transitionData);
      }

      let bots: SlotSelection[] | undefined = selections.map(
        (selection): SlotSelection => {
          if (selection?.type === BotType.User) {
            return {
              color: selection.color,
              owner: selection.owner,
              repo: selection.repo,
              type: selection.type,
            };
          } else if (selection?.type === BotType.Practice) {
            return {
              color: selection.color,
              type: selection.type,
            };
          } else if (selection?.type === BotType.Boss) {
            return {
              color: selection.color,
              type: selection.type,
              difficulty: selection.difficulty,
            };
          } else {
            return null;
          }
        }
      );

      for (let i = bots.length - 1; i >= 0; i--) {
        if (bots[i]) {
          break;
        }

        bots.pop();
      }

      if (bots.length === 0) {
        bots = undefined;
      }

      if (routeRef.current.name === routes.internalGame.name) {
        routes
          .internalGame({
            ...routeRef.current.params,
            bots,
          })
          .replace();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      } else if (routeRef.current.name === routes.externalGame.name) {
        routes
          .externalGame({
            ...routeRef.current.params,
            bots,
          })
          .replace();
      } else {
        panic("Unexpected route", routeRef.current);
      }

      setRefreshKey((key) => key + 1);
    },
    [slotSelections]
  );

  return {
    slotSelections,
    setSlotSelections,
  };
}

function useSystemErrorPrompt(outcome: GameOutcome) {
  const systemError =
    outcome.status === "done" && outcome.primary?.type === "game-error";

  const prompt = PromptStore.usePrompt();

  useEffect(() => {
    if (!systemError) {
      return;
    }

    prompt(() => (
      <div>
        <NonIdealState
          icon="error"
          title="System Error"
          description={`An unexpected error was thrown.`}
          action={
            <AnchorButton
              large
              intent="primary"
              href="https://github.com/zilch/hello/issues/new"
              target="_blank"
              rel="noreferrer"
            >
              Report Error
            </AnchorButton>
          }
        />
      </div>
    ));
  }, [systemError, prompt]);
}

function useGameOutcomeAnalyticsLogger(
  gameId: GameId | null,
  outcome: GameOutcome,
  slotSelections: TransitionSlotSelection[]
) {
  let gameFinishedEvent: {
    gameId: GameId;
    gameTurnCount: number;
    primaryOutcomeType: PrimaryOutcomeType;
    multiplayer: boolean;
    hardestBossInGame: "easy" | "medium" | "hard" | null;
  } | null = null;

  const userStore = UserStore.use();
  const username =
    userStore.query.isSuccess && userStore.query.data.type === "authenticated"
      ? userStore.query.data.likelyLogin
      : null;

  if (outcome.status === "done" && outcome.primary?.type && gameId) {
    gameFinishedEvent = {
      gameId,
      gameTurnCount: outcome.gameLength,
      primaryOutcomeType: outcome.primary.type,
      hardestBossInGame: getHardestBossDifficulty(slotSelections),
      multiplayer:
        username !== null &&
        slotSelections.some(
          (slot) => slot?.type === "user" && slot.owner !== username
        ),
    };
  }

  useEffect(() => {
    if (gameFinishedEvent) {
      posthog.capture("game_finished", gameFinishedEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringify(gameFinishedEvent)]);
}

function getHardestBossDifficulty(slotSelections: TransitionSlotSelection[]) {
  return (
    slotSelections
      .filter((slot) => slot?.type === BotType.Boss)
      .map((slot) => (slot?.type === BotType.Boss ? slot.difficulty : null))
      .sort((a, b) => {
        const toNum = (difficulty: "easy" | "medium" | "hard" | null) => {
          if (difficulty === "easy") {
            return 1;
          } else if (difficulty === "medium") {
            return 2;
          } else if (difficulty === "hard") {
            return 3;
          } else {
            return 0;
          }
        };

        return toNum(a) - toNum(b);
      })[0] ?? null
  );
}

function useDefeatedBossDifficulty(
  outcome: GameOutcome,
  slotSelections: TransitionSlotSelection[]
) {
  const victoryOverBoss =
    outcome.status === "done" && outcome.primary?.type === "victory-over-boss";

  const slotSelectionsRef = useRef(slotSelections);
  slotSelectionsRef.current = slotSelections;

  return useMemo(() => {
    if (!victoryOverBoss) {
      return null;
    }

    return getHardestBossDifficulty(slotSelections);
  }, [slotSelections, victoryOverBoss]);
}

function useUpdateStarCount(
  gameId: GameId | null,
  outcome: GameOutcome,
  slotSelections: TransitionSlotSelection[]
) {
  const starStore = StarStore.use();
  const starStoreRef = useRef(starStore);
  starStoreRef.current = starStore;

  const gameIdRef = useRef(gameId);
  gameIdRef.current = gameId;

  const slotSelectionsRef = useRef(slotSelections);

  if (outcome.status !== "done") {
    slotSelectionsRef.current = slotSelections;
  }

  const defeatedBossBotDifficulty = useDefeatedBossDifficulty(
    outcome,
    slotSelectionsRef.current
  );

  useEffect(() => {
    if (!gameIdRef.current || !defeatedBossBotDifficulty) {
      return;
    }

    const currentStarCount = starStoreRef.current.getStarCount(
      gameIdRef.current
    );
    const newStarCount = {
      easy: 1,
      medium: 2,
      hard: 3,
    }[defeatedBossBotDifficulty];

    if (newStarCount > currentStarCount) {
      fireConfetti();
      starStoreRef.current.setStarCount(gameIdRef.current, newStarCount);
      const firstBossBotIndex = slotSelectionsRef.current.findIndex(
        (slot) => slot?.type === BotType.Boss
      );
      times(newStarCount - currentStarCount).forEach((index, _, arr) => {
        setTimeout(
          () => {
            starStoreRef.current.triggerStarAnimation(
              firstBossBotIndex,
              index === arr.length - 1
            );
          },
          index * 650 + 700
        );
      });
    }
  }, [defeatedBossBotDifficulty]);
}
