.container {
  padding: 30px;
}

.topSection {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 74px;
  padding: 24px 28px;
  padding-bottom: 75px;
  background: var(--dark-gray2);
  border-radius: 8px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.5);
  position: relative;
  border-top: 1px solid var(--blue5);
  border-bottom: 3px solid var(--black);
}

.premiumIcon {
  width: 200px;
  position: absolute;
  pointer-events: none;
  bottom: -96px;
  left: 50%;
  margin-left: -100px;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.5));
}

.bottomSection {
  border-radius: 8px;
  box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.5);
}

.button {
  font-weight: 600 !important;
  font-size: 20px !important;
  padding: 20px 30px !important;
  border-radius: 8px !important;
}
