import { Button, Colors, type IconName, Spinner } from "@blueprintjs/core";
import React from "react";
import * as csx from "csx";
import { SiGithub } from "react-icons/si";
import type { UserResponse } from "../../../backend/procedures/user";
import { UserStore } from "../../stores/UserStore";
import { NonIdealState } from "./NonIdealState";

interface Props {
  icon?: IconName | React.ReactElement;
  title?: React.ReactNode;
  actionText?: string;
  description?: string;
  children: React.ReactNode | ((user: UserResponse) => React.ReactNode);
}

export function AuthenticatedView(props: Props) {
  const userStore = UserStore.use();

  if (userStore.query.isInitialLoading) {
    return <NonIdealState key="loading" icon={<Spinner />} />;
  }

  if (userStore.query.data?.type === "authenticated") {
    return (
      <>
        {typeof props.children === "function"
          ? props.children(userStore.query.data)
          : props.children}
      </>
    );
  }

  if (userStore.query.isError) {
    return (
      <NonIdealState
        key="error"
        icon="error"
        title="Oops"
        description="Unexpected problem encountered."
      />
    );
  }

  const disabled =
    userStore.signingIn && userStore.signingIn !== "authenticatedView";

  return (
    <NonIdealState
      key="unauthorized"
      icon={props.icon ?? "lock"}
      title={props.title ?? "Not signed in"}
      description={props.description}
      action={
        <Button
          icon={
            <SiGithub
              size={18}
              color={csx
                .color(Colors.LIGHT_GRAY5)
                .fade(disabled ? 0.3 : 1)
                .toString()}
            />
          }
          intent="primary"
          large
          disabled={disabled}
          loading={userStore.signingIn === "authenticatedView"}
          onClick={() => {
            userStore.signIn("authenticatedView");
          }}
        >
          {props.actionText ?? "Sign in to continue"}
        </Button>
      }
    />
  );
}
