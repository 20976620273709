import { panic } from "@zilch/panic";
import type { ZodType } from "zod";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Procedure = (context: any, ...args: any[]) => Promise<any>;

type ProcedureRequest = z.infer<typeof ProcedureRequest>;
const ProcedureRequest = z.object({
  type: z.literal("request"),
  procedureId: z.string(),
  requestId: z.string(),
  args: z.array(z.unknown()),
});

type ProcedureReply = z.infer<typeof ProcedureReply>;
const ProcedureReply = z.object({
  type: z.literal("reply").or(z.literal("error")),
  requestId: z.string(),
  data: z.unknown(),
});

interface DefineProcedureParams<T extends Procedure> {
  procedureId: string;
  procedure: T;
  parametersType: ZodType<RemoveFirst<Parameters<T>>> | "skipCheck";
  getTransferables?(replyData: Unpromise<ReturnType<T>>): Transferable[];
}

interface RegisterProcedureParams<T extends Procedure> {
  targetWindow: Window;
  targetOrigin: string;
  context: Parameters<T>[0];
}

const messageListeners = new Set<
  (event: MessageEvent<ProcedureRequest>) => boolean
>();

const queuedEvents = new Set<MessageEvent<ProcedureRequest>>();
window.addEventListener("message", (event) => {
  const request = ProcedureRequest.safeParse(event.data);

  if (!request.success) {
    return;
  }

  let handled = false;
  for (const listener of messageListeners) {
    handled = listener(event);
  }
  if (!handled) {
    queuedEvents.add(event);
  }
});

export function defineProcedure<T extends Procedure>(
  defineParams: DefineProcedureParams<T>
) {
  return {
    register(registerParams: RegisterProcedureParams<T>) {
      const handleMessage = (event: MessageEvent<unknown>): boolean => {
        if (event.source !== registerParams.targetWindow) {
          return false;
        }

        const request = ProcedureRequest.safeParse(event.data);

        if (
          !request.success ||
          request.data.procedureId !== defineParams.procedureId
        ) {
          return false;
        }

        let transferableList: Transferable[] | undefined;

        if (defineParams.parametersType !== "skipCheck") {
          const parseResult = defineParams.parametersType.safeParse(
            request.data.args
          );

          if (!parseResult.success) {
            registerParams.targetWindow.postMessage(
              {
                type: "error",
                requestId: request.data.requestId,
                data: parseResult.error,
              },
              registerParams.targetOrigin,
              transferableList
            );
            return true;
          }
        }

        defineParams
          .procedure(registerParams.context, ...request.data.args)
          .then((data): ProcedureReply => {
            transferableList = defineParams.getTransferables?.(data);
            return {
              type: "reply",
              requestId: request.data.requestId,
              data,
            };
          })
          .catch((error): ProcedureReply => {
            return {
              type: "error",
              requestId: request.data.requestId,
              data: error,
            };
          })
          .then((reply) => {
            registerParams.targetWindow.postMessage(
              reply,
              registerParams.targetOrigin,
              transferableList
            );
          });

        return true;
      };

      const timeout = setTimeout(() => {
        queuedEvents.forEach((event) => {
          if (handleMessage(event)) {
            queuedEvents.delete(event);
          }
        });
      });

      messageListeners.add(handleMessage);

      return () => {
        clearTimeout(timeout);
        messageListeners.delete(handleMessage);
      };
    },

    "~internal": {
      Type: null as unknown as T,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RemoveFirst<T extends any[]> = T extends [any, ...infer Rest]
  ? Rest
  : never;

interface CreateIfpClientParams<T extends Procedure> {
  procedureId: string;
  targetWindow: Window;
  targetOrigin: string;
  returnType: ZodType<Unpromise<ReturnType<T>>> | "skipCheck";
  getTransferableList?: (...args: RemoveFirst<Parameters<T>>) => Transferable[];
}

export function createProcedureClient<
  T extends { "~internal": { Type: Procedure } },
>(params: CreateIfpClientParams<T["~internal"]["Type"]>) {
  const client = (...args: unknown[]) => {
    return new Promise((resolve, reject) => {
      const requestId = crypto.randomUUID();

      const request: ProcedureRequest = {
        type: "request",
        procedureId: params.procedureId,
        args,
        requestId,
      };

      const handleMessage = (event: MessageEvent<unknown>) => {
        if (event.source !== params.targetWindow) {
          return;
        }

        const reply = ProcedureReply.safeParse(event.data);

        if (!reply.success || reply.data.requestId !== requestId) {
          return;
        }

        window.removeEventListener("message", handleMessage);

        if (reply.data.type === "error") {
          reject(reply.data.data);
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (reply.data.type === "reply") {
          if (params.returnType === "skipCheck") {
            resolve(reply.data.data);
          } else {
            const parseResult = params.returnType.safeParse(reply.data.data);
            if (parseResult.success) {
              resolve(parseResult.data);
            } else {
              reject(parseResult.error);
            }
          }
        } else {
          panic("Unexpected reply type", reply.data.type);
        }
      };

      window.addEventListener("message", handleMessage);

      params.targetWindow.postMessage(
        request,
        params.targetOrigin,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params.getTransferableList?.(...(args as any)) ?? undefined
      );
    });
  };

  return client as unknown as (
    ...args: RemoveFirst<Parameters<T["~internal"]["Type"]>>
  ) => ReturnType<T["~internal"]["Type"]>;
}
