import { Spinner } from "@blueprintjs/core";
import { useQuery } from "@tanstack/react-query";
import type {
  SubscriptionWithTeams,
  Team,
} from "../../../backend/db/subscription";
import type { UserResponse } from "../../../backend/procedures/user";
import { api } from "../../api";
import { NonIdealState } from "../common/NonIdealState";
import { MemberList } from "./MemberList";
import type { TeamDialogState } from "./Teams";
import { UpdateTeamHeader } from "./UpdateTeamHeader";
import css from "./UpdateTeam.module.css";
import { Dialog } from "../common/Dialog";

function getTeamAndSubscription(teamId: string | null, user: UserResponse) {
  for (const subscription of user.subscriptions ?? []) {
    for (const team of subscription.teams) {
      if (team.id === teamId) {
        return { team, subscription };
      }
    }
  }

  return { subscription: null, team: null };
}

export function UpdateTeam(props: {
  teamDialogState: TeamDialogState | null;
  onClose(): void;
  onUpdate(close?: boolean): void;
  user: UserResponse;
}) {
  const teamId =
    props.teamDialogState?.type === "existing"
      ? props.teamDialogState.teamId
      : null;

  const { team, subscription } = getTeamAndSubscription(teamId, props.user);

  return (
    <Dialog
      width={480}
      isOpen={props.teamDialogState?.type === "existing"}
      onClose={props.onClose}
    >
      {team ? (
        <UpdateTeamDialogContent
          team={team}
          userId={props.user.userId}
          subscription={subscription}
          onUpdate={props.onUpdate}
        />
      ) : (
        <NonIdealState
          icon="search"
          title="Not found"
          description={`Unable to find team with id ${teamId ?? "(unknown)"}`}
        />
      )}
    </Dialog>
  );
}

function UpdateTeamDialogContent({
  team,
  userId,
  onUpdate,
  subscription,
}: {
  team: Team;
  userId: string;
  subscription: SubscriptionWithTeams;
  onUpdate(close?: boolean): void;
}) {
  const teamQuery = useQuery(
    ["manage.getTeamMembers", team.id, userId],
    async () => {
      return await api.manage.getTeamMembers.query({ teamId: team.id });
    }
  );

  return (
    <>
      <UpdateTeamHeader
        teamQuery={teamQuery}
        userId={userId}
        onUpdate={onUpdate}
        team={team}
        subscription={subscription}
      />
      <div className={css.content}>
        {teamQuery.isLoading && <NonIdealState icon={<Spinner />} />}
        {teamQuery.isError && (
          <NonIdealState
            description={
              <div style={{ maxWidth: "300px" }}>
                Unexpected problem encountered while loading this team's list of
                members. Try refreshing the page.
              </div>
            }
          />
        )}
        {teamQuery.isSuccess && (
          <MemberList
            teamId={team.id}
            viewerUserId={userId}
            users={teamQuery.data}
            onUpdate={async (close?: boolean) => {
              onUpdate(close);
              if (!close) {
                await teamQuery.refetch();
              }
            }}
          />
        )}
      </div>
    </>
  );
}
