import {
  Button,
  Colors,
  H3,
  Icon,
  InputGroup,
  Menu,
  MenuDivider,
  MenuItem,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import type { PanelActions } from "@blueprintjs/core/lib/esm/components/panel-stack2/panelTypes";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import { useCallback, useContext, useState } from "react";
import { sample } from "lodash";
import { BotCreationStore } from "./BotCreator";
import css from "./BotCreatorLanguagePanel.module.css";
import { classes, delayCss, transitionInFromCss } from "@zilch/css-utils";
import { BotCreatorPanel } from "./BotCreatorPanel";
import type { GameConfig } from "@zilch/game-config";
import { Highlight } from "../common/Highlight";
import { Img } from "../common/Img";
import { BotCreatorAvatarPanel } from "./BotCreatorAvatarPanel";
import type { LanguageCollection } from "./useLanguages";
import { type Language, useLanguages } from "./useLanguages";
import { NonIdealState } from "../common/NonIdealState";
import * as csx from "csx";
import { BotCreatorOtherLanguagePanel } from "./BotCreatorOtherLanguagePanel";

export function BotCreatorLanguagePanel(props: PanelActions) {
  const store = useContext(BotCreationStore);

  const [search, setSearch] = useState("");
  const [showTopBorder, setShowTopBorder] = useState(false);

  const handleScroll = useCallback((e: React.UIEvent) => {
    setShowTopBorder((e.target as HTMLDivElement).scrollTop !== 0);
  }, []);

  const languagesQuery = useLanguages();

  if (!store?.botConfig.game) {
    return null;
  }

  return (
    <BotCreatorPanel className={css.container}>
      <div className={css.header}>
        <H3
          style={{ marginBottom: "0px" }}
          className={classes(transitionInFromCss.bottom, delayCss["450"])}
        >
          Select Language
        </H3>
        <InputGroup
          placeholder="Search..."
          round
          className={classes(transitionInFromCss.bottom, delayCss["100"])}
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          leftIcon="search"
        />
      </div>
      <div
        className={classes(
          css.languageListContainer,
          showTopBorder && css.languageListContainerTopBorder
        )}
        onScroll={handleScroll}
      >
        {languagesQuery.isError && (
          <NonIdealState description="Unable to load languages" />
        )}
        {languagesQuery.isLoading && <Spinner />}
        {languagesQuery.isSuccess && (
          <LanguageOptionSection
            gameConfig={store.gameConfig}
            languageCollection={languagesQuery.data}
            onSetLanguageId={(value) => {
              store.onSetBotConfig((config) => {
                return {
                  ...config,
                  language: value,
                };
              });
              if (value === null) {
                props.openPanel({
                  renderPanel: (props) => (
                    <BotCreatorOtherLanguagePanel {...props} />
                  ),
                });
              } else {
                props.openPanel({
                  renderPanel: (props) => <BotCreatorAvatarPanel {...props} />,
                });
              }
            }}
            search={search}
          />
        )}
      </div>
      <div className={css.messageOuterContainer}>
        <div
          style={{
            backdropFilter: "blur(5px)",
            background: csx.color(Colors.DARK_GRAY2).fade(0.8).toString(),
            boxShadow: "0px 2px 12px -1px rgba(0,0,0,.1)",
            pointerEvents: "auto",
          }}
        >
          <Tag minimal large icon="learning" fill style={{ fontWeight: 500 }}>
            Want a challenge? Try learning something new.
          </Tag>
        </div>
      </div>
    </BotCreatorPanel>
  );
}

function LanguageOptionSection({
  gameConfig,
  search,
  onSetLanguageId,
  languageCollection,
}: {
  gameConfig: GameConfig;
  search: string;
  onSetLanguageId: (languageId: string | null) => void;
  languageCollection: LanguageCollection;
}) {
  const languages = Object.keys(languageCollection).map((languageId) => {
    const matchingTemplateIds = gameConfig.templates.filter((templateId) =>
      templateId.startsWith(`${languageId}.`)
    );
    const variantIds = matchingTemplateIds
      .map((templateId) => templateId.split(".")[1])
      .map((variantId) => variantId ?? null);

    const language: Language = languageCollection[languageId] ?? {
      name: languageId,
    };

    const variants: ({ id: string; name: string } | null)[] = variantIds.map(
      (variantId) => {
        if (variantId === null) {
          return null;
        }

        const variant = (language.variants ?? {})[variantId] ?? {
          name: variantId,
        };

        return {
          id: variantId,
          name: variant.name,
        };
      }
    );

    return {
      name: language.name,
      id: languageId,
      supported: matchingTemplateIds.length > 0,
      variants,
    };
  });

  const supportedLanguages = languages.filter((language) => language.supported);

  const filteredSortedLanguages = languages
    .sort((a, b) => {
      if (a.supported !== b.supported) {
        return a.supported ? -1 : 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    })
    .filter((language) =>
      language.name.toLowerCase().includes(search.trim().toLowerCase())
    );

  return (
    <div className={css.languageList}>
      {filteredSortedLanguages.length > 1 && search.trim().length === 0 && (
        <div
          tabIndex={0}
          onClick={() => {
            const randomLanguage = sample(supportedLanguages)!;
            const randomVariant = sample(
              randomLanguage.variants.map((variant) => variant?.id ?? null)
            );

            const templateId =
              randomLanguage.id + (randomVariant ? "." + randomVariant : "");

            onSetLanguageId(templateId);
          }}
          className={classes(
            transitionInFromCss.bottom,
            delayCss["200"],
            css.languageOptionContainer
          )}
        >
          <Icon
            icon="random"
            size={30}
            color={Colors.GRAY5}
            style={{ transform: "translateY(-10px)" }}
          />
          <div className={css.languageName}>Random</div>
        </div>
      )}
      {filteredSortedLanguages.map((language, index) => {
        return (
          <LanguageOption
            key={language.id}
            animationDelay={search.trim().length > 0 ? 0 : index * 30 + 150}
            language={language}
            search={search}
            onSelect={(languageId) => onSetLanguageId(languageId)}
          />
        );
      })}
      {"other".includes(search.trim().toLowerCase()) && (
        <div
          tabIndex={0}
          onClick={() => onSetLanguageId(null)}
          className={classes(
            transitionInFromCss.bottom,
            search.trim() === "" && delayCss["200"],
            css.languageOptionContainer
          )}
        >
          <Icon
            icon="box"
            size={30}
            color={Colors.GRAY4}
            style={{ transform: "translateY(-10px)" }}
          />
          <div className={css.languageName}>
            <Highlight text="Other" highlight={search} />
          </div>
        </div>
      )}
    </div>
  );
}

function LanguageOption({
  language,
  search,
  onSelect,
  animationDelay,
}: {
  language: {
    name: string;
    id: string;
    supported: boolean;
    variants: ({ id: string; name: string } | null)[];
  };
  search: string;
  animationDelay: number;
  onSelect: (templateId: string) => void;
}) {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Popover2
      hasBackdrop={false}
      isOpen={menuOpen}
      position="bottom"
      onInteraction={(nextOpenState) => {
        if (!language.supported) {
          setMenuOpen(nextOpenState);
        } else if (!nextOpenState) {
          setMenuOpen(false);
        } else if (language.variants.length === 0) {
          onSelect(language.id);
        } else if (language.variants.length === 1) {
          const variant = language.variants[0];
          if (variant === null || !variant) {
            onSelect(language.id);
          } else {
            onSelect(language.id + "." + variant.id);
          }
        } else {
          setMenuOpen(true);
        }
      }}
      content={
        language.supported ? (
          <Menu
            style={{ maxHeight: "300px", overflowY: "auto" }}
            className={transitionInFromCss.bottom}
          >
            <MenuDivider
              title={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ paddingRight: "10px" }}>
                    {language.name} Variant
                  </span>
                  <Tooltip2
                    placement="top"
                    content={
                      <div style={{ maxWidth: "280px" }}>
                        Select a {language.name} variant. If you{"'"}re unsure
                        which one to pick, chose the one with the
                        &quot;Recommended&quot; label.
                      </div>
                    }
                  >
                    <Button small minimal icon="help" />
                  </Tooltip2>
                </div>
              }
            />
            {language.variants.map((variant, index) => (
              <MenuItem
                key={index}
                text={variant?.name ?? language.name}
                label={index === 0 ? "Recommended" : undefined}
                onClick={() => {
                  if (variant) {
                    onSelect(language.id + "." + variant.id);
                  } else {
                    onSelect(language.id);
                  }
                }}
              />
            ))}
          </Menu>
        ) : (
          <div style={{ padding: "10px" }}>
            {language.name} support is coming soon.
          </div>
        )
      }
    >
      <div
        className={classes(
          transitionInFromCss.bottom,
          css.languageOptionContainer,
          menuOpen && css.languageOptionContainerVariantMenuOpenCss
        )}
        tabIndex={0}
        style={{ animationDelay: animationDelay + "ms" }}
      >
        <Img
          height={50}
          style={{ maxWidth: "70px", transform: "translateY(-10px)" }}
          src={ClientEnv.LANGUAGES_METADATA_ORIGIN + `/${language.id}.svg`}
          className={css.languageOptionIcon}
          loadingPlaceholder={
            <div style={{ transform: "translateY(-10px)" }}>
              <Spinner
                className={classes(transitionInFromCss.bottom, delayCss["500"])}
                size={30}
              />
            </div>
          }
        />
        <div className={css.languageName}>
          <Highlight text={language.name} highlight={search} />
        </div>
        {language.supported &&
          (language.id === "javascript" || language.id === "python") && (
            <div
              style={{
                position: "absolute",
                bottom: "-7px",
                fontSize: "10px",
                background: csx
                  .color(Colors.DARK_GRAY3)
                  .mix(Colors.GREEN4, 0.85)
                  .toString(),
                lineHeight: "14px",
                color: Colors.GREEN5,
                fontWeight: 600,
                borderRadius: "7px",
                padding: "0px 6px",
              }}
              className="bp4-text-muted"
            >
              Beginner friendly
            </div>
          )}
        {!language.supported && (
          <div
            style={{
              position: "absolute",
              bottom: "-7px",
              fontSize: "10px",
              background: Colors.DARK_GRAY4,
              lineHeight: "14px",
              borderRadius: "7px",
              padding: "0px 6px",
            }}
            className="bp4-text-muted"
          >
            Coming soon
          </div>
        )}
      </div>
    </Popover2>
  );
}
