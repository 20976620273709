import React, { useEffect, useRef, useState } from "react";

type Props = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  loadingPlaceholder?: React.ReactNode;
  onLoadFinished?: () => void;
};

export function Img({ loadingPlaceholder, ...props }: Props) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!props.src) {
      return;
    }

    let cancelled = false;
    const image = new Image();
    image.src = props.src;
    image.onload = () => {
      if (cancelled) {
        return;
      }
      setLoaded(true);
    };

    return () => {
      setLoaded(false);
      cancelled = true;
    };
  }, [props.src]);

  const onLoadFinishedRef = useRef(props.onLoadFinished);
  onLoadFinishedRef.current = props.onLoadFinished;
  useEffect(() => {
    onLoadFinishedRef.current?.();
  }, [loaded]);

  return loaded ? <img {...props} /> : <>{loadingPlaceholder}</>;
}
